import React, { Component } from 'react';
import './styles/mobile.scss';
import Lottie from 'react-lottie';
import { LottieAnimations } from '../../../assets/icons';
import PropTypes from 'prop-types';
import { BLANK_LOADING_TYPE, CAMERA_LOADING_TYPE, HEADER_PAGE_LOADING_TYPE } from './config';
import UIController from '../UIController';

class UILoadingPage extends Component {
	constructor(props) {
		super(props);
	}

	//Mark: Render functions

	render() {
		const { style } = this.props;
		return (
			<div className='loadingPage' style={style}>
				{this.renderByType()}
			</div>
		);
	}

	renderByType = () => {
		const { type } = this.props;

		switch (type) {
			case BLANK_LOADING_TYPE:
				return null;
			case HEADER_PAGE_LOADING_TYPE:
				return (
					<UIController>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								path: LottieAnimations.cameraHomeIcon,
								rendererSettings: {
									preserveAspectRatio: 'xMidYMid slice'
								}
							}}
							height={200}
							width={200}
							isStopped={false}
							isPaused={false}
						/>
					</UIController>
				);
			case CAMERA_LOADING_TYPE:
			default:
				return (
					<div className='uiLoadingPage--body'>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								path: LottieAnimations.cameraHomeIcon,
								rendererSettings: {
									preserveAspectRatio: 'xMidYMid slice'
								}
							}}
							height={200}
							width={200}
							isStopped={false}
							isPaused={false}
						/>
					</div>
				);
		}
	};
}

UILoadingPage.propTypes = {
	type: PropTypes.string
};

export default UILoadingPage;
