import React, { useEffect, useState } from 'react';
import './styles/mobile.scss';
import { useSelector } from 'react-redux';
import { MinusIcon, PlusIcon } from '../../../assets/icons';
import UIIcon from '../UIIcon';
import './styles';
import { getIsUserAdminSelector } from '../../../redux/selectors/CurrentUserSelectors';
import * as strings from './strings';

export default function UIQuantitySelect({ minQuantity: minQuantityProp, bookableOption, className, noLabel, updateOption }: QuantitySelectProps) {
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const [minQuantity, setMinQuantity] = useState<number>(minQuantityProp ?? bookableOption.option.quantity ?? 1);

	useEffect(() => {
		if (!minQuantity || isUserAdmin) {
			setMinQuantity(1);
		} else {
			setMinQuantity(minQuantityProp ?? 1);
		}
	}, []);

	if (!bookableOption) return null;

	const incrementQuantity = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		updateOption({ ...bookableOption, selectedQuantity: bookableOption.selectedQuantity + 1 });
	};

	const decrementQuantity = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		if (bookableOption.selectedQuantity > 1 && bookableOption.selectedQuantity > minQuantity) {
			updateOption({ ...bookableOption, selectedQuantity: bookableOption.selectedQuantity - 1 });
		}
	};

	return (
		<div className={`${className} fade-in-div`}>
			{!noLabel && <p className='quantity-label'>{strings.quantity}</p>}
			<div className='quantity-row'>
				<p className='quantity-row__quantity'>
					{bookableOption.selectedQuantity} <span className='quantity-row__quantity--symbol'>x</span>
				</p>
				<div className='quantity-select'>
					<UIIcon className='quantity-image-plus' alt={'Add'} iconSrc={PlusIcon.white} onClick={incrementQuantity} />
					<UIIcon className='quantity-image-minus' alt={'Subtract'} iconSrc={MinusIcon.white} onClick={decrementQuantity} />
				</div>
			</div>
		</div>
	);
}
