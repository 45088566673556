import gql from 'graphql-tag';

export const GET_OPTION_REGION = gql`
	query GetOption($id: String!) {
		option(id: $id) {
			id
			regions {
				id
				name
			}
		}
	}
`;
