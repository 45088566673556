import styled from 'styled-components';
import UIIcon from '../../UIIcon';

export const div_UIOverlayCardContainer = styled.div`
	//DEFAULT
	display: flex;
	flex-direction: column;
	background-color: var(--white-8-solid);
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
	border: var(--white-8) solid 1px;
	opacity: 1;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		height: fit-content;
		border-radius: 24px;
		padding: 12px;
		max-width: 640px;
		max-height: calc(100% - 128px);
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		height: fit-content;
		border-radius: 24px;
		padding: 12px;
		max-width: 640px;
		max-height: 100%;
		animation: fadeIn 100ms ease-in-out;
		-webkit-animation: fadeIn 100ms ease-in-out;
		-moz-animation: fadeIn 100ms ease-in-out;
		-o-animation: fadeIn 100ms ease-in-out;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_UIOverlayCardTop = styled.div`
	//DEFAULT
	display: flex;
	flex-direction: row;
	align-items: center;
	min-height: 48px;
	width: 100%;
	padding: 0 12px;
	border-bottom: var(--white-8) solid 1px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		padding: 12px 12px 24px 12px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		border-bottom: var(--white-8) solid 1px;
		padding: 12px 12px 24px 12px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIIcon_UIOverlayCardTopIcon = styled(UIIcon)`
	//DEFAULT
	margin: 0;
	width: 32px;
	height: 32px;
	border-radius: 6px;
	padding: 8px 0;
	transition: all 300ms ease-out;
	user-select: none;

	&:hover {
		transform: scale(0.96);
		cursor: pointer;
		background-color: var(--white-2);
	}

	&:active {
		transform: scale(0.92);
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_UIOverlayCardTitle = styled.p`
	//DEFAULT
	font-weight: 700;
	color: white;
	font-size: 14pt;
	width: 100%;
	margin: 0 12px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		font-family: BrownRegular, sans-serif;
		font-size: 14pt;
		font-weight: 500;
		margin-left: 24px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_UIOverlayCardContent = styled.div`
	//DEFAULT
	display: flex;
	flex-direction: column;
	overflow-y: auto !important;
	height: 100% !important;
	padding: 12px;
	max-width: 100%;
	width: 100%;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		padding: 24px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		padding: 24px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_UIOverlayCardTitleAlt = styled.p`
	//DEFAULT
	padding: 16px 16px 0 16px;
	font-weight: 600;
	font-size: 13pt;
	margin-bottom: 4px;
	width: 100%;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_UIOverlayCardDescription = styled.p`
	//DEFAULT
	color: var(--white-80);
	font-size: 10pt;
	font-weight: 500;
	margin-top: 12px;
	margin-bottom: 24px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		color: var(--white-80);
		font-size: 12pt;
		font-weight: 600;
		margin-top: 0;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		font-size: 12pt;
		font-weight: 400;
		color: var(--white-90);
		margin-top: 0;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
