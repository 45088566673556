import { applyMiddleware, createStore } from 'redux';
import { rootReducer } from './rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import loginSaga from '../../sagas/LoginSagas/LoginSagas';
import thunk from 'redux-thunk';

const configureStore = (preloadedState) => {
	const sagaMiddleware = createSagaMiddleware();

	const store = createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(sagaMiddleware, thunk)));

	sagaMiddleware.run(loginSaga);

	if (module['hot']) {
		// Enable Webpack hot module replacement for reducers
		module['hot'].accept('./rootReducer', () => {
			const nextRootReducer = require('./rootReducer');
			store.replaceReducer(nextRootReducer);
		});
	}

	return store;
};

export default configureStore;
