import { combineReducers } from 'redux';
import control from './control/reducer';
import currentUser from './user/reducer';
import api from './api/reducer';
import booking from './booking/reducer';
import actionItems from './actionItems/reducer';
import filters from './filters/reducer';
import option from './options/reducer';

export const rootReducer = combineReducers({
	control,
	booking,
	currentUser,
	api,
	actionItems,
	filters,
	option
});
