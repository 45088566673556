// Created by carsonaberle at 6/27/21
import React, { useState, useReducer, useRef } from 'react';
import './styles/mobile.scss';
import { FileInfo, Widget, WidgetAPI } from '@uploadcare/react-widget';
import * as strings from './strings';
import { toast } from 'react-toastify';
import Lottie from 'react-lottie';
import { getAuthorizationHeader } from '../../../utils/API/ApolloClient';
import { getApiUrl } from '../../../utils/API/APIConfig';
import { FILE_TYPE_FLOOR_PLAN, FILE_TYPE_IMAGE, FILE_TYPE_RAW_IMAGE, FILE_TYPE_VIDEO, FILE_TYPE_VIDEO_RAW } from '../../../utils/Config/Constants';
import { LottieAnimations, UploadIcon } from '../../../assets/icons';
import UIIcon from '../../UI/UIIcon';
import { PLUploadProps } from './types';
import { Prompt } from 'react-router';

export default function PLUpload({ fileType, uploadStatus, onFileUploadComplete, localeTranslations, postData, uploadSuccessMessage, tagData }: PLUploadProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [isFinalizing, setIsFinalizing] = useState<boolean>(false);
	const [key, rerender] = useReducer((v: number) => v + 1, 0);

	const widgetApi = useRef<WidgetAPI>(null);

	const postUploadcare = async (groupId: string, data: object) => {
		const url = `${getApiUrl(true)}/uploadcare/create-files`;
		return new Promise(async (resolve) => {
			await fetch(url, {
				method: 'POST',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getAuthorizationHeader(localStorage)}`
				},
				body: JSON.stringify(data)
			})
				.then((response) => {
					if (response.status === 400) {
						resolve(null);
					}
					resolve(response.json());
				})
				.catch(() => {
					resolve(null);
				});
		});
	};

	const onChange = async (fileInfo: FileInfo) => {
		const response: any = await postResponse(fileInfo.uuid as string);
		if (response?.filesCreated) {
			toast.success(uploadSuccessMessage || 'Upload successful!');
			onFileUploadComplete();
		} else {
			toast.error(`Error uploading files`);
		}
	};

	const onFileSelect = () => {
		setIsLoading(true);
	};

	const postResponse = async (groupId: string) => {
		if (!isFinalizing) {
			setIsFinalizing(true);
			let data = { ...postData, groupId, fileType, status: uploadStatus, tags: tagData };
			const response = await postUploadcare(groupId, data);
			if (!response) {
				setIsLoading(false);
				setIsFinalizing(false);
				rerender();
			} else {
				setIsLoading(false);
				setIsFinalizing(false);
				rerender();
			}
			return response;
		}
	};

	const fileTypeLimit = (allowedFileTypes: string) => {
		if (allowedFileTypes === '*') {
			return function () {
				return true;
			};
		}
		const types = allowedFileTypes.split(' ');

		return (fileInfo: FileInfo) => {
			if (fileInfo.name === null) {
				return true;
			}
			const extension = fileInfo.name.split('.').pop();
			if (extension && !types.includes(extension.toLowerCase())) {
				toast.error(`Can't upload type: ${extension}`);
				throw new Error('fileType');
			}
			return;
		};
	};

	const getAllowedUploadTypes = () => {
		switch (fileType) {
			case FILE_TYPE_RAW_IMAGE:
				return '*';
			// return 'jpg jpeg 3fr ari arw bay braw crw cr2 cr3 cap data dcs dcr dng drf eip erf fff gpr iiq k25 kdc mdc mef mos mrw nef nrw obm orf pef ptx pxn r3d raf raw rwl rw2 rwz sr2 srf srw tif x3f';
			case FILE_TYPE_VIDEO:
				return 'mp4 avi m4v';
			case FILE_TYPE_VIDEO_RAW:
				return 'zip';
			case FILE_TYPE_IMAGE:
			case FILE_TYPE_FLOOR_PLAN:
			default:
				return 'jpg jpeg png pdf';
		}
	};

	const validators = [fileTypeLimit(getAllowedUploadTypes())];

	const renderLeft = () => {
		const renderLeftContent = () => {
			if (!isFinalizing && !isLoading) return <UIIcon iconSrc={UploadIcon.white} alt={'upload'} />;

			return (
				<Lottie
					options={{
						loop: true,
						autoplay: true,
						//@ts-ignore
						path: LottieAnimations.loadingCircle,
						rendererSettings: {
							preserveAspectRatio: 'xMidYMid slice'
						}
					}}
					isClickToPauseDisabled={true}
					height={'100%'}
					width={'100%'}
					isStopped={false}
					isPaused={false}
				/>
			);
		};
		return <div className='plUpload--icon'>{renderLeftContent()}</div>;
	};

	const getStatusText = () => {
		if (!isLoading) {
			switch (fileType) {
				case FILE_TYPE_IMAGE:
					return 'final images';
				case FILE_TYPE_VIDEO:
					return 'final videos';
				case FILE_TYPE_FLOOR_PLAN:
					return 'floor plans';
				case FILE_TYPE_RAW_IMAGE:
					return 'RAW images';
				case FILE_TYPE_VIDEO_RAW:
					return 'RAW videos (zip)';
				default:
					return 'files';
			}
		} else {
			if (!isFinalizing) {
				return 'view progress';
			}
			return 'finalizing...';
		}
	};

	return (
		//@ts-ignore
		<div className='plUpload' onClick={!isFinalizing ? () => widgetApi.current?.openDialog() : undefined}>
			{renderLeft()}
			<div className='plUpload--button'>
				<Widget
					key={key}
					ref={widgetApi}
					multiple={true}
					multipartConcurrency={4}
					previewStep={false}
					localeTranslations={localeTranslations ?? strings.defaultLocaleTranslations}
					validators={validators}
					tabs={'file gdrive dropbox box'}
					publicKey={process.env.UPLOADCARE_KEY as string}
					onFileSelect={onFileSelect}
					onChange={onChange}
				/>
				<p className='plUpload--status'>{getStatusText()}</p>
			</div>
			<Prompt when={isLoading} message={() => 'A file upload is in progress, are you sure you want to leave this page and cancel your upload?'} />
		</div>
	);
}
