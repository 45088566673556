import gql from 'graphql-tag';

export const GET_SERVICE_INCLUDES_MATTERPORT = gql`
	query Service($id: String!) {
		service(id: $id) {
			id
			includesMatterport
		}
	}
`;

export const UPDATE_SERVICE_INCLUDES_MATTERPORT = gql`
	mutation UpdateServiceSurge($serviceId: String!, $includesMatterport: Boolean!) {
		updateService(id: $serviceId, includesMatterport: $includesMatterport) {
			id
			includesMatterport
		}
	}
`;
