import styled from 'styled-components';
import UIOverlayCard from '../../../../components/UI/UIOverlayCard';

export const UIOverlayCard_LoginContainer = styled(UIOverlayCard)<UIOverlayCardProps>`
	//DEFAULT
	max-width: 480px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_LoginInputEmail = styled.div`
	//DEFAULT
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_LoginSignup = styled.div`
	//DEFAULT
	animation: fade-in 0.8s ease-in-out;
	display: flex;
	justify-content: space-between;
	margin-top: 48px;
	margin-bottom: 0;

	&:hover {
		& > p {
			cursor: pointer;
			color: white;
		}
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_LoginSignupTitle = styled.p`
	//DEFAULT
	font-weight: 600;
	transition: 300ms all ease-out;
	font-family: BrownRegular, sans-serif;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_LoginForgotPassword = styled.div`
	//DEFAULT
	flex-direction: row;
	align-items: center;
	margin-top: 36px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_LoginForgotPasswordTitle = styled.p`
	//DEFAULT
	display: flex;
	justify-content: flex-end;
	font-weight: 600;
	font-size: 11pt;
	cursor: pointer;
	margin-left: 24px;
	color: var(--light-grey);
	transition: 300ms all ease-out;
	padding: 12px 0;
	font-family: BrownRegular, sans-serif;

	&:hover {
		color: var(--white-80);
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
