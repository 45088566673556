// Created by jarongallo at 6/17/21
import React, { useEffect, useState } from 'react';
import * as strings from './strings';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import { CHILD_TYPE } from '../../../../../../components/UI/UIListInputRow/config';
import UICheckbox from '../../../../../../components/UI/UICheckbox/UICheckbox';
import { toast } from 'react-toastify';
import { camelCaseToSentence } from '../../../../../../utils/Helpers/StringHelpers';
import { GET_SERVICE_INCLUDES_DRONE, UPDATE_SERVICE_INCLUDES_DRONE } from './queries';

export default function PLServiceAdminIncludesDrone({ serviceId }: PLServiceAdminIncludesDroneProps) {
	const [isDroneService, setIsDroneService] = useState<boolean>(false);

	const [updateAdminDrone] = useMutation(UPDATE_SERVICE_INCLUDES_DRONE, {
		onError: (error) => toast.error(strings.errorUpdatingToggle(error.message))
	});

	const [getServiceAdmin, { data: serviceData }] = useLazyQuery(GET_SERVICE_INCLUDES_DRONE, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getServiceAdmin();
		})();
	}, []);

	useEffect(() => {
		(() => {
			if (serviceData) {
				setIsDroneService(serviceData['service'].isDroneService);
			}
		})();
	}, [serviceData]);

	const updateCheckbox = async (key: string, value: any) => {
		const response = await updateAdminDrone({ variables: { serviceId: serviceId, [key]: value } });
		if (response['data']) {
			await getServiceAdmin();
			toast.success(strings.updateToggleSuccess(camelCaseToSentence(key)));
		} else {
			toast.error(strings.errorUpdatingToggle(`update failed.`));
		}
	};

	return (
		<UIListInputRow type={CHILD_TYPE} label={strings.toggleLabel}>
			<UICheckbox
				label={strings.isDroneService}
				style={{ marginTop: 12 }}
				onChangeExpansion={() => updateCheckbox('isDroneService', !isDroneService)}
				status={isDroneService ? UICheckbox.CHECKED : UICheckbox.UNCHECKED}
			/>
		</UIListInputRow>
	);
}
