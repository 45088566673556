import gql from 'graphql-tag';
import { files } from '../../../utils/API/Queries';

export const LOGIN_MUTATION = gql`
		mutation login($email: String!, $password: String!) {
			login(email: $email, password: $password) {
				id
				token
            isActive
            name
            role
            email
            image
            notes
            inTraining
            cubiCasaEmail
            cubiCasaStatus
            certificates
            requiresPasswordChange
            stripeAccountId
            stripeCustomerId
            isInternalTech
            allowInvoicing
            websiteUrl
            phoneNumber
            bio
            serviceAreas
            additionalContacts
            claimServiceDelay
            resourcesRead
            marketingAssets {
               ${files.fields}
            }
            optOutPhotographers {
               id
               name
               image
            }
            promos {
               id
               name
               promoType
               discountAmount
               discountPercentage
               discountType
               isActive
               uses
               maxUses
               option {
                  id
                  name
               }
            }
            schedulePhotographerRequests {
               id
               isActive
               accepted
               requestedTimes
            }
            editingCompany {
               id
            }
            regions {
            	id
            }
			}
		}
	`;
