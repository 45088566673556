import React, { useEffect, useState } from 'react';
import * as S from './styles';
import * as EmailValidator from 'email-validator';
import { useDispatch } from 'react-redux';
import { ZendeskAPI } from 'react-zendesk';
import { ID_KEY, ROLE_KEY, SUPER_USER_TOKEN_KEY, TOKEN_KEY } from '../../../utils/Config/Constants';
import UIIconButton from '../../../components/UI/UIIconButton';
import UIListInputRow from '../../../components/UI/UIListInputRow';
import { removeURLParameter } from '../../../utils/Helpers/AppHelper';
import { getInitialRoute, isUserAdminOrRM } from '../../../utils/Helpers/UserHelpers';
import { UI_LABELED_INPUT_TEXT_TYPE } from '../../../components/UI/UILabeledInput/config';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import * as strings from './strings';
import { UI_ICON_BUTTON_WHITE_TYPE } from '../../../components/UI/UIIconButton/config';
import { setSignedInUser, showLogin, showSignup } from '../../../redux/store/user/actions';
import { clearUserCache } from '../../../utils/Helpers/LoginHelper';
import ForgotPasswordModal from '../../../components/modals/ForgotPasswordModal';
import { quickLoginEmails } from './config';
import { LOGIN_MUTATION } from './queries';
import QuickLoginDev from './components/QuickLoginDev';

export default function Login({ hideModal, preFilledEmail }: LoginProps) {
	const dispatch = useDispatch();

	const history = useHistory();
	const location = useLocation();

	const onLoginError = (error) => {
		setIsLoading(false);
		clearUserCache();

		const errorMessage = error.message.replace('GraphQL error: ', '');

		if (errorMessage === 'Incorrect password') {
			toast.error(`The password you entered is incorrect, please double check and try again.`);
		} else {
			toast.error(errorMessage);
		}
	};

	const [loginMutation] = useMutation(LOGIN_MUTATION, { onError: onLoginError });

	const [email, setEmail] = useState<string>(preFilledEmail || '');
	const [password, setPassword] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState<boolean>(false);

	const onKeyPress = async (e) => {
		if (e.key === 'Enter') {
			await onLoginClick();
		}
	};

	const isLoginValid = () => {
		if (email.trim() === '') {
			toast.info(strings.emailWarning);
			return false;
		} else if (!EmailValidator.validate(email.trim())) {
			toast.info(strings.emailValidWarning);
			return false;
		} else if (password.trim() === '') {
			toast.info(strings.passwordValidWarning);
			return false;
		}
		return true;
	};

	const postLoginActions = async (loggedInUser: UserType) => {
		if (!loggedInUser.token) {
			toast.error('No token!');
			return;
		}

		ZendeskAPI('webWidget', 'identify', {
			name: loggedInUser.name,
			email: loggedInUser.email
		});

		localStorage.setItem(ID_KEY, loggedInUser.id);
		localStorage.setItem(TOKEN_KEY, loggedInUser.token);
		localStorage.setItem(ROLE_KEY, loggedInUser.role);
		if (isUserAdminOrRM(loggedInUser)) {
			localStorage.setItem(SUPER_USER_TOKEN_KEY, loggedInUser.token);
		}

		dispatch(setSignedInUser(loggedInUser));
		dispatch(showLogin(false));

		setIsLoading(false);
		history.push(getInitialRoute(loggedInUser) + removeURLParameter(location.search, 'login'));
	};

	const onLoginClick = async () => {
		if (!isLoginValid()) return;

		setIsLoading(true);

		const variables = { email: email.toLowerCase(), password };

		const response = await loginMutation({ variables });

		if (response) {
			const { login: loggedInUser } = response['data'];
			await postLoginActions(loggedInUser);
		}
	};

	useEffect(() => {
		if (quickLoginEmails.includes(email) && password === 'password') {
			onLoginClick().then();
		}
	}, [email, password]);

	const renderQuickLoginDev = () => {
		switch (process.env.PL_ENVIRONMENT) {
			case 'DEV':
			case 'LOCAL_DEV':
			case 'LOCAL':
				const onClickMenuItem = async (quickLoginEmail) => {
					setEmail(quickLoginEmail);
					setPassword('password');
				};

				return <QuickLoginDev onClickMenuItem={onClickMenuItem} />;
			case 'LIVE':
			default:
				return null;
		}
	};

	const onSignupClick = () => {
		hideModal();
		dispatch(showSignup(true));
	};

	const renderForgotPasswordModal = () => {
		const hideModal = () => setShowForgotPasswordModal(false);

		return <ForgotPasswordModal hideModal={hideModal} condition={showForgotPasswordModal} />;
	};

	const renderSignup = () => {
		return (
			<>
				<S.div_LoginSignup onClick={onSignupClick}>
					<S.p_LoginSignupTitle>{strings.noAccount}</S.p_LoginSignupTitle>
				</S.div_LoginSignup>
				{renderForgotPasswordModal()}
			</>
		);
	};

	const renderForgotPassword = () => {
		return (
			<>
				<S.div_LoginForgotPassword>
					<UIIconButton
						id={strings.buttonInputId}
						dark
						isLoading={isLoading}
						type={UI_ICON_BUTTON_WHITE_TYPE}
						text={strings.loginTitle}
						onClick={onLoginClick}
					/>
					<S.p_LoginForgotPasswordTitle onClick={() => setShowForgotPasswordModal(true)}>{strings.forgotPassword}</S.p_LoginForgotPasswordTitle>
				</S.div_LoginForgotPassword>
				{renderForgotPasswordModal()}
			</>
		);
	};

	return (
		<S.UIOverlayCard_LoginContainer title={strings.loginTitle} hideModal={hideModal}>
			<S.div_LoginInputEmail>
				<UIListInputRow
					id={strings.emailInputId}
					type={UI_LABELED_INPUT_TEXT_TYPE}
					value={email}
					listInputStyle={{ paddingLeft: 0, paddingRight: 0 }}
					placeholder={strings.emailInputPlaceholder}
					label={strings.emailInputLabel}
					onChangeValue={setEmail}
				/>
				{renderQuickLoginDev()}
			</S.div_LoginInputEmail>
			<UIListInputRow
				id={strings.passwordInputId}
				type={strings.passwordInputType}
				value={password}
				listInputStyle={{ paddingLeft: 0, paddingRight: 0 }}
				placeholder={strings.passwordInputPlaceholder}
				label={strings.passwordInputLabel}
				onKeyPress={onKeyPress}
				onChangeValue={setPassword}
			/>
			{renderForgotPassword()}
			{renderSignup()}
		</S.UIOverlayCard_LoginContainer>
	);
}
