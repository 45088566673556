import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './styles';
import moment from 'moment-timezone';
import JSONPretty from 'react-json-pretty';
import UIIcon from '../../../../../components/UI/UIIcon';
import { EmailActions, MutedRed } from '../../../../../assets/icons';
import UIIconButton from '../../../../../components/UI/UIIconButton';
import Queries from '../../../../../utils/API/Queries';
import { DBActions } from '../../../../../utils/Config/Actions/DBActions';
import PLImage from '../../../../../components/PL/PLImage';
import UIModal from '../../../../../components/UI/UIModal';
import UIOverlayCard, { OVERLAY_CLOSE_TYPES } from '../../../../../components/UI/UIOverlayCard';
import { USER_ROLE_AGENT, USER_ROLE_EDITOR, USER_ROLE_PHOTOGRAPHER } from '../../../../../utils/Config/Constants';
import { getSignedInUserSelector } from '../../../../../redux/selectors/CurrentUserSelectors';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { showOption } from '../../../../../redux/store/user/actions';
import * as strings from './strings';
import { UI_ICON_BUTTON_OUTLINE_TYPE } from '../../../../../components/UI/UIIconButton/config';

export default function UserActionRow({ action, shouldShowDay, queryActions }: UserActionRowProps) {
	const signedInUser = useSelector(getSignedInUserSelector);
	const history = useHistory();

	const [createAction] = useMutation(Queries.CREATE_ACTION);
	const [showDetails, setShowDetails] = useState<boolean>(false);
	const [showAddedImagesModal, setShowAddedImagesModal] = useState<boolean>(false);
	const [actionSelected, setActionSelected] = useState<ServiceActionType | undefined>(undefined);

	if (!action) return null;

	const getReadableStatus = (action) => {
		const { title } = action;
		if (!DBActions[title]) console.log('Code 87 (description not found)', action);
		return DBActions[title] ? DBActions[title].description : 'PL_ERROR 87';
	};

	const getSubjectLine = (action) => {
		const { title } = action;
		if (!DBActions[title]) console.log('Code 88 (subject not found)', action);
		return DBActions[title] ? DBActions[title].subject : 'PL_ERROR 88';
	};

	const onClickOption = (optionId) => {
		showOption(optionId);
	};

	const renderUpgradeDetails = (details) => {
		const { upgradeDetails } = action;
		if (upgradeDetails) {
			let upgradedServices = upgradeDetails.packages;

			let totalAmt = upgradeDetails['formattedTotal'];
			if (details.options) {
				let optionId = details.options[0];
				return (
					<div className='clickable actionContainer__upgrade--text' onClick={() => onClickOption(optionId)}>
						added {upgradedServices} service upgrade to the service. <span className='totalPrice'>Total:{totalAmt}</span>
					</div>
				);
			} else {
				return (
					<div className='actionContainer__upgrade--text'>
						added {upgradedServices} service upgrade to the service. <span className='totalPrice'>Total: {totalAmt}</span>
					</div>
				);
			}
		} else return null;
	};

	const resendEmail = async (action) => {
		if (action) {
			let variables = {
				title: action.title,
				service: action.service.id,
				actor: signedInUser.id,
				isResent: true,
				scheduleRequest: null
			};
			if (
				action.title === 'agentTimeChangeRequest' ||
				action.title === 'techTimeChangeRequestDeclined' ||
				action.title === 'techTimeChangeRequestConfirmation'
			) {
				variables.scheduleRequest = action.scheduleRequest.id;
			}
			const response = await createAction({ variables });
			if (response['data']) {
				await queryActions();
				toast.success(`Email ${action.title} resent!`);
			}
		}
	};

	const renderEmail = (params, action) => {
		const createdAt = moment(action.createdAt);
		let time = createdAt.format('h:mm a');
		let dateSent = createdAt.format('MMMM Do, YYYY');

		let toArray;
		if (typeof params.to === 'string') {
			toArray = [params.to];
		} else if (typeof params.to === 'object') {
			toArray = params.to;
		}
		return (
			<div>
				<div className='actionEmail'>
					<UIIcon iconSrc={EmailActions} className='actionEmail__icon' alt={'email'} />
					<div className='actionEmail__text'>
						<div className='actionEmail__text--subject'>
							{getSubjectLine(action)} {action.service.property.address}
						</div>
						{toArray?.map((recipient, recipientIndex) => (
							<div key={`${recipient}-${recipientIndex}`} className='actionEmail__text--email'>
								{recipient}
							</div>
						))}
					</div>
				</div>

				<p className='actionEmail__success'>
					This email was successfully {action.isResent ? <span className='actionEmail__success--resent'>{strings.resent}</span> : strings.delivered} at {time}{' '}
					on {dateSent}
				</p>
				<UIIconButton type={UI_ICON_BUTTON_OUTLINE_TYPE} text='resend this email' className='actionEmail__btn' onClick={() => resendEmail(action)} />
			</div>
		);
	};

	const onClickActorImage = (id, role) => {
		switch (role) {
			case USER_ROLE_AGENT:
			case USER_ROLE_PHOTOGRAPHER:
			case USER_ROLE_EDITOR:
				return history.push(`/${role}/` + id);
			default:
				return undefined;
		}
	};

	const getActorIconClassName = (role) => {
		switch (role) {
			case 'agent':
			case 'photographer':
			case 'editor':
				return 'actionContainer__actor--img clickable';
			default:
				return 'actionContainer__actor--img';
		}
	};

	const getTime = (date, type?) => {
		let actionDay = moment(date).startOf('day');
		let today = moment().startOf('day');
		let yesterday = moment().startOf('day').subtract(1, 'days');
		let weekOld = moment().startOf('day').subtract(7, 'days');
		if (type === 'main') {
			if (actionDay.isSame(today, 'day')) {
				return 'Today';
			} else if (actionDay.isSame(yesterday, 'day')) {
				return 'Yesterday';
			} else if (actionDay.isBetween(weekOld, today)) {
				return actionDay.format('dddd');
			} else {
				return actionDay.format('MMMM Do, YYYY');
			}
		} else if (type === 'sub') {
			if (actionDay.isSame(today, 'day')) {
				return moment(date).fromNow();
			} else {
				return moment(date).format('h:mm a');
			}
		} else return null;
	};

	const renderNotifications = () => {
		return (
			<div className='actionContainer__mute'>
				<UIIcon iconSrc={MutedRed} className='actionContainer__mute--icon' alt={'muted'} />
				<p className='actionContainer__mute--text'>{strings.notificationsMuted}</p>
			</div>
		);
	};

	const renderAddedImagesModal = () => {
		const hideModal = () => {
			setShowAddedImagesModal(false);
			setActionSelected(undefined);
		};
		return (
			<UIModal condition={showAddedImagesModal}>
				<UIOverlayCard hideModal={hideModal} title={'Images added'} closeType={OVERLAY_CLOSE_TYPES.back}>
					<div className='addedImagesModal'>
						{actionSelected
							? actionSelected['updatedServiceAttributes'].requestedImages.map((image) => (
									<PLImage key={image.id} alt='images added' className='addedImagesModal__images' src={image.thumbnailUrl} />
							  ))
							: null}
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	//Mark: Render functions
	const { createdAt, actor, title, upgradeDetails, notificationParams, notificationsMuted } = action;

	return (
		<div className='actionContainer'>
			<div className='actionSubContainer'>
				{shouldShowDay && (
					<div className='actionContainer__time'>
						<p className='actionContainer__time--main'>{getTime(createdAt, 'main')}</p>
					</div>
				)}
				<p className='actionContainer__time--sub' onClick={() => getTime(createdAt)}>
					{getTime(createdAt, 'sub')}
				</p>
				{action.isResent && <p className='actionContainer--resend'>{strings.resentEmail}</p>}
				{actor && (
					<div className='actionContainer__actor--top'>
						<img className={getActorIconClassName(actor.role)} src={actor.image} alt={actor.id} onClick={() => onClickActorImage(actor.id, actor.role)} />
						<div className='actionContainer__actor'>
							<p className='actionContainer__actor--action'>
								<span className='actionContainer__actor--name'>{actor ? actor.name : 'no actor '}</span> {getReadableStatus(action)}
							</p>
						</div>
					</div>
				)}
			</div>

			{title === 'createFiles' ? (
				<div className='actionContainer__images'>
					<div className='actionContainer__images--content'>
						{action['updatedServiceAttributes'].requestedImages.map((image) => (
							<PLImage key={image.thumbnailUrl} alt='images added' className='actionContainer__images--img' src={image.thumbnailUrl} />
						))}
					</div>
					{action['updatedServiceAttributes'].requestedImages.length > 4 ? (
						<div
							className='actionContainer__images--view'
							onClick={() => {
								setShowAddedImagesModal(true);
								setActionSelected(action);
							}}>
							view all {action['updatedServiceAttributes'].requestedImages.length} images
						</div>
					) : null}
				</div>
			) : null}
			{upgradeDetails ? <div className='actionContainer__upgrade'>{renderUpgradeDetails(upgradeDetails)}</div> : null}

			{notificationsMuted && renderNotifications()}

			{!notificationsMuted && notificationParams && <div className='actionContainer__email'>{renderEmail(notificationParams, action)}</div>}

			<div className='viewData'>
				{action['updatedServiceAttributes'] && <p onClick={() => setShowDetails(!showDetails)}>{showDetails ? 'hide raw data' : 'view raw data'}</p>}
				{showDetails && (
					<div className='detailOnService'>
						{action['updatedServiceAttributes'] &&
							Object.entries(action['updatedServiceAttributes'])
								.filter((attribute) => ['id', 'type'].indexOf(attribute[0]) === -1)
								.map(([key, value]) => (
									<p className='detailOnService__action' key={key}>
										{key}: <JSONPretty className='detailOnService__action--text' id='json-pretty' data={JSON.stringify(value)} />
									</p>
								))}
					</div>
				)}
			</div>

			<div className='actionContainer--separator' />

			{renderAddedImagesModal()}
		</div>
	);
}
