import React, { useEffect, useState } from 'react';
import './styles/mobile.scss';
import { OPTION_TYPES, SCREEN_TYPE, USER_ROLE_PHOTOGRAPHER } from '../../../../../../utils/Config/Constants';
import UIOptionGroup from '../../../../../../components/UI/UIOptionGroup';
import DiscountCalculator from '../../../../../../utils/Helpers/DiscountCalculator/DiscountCalculator';
import UIIconButton from '../../../../../../components/UI/UIIconButton';
import UICard from '../../../../../../components/UI/UICard/UICard';
import UIModal from '../../../../../../components/UI/UIModal';
import UserProfileModal from '../../../../../../components/modals/UserProfileModal';
import {
	ADMIN_TYPE,
	EDIT_TYPE,
	EDITING_UPGRADE_TYPE,
	TECHNICIAN_FLOOR_PLAN_TYPE,
	VIRTUAL_STAGING_TYPE
} from '../../../../../../utils/Helpers/PackageType/PackageType';
import BookingServiceProvider from '../BookingPreferredTechnician';
import UIOverlayCard, { OVERLAY_CLOSE_TYPES } from '../../../../../../components/UI/UIOverlayCard';
import UITeamMember from '../../../../../../components/UI/UITeamMember/UITeamMember';
import UILoadingPage from '../../../../../../components/UI/UILoadingPage/UILoadingPage';
import { BOOKING_OPTION_SELECT_TABS } from './config';
import { ZendeskAPI } from 'react-zendesk';
import { useSelector } from 'react-redux';
import { getIsUserAdminSelector, getIsUserAgentSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { useLazyQuery } from '@apollo/react-hooks';
import Queries from '../../../../../../utils/API/Queries';
import { toast } from 'react-toastify';
import { getBookingConfigSelector, getSelectedOptionsSelector } from '../../../../../../redux/selectors/BookingSelectors';
import { editingServicesAgent, editingServicesTechnician, mediaServicesInfo } from './strings';
import { getScreenTypeSelector } from '../../../../../../redux/selectors/ControlSelectors';
import { GET_BOOKING_OPTIONS_FROM_REGION } from '../../../../../../components/UI/UIBookingButton/queries';
import { getRegionFromZip } from '../../config';
import { useApolloClient } from 'react-apollo';
import { UI_ICON_BUTTON_OUTLINE_TYPE } from '../../../../../../components/UI/UIIconButton/config';
import MarketingTabBar from '../../../../../public/Marketing Sites/components/MarketingTabBar';

export default function BookingOptionsSelect({ updateBookingConfig, bookingClient }: BookingOptionsSelectProps) {
	const isUserAdmin = useSelector(getIsUserAdminSelector);
	const isUserAgent = useSelector(getIsUserAgentSelector);
	const bookingConfig = useSelector(getBookingConfigSelector);
	const screenType = useSelector(getScreenTypeSelector);
	const selectedOptions = useSelector(getSelectedOptionsSelector);

	const client = useApolloClient();

	const [selectedTab, setSelectedTab] = useState<PLSegment>(bookingConfig?.optionsSelectedTab);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [editingOptions, setEditingOptions] = useState<BookableOptionType[]>([]);
	const [mediaOptions, setMediaOptions] = useState<BookableOptionType[]>([]);
	const [showUserProfile, setShowUserProfile] = useState<boolean>(false);
	const [showPreferredServiceProviderModal, setShowPreferredServiceProviderModal] = useState<boolean>(false);

	let variables: any = {
		query: {
			op_or: [
				{
					type: {
						op_or: [OPTION_TYPES.virtualStaging, OPTION_TYPES.editUpgrade, OPTION_TYPES.edit]
					}
				},
				{
					packageType: {
						op_eq: TECHNICIAN_FLOOR_PLAN_TYPE.packageType
					}
				}
			]
		},
		limit: 100
	};
	const [getOptions, { data: optionsData }] = useLazyQuery(Queries.GET_OPTIONS, {
		variables,
		onError: (error) => {
			toast.error(error.message);
		}
	});

	const [getRegionOptions, { data: regionOptionsData }] = useLazyQuery(GET_BOOKING_OPTIONS_FROM_REGION, {
		variables: {
			region: bookingConfig?.region?.id,
			limit: 1000,
			query: {
				op_and: [
					{ type: { op_ne: OPTION_TYPES.virtualStaging } },
					{ type: { op_ne: OPTION_TYPES.editUpgrade } },
					{ type: { op_ne: OPTION_TYPES.edit } },
					{
						packageType: { op_ne: TECHNICIAN_FLOOR_PLAN_TYPE.packageType }
					}
				]
			}
		},
		onError: (error) => {
			toast.error(error.message);
		}
	});

	useEffect(() => {
		(async () => {
			if (regionOptionsData) {
				const fetchedOptions: OptionType[] = regionOptionsData['options'];
				setMediaOptions(setUpBookableOptions(fetchedOptions));
				updateBookingConfig('region', { ...bookingConfig?.region, options: fetchedOptions });
			}
		})();
	}, [regionOptionsData]);

	useEffect(() => {
		if (optionsData) {
			setEditingOptions(setUpBookableOptions(optionsData['options']));
		}
	}, [optionsData]);

	useEffect(() => {
		setSelectedTab(bookingConfig?.optionsSelectedTab);
	}, [bookingConfig?.optionsSelectedTab]);

	const filterOptionsByPreferredProvider = (options: BookableOptionType[]): BookableOptionType[] => {
		const { serviceProvider } = bookingConfig;

		let filteredOptions = options;
		if (serviceProvider && selectedTab?.value === BOOKING_OPTION_SELECT_TABS.mediaServices.value) {
			const { certificates } = serviceProvider;
			filteredOptions = options.filter((bookableOption) => {
				for (let requiredCertificate of bookableOption.option.photographerCertificatesRequired) {
					if (!certificates.includes(requiredCertificate)) {
						return false;
					}
				}
				return true;
			});
		}

		return filteredOptions;
	};

	const filterOptionGroupsByPreferredProviderCertificates = (optionGroups: PackageType[]) => {
		const { serviceProvider } = bookingConfig;

		if (serviceProvider && selectedTab?.value === BOOKING_OPTION_SELECT_TABS.mediaServices.value) {
			const { certificates } = serviceProvider;
			return optionGroups.filter((optionGroup) => {
				if (optionGroup.associatedCertification) {
					return certificates.includes(optionGroup.associatedCertification);
				}
				return false;
			});
		}
		return optionGroups || [];
	};

	const getTabs = (region?: RegionType) => {
		const mediaServicesTab = BOOKING_OPTION_SELECT_TABS.mediaServices;
		const editingServicesTab = BOOKING_OPTION_SELECT_TABS.editingServices;

		let tabs = [mediaServicesTab, editingServicesTab];
		if (!region?.id) {
			tabs = tabs.filter((tab) => tab !== mediaServicesTab);
		}
		if (bookingClient.role === USER_ROLE_PHOTOGRAPHER) {
			return tabs.reverse();
		}
		return tabs;
	};

	const getRequiredPackageTypesForEditingServices = () => {
		let requiredPackageTypes: PackageType[] = [];

		const virtualStagingOptions = editingOptions.filter((bookableOption) => bookableOption.option.type === OPTION_TYPES.virtualStaging);
		const editingUpgradeOptions = editingOptions.filter((bookableOption) => bookableOption.option.type === OPTION_TYPES.editUpgrade);
		const rawEditOptions = editingOptions.filter((bookableOption) => bookableOption.option.type === OPTION_TYPES.edit);
		const technicianFloorPlanOptions = editingOptions.filter((bookableOption) => bookableOption.option.packageType === 'technicianFloorPlan');

		if (editingUpgradeOptions.length > 0) {
			requiredPackageTypes.push(EDITING_UPGRADE_TYPE);
		}
		if (virtualStagingOptions.length > 0) {
			requiredPackageTypes.push(VIRTUAL_STAGING_TYPE);
		}
		if (technicianFloorPlanOptions.length > 0 && bookingClient.cubiCasaEmail) {
			requiredPackageTypes.push(TECHNICIAN_FLOOR_PLAN_TYPE);
		}
		if (rawEditOptions.length > 0 && !isUserAgent) {
			requiredPackageTypes.push(EDIT_TYPE);
		}
		if (isUserAdmin) {
			requiredPackageTypes.push(ADMIN_TYPE);
		}
		return requiredPackageTypes;
	};

	const queryRecentServiceProviders = () => {};

	const queryUpgradeOptions = async () => {
		await getOptions();
	};

	const setUpBookableOptions = (options: OptionType[]) => {
		const setUpOption = (option: OptionType): BookableOptionType => {
			if (bookingConfig?.selectedOptions[option.id]) {
				const selectedOption = bookingConfig?.selectedOptions[option.id];
				const newOption: BookableOptionType = {
					option,
					selectedQuantity: selectedOption.selectedQuantity
				};
				return { ...newOption, discountCalculator: new DiscountCalculator(newOption, bookingClient.promos) };
			}
			const newOption: BookableOptionType = {
				option,
				selectedQuantity: option.quantity
			};
			return { ...newOption, discountCalculator: new DiscountCalculator(newOption, bookingClient.promos) };
		};
		return options.map((option) => {
			let additionalOptions = option.additionalOptions;
			let additionalBookableOptions: BookableOptionType[] = [];
			if (additionalOptions) {
				for (let i = 0; i < additionalOptions.length; i++) {
					additionalBookableOptions.push(setUpOption(additionalOptions[i]));
				}
			}
			let newBookableOption = setUpOption(option);
			newBookableOption.additionalBookableOptions = additionalBookableOptions;
			return newBookableOption;
		});
	};

	useEffect(() => {
		(async () => {
			const region = await getRegionFromZip(bookingConfig.serviceLocation?.zipCode ?? '', client);
			updateBookingConfig('region', region);
			if (region) {
				await getRegionOptions();
			}
			setSelectedTab(getTabs(region)[0]);
			await queryUpgradeOptions();
			setSelectedTab(getTabs(region)[0]);
			await queryRecentServiceProviders();
			setIsLoading(false);
		})();
	}, []);

	if (!selectedTab || !bookingConfig) return null;

	if (isLoading) {
		return <UILoadingPage />;
	}

	const getInfoForSelectedSegment = () => {
		if (selectedTab === BOOKING_OPTION_SELECT_TABS.mediaServices) {
			return mediaServicesInfo;
		} else {
			if (bookingClient.role === USER_ROLE_PHOTOGRAPHER) {
				return editingServicesTechnician;
			}
			return editingServicesAgent;
		}
	};

	const renderCubiCasaHelper = () => {
		return (
			bookingClient.role === USER_ROLE_PHOTOGRAPHER &&
			!bookingClient.cubiCasaEmail && (
				<UICard
					title={'Floor plans not enabled'}
					description={'Add a CubiCasa account to your profile and offer floor plan services to your clients.'}
					style={{ margin: '8px 8px 16px 8px' }}>
					<UIIconButton style={{ margin: '12px 0 0 0' }} type={UI_ICON_BUTTON_OUTLINE_TYPE} text={'open profile'} onClick={() => setShowUserProfile(true)} />
				</UICard>
			)
		);
	};

	const renderPreferredServiceProviderModal = () => {
		const hideModal = () => setShowPreferredServiceProviderModal(false);
		return (
			<UIModal condition={showPreferredServiceProviderModal}>
				<UIOverlayCard closeType={OVERLAY_CLOSE_TYPES.close} title={'Preferred service provider'} hideModal={hideModal} cardStyle={{ minHeight: 360 }}>
					<BookingServiceProvider updateBookingConfig={updateBookingConfig} />
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderTabBar = () => {
		const segments = getTabs(bookingConfig.region);
		if (!segments || segments.length <= 1) return null;

		return (
			<div className='bookingManager--tabs'>
				<MarketingTabBar
					darkMode
					selectedTabIndex={segments.indexOf(selectedTab)}
					setSelectedTabIndex={(index) => {
						setSelectedTab(segments[index]);
					}}
					tabs={segments.map((segment) => segment.text || '')}
				/>
				{screenType !== SCREEN_TYPE.mobile && <p className='bookingManager--tabs-info'>{getInfoForSelectedSegment()}</p>}
				<div className='pl-separator' />
			</div>
		);
	};

	const renderUserProfileModal = () => {
		const hideModal = () => setShowUserProfile(false);
		return (
			<UIModal condition={showUserProfile}>
				<UserProfileModal hideModal={hideModal} />
			</UIModal>
		);
	};

	const renderServiceProvider = () => {
		if (selectedTab?.value === BOOKING_OPTION_SELECT_TABS.mediaServices.value) {
			if (bookingConfig?.serviceProvider) {
				return (
					<div onClick={() => setShowPreferredServiceProviderModal(true)}>
						<p className='bookingManager--title'>Preferred technician:</p>
						<UITeamMember user={bookingConfig?.serviceProvider} />
						<div className='pl-separator' />
						<p className='bookingManager--title' style={{ marginBottom: 24, fontSize: '12pt' }}>
							Your preferred technician is certified to perform the following services.
						</p>
					</div>
				);
			} else {
				return (
					<div className='bookingOptionsSelect__serviceProvider'>
						<p className='bookingManager--title'>Do you have a preferred technician?</p>
						<p className='bookingManager--description'>If you have a specific technician you would like to work with, please select them now.</p>
						<UIIconButton
							style={{ marginTop: 12 }}
							onClick={() => setShowPreferredServiceProviderModal(true)}
							type={UI_ICON_BUTTON_OUTLINE_TYPE}
							text={'Request a specific technician'}
						/>
						<div className='pl-separator' />
					</div>
				);
			}
		}
		return null;
	};

	const availableOptions: BookableOptionType[] = [...mediaOptions, ...editingOptions];

	const optionGroups =
		selectedTab?.value === BOOKING_OPTION_SELECT_TABS.mediaServices.value ? bookingConfig?.requiredPackageTypes : getRequiredPackageTypesForEditingServices();

	return (
		<div className='bookingOptionsSelect fade-in-div'>
			{renderTabBar()}
			<div className='bookingOptionsSelect__content' key={selectedTab.value}>
				<div className='bookingOptionsSelect__content--right'>
					{renderCubiCasaHelper()}
					{renderServiceProvider()}
					<UIOptionGroup
						optionGroups={filterOptionGroupsByPreferredProviderCertificates(optionGroups)}
						selectedOptions={selectedOptions}
						updateSelectedOptions={(selectedOptions: OptionType[]) => {
							updateBookingConfig('selectedOptions', selectedOptions);
						}}
						bookableOptions={filterOptionsByPreferredProvider(availableOptions)}
					/>
				</div>
			</div>
			{renderUserProfileModal()}
			{renderPreferredServiceProviderModal()}
		</div>
	);
}
