import React, { useState } from 'react';
import './styles';
import { ReactSortable, Sortable } from 'react-sortablejs';
import Measure from 'react-measure';
import { MultiDrag } from 'sortablejs';
import { SCREEN_TYPE } from '../../../utils/Config/Constants';
import { useSelector } from 'react-redux';
import { getScreenTypeSelector } from '../../../redux/selectors/ControlSelectors';

if (typeof window !== `undefined`) {
	Sortable.mount(new MultiDrag());
}

export const DIMENSIONS: PLSegment = { value: 'dimensions', label: 'With dimensions (measured)' };
export const NO_DIMENSIONS: PLSegment = { value: 'noDimensions', label: 'Without dimensions' };
export const TABS = {
	DIMENSIONS,
	NO_DIMENSIONS
};

export default function PLSortable({ data, onSortEnd, renderItem }: PLSortableProps) {
	const screenType = useSelector(getScreenTypeSelector);

	const [containerBounds, setContainerBounds] = useState<PLSortableContainerBoundsType>();

	return (
		<Measure
			bounds
			onResize={(contentRect: Object | any) => {
				setContainerBounds(contentRect.bounds);
			}}>
			{({ measureRef }: Function | any) => (
				<div ref={measureRef} className='sortableContainer'>
					<ReactSortable className='sortableHeader' animation={300} multiDrag={true} list={data} setList={onSortEnd}>
						{data.map((file) => {
							if (!containerBounds) return <div />;
							let itemsPerRow = Math.floor(containerBounds.width / (screenType === SCREEN_TYPE.mobile ? 128 : 256));
							let maxDimension = (containerBounds.width - 12) / itemsPerRow - 12;
							return renderItem(file, maxDimension);
						})}
					</ReactSortable>
				</div>
			)}
		</Measure>
	);
}
