export const filterGeocoderResults: (geocoderResults: google.maps.GeocoderResult[]) => google.maps.GeocoderResult[] = (geocoderResults) => {
	return (
		geocoderResults.filter((addressComponent) => {
			return (
				addressComponent.types.includes('premise') ||
				addressComponent.types.includes('route') ||
				addressComponent.types.includes('establishment') ||
				addressComponent.types.includes('street_address')
			);
		}) ?? []
	);
};
