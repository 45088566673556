import gql from 'graphql-tag';
import { serviceRequests } from '../../../../../../utils/API/Queries';

export const GET_SERVICE_WITH_REQUESTS = gql`
	query Service($id: String!) {
		service(id: $id) {
			id
			${serviceRequests.full}
		}
	}
`;
