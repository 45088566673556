import gql from 'graphql-tag';

export const GET_REQUIRED_ACTION_SERVICES = gql`
	query GetRequiredActionServices($query: JSON!) {
		services(query: $query) {
			id
			status
			listing {
				id
			}
			property {
				id
				address
			}
		}
	}
`;

export const GET_SCHEDULE_REQUESTS = gql`
	query GetScheduleRequests($query: JSON, $serviceId: String, $agentId: String) {
		scheduleRequests(query: $query, service: $serviceId, agent: $agentId) {
			id
			agent {
				id
				name
				email
				image
				phoneNumber
			}
			photographer {
				id
				name
				email
				image
				phoneNumber
			}
			service {
				id
				property {
					id
					timezone
					googlePlaceId
				}
			}
			requestedTimes
		}
	}
`;
