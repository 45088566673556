// Created by carsonaberle at 3/30/22
import React from 'react';
import * as S from './styles';

export default function VTError({ error }: VTErrorProps) {
	return (
		<S.div_VTErrorContainer>
			<S.p_VTErrorTitle>{error.message}</S.p_VTErrorTitle>
		</S.div_VTErrorContainer>
	);
}
