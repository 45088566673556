import React, { useEffect, useState } from 'react';
import './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getLastBookTimeFromSunset, fetchAndParseSunset } from '../../../utils/Helpers/BookingHelpers';
import TimePicker from 'rc-time-picker/lib/TimePicker';
import moment from 'moment-timezone';
import { PlusIcon } from '../../../assets/icons';
import { DayPickerSingleDateController } from 'react-dates';
import UIIconButton from '../../UI/UIIconButton';
import UIOverlayCard, { OVERLAY_CLOSE_TYPES } from '../../UI/UIOverlayCard';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../redux/selectors/CurrentUserSelectors';
import { toast } from 'react-toastify';
import { Moment } from 'moment';
import * as strings from './strings';
import { UI_ICON_BUTTON_FILL_TYPE, UI_ICON_BUTTON_OUTLINE_TYPE } from '../../UI/UIIconButton/config';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_SCHEDULE_REQUEST } from './queries';
import { getSignedInUser } from '../../../sagas/LoginSagas/actions';
import { useApolloClient } from 'react-apollo';

export default function TechnicianSuggestTimeChangeModal({ service, hideModal, onFinish, photographer }: TechnicianSuggestTimeChangeModalProps) {
	const isUserAdmin = useSelector(getIsUserAdminSelector);
	const signedInUser = useSelector(getSignedInUserSelector);

	const [dataArray, setDataArray] = useState<Moment[]>([]);
	const [lastBookHour, setLastBookHour] = useState<number>();
	const [isSuggestingNewTimes, setIsSuggestingNewTimes] = useState<boolean>(false);
	const [selectedTime, setSelectedTime] = useState<Moment>(moment(service.serviceDate));

	const [createScheduleRequest] = useMutation(CREATE_SCHEDULE_REQUEST);

	const dispatch = useDispatch();
	const client = useApolloClient();

	useEffect(() => {
		fetchAndParseSunset(
			moment(service.serviceDate),
			{
				lat: service.property.latitude,
				lng: service.property.longitude
			},
			service.region.timezone,
			(sunset) => {
				let lastBookHour = getLastBookTimeFromSunset(sunset, service.bookedOptions).hour();
				setLastBookHour(lastBookHour);
			}
		).then(() => {});
	});

	const isDateValid = (current) => {
		let now = moment();
		//If admin or this is booking an "edit" type service, allow any date.
		if (isUserAdmin || photographer) {
			return true;
		}
		let daysToAdd = 0;
		if (now.hour() > 16) {
			daysToAdd = 1;
		}
		return current.startOf('day').isAfter(now.add(daysToAdd, 'day'));
	};

	const disabledHours = () => {
		if (isUserAdmin) {
			return [];
		}

		let array = [0, 1, 2, 3, 4, 5, 6, 7];
		for (let i = (lastBookHour || 18) - 1; i < 24; i++) {
			array.push(i);
		}
		return array;
	};

	const addSuggestedDate = () => {
		if (selectedTime === moment(service.serviceDate)) {
			toast.info(strings.toastSelectDifferentTime);
		} else if (dataArray.includes(selectedTime)) {
			toast.info(strings.toastTimeAlreadySelected);
		} else {
			setDataArray([...dataArray, selectedTime]);
		}
	};

	const onDateChange = (value) => {
		if (!isDateValid(value)) {
			toast.info(strings.toastUnableToGuaranteeTime);
			return;
		}
		fetchAndParseSunset(
			value,
			{
				lat: service.region.latitude,
				lng: service.region.longitude
			},
			service.region.timezone,
			updateDateWithSunset
		).then(() => {});
	};

	const getUpdatedServiceDateWith = (service, bookedOptions, sunset) => {
		let isTwilight = bookedOptions.filter((option) => option.id === service.region.regionConfig.lightingOptions.twilight).length > 0;
		let additionalTime = isTwilight ? 0 : 60;
		let serviceEstimatedTime = bookedOptions.reduce((acc, option) => acc + option.payTime, 0);

		let bookTime = moment(sunset).subtract(serviceEstimatedTime + additionalTime, 'minutes');
		let bookMinute = 0;
		if (bookTime.minutes() > 30) {
			bookMinute = 30;
		}

		if (isTwilight) {
			bookTime.set({ hour: bookTime.hour(), minute: bookMinute, second: 0 });
		} else {
			bookTime.set({
				hour: moment(service.serviceDate).hour(),
				minute: bookMinute,
				second: 0
			});
		}
		return bookTime;
	};

	const updateDateWithSunset = (sunset) => {
		let lastBookHour = getLastBookTimeFromSunset(
			sunset,
			service.bookedOptions
				.filter((bookedOption) => bookedOption.option)
				.map((bookedOption) => {
					return { ...bookedOption, payTime: bookedOption.option.payTime };
				})
		).hour();
		setLastBookHour(lastBookHour);
		setSelectedTime(getUpdatedServiceDateWith(service, service.bookedOptions, sunset));
	};

	const suggestNewServiceTime = async () => {
		if (dataArray.length === 0) {
			toast.info('Please add at least one time');
			return;
		}
		if (!isSuggestingNewTimes) {
			setIsSuggestingNewTimes(true);
			const response = await createScheduleRequest({
				variables: {
					serviceId: service.id,
					requestedTimes: dataArray,
					agentId: service.agent.id,
					photographerId: signedInUser.id
				}
			}).catch((error) => {
				toast.error(error.message.replace('GraphQL error: ', ''));
				setIsSuggestingNewTimes(false);
			});
			if (!response?.data) return;
			const { createScheduleRequest: createdSR } = response['data'];
			if (createdSR) {
				dispatch(
					getSignedInUser(client, () => {
						onFinish();
						hideModal();

						toast.success('Successfully suggested new times!');
					})
				);
			} else {
				toast.error('Error suggesting new times!');
				setIsSuggestingNewTimes(false);
			}
			setIsSuggestingNewTimes(false);
		}
	};

	if (!lastBookHour) {
		return <p>{strings.loadingPlaceholder}</p>;
	}

	return (
		<UIOverlayCard
			hideModal={hideModal}
			className='technicianSuggest'
			title={strings.cardTitle}
			description={strings.cardDescription}
			closeType={OVERLAY_CLOSE_TYPES.close}>
			<div className='technicianSuggest__datetime'>
				{dataArray.length < 2 && (
					// @ts-ignore
					<DayPickerSingleDateController
						{...['autoFocus', 'initialDate', 'showInput']}
						isDayBlocked={(value) => !isDateValid(value)}
						onDateChange={onDateChange}
						enableOutsideDays={false}
						date={selectedTime}
						numberOfMonths={1}
						focused={true}
						initialVisibleMonth={() => moment()}
					/>
				)}
				<div className='technicianSuggest__add'>
					{dataArray.length < 2 && (
						<>
							<TimePicker
								showSecond={false}
								value={moment(selectedTime).tz(service.region.timezone)}
								onChange={setSelectedTime}
								disabledHours={disabledHours}
								hideDisabledOptions
								use12Hours
								allowEmpty={false}
								inputReadOnly={true}
								minuteStep={15}
							/>
							<UIIconButton
								icon={PlusIcon.white}
								className='technicianSuggest__add--icon'
								onClick={addSuggestedDate}
								text={strings.addDateTime}
								type={UI_ICON_BUTTON_OUTLINE_TYPE}
							/>
						</>
					)}

					{dataArray.map((date, index) => (
						<div key={index} className='technicianSuggest__dates' onClick={() => setDataArray(dataArray.filter((time) => time !== date))}>
							<div>
								<p className='technicianSuggest__dates--text'>Suggested time #{index + 1}:</p>
								<p className='technicianSuggest__dates--date'>{moment(date).tz(service.region.timezone).format('MM/DD/YYYY')}</p>
								<p className='technicianSuggest__dates--time'>{moment(date).tz(service.region.timezone).format('hh:mm a z')}</p>
							</div>
						</div>
					))}
				</div>
			</div>

			{dataArray.length > 0 && (
				<div className='technicianSuggest__buttons'>
					<UIIconButton
						dark
						isDisabled={dataArray.length === 0}
						type={UI_ICON_BUTTON_FILL_TYPE}
						text={strings.suggestTimesText}
						isLoading={isSuggestingNewTimes}
						onClick={suggestNewServiceTime}
					/>
				</div>
			)}
		</UIOverlayCard>
	);
}
