// Created by jarongallo at 6/18/21
import React, { useEffect, useState } from 'react';
import ServiceServiceRequests from '../../../IndividualService/ServiceServiceRequests/ServiceServiceRequests';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SERVICE_WITH_REQUESTS } from './queries';
import { clearSidebar } from '../../../../../../redux/store/control/actions';
import './styles';

export default function PLServiceFixRequests({ serviceId }: PLServiceFixRequestsProps) {
	const [service, setService] = useState<ServiceType>();

	const [getServiceRequests, { data: serviceData }] = useLazyQuery(GET_SERVICE_WITH_REQUESTS, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getServiceRequests();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setService(serviceData['service']);
			}
		})();
	}, [serviceData]);

	if (!service) return null;

	return (
		<div className='plServiceFixRequests'>
			<ServiceServiceRequests hideAddress serviceId={serviceId} clearSidebar={clearSidebar} />
		</div>
	);
}
