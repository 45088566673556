import gql from 'graphql-tag';

export const GET_OPTION_PHOTOGRAPHER = gql`
	query GetOption($id: String!) {
		option(id: $id) {
			id
			type
			photographerPay
			photographerFileCount
			traineePay
			payTime
			photographerInstructions
			duration
			photographerCertificatesRequired
		}
	}
`;
