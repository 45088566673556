import React, { Component } from 'react';
import './ui-maintenance-page.scss';
import { PLLogoOnly, PlugFemale, PlugMale } from '../../../assets/icons';
import PLImage from '../../PL/PLImage';

export default class PLMaintenancePage extends Component {
	constructor(props) {
		super(props);
	}

	//Mark: Render functions

	render() {
		return (
			<div className='uiMaintenancePage'>
				<div className='uiMaintenancePage__spin' />
				<PLImage src={PLLogoOnly.light} className='uiMaintenancePage__img' alt={'pineapple labs'} />
				<div className='uiMaintenancePage__logos'>
					<PLImage src={PlugMale} className='uiMaintenancePage__logos--male' alt={'plug'} />
					<PLImage src={PlugFemale} className='uiMaintenancePage__logos--female' alt={'plug female'} />
				</div>
				<div className='uiMaintenancePage__content'>
					<h2 className='uiMaintenancePage__content--heading'>Pineapple Labs is under maintenance</h2>
					<p className='uiMaintenancePage__content--text'>We’re preparing to serve you better!</p>
				</div>
			</div>
		);
	}
}
