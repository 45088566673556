export const completeSuccessString = 'Service completed!';
export const approveSuccessString = 'Service approved!';
export const approveSuccessProofingString = 'Approved service and set for proofing.';
export const markCompleteBanner = 'This service has not yet been marked complete';
export const markCompleteHoverText = 'This will mark the service as complete';
export const uploadRAWSText = 'Upload the raw files required to complete this service';
export const sendToTechString = 'send to technicians';
export const proofingRequired = 'click to begin proofing';
export const approveBannerString = 'approve and send to client';
export const approveBannerHoverString = 'Approve this service';
export const sendToTechKey = 'send to tech';
export const changeServiceMessage = 'Service sent to technicians!';
export const completeServiceBannerKey = 'complete service';
export const approveServiceBannerKey = 'approve service';
export const proofingKey = 'proofing';
export const proofingSuccessMessage = 'You have successfully proofed your photos';
