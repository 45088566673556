import { getPromoTypeText } from './PaymentHelper/PaymentHelper';
import { PROMOS } from '../Config/Constants';

export const getPromoDiscount = (promo) => {
	switch (promo.discountType) {
		case PROMOS.discountTypes.amount:
			if (!promo.discountAmount) {
				return 'Error';
			}
			if (promo.promoType === PROMOS.promoTypes.credit) {
				return `$${(promo.discountAmount / 100).toString()} credit`;
			}
			return `$${(promo.discountAmount / 100).toString()} off`;
		case PROMOS.discountTypes.percentage:
			if (!promo.discountPercentage) {
				return 'Error';
			}
			return `${promo.discountPercentage.toString()}% off`;
	}
};

export const getPromoUses = (promo) => {
	if (promo.maxUses > 1000) {
		return 'unlimited uses';
	} else if (promo.maxUses === 1) {
		return '1 use';
	} else {
		return promo.maxUses.toString() + ' uses';
	}
};

export const getPromoUsesRemaining = (promo) => {
	let usesLeft = promo.maxUses - promo.uses;
	if (usesLeft === 1) {
		return usesLeft.toString() + ' use left';
	}
	return usesLeft.toString() + ' uses left';
};

export const getPromosForOptions = (options, promos) => {
	if (promos === undefined) {
		promos = [];
	}
	promos = promos.filter((promo) => promo.isActive === true);
	let usedPromos = [];
	for (let promoIndex = 0; promoIndex < promos.length; promoIndex++) {
		let promo = promos[promoIndex];
		if (promo.promoType === PROMOS.promoTypes.credit) {
			usedPromos.push(promos[promoIndex].id);
		}
		if (promo.promoType === PROMOS.promoTypes.universalDiscount) {
			usedPromos.push(promos[promoIndex].id);
		} else {
			for (let optionsIndex = 0; optionsIndex < options.length; optionsIndex++) {
				if (promo.option !== undefined && options[optionsIndex] !== undefined && promo.option !== null && options[optionsIndex] !== null) {
					if (promo.option.id === options[optionsIndex].id) {
						usedPromos.push(promo.id);
					}
				}
			}
		}
	}
	return usedPromos;
};

export const getPromosString = (promos, newLine) => {
	let promosArray = [];

	for (let i = 0; i < promos.length; i++) {
		const promo = promos[i];
		if (promo.discountAmount > 0) {
			promosArray.push('$' + promos[i].discountAmount / 100 + ' - ' + getPromoTypeText(promos[i]));
		} else if (promo.discountPercentage) {
			promosArray.push(promos[i].discountPercentage + '% - ' + getPromoTypeText(promos[i]));
		}
	}
	if (newLine) {
		return promosArray.join('\r\n');
	}
	return promosArray.join(', ');
};
