// Created by jarongallo at 6/22/21
import React from 'react';
import './styles';
import { useSelector } from 'react-redux';
import { getPortalUrl, guessCurrentTimezone } from '../../../../../../../../utils/Helpers/AppHelper';
import moment from 'moment-timezone';
import { getPackageString } from '../../../../../../../../utils/Helpers/PaymentHelper/PaymentHelper';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { PHOTOSHOOT_TYPE_SERVICE } from '../../../../../../../../utils/Config/Constants';
import * as strings from './strings';
import UIHover from '../../../../../../../../components/UI/UIHover/UIHover';
import UIIcon from '../../../../../../../../components/UI/UIIcon';
import { CopyIcon } from '../../../../../../../../assets/icons';
import { getSignedInUserSelector } from '../../../../../../../../redux/selectors/CurrentUserSelectors';
import { toast } from 'react-toastify';

export default function CopyAgentMessage({ service, copyForUser, property, region }: PLServiceCopyMessageProps) {
	const currentUser = useSelector(getSignedInUserSelector);

	const shouldRenderComponentForService = () => {
		return service.type === PHOTOSHOOT_TYPE_SERVICE && copyForUser && copyForUser.id === currentUser.id;
	};

	if (!shouldRenderComponentForService()) {
		return null;
	}

	const timezone = property.timezone ? property.timezone : region ? region.timezone : guessCurrentTimezone();

	return (
		<UIHover text={strings.agentMessageCopyValue}>
			<CopyToClipboard
				text={`Hi this is ${currentUser.name} from Pineapple Labs. I just wanted to let you know that I am all set to capture your listing at ${
					service.address ? service.address : property.address
				} on ${moment(service.serviceDate).tz(timezone).format('MM/DD/YYYY')} at ${moment(service.serviceDate)
					.tz(timezone)
					.format('h:mm a z')}. Your service includes ${getPackageString(
					service.bookedOptions.filter((option) => option.packageType !== 'deduction'),
					false,
					false,
					true
				)}. If you would like to purchase additional images, please do so before the service is performed by using the following link. ${
					getPortalUrl() + '/listing/' + service.listing.id
				}`}
				onCopy={() => toast.success(strings.agentMessageCopySuccess)}>
				<div className='copyAgentMessage'>
					<UIIcon className='copyAgentMessage--icon' iconSrc={CopyIcon} alt={'copy'} />
					<p className='copyAgentMessage--text'>reach-out message</p>
				</div>
			</CopyToClipboard>
		</UIHover>
	);
}
