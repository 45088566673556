import React from 'react';
import './styles';
import { useSelector } from 'react-redux';
import { USER_ROLE_AGENT, USER_ROLE_PHOTOGRAPHER } from '../../../../utils/Config/Constants';
import UIBookingButton from '../../UIBookingButton';
import { getSignedInUserSelector } from '../../../../redux/selectors/CurrentUserSelectors';

export default function UIControllerHeaders() {
	const signedInUser = useSelector(getSignedInUserSelector);

	const getMenuItemsHeaderFromUser = () => {
		let components: JSX.Element[] = [];
		switch (signedInUser.role) {
			case USER_ROLE_AGENT:
				components.push(<UIBookingButton key={'booking button'} title={'create a new listing'} bookingClient={signedInUser} />);
		}
		return components;
	};

	const menuItems = getMenuItemsHeaderFromUser();

	if (menuItems.length === 0) return null;

	return <div className='uiControllerHeaders'>{menuItems}</div>;
}
