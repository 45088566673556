// Created by jarongallo at 6/17/21
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './styles';
import UIIcon from '../../../../../../components/UI/UIIcon';
import { PlusIcon } from '../../../../../../assets/icons';
import UIModal from '../../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../../components/UI/UIOverlayCard';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import UIIconButton from '../../../../../../components/UI/UIIconButton';
import * as strings from './strings';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_PL_SERVICE_CLAIM_SERVICE, UPDATE_SERVICE_CLAIM } from './queries';
import { toast } from 'react-toastify';
import { UI_ICON_BUTTON_CANCEL_TYPE } from '../../../../../../components/UI/UIIconButton/config';

export default function PLServiceClaimService({ serviceId }: PLServiceAdminClaimProps) {
	const isUserAdmin = useSelector(getIsUserAdminSelector);
	const signedInUser = useSelector(getSignedInUserSelector);
	const [updateServiceClaim] = useMutation(UPDATE_SERVICE_CLAIM);

	const [service, setService] = useState<ServiceType>();
	const [isReleasingClaim, setIsReleasingClaim] = useState(false);
	const [showClaimReleaseModal, setShowClaimReleaseModal] = useState(false);
	const [notesValue, setNotesValue] = useState<string>();

	const [getService, { data: serviceData }] = useLazyQuery(GET_PL_SERVICE_CLAIM_SERVICE, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getService();
		})();
	}, []);

	useEffect(() => {
		if (serviceData) {
			const responseService = serviceData['service'];
			setService(responseService);
			setNotesValue(responseService.regionalManagerNotes);
		}
	}, [serviceData]);

	if (!isUserAdmin && !service) return null;

	const onClaim = async () => {
		let variables = { serviceId: serviceId, customerServiceAgent: signedInUser.id };
		const response = await updateServiceClaim({ variables });

		const { updateService } = response['data'];
		if (updateService) {
			await getService();
			toast.success(strings.onClaimSuccess);
		}
	};

	const onClaimRelease = async () => {
		setIsReleasingClaim(true);

		let variables = { serviceId: serviceId, customerServiceAgent: null, regionalManagerNotes: notesValue ? notesValue : '' };
		const response = await updateServiceClaim({ variables });
		const { updateService } = response['data'];

		if (updateService) {
			await getService();
			setIsReleasingClaim(false);
			setShowClaimReleaseModal(false);
			toast.success(strings.onClaimReleaseSuccess);
		} else {
			setIsReleasingClaim(false);
		}
	};
	const renderClaimReleaseModal = () => {
		const hideModal = () => {
			setShowClaimReleaseModal(false);
		};

		return (
			<UIModal condition={showClaimReleaseModal}>
				<UIOverlayCard hideModal={hideModal} title={strings.onClaimReleaseModalTitle} description={strings.onClaimReleaseModalDescription}>
					<UIListInputRow type={'textField'} onChangeValue={setNotesValue} value={notesValue} placeholder={strings.onClaimReleaseModalPlaceholder} />
					<div className='plServiceClaimService__buttons'>
						<UIIconButton
							dark
							isLoading={isReleasingClaim}
							text={strings.onClaimReleaseModalButton}
							type={UI_ICON_BUTTON_CANCEL_TYPE}
							onClick={onClaimRelease}
						/>
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const openModal = () => {
		setShowClaimReleaseModal(true);
		setNotesValue(service?.regionalManagerNotes);
	};

	return (
		<div>
			{service?.customerServiceAgent && isUserAdmin ? (
				<div className='plServiceClaimService' onClick={openModal}>
					<div className='plServiceClaimService__iconContainer'>
						<img alt={strings.adminImageAlt} className='plServiceClaimService__iconContainer-icon' src={service.customerServiceAgent.image} />
						<p>{strings.removeAdminClaim}</p>
					</div>
				</div>
			) : (
				isUserAdmin && (
					<div className='plServiceClaimService' onClick={onClaim}>
						<div className='plServiceClaimService__iconContainer'>
							<UIIcon
								isLoading={isReleasingClaim}
								className='plServiceClaimService__iconContainer-icon'
								alt={strings.adminClaimAlt}
								iconSrc={PlusIcon.white}
							/>
							<p>{strings.adminClaim}</p>
						</div>
					</div>
				)
			)}
			{renderClaimReleaseModal()}
		</div>
	);
}
