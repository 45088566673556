import './mobile.scss';
import styled from 'styled-components';

export const div_Upload = styled.div`
	//DEFAULT
	justify-content: center;
	align-items: center;
	margin: 24px;
	padding: 24px;
	border: var(--white-32) solid 2px;
	border-radius: 12px;
`;

export const p_UploadTitle = styled.p`
	//DEFAULT
	font-size: 24pt;
	text-align: center;
`;

export const p_UploadDescription = styled.p`
	//DEFAULT
	margin-top: 24px;
	font-size: 13pt;
	text-align: center;
`;

export const a_UploadLink = styled.a`
	//DEFAULT
	padding: 18px;
	border: var(--white-32) solid 2px;
	border-radius: 24px;
	margin: 24px;
	text-align: center;
`;

export const a_DownloadLink = styled.a`
	//DEFAULT
	padding: 18px;
	border: var(--white-32) solid 2px;
	border-radius: 24px;
	margin: 24px;
	text-align: center;
`;