import gql from 'graphql-tag';

export const GET_PL_SERVICE_CLAIM_SERVICE = gql`
	query service($id: String!) {
		service(id: $id) {
			id
			regionalManagerNotes
			customerServiceAgent {
				id
				image
			}
		}
	}
`;

export const UPDATE_SERVICE_CLAIM = gql`
	mutation UpdateServiceClaim($serviceId: String!, $customerServiceAgent: String, $regionalManagerNotes: String) {
		updateService(id: $serviceId, customerServiceAgent: $customerServiceAgent, regionalManagerNotes: $regionalManagerNotes) {
			id
			customerServiceAgent {
				id
			}
			regionalManagerNotes
		}
	}
`;
