import React from 'react';
import './ui-payment-request-form.scss';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import UIButton from '../../../../../../../components/UI/UIButton/UIButton';
import UIIconButton from '../../../../../../../components/UI/UIIconButton';
import { convertCents } from '../../../../../../../utils/Helpers/StringHelpers';
import { UI_ICON_BUTTON_WHITE_TYPE } from '../../../../../../../components/UI/UIIconButton/config';

class UIPaymentRequestForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	//Mark: Component functions
	getCardElementOptions = () => {
		return {
			iconStyle: 'solid',
			style: {
				base: {
					iconColor: '#c4eae1',
					color: '#ffffff',
					fontWeight: 500,
					fontSize: '14pt',
					fontSmoothing: 'antialiased',
					':-webkit-autofill': { color: '#ffffff' },
					'::placeholder': { color: '#949494' }
				},
				invalid: {
					iconColor: '#ff6666aa',
					color: '#ff6666aa'
				}
			}
		};
	};

	onCardChange = (event) => {
		const { complete } = event;
		this.setState({ complete });
	};

	submitPayment = async () => {
		const { requestSaveCard } = this.state;
		const { updateIsPaying } = this.props;
		const { stripe, elements } = this.props;
		if (!stripe || !elements) {
			return;
		}
		updateIsPaying(true);

		let confirmationData = {
			payment_method: {
				card: elements.getElement(CardElement)
			}
		};
		if (requestSaveCard) {
			confirmationData['setup_future_usage'] = 'off_session';
		}

		const paymentIntent = await this.props.getPaymentIntent();
		if (!paymentIntent.clientSecret) {
			updateIsPaying(false);
			this.props.onPaymentError('');
		}

		const result = await stripe.confirmCardPayment(paymentIntent.clientSecret, confirmationData);

		if (result.error) {
			updateIsPaying(false);
			this.props.onPaymentError(result.error);
		} else {
			if (result.paymentIntent.status === 'succeeded') {
				await this.props.onPaymentSuccess(result.paymentIntent);
			}
		}
	};

	render() {
		const { amount, isPaying } = this.props;
		const { complete } = this.state;

		return (
			<div className='uiPaymentRequestForm'>
				<CardElement className='uiPaymentRequestForm__card' onChange={this.onCardChange} options={this.getCardElementOptions()} />
				{/*<UICheckbox*/}
				{/*   style={{ margin: "24px 0 0 0" }}*/}
				{/*   subBox*/}
				{/*   onChangeExpansion={() => this.setState({ requestSaveCard: !requestSaveCard })}*/}
				{/*   status={requestSaveCard ? UICheckbox.CHECKED : UICheckbox.UNCHECKED}*/}
				{/*   label={"Save card for future use"}*/}
				{/*/>*/}
				<UIIconButton
					dark
					isLoading={isPaying}
					isDisabled={!complete}
					disabledErrorMessage={'Please enter your card details.'}
					className='uiPaymentRequestForm__button'
					style={{ margin: '32px 0 0 0', width: '100%', borderRadius: 6 }}
					type={UI_ICON_BUTTON_WHITE_TYPE}
					onClick={this.submitPayment}
					text={`Pay ${convertCents(amount)}`}
				/>
			</div>
		);
	}
}

const InjectedCheckoutForm = (props) => {
	return (
		<ElementsConsumer>
			{({ elements, stripe }) => {
				if (stripe && elements) {
					return <UIPaymentRequestForm {...props} elements={elements} stripe={stripe} />;
				}
				return <div />;
			}}
		</ElementsConsumer>
	);
};

export default InjectedCheckoutForm;
