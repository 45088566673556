import React, { Component } from 'react';
import './service-notes.scss';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import { withApollo } from '@apollo/react-hoc';
import { EditIcon, LottieAnimations } from '../../../../../assets/icons';
import {
	PHOTOSHOOT_TYPE_SERVICE,
	USER_ROLE_ADMIN,
	USER_ROLE_AGENT,
	USER_ROLE_EDITING_MANAGER,
	USER_ROLE_EDITOR,
	USER_ROLE_PHOTOGRAPHER,
	USER_ROLE_REGIONAL_MANAGER,
	USER_ROLE_SALES
} from '../../../../../utils/Config/Constants';
import Queries from '../../../../../utils/API/Queries';
import UIModal from '../../../../../components/UI/UIModal';
import UIListInputRow from '../../../../../components/UI/UIListInputRow';
import UIIcon from '../../../../../components/UI/UIIcon';
import UIOverlayCard from '../../../../../components/UI/UIOverlayCard';
import UIIconButton from '../../../../../components/UI/UIIconButton';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../../../utils/Helpers/AppHelper';
import { CHILD_TYPE } from '../../../../../components/UI/UIListInputRow/config';
import UILabeledInput from '../../../../../components/UI/UILabeledInput';
import { UI_LABELED_INPUT_TEXT_FIELD } from '../../../../../components/UI/UILabeledInput/config';
import { UI_ICON_BUTTON_WHITE_TYPE } from '../../../../../components/UI/UIIconButton/config';
import { toast } from 'react-toastify';

class ServiceNotes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true
		};
	}

	//Mark: Component functions
	editNotesFor = (editNotesForRole) => {
		this.setState({ editNotesForRole, notesValue: this.getNotesValueFromRole(editNotesForRole) });
	};

	saveNotes = async () => {
		const { editNotesForRole, isSavingNotes, notesValue } = this.state;
		const { service } = this.props;
		const { id: serviceId } = service;

		if (!isSavingNotes) {
			this.setState({ isSavingNotes: true });
			let variables;
			switch (editNotesForRole) {
				case USER_ROLE_PHOTOGRAPHER:
					variables = {
						serviceId,
						photographerNotes: notesValue
					};
					break;
				case USER_ROLE_EDITING_MANAGER:
				case USER_ROLE_EDITOR:
					variables = {
						serviceId,
						editorNotes: notesValue
					};
					break;
				case USER_ROLE_AGENT:
					variables = {
						serviceId,
						agentNotes: notesValue
					};
					break;
				case USER_ROLE_ADMIN:
				case USER_ROLE_REGIONAL_MANAGER:
					variables = {
						serviceId,
						regionalManagerNotes: notesValue
					};
					break;
				default:
					variables = {};
			}
			const response = await this.props.client.mutate({
				mutation: Queries.UPDATE_SERVICE,
				variables: variables
			});
			const { updateService } = response['data'];
			if (updateService) {
				await this.props.updateService();
				this.setState({ isSavingNotes: false, editNotesForRole: undefined, notesValue: '' }, () => toast.success('Updated notes!'));
			}
		}
	};

	getTitleFromRole = (role) => {
		if (role === this.props.currentUser.user.role) {
			return 'Your notes';
		}
		let title = 'notes';
		switch (role) {
			case USER_ROLE_ADMIN:
			case USER_ROLE_REGIONAL_MANAGER:
				title = 'Admin notes';
				break;
			case USER_ROLE_PHOTOGRAPHER:
				title = 'Technician notes';
				break;
			case USER_ROLE_EDITING_MANAGER:
			case USER_ROLE_EDITOR:
				title = 'Editing notes';
				break;
			case USER_ROLE_SALES:
				title = 'Sales notes';
				break;
			case USER_ROLE_AGENT:
				title = 'Client notes';
				break;
		}
		return title;
	};

	getNotesValueFromRole = (role) => {
		const { service } = this.props;
		let value;
		switch (role) {
			case USER_ROLE_REGIONAL_MANAGER:
				value = service.regionalManagerNotes;
				break;
			case USER_ROLE_ADMIN:
				value = service.regionalManagerNotes;
				break;
			case USER_ROLE_PHOTOGRAPHER:
				value = service.photographerNotes;
				break;
			case USER_ROLE_EDITOR:
				value = service.editorNotes;
				break;
			case USER_ROLE_SALES:
				value = '';
				break;
			case USER_ROLE_AGENT:
				value = service.agentNotes;
				break;
			default:
				value = '';
				break;
		}
		if (!value) {
			value = '';
		}
		return value;
	};

	getEditPermissionRoles = () => {
		let permissionRoles = [];
		switch (this.props.currentUser.user.role) {
			case USER_ROLE_REGIONAL_MANAGER:
			case USER_ROLE_ADMIN:
				permissionRoles = [USER_ROLE_ADMIN, USER_ROLE_AGENT, USER_ROLE_PHOTOGRAPHER, USER_ROLE_EDITOR];
				break;
			case USER_ROLE_PHOTOGRAPHER:
				permissionRoles = [USER_ROLE_PHOTOGRAPHER];
				break;
			case USER_ROLE_EDITING_MANAGER:
			case USER_ROLE_EDITOR:
				permissionRoles = [USER_ROLE_EDITOR];
				break;
			case USER_ROLE_AGENT:
				permissionRoles = [USER_ROLE_AGENT];
				break;
			default:
				break;
		}
		return permissionRoles;
	};

	getPermissionRoles = () => {
		const { service } = this.props;
		let permissionRoles = [];
		switch (this.props.currentUser.user.role) {
			case USER_ROLE_REGIONAL_MANAGER:
			case USER_ROLE_ADMIN:
				permissionRoles = [USER_ROLE_ADMIN, USER_ROLE_AGENT, USER_ROLE_PHOTOGRAPHER, USER_ROLE_EDITOR];
				break;
			case USER_ROLE_PHOTOGRAPHER:
				if (service.type === PHOTOSHOOT_TYPE_SERVICE) {
					permissionRoles = [USER_ROLE_AGENT, USER_ROLE_PHOTOGRAPHER];
				} else {
					permissionRoles = [USER_ROLE_PHOTOGRAPHER];
				}
				break;
			case USER_ROLE_EDITING_MANAGER:
			case USER_ROLE_EDITOR:
				permissionRoles = [USER_ROLE_EDITOR];
				break;
			case USER_ROLE_AGENT:
				permissionRoles = [USER_ROLE_AGENT];
				break;
			default:
				break;
		}
		return permissionRoles;
	};

	renderNotes = () => {
		const { service } = this.props;

		const renderRightItems = (role) => {
			if (this.getEditPermissionRoles().includes(role)) {
				return (
					<div onClick={() => this.editNotesFor(role)}>
						<UIIcon iconSrc={EditIcon.white} className='notesMobileContainer--icon' alt={'edit'} />
					</div>
				);
			}
		};
		if (service) {
			return (
				<div className='notesMobileContainer__allNotes'>
					{this.getPermissionRoles().map((role) => (
						<UIListInputRow key={role} label={this.getTitleFromRole(role)} type={CHILD_TYPE} rightItems={renderRightItems(role)}>
							<p className='serviceNotes--notes'>{this.getNotesValueFromRole(role) ? this.getNotesValueFromRole(role) : 'No notes have been added.'}</p>
						</UIListInputRow>
					))}
				</div>
			);
		}
	};

	//Mark: Render functions
	render() {
		const { service } = this.props;
		if (!service) {
			return (
				<div key={service}>
					<Lottie
						options={{
							loop: true,
							autoplay: true,
							path: LottieAnimations.pineappleLogo,
							rendererSettings: {
								preserveAspectRatio: 'xMidYMid slice'
							}
						}}
						height={200}
						width={200}
						isStopped={false}
						isPaused={false}
					/>
				</div>
			);
		}
		return (
			<div className='notesMobileContainer'>
				{this.renderEditNotesModal()}
				{this.renderNotes()}
			</div>
		);
	}

	renderEditNotesModal = () => {
		const { editNotesForRole, isSavingNotes, notesValue } = this.state;
		const hideModal = () => this.setState({ editNotesForRole: undefined, notesValue: '' });
		return (
			<UIModal condition={editNotesForRole} hideModal={hideModal}>
				<UIOverlayCard hideModal={hideModal} title={'Edit notes'} cardStyle={{ minHeight: '500px', maxHeight: '100%' }}>
					<UILabeledInput
						type={UI_LABELED_INPUT_TEXT_FIELD}
						style={{ minHeight: '500px', padding: 24 }}
						onChangeValue={(notesValue) => this.setState({ notesValue })}
						value={notesValue}
						placeholder={'Notes...'}
					/>
					<div className='uiOverlayCard__buttons' style={{ marginTop: 24 }}>
						<UIIconButton dark type={UI_ICON_BUTTON_WHITE_TYPE} isLoading={isSavingNotes} text={'Save notes'} onClick={this.saveNotes} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};
}

export default withApollo(connect(commonMapStateToProps, commonMapDispatchToProps)(ServiceNotes));
