import initialState from '../initialState';
import * as types from './types';

function insertItemToBeginning(array, client) {
	let newArray = array.slice();
	newArray.splice(0, 0, client);
	return newArray;
}

function removeItemFrom(array) {
	let newArray = array.slice();
	newArray.splice(0, 1);
	return newArray;
}

export default function apiReducer(state = initialState.api, action) {
	if (action.type === types.ADD_CLIENT) {
		return {
			...state,
			clients: insertItemToBeginning(state.clients, action.client)
		};
	} else if (action.type === types.REMOVE_FIRST_CLIENT) {
		return {
			...state,
			clients: removeItemFrom(state.clients)
		};
	} else {
		return state;
	}
}
