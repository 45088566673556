export const DBActions = {
	['serviceUpdate']: {
		title: 'serviceUpdate',
		description: ' updated this service. '
	},
	['agentFirstBooking']: {
		title: 'agentFirstBooking',
		description: ' booked their first service. ',
		subject: ' booked their first service '
	},
	['serviceIsLive']: {
		title: 'serviceIsLive',
		description: ' made this service status live. '
	},
	['readyForEdit']: {
		title: 'readyForEdit',
		description: ' made this service status ready for editing. '
	},
	['readyForProofing']: {
		title: 'readyForProofing',
		description: ` made this service status "ready for proofing". `
	},
	['photographerClaimed']: {
		title: 'photographerClaimed',
		description: ' accepted this photoshoot. '
	},
	['serviceCreated']: {
		title: 'serviceCreated',
		description: ' created this service. '
	},
	['readyForEditAlternate']: {
		title: 'readyForEditAlternate',
		description: ' made this service status ready for edit alternate. '
	},
	['createFiles']: {
		title: 'createFiles',
		description: ' added files to the service. '
	},
	['serviceUpdated']: {
		title: 'serviceUpdated',
		description: ' updated the service. '
	},
	['updatingService']: {
		title: 'updatingService',
		description: ' updated the service. '
	},
	['agentTimeChangeRequest']: {
		title: 'agentTimeChangeRequest',
		description: ' time changed requested. ',
		subject: 'Service Time Change Requested for - '
	},
	['agentContentReady']: {
		title: 'agentContentReady',
		description: ' made the content ready for agent. ',
		subject: 'Your content is ready! - '
	},
	['proofService']: {
		title: 'proofService',
		description: ' set this service to “awaiting proofing” status. '
	},
	['techEditServiceCreated']: {
		title: 'techEditServiceCreated',
		description: ` created a "edit" type service. `,
		subject: 'Pineapple Labs | Editing service confirmation - '
	},
	['editorAssignedReadyForEdit']: {
		title: 'editorAssignedReadyForEdit',
		description: ` editor assigned to service. `,
		subject: 'Files Ready for Edit - '
	},
	['agentBookingRequest']: {
		title: 'agentBookingRequest',
		description: ` agent has requested booking. `,
		subject: 'We have received your request for photography - '
	},
	['bookingRequestWithPreferredTech']: {
		title: 'bookingRequestWithPreferredTech',
		description: ` agent has requested booking with a preferred technician. `,
		subject: 'A Pineapple client has requested your services - '
	},
	['techClaimedService']: {
		title: 'techClaimedService',
		description: ` technician claimed service. `,
		subject: 'Pineapple Labs: Service accepted - '
	},
	['agentServiceAccepted']: {
		title: 'agentServiceAccepted',
		description: ` technician claimed a service request. `,
		subject: 'Pineapple Labs Booking Confirmation - '
	},
	['imagesReadyForProofing']: {
		title: 'imagesReadyForProofing',
		description: ` set this service to "ready for proofing" status. `,
		subject: 'Your Photos are Ready for Proofing! - '
	},
	['serviceUpdatedConfirmation']: {
		title: 'serviceUpdatedConfirmation',
		description: ` updated service. `,
		subject: 'Your service has been updated - '
	},
	['techTimeChangeRequestConfirmation']: {
		title: 'techTimeChangeRequestConfirmation',
		description: ` technician has submitted a time/date change to agent. `,
		subject: 'Time change request submitted! - '
	},
	['techServiceUpdatedDetails']: {
		title: 'techServiceUpdatedDetails',
		description: ` technician has been emailed about the upgraded details. `,
		subject: 'Upcoming Shoot Updated - '
	},
	['techTimeChangeDeclined']: {
		title: 'techTimeChangeDeclined',
		description: ` agent has declined time request change. `,
		subject: 'Time change request declined - '
	},
	['agentFloorPlansReady']: {
		title: 'agentFloorPlansReady',
		description: ` floor plans are ready. `
	},
	['editorRemindToUpload']: {
		title: 'editorRemindToUpload',
		description: ` reminder email sent for editor to upload. `
	},
	['preferredTechRemindedToAcceptOrDecline']: {
		title: 'preferredTechRemindedToAcceptOrDecline',
		description: ` reminder email sent to technician to accept/decline service request. `
	},
	['createListing']: {
		title: 'createListing',
		description: ` listing has been created. `
	},
	['creatingService']: {
		title: 'creatingService',
		description: ` a service has been created. `
	},
	['deleteAssociatedScheduleRequests']: {
		title: 'deleteAssociatedScheduleRequests',
		description: ` technician has claimed a service and service requests have been deleted. `
	},
	['deleteListing']: {
		title: 'deleteListing',
		description: ` listing have been deleted. `
	},
	['deleteService']: {
		title: 'deleteService',
		description: ` service have been deleted. `
	},
	['listingCreated']: {
		title: 'listingCreated',
		description: ` listing has been created. `
	},
	['listingUpdate']: {
		title: 'listingUpdate',
		description: ` listing has been updated. `
	},
	['readyForEditorsToClaim']: {
		title: 'readyForEditorsToClaim',
		description: ` editors can now claim this service `
	},
	['serviceUpgraded']: {
		title: 'serviceUpgraded',
		description: ` service has bee upgraded `
	},
	['upgradeRequest']: {
		title: 'upgradeRequest',
		description: ` upgrade has been requested for service `
	}
};
