import React, { useEffect, useRef, useState } from 'react';
import './styles';
import { removeCountryFromAddress } from '../../../utils/Helpers/StringHelpers';
import Geosuggest, { Suggest } from 'react-geosuggest';
import { MapIcon } from '../../../assets/icons';
import UIModal from '../UIModal';
import BookingMap from '../../../pages/portal/global/BookingManager/components/BookingMap';
import PLImage from '../../PL/PLImage';
import { generateLocationFromSuggest } from '../../../utils/Helpers/ServiceHelper';
import { UIAddressSelectProps } from './types';
import Lottie from 'react-lottie';
import { PLLottieProps } from '../../../pages/portal/global/IndividualService/FullscreenFiles/interfaces';
import { LOCATION_KEY } from '../../../utils/Config/Constants';

export default function UIAddressSelect({
	updateLocation,
	style,
	containerClassName,
	googlePlaceId,
	isLoading,
	setIsLoading,
	hideMap,
	serviceLocation: inputServiceLocation
}: UIAddressSelectProps) {
	const [serviceLocation, updateServiceLocation] = useState(inputServiceLocation);
	const [showMapBookingComponent, setShowMapBookingComponent] = useState<boolean>(false);
	const [myLocation, setMyLocation] = useState<google.maps.LatLngLiteral | number>(JSON.parse(localStorage.getItem(LOCATION_KEY)!));

	const geosuggestEl = useRef(null);

	useEffect(() => {
		getMyLocation();
	}, [googlePlaceId]);

	const showMapComponent = () => {
		setShowMapBookingComponent(true);
	};

	const updateLocationUI = (serviceLocation) => {
		updateServiceLocation(serviceLocation);
		updateLocation(serviceLocation);
	};

	const renderMapBookingComponent = () => {
		const hideModal = () => setShowMapBookingComponent(false);

		return (
			<UIModal condition={showMapBookingComponent}>
				<BookingMap
					myLocation={myLocation}
					hideMap={hideModal}
					updateLocation={updateLocationUI}
					onSetLocation={updateServiceLocation}
					serviceLocation={serviceLocation}
				/>
			</UIModal>
		);
	};

	const getMyLocation = () => {
		const currentLocation = window.navigator && window.navigator.geolocation;
		if (currentLocation) {
			currentLocation.getCurrentPosition((position) => {
				const myLocation = { lng: position.coords.longitude, lat: position.coords.latitude };
				setMyLocation(myLocation);
				localStorage.setItem(LOCATION_KEY, JSON.stringify(myLocation));
			});
		}
	};

	const handleAddressSubmit = (address: Suggest) => {
		const serviceLocation: ServiceLocation = generateLocationFromSuggest(address);
		updateLocationUI(serviceLocation);
	};

	const onSelectAddress = (address: Suggest) => {
		if (setIsLoading) setIsLoading(true);
		if (address && geosuggestEl && geosuggestEl.current) {
			// @ts-ignore
			geosuggestEl.current.blur();
			// @ts-ignore
			geosuggestEl.current.clear();
			handleAddressSubmit(address);
		}
	};

	// @ts-ignore
	const lottieProps: PLLottieProps = {
		loop: true,
		autoplay: true,
		path: 'https://res.cloudinary.com/pineapple-labs/raw/upload/v1592767290/portal-assets/loading-circle_kxpoc7.json',
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	const shouldSkipSuggest = (suggest) => {
		if (suggest.types.includes('locality')) {
			return true;
		} else if (suggest.types.includes('route')) {
			return true;
		} else if (suggest.types.includes('natural_feature')) {
			//For geographically significant areas like lakes, pods, hills, mountains
			return false;
		} else if (suggest.types.includes('point_of_interest')) {
			//For significant areas like parks and monuments
			return false;
		} else if (suggest.types.includes('country')) {
			return true;
		} else if (!suggest.types.includes('geocode')) {
			return true;
		}
		return false;
	};

	return (
		<div style={{ ...style, margin: '0 12px', position: 'relative' }} className={containerClassName}>
			<div className='uiAddressSelect__empty'>
				{isLoading && (
					<div className='uiAddressSelect__loading'>
						<Lottie options={lottieProps} height={24} width={24} isStopped={false} isPaused={false} />
					</div>
				)}
				<Geosuggest
					ref={geosuggestEl}
					getSuggestLabel={(suggest) => removeCountryFromAddress(suggest.description)}
					inputClassName={`label_input_input uiAddressSelect--input`}
					suggestsClassName='autocomplete-container-book'
					location={new google.maps.LatLng(myLocation)}
					radius={100}
					skipSuggest={shouldSkipSuggest}
					suggestItemClassName='autocompleteItem-book'
					placeholder={serviceLocation?.latitude ? 'Search new address or location...' : 'Search an address or location...'}
					types={['geocode', 'establishment']}
					onSuggestSelect={onSelectAddress}
				/>
				{!hideMap && <PLImage className='uiAddressSelect--map' src={MapIcon.white} onClick={showMapComponent} alt={'map'} />}
			</div>
			{renderMapBookingComponent()}
		</div>
	);
}
