import gql from 'graphql-tag';

export const GET_SERVICE_INCLUDES_DRONE = gql`
	query Service($id: String!) {
		service(id: $id) {
			id
			isDroneService
		}
	}
`;

export const UPDATE_SERVICE_INCLUDES_DRONE = gql`
	mutation UpdateServiceSurge($serviceId: String!, $isDroneService: Boolean!) {
		updateService(id: $serviceId, isDroneService: $isDroneService) {
			id
			isDroneService
		}
	}
`;
