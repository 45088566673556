import gql from 'graphql-tag';

export const GET_SERVICE_ADDRESS = gql`
	query GetServiceAddressMultiFileSelect($serviceId: String!) {
		service(id: $serviceId) {
			id
			address
			property {
				id
				streetAddress
				address
			}
		}
	}
`;
