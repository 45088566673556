// noinspection SpellCheckingInspection

import React, { useState } from 'react';
import './styles/mobile.scss';
import { useDispatch, useSelector } from 'react-redux';
import UILabeledInput from '../../UI/UILabeledInput';
import PLMap from '../../PL/PLMap';
import Queries from '../../../utils/API/Queries';
import UIOverlayCard from '../../UI/UIOverlayCard';
import { UI_LABELED_INPUT_DROPDOWN_TYPE } from '../../UI/UILabeledInput/config';
import { DARK_STYLE, SOURCES } from '../../PL/PLMap/config';
import { useApolloClient } from 'react-apollo';
import { toast } from 'react-toastify';
import { getIsUserAdminSelector, getSelectedRegionSelector } from '../../../redux/selectors/CurrentUserSelectors';
import { setSelectedRegion } from '../../../redux/store/user/actions';

let ReactMapboxGl, Layer, Feature, Popup, Source;
if (process.env.BUILD_TARGET === 'client') {
	ReactMapboxGl = require('react-mapbox-gl');
	Layer = ReactMapboxGl.Layer;
	Feature = ReactMapboxGl.Feature;
	Popup = ReactMapboxGl.Popup;
	Source = ReactMapboxGl.Source;
}

export default function TechnicianCoverageAreaModal({ technician, hideModal, regions }: TechnicianCoverageAreaModalProps) {
	let flyTo;

	const selectedRegion = useSelector(getSelectedRegionSelector);
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const [isUpdatingTechnician, setIsUpdatingTechnician] = useState<boolean>(false);

	const client = useApolloClient();
	const dispatch = useDispatch();

	const updateTechnicianKV = async (key, value) => {
		await client
			.mutate({
				mutation: Queries.UPDATE_USER_MUTATION,
				variables: { id: technician.id, [key]: value }
			})
			.catch((error) => {
				setIsUpdatingTechnician(false);
				toast.error(error.message);
			});
	};

	const addZipCode = async (zipCode) => {
		let selectedZips = technician['serviceAreas'];
		if (!selectedZips) {
			selectedZips = [];
		}
		selectedZips.push(zipCode);
		await updateTechnicianKV('serviceAreas', selectedZips);
		setIsUpdatingTechnician(false);
	};

	const removeZipCode = async (zipCode) => {
		await updateTechnicianKV(
			'serviceAreas',
			technician['serviceAreas'].filter((e) => e !== zipCode)
		);
		setIsUpdatingTechnician(false);
	};

	const onMapClick = async (map, evt) => {
		const features = map.queryRenderedFeatures(evt.point).filter((feature) => feature.source === 'zipSource');

		if (features.length > 0) {
			let feature = features[0];

			const { GEOID10: GEOID } = feature.properties;
			if (!selectedRegion?.zipCodes.includes(GEOID)) {
				toast.info('This zipcode is not in our coverage area');
			} else {
				if (!isUpdatingTechnician) {
					if (features.filter((feature) => feature.layer.id === 'layer_id').length > 0) {
						setIsUpdatingTechnician(true);
						let zips = map.getFilter('layer_id');

						const { GEOID10 } = feature.properties;
						let index = zips.indexOf(GEOID10);
						if (index > -1) {
							zips.splice(index, 1);
						}
						map.setFilter('layer_id', zips);
						await removeZipCode(GEOID);
						toast.info('Removed ' + GEOID + '!');
					} else if (features.filter((feature) => feature.layer.id === 'unselectedLayerId').length > 0) {
						setIsUpdatingTechnician(true);
						let zips = map.getFilter('layer_id');
						await addZipCode(GEOID);
						zips.push(GEOID);
						map.setFilter('layer_id', zips);
						toast.success('Added ' + GEOID + '!');
					}
				}
			}
		}
	};

	const { serviceAreas } = technician;
	let unselectedZips;

	if (serviceAreas) {
		unselectedZips = selectedRegion?.zipCodes.filter((zipCode) => serviceAreas.indexOf(zipCode) === -1);
	} else {
		unselectedZips = selectedRegion?.zipCodes;
	}
	let technicianZipsFilter = ['in', 'GEOID10'];
	let technicianUnselectedZipsFilter = ['in', 'GEOID10', unselectedZips].flat();
	let regionZipsFilter = ['in', 'GEOID10'];

	technicianZipsFilter.push.apply(technicianZipsFilter, serviceAreas);
	regionZipsFilter.push.apply(regionZipsFilter, selectedRegion?.zipCodes);
	const title = isUserAdmin ? 'Technician coverage area zip codes' : 'Your coverage area zip codes';

	const renderMap = () => {
		if (!selectedRegion) return null;
		return (
			<PLMap
				style={DARK_STYLE}
				center={[selectedRegion?.longitude, selectedRegion?.latitude]}
				zoom={[8]}
				onClick={onMapClick}
				noLocation
				setFlyTo={(plMapFlyTo) => (flyTo = plMapFlyTo)}
				containerStyle={{
					height: '100%',
					width: '100%',
					borderRadius: 16
				}}>
				<Source id='zipSource' tileJsonSource={SOURCES.US_ZIP_CODES} />
				<Layer
					type='fill'
					id='layer_id'
					filter={technicianZipsFilter}
					paint={{
						'fill-color': '#5cb85c',
						'fill-opacity': 0.25
					}}
					before={'road-label'}
					sourceId={'zipSource'}
					sourceLayer='zcta5-8785yf'
				/>
				<Layer
					type='line'
					id='outlineLayerId'
					paint={{
						'line-color': '#FFFFFF',
						'line-opacity': 0.25,
						'line-width': 1
					}}
					filter={regionZipsFilter}
					minZoom={6}
					before={'layer_id'}
					sourceId={'zipSource'}
					sourceLayer='zcta5-8785yf'
				/>
				<Layer
					type='fill'
					id='unselected_zips'
					filter={technicianUnselectedZipsFilter}
					paint={{
						'fill-color': '#FF1a1a',
						'fill-opacity': 0.15
					}}
					before={'road-label'}
					sourceId={'zipSource'}
					sourceLayer='zcta5-8785yf'
				/>
				<Layer
					type='fill'
					id='unselectedLayerId'
					paint={{
						'fill-color': '#FFFFFF',
						'fill-opacity': 0
					}}
					minZoom={6}
					before={'layer_id'}
					sourceId={'zipSource'}
					sourceLayer='zcta5-8785yf'
				/>
			</PLMap>
		);
	};
	return (
		<UIOverlayCard hideModal={hideModal} title={title} className='fade-in-div zipSelectModal'>
			<div className='zipSelectModal__top'>
				<UILabeledInput
					filterKey={'id'}
					type={UI_LABELED_INPUT_DROPDOWN_TYPE}
					options={regions?.map((region) => {
						return { value: region, label: region.name };
					})}
					containerStyle={{ margin: '8px 0' }}
					onChangeDropdownValue={(region) => {
						if (!region) return;
						const selectedRegion = region.value;
						dispatch(setSelectedRegion(selectedRegion));
						if (flyTo) {
							flyTo([selectedRegion.longitude, selectedRegion.latitude]);
						}
					}}
					value={selectedRegion}
					placeholder={'select a region...'}
				/>
			</div>
			{renderMap()}
		</UIOverlayCard>
	);
}
