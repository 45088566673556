export const requestedTechSearchOverlayTitle = 'Add a preferred service provider';
export const requestedTechSearchEmptySet = 'No service providers found with that name';
export const requestedTechSearchLabel = 'Who will be the preferred service provider?';
export const requestedTechSearchPlaceholder = 'search service providers...';
export const requestedTechAddButton = 'add';
export const requestTechButton = 'add a preferred service provider';
export const offerButton = 'remove & offer to others';
export const removeSPButton = 'remove';
export const offerSuccessMessage = 'This service has been offered to all certified service providers.';
export const removeSPSuccessMessage = 'The preferred service provider has been removed.';
export const removeRequestedButton = 'remove requested service provider';
export const removeRequestedModalTitle = 'Remove request for service provider?';
export const removeRequestedModalDescription =
	'Are you sure you want to remove this preferred service provider on this service? In order for a service provider to pick it up, they must be the preferred service provider or the service status needs changed to "Scheduling technician" so other certified providers can pick it up.';
export const removeRequestedModalButton = 'remove preferred service provider';
export const removeRequestedModalSuccess = 'Successfully offered service to all certified service providers!';
