// Created by carsonaberle at 11/23/21
import React from 'react';
import './styles';
import UIModal from '../../UI/UIModal';
import UIOverlayCard from '../../UI/UIOverlayCard';
import * as strings from './strings';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_SERVICE_STAGE } from './queries';
import { PHOTOSHOOT_TYPE_SERVICE } from '../../../utils/Config/Constants';
import { adminStagesList, photoshootStatusList, rawEditStatusList } from '../../../utils/Config/GeneralConfig';

export default function ChangeServiceStageModal({ service, condition, hideModal, onComplete }: ChangeServiceStageModalProps) {
	const [mutateServiceStage] = useMutation(UPDATE_SERVICE_STAGE, {
		onError: (error) => toast.error(error.message.replace('GraphQL error: ', ''))
	});

	const changeServiceStage = async (status) => {
		let variables = {
			serviceId: service.id,
			status
		};

		const response = await mutateServiceStage({ variables });
		if (response?.data?.updateService) {
			await onComplete();
			toast.success(strings.onChangeStageString);
		}
	};

	const renderStage = (stage: StageOption, additionalItemClass?: string) => {
		const isSelected = service.status === stage.value ? 'changeServiceStageModal--item-selected' : '';
		const selectedItemClassName = isSelected ? 'changeServiceStageModal--item-selected' : '';
		const selectedLabelClassName = isSelected ? 'changeServiceStageModal--item-label-selected' : '';

		return (
			<div
				key={stage.value}
				className={`changeServiceStageModal--item ${additionalItemClass} ${selectedItemClassName}`}
				onClick={() => changeServiceStage(stage.value)}>
				<p className={`changeServiceStageModal--item-label ${selectedLabelClassName}`}>{stage.label}</p>
				<p className='changeServiceStageModal--item-description'>{stage.description}</p>
			</div>
		);
	};

	const renderStages = () => {
		const stages = service.type === PHOTOSHOOT_TYPE_SERVICE ? photoshootStatusList : rawEditStatusList;

		return (
			<div className='changeServiceStageModal'>
				{stages.map((stage) => renderStage(stage))}
				{adminStagesList.map((stage) => renderStage(stage, 'changeServiceStageModal--item-admin'))}
			</div>
		);
	};

	return (
		<UIModal condition={condition}>
			<UIOverlayCard hideModal={hideModal} title={strings.title}>
				{renderStages()}
			</UIOverlayCard>
		</UIModal>
	);
}
