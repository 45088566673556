export const nameValidatorMessage = 'Please enter a name.';
export const emailValidatorlengthMessage = 'Please enter an email.';
export const emailValidatorMessage = 'Please enter a valid email.';
export const numberValidatorMessage = 'Please enter your phone number.';
export const passwordValidatorMessage = 'Please enter a password 8 characters or more.';
export const termsOfUseCheckboxValidator = 'In order to use Pineapple Labs, you must agree to our Terms of Use and Privacy Policy.';
export const welcome = 'Welcome!';
export const componentTitle = 'Pineapple Labs | Sign up';

export const metaTitle = 'Pineapple Labs | Sign up to the portal';
export const metaDescription =
	'Sign up for real estate services including photography, videography, aerial photography & more for agents and photographers. Have an account? Login today!';
export const techHeader = 'Service provider signup';
export const agentHeader = 'Agent & homeowner signup';
export const createAccount = 'create an account';
export const userRoleHeader = 'Choose what best describes you.';
export const userRoleAgent = "I'm an agent or homeowner";
export const userRoleTech = "I'm a media service provider";
export const nameId = 'signup-name-input';
export const namePlaceholder = 'Your name...';
export const nameLabel = 'FIRST AND LAST NAME';
export const emailId = 'signup-email-input';
export const emailPlaceholder = 'Your email...';
export const emailLabel = 'EMAIL';
export const numberId = 'signup-number-input';
export const numberPlaceholder = 'Your phone number...';
export const numberLabel = 'PHONE NUMBER';
export const passwordId = 'signup-password-input';
export const passwordPlaceholder = 'Choose a password...';
export const passwordLabel = 'PASSWORD';
export const inviteCodeId = 'signup-invite-code-input';
export const inviteCodePlaceholder = 'Your invite code (optional)...';
export const inviteCodeLabel = 'INVITE CODE';
export const termsString1 = 'I have read and agree to the';
export const termsString2 = 'Terms of Use';
export const andText = 'and';
export const termsString3 = 'Privacy Policy';
export const buttonId = 'signup-finish-button';
export const finishString = 'finish';
