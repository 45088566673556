import { ENTRY_OPTIONS } from '../../config';

export const ENTRY_TYPE_AGENT: PLTab = {
	value: ENTRY_OPTIONS.agent,
	text: ENTRY_OPTIONS.agent
};

export const ENTRY_TYPE_HOMEOWNER: PLTab = {
	value: ENTRY_OPTIONS.homeowner,
	text: ENTRY_OPTIONS.homeowner
};

export const ENTRY_TYPE_LOCK_BOX: PLTab = {
	value: ENTRY_OPTIONS.lockBox,
	text: ENTRY_OPTIONS.lockBox
};
