export const onClaimSuccess = 'Successfully claimed service!';
export const onClaimReleaseSuccess = 'Successfully released claim on this service!';
export const onClaimReleaseModalTitle = 'Release this claim';
export const onClaimReleaseModalDescription = 'Change the notes before releasing claim here.';
export const onClaimReleaseModalPlaceholder = 'Notes...';
export const onClaimReleaseModalButton = 'release claim';
export const removeAdminClaim = 'remove claim';
export const adminClaim = 'claim service';
export const adminClaimAlt = 'claim';
export const adminImageAlt = 'CS agent';
