// Created by carsonaberle at 6/16/21
import React from 'react';
import './styles';
import { IncompletePlaceholder } from '../../../../../../assets/icons';
import PLImage from '../../../../PLImage';
import { Link, useHistory } from 'react-router-dom';
import { getDimensionsToFitContainer } from '../../../../../UI/UIMultiImageSelect/config';
import { SCREEN_TYPE, STATUS_PROOFING } from '../../../../../../utils/Config/Constants';
import { useSelector } from 'react-redux';
import { getScreenTypeSelector } from '../../../../../../redux/selectors/ControlSelectors';

export default function TableListingRow({ listing }: TableListingRowProps) {
	const screenType = useSelector(getScreenTypeSelector);

	const history = useHistory();

	const renderClient = (client) => {
		if (!client) {
			return;
		}

		return (
			<div key={client.id} className='tableListingRow__client'>
				<PLImage
					imageClassName='tableListingRow__client--image'
					className='tableListingRow__client--image-container'
					alt={'agent image'}
					borderRadius={12}
					src={client.image}
				/>
				<Link onClick={(e) => e.stopPropagation()} to={`/agent/${client.id}`}>
					<p className='tableListingRow__client--name'>{client.name}</p>
				</Link>
			</div>
		);
	};

	const renderAddressComponents = (listing) => {
		const { property } = listing;

		const coverDimensions = getDimensionsToFitContainer(
			listing.coverFile,
			screenType === SCREEN_TYPE.mobile ? { width: 64, height: 64 } : { width: 96, height: 96 }
		);

		return (
			<div className='tableListingRow__cover fade-in-div'>
				<Link className='tableListingRow--pending' onClick={(e) => e.stopPropagation()} to={`/listing/${listing.id}?photos`}>
					<PLImage
						className='tableListingRow__cover-file'
						width={coverDimensions.width}
						borderRadius={3}
						src={listing.coverFile?.thumbnailUrl || IncompletePlaceholder.default}
						alt={'Listing cover image'}
					/>
				</Link>
				{property && (
					<div className='tableListingRow__cover-address'>
						<p className='tableListingRow__cover-address--street'>{property.streetAddress}</p>
						<p className='tableListingRow__cover-address--city'>
							{property.city}, <span className='tableListingRow__cover-address--state'>{property.state}</span>
						</p>
						{property.alias && <p className='tableListingRow__cover-address--alias'>{property.alias}</p>}
					</div>
				)}
			</div>
		);
	};

	const onClickRow = () => {
		history.push(`/listing/${listing.id}`);
	};

	const renderPendingServices = () => {
		const pendingServices = listing.services.filter((service) => service.isActive).sort((a, b) => Date.parse(a.serviceDate) - Date.parse(b.serviceDate));

		if (pendingServices.length === 0) return null;

		return (
			<p
				className='tableListingRow__bottom-text'
				style={{ color: pendingServices.filter((service) => service.status === STATUS_PROOFING).length > 0 ? 'var(--red)' : 'unset' }}>
				{pendingServices.length} service{pendingServices.length > 1 ? 's' : ''} ordered
			</p>
		);
	};

	return (
		<div key={listing.id} className='tableListingRow' onClick={onClickRow}>
			<div className='tableListingRow__top'>{renderAddressComponents(listing)}</div>
			<div className='tableListingRow__info'>
				{renderClient(listing.client)}
				{renderPendingServices()}
			</div>
		</div>
	);
}
