import React, { useEffect, useState } from 'react';
import './styles';
import * as PackageType from '../../../../utils/Helpers/PackageType/PackageType';
import { getPackageTypesFromOptions, TECHNICIAN_FLOOR_PLAN_TYPE } from '../../../../utils/Helpers/PackageType/PackageType';
import { OPTION_TYPES } from '../../../../utils/Config/Constants';
import UIOptionGroup from '../../../../components/UI/UIOptionGroup';
import moment from 'moment-timezone';
import UILoadingPage from '../../../../components/UI/UILoadingPage/UILoadingPage';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_EDIT_OPTIONS_PROMOS, GET_EDIT_OPTIONS_SERVICE, GET_EDIT_OPTIONS_UPGRADABLE_OPTIONS } from './queries';
import { useSelector } from 'react-redux';
import { getIsUserAdminSelector } from '../../../../redux/selectors/CurrentUserSelectors';
import EditOptionsPayment from './EditOptionsPayment';
import DiscountCalculator from '../../../../utils/Helpers/DiscountCalculator/DiscountCalculator';

export default function EditOptions({ userToBookFor, onUpdate, onFinish, serviceId }: EditOptionsProps) {
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const [promos, setPromos] = useState<PromoType[]>();
	const [service, setService] = useState<ServiceType>();
	const [serviceRegion, setServiceRegion] = useState<RegionType>();
	const [selectedOptions, setSelectedOptions] = useState<BookableOptionType[]>([]);
	const [upgradableOptions, setUpgradableOptions] = useState<BookableOptionType[]>();
	const [bookableOptions, setBookableOptions] = useState<BookableOptionType[]>();
	const [getPromos, { data: promosData }] = useLazyQuery(GET_EDIT_OPTIONS_PROMOS, { variables: { userId: userToBookFor.id, limit: 1000 } });
	const [getUpgradableOptions, { data: upgradableOptionsData }] = useLazyQuery(GET_EDIT_OPTIONS_UPGRADABLE_OPTIONS, {
		variables: {
			q: {
				op_or: [
					{
						type: {
							op_or: [{ op_eq: OPTION_TYPES.virtualStaging }, { op_eq: OPTION_TYPES.editUpgrade }]
						}
					},
					{
						packageType: TECHNICIAN_FLOOR_PLAN_TYPE.packageType
					}
				]
			},
			limit: 100
		}
	});
	const [getService, { data: serviceData }] = useLazyQuery(GET_EDIT_OPTIONS_SERVICE, { variables: { id: serviceId } });
	const [getBookableOptions, { data: bookableOptionsData }] = useLazyQuery(GET_EDIT_OPTIONS_UPGRADABLE_OPTIONS, {
		variables: {
			isActive: true,
			region: serviceRegion?.id,
			limit: 200,
			q: {
				type: {
					op_and: [{ op_ne: OPTION_TYPES.virtualStaging }, { op_ne: OPTION_TYPES.editUpgrade }]
				},
				packageType: { op_ne: TECHNICIAN_FLOOR_PLAN_TYPE.packageType }
			}
		}
	});

	const getPreviouslyPurchasedBookedOption = (option: OptionType): BookedOptionType | undefined => {
		if (service) {
			const previousMatchingOptions = service.bookedOptions.filter((bookedOption) => bookedOption.option && bookedOption.option.id === option.id);
			if (previousMatchingOptions.length > 0) return previousMatchingOptions[0];
		}
		return;
	};

	const setUpBookableOptions = (options: OptionType[]): BookableOptionType[] => {
		return options.map((option) => {
			const previousBookedOption = getPreviouslyPurchasedBookedOption(option);
			let bookableOption: BookableOptionType = {
				option,
				selectedQuantity: option.quantity && option.quantity > 0 ? option.quantity : 1
			};

			if (previousBookedOption) {
				bookableOption.previouslyPurchasedQuantity = previousBookedOption?.quantity;
			}
			return { ...bookableOption, discountCalculator: new DiscountCalculator(bookableOption, userToBookFor.promos) };
		});
	};

	useEffect(() => {
		(async () => {
			await getService();
			await getPromos();
			await getUpgradableOptions();
			await getBookableOptions();
		})();
	}, []);

	useEffect(() => {
		if (serviceData) {
			setService(serviceData['service']);
			setServiceRegion(serviceData['service'].region);
		}
	}, [serviceData]);

	useEffect(() => {
		if (promosData) {
			setPromos(promosData['promos']);
		}
	}, [promosData]);

	useEffect(() => {
		if (upgradableOptionsData) {
			setUpgradableOptions(setUpBookableOptions(upgradableOptionsData['options']));
		}
	}, [upgradableOptionsData]);

	useEffect(() => {
		if (bookableOptionsData) {
			setBookableOptions(setUpBookableOptions(bookableOptionsData['options']));
		}
	}, [bookableOptionsData]);

	if (!promos) return null;
	if (!service) return null;
	if (!bookableOptions) return null;
	if (!upgradableOptions) return null;

	const options = bookableOptions.concat(upgradableOptions);

	const getPackageTypes = (): PackageType[] => {
		let types: PackageType[] = [];

		if (!options) {
			return [];
		}

		if (isUserAdmin) {
			return getPackageTypesFromOptions(options);
		} else if (service.region) {
			let { isDroneService } = service;
			if (isDroneService) {
				types.push(PackageType.ADDITIONAL_DRONE_TYPE);
			}
			const previouslyBookedOptionsOfType = (optionType: string) => {
				return service.bookedOptions.filter((oldOption) => oldOption.option && oldOption.option.type === optionType);
			};
			if (!service.photographer) {
				types.push(PackageType.ADDITIONAL_PHOTOS_TYPE);
				if (previouslyBookedOptionsOfType('floorplan').length === 0) {
					types.push(PackageType.FLOOR_PLAN_TYPE);
				}
				if (previouslyBookedOptionsOfType('video').length === 0) {
					types.push(PackageType.VIDEO_TYPE);
				}
				if (previouslyBookedOptionsOfType('matterport').length === 0) {
					types.push(PackageType.MATTERPORT_TYPE);
				}
				if (previouslyBookedOptionsOfType('matterport').length === 0) {
					types.push(PackageType.DRONE_TYPE);
				}
			} else {
				const canServiceBeUpdatedWithTechnicianOptions = moment(service.serviceDate).isAfter(moment().add(14, 'hours'));

				if (!canServiceBeUpdatedWithTechnicianOptions) return [];

				types.push(PackageType.ADDITIONAL_PHOTOS_TYPE);

				const isPhotographerCertified = (certificate: string) => {
					return (
						service.photographer?.certificates.indexOf(certificate) !== -1 &&
						service.bookedOptions.filter((oldOption) => oldOption.option && oldOption.option.type === certificate).length === 0
					);
				};

				if (isPhotographerCertified('floorplan')) {
					types.push(PackageType.FLOOR_PLAN_TYPE);
				}
				if (isPhotographerCertified('matterport')) {
					types.push(PackageType.MATTERPORT_TYPE);
				}
				if (isPhotographerCertified('dslrVideo')) {
					types.push(PackageType.VIDEO_TYPE);
				}
				if (isPhotographerCertified('dronePhoto')) {
					types.push(PackageType.DRONE_TYPE);
				}
			}
		}
		return types;
	};

	const getNewOptions = (): BookableOptionType[] => {
		return selectedOptions.filter((selectedOption) => {
			return !getPreviouslyPurchasedBookedOption(selectedOption.option);
		});
	};

	const getUpdatedOptions = () => {
		return selectedOptions.filter((option) => option.previouslyPurchasedQuantity && option.selectedQuantity > 0);
	};

	const renderPackageTypes = () => {
		const { bookedOptions } = service;

		if (!options || !userToBookFor) {
			return;
		}

		if (getPackageTypes().length === 0 || (bookedOptions.length === 0 && !isUserAdmin)) {
			return undefined;
		}

		return (
			<UIOptionGroup selectedOptions={selectedOptions} updateSelectedOptions={setSelectedOptions} optionGroups={getPackageTypes()} bookableOptions={options} />
		);
	};

	if (!options) {
		return <UILoadingPage />;
	}

	let optionsForUpdate = getNewOptions().concat(getUpdatedOptions());

	const onPaymentComplete = async () => {
		await onUpdate();
		await getBookableOptions();
		onFinish();
		setSelectedOptions([]);
	};

	//Todo: Create empty set if no options can be booked
	return (
		<div className='editOptions'>
			<EditOptionsPayment bookableOptions={optionsForUpdate} onUpdate={onPaymentComplete} service={service} userToBookFor={userToBookFor} />
			{renderPackageTypes()}
		</div>
	);
}
