import gql from 'graphql-tag';

export const GET_BOOKING_OPTIONS_FROM_REGION = gql`
	query GetBookingOptionsFromRegion($query: JSON!, $region: String, $limit: Int) {
		options(query: $query, region: $region, limit: $limit) {
			id
			coverFile {
				id
				originalUrl
				webUrl
				thumbnailUrl
			}
			name
			price
			payTime
			type
			packageType
			photographerPay
			traineePay
			quantity
			description
			finalFileCount
			deliveryTimeEstimate
			exampleUrl
			photographerCertificatesRequired
			editorCertificatesRequired
			photographerInstructions
			editorInstructions
			requiredInfoPrompt
			requiredInfo
			isAdditionalOption
			regions {
				id
				name
			}
			additionalOptions {
				id
				packageType
				quantity
				price
				name
				requiredInfoPrompt
				requiredInfo
				deliveryTimeEstimate
				exampleUrl
				payTime
				type
				photographerCertificatesRequired
				description
				coverFile {
					id
					originalUrl
					webUrl
					thumbnailUrl
				}
			}
		}
	}
`;
