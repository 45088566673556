// Created by jarongallo at 6/9/21
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { guessCurrentTimezone } from '../../../../../../utils/Helpers/AppHelper';
import './styles';
import {
	PHOTOSHOOT_TYPE_SERVICE,
	STATUS_FINDING_PREFERRED_TECHNICIAN,
	STATUS_FINDING_TECHNICIAN,
	STATUS_PRE_ORDER,
	STATUS_TECHNICIAN_UPLOAD
} from '../../../../../../utils/Config/Constants';
import moment from 'moment-timezone';
import UIIconButton from '../../../../../../components/UI/UIIconButton';
import UIModal from '../../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../../components/UI/UIOverlayCard';
import { SingleDatePicker } from 'react-dates';
import TimePicker from 'rc-time-picker/lib/TimePicker';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import UIIcon from '../../../../../../components/UI/UIIcon';
import PLServiceDateRescheduleButton from './components/PLServiceDateRescheduleButton';
import { EditIcon } from '../../../../../../assets/icons';
import * as strings from './strings';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_SERVICE_WITH_SERVICE_DATE, UPDATE_SERVICE_DATE } from './queries';
import { getIsUserAdminSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { toast } from 'react-toastify';
import UIHover from '../../../../../../components/UI/UIHover/UIHover';
import { Moment } from 'moment';
import { CHILD_TYPE } from '../../../../../../components/UI/UIListInputRow/config';

export default function PLServiceDate({ serviceId }: PLServiceDateProps) {
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const [updateServiceDate] = useMutation(UPDATE_SERVICE_DATE);
	const [showEditServiceDateModal, setShowEditServiceDateModal] = useState(false);
	const [serviceDate, setServiceDate] = useState<Moment | null>(null);
	const [isSavingServiceDate, setIsSavingServiceDate] = useState<boolean>(false);
	const [isEditServiceDateFocused, setIsEditServiceDateFocused] = useState<boolean>(false);
	const [serviceWithDate, setServiceWithDate] = useState<ServiceType>();

	const [getService, { data: serviceData }] = useLazyQuery(GET_SERVICE_WITH_SERVICE_DATE, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getService();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setServiceWithDate(serviceData['service']);
			}
		})();
	}, [serviceData]);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				let serviceDateData = moment(serviceData['service'].serviceDate);
				setServiceDate(serviceDateData);
			}
		})();
	}, [serviceData]);

	if (!serviceWithDate) return null;

	const timezone = serviceWithDate.property?.timezone ?? guessCurrentTimezone();

	const renderServiceDate = () => {
		let hasServiceBeenPerformed = false;
		if (serviceWithDate.type === PHOTOSHOOT_TYPE_SERVICE) {
			switch (serviceWithDate.status) {
				case STATUS_PRE_ORDER:
				case STATUS_FINDING_PREFERRED_TECHNICIAN:
				case STATUS_FINDING_TECHNICIAN:
				case STATUS_TECHNICIAN_UPLOAD:
					break;
				default:
					hasServiceBeenPerformed = true;
			}
		}

		const serviceMoment = moment(serviceWithDate.serviceDate).tz(timezone);

		return (
			<div className='plServiceDate__container'>
				<div style={{ marginLeft: 12 }}>
					<p className='plServiceDate--day'>{serviceMoment.format('dddd MMMM D, YYYY')}</p>
					<p className='plServiceDate--hours'>{serviceMoment.format('h:mm a z')}</p>
				</div>
				<PLServiceDateRescheduleButton hasServiceBeenPerformed={hasServiceBeenPerformed} />
			</div>
		);
	};

	const renderEditServiceDateModal = () => {
		const hideModal = () => setShowEditServiceDateModal(false);

		const datePicker = (serviceDate: any) => {
			setServiceDate(serviceDate);
			setIsEditServiceDateFocused(false);
		};

		const onChangeFocus = (focus: any) => setIsEditServiceDateFocused(focus.focused);

		const saveDate = async () => {
			if (!serviceDate) return;

			let variables = {
				serviceId: serviceId,
				serviceDate: moment(serviceDate).tz(timezone)
			};

			const response = await updateServiceDate({ variables });
			const { updateService } = response['data'];
			if (updateService) {
				await getService();
				setShowEditServiceDateModal(false);
				setIsSavingServiceDate(false);
				toast.success(strings.serviceDateSuccess);
			}
		};

		const isOutsideRange = () => {
			return !isUserAdmin;
		};

		return (
			<UIModal condition={showEditServiceDateModal}>
				<UIOverlayCard
					hideModal={hideModal}
					cardStyle={{ height: '100%' }}
					title={strings.editServiceDateModalTitle}
					description={strings.editServiceDateModalDescription}>
					<div className='plServiceDate__time'>
						{/*//@ts-ignore*/}
						<SingleDatePicker
							onDateChange={datePicker}
							displayFormat={'MM/DD/YY'}
							placeholder={strings.serviceDatePickerPlaceholder}
							date={serviceDate}
							numberOfMonths={1}
							focused={isEditServiceDateFocused}
							onFocusChange={onChangeFocus}
							initialVisibleMonth={() => (serviceDate ? serviceDate : moment())}
							isOutsideRange={isOutsideRange}
						/>
						<p className='plServiceDate__time--timezone'>at</p>
						<TimePicker
							showSecond={false}
							hideDisabledOptions
							value={serviceDate?.tz(timezone)}
							onChange={setServiceDate}
							use12Hours
							allowEmpty={false}
							inputReadOnly={true}
							minuteStep={15}
						/>
						<p className='plServiceDate__time--timezone'>
							<span>{moment(serviceDate).tz(timezone).zoneAbbr()}</span>
						</p>
					</div>
					<UIIconButton
						className='plServiceDate__button'
						dark
						isLoading={isSavingServiceDate}
						text={strings.updateServiceDateButton}
						onClick={async () => {
							setIsSavingServiceDate(true);
							await saveDate();
						}}
					/>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderRightItems = () => {
		const onClickServiceDate = () => {
			if (isUserAdmin) {
				setShowEditServiceDateModal(true);
			}
		};
		if (isUserAdmin) {
			return (
				<UIHover text={strings.serviceDateHover}>
					<UIIcon className='plServiceDate__edit' iconSrc={EditIcon.white} onClick={onClickServiceDate} alt={'edit'} />
				</UIHover>
			);
		} else return;
	};

	return (
		<UIListInputRow label={strings.serviceDateLabel} type={CHILD_TYPE} rightItems={renderRightItems()}>
			{renderServiceDate()}
			{renderEditServiceDateModal()}
		</UIListInputRow>
	);
}
