import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/react-hoc';
import './agent-team-members-modal.scss';
import UIAdditionalContacts from '../../UI/UITeamMembers';
import UIIconButton from '../../UI/UIIconButton';
import { PlusIcon } from '../../../assets/icons';
import UIOverlayCard from '../../UI/UIOverlayCard';
import PropTypes from 'prop-types';
import UIModal from '../../UI/UIModal';
import Queries from '../../../utils/API/Queries';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../utils/Helpers/AppHelper';
import AddTeamMemberModal from '../AddTeamMemberModal';
import { UI_ICON_BUTTON_CANCEL_TYPE, UI_ICON_BUTTON_WHITE_TYPE } from '../../UI/UIIconButton/config';

class AgentTeamMembersModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			addingAdditionalContacts: false,
			contactToDelete: undefined,
			isAddingContact: false
		};
	}

	addAdditionalContact = async (contact) => {
		const { isAddingContact } = this.state;
		if (!isAddingContact) {
			this.setState({ isAddingContact: true });
			let { user } = this.props.currentUser;
			let { additionalContacts } = user;

			let variables = { id: user.id };
			if (additionalContacts) {
				variables.additionalContacts = [...user.additionalContacts, contact];
			} else {
				variables.additionalContacts = [contact];
			}

			const response = await this.props.client.mutate({
				mutation: Queries.UPDATE_USER_MUTATION,
				variables: variables
			});
			const { updateUser } = response['data'];
			if (updateUser) {
				const onFinish = () => {
					this.setState({ addingAdditionalContacts: false, isAddingContact: false });
					this.props.showSuccessMessage('Successfully added additional contact!');
				};
				await this.updateCurrentUser(onFinish);
			}
		}
	};

	deleteAdditionalContact = async () => {
		let { user } = this.props.currentUser;
		const { contactToDelete, isDeletingContact } = this.state;
		const result = user.additionalContacts.filter((contact) => contact.name !== contactToDelete.name);
		if (!isDeletingContact) {
			this.setState({ isDeletingContact: true });
			const response = await this.props.client
				.mutate({
					mutation: Queries.UPDATE_USER_MUTATION,
					variables: { id: user.id, additionalContacts: result }
				})
				.catch(this.errorCatch);
			const { updateUser } = response['data'];

			if (updateUser) {
				const onFinish = () => {
					this.setState({
						contactToDelete: undefined,
						isDeletingContact: false
					});
					this.props.showSuccessMessage('Successfully removed team member!');
				};
				await this.updateCurrentUser(onFinish);
			}
		}
	};

	showAddTeamMemberModal = () => {
		this.setState({ addingAdditionalContacts: true });
	};

	showDeleteAdditionalContactModal = (contactToDelete) => {
		this.setState({ contactToDelete });
	};

	updateCurrentUser = async (onFinish) => {
		this.props.getSignedInUser(this.props.client, onFinish);
	};

	render() {
		const { hideModal } = this.props;
		const { additionalContacts } = this.props.currentUser.user;
		return (
			<UIOverlayCard
				hideModal={hideModal}
				title={'Team members'}
				description={'Your team members will receive all notifications for all listings and services associated with this account.'}
				className='editAdditionalContacts'>
				<UIAdditionalContacts data={additionalContacts} deleteOnClick={true} deleteAdditionalContact={this.showDeleteAdditionalContactModal} />
				<div className='agentTeamMembersModal__add'>
					<UIIconButton
						iconStyle={{ width: 24 }}
						type={UI_ICON_BUTTON_WHITE_TYPE}
						dark
						onClick={this.showAddTeamMemberModal}
						icon={PlusIcon.grey}
						text='add a team member'
					/>
				</div>
				{this.renderAddTeamMemberModal()}
				{this.renderDeleteContactModal()}
			</UIOverlayCard>
		);
	}

	renderAddTeamMemberModal = () => {
		const { addingAdditionalContacts, isAddingContact } = this.state;
		const hideModal = () => this.setState({ addingAdditionalContacts: false });
		return (
			<UIModal noClose condition={addingAdditionalContacts} hideModal={hideModal}>
				<AddTeamMemberModal
					hideModal={hideModal}
					isAddingContact={isAddingContact}
					onAddTeamMember={async (contact) => {
						await this.addAdditionalContact(contact);
					}}
				/>
			</UIModal>
		);
	};

	renderDeleteContactModal = () => {
		const { contactToDelete, isDeletingContact } = this.state;
		const hideModal = () => this.setState({ contactToDelete: undefined });
		return (
			<UIModal condition={contactToDelete} hideModal={hideModal}>
				<UIOverlayCard
					hideModal={hideModal}
					title={`Remove ${contactToDelete ? contactToDelete.name : undefined}?`}
					description={'Removing a team member means that they will no longer receive any updates associated with this account.'}>
					<div className='agentTeamMembersModal__delete'>
						<UIIconButton
							dark
							isLoading={isDeletingContact}
							type={UI_ICON_BUTTON_CANCEL_TYPE}
							onClick={this.deleteAdditionalContact}
							text={'remove'}
							className='agentTeamMembersModal__delete--button'
						/>
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};
}

AgentTeamMembersModal.propTypes = {
	hideModal: PropTypes.func
};

export default withApollo(connect(commonMapStateToProps, commonMapDispatchToProps)(AgentTeamMembersModal));
