// Created by jarongallo at 6/17/21
import React, { useEffect, useState } from 'react';
import * as strings from './strings';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import { CHILD_TYPE } from '../../../../../../components/UI/UIListInputRow/config';
import UICheckbox from '../../../../../../components/UI/UICheckbox/UICheckbox';
import { toast } from 'react-toastify';
import { camelCaseToSentence } from '../../../../../../utils/Helpers/StringHelpers';
import { GET_SERVICE_INCLUDES_MATTERPORT, UPDATE_SERVICE_INCLUDES_MATTERPORT } from './queries';

export default function PLServiceAdminIncludesMatterport({ serviceId }: PLServiceAdminIncludesMatterportProps) {
	const [includesMatterport, setIncludesMatterport] = useState<boolean>(false);

	const [updateMatterport] = useMutation(UPDATE_SERVICE_INCLUDES_MATTERPORT, {
		onError: (error) => toast.error(strings.errorUpdatingToggle(error.message))
	});

	const [getServiceAdminMatterport, { data: serviceData }] = useLazyQuery(GET_SERVICE_INCLUDES_MATTERPORT, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getServiceAdminMatterport();
		})();
	}, []);

	useEffect(() => {
		(() => {
			if (serviceData) {
				setIncludesMatterport(serviceData['service'].includesMatterport);
			}
		})();
	}, [serviceData]);

	const updateCheckbox = async (key: string, value: any) => {
		const response = await updateMatterport({ variables: { serviceId: serviceId, [key]: value } });
		if (response['data']) {
			await getServiceAdminMatterport();
			toast.success(strings.updateToggleSuccess(camelCaseToSentence(key)));
		} else {
			toast.error(strings.errorUpdatingToggle(`update failed.`));
		}
	};

	return (
		<UIListInputRow type={CHILD_TYPE} label={strings.toggleLabel} description={strings.includesMatterportDescription}>
			<UICheckbox
				label={strings.includesMatterport}
				style={{ marginTop: 12 }}
				onChangeExpansion={() => updateCheckbox('includesMatterport', !includesMatterport)}
				status={includesMatterport ? UICheckbox.CHECKED : UICheckbox.UNCHECKED}
			/>
		</UIListInputRow>
	);
}
