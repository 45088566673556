import React, { useEffect, useState } from 'react';
import './styles';
import UIProperty from '../../../../../../components/UI/UIProperty';
import { TYPES } from '../../../../../../components/UI/UIProperty/config';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SERVICE_WITH_PROPERTY } from './queries';
import { useSelector } from 'react-redux';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { PLServicePropertyProps } from './types';

export default function PLServiceProperty({ serviceId, clientId }: PLServicePropertyProps) {
	const signedInUser = useSelector(getSignedInUserSelector);
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const [property, setProperty] = useState<PropertyType>();

	const [getProperty, { data: propertyData }] = useLazyQuery(GET_SERVICE_WITH_PROPERTY, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getProperty();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (propertyData) {
				setProperty(propertyData['service'].property);
			}
		})();
	}, [propertyData]);

	if (!property) return <div className='plServiceProperty--header' />;

	return <UIProperty editOnClick={isUserAdmin || signedInUser.id === clientId} type={TYPES.sideBar} property={property} onFinishSavingAddress={getProperty} />;
}
