import moment from 'moment-timezone';

export const getTimezoneFromServiceLocation = async (serviceLocation) => {
	const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${serviceLocation.latitude},${
		serviceLocation.longitude
	}&timestamp=${moment().unix()}&key=${process.env.GOOGLE_MAPS}`;
	const opts = {
		method: 'GET'
		// headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}` },
		// body: JSON.stringify({ query })
	};

	return await fetch(url, opts)
		.then((res) => res.json())
		.then((response) => {
			return response;
		});
};
