// Created by carsonaberle at 11/14/21
import React from 'react';
import './styles';
import Lottie from 'react-lottie';
import UIIcon from '../../../../../../../components/UI/UIIcon';

export default function FullscreenFilesQuickAction({ file, quickAction, onClickQuickAction, isUpdating, disableClick }: FullscreenFilesQuickActionProps) {
	const lottieOptions = {
		loop: true,
		autoplay: true,
		animationData: undefined,
		path: 'https://res.cloudinary.com/pineapple-labs/raw/upload/v1592767290/portal-assets/loading-circle_kxpoc7.json',
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	return (
		<div key={quickAction.type}>
			<div
				className={`fullscreenFiles__quickActions--container ${disableClick ? 'fullscreenFiles__quickActions--container-disabled' : ''}`}
				onClick={() => (disableClick ? {} : onClickQuickAction(file, quickAction.type))}>
				{isUpdating[quickAction.type] ? (
					<Lottie
						options={lottieOptions}
						height={32}
						width={32}
						isStopped={false}
						isPaused={false}
						style={{ padding: '4px', marginRight: '24px', marginLeft: 0 }}
					/>
				) : (
					<UIIcon className='fullscreenFiles__quickActions--container-icon' iconSrc={quickAction.icon(file)} alt={'quick action'} />
				)}

				<p className={quickAction.textClassname}> {quickAction.helpText(file)}</p>
			</div>
			<div className='fullscreenFiles__quickActions--separator' />
		</div>
	);
}
