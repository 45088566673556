import initialState from '../initialState';
import * as types from './types';

export default function controlReducer(state = initialState.control, action) {
	switch (action.type) {
		case types.SET_SCREEN_TYPE:
			return {
				...state,
				screenType: action.screenType
			};
		case types.SET_SHOW_ZENDESK_TYPE:
			return {
				...state,
				showZendesk: action.showZendesk
			};
		case types.SET_SELECTED_PANEL_TAB:
			return {
				...state,
				selectedPanelTab: action.selectedPanelTab
			};
		case types.SHOW_OVERLAY:
			return {
				...state,
				overlayInfo: action.overlayInfo
			};
		case types.SET_OVERLAY_COMPONENT:
			return {
				...state,
				overlayComponent: action.overlayComponent
			};
		case types.SET_RIGHT_PANEL_TYPE:
			return {
				...state,
				rightPanelType: action.rightPanelType
			};
		case types.SET_ADDITIONAL_HEADER:
			return {
				...state,
				additionalHeader: action.additionalHeader
			};
		case types.REQUIRE_LOGIN:
			return {
				...state,
				requireLogin: action.requireLogin
			};
		case types.ADD_PAGE_TO_SIDEBAR_NAVIGATION:
			return {
				...state,
				sidebarPages: [...state.sidebarPages, action.pageToAdd]
			};
		case types.GO_TO_SIDEBAR_INDEX:
			return {
				...state,
				sidebarNavigationIndex: action.sidebarNavigationIndex
			};
		case types.CLEAR_SIDEBAR:
			return {
				...state,
				sidebarNavigationIndex: 0,
				sidebarPages: []
			};
		case types.TOGGLE_MENU_VISIBILITY:
			return {
				...state,
				isMobileMenuVisible: !state.isMobileMenuVisible
			};
		case types.SET_SHOW_ACTIONS_NEEDED:
			return {
				...state,
				showActionsNeeded: action.showActionsNeeded
			};
		case types.TOGGLE_BOOKING:
			if (action.user) {
				return {
					...state,
					booking: !state.booking,
					bookingUser: action.user
				};
			}
			return {
				...state,
				booking: !state.booking,
				bookingUser: undefined
			};
		default:
			return state;
	}
}
