// Created by carsonaberle at 3/25/22
import React, { useEffect, useState } from 'react';
import * as S from './styles';
import * as strings from './strings';
import { BackIcon, ForwardIcon, MuteIcon, PauseIcon, PlayIcon, SkipBackIcon, SkipForwardIcon, UnMuteIcon } from './config';
import { useAudioPlayer } from 'react-use-audio-player';

export default function VTSlideshow({ files, audioFile, isExternal }: VTSlideshowProps) {
	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	const [isMuted, setIsMuted] = useState<boolean>(false);
	const [selectedFileIndex, setSelectedFileIndex] = useState<number>(0);

	const { play, pause, fade } = useAudioPlayer({
		src: audioFile?.originalUrl,
		format: 'mp3',
		autoplay: false,
		loop: true
	});

	const _handleKeyDown = (event) => {
		switch (event.keyCode) {
			//Space bar
			case 32:
				setIsPlaying(!isPlaying);
				break;
			//right arrow
			case 39:
				goForward();
				break;
			//left arrow
			case 37:
				goBack();
				break;
			//m key
			case 77:
				setIsMuted(!isMuted);
				break;
			default:
				break;
		}
	};

	const preloadNextImage = (fromIndex: number) => {
		let nextSelectedIndex = fromIndex + 1;
		if (nextSelectedIndex % files.length === 0) {
			nextSelectedIndex = 0;
		}
		const img = new Image();
		img.src = files[nextSelectedIndex].webUrl ?? '';
	};

	const preloadPreviousImage = (fromIndex: number) => {
		let previousSelectedIndex = fromIndex - 1;
		if (previousSelectedIndex < 0) {
			previousSelectedIndex = files.length - 1;
		}
		const img = new Image();
		img.src = files[previousSelectedIndex].webUrl ?? '';
	};

	useEffect(() => {
		document.addEventListener('keydown', _handleKeyDown, { passive: true });
		return () => {
			document.removeEventListener('keydown', _handleKeyDown);
		};
	});

	useEffect(() => {
		const interval = setInterval(() => {
			if (isPlaying) {
				goForward();
			}
		}, 5000);
		return () => clearInterval(interval);
	}, [selectedFileIndex, isPlaying]);

	useEffect(() => {
		if (!isPlaying) {
			pause();
		} else {
			play();
		}
	}, [isPlaying]);

	useEffect(() => {
		if (!isMuted) {
			fade(0, 1, 150);
		} else {
			fade(1, 0, 150);
		}
	}, [isMuted]);

	const goBack = () => {
		let newIndex = selectedFileIndex - 1;
		if (newIndex < 0) {
			newIndex = files.length - 1;
		}
		setSelectedFileIndex(newIndex);
		preloadPreviousImage(newIndex);
	};

	const goForward = () => {
		let newIndex = selectedFileIndex + 1;
		if (newIndex === files.length) {
			newIndex = 0;
		}
		setSelectedFileIndex(newIndex);
		preloadNextImage(newIndex);
	};

	const renderPlayControl = () => {
		return (
			<S.VTSlideshowPlaybackControl>
				<S.img_VTSlideshowControlIcon
					alt={isPlaying ? strings.pause : strings.play}
					src={isPlaying ? PauseIcon : PlayIcon}
					onClick={() => setIsPlaying(!isPlaying)}
				/>
			</S.VTSlideshowPlaybackControl>
		);
	};

	const renderAudioControl = () => {
		if (!audioFile) return null;

		return (
			<S.VTSlideshowAudioControl>
				<S.img_VTSlideshowControlIcon
					alt={isMuted ? strings.unmute : strings.mute}
					src={isMuted ? MuteIcon : UnMuteIcon}
					onClick={() => setIsMuted(!isMuted)}
				/>
			</S.VTSlideshowAudioControl>
		);
	};

	const renderProgressBar = () => {
		if (!isPlaying) return null;
		return <S.VTSlideshowProgressBar key={selectedFileIndex} />;
	};

	const renderBackButton = () => {
		const icon = selectedFileIndex === 0 ? SkipBackIcon : BackIcon;
		return <S.img_VTSlideshowBack alt={strings.back} src={icon} onClick={goBack} />;
	};

	const renderForwardButton = () => {
		const icon = selectedFileIndex === files.length - 1 ? SkipForwardIcon : ForwardIcon;
		return <S.img_VTSlideshowForward alt={strings.forward} src={icon} onClick={goForward} />;
	};

	const currentFile = files[selectedFileIndex];

	return (
		<S.VTSlideshowContainer paddingBottom={isExternal ? '0' : '48px'}>
			{renderBackButton()}
			<S.VTSlideshowContent taller={currentFile?.height > currentFile?.width}>
				<S.VTSlideshowVertical>
					<S.VTSlideshowHorizontal>
						<S.img_VTSlideshowImage key={currentFile.webUrl} alt={currentFile.filename} src={currentFile.webUrl} />
						<S.div_VTSlideshowControlContainer>
							{renderPlayControl()}
							{renderAudioControl()}
							{renderProgressBar()}
						</S.div_VTSlideshowControlContainer>
					</S.VTSlideshowHorizontal>
				</S.VTSlideshowVertical>
			</S.VTSlideshowContent>
			{renderForwardButton()}
		</S.VTSlideshowContainer>
	);
}
