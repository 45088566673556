import {
	DEPRECATED_STATUS_EDITOR_REDO,
	DEPRECATED_STATUS_RE_EDIT_REVIEW,
	PROMOS,
	SERVICE_REQUESTS,
	STATUS_ADMIN_HOLD,
	STATUS_EDITOR_UPLOAD,
	STATUS_FINDING_EDIT_TECHNICIAN,
	STATUS_FINDING_PREFERRED_TECHNICIAN,
	STATUS_FINDING_TECHNICIAN,
	STATUS_LIVE,
	STATUS_PRE_ORDER,
	STATUS_PROOFING,
	STATUS_REVIEW,
	STATUS_STANDBY,
	STATUS_TECHNICIAN_UPLOAD
} from '../../Config/Constants';
import { ADMIN_TYPE } from '../PackageType/PackageType';

export const getPhotographerPayFromOptions = (service, user): string => {
	let amount;
	amount = service.bookedOptions.reduce((acc, option) => {
		if (service.trainee) {
			if (service.trainee.id === user.id) {
				if (option.packageType === 'training') {
					return acc + option.traineePay;
				} else {
					return acc;
				}
			} else {
				return acc + option.photographerPay * option.quantity;
			}
		} else {
			return acc + option.photographerPay * option.quantity;
		}
	}, 0);

	//Only increment photographer pay $30 if all the options aren't admin type
	if (service.bookedOptions.filter((bookedOption) => bookedOption.packageType !== ADMIN_TYPE.packageType).length > 0) {
		amount += 3000;
	}

	if (service.surgeBoost) {
		amount += service.surgeBoost;
	}

	if (user['isInternalTech']) {
		return '0';
	}
	return (amount / 100).toFixed(2);
};

export const getPackageString = (options, isLighting, newLine, both, optionList?) => {
	let packageArray: any[] = [];

	for (let i = 0; i < options.length; i++) {
		let option = options[i];
		let quantity = option.quantity;
		if (option['bookedOption']) {
			quantity -= option['bookedOption'].quantity;
		}
		if (both) {
			if (quantity > 1) {
				packageArray.push({ name: quantity.toString() + 'x - ' + option.name });
			} else if (optionList) {
				packageArray.push({
					name: option.name,
					photographerInstructions: option.option.photographerInstructions,
					exampleUrl: option.option.exampleUrl,
					coverFile: option.option.coverFile
				});
				// packageArray.push({ option: option });
			} else {
				packageArray.push(option.name);
			}
		} else if (!isLighting) {
			if (option.type !== 'lighting') {
				if (quantity > 1) {
					packageArray.push({ name: quantity.toString() + 'x - ' + option.name });
				} else {
					packageArray.push({ name: option.name });
				}
			}
		} else {
			if (option.type === 'lighting') {
				if (quantity > 1) {
					packageArray.push({ name: quantity.toString() + 'x - ' + option.name });
				} else {
					packageArray.push({ name: option.name });
				}
			}
		}
	}
	if (newLine) {
		return packageArray.join('\r\n');
	}
	if (optionList) {
		return packageArray;
	} else return packageArray.join(', ');
};

export const getOptionPriceWithPromos = (option) => {
	let { price } = option;
	let discountedPrice = price;

	if (option.option) {
		price = option.option.price;
		discountedPrice = option.option.price;
	}

	const greatestPromo = option.discountCalculator ? option.discountCalculator.greatestPromo : undefined;
	if (greatestPromo) {
		discountedPrice = greatestPromo.discountAmount > price ? 0 : price - greatestPromo.discountAmount;
	}

	return [price, discountedPrice];
};

export const getPromoTypeText = (promo) => {
	if (promo.promoType === PROMOS.promoTypes.universalDiscount) {
		return 'All Packages';
	} else if (promo.promoType === PROMOS.promoTypes.specificOption) {
		if (!promo.option) {
			return '';
		}
		return promo.option.name;
	} else if (promo.promoType === PROMOS.promoTypes.credit) {
		return 'Credit';
	}
};

export const getStripeAccountUrl = (accountName) => {
	switch (process.env.PL_ENVIRONMENT) {
		case 'DEV':
		case 'LOCAL_DEV':
		case 'LOCAL':
			return 'https://dashboard.stripe.com/test/connect/accounts/' + accountName;
		case 'LIVE':
		default:
			return 'https://dashboard.stripe.com/connect/accounts/' + accountName;
	}
};

export const getReadableStatus = (dbStatus) => {
	switch (dbStatus) {
		case STATUS_PRE_ORDER:
			return 'Pending initial approval';
		case STATUS_TECHNICIAN_UPLOAD:
			return 'Session confirmed';
		case STATUS_FINDING_EDIT_TECHNICIAN:
			return 'Editing';
		case STATUS_EDITOR_UPLOAD:
			return 'Processing';
		case STATUS_REVIEW:
			return 'Quality Check';
		case STATUS_PROOFING:
			return 'Proofing required';
		case STATUS_LIVE:
			return 'Live';
		case STATUS_ADMIN_HOLD:
			return 'Admin hold';
		case STATUS_STANDBY:
			return 'Standby';
		case STATUS_FINDING_TECHNICIAN:
			return 'Scheduling tech';
		case STATUS_FINDING_PREFERRED_TECHNICIAN:
			return 'Scheduling preferred tech';
		case DEPRECATED_STATUS_EDITOR_REDO:
			return 'Additional processing';
		case DEPRECATED_STATUS_RE_EDIT_REVIEW:
			return 'Reviewing processing';
		case SERVICE_REQUESTS.status.complete:
			return 'Complete';
		case SERVICE_REQUESTS.status.inProgress:
			return 'In progress';
		case SERVICE_REQUESTS.status.awaitingAdminApproval:
			return 'Awaiting admin approval';
		case SERVICE_REQUESTS.status.awaitingAcceptance:
			return 'Awaiting acceptance';
		case SERVICE_REQUESTS.status.awaitingRequestApproval:
			return 'Awaiting request approval';
		default:
			return '';
	}
};
