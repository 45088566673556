import { DIMENSIONS } from './index';

export const filterFloorplansByTab = (items, selectedTab) => {
	return items
		.filter((file) => {
			if (selectedTab === DIMENSIONS) {
				return file.tags?.withDimension || false;
			}
			return !file.tags?.withDimension || false;
		})
		.sort((a, b) => {
			return `${a.tags['floorLevel']}`.localeCompare(`${b.tags['floorLevel']}`);
		});
};
