import * as types from './types';
import { showErrorMessage, showInfoMessage, showSuccessMessage } from '../Control';

export const setScreenType = (screenType) => {
	return { type: types.SET_SCREEN_TYPE, screenType };
};

export const setShowZendeskType = (showZendesk) => {
	return { type: types.SET_SHOW_ZENDESK_TYPE, showZendesk };
};

export const setSelectedPanelTab = (selectedPanelTab) => {
	return { type: types.SET_SELECTED_PANEL_TAB, selectedPanelTab };
};

export const addPage = (pageToAdd) => {
	return { type: types.ADD_PAGE_TO_SIDEBAR_NAVIGATION, pageToAdd };
};

export const setRightPanelType = (rightPanelType) => {
	return { type: types.SET_RIGHT_PANEL_TYPE, rightPanelType };
};

export const requireLogin = (requireLogin) => {
	return { type: types.REQUIRE_LOGIN, requireLogin };
};

export const toggleMenuVisibility = (isMobileMenuVisible) => {
	return { type: types.TOGGLE_MENU_VISIBILITY, isMobileMenuVisible };
};

export const goToSidebarPage = (sidebarNavigationIndex) => {
	return { type: types.GO_TO_SIDEBAR_INDEX, sidebarNavigationIndex };
};

export const clearSidebar = () => {
	return { type: types.CLEAR_SIDEBAR };
};

export const setShowActionsNeeded = (showActionsNeeded) => {
	return { type: types.SET_SHOW_ACTIONS_NEEDED, showActionsNeeded };
};

export const controlActions = {
	setScreenType,
	setSelectedPanelTab,
	setRightPanelType,
	requireLogin,
	toggleMenuVisibility,
	showSuccessMessage,
	showErrorMessage,
	showInfoMessage,
	goToSidebarPage,
	clearSidebar,
	setShowActionsNeeded
};
