import React from 'react';
import './styles/table-agent-list.scss';
import './styles/table-agent-list-tablet.scss';
import './styles/table-agent-list-desktop.scss';
import PLImage from '../../../PLImage';
import { TableAgentListProps } from './types';

const TableAgentList = ({ data, pagination, onSelect }: TableAgentListProps) => {
	return (
		<div className='tableAgent'>
			<div className='fade-in-div tableAgent__rows'>
				{data.map((agent) => {
					return (
						<div onClick={() => onSelect(agent)} className='agentRow' key={agent.id}>
							<PLImage alt={'agent image'} src={agent.image} imageClassName='agentRow__image' />
							<div>
								<p className='agentRow__name'>{agent.name}</p>
								<p className='agentRow__email'>{agent.email}</p>
							</div>
						</div>
					);
				})}
			</div>
			{pagination}
		</div>
	);
};

export default TableAgentList;
