// noinspection SpellCheckingInspection

import React, { useEffect, useState } from 'react';
import { BASE_STYLE } from './config';

let ReactMapboxGl, Layer, Feature, Popup, Map, Marker;
if (process.env.BUILD_TARGET === 'client') {
	ReactMapboxGl = require('react-mapbox-gl');
	Layer = ReactMapboxGl.Layer;
	Feature = ReactMapboxGl.Feature;
	Popup = ReactMapboxGl.Popup;
	Marker = ReactMapboxGl.Marker;
	Map = ReactMapboxGl.Map({
		accessToken: 'pk.eyJ1IjoiY2Fyc29uYWJlcmxlIiwiYSI6ImNqcjFpZG15ZjEwZnY0M28wanoxOHpoeWQifQ.2Ll0KPwMXf6ivxJcVhG60w'
	});
}

export default function PLMap({
	style,
	center: inputCenter,
	zoom: inputZoom,
	setFlyTo,
	noLocation,
	onClick,
	containerClassName,
	containerStyle,
	children
}: PLMapProps) {
	const DEFAULT_CENTER: [number, number] = [-104.99025, 39.739235];

	const [center, setCenter] = useState<[number, number]>(inputCenter ?? DEFAULT_CENTER);
	const [zoom, setZoom] = useState<number[]>(inputZoom || [6]);

	const locateUser = () => {
		if (!noLocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				setZoom(zoom);
				setCenter([position.coords.longitude, position.coords.latitude]);
			});
		}
	};

	useEffect(() => {
		if (!center) {
			locateUser();
		}

		if (setFlyTo) {
			setFlyTo(setCenter);
		}
	}, []);

	const onStyleLoad = (map) => map.resize();

	return (
		<Map
			onStyleLoad={onStyleLoad}
			minZoom={[8]}
			style={style ?? BASE_STYLE}
			center={center}
			onClick={onClick}
			zoom={zoom}
			className={containerClassName}
			containerStyle={containerStyle}>
			{children}
		</Map>
	);
}
