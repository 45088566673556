// Created by carsonaberle at 2/15/22
import styled from 'styled-components';

export const MSTabBarContainer = styled.div`
	//DEFAULT
	flex-direction: row;
	height: 60px;
	min-height: 60px;
	overflow-x: scroll;
	padding-right: 24px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		padding: 0 calc(min(6vw, 120px)) 0 0;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSTabBarItem = styled.div<{ darkMode?: boolean; maxWidth?: string }>`
	//DEFAULT
	padding: 0 24px;
	height: 100%;
	width: fit-content;
	margin-right: 36px;
	justify-content: center;
	border-radius: 0;
	border-top: var(--dark-grey) solid 3px;
	border-bottom-width: 3px;
	border-left-width: 3px;
	border-right-width: 3px;
	cursor: pointer;
	max-width: ${(props) => props.maxWidth || 'unset'};
	border-color: ${(props) => (props.darkMode === true ? '#f1f1f1' : 'var(--dark-grey)')};

	&:hover {
		opacity: 0.8;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		padding: 0 24px;
		width: 240px;
		min-width: 180px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		padding: 0 24px;
		width: 240px;
		min-width: 180px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSTabBarItemSelected = styled(MSTabBarItem)<{ darkMode?: boolean; maxWidth?: string }>`
	//DEFAULT
	border-radius: 6px;
	border: var(--dark-grey) solid 3px;
	max-width: ${(props) => props.maxWidth || 'unset'};
	border-color: ${(props) => (props.darkMode === true ? '#f1f1f1' : 'var(--dark-grey)')};

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSTabBarItemText = styled.div<{ darkMode?: boolean }>`
	//DEFAULT
	color: ${(props) => (props.darkMode === true ? '#f1f1f1' : 'var(--dark-grey)')};
	text-align: center;
	font-family: BrownBold, sans-serif;
	white-space: nowrap;
	user-select: none;

	&:hover {
		opacity: 0.8;
	}
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
