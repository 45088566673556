import React from 'react';
import './fade-in-out-transition.scss';
import { CSSTransition } from 'react-transition-group';

const FadeInOutTransition = ({ transitionIn, children }: FadeInOutTransitionProps) => {
	return (
		<CSSTransition appear={true} in={transitionIn} timeout={0} classNames='fadeInOutTransition'>
			{children}
		</CSSTransition>
	);
};

export default FadeInOutTransition;
