// Created by carsonaberle at 4/10/21
import React, { useEffect, useState } from 'react';
import './styles';
import { TABS } from './config';
import PLServiceOptionsTab from './PLServiceTabComponents/PLServiceOptionsTab';
import PLServiceAdminTab from './PLServiceTabComponents/PLServiceAdminTab';
import PLServiceInfoTab from './PLServiceTabComponents/PLServiceInfoTab';
import PLServiceContent from './PLServiceTabComponents/PLServiceContent';
import * as strings from './strings.js';
import { useDispatch, useSelector } from 'react-redux';
import {
	EDIT_TYPE_SERVICE,
	USER_ROLE_ADMIN,
	USER_ROLE_AFFILIATE,
	USER_ROLE_AGENT,
	USER_ROLE_EDITING_MANAGER,
	USER_ROLE_EDITOR,
	USER_ROLE_PHOTOGRAPHER,
	USER_ROLE_REGIONAL_MANAGER,
	USER_ROLE_SALES
} from '../../../../utils/Config/Constants';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_PL_SERVICE_SERVICE } from './queries';
import { ZendeskAPI } from 'react-zendesk';
import PLServiceFixRequests from './PLServiceComponents/PLServiceFixRequests';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../../redux/selectors/CurrentUserSelectors';
import PLServiceNotes from './PLServiceComponents/PLServiceNotes';
import { PLServiceProps } from './types';
import { useHistory, useLocation } from 'react-router-dom';
import UINotificationBanner from '../../../../components/UI/UINotificationBanner';
import UISegmentedControl from '../../../../components/UI/UISegmentedControl';
import PLServiceProperty from './PLServiceComponents/PLServiceProperty';
import PLServiceAdminRibbons from './PLServiceComponents/PLServiceAdminRibbons';
import PLServiceAgentHelp from './PLServiceComponents/PLServiceHelp';
import { clearSidebar } from '../../../../redux/store/control/actions';
import { parseQuery } from '../../../../components/UI/UIMenuBody';
import UILoadingPage from '../../../../components/UI/UILoadingPage/UILoadingPage';
import PLServiceProofing from './PLServiceComponents/PLServiceProofing';
import { updateUrlParameter } from '../../../../utils/Helpers/AppHelper';

export default function PLService({ serviceId }: PLServiceProps) {
	const isUserAdmin = useSelector(getIsUserAdminSelector);
	const signedInUser = useSelector(getSignedInUserSelector);

	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const [service, setService] = useState<ServiceType>();

	const [getService, { data: serviceData }] = useLazyQuery(GET_PL_SERVICE_SERVICE, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getService();
			let pageParam = new URLSearchParams(location.search).get('page');
			const parsedQuery = parseQuery(location.search);
			if (!pageParam) {
				const searchWithoutService = Object.entries(parsedQuery).filter((queryPair) => queryPair[0] !== 'service' && queryPair[0] !== 'page');
				const firstItem = searchWithoutService[0] ?? [];
				if (firstItem && firstItem[0]) {
					history.replace(`${location.pathname}?${firstItem[0]}&service=${serviceId}&page=${TABS.INFO.path}`);
				} else {
					history.replace(`${location.pathname}?service=${serviceId}&page=${TABS.INFO.path}`);
				}
			}
		})();
	}, []);

	useEffect(() => {
		if (serviceData) {
			setService(serviceData['service']);
		}
	}, [serviceData]);

	if (!service) {
		return (
			<div className='plService'>
				<div className='plService__sidebar'>
					<UILoadingPage />
				</div>
			</div>
		);
	}

	const openListingPage = () => {
		history.push(`/listing/${service.listing.id}`);
		dispatch(clearSidebar());
	};

	const getSelectedPage = () => {
		const tabs = getPageTabs();
		let page = new URLSearchParams(location.search).get('page');
		return tabs.filter((tab) => tab.path === page)[0] ?? tabs[0];
	};

	const renderPage = () => {
		switch (getSelectedPage()) {
			case TABS.INFO:
				return <PLServiceInfoTab serviceId={service.id} />;
			case TABS.CONTENT:
				return <PLServiceContent serviceId={service.id} />;
			case TABS.OPTIONS:
				return <PLServiceOptionsTab serviceId={service.id} />;
			case TABS.FIX_REQUESTS:
				return <PLServiceFixRequests serviceId={service.id} />;
			case TABS.NOTES:
				return <PLServiceNotes serviceId={service.id} />;
			case TABS.ADMIN:
				return <PLServiceAdminTab serviceId={service.id} />;
			default:
				return;
		}
	};

	const shouldRenderFixRequestsTab = () => {
		let serviceRequests;
		let serviceRequestsForMatchingEditor;
		if (isUserAdmin && service.serviceRequests) {
			serviceRequests = service.serviceRequests.filter((serviceRequest) => serviceRequest.isActive);
			return serviceRequests && serviceRequests.length > 0;
		} else if ([USER_ROLE_EDITOR, USER_ROLE_EDITING_MANAGER].indexOf(signedInUser.role) !== -1) {
			serviceRequestsForMatchingEditor = service.serviceRequests.filter(
				(serviceRequest) => serviceRequest.isActive && serviceRequest.editor && serviceRequest.editor.id === signedInUser.id
			);
			if (serviceRequestsForMatchingEditor.length > 0) {
				serviceRequests = serviceRequestsForMatchingEditor;
				return serviceRequests && serviceRequests.length > 0;
			} else return false;
		}

		return false;
	};

	const getPageTabs = () => {
		let role = signedInUser.role;
		let tabs: PLSegment[] = [];

		switch (role) {
			case USER_ROLE_ADMIN:
			case USER_ROLE_REGIONAL_MANAGER:
				tabs = [TABS.INFO, TABS.CONTENT, TABS.OPTIONS, TABS.NOTES, TABS.ADMIN];
				if (shouldRenderFixRequestsTab()) {
					tabs.push(TABS.FIX_REQUESTS);
				}
				break;
			case USER_ROLE_PHOTOGRAPHER:
				tabs = [TABS.INFO, TABS.CONTENT, TABS.OPTIONS, TABS.NOTES];
				if (shouldRenderFixRequestsTab()) {
					tabs.push(TABS.FIX_REQUESTS);
				}
				break;
			case USER_ROLE_EDITOR:
			case USER_ROLE_EDITING_MANAGER:
				tabs = [TABS.INFO, TABS.CONTENT, TABS.OPTIONS, TABS.NOTES];
				if (shouldRenderFixRequestsTab()) {
					tabs.push(TABS.FIX_REQUESTS);
				}
				break;
			case USER_ROLE_SALES:
				tabs = [TABS.INFO, TABS.OPTIONS, TABS.NOTES];
				if (shouldRenderFixRequestsTab()) {
					tabs.push(TABS.FIX_REQUESTS);
				}
				break;
			case USER_ROLE_AGENT:
				tabs = [TABS.INFO, TABS.OPTIONS, TABS.NOTES];
				if (shouldRenderFixRequestsTab()) {
					tabs.push(TABS.FIX_REQUESTS);
				}
				if (service.type === EDIT_TYPE_SERVICE) {
					tabs.push(TABS.CONTENT);
				}
				break;
			case USER_ROLE_AFFILIATE:
				tabs = [TABS.INFO, TABS.OPTIONS];
				break;
		}

		return tabs;
	};

	const renderHeader = () => {
		const onUpdatePage = (selectedPage) => {
			const urlWithReplacedPage = updateUrlParameter(`${location.pathname}${location.search}`, 'page', selectedPage.path);
			history.replace(urlWithReplacedPage);
		};
		return (
			<div className='plService__sidebar--header'>
				<PLServiceProperty serviceId={serviceId} clientId={service.client.id} />
				<PLServiceAdminRibbons serviceId={serviceId} />
				<PLServiceProofing service={service} />
				<div className='plService__sidebar--tab'>
					<UISegmentedControl
						key={strings.plServiceTabKey}
						selectedSegment={getSelectedPage()}
						updateSelectedSegment={onUpdatePage}
						segments={getPageTabs()}
					/>
				</div>
			</div>
		);
	};

	const renderFooter = () => {
		if (signedInUser.role === USER_ROLE_EDITOR || signedInUser.role === USER_ROLE_EDITING_MANAGER) return null;
		if (signedInUser.role === USER_ROLE_PHOTOGRAPHER && service.client.id !== signedInUser.id) return null;
		if (location.pathname.startsWith('/listing/')) return null;

		return (
			<div className='plService__sidebar--footer'>
				<UINotificationBanner
					className='plService__sidebar--footer-banner'
					onClick={openListingPage}
					backgroundColor={'var(--white-16)'}
					text={strings.infoBannerText}
					hoverText={strings.infoBannerHover}
					textStyle={{ color: 'white' }}
				/>
			</div>
		);
	};

	return (
		<div className='plService'>
			<div className='plService__sidebar' onClick={(e) => e.stopPropagation()}>
				{renderHeader()}
				<PLServiceAgentHelp serviceId={service.id} />
				<div className='plService__sidebar--content'>{renderPage()}</div>
				{renderFooter()}
			</div>
		</div>
	);
}
