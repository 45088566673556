// Created by jarongallo at 6/25/21
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './styles';
import {
	DEPRECATED_STATUS_EDITOR_REDO,
	DEPRECATED_STATUS_RE_EDIT_REVIEW,
	STATUS_EDITOR_UPLOAD,
	STATUS_FINDING_EDIT_TECHNICIAN,
	STATUS_FINDING_PREFERRED_TECHNICIAN,
	STATUS_FINDING_TECHNICIAN,
	STATUS_LIVE,
	STATUS_PRE_ORDER,
	STATUS_PROOFING,
	STATUS_REVIEW,
	STATUS_TECHNICIAN_UPLOAD,
	USER_ROLE_ADMIN,
	USER_ROLE_AGENT,
	USER_ROLE_PHOTOGRAPHER,
	USER_ROLE_REGIONAL_MANAGER
} from '../../../../../../utils/Config/Constants';
import UINotificationBanner from '../../../../../../components/UI/UINotificationBanner';
import UIModal from '../../../../../../components/UI/UIModal';
import MarkServiceCompleteModal from '../../../../../../components/modals/MarkServiceCompleteModal/MarkServiceCompleteModal';
import Queries from '../../../../../../utils/API/Queries';
import ApproveServiceModal from '../../../../../../components/modals/ApproveServiceModal/ApproveServiceModal';
import { getImagesFrom, getTotalNumberOfFilesAvailable } from '../../../../../../utils/Helpers/FileHelper';
import * as strings from './strings';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_SERVICE_ADMIN_FILES, GET_SERVICE_ADMIN_RIBBON } from './queries';
import { toast } from 'react-toastify';
import { getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { PROOF_SHOOT } from '../../../IndividualService/ServiceProofing/components/ServiceProofingCart/queries';
import { updateUrlParameter } from '../../../../../../utils/Helpers/AppHelper';
import { useHistory } from 'react-router-dom';

export default function PLServiceAdminRibbons({ serviceId }: PLServiceAdminRibbonsProps) {
	const signedInUser = useSelector(getSignedInUserSelector);

	const history = useHistory();

	const [isMarkingComplete, setIsMarkingComplete] = useState(false);
	const [showMarkCompleteModal, setShowMarkCompleteModal] = useState(false);
	const [showApproveModal, setShowApproveModal] = useState(false);
	const [service, setService] = useState<ServiceType>();
	const [images, setImages] = useState<FileType[]>();

	const [updateService] = useMutation(Queries.UPDATE_SERVICE, {
		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		}
	});

	const [proofService] = useMutation(PROOF_SHOOT, {
		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		}
	});

	const [getService, { data: serviceData }] = useLazyQuery(GET_SERVICE_ADMIN_RIBBON, {
		variables: { id: serviceId }
	});

	const [getServiceImages, { data: filesData }] = useLazyQuery(GET_SERVICE_ADMIN_FILES, {
		variables: { query: { serviceId }, limit: 1000 }
	});

	useEffect(() => {
		(async () => {
			await getService();
			await getServiceImages();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setService(serviceData['service']);
			}
		})();
	}, [serviceData]);

	useEffect(() => {
		(async () => {
			if (filesData) {
				setImages(filesData['files']);
			}
		})();
	}, [filesData]);

	if (!service || !images) return null;

	const changeServiceStatus = async (newStatus: ServiceStatus, message?: string) => {
		const response = await updateService({
			variables: {
				serviceId: serviceId,
				status: newStatus,
				type: service.type
			}
		});
		const { updateService: updatedService } = response['data'];
		if (updatedService) {
			await getService();
			if (message) {
				toast.success(message);
			}
		}
	};

	const renderCompleteModal = () => {
		const markServiceComplete = async () => {
			if (!isMarkingComplete) {
				setIsMarkingComplete(true);
			}
			const response = await updateService({
				variables: {
					serviceId: serviceId,
					isAdminApproved: true,
					type: service.type
				}
			});
			if (response.data.updateService) {
				await getService();
				toast.success(strings.completeSuccessString);
				setIsMarkingComplete(false);
				setShowMarkCompleteModal(false);
			}
		};
		const hideModal = () => {
			setShowMarkCompleteModal(false);
		};

		return (
			<UIModal condition={showMarkCompleteModal}>
				<MarkServiceCompleteModal hideModal={hideModal} service={service} updateService={getService} markComplete={markServiceComplete} />
			</UIModal>
		);
	};

	const shouldSkipToLive = () => {
		return getTotalNumberOfFilesAvailable(service) >= getImagesFrom(images).length;
	};

	const renderApproveModal = () => {
		const addAllFilesToService = async () => {
			const { id } = service;
			const response = await proofService({
				variables: {
					serviceId: id,
					proofedFiles: getImagesFrom(images).map((file) => {
						return { id: file.id, index: file.index };
					})
				}
			}).catch((error) => toast.error(error.message));
			const { proofService: proofedService } = response['data'];
			if (proofedService) {
				await changeServiceStatus(STATUS_LIVE);
				toast.success(strings.approveSuccessString);
			}
		};

		const approveService = async () => {
			if (shouldSkipToLive()) {
				await addAllFilesToService();
				setShowApproveModal(false);
			} else {
				await changeServiceStatus(STATUS_PROOFING, strings.approveSuccessProofingString);
				setShowApproveModal(false);
			}
		};
		const hideModal = () => {
			setShowApproveModal(false);
		};

		return (
			<UIModal condition={showApproveModal}>
				<ApproveServiceModal hideModal={hideModal} service={service} updateService={getService} serviceFiles={images} approveService={approveService} />
			</UIModal>
		);
	};

	const openRawFiles = () => {
		const urlWithReplacedPage = updateUrlParameter(`${location.pathname}${location.search}&upload=raw-images`, 'page', 'files');
		history.replace(urlWithReplacedPage);
	};

	const adminActions = () => {
		let adminComponents: JSX.Element[] = [];
		let role = signedInUser.role;

		switch (role) {
			case USER_ROLE_AGENT:
				break;
			case USER_ROLE_PHOTOGRAPHER:
				if (service.photographer?.id === signedInUser.id && service.status === STATUS_TECHNICIAN_UPLOAD) {
					adminComponents.push(
						<UINotificationBanner
							key={strings.completeServiceBannerKey}
							className='plServiceAdminRibbons__ribbon'
							onClick={openRawFiles}
							backgroundColor={'var(--mint-primary-32)'}
							text={strings.uploadRAWSText}
							textStyle={{ color: 'var(--mint-primary)' }}
						/>
					);
				}
				break;
			case USER_ROLE_REGIONAL_MANAGER:
			case USER_ROLE_ADMIN:
				switch (service.status) {
					case STATUS_LIVE:
						if (!service.isAdminApproved) {
							adminComponents.push(
								<UINotificationBanner
									key={strings.completeServiceBannerKey}
									className='plServiceAdminRibbons__ribbon plServiceAdminRibbons__ribbon-green'
									onClick={() => setShowMarkCompleteModal(true)}
									backgroundColor={'var(--mint-primary-32)'}
									text={strings.markCompleteBanner}
									textStyle={{ color: 'var(--mint-primary)' }}
									hoverText={strings.markCompleteHoverText}
								/>
							);
						}
						break;
					case STATUS_TECHNICIAN_UPLOAD:
						break;
					case STATUS_PROOFING:
						break;
					case STATUS_EDITOR_UPLOAD:
					case STATUS_FINDING_EDIT_TECHNICIAN:
					case DEPRECATED_STATUS_EDITOR_REDO:
						break;
					case STATUS_PRE_ORDER:
						adminComponents.push(
							<UINotificationBanner
								key={strings.sendToTechKey}
								className='plServiceAdminRibbons__ribbon plServiceAdminRibbons__ribbon-green'
								onClick={() =>
									changeServiceStatus(
										service.preferredPhotographers.length > 0 ? STATUS_FINDING_PREFERRED_TECHNICIAN : STATUS_FINDING_TECHNICIAN,
										strings.changeServiceMessage
									)
								}
								backgroundColor={'var(--mint-primary-32)'}
								textStyle={{ color: 'var(--mint-primary)' }}
								text={strings.sendToTechString}
								hoverText={strings.sendToTechString}
							/>
						);
						break;
					case STATUS_REVIEW:
					case DEPRECATED_STATUS_RE_EDIT_REVIEW:
						adminComponents.push(
							<UINotificationBanner
								key={strings.approveServiceBannerKey}
								className='plServiceAdminRibbons__ribbon plServiceAdminRibbons__ribbon-green'
								onClick={() => setShowApproveModal(true)}
								backgroundColor={'var(--mint-primary-32)'}
								text={strings.approveBannerString}
								textStyle={{ color: 'var(--mint-primary)' }}
								hoverText={strings.approveBannerHoverString}
							/>
						);
						break;
				}
				break;
			default:
				break;
		}
		return (
			<div>
				{adminComponents.map((component) => {
					return component;
				})}
			</div>
		);
	};

	return (
		<div className='plServiceAdminRibbons'>
			{adminActions()}
			{renderCompleteModal()}
			{renderApproveModal()}
		</div>
	);
}
