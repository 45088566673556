import React, { useState } from 'react';
import './styles/mobile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { getPLStripeKey } from '../../../../../../utils/Helpers/AppHelper';
import InjectedCheckoutForm from './PaymentRequestForm/UIPaymentRequestForm';
import { PROMOS } from '../../../../../../utils/Config/Constants';
import Queries from '../../../../../../utils/API/Queries';
import UIIconButton from '../../../../../../components/UI/UIIconButton';
import AddTeamMemberModal from '../../../../../../components/modals/AddTeamMemberModal';
import UIAdditionalContacts from '../../../../../../components/UI/UITeamMembers';
import UIModal from '../../../../../../components/UI/UIModal';
import { convertCents } from '../../../../../../utils/Helpers/StringHelpers';
import { areEditVSUpgradeOptionsSelected, areMediaOptionsSelected, ENTRY_OPTIONS, getOptionsFromBookingConfigWithLighting } from '../../config';
import {
	getAppliedPromosFromBookableOptions,
	getCreateListingVariablesFromBookingConfig,
	getCreateServiceVariablesFromBookingConfig,
	getRequestedOptionsFrom
} from '../../../../../../utils/Helpers/BookingHelpers';
import { getPromosForOptions } from '../../../../../../utils/Helpers/PromoHelpers';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import UICard from '../../../../../../components/UI/UICard/UICard';
import { loadStripe } from '@stripe/stripe-js/pure';
import { toast } from 'react-toastify';
import { getIsUserAdminSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import { CreatePaymentIntentParams } from '../../../../../../utils/API/types';
import { getBookingConfigSelector } from '../../../../../../redux/selectors/BookingSelectors';
import ReviewOption from './ReviewOption';
import { CHILD_TYPE } from '../../../../../../components/UI/UIListInputRow/config';
import UIServiceLocation from '../../../../../../components/UI/UIServiceLocation';
import { setBookingConfig } from '../../../../../../redux/store/booking/actions';

const stripePromise = loadStripe(getPLStripeKey());

export default function BookingPay({ discountSummer, updateBookingConfig, options, bookingClient, closeBooking }: BookingPayProps) {
	const bookingConfig = useSelector(getBookingConfigSelector);

	const [showAdditionalContacts, setShowAdditionalContacts] = useState<boolean>(false);
	const [isPaying, setIsPaying] = useState<boolean>(false);

	const history = useHistory();
	const client = useApolloClient();
	const dispatch = useDispatch();

	const isUserAdmin = useSelector(getIsUserAdminSelector);

	if (!bookingConfig) return null;
	if (!bookingClient) {
		toast.error('No client has been specified! Please contact customer support.');
		return null;
	}

	const getPaymentIntent = async () => {
		let requestedOptions = getRequestedOptionsFrom(options);

		let appliedPromos = getAppliedPromosFromBookableOptions(options, discountSummer);

		let variables: CreatePaymentIntentParams = {
			currency: 'USD',
			stripeBookingTotal: getFinalPrice(),
			requestedOptions: requestedOptions.map((option) => {
				return {
					id: option.id,
					quantity: option.selectedQuantity
				};
			})
		};

		variables.promos = [...new Set(appliedPromos.map((appliedPromo) => appliedPromo.promo?.id))];

		const response = await client
			.mutate({
				mutation: Queries.CREATE_PAYMENT_INTENT,
				variables
			})
			.catch(() => {
				setIsPaying(false);
				return { data: 'error' };
			});
		const { createPaymentIntent } = response['data'];
		if (createPaymentIntent && createPaymentIntent.validated) {
			return { clientSecret: createPaymentIntent.client_secret };
		} else {
			toast.error('Error validating payment');
			setIsPaying(false);
			return {};
		}
	};

	const getFinalPrice = () => {
		if (isUserAdmin) {
			return 0;
		}
		return discountSummer.getTotalPrice();
	};

	const onPaymentSuccess = async (paymentResult) => {
		if (bookingConfig.selectedOptions.length === 0) {
			const optionsToBook = getOptionsFromBookingConfigWithLighting(bookingConfig);
			const response = await client.mutate({
				mutation: Queries.CREATE_LISTING,
				variables: getCreateListingVariablesFromBookingConfig(bookingConfig, bookingClient, optionsToBook, getFinalPrice(), paymentResult)
			});
			const { createListing } = response['data'];
			if (createListing) {
				await closeBooking();
				updateBookingConfig('isPaymentComplete', true);
				setIsPaying(false);
				history.replace(`/listing/${createListing.id}`);
				toast.success('Successfully created listing!');
			} else {
				toast.error('Error paying');
				setIsPaying(false);
			}
		} else {
			let variables: any = getCreateServiceVariablesFromBookingConfig(
				bookingConfig,
				bookingClient,
				bookingConfig.selectedOptions,
				getFinalPrice(),
				paymentResult
			);

			let appliedPromos = getAppliedPromosFromBookableOptions(options, discountSummer);
			variables.promos = [...new Set(appliedPromos.map((appliedPromo) => appliedPromo.promo?.id))];

			const response = await client
				.mutate({
					mutation: Queries.CREATE_SERVICE_MUTATION,
					variables: variables
				})
				.catch((error: Error) => {
					toast.error(error.message);
					setIsPaying(false);
				});
			if (response) {
				const { createService } = response['data'];
				if (createService) {
					await closeBooking();
					updateBookingConfig('isPaymentComplete', true);
					dispatch(setBookingConfig({}));
					setIsPaying(false);
					history.replace(`/listing/${createService.listing.id}`);
					toast.success('Successfully created listing and booked your service!');
				}
			}
		}
	};

	const toggleServiceAdditionalContact = (contact: AdditionalContact) => {
		let serviceAdditionalContacts = bookingConfig.additionalContacts;

		if (serviceAdditionalContacts.filter((additionalContact: AdditionalContact) => additionalContact.email === contact.email).length > 0) {
			serviceAdditionalContacts = serviceAdditionalContacts.filter((additionalContact: AdditionalContact) => additionalContact.email !== contact.email);
			toast.success("Successfully removed contact from this service's notifications!");
		} else {
			serviceAdditionalContacts.push(contact);
			toast.success("Successfully added contact to this service's notifications!");
		}
		setShowAdditionalContacts(false);
		updateBookingConfig('additionalContacts', serviceAdditionalContacts);
	};

	const renderPayComponent = () => {
		const totalPrice = getFinalPrice();

		return (
			<UICard style={{ margin: 0 }} title={!totalPrice || totalPrice === 0 ? 'Create this service' : 'Payment'} className='bookingPay--card'>
				<div className={'bookingPay--stripe'}>{renderPayButton()}</div>
			</UICard>
		);
	};

	const renderTeamMembersComponent = () => {
		if (!areMediaOptionsSelected(bookingConfig.selectedOptions) && !areEditVSUpgradeOptionsSelected(bookingConfig.selectedOptions)) {
			return;
		}
		return (
			<UICard
				title={'Email notifications'}
				className='bookingPay--card'
				description={'By default, only you will receive email notifications. If you would like someone else to get all notifications, add them below.'}>
				{bookingConfig.additionalContacts?.map((contact: AdditionalContact) => (
					<div
						key={contact.email}
						className='bookingPay--teamMember'
						onClick={() =>
							updateBookingConfig(
								'additionalContacts',
								bookingConfig.additionalContacts.filter((filterAdditionalContact) => filterAdditionalContact.email !== contact.email)
							)
						}>
						<p className='bookingPay--teamMember--name'>{contact.name}</p>
						<p className='bookingPay--teamMember--email'>{contact.email}</p>
					</div>
				))}
				<p className='bookingPay--contacts' onClick={() => setShowAdditionalContacts(true)}>
					{bookingConfig.additionalContacts && bookingConfig.additionalContacts.length > 0 ? 'add another team member' : 'add team member'}
				</p>
			</UICard>
		);
	};

	const renderReviewComponent = () => {
		const { serviceLocation } = bookingConfig;
		let totalOptions: BookableOptionType[] = getOptionsFromBookingConfigWithLighting(bookingConfig);

		const totalAppliedPromoIds = getPromosForOptions(totalOptions, bookingClient.promos);
		const creditPromos = bookingClient.promos.filter((promo) => totalAppliedPromoIds.indexOf(promo.id) !== -1 && promo.promoType === PROMOS.promoTypes.credit);

		return (
			<UICard className='bookingPay--card'>
				<UIServiceLocation serviceLocation={serviceLocation} hideEditUnitNumber={true} />
				{bookingConfig.serviceProvider && bookingConfig.serviceProvider.id && (
					<UIListInputRow type={CHILD_TYPE} label={'Preferred service provider'}>
						<p>{bookingConfig.serviceProvider.name}</p>
					</UIListInputRow>
				)}
				<div className='pl-separator' />
				{totalOptions.map((bookableOption, index) => (
					<>
						<ReviewOption id={`bookable-option-${index}`} bookableOption={bookableOption} />
						<div className='pl-separator' />
					</>
				))}
				{creditPromos.length > 0 && (
					<div className='bookingPay__review__discounts'>
						{creditPromos.map((promo, index) => (
							<p key={`booking-promo-${index}`} className='bookingPay__review__discounts--text'>
								Credit: -{convertCents(promo.discountAmount)}
							</p>
						))}
					</div>
				)}
				{totalOptions.length > 0 && <p className='bookingPay__review--totalPrice'>Total: {convertCents(getFinalPrice())}</p>}
				<div className='pl-separator' />
				{areMediaOptionsSelected(bookingConfig.selectedOptions) && (
					<>
						{bookingConfig.serviceDate && <p className='bookingPay__review__item--date'>{bookingConfig.serviceDate.format('MM/DD/YYYY [at] hh:mm a z')}</p>}
						<div className='pl-separator' />
					</>
				)}
				{bookingConfig.region && bookingConfig.region.id && areMediaOptionsSelected(bookingConfig.selectedOptions) && (
					<>
						<p className='bookingPay__review__item--title'>Property entry information</p>
						<p className='bookingPay__review__item--date'>{bookingConfig.entry.type}</p>
						{bookingConfig.entry.notes && <p>{bookingConfig.entry.notes}</p>}
						{bookingConfig.entry.type === ENTRY_OPTIONS.lockBox && bookingConfig.entry.lockBoxCode && <p>Code: {bookingConfig.entry.lockBoxCode}</p>}
					</>
				)}
			</UICard>
		);
	};

	const renderAddAdditionalContactsModal = () => {
		const hideModal = () => setShowAdditionalContacts(false);
		return (
			<UIModal condition={showAdditionalContacts}>
				<AddTeamMemberModal
					// @ts-ignore
					hideModal={hideModal}
					onAddTeamMember={toggleServiceAdditionalContact}>
					<p className='general-description'>If you add a contact to this service they will receive all notification emails associated with this service.</p>
					<UIAdditionalContacts
						data={bookingConfig.additionalContacts}
						deleteOnClick={true}
						deleteAdditionalContact={toggleServiceAdditionalContact}
						addAdditionalContact={toggleServiceAdditionalContact}
					/>
				</AddTeamMemberModal>
			</UIModal>
		);
	};

	const renderPayButton = () => {
		const totalPrice = getFinalPrice();

		if (totalPrice === 0) {
			const createService = async () => {
				if (!isPaying) {
					setIsPaying(true);
					if (isUserAdmin) {
						await onPaymentSuccess({});
					} else {
						const paymentIntent = await getPaymentIntent();
						if (!paymentIntent.clientSecret) {
							setIsPaying(false);
							toast.error('No client secret');
						}
						await onPaymentSuccess(paymentIntent);
					}
				}
			};

			return <UIIconButton dark text={'create service'} onClick={createService} />;
		}

		return (
			<Elements stripe={stripePromise}>
				<InjectedCheckoutForm
					amount={totalPrice}
					isPaying={isPaying}
					updateIsPaying={setIsPaying}
					getPaymentIntent={getPaymentIntent}
					onPaymentSuccess={onPaymentSuccess}
					onPaymentError={toast.error}
				/>
			</Elements>
		);
	};

	return (
		<div className='bookingPay'>
			{renderReviewComponent()}
			{renderTeamMembersComponent()}
			{renderPayComponent()}
			{renderAddAdditionalContactsModal()}
		</div>
	);
}
