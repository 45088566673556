import React from 'react';
import './styles/mobile.scss';
import './styles/tablet.scss';
import './styles/desktop.scss';
import { useSelector } from 'react-redux';
import { getAvailableAddOns } from '../../../../../../utils/Helpers/BookingHelpers';
import { ADD_ON_TYPE, getPackageTypesList } from '../../../../../../utils/Helpers/PackageType/PackageType';
import UIOptionGroup from '../../../../../../components/UI/UIOptionGroup';
import { getBookingConfigSelector } from '../../../../../../redux/selectors/BookingSelectors';

export default function BookingUpgrades({ updateBookingConfig }: BookingUpgradesProps) {
	const bookingConfig = useSelector(getBookingConfigSelector);

	const availableOptions = getAvailableAddOns(bookingConfig).availableOptions;

	const packageTypes = availableOptions
		.map((bookableOption) => getPackageTypesList().filter((packageType) => packageType.packageType === bookableOption.option.packageType)[0])
		.filter((value, index, self) => {
			return self.findIndex((v) => v.packageType === value.packageType) === index;
		});

	return (
		<div className='bookingUpgrades'>
			<p className='bookingOptionsSelect--heading' style={{ margin: '24px 0' }}>
				You have the following add ons available
			</p>
			<UIOptionGroup
				disableAutoClose={true}
				disableAutoDeselectGroup={true}
				optionGroups={packageTypes}
				selectedOptions={bookingConfig.selectedOptions}
				updateSelectedOptions={(selectedOptions) => {
					updateBookingConfig('selectedOptions', selectedOptions);
				}}
				bookableOptions={availableOptions}
			/>
		</div>
	);
}
