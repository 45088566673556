import React, { Component } from 'react';
import './ui-user-search.scss';
import { connect } from 'react-redux';
import UIUser from '../UITeamMember/UITeamMember';
import { withApollo } from '@apollo/react-hoc';
import PLTable from '../../PL/PLTable/PLTable';
import Queries from '../../../utils/API/Queries';
import UILabeledInput from '../UILabeledInput';
import PropTypes from 'prop-types';
import { INVITED_TO_PINEAPPLE, USER_ROLE_AGENT, USER_ROLE_PHOTOGRAPHER } from '../../../utils/Config/Constants';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../utils/Helpers/AppHelper';
import { USER_SEARCH_TABLE_TYPE } from '../../PL/PLTable/config';
import { UI_LABELED_INPUT_SEARCH_TYPE } from '../UILabeledInput/config';

const QUERY_LIMIT = 15;
class UIUserSearch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchParams: ''
		};
	}

	//Mark: Component functions
	searchForTechnicians = async () => {
		const { searchParams } = this.state;
		const { role } = this.props;
		let variables;
		if (!role) {
			this.props.showErrorMessage('no role requested');
		}
		if (role === USER_ROLE_PHOTOGRAPHER) {
			variables = {
				q: {
					role: { op_eq: role },
					name: { op_iLike: '%' + searchParams + '%' },
					op_and: [
						{
							stripeAccountId: { op_ne: 'null' }
						},
						{
							stripeAccountId: { op_ne: INVITED_TO_PINEAPPLE }
						}
					]
				},
				limit: QUERY_LIMIT
			};
		} else if (role === USER_ROLE_AGENT) {
			variables = {
				q: {
					role: { op_eq: role },
					name: { op_iLike: '%' + searchParams + '%' }
				},
				limit: QUERY_LIMIT
			};
		} else {
			variables = {
				q: {
					role: { op_eq: role },
					name: { op_regexp: '(?i)' + searchParams }
				},
				limit: QUERY_LIMIT
			};
		}

		const response = await this.props.client.query({
			query: Queries.GET_USERS_WITH_QUERY,
			variables
		});
		const { users: technicianResults } = response['data'];
		if (technicianResults) {
			this.setState({ technicianResults });
		}
	};

	//Mark: Render functions
	render() {
		const { searchParams, technicianResults } = this.state;
		const { selectedUser, onSelectUser, placeholder, label, selectedUserHeader, emptySetText, style } = this.props;

		return (
			<div className='uiUserSearch' style={style}>
				{!selectedUser && (
					<UILabeledInput
						containerStyle={{ minWidth: 320, width: '100%' }}
						style={{ width: '100%' }}
						placeholder={placeholder}
						hideLabel={!label}
						type={UI_LABELED_INPUT_SEARCH_TYPE}
						searchParams={searchParams}
						label={label}
						onChangeValue={(searchParams) => {
							this.setState({ searchParams }, this.searchForTechnicians);
							if (this.props.onUpdateSearchParams) {
								this.props.onUpdateSearchParams(searchParams);
							}
						}}
						onSearch={this.searchForTechnicians}
					/>
				)}
				{(selectedUser || (technicianResults && searchParams !== '')) && (
					<div className='uiUserSearch__content'>
						{selectedUser && selectedUserHeader}
						{selectedUser ? (
							<UIUser deleteOnClick onClick={() => onSelectUser(undefined)} user={selectedUser} />
						) : (
							technicianResults &&
							searchParams !== '' && (
								<PLTable
									updateTable={this.searchForTechnicians}
									limit={QUERY_LIMIT}
									type={USER_SEARCH_TABLE_TYPE}
									emptySetText={emptySetText || `No users found`}
									data={technicianResults}
									onSelect={onSelectUser}
								/>
							)
						)}
						{!selectedUser && technicianResults && searchParams !== '' && (
							<p className='uiUserSearch__text'>
								Don't see who you’re looking for? Perhaps they haven’t be invited to use Pineapple Labs as a service provider.
							</p>
						)}
					</div>
				)}
			</div>
		);
	}
}

UIUserSearch.propTypes = {
	style: PropTypes.object,
	placeholder: PropTypes.string,
	onUpdateSearchParams: PropTypes.func,
	onSelectUser: PropTypes.func,
	role: PropTypes.string,
	selectedUser: PropTypes.object,
	emptySetText: PropTypes.string.isRequired
};
export default withApollo(connect(commonMapStateToProps, commonMapDispatchToProps)(UIUserSearch));
