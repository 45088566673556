import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { After, ensureReady, getSerializedData } from '@jaredpalmer/after';
import routes from './routes';
import { Provider } from 'react-redux';
import configureStore from './redux/store/ConfigureStore';
import BaseSetup from './components/BaseSetup';
import createApolloClient from './utils/API/ApolloClient';
import { ApolloProvider } from 'react-apollo';
import ClearCache from 'react-clear-cache';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import GlobalStyle from './globalStyles';

const preloadedState = getSerializedData('preloaded_state');
const store = configureStore(preloadedState);

const client = createApolloClient({ clientLocalStorage: localStorage });

ensureReady(routes).then((data) =>
	hydrate(
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<Provider store={store}>
					<ApolloProvider client={client}>
						<ClearCache auto={true}>
							{() => (
								<BaseSetup client={client}>
									<GlobalStyle />
									<After data={data} routes={routes} client={client} />
								</BaseSetup>
							)}
						</ClearCache>
					</ApolloProvider>
				</Provider>
			</ThemeProvider>
		</BrowserRouter>,
		document.getElementById('root')
	)
);

if (module['hot']) {
	module['hot'].accept();
}
