import React, { useState } from 'react';
import './styles';
import UIModal from '../UIModal';
import BookingManager from '../../../pages/portal/global/BookingManager';
import UIIcon from '../UIIcon';
import { PlusIcon } from '../../../assets/icons';
import * as strings from './strings';
import { useDispatch } from 'react-redux';
import { setBookingConfig } from '../../../redux/store/booking/actions';
import { DRONE_TYPE, FLOOR_PLAN_TYPE, MATTERPORT_TYPE, PHOTO_TYPE, VIDEO_TYPE } from '../../../utils/Helpers/PackageType/PackageType';
import { ENTRY_OPTIONS } from '../../../pages/portal/global/BookingManager/config';
import { BOOKING_OPTION_SELECT_TABS } from '../../../pages/portal/global/BookingManager/components/BookingOptionsSelect/config';

export default function UIBookingButton({ listing, title, bookingClient, onFinishBooking, iconStyle }: UIBookingButtonProps) {
	const [showBooking, setShowBooking] = useState<boolean>(false);

	const dispatch = useDispatch();

	const hideBooking = async () => {
		dispatch(setBookingConfig(undefined));
		if (onFinishBooking) {
			await onFinishBooking();
		}
		setShowBooking(false);
	};

	const renderBookingModal = () => {
		if (!showBooking) return null;

		return (
			<UIModal condition={showBooking}>
				<BookingManager closeBooking={hideBooking} bookingClientId={bookingClient.id} listing={listing} />
			</UIModal>
		);
	};

	return (
		<>
			<div
				className='uiBookingButton'
				onClick={() => {
					const optionsSelectedTab = listing?.region !== null ? BOOKING_OPTION_SELECT_TABS.mediaServices : BOOKING_OPTION_SELECT_TABS.editingServices;
					dispatch(
						setBookingConfig({
							serviceLocation: listing?.property,
							region: listing?.property?.region,
							selectedOptions: [],
							additionalContacts: [],
							lightingOptions: [],
							optionsSelectedTab,
							requiredPackageTypes: [PHOTO_TYPE, DRONE_TYPE, VIDEO_TYPE, MATTERPORT_TYPE, FLOOR_PLAN_TYPE],
							selectedPartnerPackage: undefined,
							listing,
							entry: { type: ENTRY_OPTIONS.agent },
							isPaymentComplete: false
						})
					);
					setShowBooking(true);
				}}>
				{/*<UIIcon style={iconStyle} className='uiBookingButton--icon' iconSrc={PlusIcon.darkGrey} alt={'add'} />*/}
				<p className='uiBookingButton--text'>{title || strings.book}</p>
			</div>
			{renderBookingModal()}
		</>
	);
}
