import React from 'react';
import './styles';
import UIIcon from '../UIIcon';
import { ZendeskAPI } from 'react-zendesk';

export default function UISegmentedControl({ segments, selectedSegment, updateSelectedSegment, className, style }: UISegmentedControlProps) {
	return (
		<div className={`uiSegmentedControl ${className}`} style={style}>
			{segments.map((segment) => {
				const { icons } = segment;
				const isSelectedSegment = selectedSegment.value === segment.value;

				let iconComponent;
				if (icons) {
					const { white, mint } = icons;
					iconComponent = <UIIcon className='uiSegmentedControl__icon' iconSrc={isSelectedSegment ? mint : white} alt={'segment icon'} />;
				}

				const highlightClassName = segment.highlight ? 'uiSegmentedControl--label-highlighted' : '';

				const labelClassName = isSelectedSegment
					? `uiSegmentedControl--label uiSegmentedControl--label-selected ${highlightClassName}`
					: `uiSegmentedControl--label uiSegmentedControl--label-unselected ${highlightClassName}`;

				let content = (
					<>
						{iconComponent}
						{segment.label && <p className={labelClassName}>{segment.label}</p>}
					</>
				);

				return (
					<div key={segment.value} className='uiSegmentedControl--content' onClick={() => updateSelectedSegment(segment)}>
						{content}
					</div>
				);
			})}
		</div>
	);
}
