import React, { useEffect, useState } from 'react';
import './styles';
import UIBookedOption from '../../../../../components/UI/UIBookedOption';
import { PHOTOSHOOT_TYPE_SERVICE, USER_ROLE_PHOTOGRAPHER } from '../../../../../utils/Config/Constants';
import { convertCents } from '../../../../../utils/Helpers/StringHelpers';
import { getPhotographerPayFromOptions } from '../../../../../utils/Helpers/PaymentHelper/PaymentHelper';
import UICard from '../../../../../components/UI/UICard/UICard';
import { useSelector } from 'react-redux';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../../../redux/selectors/CurrentUserSelectors';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_SERVICE_BOOKED_OPTIONS, UPDATE_SERVICE_ORDER_INFORMATION } from './queries';
import UIIcon from '../../../../../components/UI/UIIcon';
import { Trashcan } from '../../../../../assets/icons';
import UIModal from '../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../components/UI/UIOverlayCard';
import UIIconButton from '../../../../../components/UI/UIIconButton';
import { toast } from 'react-toastify';
import * as strings from './strings';
import { UI_ICON_BUTTON_CANCEL_TYPE } from '../../../../../components/UI/UIIconButton/config';

export default function PLServiceBookedOptions({ service, fetchService }: PLServiceBookedOptionsProps) {
	const signedInUser = useSelector(getSignedInUserSelector);
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const [updateOrderInfo] = useMutation(UPDATE_SERVICE_ORDER_INFORMATION);

	const [bookedOptions, setBookedOptions] = useState<BookedOptionType[]>();
	const [showDeletePackageModal, setShowDeletePackageModal] = useState<boolean>(false);
	const [isDeleting, setIsDeleting] = useState<boolean>(false);

	const [getBookedOptions, { data: bookedOptionsData }] = useLazyQuery(GET_SERVICE_BOOKED_OPTIONS, { variables: { query: { serviceId: service.id } } });

	useEffect(() => {
		(async () => {
			await getBookedOptions();
		})();
	}, []);

	useEffect(() => {
		if (bookedOptionsData) {
			setBookedOptions(bookedOptionsData['bookedOptions']);
		}
	}, [bookedOptionsData]);

	if (!bookedOptions) return null;

	const renderPayComponent = () => {
		return (
			signedInUser.role === USER_ROLE_PHOTOGRAPHER &&
			service.type === PHOTOSHOOT_TYPE_SERVICE &&
			service.photographer?.id === signedInUser.id &&
			!service.photographer?.isInternalTech && (
				<UICard title={strings.techPayCardTitle} description={strings.techPayCardBase}>
					{service.surgeBoost !== null && service.surgeBoost > 0 && (
						<p className='viewOptions--info'>
							Boosted: {service.surgeBoost && <span className='viewOptions--info-price'>{convertCents(service.surgeBoost)}</span>}
						</p>
					)}
					<p className='viewOptions--info'>
						Total: <span className='viewOptions--info-techPay'>${getPhotographerPayFromOptions(service, service.photographer)}</span>
					</p>
				</UICard>
			)
		);
	};

	const renderOrderInformation = () => {
		return (
			service.orderInformation && (
				<div className='viewOptions__package'>
					<p className='viewOptions--title'>Package ordered</p>
					<p className='viewOptions--info-option'>{service.orderInformation.name}</p>
					{isUserAdmin && (
						<>
							<p className='viewOptions--info'>Price</p>
							{service.orderInformation.price && <span className='viewOptions--info-price'>{convertCents(service.orderInformation.price)}</span>}
						</>
					)}
					<p className='viewOptions--info'>Options included</p>
					<div>
						{service.orderInformation?.options?.map((partnerPackageOption) => (
							<span key={partnerPackageOption.name} className='viewOptions--info-option'>
								{partnerPackageOption.name}
							</span>
						))}
					</div>
					{isUserAdmin && (
						<div
							onClick={(e) => {
								e.stopPropagation();
								setShowDeletePackageModal(true);
							}}>
							<UIIcon iconSrc={Trashcan.white} className='viewOptions--delete' alt={'delete'} />
						</div>
					)}
				</div>
			)
		);
	};
	const deletePackage = async () => {
		setIsDeleting(true);
		let variables = {
			serviceId: service.id,
			orderInformation: null
		};
		const response = await updateOrderInfo({ variables });
		const { updateService } = response['data'];

		if (updateService) {
			setIsDeleting(false);
			setShowDeletePackageModal(false);
			toast.success(strings.removePackageSuccess);
			await fetchService();
		}
	};

	const renderDeleteModal = () => {
		const hideModal = () => {
			setShowDeletePackageModal(false);
		};

		return (
			<UIModal condition={showDeletePackageModal}>
				<UIOverlayCard
					hideModal={hideModal}
					title={strings.removePackageTitle}
					description={`This will remove the ${service.orderInformation && service.orderInformation.name} package from this service.`}>
					<UIIconButton
						type={UI_ICON_BUTTON_CANCEL_TYPE}
						isLoading={isDeleting}
						icon={Trashcan.white}
						text={strings.removePackageButton}
						onClick={deletePackage}
					/>
				</UIOverlayCard>
			</UIModal>
		);
	};

	return (
		<div className='viewOptions'>
			{renderPayComponent()}
			{renderOrderInformation()}
			<div className='viewOptions__items'>
				{bookedOptions.map((bookedOption) => (
					<UIBookedOption
						key={bookedOption.id}
						onUpdate={getBookedOptions}
						service={service}
						disableSelection
						disableCost={service.orderInformation !== null}
						bookedOption={bookedOption}
						allBookedOptions={bookedOptions}
					/>
				))}
			</div>
			{renderDeleteModal()}
		</div>
	);
}
