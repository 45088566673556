import gql from 'graphql-tag';

export const CREATE_SCHEDULE_REQUEST = gql`
	mutation CreateScheduleRequest($serviceId: String!, $requestedTimes: [String]!, $agentId: String!, $photographerId: String!) {
		createScheduleRequest(service: $serviceId, requestedTimes: $requestedTimes, agent: $agentId, photographer: $photographerId) {
			id
			updatedAt
			createdAt
			accepted
			acceptedTime
			service {
				id
				serviceDate
				scheduleRequests {
					id
				}
			}
			agent {
				id
				name
			}
			photographer {
				id
				name
				schedulePhotographerRequests {
					id
				}
			}
		}
	}
`;
