import React, { Component } from 'react';
import './ui-video.scss';
import './ui-video-tablet.scss';
import './ui-video-desktop.scss';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player/lazy';
import { withApollo } from '@apollo/react-hoc';
import UIModal from '../UIModal';
import Queries from '../../../utils/API/Queries';
import UIButton from '../UIButton/UIButton';
import UIOverlayCard from '../UIOverlayCard';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../utils/Helpers/AppHelper';

class UIVideo extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	//Mark: Component functions
	toggleIsActive = async () => {
		const { isDeleting } = this.state;
		const { video } = this.props;
		if (!isDeleting) {
			this.setState({ isDeleting: true });
			let newIsActive = !video.isActive;
			const response = await this.props.client.mutate({
				mutation: Queries.UPDATE_FILE,
				variables: { fileId: video.id, isActive: newIsActive }
			});
			if (response['data']) {
				this.props.onUpdate(() =>
					this.setState({ isDeleting: false, renderDeleteModal: false }, () => {
						this.props.showSuccessMessage(`Successfully ${newIsActive === false ? 'deactivated video.' : 'restored video.'}`);
					})
				);
			}
		}
	};

	//Mark: Render functions
	render() {
		const { video, fullScreen, controls, className } = this.props;
		return (
			<div
				className={fullScreen ? `uiVideo uiVideo__fullscreen ` : `uiVideo`}
				style={!video.isActive ? { opacity: 0.75, border: 'var(--red) solid 1px' } : undefined}>
				<ReactPlayer
					controls={controls}
					url={video.printUrl}
					className={`uiVideo--player ${className}`}
					style={fullScreen ? { maxWidth: 'unset', maxHeight: 'unset' } : {}}
				/>
				{fullScreen ? null : this.renderOptions(video)}
				{this.renderDeleteVideoModal()}
			</div>
		);
	}

	renderOptions = (video) => {
		if (!video.isActive) {
			return (
				<div className='uiVideo__content'>
					<p className='uiVideo--filename' style={{ color: 'var(--red)' }}>
						{video.filename}
					</p>
					<div className='uiVideo__content-row'>
						<div className='uiVideo__hide' onClick={this.toggleIsActive}>
							<p className='uiVideo__hide-label'>reactivate this video</p>
						</div>
					</div>
				</div>
			);
		}
	};

	renderDeleteVideoModal = () => {
		const { isDeleting, renderDeleteModal } = this.state;
		const hideModal = () => {
			this.setState({ renderDeleteModal: false });
		};

		return (
			<UIModal condition={renderDeleteModal} hideModal={hideModal}>
				<UIOverlayCard hideModal={hideModal} title={'Are you sure you want to delete this video?'}>
					<div style={{ display: 'flex', flexDirection: 'row', marginTop: 16 }}>
						<UIButton type={UIButton.CANCEL_TYPE} isLoading={isDeleting} text={'yes, delete'} onClick={this.toggleIsActive} />
						<UIButton style={{ marginLeft: 16 }} type={UIButton.OUTLINE_TYPE} text={'cancel'} onClick={hideModal} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};
}

export default withApollo(connect(commonMapStateToProps, commonMapDispatchToProps)(UIVideo));
