import gql from 'graphql-tag';

export const GET_SERVICE_WITH_CLIENT = gql`
	query GetServiceWithClient($id: String!) {
		service(id: $id) {
			id
			type
			serviceDate
			client {
				id
				role
				name
				image
				email
				phoneNumber
			}
			photographer {
				id
				name
				role
			}
			listing {
				id
				client {
					id
				}
			}
			property {
				id
				timezone
				address
			}
			bookedOptions {
				id
				name
				packageType
			}
		}
	}
`;

export const UPDATE_SERVICE_CLIENT = gql`
	mutation UpdateServiceClient($serviceId: String!, $client: String!) {
		updateService(id: $serviceId, client: $client) {
			id
			client {
				id
			}
		}
	}
`;
