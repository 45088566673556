export const PROMO_TYPES = {
	CREDIT: 'Credit',
	SPECIFIC_OPTION: 'Specific Option',
	UNIVERSAL_DISCOUNT: 'Universal Discount'
};
export const DISCOUNT_TYPES = {
	REDUCTION_AMOUNT: 'amount',
	REDUCTION_PERCENTAGE: 'percentage'
};
export class DiscountSummer implements DiscountSummerType {
	promos: PromoType[] = [];
	bookableOptions: BookableOptionType[] = [];
	creditPromos: AppliedPromos[];

	constructor(bookableOptions: BookableOptionType[], promos: PromoType[]) {
		for (let i = 0; i < bookableOptions.length; i++) {
			bookableOptions[i].discountCalculator = new DiscountCalculator(bookableOptions[i], promos);
		}
		this.bookableOptions = bookableOptions;
		this.promos = promos;
		this.creditPromos = this.getCreditPromos();
	}

	getCreditPromos(): AppliedPromos[] {
		let creditPromoIds: string[] = [];
		let creditPromos: AppliedPromos[] = [];

		for (let i = 0; i < this.bookableOptions.length; i++) {
			let bookableOption = this.bookableOptions[i];
			if (bookableOption.discountCalculator) {
				for (let creditPromo of bookableOption.discountCalculator?.creditPromos) {
					if (creditPromoIds.indexOf(creditPromo.id) === -1) {
						//Add to array of ID's for cleaner lookup
						creditPromoIds.push(creditPromo.id);
						creditPromos.push({ promo: creditPromo, discountAmount: creditPromo.discountAmount });
					}
				}
			}
		}
		return creditPromos;
	}

	getLargestCreditAmount() {
		let largestCredit = 0;
		for (let i = 0; i < this.bookableOptions.length; i++) {
			let option = this.bookableOptions[i];
			const optionAvailableCredit = option.discountCalculator?.getOptionCreditsAmount() || 0;

			if (optionAvailableCredit && optionAvailableCredit > largestCredit) {
				largestCredit = optionAvailableCredit;
			}
		}
		return largestCredit;
	}

	getTotalPrice() {
		let largestCredit = 0;
		const optionsTotalPrice = this.bookableOptions.reduce((cumulativeTotal, bookableOption) => {
			const standardPrice = bookableOption.option.price * (bookableOption.selectedQuantity ? bookableOption.selectedQuantity : bookableOption.option.quantity);
			const discountAmount = bookableOption.discountCalculator?.getBookedOptionDiscount() || 0;
			let optionPrice = standardPrice - (discountAmount ? discountAmount : 0);
			if (optionPrice < 0) {
				optionPrice = 0;
			}
			const optionAvailableCredit = bookableOption.discountCalculator?.getOptionCreditsAmount() || 0;

			if (optionAvailableCredit && optionAvailableCredit > largestCredit) {
				largestCredit = optionAvailableCredit;
			}
			return cumulativeTotal + optionPrice;
		}, 0);

		const finalAmount = optionsTotalPrice - largestCredit;
		return Math.ceil(finalAmount >= 0 ? finalAmount : 0);
	}
}
class DiscountCalculator implements DiscountCalculatorType {
	greatestPromo: AppliedPromos = { promo: undefined, discountAmount: 0 };
	eligiblePromos: PromoType[] = [];
	percentagePromos: PromoType[] = [];
	discountPromos: PromoType[] = [];
	creditPromos: PromoType[] = [];
	appliedPromos: AppliedPromos[] = [];
	bookableOption: BookableOptionType;

	constructor(bookableOption: BookableOptionType, promos: PromoType[]) {
		this.eligiblePromos = promos.filter((promo) => promo.isActive && promo.maxUses > promo.uses) || [];
		this.percentagePromos = this.eligiblePromos.filter((eligiblePromo) => eligiblePromo.discountType === DISCOUNT_TYPES.REDUCTION_PERCENTAGE) || [];
		this.discountPromos = this.eligiblePromos.filter(
			(eligiblePromo) => eligiblePromo.discountType === DISCOUNT_TYPES.REDUCTION_AMOUNT || eligiblePromo.discountType === null
		);
		this.creditPromos = this.eligiblePromos.filter((eligiblePromo) => eligiblePromo.promoType === PROMO_TYPES.CREDIT);
		this.bookableOption = bookableOption;
		this.appliedPromos = [];
		const universalDiscount: AppliedPromos = this.getUniversalDiscount();
		const specificDiscount: AppliedPromos = this.getSpecificOptionDiscount();
		const greatestDiscount = universalDiscount.discountAmount >= specificDiscount.discountAmount ? universalDiscount : specificDiscount;
		if (greatestDiscount) this.greatestPromo = greatestDiscount;
	}

	setBookableOption(bookableOption) {
		this.bookableOption = bookableOption;
	}

	getOptionCreditsAmount() {
		return this.creditPromos.reduce((acc, val) => {
			return acc + val.discountAmount;
		}, 0);
	}

	getBookedOptionDiscount() {
		const optionTotal = this.bookableOption.option.price * this.bookableOption.selectedQuantity;
		const totalDiscount = this.greatestPromo.discountAmount;
		// Return total discount unless discount had exceeded original option price, then just reduce full price of option
		return (totalDiscount <= optionTotal ? totalDiscount : optionTotal) * this.bookableOption.selectedQuantity;
	}
	getUniversalDiscount(): AppliedPromos {
		// Choose greatest universal discount
		const percentage = this.getPercentageUniversalDiscount();
		const amount = this.getAmountUniversalDiscount();
		return amount.discountAmount > percentage.discountAmount ? amount : percentage;
	}

	getSpecificOptionDiscount(): AppliedPromos {
		// Choose the greatest specific option
		const percentage: AppliedPromos = this.getPercentageSpecificOption();
		const amount: AppliedPromos = this.getAmountSpecificOption();
		return amount.discountAmount > percentage.discountAmount ? amount : percentage;
	}

	getPercentageUniversalDiscount(): AppliedPromos {
		const universal = this.percentagePromos.filter((percentagePromo) => percentagePromo.promoType === PROMO_TYPES.UNIVERSAL_DISCOUNT);
		const firstUniversal = universal[0];
		if (firstUniversal) {
			return {
				promo: firstUniversal,
				discountAmount: this.bookableOption.option.price * (firstUniversal.discountPercentage / 100)
			};
		}
		return {
			promo: undefined,
			discountAmount: 0
		};
	}

	getAmountUniversalDiscount(): AppliedPromos {
		const universal = this.discountPromos.filter((discountPromo) => discountPromo.promoType === PROMO_TYPES.UNIVERSAL_DISCOUNT);
		const firstUniversal = universal[0];
		if (firstUniversal) {
			return {
				promo: firstUniversal,
				discountAmount: firstUniversal.discountAmount
			};
		}
		return {
			promo: undefined,
			discountAmount: 0
		};
	}

	getPercentageSpecificOption(): AppliedPromos {
		const specificPromos = this.percentagePromos.filter((percentagePromo) => percentagePromo.promoType === PROMO_TYPES.SPECIFIC_OPTION);
		const matchingOptionPromos = specificPromos.filter(
			(percentagePromo) => percentagePromo.option && percentagePromo.option.id === this.bookableOption.option.id
		);
		const matchingOptionPromo = matchingOptionPromos[0];
		if (matchingOptionPromo) {
			return {
				promo: matchingOptionPromo,
				discountAmount: this.bookableOption.option.price * (matchingOptionPromo.discountPercentage / 100)
			};
		}
		return {
			promo: undefined,
			discountAmount: 0
		};
	}

	getAmountSpecificOption(): AppliedPromos {
		const specificOptionPromos = this.discountPromos.filter((discountPromo) => discountPromo.promoType === PROMO_TYPES.SPECIFIC_OPTION);
		const matchingSpecificOptionPromos = specificOptionPromos.filter(
			(specificOptionDiscountPromo) => specificOptionDiscountPromo.option && specificOptionDiscountPromo.option.id === this.bookableOption.option.id
		);
		const matchingSpecificOptionPromo = matchingSpecificOptionPromos[0];
		if (matchingSpecificOptionPromo) {
			return {
				promo: matchingSpecificOptionPromo,
				discountAmount: matchingSpecificOptionPromo.discountAmount
			};
		}
		return {
			promo: undefined,
			discountAmount: 0
		};
	}
}
export default DiscountCalculator;
