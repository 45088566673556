// Created by carsonaberle at 7/18/21
import React, { useState } from 'react';
import './styles';
import UICard from '../../../../../components/UI/UICard/UICard';
import UIIconButton from '../../../../../components/UI/UIIconButton';
import { Elements } from '@stripe/react-stripe-js';
import InjectedCheckoutForm from '../../BookingManager/components/BookingPay/PaymentRequestForm/UIPaymentRequestForm';
import { useSelector } from 'react-redux';
import { getIsUserAdminSelector } from '../../../../../redux/selectors/CurrentUserSelectors';
import { loadStripe } from '@stripe/stripe-js/pure';
import { getPLStripeKey } from '../../../../../utils/Helpers/AppHelper';
import { toast } from 'react-toastify';
import Queries from '../../../../../utils/API/Queries';
import { OPTION_TYPES } from '../../../../../utils/Config/Constants';
import { useMutation } from '@apollo/react-hooks';
import { getErrorMessageFromApolloError } from '../../../../../utils/Helpers/APIHelper';
import { DiscountSummer } from '../../../../../utils/Helpers/DiscountCalculator/DiscountCalculator';

const stripePromise = loadStripe(getPLStripeKey());

export default function EditOptionsPayment({ bookableOptions, service, onUpdate, userToBookFor }: EditOptionsPaymentProps) {
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const [isPaying, setIsPaying] = useState(false);

	const [createPaymentIntent] = useMutation(Queries.CREATE_PAYMENT_INTENT, {
		onError: (e) => {
			setIsPaying(false);
			toast.error(getErrorMessageFromApolloError(e));
		}
	});

	const [upgradeServiceCall] = useMutation(Queries.UPGRADE_SHOOT, {
		onError: (e) => {
			setIsPaying(false);
			toast.error(getErrorMessageFromApolloError(e));
		}
	});

	const getUpgradePrice = () => {
		// if (isUserAdmin) {
		// 	return 0;
		// }
		const discountSummer = new DiscountSummer(bookableOptions, userToBookFor.promos);
		return discountSummer.getTotalPrice();
	};

	const onPaymentSuccess = async () => {
		await onUpdate();
		setIsPaying(false);
		toast.success('Successfully updated service!');
	};

	const onPaymentError = () => {
		setIsPaying(false);
		toast.error('Error updating service!');
	};

	const upgradePrice = getUpgradePrice();

	const renderOption = (bookableOption: BookableOptionType) => {
		return (
			<div key={bookableOption.option.id} className='editOptions__options__option'>
				<p className='editOptions__options__option--name'>{bookableOption.option.name}</p>
			</div>
		);
	};

	const getPaymentIntent = async () => {
		let requestedOptionsArray = bookableOptions.map(
			(option): BookableOptionType => {
				let quantity = option.selectedQuantity;
				if (option.previouslyPurchasedQuantity) {
					quantity += option.previouslyPurchasedQuantity;
				}
				return { ...option, selectedQuantity: quantity };
			}
		);

		let appliedPromos = requestedOptionsArray
			.map((option) => {
				return option.discountCalculator?.greatestPromo;
			})
			.flat()
			.filter((greatestDiscount) => greatestDiscount && greatestDiscount.promo)
			// @ts-ignore
			.map((greatestDiscount) => greatestDiscount.promo.id);

		const response = await createPaymentIntent({
			variables: {
				currency: 'USD',
				id: service.id,
				stripeBookingTotal: getUpgradePrice(),
				requestedOptions: requestedOptionsArray.map((option) => {
					return {
						id: option.option.id,
						quantity: option.selectedQuantity
					};
				}),
				promos: appliedPromos
			}
		});
		if (response) {
			const { createPaymentIntent: createdPaymentIntent } = response['data'];
			if (createdPaymentIntent) {
				return createdPaymentIntent;
			}
		}
		return {};
	};

	const upgradeService = async (paymentIntent: PaymentIntent) => {
		if (paymentIntent.client_secret) {
			let isDroneService = service.isDroneService;
			let includesMatterport = service.includesMatterport;

			let requestedOptionsArray = bookableOptions.map((bookableOption) => {
				let quantity = bookableOption.selectedQuantity;

				return {
					option: bookableOption.option.id,
					quantity: quantity,
					requiredInfo: bookableOption.option.requiredInfo
				};
			});

			for (let i = 0; i < bookableOptions.length; i++) {
				if (bookableOptions[i].option.type === OPTION_TYPES.drone) {
					isDroneService = true;
				}
				if (bookableOptions[i].option.type === OPTION_TYPES.matterport) {
					includesMatterport = true;
				}
			}

			let variables = {
				serviceId: service.id,
				requestedOptions: requestedOptionsArray,
				isDroneService: isDroneService,
				includesMatterport: includesMatterport,
				stripeBookingTotal: getUpgradePrice(),
				stripePaymentToken: paymentIntent.paymentIntentId,
				currency: 'USD'
			};

			const upgradeResponse = await upgradeServiceCall({ variables });
			if (upgradeResponse) {
				const { upgradeService: upgradedService } = upgradeResponse['data'];
				if (upgradedService) {
					await onPaymentSuccess();
					return;
				}
			}
		}
	};

	if (bookableOptions.length > 0) {
		return (
			<UICard title={isUserAdmin ? 'Add service options' : 'Enter your payment details'} style={{ width: '100%', marginBottom: 24 }}>
				<div className='editOptions__options'>{bookableOptions.map(renderOption)}</div>
				{upgradePrice === 0 || isUserAdmin ? (
					<UIIconButton
						text={'save'}
						isLoading={isPaying}
						onClick={async () => {
							setIsPaying(true);
							const paymentIntent = await getPaymentIntent();
							if (!paymentIntent.client_secret) {
								setIsPaying(false);
							}
							await upgradeService(paymentIntent);
						}}
					/>
				) : (
					<div style={{ margin: '24px 0 0 0' }}>
						<Elements stripe={stripePromise}>
							<InjectedCheckoutForm
								amount={upgradePrice}
								getPaymentIntent={getPaymentIntent}
								isPaying={isPaying}
								updateIsPaying={setIsPaying}
								onPaymentSuccess={upgradeService}
								onPaymentError={onPaymentError}
							/>
						</Elements>
					</div>
				)}
			</UICard>
		);
	}
	return null;
}
