import React, { Component } from 'react';
import './ui-checkbox.scss';
import { connect } from 'react-redux';
import { CheckboxIcon } from '../../../assets/icons';
import PropTypes from 'prop-types';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../utils/Helpers/AppHelper';

class UICheckbox extends Component {
	static UNCHECKED = 'unchecked';
	static CHECKED = 'checked';
	static EXPANDED = 'expanded';

	constructor(props) {
		super(props);

		this.state = {};
	}

	//Mark: Component functions
	getImageClassnameFromStatus = () => {
		const { subBox, status } = this.props;
		switch (status) {
			case UICheckbox.CHECKED:
			case UICheckbox.EXPANDED:
				if (subBox) {
					return 'checkbox-check-container-selected checkbox-check-container-selected--sub';
				}
				return 'checkbox-check-container-selected';
			case UICheckbox.UNCHECKED:
			default:
				if (subBox) {
					return 'checkbox-check-container checkbox-check-container--sub';
				}
				return 'checkbox-check-container';
		}
	};

	getImageSourceFromStatus = () => {
		const { status } = this.props;
		switch (status) {
			case UICheckbox.CHECKED:
				return CheckboxIcon.mintChecked;
			case UICheckbox.UNCHECKED:
				return undefined;
			case UICheckbox.EXPANDED:
				return CheckboxIcon.expanded;
			default:
				return undefined;
		}
	};

	getLabelClassName = () => {
		const { status } = this.props;
		switch (status) {
			case UICheckbox.CHECKED:
				return 'checkbox-label-selected';
			case UICheckbox.UNCHECKED:
				return 'checkbox-label';
			case UICheckbox.EXPANDED:
				return 'checkbox-label-selected';
			default:
				return 'checkbox-label';
		}
	};

	//Mark: Render functions
	render() {
		const { onChangeExpansion, subBox, className, style, status, label, id } = this.props;
		return (
			<div
				id={id}
				className={`uiCheckbox ${className}`}
				style={style}
				onClick={(e) => {
					onChangeExpansion();
					e.stopPropagation();
				}}>
				<div className={subBox ? 'checkbox-check-container checkbox-check-container--sub' : 'checkbox-check-container'}>
					{status === UICheckbox.UNCHECKED ? undefined : (
						<img className={this.getImageClassnameFromStatus()} alt={'checkbox'} src={this.getImageSourceFromStatus()} />
					)}
				</div>
				<p className={this.getLabelClassName()}>{label}</p>
			</div>
		);
	}
}

UICheckbox.propTypes = {
	onChangeExpansion: PropTypes.func.isRequired,
	subBox: PropTypes.bool,
	className: PropTypes.string,
	id: PropTypes.string,
	status: PropTypes.oneOf([UICheckbox.CHECKED, UICheckbox.UNCHECKED, UICheckbox.EXPANDED]).isRequired,
	label: PropTypes.string,
	style: PropTypes.object
};

export default connect(commonMapStateToProps, commonMapDispatchToProps)(UICheckbox);
