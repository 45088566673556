import React, { useEffect, useState } from 'react';
import './styles';
import UserActionRow from '../Notifications/UserActionRow';
import Lottie from 'react-lottie';
import Queries from '../../../../../utils/API/Queries';
import { LottieAnimations } from '../../../../../assets/icons';
import moment from 'moment-timezone';
import { useLazyQuery } from '@apollo/react-hooks';

export default function ServiceHistory({ serviceId }: ServiceHistoryProps) {
	const [actions, setActions] = useState<ServiceActionType[]>([]);

	const [getActions, { data: actionsData }] = useLazyQuery(Queries.GET_ACTIONS, {
		variables: { limit: 400, order: [['updatedAt', 'DESC']], q: { serviceId: serviceId } }
	});

	//Mark: Lifecycle functions

	useEffect(() => {
		(async () => {
			await getActions();
		})();
	}, []);

	useEffect(() => {
		if (actionsData) {
			setActions(actionsData['actions']);
		}
	}, [actionsData]);

	const renderNotifications = () => {
		const newDayIndexes = getNewDayIndexes();
		const shouldShowDay = (index) => {
			return newDayIndexes.filter((newDayIndex) => newDayIndex === index).length > 0;
		};

		return actions.map((action, index) => <UserActionRow key={index} shouldShowDay={shouldShowDay(index)} action={action} queryActions={getActions} />);
	};

	const getNewDayIndexes = () => {
		const newDayIndexes: number[] = [];

		actions.forEach((action, index) => {
			if (index === 0) {
				newDayIndexes.push(index);
				return;
			}
			let previousDay = moment(actions[index - 1].createdAt);
			let currentDay = moment(action.createdAt);
			if (!previousDay.isSame(currentDay, 'day')) {
				newDayIndexes.push(index);
			}
		});

		return newDayIndexes;
	};

	if (!actions) {
		return (
			<div key={actions}>
				<Lottie
					options={{
						loop: true,
						autoplay: true,
						// @ts-ignore
						path: LottieAnimations.cameraHomeIcon,
						rendererSettings: {
							preserveAspectRatio: 'xMidYMid slice'
						}
					}}
					height={'100%'}
					width={'100%'}
					isStopped={false}
					isPaused={false}
				/>
			</div>
		);
	}
	return <div className='servicesMobileContainer'>{renderNotifications()}</div>;
}
