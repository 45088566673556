import { FILE_STATUS_LIVE, FILE_TYPE_IMAGE, FILE_TYPE_RAW_IMAGE, USER_ROLE_EDITING_MANAGER, USER_ROLE_EDITOR } from '../Config/Constants';
import Queries from '../API/Queries';
import { isUserAdminOrRM } from './UserHelpers';
import { ApolloQueryResult } from 'apollo-client';

export const getImagesFrom = (files: FileType[]): FileType[] => {
	if (files) {
		return files.filter((file) => file.type === FILE_TYPE_IMAGE && file.isActive).sort((a, b) => a.index - b.index);
	}
	return [];
};

export const getServiceRequestsOnFile = (serviceRequests: ServiceRequestType[], file: FileType) => {
	return serviceRequests.filter(
		(serviceRequest) => serviceRequest.requestedFiles && serviceRequest.requestedFiles.map((requestedFile) => requestedFile.id).indexOf(file.id) !== -1
	);
};

export const getImages = (files: FileType[]) => {
	if (files) {
		return files.filter((file) => file.type === FILE_TYPE_IMAGE).sort((a, b) => a.index - b.index);
	}
	return [];
};

export const filterLiveImages = (file: FileType) => {
	return file.status === FILE_STATUS_LIVE;
};

export const getVisibleImagesFrom = (files: FileType[]) => {
	if (files) {
		return files.filter((file) => file.isVisible && file.type === FILE_TYPE_IMAGE && file.isActive).sort((a, b) => a.index - b.index);
	}
	return [];
};

export const getRawImages = (files: FileType[]) => {
	if (files) {
		return files.filter((file) => file.type === FILE_TYPE_RAW_IMAGE && file.isActive).sort((a, b) => a.index - b.index);
	}
	return [];
};

export const toggleFileVisibility = (file: FileType, client: any, completion: Function) => {
	client
		.mutate({
			mutation: Queries.UPDATE_FILE,
			variables: { fileId: file.id, isVisible: !file.isVisible, status: file.status }
		})
		.then((response: ApolloQueryResult<any>) => {
			const { updateFile } = response.data;

			if (updateFile) {
				completion(file);
			}
		});
};

/**
 * @returns {Array} Filtered files by type
 */
export const filterFiles = (files: FileType[], user: UserType, fileType: string) => {
	if (!fileType) {
		fileType = FILE_TYPE_IMAGE;
	}
	if (isUserAdminOrRM(user) || user.role === USER_ROLE_EDITING_MANAGER || user.role === USER_ROLE_EDITOR) {
		return files.filter((file) => file.type === fileType && file.isActive).sort((a, b) => a.index - b.index);
	}
	return files.filter((file) => file.status === FILE_STATUS_LIVE && file.type === fileType && file.isActive).sort((a, b) => a.index - b.index);
};

export const getTotalNumberOfFilesAvailable = (service?: ServiceType, previouslyPurchasedCount?: number): number => {
	let amount =
		service?.bookedOptions
			.map((bookedOption) => bookedOption.option && bookedOption.option.finalFileCount * bookedOption.quantity)
			.reduce((total, acc) => acc + total, 0) ?? 0;
	if (previouslyPurchasedCount) {
		amount = amount - previouslyPurchasedCount;
	}
	return amount < 0 ? 0 : amount;
};

export const getTotalNumberOfPhotographerUploadFilesAvailable = (service: ServiceType, previouslyPurchasedCount?: number): number => {
	let amount = service.bookedOptions
		.map((bookedOption) => bookedOption.option && bookedOption.option.photographerFileCount * bookedOption.quantity)
		.reduce((total, acc) => acc + total, 0);
	if (previouslyPurchasedCount) {
		amount = amount - previouslyPurchasedCount;
	}
	return amount < 0 ? 0 : amount;
};
