import moment from 'moment-timezone';

export let API_URLS = {
	prod: 'https://prod-portal-api.pineapplelabs.io/api',
	prodAsia: 'https://prod-asia-api.pineapplelabs.io/api',
	dev: 'https://dev-portal-api.pineapplelabs.io/api',
	localDev: 'https://dev-portal-api.pineapplelabs.io/api',
	local: 'http://localhost:8088/api'
};

export const getApiUrl = (baseOnly) => {
	let url;
	switch (process.env.PL_ENVIRONMENT) {
		case 'LIVE':
			url = API_URLS.prod;
			break;
		case 'DEV':
			url = API_URLS.dev;
			break;
		case 'LOCAL_DEV':
			url = API_URLS.localDev;
			break;
		case 'LOCAL':
			url = API_URLS.local;
			break;
		default:
			url = API_URLS.prod;
			break;
	}
	if (baseOnly) {
		return url.replace('/api', '');
	}

	return url;
};

export const getApiDownloadUrl = (baseOnly) => {
	let url;
	if (process.env.PL_ENVIRONMENT === 'LIVE') {
		const momentOffset = moment().utcOffset();
		const utcOffset = (momentOffset ? momentOffset : 0) / 60;
		url = API_URLS.prod;
		if (utcOffset > 3) {
			url = API_URLS.prodAsia;
		}
		
		if (baseOnly) {
			url = url.replace('/api', '');
		}
	} else {
		url = getApiUrl(baseOnly);
	}
	return url;
};
