import React, { useEffect, useState } from 'react';
import './styles/service-service-requests.scss';
import './styles/service-service-requests-tablet.scss';
import './styles/service-service-requests-desktop.scss';
import Queries from '../../../../../utils/API/Queries';
import PLTable from '../../../../../components/PL/PLTable/PLTable';
import UILoadingPage from '../../../../../components/UI/UILoadingPage/UILoadingPage';
import { SERVICE_REQUEST_TABLE_TYPE } from '../../../../../components/PL/PLTable/config';
import { useApolloClient } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { ServiceServiceRequestsProps } from './types';

const ServiceServiceRequests = ({ serviceId, clearSidebar, hideAddress }: ServiceServiceRequestsProps) => {
	const [serviceRequests, setServiceRequests] = useState<ServiceRequestType[]>([]);

	const history = useHistory();
	const client = useApolloClient();

	useEffect(() => {
		queryServiceRequests().then();
	}, []);

	const queryServiceRequests = async () => {
		await client
			.mutate({
				mutation: Queries.GET_SERVICE_REQUESTS,
				variables: {
					query: {
						serviceId: serviceId
					}
				}
			})
			.then((response) => {
				const { serviceRequests } = response['data'];

				if (serviceRequests) {
					setServiceRequests(serviceRequests);
				}
			});
	};

	const routeToServiceRequest = (serviceRequest) => {
		clearSidebar();
		history.push('/service-request/' + serviceRequest.id);
	};

	if (!serviceRequests) {
		return <UILoadingPage />;
	}
	return (
		<div className='serviceServiceRequest'>
			<PLTable
				hideAddress={hideAddress}
				emptySetText={'There are no service requests for this service.'}
				type={SERVICE_REQUEST_TABLE_TYPE}
				limit={100}
				updateTable={queryServiceRequests}
				data={serviceRequests}
				onSelect={routeToServiceRequest}
			/>
		</div>
	);
};

export default ServiceServiceRequests;
