import gql from 'graphql-tag';

export const GET_SERVICE_REQ_TECHS = gql`
	query Service($id: String!) {
		service(id: $id) {
			id
			type
			status
			preferredPhotographers {
				id
				name
				email
				image
			}
			region {
				id
				name
			}
			bookedOptions {
				id
				name
				photographerCertificatesRequired
			}
		}
	}
`;
