import styled from 'styled-components';

export const div_UIAvailableOptionsWrapper = styled.div`
	//DEFAULT
	width: 100%;
	margin: 12px 0;
`;
export const div_UIAvailableOptionsContainer = styled.div`
	//DEFAULT
	display: flex;
	flex-direction: column;
	padding: 24px;
	margin: 6px 6px 6px 0;
	height: fit-content;
	border-radius: 6px;
	justify-content: flex-start;
	width: 100%;
	border: #f1f1f12c solid 2px;
`;

export const p_UIAvailableOptionName = styled.p`
	//DEFAULT
	font-size: 16pt;
	font-weight: 600;
`;

export const p_UIAvailableOptionPay = styled.p`
	//DEFAULT
	font-weight: 600;
	font-size: 12pt;
	color: var(--mint-primary);
	margin-bottom: 6px;
`;

export const p_UIAvailableOptionInstructions = styled.p`
	//DEFAULT
	font-weight: 500;
	font-size: 12pt;
	margin-top: 12px;
	transition: 200ms all ease-out;
	text-decoration: underline;

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		&:hover {
			cursor: pointer;
			color: var(--mint-primary);
		}
	}
`;

export const a_UIAvailableOptionsExample = styled.a`
	//DEFAULT
	font-size: 12pt;
	font-weight: 600;
	color: var(--mint-primary);

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		&:hover {
			text-decoration: underline;
		}
	}
`;

export const div_UIAvailableOptionOptions = styled.div`
	//DEFAULT
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
`;
