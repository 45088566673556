import React from 'react';
import { asyncComponent } from '@jaredpalmer/after';
import {
	ActionNeededIcon,
	AffiliatesIcon,
	AgentIcon,
	DocumentIcon,
	EditIcon,
	EditingCompaniesIcon,
	EditServicesIcon,
	EducationIcon,
	FinancesIcon,
	InviteCodes,
	ListingIcon,
	PineappleServicesIcon,
	RegionIcon,
	ServicesIcon,
	TechniciansIcon
} from './assets/icons';

const placeholderComponent = (): any => <div />;

export const SIGNUP: RouteType = {
	name: 'Signup',
	title: 'Signup',
	path: '/signup',
	exact: true,
	chunkName: 'Marketing',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Marketing" */ './pages/public/Signup'),
		Placeholder: placeholderComponent
	})
};

export const MARKETING_SITE: RouteType = {
	name: 'MarketingSite',
	title: 'Marketing site',
	path: '/',
	exact: true,
	chunkName: 'MarketingSite',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "MarketingSite" */ './pages/public/Marketing Sites'),
		Placeholder: placeholderComponent
	})
};

// ADMIN
export const ADMIN_HOME: RouteType = {
	name: 'AdminTasks',
	title: 'Tasks',
	path: '/admin-tasks',
	exact: true,
	showInPanel: true,
	icon: ActionNeededIcon,
	chunkName: 'Admin',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Admin" */ './pages/portal/roles/Admin/AdminTasks'),
		Placeholder: placeholderComponent
	})
};

export const ADMIN_SERVICES: RouteType = {
	name: 'AdminServices',
	title: 'Services',
	path: '/services',
	exact: true,
	icon: ServicesIcon,
	showInPanel: true,
	chunkName: 'Admin',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Admin" */ './pages/portal/roles/Admin/AdminServices'),
		Placeholder: placeholderComponent
	})
};

export const ADMIN_TECHNICIANS: RouteType = {
	name: 'AdminTechnicians',
	title: 'Technicians',
	path: '/technicians',
	exact: true,
	icon: TechniciansIcon,
	showInPanel: true,
	chunkName: 'Admin',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Admin" */ './pages/portal/roles/Admin/AdminTechnicians'),
		Placeholder: placeholderComponent
	})
};

export const ADMIN_AGENTS: RouteType = {
	name: 'AdminAgents',
	title: 'Agents',
	path: '/agents',
	exact: true,
	icon: AgentIcon,
	showInPanel: true,
	chunkName: 'Admin',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Admin" */ './pages/portal/roles/Admin/AdminAgents'),
		Placeholder: placeholderComponent
	})
};

export const ADMIN_EDITING_COMPANIES: RouteType = {
	name: 'AdminEditingCompanies',
	title: 'Editing',
	path: '/edit-partners',
	exact: true,
	icon: EditingCompaniesIcon,
	showInPanel: true,
	chunkName: 'AdminEditingCompanies',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Admin" */ './pages/portal/roles/Admin/AdminEditingCompanies'),
		Placeholder: placeholderComponent
	})
};

export const ADMIN_REGIONS: RouteType = {
	name: 'AdminRegions',
	title: 'Regions',
	path: '/manage-regions',
	exact: true,
	icon: RegionIcon.white,
	showInPanel: true,
	chunkName: 'Admin',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Admin" */ './pages/portal/roles/Admin/AdminRegions'),
		Placeholder: placeholderComponent
	})
};

export const ADMIN_AFFILIATES: RouteType = {
	name: 'AdminAffiliates',
	title: 'Affiliates',
	path: '/affiliates',
	exact: true,
	icon: AffiliatesIcon,
	showInPanel: true,
	chunkName: 'Admin',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Admin" */ './pages/portal/roles/Admin/AdminAffiliates'),
		Placeholder: placeholderComponent
	})
};

export const ADMIN_INVITE_CODES: RouteType = {
	name: 'AdminInviteCodes',
	title: 'Invite codes',
	path: '/invite-codes',
	exact: true,
	icon: InviteCodes,
	showInPanel: true,
	chunkName: 'Admin',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Admin" */ './pages/portal/roles/Admin/AdminInviteCodes'),
		Placeholder: placeholderComponent
	})
};

export const ADMIN_PAYMENT: RouteType = {
	name: 'AdminPayment',
	title: 'Finances',
	path: '/finances',
	exact: true,
	icon: FinancesIcon,
	showInPanel: true,
	chunkName: 'Admin',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Admin" */ './pages/portal/roles/Admin/AdminFinances'),
		Placeholder: placeholderComponent
	})
};

export const ADMIN_LISTINGS: RouteType = {
	name: 'AdminListing',
	title: 'Listings',
	path: '/listings',
	exact: true,
	icon: ListingIcon,
	showInPanel: true,
	chunkName: 'Admin',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Admin" */ './pages/portal/roles/Admin/AdminListings'),
		Placeholder: placeholderComponent
	})
};

export const ADMIN_SERVICE_REQUESTS: RouteType = {
	name: 'AdminServiceRequests',
	title: 'Service Requests',
	path: '/service-requests',
	exact: true,
	icon: EditServicesIcon,
	showInPanel: true,
	chunkName: 'Admin',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Admin" */ './pages/portal/roles/Admin/AdminServiceRequests'),
		Placeholder: placeholderComponent
	})
};

export const AGENT_LISTINGS: RouteType = {
	name: 'AgentListings',
	title: 'My listings',
	path: '/my-listings',
	exact: true,
	icon: ListingIcon,
	showInPanel: true,
	chunkName: 'Agent',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Agent" */ './pages/portal/roles/Agent/AgentListings'),
		Placeholder: placeholderComponent
	})
};

export const AGENT_PENDING_SERVICES: RouteType = {
	name: 'AgentPendingServices',
	title: 'Pending services',
	path: '/agent-pending-services',
	exact: true,
	icon: ServicesIcon,
	showInPanel: true,
	chunkName: 'Agent',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Agent" */ './pages/portal/roles/Agent/AgentPendingServices'),
		Placeholder: placeholderComponent
	})
};

export const EDITOR_SERVICE_REQUESTS: RouteType = {
	name: 'EditorServiceRequests',
	title: 'Service requests',
	path: '/edit-service-requests',
	exact: true,
	icon: ActionNeededIcon,
	showInPanel: true,
	chunkName: 'Editor',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Editor" */ './pages/portal/roles/Editor/EditorServiceRequests'),
		Placeholder: placeholderComponent
	})
};

export const EDITOR_SERVICES: RouteType = {
	name: 'EditorServices',
	title: 'Edits',
	path: '/edit-services',
	exact: true,
	icon: EditIcon.white,
	showInPanel: true,
	chunkName: 'Editor',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Editor" */ './pages/portal/roles/Editor/EditorServices'),
		Placeholder: placeholderComponent
	})
};

export const EDITING_MANAGER_HOME: RouteType = {
	name: 'EditingManagerHome',
	title: 'Services',
	path: '/edit-provider-services',
	exact: true,
	icon: PineappleServicesIcon,
	showInPanel: true,
	chunkName: 'EditingManager',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "EditingManager" */ './pages/portal/roles/EditingManager/EditingManagerHome/EditingManagerHome'),
		Placeholder: placeholderComponent
	})
};

export const EDITING_MANAGER_PENDING: RouteType = {
	name: 'EditingManagerEdits',
	title: 'Edits',
	path: '/all-pending-edits',
	exact: true,
	icon: EditIcon.white,
	showInPanel: true,
	chunkName: 'EditingManager',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "EditingManager" */ './pages/portal/roles/EditingManager/EditingManagerPending/EditingManagerPending'),
		Placeholder: placeholderComponent
	})
};

export const EDITING_MANAGER_EDITORS: RouteType = {
	name: 'EditingManagerEditors',
	title: 'Editors',
	path: '/editors',
	exact: true,
	icon: EditingCompaniesIcon,
	showInPanel: true,
	chunkName: 'EditingManager',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "EditingManager" */ './pages/portal/roles/EditingManager/EditingManagerEditors/EditingManagerEditors'),
		Placeholder: placeholderComponent
	})
};

export const TECHNICIAN_STRIPE_SIGNUP: RouteType = {
	name: 'TechnicianStripeSignup',
	title: 'Stripe signup',
	path: '/stripe-signup',
	exact: true,
	icon: DocumentIcon,
	showInPanel: true,
	chunkName: 'Technician',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Technician" */ './pages/portal/roles/Technician/TechnicianStripeSignup'),
		Placeholder: placeholderComponent
	})
};

export const TECHNICIAN_PINEAPPLE_SERVICES: RouteType = {
	name: 'TechnicianPineappleServices',
	title: 'Services',
	path: '/pineapple-services',
	exact: true,
	icon: PineappleServicesIcon,
	showInPanel: true,
	chunkName: 'Technician',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Technician" */ './pages/portal/roles/Technician/TechnicianPineappleServices'),
		Placeholder: placeholderComponent
	})
};

export const TECHNICIAN_LISTINGS: RouteType = {
	name: 'TechnicianListings',
	title: 'My listings',
	path: '/personal-listings',
	exact: true,
	icon: ListingIcon,
	showInPanel: true,
	chunkName: 'Technician',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Technician" */ './pages/portal/roles/Technician/TechnicianListings'),
		Placeholder: placeholderComponent
	})
};

export const TECHNICIAN_EDUCATION: RouteType = {
	name: 'TechnicianResources',
	title: 'Resources',
	path: '/resources',
	exact: true,
	icon: EducationIcon,
	showInPanel: true,
	chunkName: 'Technician',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Technician" */ './pages/portal/roles/Technician/TechnicianResources'),
		Placeholder: placeholderComponent
	})
};

export const AFFILIATE_AGENTS: RouteType = {
	name: 'AffiliateAgents',
	title: 'Signups',
	path: '/affiliate-agents',
	exact: true,
	icon: AgentIcon,
	showInPanel: true,
	chunkName: 'Affiliate',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Affiliate" */ './pages/portal/roles/Sales/SalesAgents/SalesAgents'),
		Placeholder: placeholderComponent
	})
};

export const AFFILIATE_INVITES: RouteType = {
	name: 'SalespersonInvites',
	title: 'Invite codes',
	path: '/affiliate-invites',
	exact: true,
	icon: InviteCodes,
	showInPanel: true,
	chunkName: 'Sales',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Sales" */ './pages/portal/roles/Sales/SalesInvites/SalesInvites'),
		Placeholder: placeholderComponent
	})
};

export const AFFILIATE_PAYMENT: RouteType = {
	name: 'AffiliatePayments',
	title: 'Payments',
	path: '/sales-payments',
	exact: true,
	icon: FinancesIcon,
	showInPanel: true,
	chunkName: 'Affiliate',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Affiliate" */ './pages/portal/roles/Sales/SalesPayments/SalesPayments'),
		Placeholder: placeholderComponent
	})
};

export const SALESPERSON_AGENTS: RouteType = {
	name: 'SalespersonAgents',
	title: 'Signups',
	path: '/sales-agents',
	exact: true,
	icon: AgentIcon,
	showInPanel: true,
	chunkName: 'Sales',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Sales" */ './pages/portal/roles/Sales/SalesAgents/SalesAgents'),
		Placeholder: placeholderComponent
	})
};

export const SALESPERSON_INVITES: RouteType = {
	name: 'SalespersonInvites',
	title: 'Invite codes',
	path: '/sales-invites',
	exact: true,
	icon: InviteCodes,
	showInPanel: true,
	chunkName: 'Sales',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Sales" */ './pages/portal/roles/Sales/SalesInvites/SalesInvites'),
		Placeholder: placeholderComponent
	})
};

export const SALESPERSON_PAYMENT: RouteType = {
	name: 'SalespersonPayments',
	title: 'Payments',
	path: '/sales-payments',
	exact: true,
	icon: FinancesIcon,
	showInPanel: true,
	chunkName: 'Sales',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Sales" */ './pages/portal/roles/Sales/SalesPayments/SalesPayments'),
		Placeholder: placeholderComponent
	})
};

export const INDIVIDUAL_AGENT: RouteType = {
	name: 'IndividualAgent',
	title: 'Agent',
	path: '/agent/:id',
	exact: true,
	noPanel: true,
	chunkName: 'Common',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Common" */ './pages/portal/global/IndividualAgent'),
		Placeholder: placeholderComponent
	})
};

export const INDIVIDUAL_SERVICE_REQUEST: RouteType = {
	name: 'IndividualServiceRequest',
	title: 'ServiceRequest',
	path: '/service-request/:id',
	exact: true,
	noPanel: true,
	chunkName: 'Common',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Common" */ './pages/portal/global/IndividualServiceRequest/IndividualServiceRequest'),
		Placeholder: placeholderComponent
	})
};

export const INDIVIDUAL_TECHNICIAN: RouteType = {
	name: 'IndividualTechnician',
	title: 'Technician',
	path: '/photographer/:id',
	exact: true,
	noPanel: true,
	chunkName: 'Common',
	skipAllOnBack: true,
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Common" */ './pages/portal/global/IndividualTechnician'),
		Placeholder: placeholderComponent
	})
};

export const INDIVIDUAL_EDITING_COMPANY: RouteType = {
	name: 'IndividualEditingCompany',
	title: 'Editing company',
	path: '/editing-company/:id',
	exact: true,
	noPanel: true,
	skipAllOnBack: true,
	chunkName: 'Common',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Common" */ './pages/portal/global/IndividualEditingCompany'),
		Placeholder: placeholderComponent
	})
};

export const INDIVIDUAL_EDITOR: RouteType = {
	name: 'IndividualEditor',
	title: 'Editor',
	path: '/editor/:id',
	exact: true,
	noPanel: true,
	skipAllOnBack: true,
	chunkName: 'Common',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Common" */ './pages/portal/global/IndividualEditor/IndividualEditor'),
		Placeholder: placeholderComponent
	})
};

export const INDIVIDUAL_INVITE_CODE: RouteType = {
	name: 'IndividualInviteCode',
	title: 'Invite code',
	path: '/invitecode/:id',
	exact: true,
	noPanel: true,
	skipAllOnBack: true,
	chunkName: 'Common',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Common" */ './pages/portal/global/IndividualInviteCode'),
		Placeholder: placeholderComponent
	})
};

export const INDIVIDUAL_LISTING: RouteType = {
	name: 'IndividualListing',
	title: 'Listing',
	path: '/listing/:id',
	exact: true,
	showInPanel: true,
	noPanel: true,
	rightMobileMenu: true,
	chunkName: 'Common',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Common" */ './pages/portal/global/IndividualListing'),
		Placeholder: placeholderComponent
	})
};

export const VIRTUAL_TOUR: RouteType = {
	name: 'VirtualTour',
	title: 'Virtual tour',
	path: '/virtualtour/:id/:page?',
	exact: true,
	noPanel: true,
	noHeader: true,
	chunkName: 'VirtualTour',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "VirtualTour" */ './pages/public/VirtualTour'),
		Placeholder: placeholderComponent
	})
};

export const EXTERNAL_VIRTUAL_TOUR: RouteType = {
	name: 'ExternalVirtualTour',
	title: 'Virtual tour',
	path: '/external/:id/:page?',
	exact: true,
	noPanel: true,
	noHeader: true,
	chunkName: 'VirtualTour',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "VirtualTour" */ './pages/public/VirtualTour'),
		Placeholder: placeholderComponent
	})
};

export const INDIVIDUAL_REGION: RouteType = {
	name: 'IndividualRegion',
	title: 'Regions',
	path: '/region/:infoPath',
	exact: true,
	noPanel: true,
	chunkName: 'Admin',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Admin" */ './pages/portal/global/IndividualRegion'),
		Placeholder: placeholderComponent
	})
};

export const INDIVIDUAL_SALESPERSON: RouteType = {
	name: 'IndividualSalesperson',
	title: 'Salesperson',
	path: '/salesperson/:id',
	exact: true,
	noPanel: true,
	skipAllOnBack: true,
	chunkName: 'Admin',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Admin" */ './pages/portal/global/IndividualSalesperson'),
		Placeholder: placeholderComponent
	})
};

export const CONTACT: RouteType = {
	name: 'GetStarted',
	title: 'Contact',
	path: '/contact',
	exact: true,
	noPanel: true,
	noHeader: true,
	chunkName: 'Marketing',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Marketing" */ './components/Homepage/Contact/Contact'),
		Placeholder: placeholderComponent
	})
};

export const HOME_PREPARATION: RouteType = {
	name: 'HomePreparation',
	title: 'Home preparation',
	path: '/home-preparation',
	exact: true,
	noPanel: true,
	noHeader: true,
	chunkName: 'Marketing',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Marketing" */ './pages/public/HomePreparation/HomePreparation'),
		Placeholder: placeholderComponent
	})
};

export const PRIVACY_POLICY: RouteType = {
	name: 'PrivacyPolicy',
	title: 'Privacy Policy',
	path: '/privacy-policy',
	exact: true,
	noPanel: true,
	chunkName: 'Marketing',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Marketing" */ './pages/public/PrivacyPolicyAndTerms/PrivacyPolicy'),
		Placeholder: placeholderComponent
	})
};

export const TERMS_OF_USE: RouteType = {
	name: 'TermsOfUse',
	title: 'Terms Of Use',
	path: '/terms-of-use',
	exact: true,
	noPanel: true,
	chunkName: 'Marketing',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Marketing" */ './pages/public/PrivacyPolicyAndTerms/TermsOfUse'),
		Placeholder: placeholderComponent
	})
};

export const BRAND_BOOK: RouteType = {
	name: 'BrandBook',
	title: 'Brand Book',
	path: '/brand-book',
	exact: true,
	noPanel: true,
	chunkName: 'Marketing',
	component: asyncComponent({
		loader: () => import(/* webpackChunkName: "Marketing" */ './pages/public/BrandBook/BrandBook'),
		Placeholder: placeholderComponent
	})
};

export function isRouteExcludedFromAuth(matchingRoute) {
	return [MARKETING_SITE, SIGNUP, CONTACT, HOME_PREPARATION, VIRTUAL_TOUR, EXTERNAL_VIRTUAL_TOUR, INDIVIDUAL_REGION, PRIVACY_POLICY, TERMS_OF_USE].includes(
		matchingRoute
	);
}
