import React from "react";
import * as S from "./styles";
import * as strings from "./strings";
import { BackIcon, CloseIcon } from "../../../assets/icons";
import UILoadingBar from "../UILoadingBar";

export const OVERLAY_CLOSE_TYPES = {
   close: CloseIcon.white,
   back: BackIcon.white
};

export default function UIOverlayCard({
                                         className,
                                         style,
                                         isLoading,
                                         padding,
                                         title,
                                         titleAlt,
                                         description,
                                         margin,
                                         hideModal,
                                         closeType,
                                         noClose,
                                         contentClassName,
                                         cardStyle,
                                         rightItem,
                                         children,
                                         noLoader = false
                                      }: UIOverlayCardProps) {
   const getStyleFromProps = () => {
      let returnStyle = style ?? {};

      if (padding !== undefined) {
         returnStyle = { ...returnStyle, padding: padding };
      }

      if (margin !== undefined) {
         returnStyle = { ...returnStyle, margin: margin };
      }

      return returnStyle;
   };

   const onClickCard = (e) => {
      e.stopPropagation();
   };

   return (
      <S.div_UIOverlayCardContainer style={cardStyle} className={className} onClick={onClickCard}>
         <S.div_UIOverlayCardTop>
            {!noClose && <S.UIIcon_UIOverlayCardTopIcon iconSrc={closeType || CloseIcon.white} alt={strings.back} onClick={hideModal} />}
            {title && <S.p_UIOverlayCardTitle>{title}</S.p_UIOverlayCardTitle>}
            {rightItem}
         </S.div_UIOverlayCardTop>
         {noLoader === false && <UILoadingBar isLoading={isLoading || false} background={"var(--white-60)"} />}
         <S.div_UIOverlayCardContent className={contentClassName} style={getStyleFromProps()}>
            {!isLoading && (
               <>
                  {titleAlt && <S.p_UIOverlayCardTitleAlt>{titleAlt}</S.p_UIOverlayCardTitleAlt>}
                  {description && <S.p_UIOverlayCardDescription>{description}</S.p_UIOverlayCardDescription>}
                  {children}
               </>
            )}
         </S.div_UIOverlayCardContent>
      </S.div_UIOverlayCardContainer>
   );
}
