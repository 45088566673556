import React, { useState } from 'react';
import * as S from './styles';
import * as strings from './strings';
import VTFullscreen from '../VTFullscreen';
import { filterByShouldShowDimensions } from './config';

export default function VTFloorPlans({ files, showDimensions }: VTFloorPlansProps) {
	const [expandedFileIndex, setExpandedFileIndex] = useState<number>();

	if (!files) return null;

	const filteredFloorPlans = files.filter((file) => filterByShouldShowDimensions(showDimensions, file));
	const floorPlans = filteredFloorPlans.sort((a, b) => (a.tags?.floorLevel || 1) - (b.tags?.floorLevel || 1));

	return (
		<S.div_VTFloorPlansContainer>
			{floorPlans.map((file, index) => {
				const openFullscreen = () => setExpandedFileIndex(index);
				const floorString = strings.floorTitle(file.tags.floorLevel);

				return (
					<S.div_VTFloorPlansFloor key={file.id}>
						{file.tags.floorLevel && <S.p_VTFloorPlansFloorTitle>{floorString}</S.p_VTFloorPlansFloorTitle>}
						<S.div_VTFloorPlansItem onClick={openFullscreen}>
							<div style={{ width: '100%', height: '100%', minWidth: '100%', justifyContent: 'center', alignItems: 'center', padding: '0 12px' }}>
								<S.img_VTFloorPlansImage alt={file.filename} src={file.webUrl} />
							</div>
						</S.div_VTFloorPlansItem>
					</S.div_VTFloorPlansFloor>
				);
			})}
			<VTFullscreen setExpandedFileIndex={setExpandedFileIndex} files={floorPlans} expandedFileIndex={expandedFileIndex} />
		</S.div_VTFloorPlansContainer>
	);
}
