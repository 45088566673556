import { BookingConfig } from '../../types';

export const isLightingOptionSelected = (lightingOption: BookableOptionType, bookingConfig: BookingConfig) => {
	if (!lightingOption) return false;
	return bookingConfig.lightingOptions.map((bookableOption) => bookableOption.option.id).indexOf(lightingOption.option.id) !== -1;
};

export const STANDARD_LIGHTING_TAB: PLTab = { text: 'No lighting upgrades', value: 'standard' };
export const VIRTUAL_TWILIGHT_LIGHTING_TAB: PLTab = { text: 'Virtual Twilight', value: 'virtualTwilight' };
export const TWILIGHT_LIGHTING_TAB: PLTab = { text: 'Twilight', value: 'twilight' };
