import React, { useEffect, useRef, useState } from 'react';
import './styles';
import UIIcon from '../../UIIcon';
import { BellIcon, MutedIcon, PauseIcon, PlayIcon } from '../../../../assets/icons';
import ReactAudioPlayer from 'react-audio-player';
import { isSafari, isMobileSafari } from 'react-device-detect';

export default function UIFilmstripControl({ autoplaySeconds, selectedIndex, goToNext, audioFile, autoMute = false }: UIFilmstripControlProps) {
	const [isPlaying, setIsPlaying] = useState(true);
	const [isMuted, setIsMuted] = useState(autoMute);

	const playerRef = useRef<ReactAudioPlayer>();

	const _handleKeyDown = (event) => {
		switch (event.keyCode) {
			//Space bar
			case 32:
				setIsPlaying(!isPlaying);
				break;

			default:
				break;
		}
	};

	useEffect(() => {
		if (!autoplaySeconds || !isPlaying) return;
		const intervalId = setInterval(() => {
			goToNext(true, !isSafari && !isMobileSafari);
		}, autoplaySeconds * 1000);

		return () => {
			clearInterval(intervalId);
		};
	}, [selectedIndex, isPlaying]);

	useEffect(() => {
		if (!isPlaying) {
			//@ts-ignore
			playerRef.current?.audioEl.current.pause();
		} else {
			//@ts-ignore
			const playedPromise = playerRef.current?.audioEl.current.play();
			if (playedPromise) {
				playedPromise.catch((e) => {
					if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
					}
				});
			}
		}
		document.addEventListener('keydown', _handleKeyDown, { passive: true });
		return () => {
			document.removeEventListener('keydown', _handleKeyDown);
		};
	}, [isPlaying]);

	const onChangeIsAutoplaying = () => {
		setIsPlaying(!isPlaying);
	};

	return (
		<div className='uiFilmstripControl'>
			{autoplaySeconds && (
				<UIIcon alt={'play toggle'} className='uiFilmstripControl--icon' iconSrc={isPlaying ? PauseIcon : PlayIcon.white} onClick={onChangeIsAutoplaying} />
			)}
			{audioFile && (
				<UIIcon
					alt={'mute toggle'}
					className='uiFilmstripControl--mute'
					iconSrc={isMuted ? MutedIcon.white : BellIcon.white}
					onClick={() => setIsMuted(!isMuted)}
				/>
			)}
			{audioFile && (
				<ReactAudioPlayer
					volume={isMuted ? 0 : 1}
					loop={true}
					//@ts-ignore
					ref={playerRef}
					id={`music.title-${audioFile.filename}`}
					src={audioFile.originalUrl}
					autoPlay={true}
					controls={false}
				/>
			)}
		</div>
	);
}
