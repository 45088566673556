import React, { useState } from 'react';
import './ui-certified-tech-search.scss';
import { useSelector } from 'react-redux';
import UIUser from '../UITeamMember/UITeamMember';
import PLTable from '../../PL/PLTable/PLTable';
import Queries from '../../../utils/API/Queries';
import UILabeledInput from '../UILabeledInput';
import { INVITED_TO_PINEAPPLE, USER_ROLE_PHOTOGRAPHER } from '../../../utils/Config/Constants';
import { USER_SEARCH_TABLE_TYPE } from '../../PL/PLTable/config';
import { UI_LABELED_INPUT_SEARCH_TYPE } from '../UILabeledInput/config';
import UIHover from '../UIHover/UIHover';
import { getIsUserAdminSelector } from '../../../redux/selectors/CurrentUserSelectors';
import { useApolloClient } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

export default function UICertifiedTechSearch({
	label,
	certifications,
	onUpdateSearchParams,
	onSelectUser,
	selectedUser,
	emptySetText,
	region,
	showSearchParameters
}: UICertifiedTechSearchProps) {
	const [searchParams, setSearchParams] = useState<string>('');
	const [technicianResults, setTechnicianResults] = useState<object[]>([]);

	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const client = useApolloClient();

	const onChangeSearchParams = async (searchParams) => {
		setSearchParams(searchParams);
		await searchForTechnicians();
		if (onUpdateSearchParams) {
			onUpdateSearchParams(searchParams);
		}
	};

	const searchForTechnicians = async () => {
		let certificates: string[] = [];

		for (let i = 0; i < certifications.length; i++) {
			if (certifications[i]) {
				certificates.push(certifications[i]);
			}
		}

		let variables = {
			requestedOptions: [],
			regionId: region.id,
			q: {
				role: { op_eq: USER_ROLE_PHOTOGRAPHER },
				name: { op_regexp: '(?i)' + searchParams },
				certificates: { op_contains: certificates },
				op_and: [
					{
						stripeAccountId: { op_ne: 'null' }
					},
					{
						stripeAccountId: { op_ne: INVITED_TO_PINEAPPLE }
					}
				]
			},
			limit: 15
		};

		const response = await client.query({
			query: Queries.GET_CERTIFIED_TECHNICIANS,
			variables
		});
		const { users: technicianResults } = response['data'];
		if (technicianResults) {
			setTechnicianResults(technicianResults);
		} else {
			toast.error('Error searching for certified technicians!');
		}
	};

	const renderResults = () => {
		if (selectedUser) {
			return (
				<UIHover string={`remove ${selectedUser.name} as a preferred technician`} className='uiCertifiedUserSearch__content'>
					<p className='uiCertifiedUserSearch__selected-description'>{`You have selected ${selectedUser.name} as your preferred service provider. Unless you let us know otherwise, we will try to schedule only with them.`}</p>
					<UIUser deleteOnClick onClick={() => onSelectUser(undefined)} user={selectedUser} />
				</UIHover>
			);
		}

		if (technicianResults && searchParams !== '') {
			return (
				<div className='uiUserSearch__content'>
					<PLTable
						limit={15}
						type={USER_SEARCH_TABLE_TYPE}
						emptySetText={emptySetText || `No users found`}
						data={technicianResults}
						onSelect={onSelectUser}
						updateTable={searchForTechnicians}
					/>
					{isUserAdmin && (
						<p className='uiUserSearch__text'>
							Don't see who you’re looking for? Perhaps they haven’t be invited to use Pineapple Labs as a service provider or they don't have the correct
							certifications.
						</p>
					)}
				</div>
			);
		}
		return null;
	};

	const renderSearch = () => {
		if (!selectedUser) {
			return (
				<UILabeledInput
					containerStyle={{ minWidth: 320, width: '100%' }}
					style={{ width: '100%' }}
					placeholder={'Search technicians by name...'}
					type={UI_LABELED_INPUT_SEARCH_TYPE}
					searchParams={searchParams}
					label={label}
					onChangeValue={onChangeSearchParams}
					onSearch={searchForTechnicians}
				/>
			);
		}
		return null;
	};

	const renderParameters = () => {
		if (!showSearchParameters) return null;

		const certificatesString = certifications.map((certificate) => certificate.replace(/(^|\s)\S/g, (letter) => letter.toUpperCase())).join(', ');
		return (
			<div className='uiCertifiedUserSearch__parameters'>
				<p className='uiCertifiedUserSearch__parameters-text'>
					Role: <span className='uiCertifiedUserSearch__parameters-value'>Photographer</span>
				</p>
				<p className='uiCertifiedUserSearch__parameters-text'>
					Region: <span className='uiCertifiedUserSearch__parameters-value'>{region.name}</span>
				</p>
				<p className='uiCertifiedUserSearch__parameters-text'>
					Certificates: <span className='uiCertifiedUserSearch__parameters-value'>{certificatesString}</span>
				</p>
				<p className='uiCertifiedUserSearch__parameters-text'>
					Stripe signup: <span className='uiCertifiedUserSearch__parameters-value'>Complete</span>
				</p>
			</div>
		);
	};

	return (
		<div className='uiCertifiedUserSearch'>
			{renderSearch()}
			{renderResults()}
			{renderParameters()}
		</div>
	);
}
