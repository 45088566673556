import React, { Component } from 'react';
import { connect } from 'react-redux';
import './table-technician-list.scss';
import PLImage from '../../../PLImage';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../../../utils/Helpers/AppHelper';

class TableTechnicianList extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	//Mark: Render functions
	render() {
		const { data, pagination } = this.props;
		return (
			<div className='tableTech'>
				<div className='tableTech__rows fade-in-div'>
					{data.map((photographer) => {
						return (
							<div className='tableTech__row' onClick={() => this.props.onSelect(photographer)} key={photographer.id}>
								<div className='tableTech__row--item'>
									<div className='photographerListLabel'>
										<PLImage alt={'photographer image'} src={photographer.image} imageClassName='photographerListLabel__img' />
										<div className='photographerListLabel__info'>
											<p className='photographerListLabel__info--name'>{photographer.name}</p>
											<p className='photographerListLabel__info--email'>{photographer.email}</p>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
				{pagination}
			</div>
		);
	}
}

export default connect(commonMapStateToProps, commonMapDispatchToProps)(TableTechnicianList);
