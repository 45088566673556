import {
	AddToCartIcon,
	CheckmarkIcon,
	DownloadIcon,
	EditIcon,
	FloorplansIcon,
	InvisibleWhite,
	RemoveFromCartIcon,
	ReportIssueIcon,
	TrashCanRed,
	VisibleWhite
} from '../../../../../assets/icons';
import { UI_ICON_BUTTON_CANCEL_TYPE, UI_ICON_BUTTON_FILL_TYPE, UI_ICON_BUTTON_OUTLINE_TYPE } from '../../../../../components/UI/UIIconButton/config';

const FULLSCREEN_TYPES_ALL: string = 'allType';
const FULLSCREEN_TYPES_FLOOR_PLAN_TYPE: string = 'floorPlanType';
const FULLSCREEN_TYPES_IMAGE_TYPE: string = 'imageType';
const FULLSCREEN_TYPES_VIDEO_TYPE: string = 'videoType';
const FULLSCREEN_TYPES_SOCIAL_MEDIA_TYPE: string = 'socialMediaType';
const FULLSCREEN_TYPES_PROOFING_TYPE: string = 'proofingType';

export const FULLSCREEN_TYPES = {
	ALL: FULLSCREEN_TYPES_ALL,
	FLOOR_PLAN_TYPE: FULLSCREEN_TYPES_FLOOR_PLAN_TYPE,
	IMAGE_TYPE: FULLSCREEN_TYPES_IMAGE_TYPE,
	VIDEO_TYPE: FULLSCREEN_TYPES_VIDEO_TYPE,
	SOCIAL_MEDIA_TYPE: FULLSCREEN_TYPES_SOCIAL_MEDIA_TYPE,
	PROOFING_TYPE: FULLSCREEN_TYPES_PROOFING_TYPE
};

export const FULLSCREEN_QUICK_ACTION_DOWNLOAD: FullscreenQuickAction = {
	icon: () => DownloadIcon.white,
	helpText: () => 'Download this file',
	type: 'download',
	buttonType: UI_ICON_BUTTON_OUTLINE_TYPE as UIIconButtonType,
	textClassname: 'fullscreenFiles__quickActions--container-text'
};

export const FULLSCREEN_QUICK_ACTION_FLOOR_PLAN_FLOOR_LEVEL: FullscreenQuickAction = {
	icon: () => EditIcon.white,
	helpText: (currentFile: FileType) => `Floor level: ${currentFile.tags['floorLevel'] === undefined ? 'not set' : currentFile.tags['floorLevel']}`,
	type: 'floorPlanFloorLevel',
	buttonType: UI_ICON_BUTTON_OUTLINE_TYPE as UIIconButtonType,
	textClassname: 'fullscreenFiles__quickActions--container-text'
};

export const FULLSCREEN_QUICK_ACTION_FLOOR_PLAN_SECTION: FullscreenQuickAction = {
	icon: () => EditIcon.white,
	helpText: (currentFile: FileType) => `Send to: ${currentFile.tags['floorLevel'] ? 'measured' : 'unmeasured'}`,
	type: 'floorPlanSection',
	buttonType: UI_ICON_BUTTON_OUTLINE_TYPE as UIIconButtonType,
	textClassname: 'fullscreenFiles__quickActions--container-text'
};

export const FULLSCREEN_QUICK_ACTION_FLOOR_PLAN_FIX_REQUEST: FullscreenQuickAction = {
	icon: () => FloorplansIcon,
	helpText: () => 'Edit floor plan',
	type: 'fixRequest',
	textClassname: 'fullscreenFiles__quickActions--container-text'
};

export const FULLSCREEN_QUICK_ACTION_EDIT_IMAGE: FullscreenQuickAction = {
	icon: () => FloorplansIcon,
	helpText: () => 'Edit this image',
	type: 'editImage',
	textClassname: 'fullscreenFiles__quickActions--container-text'
};

export const FULLSCREEN_QUICK_ACTION_VISIBILITY: FullscreenQuickAction = {
	icon: () => InvisibleWhite,
	helpText: () => 'Hide in downloads and virtual tour',
	type: 'visible',
	buttonType: UI_ICON_BUTTON_OUTLINE_TYPE as UIIconButtonType,
	textClassname: 'fullscreenFiles__quickActions--container-text'
};

export const FULLSCREEN_QUICK_ACTION_INVISIBILITY: FullscreenQuickAction = {
	icon: () => VisibleWhite,
	helpText: () => 'Show in downloads and virtual tour',
	type: 'visible',
	buttonType: UI_ICON_BUTTON_OUTLINE_TYPE as UIIconButtonType,
	textClassname: 'fullscreenFiles__quickActions--container-text'
};

export const FULLSCREEN_QUICK_ACTION_EDIT_REQUEST: FullscreenQuickAction = {
	icon: () => ReportIssueIcon.white,
	helpText: () => 'Report an issue',
	type: 'serviceRequest',
	buttonType: UI_ICON_BUTTON_FILL_TYPE as UIIconButtonType,
	textClassname: 'fullscreenFiles__quickActions--container-text'
};

export const FULLSCREEN_QUICK_ACTION_DELETE: FullscreenQuickAction = {
	icon: () => TrashCanRed,
	helpText: () => 'Delete this file',
	type: 'delete',
	buttonType: UI_ICON_BUTTON_CANCEL_TYPE as UIIconButtonType,
	textClassname: 'fullscreenFiles__quickActions--container-text-delete'
};

export const FULLSCREEN_QUICK_ACTION_ADD_TO_CART: FullscreenQuickAction = {
	icon: () => AddToCartIcon.mint,
	helpText: () => 'Add to cart',
	type: 'addToCart',
	buttonType: UI_ICON_BUTTON_FILL_TYPE as UIIconButtonType,
	textClassname: 'fullscreenFiles__quickActions--container-text-request'
};

export const FULLSCREEN_QUICK_ACTION_REMOVE_FROM_CART: FullscreenQuickAction = {
	icon: () => RemoveFromCartIcon.red,
	helpText: () => 'Remove from cart',
	type: 'removeFromCart',
	buttonType: UI_ICON_BUTTON_FILL_TYPE as UIIconButtonType,
	textClassname: 'fullscreenFiles__quickActions--container-text-delete'
};

export const FULLSCREEN_QUICK_ACTION_READY_TO_APPROVE: FullscreenQuickAction = {
	icon: () => CheckmarkIcon.white,
	helpText: () => 'ready to approve',
	type: 'readyToApprove',
	buttonType: UI_ICON_BUTTON_FILL_TYPE as UIIconButtonType,
	textClassname: 'fullscreenFiles__quickActions--container-text'
};

export const QUICK_ACTIONS_FULLSCREEN = {
	DOWNLOAD: FULLSCREEN_QUICK_ACTION_DOWNLOAD,
	FLOOR_PLAN_FLOOR_LEVEL: FULLSCREEN_QUICK_ACTION_FLOOR_PLAN_FLOOR_LEVEL,
	FLOOR_PLAN_SECTION: FULLSCREEN_QUICK_ACTION_FLOOR_PLAN_SECTION,
	FLOOR_PLAN_FIX_REQUEST: FULLSCREEN_QUICK_ACTION_FLOOR_PLAN_FIX_REQUEST,
	EDIT_IMAGE: FULLSCREEN_QUICK_ACTION_EDIT_IMAGE,
	VISIBILITY: FULLSCREEN_QUICK_ACTION_VISIBILITY,
	INVISIBILITY: FULLSCREEN_QUICK_ACTION_INVISIBILITY,
	EDIT_REQUEST: FULLSCREEN_QUICK_ACTION_EDIT_REQUEST,
	DELETE: FULLSCREEN_QUICK_ACTION_DELETE,
	ADD_TO_CART: FULLSCREEN_QUICK_ACTION_ADD_TO_CART,
	REMOVE_FROM_CART: FULLSCREEN_QUICK_ACTION_REMOVE_FROM_CART,
	READY_TO_APPROVE: FULLSCREEN_QUICK_ACTION_READY_TO_APPROVE
};
