import React, { Component } from 'react';
import './ui-team-member.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../utils/Helpers/AppHelper';

class UITeamMember extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	//Mark: Render functions

	render() {
		const { user, onClick, deleteOnClick, showPhoneNumber, showEmail, dontShowImg, style } = this.props;

		if (!user) {
			return <p>error</p>;
		}

		return (
			<div
				className={
					user['isCertified'] === false ? 'uiTeamMember-uncertified' : deleteOnClick ? 'uiTeamMember-selected fade-in-div' : 'uiTeamMember fade-in-div'
				}
				key={user.id}
				style={style}
				onClick={user['isCertified'] === false ? undefined : onClick}>
				{dontShowImg ? undefined : <img alt={user.name} className='uiTeamMember--image' src={user.image} />}
				<div className='uiTeamMember__info'>
					<p className='uiTeamMember__info-name'>{user.name}</p>
					{user['isCertified'] === false ? <p className='uiTeamMember__info-name'>(Not certified for this package)</p> : undefined}
					{showPhoneNumber && <p className='uiTeamMember__info-phone'>{user.phoneNumber}</p>}
					{showEmail && <p className='uiTeamMember__info-email'>{user.email}</p>}
				</div>
			</div>
		);
	}
}

UITeamMember.propTypes = {
	key: PropTypes.string,
	style: PropTypes.object,
	user: PropTypes.object,
	showPhoneNumber: PropTypes.bool,
	showEmail: PropTypes.bool,
	background: PropTypes.bool,
	dontShowImg: PropTypes.bool,
	deleteOnClick: PropTypes.bool,
	onClick: PropTypes.func
};

export default connect(commonMapStateToProps, commonMapDispatchToProps)(UITeamMember);
