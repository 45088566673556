import initialState from '../initialState';
import * as types from './types';

export default function filterReducer(state = initialState.filters, action) {
	const { type, payload } = action;
	switch (type) {
		case types.UPDATE_SEARCH:
			return {
				...state,
				[payload.route]: { ...state[payload.route], search: payload.value }
			};
		case types.UPDATE_SEARCH_FIELD:
			return {
				...state,
				[payload.route]: { ...state[payload.route], search_field: payload.value }
			};
		case types.UPDATE_PAGE:
			return {
				...state,
				[payload.route]: { ...state[payload.route], page: payload.page }
			};
		case types.UPDATE_FILTER:
			if (state[payload.route]) {
				//Update
				return {
					...state,
					[payload.route]: { ...state[payload.route], [payload.filter.name]: payload.filter.value }
				};
			} else {
				//Add
				return {
					...state,
					[payload.route]: { [payload.filter.name]: payload.filter.value }
				};
			}
		case types.CLEAR_FILTER:
			let newState = Object.assign({}, state);
			let name = payload.filter.name;
			if (name === 'date') {
				//Delete startDate and endDate
				delete newState[payload.route]['startDate'];
				delete newState[payload.route]['endDate'];
			} else {
				delete newState[payload.route][name];
			}
			return {
				...newState
			};
		case types.CLEAR_FILTERS:
			return {};
		default:
			return state;
	}
}
