import React, { Component } from 'react';
import { connect } from 'react-redux';
import './table-editors.scss';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../../../utils/Helpers/AppHelper';

class TableEditors extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	//Mark: Render functions
	render() {
		const { data, pagination } = this.props;
		let mobile = this.props.control.screenType;
		if (mobile === 'mobile' || mobile === 'tablet') {
			return (
				<div className='tableEditors'>
					<div className='fade-in-div'>
						{data.map((editor, index) => {
							return (
								<div className='tableEditorsMobile' key={`${editor.id}${index}`} onClick={() => this.props.onSelect(editor)}>
									<div className='tableEditorsMobile__name'>
										<p>{editor.name}</p>
									</div>
									<div className='tableEditorsMobile__email'>
										<p>{editor.email}</p>
									</div>
									<div className='tableEditorsMobile__certs'>
										<p className='tableEditorsMobile__certs--heading'>Certifications</p>
										<p>{editor.certificates ? editor.certificates.join(', ') : 'no certificates'}</p>
									</div>
								</div>
							);
						})}
						{pagination}
					</div>
				</div>
			);
		} else
			return (
				<div className='tableEditorsContainer '>
					<div className='tableEditorsContainer__header'>
						<div className='tableEditorsContainer__header--label'>Name</div>
						<div className='tableEditorsContainer__header--label'>Email</div>
						<div className='tableEditorsContainer__header--label'>Certifications</div>
					</div>
					<div className='fade-in-div'>
						{data.map((editor, index) => {
							return (
								<div className='tableEditorsContainer__row' key={`${editor.id}${index}`} onClick={() => this.props.onSelect(editor)}>
									<div className='tableEditorsContainer__row--item'>
										<p className='tableEditorsContainer__row--item-text'>{editor.name}</p>
									</div>
									<div className='tableEditorsContainer__row--item'>
										<p className='tableEditorsContainer__row--item-text'>{editor.email}</p>
									</div>
									<div className='tableEditorsContainer__row--item'>
										<p className='tableEditorsContainer__row--item-text'>{editor.certificates ? editor.certificates.join(', ') : 'no certificates'}</p>
									</div>
								</div>
							);
						})}
						{pagination}
					</div>
				</div>
			);
	}
}

export default connect(commonMapStateToProps, commonMapDispatchToProps)(TableEditors);
