// Created by jarongallo at 6/14/21
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	FILE_TYPE_IMAGE,
	USER_ROLE_ADMIN,
	USER_ROLE_EDITOR,
	USER_ROLE_REGIONAL_MANAGER,
	USER_ROLE_EDITING_MANAGER,
	FILE_STATUS_LIVE,
	STATUS_EDITOR_UPLOAD,
	STATUS_REVIEW,
	FILE_STATUS_PROOF
} from '../../../../../../utils/Config/Constants';
import './styles';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_PL_SERVICE_PHOTOS_FILES, UPDATE_SERVICE_STATUS } from './queries';
import UIMultiImageSelect from '../../../../../../components/UI/UIMultiImageSelect';
import { TYPES } from '../../../../../../components/UI/UIMultiImageSelect/config';
import { PLServiceFilesQueryParams } from '../../types';
import * as strings from './strings';
import PLUpload from '../../../../../../components/PL/PLUpload';
import UILoadingPage from '../../../../../../components/UI/UILoadingPage/UILoadingPage';

export default function PLServicePhotos({ serviceId, serviceStatus }: PLServicePhotosProps) {
	const signedInUser = useSelector(getSignedInUserSelector);
	let isUserAdmin = useSelector(getIsUserAdminSelector);

	const [images, setImages] = useState<FileType[]>();
	let variables: PLServiceFilesQueryParams = { limit: 1000, query: { serviceId: serviceId, type: FILE_TYPE_IMAGE }, isActive: true, order: [['index', 'ASC']] };
	if (!isUserAdmin && signedInUser.role !== USER_ROLE_EDITOR && signedInUser.role !== USER_ROLE_EDITING_MANAGER) variables.query.status = FILE_STATUS_LIVE;
	const [updateService] = useMutation(UPDATE_SERVICE_STATUS);
	const [getPhotos, { data: filesData }] = useLazyQuery(GET_PL_SERVICE_PHOTOS_FILES, { variables });

	useEffect(() => {
		(async () => {
			await getPhotos();
		})();
	}, []);

	useEffect(() => {
		if (filesData) {
			setImages(filesData['files']);
		}
	}, [filesData]);

	if (!images) return <UILoadingPage />;

	const onFileUploadComplete = async () => {
		if (serviceStatus === STATUS_EDITOR_UPLOAD) {
			await updateService({ variables: { serviceId, status: STATUS_REVIEW } });
		}
		await getPhotos();
	};

	const renderUpload = () => {
		if (signedInUser) {
			const allowedRoles = [USER_ROLE_ADMIN, USER_ROLE_REGIONAL_MANAGER, USER_ROLE_EDITOR, USER_ROLE_EDITING_MANAGER];
			if (allowedRoles.indexOf(signedInUser.role) !== -1) {
				return (
					<PLUpload
						multiple={true}
						fileType={FILE_TYPE_IMAGE}
						postData={{ serviceId }}
						onFileUploadComplete={onFileUploadComplete}
						uploadStatus={FILE_STATUS_PROOF}
					/>
				);
			}
		}
		return;
	};

	const renderContent = () => {
		return (
			<UIMultiImageSelect
				disableSort
				type={TYPES.image}
				rightItem={renderUpload()}
				updateData={getPhotos}
				files={images}
				serviceId={serviceId}
				emptySetTitle={strings.emptySetTitle}
				emptySetDescription={strings.emptySetDescription}
			/>
		);
	};

	return <div className='plServicePhotos'>{renderContent()}</div>;
}
