import * as types from './types';

export const updateFilter = (payload) => {
	return { type: types.UPDATE_FILTER, payload };
};
export const clearFilter = (payload) => {
	return { type: types.CLEAR_FILTER, payload };
};
export const updateSearch = (payload) => {
	return { type: types.UPDATE_SEARCH, payload };
};
export const updateSearchField = (payload) => {
	return { type: types.UPDATE_SEARCH_FIELD, payload };
};
export const updatePage = (payload) => {
	return { type: types.UPDATE_PAGE, payload };
};
export const clearFilters = (payload) => {
	return { type: types.CLEAR_FILTERS, payload };
};

export const filtersActions = {
	updateFilter,
	clearFilter,
	updateSearch,
	updateSearchField,
	updatePage,
	clearFilters
};
