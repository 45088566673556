import gql from 'graphql-tag';

export const GET_SERVICE_RECEIPTS = gql`
	query Service($id: String!) {
		service(id: $id) {
			id
			promos {
				id
				name
				promoType
				discountAmount
				discountPercentage
				discountType
				uses
				maxUses
				option {
					id
					name
				}
			}
			transactions {
				id
				amount
				creditsApplied
				currency
				discountedAmount
				flags
				invoiced
				invoiceId
				invoiceItemId
				optionsPurchased
				paymentMethod
				paymentStatus
				receiptUrl
				serviceDate
				stripeBookingTotal
				service {
					id
					address
					property {
						id
						address
					}
					orderInformation
				}
				bookedOptions {
					id
					name
					quantity
					price
				}
				promos {
					id
					name
					promoType
					discountAmount
					discountPercentage
					discountType
					uses
					maxUses
					option {
						id
						name
					}
				}
			}
		}
	}
`;

// export const GET_USER_PROMOS = gql`
// 	query promos($userId: String!, $limit: Int, $isActive: Boolean) {
// 		promos(user: $userId, limit: $limit, isActive: $isActive) {
// 			id
// 			name
// 			promoType
// 			discountAmount
// 			discountPercentage
// 			discountType
// 			isActive
// 			uses
// 			maxUses
// 			option {
// 				id
// 				name
// 			}
// 		}
// 	}
// `;
