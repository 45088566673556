// Created by jarongallo at 6/14/21
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './styles';
import * as strings from './strings';
import { DIMENSIONS, NO_DIMENSIONS } from '../../../../../../components/PL/PLSortable';
import UIModal from '../../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../../components/UI/UIOverlayCard';
import UIIconButton from '../../../../../../components/UI/UIIconButton';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import { EditIcon, PlusIcon } from '../../../../../../assets/icons';
import UIIcon from '../../../../../../components/UI/UIIcon';
import Queries from '../../../../../../utils/API/Queries';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { FILE_STATUS_LIVE, FILE_TYPE_FLOOR_PLAN } from '../../../../../../utils/Config/Constants';
import { getIsUserAdminSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import UIMultiImageSelect from '../../../../../../components/UI/UIMultiImageSelect';
import { TYPES } from '../../../../../../components/UI/UIMultiImageSelect/config';
import { filterFloorplansByTab } from '../../../../../../components/PL/PLSortable/helpers';
import { PLServiceFilesQueryParams } from '../../types';
import UISegmentedControl from '../../../../../../components/UI/UISegmentedControl';
import { GET_PL_SERVICE_FLOOR_PLANS_FILES, GET_PL_SERVICE_FOR_FLOOR_PLANS } from './queries';
import UILoadingPage from '../../../../../../components/UI/UILoadingPage/UILoadingPage';
import { UI_ICON_BUTTON_CANCEL_TYPE, UI_ICON_BUTTON_FILL_TYPE } from '../../../../../../components/UI/UIIconButton/config';

export default function PLServiceFloorPlans({ serviceId }: PLServiceFloorPlansProps) {
	let isUserAdmin = useSelector(getIsUserAdminSelector);

	const [floorPlans, setFloorPlans] = useState<FileType[]>();
	const [showAddFloorPlanModal, setShowAddFloorPlanModal] = useState<boolean>(false);
	const [service, setService] = useState<ServiceType>();
	const [cubiCasaModelId, setCubiCasaModelId] = useState<string>();
	const [cubiCasaOrderId, setCubiCasaOrderId] = useState<string>();
	const [isSyncing, setIsSyncing] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<object>({});
	const [selectedFloorPlanSegment, setSelectedFloorPlanSegment] = useState<PLSegment>();

	const [syncCubiCasa] = useMutation(Queries.SYNC_CUBI_CASA, {
		onError: (error) => {
			toast.error(error.message);
		}
	});
	const [updateService] = useMutation(Queries.UPDATE_SERVICE);

	let floorPlanFilesVariables: PLServiceFilesQueryParams = {
		limit: 1000,
		query: { serviceId: serviceId, type: FILE_TYPE_FLOOR_PLAN },
		isActive: true,
		order: [['index', 'ASC']]
	};

	const serviceVariables = { variables: { id: serviceId } };

	if (!isUserAdmin) floorPlanFilesVariables.query.status = FILE_STATUS_LIVE;

	const [getFloorPlanFiles, { data: floorPlanFilesData }] = useLazyQuery(GET_PL_SERVICE_FLOOR_PLANS_FILES);

	const [getService, { data: serviceData }] = useLazyQuery(GET_PL_SERVICE_FOR_FLOOR_PLANS);

	const updateAllData = async () => {
		await getService(serviceVariables);
		await getFloorPlanFiles({ variables: floorPlanFilesVariables });
	};

	useEffect(() => {
		(async () => {
			await updateAllData();
		})();
	}, []);

	useEffect(() => {
		if (serviceData) {
			const fetchedService: ServiceType = serviceData['service'];
			setService(fetchedService);
			setCubiCasaOrderId(fetchedService.cubiCasaOrderId);
			setCubiCasaModelId(fetchedService.cubiCasaModelId);
		}
	}, [serviceData]);

	useEffect(() => {
		if (floorPlanFilesData) {
			const fetchedFloorPlans: FileType[] = floorPlanFilesData['files'];
			setFloorPlans(fetchedFloorPlans);
			setSelectedFloorPlanSegment(getSegments(fetchedFloorPlans)[0]);
		}
	}, [floorPlanFilesData]);

	const getSegments = (files) => {
		let returnArray: PLSegment[] = [];
		if (filterFloorplansByTab(files, DIMENSIONS).length > 0) {
			returnArray.push(DIMENSIONS);
		}
		if (filterFloorplansByTab(files, NO_DIMENSIONS).length > 0) {
			returnArray.push(NO_DIMENSIONS);
		}
		return returnArray;
	};

	if (!service || !floorPlans) return <UILoadingPage />;

	const onSave = async (key: string, value: any) => {
		setIsSaving({ ...isSaving, [key]: true });
		await updateService({ variables: { serviceId, [key]: value } });
		await updateAllData();
		setIsSaving({ ...isSaving, [key]: false });
		toast.success(strings.updatedService);
	};

	const syncWithCubiCasa = async () => {
		setIsSyncing(true);
		const response = await syncCubiCasa({ variables: { id: serviceId } });
		setIsSyncing(false);
		if (response) {
			await updateAllData();
			setShowAddFloorPlanModal(false);
			toast.success(strings.syncCubiSuccess);
		}
	};

	const removeFloorplan = async () => {
		await updateService({ variables: { serviceId, floorplans: [], cubiCasaModelId: null, cubiCasaOrderId: null } });
		await updateAllData();
		setCubiCasaOrderId('');
		setCubiCasaModelId('');
		toast.success(strings.removedFloorplansSuccess);
	};

	const renderAddFloorPlans = () => {
		const hideModal = () => setShowAddFloorPlanModal(false);

		const isSyncEnabled = !service.cubiCasaModelId || !service.cubiCasaOrderId;
		const isFloorplanActive = service.cubiCasaModelId && service.cubiCasaOrderId;

		return (
			<UIModal condition={showAddFloorPlanModal}>
				<UIOverlayCard hideModal={hideModal} title={strings.addFloorPlanModalTitle}>
					<UIListInputRow
						value={cubiCasaOrderId}
						label={strings.cubiCasaOrderIdLabel}
						isLoading={isSaving['cubiCasaOrderId']}
						placeholder={strings.cubiCasaOrderIdPlaceholder}
						savedValue={service.cubiCasaOrderId}
						onChangeValue={setCubiCasaOrderId}
						onSave={() => onSave('cubiCasaOrderId', cubiCasaOrderId)}
					/>
					<UIListInputRow
						value={cubiCasaModelId}
						label={strings.cubiCasaModelIdLabel}
						isLoading={isSaving['cubiCasaModelId']}
						placeholder={strings.cubiCasaModelIdPlaceholder}
						savedValue={service.cubiCasaModelId}
						onChangeValue={setCubiCasaModelId}
						onSave={() => onSave('cubiCasaModelId', cubiCasaModelId)}
					/>
					<UIIconButton
						dark
						className='plServiceFloorplans__button'
						isDisabled={isSyncEnabled}
						type={UI_ICON_BUTTON_FILL_TYPE}
						disabledErrorMessage={strings.syncCubiDisabled}
						isLoading={isSyncing}
						text={strings.syncCubiButton}
						onClick={syncWithCubiCasa}
					/>
					{isFloorplanActive && (
						<UIIconButton
							dark
							className='plServiceFloorplans__button'
							type={UI_ICON_BUTTON_CANCEL_TYPE}
							style={{ marginTop: 12 }}
							text={strings.removeFloorplansButton}
							onClick={removeFloorplan}
						/>
					)}
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderSegmentedControl = () => {
		const segments = getSegments(floorPlans);

		return (
			segments.length > 0 &&
			selectedFloorPlanSegment && (
				<UISegmentedControl
					className='plServiceFloorplans__segment'
					segments={segments}
					selectedSegment={selectedFloorPlanSegment}
					updateSelectedSegment={setSelectedFloorPlanSegment}
				/>
			)
		);
	};

	const renderRightItem = () => {
		if (isUserAdmin) {
			const icon = !cubiCasaModelId || !cubiCasaOrderId ? PlusIcon.white : EditIcon.white;
			return <UIIcon className='plServiceFloorplans__edit' iconSrc={icon} onClick={() => setShowAddFloorPlanModal(true)} alt={'edit'} />;
		}
		return;
	};

	const renderContent = () => {
		const filteredFiles = filterFloorplansByTab(floorPlans, selectedFloorPlanSegment);
		return (
			<UIMultiImageSelect
				disableSort
				emptySetTitle={strings.emptySetTitle}
				emptySetDescription={strings.emptySetDescription}
				rightItem={renderRightItem()}
				type={TYPES.floorPlan}
				updateData={updateAllData}
				files={filteredFiles}
				serviceId={serviceId}
			/>
		);
	};

	return (
		<div className='plServiceFloorplans'>
			{renderSegmentedControl()}
			{renderContent()}
			{renderAddFloorPlans()}
		</div>
	);
}
