import styled from 'styled-components';
import UIIconButton from '../../../../components/UI/UIIconButton';

export const div_VTBody = styled.div`
	//DEFAULT
	display: flex;
	align-items: center;
	padding: 0;
	background-color: #f1f1f1;
	flex-direction: column;
	width: 100%;
	height: 100%;
	max-height: 100%;
	margin: 0;
	min-width: 240px;
	overflow-x: auto;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTContent = styled.div`
	//DEFAULT
	padding: 0 12px;
	overflow-y: auto;
	width: 100%;
	flex: 1;
	max-height: 100%;
	align-items: center;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		padding: 0 48px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		padding: 0 48px;
	}

	//MONITOR
	@media only screen and (min-width: 1500px) {
		padding: 0 120px;
	}
`;

export const div_VT_DownloadMobile = styled.div`
	//DEFAULT
	min-height: 40px;
	width: 100%;
	align-items: center;
	justify-content: center;
	padding: 12px;
	background-color: white;
	border-top: #1212121c solid 1px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: none;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: none;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIIconButton_VTDownloadMobile = styled(UIIconButton)`
	//DEFAULT
	width: 100% !important;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTSectionInfo = styled.div`
	//DEFAULT
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	padding: 0;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		padding: 0;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		padding: 0 48px;
		height: 40px;
		margin-bottom: 12px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTSectionMatterportInclude = styled.div`
	//DEFAULT
	flex-direction: row;
	align-items: center;
	max-height: 48px;
	min-height: 48px;
	height: 40px;
	cursor: pointer;
	margin-bottom: 0;
	width: 100%;
	padding: 0 12px;
	border-bottom: #1212121c solid 1px;

	&:hover {
		opacity: 0.8;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		width: unset;
		border-bottom: none;
		padding: 0 48px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		width: unset;
		padding: unset;
		border-bottom: none;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTSectionMatterportIncludeCheck = styled.div`
	//DEFAULT
	width: 24px;
	height: 24px;
	border-radius: 12px;
	border: var(--dark-grey) solid 3px;
	margin-right: 12px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
export const div_VTSectionMatterportIncludeChecked = styled(div_VTSectionMatterportIncludeCheck)`
	//DEFAULT
	background-color: var(--dark-grey);

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_VTSectionMatterportIncludeLabel = styled.p`
	//DEFAULT
	color: var(--dark-grey);

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
