// Created by jarongallo at 6/14/21
import React, { useEffect, useState } from 'react';
import './styles';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SERVICE_ADMIN_TAB } from './queries';
import PLServiceAdminSurge from '../../PLServiceComponents/PLServiceAdminSurge';
import PLServiceAdminDelete from '../../PLServiceComponents/PLServiceAdminDelete';
import PLServiceMute from '../../PLServiceComponents/PLServiceMute';
import PLServiceHistory from '../../PLServiceComponents/PLServiceHistory';
import PLServiceOrderInformation from '../../PLServiceComponents/PLServiceOrderInformation';
import PLServiceClaimService from '../../PLServiceComponents/PLServiceClaimService';
import PLServiceAdminApproved from '../../PLServiceComponents/PLServiceAdminApproved';
import PLServiceAdminIncludesDrone from '../../PLServiceComponents/PLServiceAdminIncludesDrone';
import PLServiceAdminIncludesFloorplan from '../../PLServiceComponents/PLServiceAdminIncludesFloorplan';
import PLServiceAdminIncludesMatterport from '../../PLServiceComponents/PLServiceAdminIncludesMatterport';

export default function PLServiceAdminTab({ serviceId }: PLServiceAdminProps) {
	const [service, setService] = useState<ServiceType>();

	const [getService, { data: serviceData }] = useLazyQuery(GET_SERVICE_ADMIN_TAB, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getService();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setService(serviceData['service']);
			}
		})();
	}, [serviceData]);

	if (!service) return null;

	return (
		<div className='plServiceAdmin'>
			<div className='plServiceAdmin--content'>
				<div className='plServiceAdmin__quickActions'>
					<PLServiceMute serviceId={serviceId} />
					<PLServiceHistory serviceId={serviceId} />
					<PLServiceOrderInformation serviceId={serviceId} />
					<PLServiceClaimService serviceId={serviceId} />
				</div>
				<PLServiceAdminSurge serviceId={serviceId} />
				<PLServiceAdminApproved serviceId={serviceId} />
				<PLServiceAdminIncludesDrone serviceId={serviceId} />
				<PLServiceAdminIncludesFloorplan serviceId={serviceId} />
				<PLServiceAdminIncludesMatterport serviceId={serviceId} />
				<PLServiceAdminDelete service={service} />
			</div>
		</div>
	);
}
