import initialState from '../initialState';
import * as types from './types';

export default function bookingReducer(state = initialState.booking, action) {
	switch (action.type) {
		case types.SET_BOOKING_CONFIG:
			return {
				...state,
				bookingConfig: action.bookingConfig
			};
		default:
			return state;
	}
}
