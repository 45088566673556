import { ReduxStateType } from '../types';
import { USER_ROLE_AGENT, USER_ROLE_ADMIN, USER_ROLE_REGIONAL_MANAGER } from '../../utils/Config/Constants';

export const getCurrentUserSelector = (state: ReduxStateType) => {
	return state.currentUser;
};

export const getSignedInUserSelector = (state: ReduxStateType) => {
	return state.currentUser.user;
};

export const getSuperUserSelector = (state: ReduxStateType) => {
	return state.currentUser.superUser;
};

export const getSuperUserReturnRouteSelector = (state: ReduxStateType) => {
	return state.currentUser.superUserReturnRoute;
};

export const getPreFilledEmailSelector = (state: ReduxStateType) => {
	return state.currentUser.preFilledEmail;
};

export const getDisplayLoginSelector = (state: ReduxStateType) => {
	return state.currentUser.displayLogin;
};

export const getDisplaySignupSelector = (state: ReduxStateType) => {
	return state.currentUser.displaySignup;
};

export const getOnLoginCompleteFunction = (state: ReduxStateType) => {
	return state.currentUser.onLoginComplete;
};

export const getSignedInUserRoleSelector = (state: ReduxStateType) => {
	return state.currentUser.user.role;
};

export const getFiltersProp = (state: ReduxStateType) => {
	return state.filters;
};

export const getSelectedRegionSelector = (state: ReduxStateType) => {
	return state.currentUser.selectedRegion;
};

export const getIsUserAdminSelector = (state: ReduxStateType) => {
	const { user } = state.currentUser;
	if (user) {
		return user.role === USER_ROLE_ADMIN || user.role === USER_ROLE_REGIONAL_MANAGER;
	}
	return false;
};

export const getIsUserAgentSelector = (state: ReduxStateType) => {
	const { user } = state.currentUser;
	if (user.role === USER_ROLE_AGENT) {
		return true;
	}
	return false;
};
