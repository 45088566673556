// Created by jarongallo at 6/30/21
import React from 'react';
import './styles';
import PlServiceTeamMembers from '../../PLServiceComponents/PLServiceTeamMembers';
import PLServiceDate from '../../PLServiceComponents/PLServiceDate';
import PLServiceStatus from '../../PLServiceComponents/PLServiceStatus';
import PLServiceClient from '../../PLServiceComponents/PLServiceClient';
import PLServiceTechnician from '../../PLServiceComponents/PLServiceTechnician';
import PLServiceEditor from '../../PLServiceComponents/PLServiceEditor';

export default function PLServiceInfoTab({ serviceId }: PLServiceInfoTabProps) {
	return (
		<div className='plServiceInfo'>
			<div className='plServiceInfo__content'>
				<div className='plServiceInfo__content-rows fade-in-div'>
					<PLServiceDate serviceId={serviceId} />
					<PLServiceStatus serviceId={serviceId} />
					<PLServiceClient serviceId={serviceId} />
					<PLServiceTechnician serviceId={serviceId} />
					<PLServiceEditor serviceId={serviceId} />
					<PlServiceTeamMembers serviceId={serviceId} />
				</div>
			</div>
		</div>
	);
}
