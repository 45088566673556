import gql from 'graphql-tag';

export const GET_PL_SERVICE_MATTERPORT_SERVICE = gql`
	query GetServiceWithMatterport($id: String!) {
		service(id: $id) {
			id
			matterportModelId
		}
	}
`;

export const UPDATE_SERVICE_MATTERPORT = gql`
	mutation UpdateServiceMatterport($serviceId: String!, $matterportModelId: String) {
		updateService(id: $serviceId, matterportModelId: $matterportModelId) {
			id
			matterportModelId
		}
	}
`;
