export const errorUpdatingOption = 'Error updating option!';
export const successAddCert = 'Successfully added certification!';
export const errorRemoveCert = 'Error removing certification! ';
export const successRemoveCert = 'Successfully removed certification!';
export const removeCertTitle = 'Remove certification';
export const removeCertCheck = 'Are you sure you would like to remove the certification';
export const removeCertButton = 'remove';
export const selectCert = 'select a certification...';
export const selectCertTitle = 'Add a required certification';
export const dollarPrefix = '$';
export const techPayPlaceholder = 'Photographer pay...';
export const techPayHelp = 'This is how much a photographer is paid.';
export const techPayLabel = 'PHOTOGRAPHER PAY';
export const techFilePlaceholder = 'Photographer total file upload count...';
export const techFileHelp = 'This is the total number of files the photographer is told they are taking.';
export const techFileLabel = 'FILE UPLOAD COUNT';
export const traineePlaceholder = 'This is how much the trainee is paid.';
export const traineeLabel = 'TRAINEE PAY';
export const payTimePlaceholder = 'Pay time...';
export const payTimeHelp = 'In minutes how long is this option expected to take a photographer.';
export const payTimeLabel = 'PAY TIME (MIN)';
export const techInstructionPlaceholder = 'Specific instructions for the photographer for every time they service this option...';
export const techInstructionLabel = 'PHOTOGRAPHER INSTRUCTIONS';
export const durationLabel = 'DURATION';
export const durationPlaceholder = 'Duration...';
export const addCertHover = 'add required certification';
export const addCertHoverImage = 'add';
export const noRequiredCerts = 'this option has no required photographer certifications yet';
