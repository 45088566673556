// Created by jarongallo at 6/23/21
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EDIT_TYPE_SERVICE, STATUS_FINDING_TECHNICIAN, USER_ROLE_PHOTOGRAPHER } from '../../../../../../../../utils/Config/Constants';
import UIIconButton from '../../../../../../../../components/UI/UIIconButton';
import { Trashcan } from '../../../../../../../../assets/icons';
import UIButton from '../../../../../../../../components/UI/UIButton/UIButton';
import UIUser from '../../../../../../../../components/UI/UITeamMember/UITeamMember';
import UIOverlayCard from '../../../../../../../../components/UI/UIOverlayCard';
import UIModal from '../../../../../../../../components/UI/UIModal';
import Queries from '../../../../../../../../utils/API/Queries';
import * as strings from './strings';
import './styles';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../../../../../../redux/selectors/CurrentUserSelectors';
import { GET_SERVICE_REQ_TECHS } from './queries';
import { UI_ICON_BUTTON_CANCEL_TYPE, UI_ICON_BUTTON_FILL_TYPE, UI_ICON_BUTTON_WHITE_TYPE } from '../../../../../../../../components/UI/UIIconButton/config';
import UICertifiedTechSearch from '../../../../../../../../components/UI/UICertifiedTechSearch';

export default function EditPreferredSPModal({ serviceId, queryService, renderButton }: EditPreferredSPModalProps) {
	const isUserAdmin = useSelector(getIsUserAdminSelector);
	const signedInUser = useSelector(getSignedInUserSelector);

	const [service, setService] = useState<ServiceType>();
	const [showAddPreferredTechnicianModal, setShowAddPreferredTechnicianModal] = useState(false);
	const [isAddingPreferredTechnician, setIsAddingPreferredTechnician] = useState(false);
	const [isRemovingPreferredTechnician, setIsRemovingPreferredTechnician] = useState(false);
	const [selectedPreferredTechnician, setSelectedPreferredTechnician] = useState<UserType>();
	const [showRemoveRequestedTechnicianModal, setShowRemoveRequestedTechnicianModal] = useState(false);

	const [updateService] = useMutation(Queries.UPDATE_SERVICE);

	const [getService, { data: serviceData }] = useLazyQuery(GET_SERVICE_REQ_TECHS, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getService();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setService(serviceData['service']);
			}
		})();
	}, [serviceData]);

	if (!service) return null;

	const renderAddRequestedTechnicianModal = () => {
		const hideModal = () => {
			setShowAddPreferredTechnicianModal(false);
		};

		const onClickAddPreferredTech = async () => {
			if (!selectedPreferredTechnician) return;

			setIsAddingPreferredTechnician(true);
			toast.success(`Requested ${selectedPreferredTechnician.name}`);
			await updatePreferredTechnician(selectedPreferredTechnician.id);
			setShowAddPreferredTechnicianModal(false);
			setIsAddingPreferredTechnician(false);
			setSelectedPreferredTechnician(undefined);
		};
		return (
			<UIModal condition={showAddPreferredTechnicianModal}>
				<UIOverlayCard hideModal={hideModal} cardStyle={{ height: '100%' }} title={strings.requestedTechSearchOverlayTitle}>
					<UICertifiedTechSearch
						emptySetText={strings.requestedTechSearchEmptySet}
						certifications={service.bookedOptions.map((option) => option.photographerCertificatesRequired).flat(2)}
						label={strings.requestedTechSearchLabel}
						selectedUser={selectedPreferredTechnician}
						showSearchParameters={true}
						onSelectUser={(selectedPreferredTechnician: UserType) => setSelectedPreferredTechnician(selectedPreferredTechnician)}
						region={service.region}
					/>
					<div className='requestedTechnician--buttons'>
						{selectedPreferredTechnician && (
							<UIIconButton
								dark
								type={UI_ICON_BUTTON_FILL_TYPE}
								text={strings.requestedTechAddButton}
								isLoading={isAddingPreferredTechnician}
								onClick={onClickAddPreferredTech}
							/>
						)}
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderRequestedTechnician = () => {
		if (service.type === EDIT_TYPE_SERVICE || signedInUser.role === USER_ROLE_PHOTOGRAPHER) {
			return undefined;
		}

		const offerToOthers = async () => {
			const response = await updateService({ variables: { status: STATUS_FINDING_TECHNICIAN } });
			const { updateService: updatedService } = response['data'];
			if (updatedService) {
				await queryService();
				toast.success(strings.offerSuccessMessage);
			}
		};

		const removePreferredPhotographer = async () => {
			const response = await updateService({ variables: { preferredPhotographers: [] } });
			const { updateService: updatedService } = response['data'];
			if (updatedService) {
				await queryService();
				toast.success(strings.removeSPSuccessMessage);
			}
		};

		if (service.preferredPhotographers && (isUserAdmin || service.preferredPhotographers.length > 0)) {
			return (
				<div className='requestedTechnician__container'>
					{service.preferredPhotographers.length === 0 ? (
						isUserAdmin && (
							<div>
								<UIIconButton
									type={UI_ICON_BUTTON_WHITE_TYPE}
									className='requestedTechnician__container--button'
									onClick={() => setShowAddPreferredTechnicianModal(true)}
									text={strings.requestTechButton}
								/>
								{service.preferredPhotographers[0] && (
									<UIButton className='requestedTechnician__container--button' onClick={offerToOthers} text={strings.offerButton} />
								)}
								{service.preferredPhotographers[0] && (
									<UIButton className='requestedTechnician__container--button' onClick={removePreferredPhotographer} text={strings.removeSPButton} />
								)}
							</div>
						)
					) : (
						<UIUser user={service.preferredPhotographers[0]} />
					)}
					{isUserAdmin && renderButton && service.preferredPhotographers.length > 0 && (
						<UIIconButton
							className='requestedTechnician__container--button'
							type={UI_ICON_BUTTON_CANCEL_TYPE}
							icon={Trashcan.white}
							text={strings.removeRequestedButton}
							onClick={() => setShowRemoveRequestedTechnicianModal(true)}
						/>
					)}
				</div>
			);
		}
		return undefined;
	};

	const renderRemoveRequestedTechnicianModal = () => {
		let requestedTechnician = service.preferredPhotographers[0];

		if (!requestedTechnician) {
			return undefined;
		}
		const hideModal = () => {
			setShowRemoveRequestedTechnicianModal(false);
		};

		const onClickRemovePreferred = async () => {
			setIsRemovingPreferredTechnician(true);
			await updatePreferredTechnician(null);
			setIsRemovingPreferredTechnician(false);
			setSelectedPreferredTechnician(undefined);
			setShowRemoveRequestedTechnicianModal(false);
			toast.success(strings.removeRequestedModalSuccess);
		};

		return (
			<UIModal condition={showRemoveRequestedTechnicianModal}>
				<UIOverlayCard hideModal={hideModal} title={strings.removeRequestedModalTitle} description={strings.removeRequestedModalDescription}>
					<UIIconButton
						type={UI_ICON_BUTTON_CANCEL_TYPE}
						text={strings.removeRequestedModalButton}
						isLoading={isRemovingPreferredTechnician}
						onClick={onClickRemovePreferred}
					/>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const updatePreferredTechnician = async (technicianId: string | null) => {
		let variables = {
			serviceId: service.id,
			preferredPhotographers: [technicianId]
		};

		const response = await updateService({
			variables: variables
		});

		const { updateService: updatedService } = response['data'];
		if (updatedService) {
			await queryService();
		}
	};

	return (
		<div>
			{renderRequestedTechnician()}
			{renderAddRequestedTechnicianModal()}
			{renderRemoveRequestedTechnicianModal()}
		</div>
	);
}
