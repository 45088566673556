import React, { useEffect, useState } from 'react';
import './styles';
import UIOverlayCard from '../../UI/UIOverlayCard';
import UIListInputRow from '../../UI/UIListInputRow';
import Queries from '../../../utils/API/Queries';
import UIIconButton from '../../UI/UIIconButton';
import { convertCents } from '../../../utils/Helpers/StringHelpers';
import { UI_LABELED_INPUT_DROPDOWN_TYPE } from '../../UI/UILabeledInput/config';
import { DropdownItem } from '../../UI/UILabeledInput/types';
import { useLazyQuery } from '@apollo/react-hooks';
import { setUpBookableOptions } from '../../../pages/portal/global/EditOptions/config';

export default function PartnerSelectModal({ hideModal, onSelectionComplete, selectedPartnerPackage, packages, update }: BookingPartnerSelectModalProps) {
	const [selectedPartner, setSelectedPartner] = useState<DropdownItem>();
	const [optionsPool, setOptionsPool] = useState<BookableOptionType[]>();

	const partners = packages?.partners ?? [];
	let optionsPoolIds: string[] =
		partners
			.map((partner) => partner.packages)
			.flat(2)
			.map((partnerPack) => partnerPack.options)
			.flat(2)
			.map((option) => option.id) ?? [];

	const [getOptionsPool, { data: optionsPoolData }] = useLazyQuery(Queries.GET_OPTIONS, {
		variables: { query: { id: { op_in: optionsPoolIds } }, limit: 1000 }
	});

	useEffect(() => {
		(async () => {
			await getOptionsPool();
		})();
	}, []);

	useEffect(() => {
		(() => {
			if (optionsPoolData) {
				const fetchedOptionsPool = optionsPoolData['options'];
				setOptionsPool(setUpBookableOptions(fetchedOptionsPool, []));
			}
		})();
	}, [optionsPoolData]);

	if (!optionsPool) return null;

	const onSelectPackage = (partnerPackage) => {
		if (selectedPartnerPackage && partnerPackage?.name === selectedPartnerPackage.name) {
			update({ selectedPartnerPackage: undefined, selectedOptions: [] });
			return;
		}
		const selectedOptions = partnerPackage.options.map((option) => optionsPool.filter((poolOption) => poolOption.option.id === option.id)[0]).flat(2);
		update({ selectedPartnerPackage: partnerPackage, selectedOptions });
	};

	const renderSelectedPartner = () => {
		if (!selectedPartner) return;

		const selectedPartnerPackages = packages?.partners.filter((partner) => partner.name === selectedPartner.value)[0].packages ?? [];

		if (selectedPartnerPackages) {
			return (
				<div>
					{selectedPartnerPackages.map((partnerPackage) => {
						const isPackageSelected = selectedPartnerPackage?.name === partnerPackage.name;

						return (
							<div
								key={JSON.stringify(partnerPackage)}
								className='bookingPartnerSelect__option'
								onClick={() => onSelectPackage(partnerPackage)}
								style={isPackageSelected ? { border: 'var(--mint-primary) solid 2px' } : {}}>
								<p>{partnerPackage.name}</p>
								<p>{convertCents(partnerPackage.price)}</p>
								<div>
									{partnerPackage.options.map((option, index) => {
										const populatedOption = optionsPool.filter((poolOption) => poolOption.option.id === option.id)[0];
										return (
											<div key={`${option.id}-${index}`} className='bookingPartnerSelect__option--option'>
												<p className='bookingPartnerSelect__option--option-name'>{populatedOption.option.name}</p>
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
			);
		}
		return null;
	};

	const availablePartners = packages?.partners?.map((partner) => {
		return { label: partner.name, value: partner.name };
	});

	return (
		<UIOverlayCard
			title={'Select a partner and package'}
			description={'Quickly book a package from one of our partners. This will not charge the agent, but will create a pending invoice for the partner.'}
			hideModal={hideModal}
			cardStyle={{ minHeight: '100%' }}
			contentClassName={'bookingPartnerSelect__card'}>
			<UIListInputRow
				listInputStyle={{ padding: '8px 0' }}
				label={'select a partner'}
				type={UI_LABELED_INPUT_DROPDOWN_TYPE}
				value={selectedPartner}
				onChangeDropdownValue={setSelectedPartner}
				options={availablePartners}
			/>
			{renderSelectedPartner()}
			<div className='bookingPartnerSelect--button'>
				<UIIconButton dark isDisabled={!selectedPartnerPackage} onClick={onSelectionComplete} text={'add package'} />
			</div>
		</UIOverlayCard>
	);
}
