import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ID_KEY } from '../../utils/Config/Constants';
import { getUser } from '../../api/user/userApi';
import { USER_FETCH_FAILED, USER_FETCH_REQUESTED } from './types';
import { SET_SIGNED_IN_USER, SET_SUPER_USER, SHOW_LOGIN } from '../../redux/store/user/types';
import { isUserAdminOrRM } from '../../utils/Helpers/UserHelpers';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* onFetchFailed() {}

function* fetchSignedInUser(payload) {
	try {
		const user = yield getUser({ id: localStorage.getItem(ID_KEY) }, payload.client);
		if (!user) {
			yield put({ type: USER_FETCH_FAILED, message: 'User is null' });
			if (payload.onError) payload.onError({ message: 'User is null', nullUser: true });
			return;
		}

		if (isUserAdminOrRM(user)) {
			yield put({
				type: SET_SUPER_USER,
				superUser: {
					...user
				}
			});
		}

		yield put({ type: SET_SIGNED_IN_USER, user });
		yield put({ type: SHOW_LOGIN, displayLogin: false });
		if (payload.onFinish) payload.onFinish(user);
	} catch (e) {
		yield put({ type: USER_FETCH_FAILED, message: e.message });
		if (payload.onError) payload.onError(e);
	}
}

/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
function* loginSaga() {
	yield takeLatest(USER_FETCH_REQUESTED, fetchSignedInUser);
	yield takeEvery(USER_FETCH_FAILED, onFetchFailed);
}

export default loginSaga;
