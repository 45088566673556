import {
	STATUS_ADMIN_HOLD,
	STATUS_EDITOR_UPLOAD,
	STATUS_FINDING_EDIT_TECHNICIAN,
	STATUS_FINDING_PREFERRED_TECHNICIAN,
	STATUS_FINDING_TECHNICIAN,
	STATUS_LIVE,
	STATUS_PRE_ORDER,
	STATUS_PROOFING,
	STATUS_REVIEW,
	STATUS_STANDBY,
	STATUS_TECHNICIAN_UPLOAD
} from './Constants';

export const LABEL_AWAITING_APPROVAL = 'Pending initial approval';
export const LABEL_FINDING_PHOTOGRAPHER = 'Scheduling technician';
export const LABEL_FINDING_PREFERRED_TECHNICIAN = 'Scheduling preferred technician';
export const LABEL_PHOTOGRAPHER_UPLOAD = 'Service confirmed';
export const LABEL_FINDING_EDITOR = 'Editing';
export const LABEL_EDITOR_UPLOAD = 'Processing';
export const LABEL_REVIEW = 'Awaiting review';
export const LABEL_PROOFING = 'Proofing';
export const LABEL_LIVE = 'Live';
export const LABEL_ADMIN_HOLD = 'Administrative hold';
export const LABEL_STANDBY = 'Standby';

export const DESCRIPTION_AWAITING_APPROVAL = 'When a service is booked via our API integration or JSON it will begin in this status.';
export const DESCRIPTION_FINDING_PHOTOGRAPHER = 'When a service has no technician assigned.';
export const DESCRIPTION_FINDING_PREFERRED_TECHNICIAN = 'When a service has no technician assigned, but a preferred technician is requested.';
export const DESCRIPTION_PHOTOGRAPHER_UPLOAD = 'When a service is scheduled with a technician and the technician has not uploaded files yet.';
export const DESCRIPTION_FINDING_EDITOR = 'When a service has all the required raw files for processing, but has no editor assigned yet.';
export const DESCRIPTION_EDITOR_UPLOAD = 'When a service is waiting for an editor to complete the request.';
export const DESCRIPTION_REVIEW = 'When a service has been completed by the editor and the content needs a managers approval.';
export const DESCRIPTION_PROOFING = 'When a service is approved, but has photos that need proofed.';
export const DESCRIPTION_LIVE = 'When a service is complete and all work is finished.';
export const DESCRIPTION_ADMIN_HOLD = 'When a service is on hold due to an issue.';
export const DESCRIPTION_STANDBY = 'When a service does not have a stage.';

export const statusList = [
	{ value: STATUS_PRE_ORDER, label: LABEL_AWAITING_APPROVAL, description: DESCRIPTION_AWAITING_APPROVAL },
	{ value: STATUS_FINDING_TECHNICIAN, label: LABEL_FINDING_PHOTOGRAPHER, description: DESCRIPTION_FINDING_PHOTOGRAPHER },
	{ value: STATUS_FINDING_PREFERRED_TECHNICIAN, label: LABEL_FINDING_PREFERRED_TECHNICIAN, description: DESCRIPTION_FINDING_PREFERRED_TECHNICIAN },
	{ value: STATUS_TECHNICIAN_UPLOAD, label: LABEL_PHOTOGRAPHER_UPLOAD, description: DESCRIPTION_PHOTOGRAPHER_UPLOAD },
	{ value: STATUS_FINDING_EDIT_TECHNICIAN, label: LABEL_FINDING_EDITOR, description: DESCRIPTION_FINDING_EDITOR },
	{ value: STATUS_EDITOR_UPLOAD, label: LABEL_EDITOR_UPLOAD, description: DESCRIPTION_EDITOR_UPLOAD },
	{ value: STATUS_REVIEW, label: LABEL_REVIEW, description: DESCRIPTION_REVIEW },
	{ value: STATUS_PROOFING, label: LABEL_PROOFING, description: DESCRIPTION_PROOFING },
	{ value: STATUS_LIVE, label: LABEL_LIVE, description: DESCRIPTION_LIVE },
	{ value: STATUS_ADMIN_HOLD, label: LABEL_ADMIN_HOLD, description: DESCRIPTION_ADMIN_HOLD },
	{ value: STATUS_STANDBY, label: LABEL_STANDBY, description: DESCRIPTION_STANDBY }
];

export const photoshootStatusList = [
	{ value: STATUS_FINDING_TECHNICIAN, label: LABEL_FINDING_PHOTOGRAPHER, description: DESCRIPTION_FINDING_PHOTOGRAPHER },
	{ value: STATUS_FINDING_PREFERRED_TECHNICIAN, label: LABEL_FINDING_PREFERRED_TECHNICIAN, description: DESCRIPTION_FINDING_PREFERRED_TECHNICIAN },
	{ value: STATUS_TECHNICIAN_UPLOAD, label: LABEL_PHOTOGRAPHER_UPLOAD, description: DESCRIPTION_PHOTOGRAPHER_UPLOAD },
	{ value: STATUS_FINDING_EDIT_TECHNICIAN, label: LABEL_FINDING_EDITOR, description: DESCRIPTION_FINDING_EDITOR },
	{ value: STATUS_EDITOR_UPLOAD, label: LABEL_EDITOR_UPLOAD, description: DESCRIPTION_EDITOR_UPLOAD },
	{ value: STATUS_REVIEW, label: LABEL_REVIEW, description: DESCRIPTION_REVIEW },
	{ value: STATUS_PROOFING, label: LABEL_PROOFING, description: DESCRIPTION_PROOFING },
	{ value: STATUS_LIVE, label: LABEL_LIVE, description: DESCRIPTION_LIVE }
];

export const rawEditStatusList = [
	{ value: STATUS_FINDING_EDIT_TECHNICIAN, label: LABEL_FINDING_EDITOR, description: DESCRIPTION_FINDING_EDITOR },
	{ value: STATUS_EDITOR_UPLOAD, label: LABEL_EDITOR_UPLOAD, description: DESCRIPTION_EDITOR_UPLOAD },
	{ value: STATUS_REVIEW, label: LABEL_REVIEW, description: DESCRIPTION_REVIEW },
	{ value: STATUS_PROOFING, label: LABEL_PROOFING, description: DESCRIPTION_PROOFING },
	{ value: STATUS_LIVE, label: LABEL_LIVE, description: DESCRIPTION_LIVE }
];

export const adminStagesList = [
	{ value: STATUS_PRE_ORDER, label: LABEL_AWAITING_APPROVAL, description: DESCRIPTION_AWAITING_APPROVAL },
	{ value: STATUS_ADMIN_HOLD, label: LABEL_ADMIN_HOLD, description: DESCRIPTION_ADMIN_HOLD },
	{ value: STATUS_STANDBY, label: LABEL_STANDBY, description: DESCRIPTION_STANDBY }
];
