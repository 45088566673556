export const cardTitle = 'Update property';
export const saveButtonTitle = 'save property';

export const updateSuccess = 'Successfully updated property!';
export const updateError = 'Error updating property!';

export const streetAddressLabel = 'street address *';
export const streetAddressPlaceholder = 'street address...';
export const unitNumberLabel = 'unit number';
export const unitNumberPlaceholder = 'unit #...';
export const cityLabel = 'city *';
export const cityPlaceholder = 'city...';
export const stateLabel = 'state *';
export const statePlaceholder = 'state...';
export const countryLabel = 'country *';
export const countryPlaceholder = 'country...';
export const zipCodeLabel = 'zip code *';
export const zipCodePlaceholder = 'zip code...';
export const aliasLabel = 'alias';
export const aliasPlaceholder = 'alias...';
