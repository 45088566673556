import React, { Component } from 'react';
import './table-available-editor.scss';
import { connect } from 'react-redux';
import { STATUS_EDITOR_UPLOAD } from '../../../../../utils/Config/Constants';
import { getPackageString } from '../../../../../utils/Helpers/PaymentHelper/PaymentHelper';
import UIIconButton from '../../../../UI/UIIconButton';
import Queries from '../../../../../utils/API/Queries';
import UIModal from '../../../../UI/UIModal';
import UIOverlayCard from '../../../../UI/UIOverlayCard';
import UIAvailableOptions from '../../../../UI/UIAvailableOptions';
import { withApollo } from '@apollo/react-hoc';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../../../utils/Helpers/AppHelper';
import { getServiceDate, getServiceTime } from '../../PLTableHelpers/PLTableHelpers';
import { UI_ICON_BUTTON_WHITE_TYPE } from '../../../../UI/UIIconButton/config';

class TableAvailableEditor extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	// accepts service as editor
	acceptServiceEditor = async () => {
		const { isAcceptingService, serviceToAccept } = this.state;
		if (!isAcceptingService) {
			this.setState({ isAcceptingService: true });
			if (serviceToAccept) {
				const response = await this.props.client
					.mutate({
						mutation: Queries.UPDATE_SERVICE,
						variables: {
							serviceId: serviceToAccept.id,
							status: STATUS_EDITOR_UPLOAD,
							editor: this.props.currentUser.user.id,
							editingCompany: this.props.currentUser.user.editingCompany.id
						}
					})
					.catch((error) => this.props.showInfoMessage(error.message));
				const { data } = response;
				if (data) {
					await this.props.updateTable();
					this.setState({ serviceToAccept: undefined, isAcceptingService: false });
					this.props.showSuccessMessage('Successfully accepted edit!');
				}
			}
		}
	};

	//Mark: Render functions
	render() {
		const { data, pagination } = this.props;

		return (
			<div className='tableAvailableEditor'>
				<div className='tableAvailableEditor__items fade-in-div'>
					{data.map((service) => this.renderEditorAvailableTable(service))}
					{pagination}
				</div>
				{this.renderAcceptServiceModal()}
			</div>
		);
	}

	renderEditorAvailableTable = (service) => {
		let serviceDate = getServiceDate(service);
		let serviceTime = getServiceTime(service);
		if (!service) {
			return null;
		} else
			return (
				<div className='tableAvailableEditor__items--row' key={service.id} onClick={() => this.setState({ serviceToAccept: service })}>
					<div className='tableAvailableEditor__items--datetime'>
						<p className='tableAvailableEditor__items--datetime-date'>{serviceDate}</p>
						<p className='tableAvailableEditor__items--datetime-time'>{serviceTime}</p>
					</div>
					<p className='tableAvailableEditor__items--address'>{service.property.address}</p>
					<div className='tableAvailableEditor__items__options'>
						{service.bookedOptions.map((bookedOption) => (
							<p key={bookedOption.id}>{bookedOption.name}</p>
						))}
					</div>
				</div>
			);
	};

	renderAcceptServiceModal = () => {
		const { serviceToAccept, isAcceptingService } = this.state;
		let options;

		if (!serviceToAccept) {
			return <div key={serviceToAccept} />;
		}
		options = getPackageString(serviceToAccept.bookedOptions, false, false, true, true);
		const hideModal = () => {
			this.setState({ serviceToAccept: undefined });
		};

		return (
			<UIModal condition={serviceToAccept} hideModal={hideModal}>
				<UIOverlayCard hideModal={hideModal} title='Accept edit?'>
					<div className='tableAvailableEditor__options'>
						<p className='tableAvailableEditor__options--header'>Edits required</p>
						<UIAvailableOptions className='tableAvailableEditor__options--list' bookedOptions={options} />
					</div>
					<UIIconButton type={UI_ICON_BUTTON_WHITE_TYPE} isLoading={isAcceptingService} text={'accept'} onClick={this.acceptServiceEditor} />
				</UIOverlayCard>
			</UIModal>
		);
	};
}

export default withApollo(connect(commonMapStateToProps, commonMapDispatchToProps)(withApollo(TableAvailableEditor)));
