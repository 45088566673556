import gql from 'graphql-tag';

export const GET_SERVICE_WITH_STATUS = gql`
	query GetServiceWithStatus($id: String!) {
		service(id: $id) {
			id
			status
			type
		}
	}
`;
