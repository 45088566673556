import React, { useEffect, useState } from 'react';
import * as EmailValidator from 'email-validator';
import * as S from './styles';
import { ZendeskAPI } from 'react-zendesk';
import Queries from '../../../utils/API/Queries';
import { ID_KEY, ROLE_KEY, SUPER_USER_TOKEN_KEY, TOKEN_KEY, USER_ROLE_AGENT, USER_ROLE_PHOTOGRAPHER } from '../../../utils/Config/Constants';
import { AgentIcon, TechniciansIcon } from '../../../assets/icons';
import { clearUserCache } from '../../../utils/Helpers/LoginHelper';
import UICheckbox from '../../../components/UI/UICheckbox/UICheckbox';
import { useHistory, useLocation } from 'react-router-dom';
import { getInitialRoute, isUserAdminOrRM } from '../../../utils/Helpers/UserHelpers';
import { UI_LABELED_INPUT_PHONE_NUMBER_TYPE, UI_LABELED_INPUT_TEXT_TYPE } from '../../../components/UI/UILabeledInput/config';
import { toast } from 'react-toastify';
import { useApolloClient } from '@apollo/react-hooks';
import * as strings from './strings';
import { TABS } from './config';
import { useDispatch } from 'react-redux';
import { setSignedInUser, showSignup } from '../../../redux/store/user/actions';
import { UI_ICON_BUTTON_WHITE_TYPE } from '../../../components/UI/UIIconButton/config';
import UIOverlayCard, { OVERLAY_CLOSE_TYPES } from '../../../components/UI/UIOverlayCard';

export default function Signup({ inviteCode, hideModal }: SignupProps) {
	const dispatch = useDispatch();

	const history = useHistory();

	const client = useApolloClient();

	const location = useLocation();

	const [name, setName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [number, setNumber] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [isPhotographerSignup, setIsPhotographerSignup] = useState(false);
	const [inviteCodeValue, setInviteCodeValue] = useState<string>(inviteCode ? inviteCode : '');
	const [selectedPage, setSelectedPage] = useState<SignupTabProps | object>();
	const [checkbox, setCheckbox] = useState(false);
	const [tabSelected, setTabSelected] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (new URLSearchParams(location.search).get('invitecode')) {
			let inviteCodePassed = new URLSearchParams(location.search).get('invitecode');
			setInviteCodeValue(inviteCodePassed || '');
		}
	});

	const userSelection = (userType) => {
		let user = userType;
		setTabSelected(true);

		user === USER_ROLE_AGENT ? setSelectedPage(TABS.agentSignup) : setSelectedPage(TABS.photographerSignup);
	};

	const goBack = () => {
		setTabSelected(false);
		setIsPhotographerSignup(false);
		setSelectedPage(undefined);
	};

	const toggleIsChecked = () => {
		if (!checkbox) {
			setCheckbox(true);
		} else {
			setCheckbox(false);
		}
	};

	const signup = async () => {
		if (name.trim().length === 0) {
			toast.success(strings.nameValidatorMessage);
		} else if (email.trim().length === 0) {
			toast.success(strings.emailValidatorlengthMessage);
		} else if (!EmailValidator.validate(email.trim())) {
			toast.success(strings.emailValidatorMessage);
		} else if (number.trim().length < 10) {
			toast.success(strings.numberValidatorMessage);
		} else if (password.trim().length < 8) {
			toast.success(strings.passwordValidatorMessage);
		} else if (!checkbox) {
			toast.success(strings.termsOfUseCheckboxValidator);
		} else {
			await signupCall();
		}
	};

	const getUserPromos = async (createdUser) => {
		const response = await client
			.query({
				query: Queries.GET_USER_PROMOS,
				variables: { userId: createdUser.id }
			})
			.catch((error) => toast.error(error.message));
		const { promos } = response['data'];
		if (promos) {
			clearUserCache();

			localStorage.setItem(ID_KEY, createdUser.id);
			localStorage.setItem(TOKEN_KEY, createdUser.token);
			if (isUserAdminOrRM(createdUser)) {
				localStorage.setItem(SUPER_USER_TOKEN_KEY, createdUser.token);
			}
			localStorage.setItem(ROLE_KEY, createdUser.role);

			ZendeskAPI('webWidget', 'identify', {
				name: createdUser.name,
				email: createdUser.email
			});

			createdUser.promos = promos;
			return createdUser;
		}
	};

	const signupCall = async () => {
		let photographerSignup;
		photographerSignup = selectedPage === TABS.photographerSignup;

		setIsLoading(true);
		let variables = {
			role: isPhotographerSignup || photographerSignup ? USER_ROLE_PHOTOGRAPHER : USER_ROLE_AGENT,
			name: name,
			email: email,
			phoneNumber: number,
			image: 'https://storage.googleapis.com/pineapple-estates/marketing-images/default-image.svg',
			password: password,
			requiresPasswordChange: false,
			inviteCode: inviteCodeValue !== '' ? inviteCodeValue.toLowerCase() : undefined
		};

		const response = await client
			.mutate({
				mutation: Queries.CREATE_USER_MUTATION,
				variables: variables
			})
			.catch((error) => {
				toast.error(error.message.replace('GraphQL error: ', ''));
				setIsLoading(false);
			});

		const { signup } = response['data'];
		if (signup) {
			const user = await getUserPromos(signup);
			await getUserListingsAndLogin(user);
		}
	};

	const getUserListingsAndLogin = async (user) => {
		const response = await client
			.query({
				query: Queries.GET_LISTINGS_WITH_QUERY,
				variables: { q: { agentId: { op_eq: user.id } } }
			})
			.catch((error) => toast.error(error.message));
		const { listings } = response['data'];
		if (listings) {
			user.listings = listings;
			setIsLoading(false);
			dispatch(setSignedInUser(user));
			dispatch(showSignup(false));
			toast.success(strings.welcome);
			history.push(getInitialRoute(user) + location.search);
		}
	};

	const renderUserSelection = () => {
		if (tabSelected) return null;

		return (
			<S.div_SignupSelection>
				<S.p_SignupCardHeader>{strings.userRoleHeader}</S.p_SignupCardHeader>
				<S.div_SignupCardsContainer>
					<S.div_SignupCard id='user-agent-select-button' onClick={() => userSelection('agent')}>
						<S.PLImage_SignupCardImage src={AgentIcon} alt={'agent icon'} style={{ borderRadius: 30 }} imageStyle={{ height: '100%' }} />
						<S.p_SignupCardText>{strings.userRoleAgent}</S.p_SignupCardText>
					</S.div_SignupCard>
					<S.div_SignupCard onClick={() => userSelection('provider')}>
						<S.PLImage_SignupCardImage src={TechniciansIcon} alt={'technician icon'} style={{ borderRadius: 30 }} imageStyle={{ height: '100%' }} />
						<S.p_SignupCardText>{strings.userRoleTech}</S.p_SignupCardText>
					</S.div_SignupCard>
				</S.div_SignupCardsContainer>
			</S.div_SignupSelection>
		);
	};

	const renderTab = () => {
		if (!tabSelected) return null;

		return (
			<S.div_SignupContainerTab>
				<S.UIListInputRow_SignupInput
					id={strings.nameId}
					type={UI_LABELED_INPUT_TEXT_TYPE}
					value={name}
					placeholder={strings.namePlaceholder}
					label={strings.nameLabel}
					onChangeValue={(value) => setName(value)}
				/>
				<S.UIListInputRow_SignupInput
					id={strings.emailId}
					type={UI_LABELED_INPUT_TEXT_TYPE}
					value={email}
					placeholder={strings.emailPlaceholder}
					label={strings.emailLabel}
					onChangeValue={(value) => setEmail(value)}
				/>
				<S.UIListInputRow_SignupInput
					id={strings.numberId}
					type={UI_LABELED_INPUT_PHONE_NUMBER_TYPE}
					value={number}
					placeholder={strings.numberPlaceholder}
					label={strings.numberLabel}
					onChangeValue={(value) => setNumber(value)}
				/>
				<S.UIListInputRow_SignupInput
					id={strings.passwordId}
					type={UI_LABELED_INPUT_TEXT_TYPE}
					value={password}
					placeholder={strings.passwordPlaceholder}
					label={strings.passwordLabel}
					onChangeValue={(value) => setPassword(value)}
				/>
				<S.UIListInputRow_SignupInput
					id={strings.inviteCodeId}
					type={UI_LABELED_INPUT_TEXT_TYPE}
					value={inviteCodeValue}
					placeholder={strings.inviteCodePlaceholder}
					label={strings.inviteCodeLabel}
					onChangeValue={(value) => setInviteCodeValue(value)}
				/>
				<S.div_SignupCheckboxContainer>
					<UICheckbox id='signup-checkbox' onChangeExpansion={() => toggleIsChecked()} status={checkbox ? UICheckbox.CHECKED : UICheckbox.UNCHECKED} />
					<S.p_SignupTerms>
						{strings.termsString1}{' '}
						<S.Link_SignupTermsLink target='_blank' onClick={(e) => e.stopPropagation()} to={'/terms-of-use'}>
							{strings.termsString2}{' '}
						</S.Link_SignupTermsLink>
						{strings.andText}{' '}
						<S.Link_SignupTermsLink target='_blank' onClick={(e) => e.stopPropagation()} to={'/privacy-policy'}>
							{strings.termsString3}
						</S.Link_SignupTermsLink>
						.
					</S.p_SignupTerms>
				</S.div_SignupCheckboxContainer>
				<S.UIIconButton_SignupFinishButton
					dark
					id={strings.buttonId}
					type={UI_ICON_BUTTON_WHITE_TYPE}
					isLoading={isLoading}
					onClick={signup}
					text={strings.finishString}
				/>
			</S.div_SignupContainerTab>
		);
	};

	const onCloseClick = () => {
		if (!selectedPage) {
			hideModal();
		} else {
			goBack();
		}
	};

	return (
		<UIOverlayCard title={strings.createAccount} hideModal={onCloseClick} closeType={selectedPage ? OVERLAY_CLOSE_TYPES.back : OVERLAY_CLOSE_TYPES.close}>
			{renderTab()}
			{renderUserSelection()}
		</UIOverlayCard>
	);
}
