// Created by carsonaberle at 11/24/21
import React from 'react';
import './styles';
import UIOverlayCard, { OVERLAY_CLOSE_TYPES } from '../../../../../../../components/UI/UIOverlayCard';
import { EDIT_TYPE_SERVICE, SCREEN_TYPE } from '../../../../../../../utils/Config/Constants';
import * as strings from './strings';
import { useSelector } from 'react-redux';
import { getScreenTypeSelector } from '../../../../../../../redux/selectors/ControlSelectors';

export default function ServiceProofingHelpCard({ hideModal, serviceType }: ServiceProofingHelpCardProps) {
	const screenType = useSelector(getScreenTypeSelector);

	const renderAdditionalPurchase = () => {
		if (serviceType !== EDIT_TYPE_SERVICE) {
			return (
				<>
					<p className='serviceProofingHelpCard--header'>{strings.laterPurchaseTitle}</p>
					<p className='serviceProofingHelpCard--description'>{strings.laterPurchaseDescription}</p>
				</>
			);
		}
		return null;
	};

	return (
		<UIOverlayCard
			closeType={screenType === SCREEN_TYPE.mobile ? OVERLAY_CLOSE_TYPES.back : OVERLAY_CLOSE_TYPES.close}
			hideModal={hideModal}
			title={strings.cardTitle}>
			<p className='serviceProofingHelpCard--header'>{strings.whyAmISeeingThisTitle}</p>
			<p className='serviceProofingHelpCard--description'>{strings.whyAmISeeingThisDescription}</p>
			<p className='serviceProofingHelpCard--header'>{strings.whatNowTitle}</p>
			<p className='serviceProofingHelpCard--description'>
				{serviceType === EDIT_TYPE_SERVICE ? strings.whatNowDescriptionEdit : strings.whatNowDescriptionPhotoshoot}
			</p>
			{renderAdditionalPurchase()}
			<p className='serviceProofingHelpCard--header'>{strings.helpTitle}</p>
			<a
				className='serviceProofingHelpCard--description-primary'
				target='__blank'
				href='https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=pineapplelabs.zendesk.com'>
				{strings.chatNow}
			</a>
		</UIOverlayCard>
	);
}
