import { ReduxStateType } from '../types';

export const getBookingConfigSelector = (state: ReduxStateType) => {
	return state.booking.bookingConfig;
};

export const getSelectedOptionsSelector = (state: ReduxStateType) => {
	return state.booking.bookingConfig?.selectedOptions ?? [];
};

export const getBookingServiceLocationSelector = (state: ReduxStateType) => {
	return state.booking.bookingConfig?.serviceLocation;
};

export const getSelectedPartnerPackageSelector = (state: ReduxStateType) => {
	return state.booking.bookingConfig?.selectedPartnerPackage;
};
