import React, { useEffect, useState } from 'react';
import * as S from './styles';
import VTFloorPlans from './components/VTFloorPlans';
import {
	VIRTUAL_TOUR_FLOOR_PLANS_TAB,
	VIRTUAL_TOUR_INCOMPLETE_TAB,
	VIRTUAL_TOUR_MATTERPORT_TAB,
	VIRTUAL_TOUR_PHOTOS_TAB,
	VIRTUAL_TOUR_SLIDESHOW_TAB,
	VIRTUAL_TOUR_VIDEOS_TAB
} from './config';
import UIModal from '../../../components/UI/UIModal';
import DownloadModal from '../../../components/modals/Download';
import VTPhotos from './components/VTPhotos';
import { generatePath, useHistory } from 'react-router-dom';
import { VIRTUAL_TOUR_QUERY } from './queries';
import VTSectionBar from './components/VTSectionBar';
import VTSlideshow from './components/VTSlideshow';
import VTVideos from './components/VTVideo';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_VIRTUAL_TOUR_FILES } from './components/VTPhotos/queries';
import { FILE_STATUS_LIVE, FILE_TYPE_FLOOR_PLAN, FILE_TYPE_IMAGE, FILE_TYPE_VIDEO } from '../../../utils/Config/Constants';
import { AudioPlayerProvider } from 'react-use-audio-player';
import VTMetaHelmet from './components/VTMetaHelmet';
import VTHeader from './components/VTHeader';
import VTError from './components/VTError';
import * as strings from './strings';
import { UI_ICON_BUTTON_BLACK_TYPE } from '../../../components/UI/UIIconButton/config';
import VTMatterport from './components/VTMatterport';
import { filterByShouldShowDimensions } from './components/VTFloorPlans/config';
import * as ReactGA from 'react-ga';

export default function VirtualTour({ listing, error, match }: VirtualTourProps) {
	const [files, setFiles] = useState<FileType[]>();
	const [shouldShowDownloadModal, setShouldShowDownloadModal] = useState<boolean>(false);
	const [showFPDimensions, setShowFPDimensions] = useState<boolean>(false);

	const showDownloadModal = () => setShouldShowDownloadModal(true);

	if (error) return <VTError error={error} />;
	if (!listing) return <VTError error={{ path: [], message: strings.noListingFoundError }} />;

	const history = useHistory();

	const isExternal = match.path.startsWith('/external');

	useEffect(() => {
		ReactGA.pageview('/virtualtour/' + listing.id, [], 'VirtualTour');
		ReactGA.event({
			category: 'VirtualTour',
			action: listing.id
		});
	}, []);

	useEffect(() => {
		const fileLength = files?.length || 0;
		if (fileLength > 0 && !match.params.page) {
			const path = generatePath(match.path, { id: listing.id, page: menuItems[0].path });
			history.replace(path);
		}
	}, [files]);

	const [getFiles, { data: filesData }] = useLazyQuery(GET_VIRTUAL_TOUR_FILES, {
		variables: {
			limit: 2000,
			query: {
				listingId: listing.id,
				isActive: true,
				isVisible: true,
				status: FILE_STATUS_LIVE,
				type: { op_in: [FILE_TYPE_IMAGE, FILE_TYPE_FLOOR_PLAN, FILE_TYPE_VIDEO] }
			}
		}
	});

	useEffect(() => {
		(async () => {
			await getFiles();
		})();
	}, []);

	useEffect(() => {
		(() => {
			if (filesData) {
				const fetchedFiles: FileType[] = filesData['files'];
				if (fetchedFiles) {
					setFiles(fetchedFiles.filter((file) => file.isVisible && file.isActive).sort((a, b) => a.index - b.index));
				}
			}
		})();
	}, [filesData]);

	let listingMatterportModelId = listing.matterportModelId;
	let listingImages = files?.filter((file) => file.type === FILE_TYPE_IMAGE) || [];
	let listingFloorPlans = files?.filter((file) => file.type === FILE_TYPE_FLOOR_PLAN) || [];
	let listingVideos = files?.filter((file) => file.type === FILE_TYPE_VIDEO) || [];

	const getMenuItems = () => {
		if (!listing) return [VIRTUAL_TOUR_INCOMPLETE_TAB];

		let menuItems: VirtualTourSection[] = [];

		if (listingImages.length > 0) {
			menuItems.push(VIRTUAL_TOUR_SLIDESHOW_TAB);
			menuItems.push(VIRTUAL_TOUR_PHOTOS_TAB);
		}
		if (listingMatterportModelId) {
			menuItems.push(VIRTUAL_TOUR_MATTERPORT_TAB);
		}
		if (listingFloorPlans.length > 0) {
			menuItems.push(VIRTUAL_TOUR_FLOOR_PLANS_TAB);
		}
		if (listingVideos.length > 0) {
			menuItems.push(VIRTUAL_TOUR_VIDEOS_TAB);
		}
		if (menuItems.length === 0) {
			menuItems.push(VIRTUAL_TOUR_INCOMPLETE_TAB);
		}

		return menuItems;
	};

	const menuItems: VirtualTourSection[] = getMenuItems();

	const Helmet = <VTMetaHelmet listing={listing} />;

	if (!files) return Helmet;

	const renderDownloadModal = () => {
		const hideModal = () => setShouldShowDownloadModal(false);

		return (
			<UIModal condition={shouldShowDownloadModal} className='plModal-zIndex-fix'>
				<DownloadModal hideModal={hideModal} listingId={listing ? listing.id : undefined} downloadFilename={listing?.property?.address ?? 'download'} />
			</UIModal>
		);
	};

	const renderTabComponent = () => {
		if (!listing) return null;

		switch (match.params.page) {
			case VIRTUAL_TOUR_PHOTOS_TAB.path:
				return <VTPhotos files={listingImages} />;
			case VIRTUAL_TOUR_FLOOR_PLANS_TAB.path:
				return <VTFloorPlans files={listingFloorPlans} showDimensions={showFPDimensions} />;
			case VIRTUAL_TOUR_MATTERPORT_TAB.path:
				return <VTMatterport matterportModelId={listingMatterportModelId} isExternal={isExternal} />;
			case VIRTUAL_TOUR_VIDEOS_TAB.path:
				return <VTVideos videos={listingVideos} />;
			case VIRTUAL_TOUR_SLIDESHOW_TAB.path:
				return (
					<AudioPlayerProvider>
						<VTSlideshow files={listingImages} audioFile={listing.audioFile} isExternal={isExternal} />
					</AudioPlayerProvider>
				);
			case VIRTUAL_TOUR_INCOMPLETE_TAB.path:
			default:
				return null;
		}
	};

	const renderAdditionalInfo = () => {
		if (match.params.page !== VIRTUAL_TOUR_FLOOR_PLANS_TAB.path) return null;
		if (files.filter((file) => filterByShouldShowDimensions(true, file)).length === 0) return null;

		const StyledCheckmark = showFPDimensions ? S.div_VTSectionMatterportIncludeChecked : S.div_VTSectionMatterportIncludeCheck;
		return (
			<S.div_VTSectionMatterportInclude onClick={() => setShowFPDimensions(!showFPDimensions)}>
				<StyledCheckmark />
				<S.p_VTSectionMatterportIncludeLabel>include measurements</S.p_VTSectionMatterportIncludeLabel>
			</S.div_VTSectionMatterportInclude>
		);
	};

	const renderMobileDownloadButton = () => {
		if (!isExternal) return null;

		return (
			<S.div_VT_DownloadMobile>
				<S.UIIconButton_VTDownloadMobile style={{ borderRadius: 20 }} type={UI_ICON_BUTTON_BLACK_TYPE} text={strings.download} onClick={showDownloadModal} />
			</S.div_VT_DownloadMobile>
		);
	};

	return (
		<S.div_VTBody>
			{Helmet}
			<VTHeader listing={listing} showDownloadModal={showDownloadModal} />
			<S.div_VTSectionInfo>
				<VTSectionBar listingId={listing.id} match={match} sections={menuItems} />
				{renderAdditionalInfo()}
			</S.div_VTSectionInfo>
			<S.div_VTContent> {renderTabComponent()}</S.div_VTContent>
			{renderMobileDownloadButton()}
			{renderDownloadModal()}
		</S.div_VTBody>
	);
}

//query on the server render and pass queried listing as props of functional component
VirtualTour.getInitialProps = async ({ match, client }) => {
	let listingId = match.params.id;
	const response = await client.query({
		query: VIRTUAL_TOUR_QUERY,
		variables: { id: listingId }
	});
	if (response.errors) {
		return { error: response.errors[0] };
	}
	const { listingVirtualTour: listing } = response.data;
	return { listing: listing || { id: listingId } };
};
