import React from 'react';
import './styles';
import TableServiceRow from './TableServiceRow';

export default function TableService({ data, pagination, hideAddress }: TableServiceProps) {
	return (
		<div className='service-table-card-container fade-in-div' id={'table-service-container'}>
			<div className='tableService'>
				{data.map((service) => (
					<TableServiceRow key={service.id} service={service} hideAddress={hideAddress} />
				))}
			</div>
			{pagination}
		</div>
	);
}
