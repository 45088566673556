import React, { useState } from 'react';
import './table-available-photographer.scss';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { getPackageString, getPhotographerPayFromOptions } from '../../../../../utils/Helpers/PaymentHelper/PaymentHelper';
import { SCREEN_TYPE, STATUS_FINDING_PREFERRED_TECHNICIAN, STATUS_FINDING_TECHNICIAN, STATUS_TECHNICIAN_UPLOAD } from '../../../../../utils/Config/Constants';
import UIModal from '../../../../UI/UIModal';
import TechnicianSuggestTimeChangeModal from '../../../../modals/TechnicianSuggestTimeChange';
import Queries from '../../../../../utils/API/Queries';
import { guessCurrentTimezone } from '../../../../../utils/Helpers/AppHelper';
import UIUser from '../../../../UI/UITeamMember/UITeamMember';
import { HistoryWhite } from '../../../../../assets/icons';
import UIIconButton from '../../../../UI/UIIconButton';
import UIOverlayCard, { OVERLAY_CLOSE_TYPES } from '../../../../UI/UIOverlayCard';
import UIAvailableOptions from '../../../../UI/UIAvailableOptions';
import UIProperty from '../../../../UI/UIProperty';
import { getAddressFromServiceLocation } from '../../../../../utils/Helpers/ServiceHelper';
import { TYPES } from '../../../../UI/UIProperty/config';
import { UI_LABELED_INPUT_DATE_STATIC_TYPE, UI_LABELED_INPUT_LINK_TYPE, UI_LABELED_INPUT_STATIC_TYPE } from '../../../../UI/UILabeledInput/config';
import { UI_ICON_BUTTON_CANCEL_TYPE, UI_ICON_BUTTON_FILL_TYPE, UI_ICON_BUTTON_OUTLINE_TYPE, UI_ICON_BUTTON_WHITE_TYPE } from '../../../../UI/UIIconButton/config';
import UIListInputRow from '../../../../UI/UIListInputRow';
import { toast } from 'react-toastify';
import { getSignedInUserSelector } from '../../../../../redux/selectors/CurrentUserSelectors';
import { getScreenTypeSelector } from '../../../../../redux/selectors/ControlSelectors';
import { useApolloClient } from 'react-apollo';

const TableAvailablePhotographer = ({ updateTable, data, pagination }: TableAvailablePhotographerProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isDecliningSR, setIsDecliningSR] = useState<boolean>(false);
	const [selectedService, setSelectedService] = useState<ServiceType>();
	const [serviceToDeclineRequest, setServiceToDeclineRequest] = useState<ServiceType>();
	const [showAcceptServiceModal, setShowAcceptServiceModal] = useState<boolean>(false);
	const [showSuggestModalService, setShowSuggestModalService] = useState<ServiceType>();

	const signedInUser = useSelector(getSignedInUserSelector);
	const screenType = useSelector(getScreenTypeSelector);

	const client = useApolloClient();

	const declineServiceRequest = async () => {
		setIsDecliningSR(true);
		const response = await client
			.mutate({
				mutation: Queries.UPDATE_SERVICE,
				variables: { serviceId: serviceToDeclineRequest?.id, preferredPhotographers: [], status: STATUS_FINDING_TECHNICIAN }
			})
			.catch(() => setIsDecliningSR(false));
		const { updateService: updatedService } = response['data'];
		if (updatedService) {
			await updateTable();
			setIsDecliningSR(false);
			setSelectedService(undefined);
			setServiceToDeclineRequest(undefined);
			toast.success('Successfully declined service request!');
		}
	};

	const acceptService = async () => {
		setIsLoading(true);

		const response = await client
			.mutate({
				mutation: Queries.UPDATE_SERVICE,
				variables: {
					serviceId: selectedService?.id,
					photographer: signedInUser.id,
					status: STATUS_TECHNICIAN_UPLOAD
				}
			})
			.catch((error) => {
				toast.error(error.message);
			});
		const { updateService } = response['data'];
		if (updateService) {
			await updateTable();
			setIsLoading(false);
			setSelectedService(undefined);
			setShowAcceptServiceModal(false);
			toast.success('Successfully accepted photoshoot!');
		}
	};

	const renderScheduleRequestButton = (service) => {
		const { isFlexBooking } = service;
		let hasPendingRequest = service.scheduleRequests.filter((scheduleRequest) => scheduleRequest?.photographer?.id === signedInUser.id).length > 0;

		if (!isFlexBooking) {
			return <p className='tableAP--notFlex'>Change requests not yet available</p>;
		} else if (hasPendingRequest) {
			return (
				<UIIconButton
					isDisabled={true}
					className='tableAP__suggest'
					type={UI_ICON_BUTTON_FILL_TYPE}
					text={'request already sent'}
					disabledErrorMessage='schedule request(s) already sent for service'
				/>
			);
		}
		return (
			<UIIconButton
				className='tableAP__suggest'
				type={UI_ICON_BUTTON_OUTLINE_TYPE}
				text={'suggest a new time'}
				icon={HistoryWhite}
				iconPadding={2}
				onClick={() => setShowSuggestModalService(service)}
			/>
		);
	};

	const isDesktop = screenType === SCREEN_TYPE.desktop;

	const renderService = (service) => {
		let timezone = service.region ? service.region.timezone : guessCurrentTimezone();

		let serviceDate = moment(service.serviceDate).tz(timezone).format('MMM Do YYYY');
		let serviceTime = moment(service.serviceDate).tz(timezone).format('h:mm a z');
		let hasPendingRequest = service.scheduleRequests?.filter((scheduleRequest) => scheduleRequest?.photographer?.id === signedInUser.id).length > 0;

		const technicianPay = getPhotographerPayFromOptions(service, signedInUser);

		return (
			<div
				className='service-table-row'
				style={service.status === STATUS_FINDING_PREFERRED_TECHNICIAN ? { backgroundColor: 'var(--mint-primary-32)' } : {}}
				key={service.id}
				onClick={() => setSelectedService(service)}>
				<div className='tableAP__datetime'>
					<p className='tableAP__datetime--date'>{serviceDate}</p>
					<p className='tableAP__datetime--time'>{serviceTime}</p>
				</div>
				{parseFloat(technicianPay) > 0 && (
					<div className='tableAP__pay'>
						<p className='tableAP__pay--text'>${technicianPay}</p>
					</div>
				)}
				<div className='tableAP__options'>
					{service.bookedOptions.map((bookedOption) => {
						return (
							<p key={bookedOption.id} className='tableAP__options--text'>
								{bookedOption.name}
							</p>
						);
					})}
				</div>
				<div className='tableAP__address'>
					<p className='tableAP__address--text'>{service.property ? service.property.address : 'Error'}</p>
					<p className='tableAP__address--alias'>{service.property ? service.property.alias : 'Error'}</p>
				</div>
				<div className='tableAP__request'>
					{hasPendingRequest ? <div className='tableAP__pending'>you sent a schedule request</div> : null}
					{service.status === STATUS_FINDING_PREFERRED_TECHNICIAN && (
						<div className='tableAP__pending' style={{ borderColor: 'var(--mint-primary)', color: 'var(--mint-primary)' }}>
							you've been requested
						</div>
					)}
				</div>
				<div className='tableAP__client'>
					<img alt={''} src={service.client.image} className='tableAP__client-image' />
					<p>{service.client.name}</p>
				</div>
			</div>
		);
	};

	const renderServiceMobile = (service) => {
		let hasPendingRequest = service.scheduleRequests?.filter((scheduleRequest) => scheduleRequest?.photographer?.id === signedInUser.id).length > 0;
		let serviceDate = moment(service.serviceDate)
			.tz(service.region ? service.region.timezone : guessCurrentTimezone())
			.format('dddd, MMMM Do YYYY [at] h:mm a z');
		return (
			<div className='tableAP__card' key={service.id} onClick={() => setSelectedService(service)}>
				{service.status === STATUS_FINDING_PREFERRED_TECHNICIAN && <p className='tableAP--request-mobile'>You have been requested by the client!</p>}
				<p className='tableAP__addressMobile'>{getAddressFromServiceLocation(service.property)}</p>
				<p className='tableAP__heading' style={{ marginTop: 24 }}>
					date and time
				</p>
				<p className='tableAP__text'>{serviceDate}</p>
				{!signedInUser.isInternalTech && (
					<p className='tableAP__heading' style={{ marginTop: 24 }}>
						service pay
					</p>
				)}
				{!signedInUser.isInternalTech && <p className='tableAP__text'>${getPhotographerPayFromOptions(service, signedInUser)}</p>}
				<div className='tableAP__client' style={{ marginTop: 24 }}>
					<img alt={''} src={service.client.image} className='tableAP__client-image' />
					<p>{service.client.name}</p>
				</div>
				{hasPendingRequest ? (
					<div className='tableAP__pending' style={{ marginTop: 24 }}>
						you sent a schedule request
					</div>
				) : null}
			</div>
		);
	};

	const renderSuggestModal = () => {
		const hideModal = () => setShowSuggestModalService(undefined);
		if (!showSuggestModalService) return null;

		return (
			<UIModal condition={!!showSuggestModalService}>
				<TechnicianSuggestTimeChangeModal
					service={showSuggestModalService}
					onFinish={async () => {
						await updateTable();
						setSelectedService(undefined);
					}}
					hideModal={hideModal}
				/>
			</UIModal>
		);
	};

	const renderDeclineRequestModal = () => {
		const hideModal = () => setServiceToDeclineRequest(undefined);

		return (
			<UIModal condition={!!serviceToDeclineRequest}>
				<UIOverlayCard
					hideModal={hideModal}
					title={'Decline request'}
					closeType={OVERLAY_CLOSE_TYPES.back}
					description={'If you decline request it will be automatically offered to other technicians.'}>
					<div className='declineOverlayButtons'>
						<UIIconButton dark type={UI_ICON_BUTTON_CANCEL_TYPE} isLoading={isDecliningSR} text={'decline'} onClick={declineServiceRequest} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderAcceptServiceModal = () => {
		const hideModal = () => setShowAcceptServiceModal(false);

		return (
			<UIModal condition={showAcceptServiceModal}>
				<UIOverlayCard hideModal={hideModal} title={'Confirm: Accept service?'} closeType={OVERLAY_CLOSE_TYPES.back}>
					<UIListInputRow
						type={UI_LABELED_INPUT_DATE_STATIC_TYPE}
						timezone={selectedService?.region.timezone ?? ''}
						value={selectedService?.serviceDate}
						label={'SHOOT DATE'}
					/>
					<UIListInputRow
						type={UI_LABELED_INPUT_LINK_TYPE}
						linkUrl={`https://maps.google.com/?q=${selectedService?.property.latitude},${selectedService?.property.longitude}`}
						value={selectedService ? getAddressFromServiceLocation(selectedService.property) : ''}
						label={'SHOOT ADDRESS'}
					/>
					{!signedInUser.isInternalTech && (
						<UIListInputRow
							type={UI_LABELED_INPUT_STATIC_TYPE}
							value={'$' + getPhotographerPayFromOptions(selectedService, signedInUser)}
							label={'YOUR PAY'}
						/>
					)}
					<UIListInputRow key={'requirements'} type={'static'} label={'OPTIONS'} value={getPackageString(selectedService?.bookedOptions, false, true, true)} />
					<div className='general-buttons-container'>
						<UIIconButton dark type={UI_ICON_BUTTON_FILL_TYPE} isLoading={isLoading} text={'accept'} onClick={acceptService} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderLearnMoreModal = () => {
		if (!selectedService) return;

		let options = selectedService.bookedOptions;

		const hideModal = () => setSelectedService(undefined);

		return (
			<UIModal condition={!!selectedService}>
				<UIOverlayCard hideModal={hideModal} title={'Accept service?'}>
					<div className='moreInfoModal'>
						{selectedService.property && (
							<>
								<p className='moreInfoModal__options--heading'>Location</p>
								<UIProperty property={selectedService.property} type={TYPES.map} />
							</>
						)}
						{selectedService.status === STATUS_FINDING_PREFERRED_TECHNICIAN && (
							<>
								<p className='moreInfoModal__options--heading' style={{ marginTop: 12 }}>
									Agent
								</p>
								<UIUser showPhoneNumber user={selectedService.agent} />
							</>
						)}
						{!signedInUser.isInternalTech && options && options.length > 0 && (
							<div className='moreInfoModal__options'>
								<p className='moreInfoModal__options--heading'>Options & pay</p>

								<div className='moreInfoModal__options--list'>
									<UIAvailableOptions bookedOptions={options} showPayPerOption />
								</div>

								<p style={{ marginTop: 12, fontSize: '14pt', color: 'var(--mint-primary)' }}>
									Total Pay: ${getPhotographerPayFromOptions(selectedService, signedInUser)}
								</p>
							</div>
						)}
						<div className='moreInfoModal__buttons' style={{ marginBottom: 12 }}>
							<UIIconButton
								dark
								text='accept'
								className='tableAP__accept'
								type={UI_ICON_BUTTON_WHITE_TYPE}
								iconPadding={4}
								onClick={() => setShowAcceptServiceModal(true)}
							/>
							{selectedService.status === STATUS_FINDING_PREFERRED_TECHNICIAN && (
								<UIIconButton
									dark
									type={UI_ICON_BUTTON_CANCEL_TYPE}
									text={'decline'}
									style={{ margin: '0 12px 0 4px' }}
									onClick={() => {
										setServiceToDeclineRequest(selectedService);
									}}
								/>
							)}
							{renderScheduleRequestButton(selectedService)}
						</div>
					</div>
					{renderAcceptServiceModal()}
					{renderSuggestModal()}
					{renderDeclineRequestModal()}
				</UIOverlayCard>
			</UIModal>
		);
	};

	return (
		<div className='service-table-card-container fade-in-div' style={{ overflowX: 'auto' }}>
			{renderLearnMoreModal()}
			{data.map((service) => (isDesktop ? renderService(service) : renderServiceMobile(service)))}
			{pagination}
		</div>
	);
};

export default TableAvailablePhotographer;
