export const removeAdditionalTitle = 'Remove additional option?';
export const errorAddingAdditional = 'Error adding additional option! ';
export const errorRemovingAdditional = 'Error removing additional option! ';
export const assignButton = 'assign';
export const addButton = 'add';
export const assignAdditionalTitle = 'Assign an additional option';
export const searchRegionLabel = 'SEARCH OPTIONS BY REGION';
export const searchRegionPlaceholder = 'Select a region...';
export const selectOptionLabel = 'SELECT OPTION';
export const selectOptionPlaceholder = 'Select an option...';
export const additionalOptions = 'additional options:';
export const noAdditionalOptions = 'No additional options yet ask an admin!';
export const noAdditionalOptionsWarning =
	'This option has no additional options yet. When you add one they will only appear to your client if they have also selected this option.';
export const deactivate = 'Deactivate';
export const activate = 'Activate';
export const hoverAdd = 'add additional option';
