import React, { useRef } from 'react';
import * as S from './styles';
import { CHILD_TYPE, HEADER_TYPE } from './config';
import { UIListInputRowProps } from './types';
import { UI_LABELED_INPUT_BUTTON_TYPE } from '../UILabeledInput/config';

export default function UIListInputRow(props: UIListInputRowProps) {
	const {
		label,
		type,
		children,
		item,
		listInputStyle,
		rightItems,
		placeholder,
		description,
		onChangeValue,
		onClick,
		savedValue,
		style,
		className
	}: UIListInputRowProps = props;
	const inputRef = useRef<HTMLInputElement>();

	const onClickInput = (e) => {
		if (onClick && type !== UI_LABELED_INPUT_BUTTON_TYPE) {
			onClick(e);
		}
		inputRef?.current?.focus();
	};

	const renderHeaderType = () => {
		return (
			<S.div_UIListInputRowHeaderContainer className={className} onClick={onClick}>
				<S.div_UIListInputRowHeaderItems>
					{label && <S.p_UIListInputRowLabel style={style}>{label}</S.p_UIListInputRowLabel>}
					{rightItems}
				</S.div_UIListInputRowHeaderItems>
				<S.p_UIListInputRowHeaderDescription>{description}</S.p_UIListInputRowHeaderDescription>
				{item}
			</S.div_UIListInputRowHeaderContainer>
		);
	};

	const renderChildType = () => {
		return (
			<S.div_UIListInputRowContainer className={className} onClick={onClick} style={listInputStyle}>
				<S.div_UIListInputRowHeaderItems>
					{label && <S.p_UIListInputRowLabel>{label}</S.p_UIListInputRowLabel>}
					{rightItems}
				</S.div_UIListInputRowHeaderItems>
				<S.p_UIListInputRowHeaderDescription>{description}</S.p_UIListInputRowHeaderDescription>
				{children}
			</S.div_UIListInputRowContainer>
		);
	};

	const renderDefaultType = () => {
		return (
			<S.div_UIListInputRowContainer className={className} style={listInputStyle} onClick={onClickInput}>
				{label && <S.p_UIListInputRowLabel>{label}</S.p_UIListInputRowLabel>}
				<S.input_UIListInputRowInput
					{...props}
					label={undefined}
					onClick={onClick}
					savedValue={savedValue}
					description={description}
					onChangeValue={onChangeValue}
					placeholder={placeholder}
					containerClassName='div_UIListInputRowContainer--wrapper'
					inputRef={inputRef}
					labelClassName='p_UIListInputRowLabel'
				/>
				{children}
			</S.div_UIListInputRowContainer>
		);
	};

	switch (type) {
		case HEADER_TYPE:
			return renderHeaderType();
		case CHILD_TYPE:
			return renderChildType();
		default:
			return renderDefaultType();
	}
}
