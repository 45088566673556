import React, { Component } from 'react';
import './table-editing-company.scss';
import './table-editing-company-tablet.scss';
import './table-editing-company-desktop.scss';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../../../utils/Helpers/AppHelper';

class TableEditingCompany extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	//Mark: Render functions
	render() {
		const { data, pagination } = this.props;
		return (
			<div className='tableEditingComp' key={this.props.currentUser.user}>
				<div className='tableEditingComp__rows fade-in-div '>
					{data.map((editingCompany) => {
						return (
							<div className='tableEditingComp__row' key={editingCompany.id} onClick={() => this.props.onSelect(editingCompany)}>
								<div className='tableEditingComp__row--item'>
									<p className='tableEditingComp__row--item--name'>{editingCompany.name}</p>
								</div>
								<div className='tableEditingComp__row--item'>
									<p>{editingCompany.email}</p>
								</div>
								{editingCompany.phoneNumber && (
									<div className='tableEditingComp__row--item'>
										<p>
											<NumberFormat displayType={'text'} value={editingCompany.phoneNumber} format='(###) ###-####' mask='_' />
										</p>
									</div>
								)}
							</div>
						);
					})}
				</div>
				{pagination}
			</div>
		);
	}
}

export default connect(commonMapStateToProps, commonMapDispatchToProps)(TableEditingCompany);
