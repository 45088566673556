import React from 'react';
import './additional-info.scss';
import UILabeledInput from '../../../UILabeledInput';
import QuantitySelect from '../../../QuantitySelect';
import { OPTION_ADDITIONAL_INFO_TYPES } from '../../../../../utils/Config/Constants';
import { convertCents } from '../../../../../utils/Helpers/StringHelpers';
import { UI_LABELED_INPUT_TEXT_FIELD } from '../../../UILabeledInput/config';
import { getTotalPriceFromBookableOption } from '../../helpers';
import * as strings from './strings';

export default function AdditionalInfo({ bookableOption, minQuantity, updateSelectedOption }: AdditionalInfoProps) {
	if (!bookableOption.option.requiredInfo) return null;

	const changeOptionRequiredInfo = (requiredInfoAnswer) => {
		updateSelectedOption({ ...bookableOption, requiredInfoAnswer });
	};

	const getPlaceholder = () => {
		const { option } = bookableOption;
		const { requiredInfoPrompt } = option;
		if (requiredInfoPrompt && requiredInfoPrompt.trim() !== '') {
			return requiredInfoPrompt;
		}
		return strings.defaultNotes;
	};

	const renderBookableOptionByRequiredInfoType = () => {
		switch (bookableOption.option.requiredInfo) {
			case OPTION_ADDITIONAL_INFO_TYPES.text:
				return (
					<UILabeledInput
						containerClassName='additionalInfo--text-input'
						onChangeValue={changeOptionRequiredInfo}
						value={bookableOption.requiredInfoAnswer}
						type={UI_LABELED_INPUT_TEXT_FIELD}
						placeholder={getPlaceholder()}
					/>
				);
			case OPTION_ADDITIONAL_INFO_TYPES.quantity:
				return (
					<div style={{ marginTop: 12 }}>
						<QuantitySelect minQuantity={minQuantity} updateOption={updateSelectedOption} bookableOption={bookableOption} />
						<p className='additionalInfo--total'>Total: {convertCents(getTotalPriceFromBookableOption(bookableOption), false)}</p>
					</div>
				);
			case OPTION_ADDITIONAL_INFO_TYPES.quantityAndNotes:
				return (
					<div style={{ marginTop: 12 }}>
						<QuantitySelect minQuantity={minQuantity} updateOption={updateSelectedOption} bookableOption={bookableOption} />
						<p className='additionalInfo--total'>Total: {convertCents(getTotalPriceFromBookableOption(bookableOption), false)}</p>
						<UILabeledInput
							containerClassName='additionalInfo--text-input'
							onChangeValue={changeOptionRequiredInfo}
							value={bookableOption.requiredInfoAnswer}
							type={UI_LABELED_INPUT_TEXT_FIELD}
							placeholder={getPlaceholder()}
						/>
					</div>
				);
			default:
				return null;
		}
	};

	return <div className='additionalInfo'>{renderBookableOptionByRequiredInfoType()}</div>;
}
