import moment from 'moment-timezone';
import { guessCurrentTimezone } from '../../../../utils/Helpers/AppHelper';

export const getServiceDate = (service) => {
	return service.serviceDate
		? moment(new Date(service.serviceDate))
				.tz(service.region?.timezone ?? service.property?.timezone ?? service.timezone ?? guessCurrentTimezone())
				.format('MMM Do YYYY')
		: 'Invalid date';
};

export const getServiceTime = (service) => {
	return service.serviceDate
		? moment(service.serviceDate)
				.tz(service.region?.timezone ?? service.property?.timezone ?? service.timezone ?? guessCurrentTimezone())
				.format('h:mm a z')
		: '';
};
