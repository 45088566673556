// Created by carsonaberle at 2/15/22
import React from 'react';
import * as S from './styles';

export default function MarketingTabBar({ darkMode, tabs, selectedTabIndex, setSelectedTabIndex, maxWidth }: MarketingTabBarProps) {
	const renderItem = (tab, index) => {
		const isSelected = index === selectedTabIndex;

		const StyledContainer = isSelected ? S.MSTabBarItemSelected : S.MSTabBarItem;
		return (
			<StyledContainer
				darkMode={darkMode}
				id='marketing-tab-bar'
				key={tab}
				maxWidth={maxWidth}
				style={{ marginRight: index + 1 === tabs.length ? 0 : 36 }}
				onClick={() => setSelectedTabIndex(index)}>
				<S.MSTabBarItemText darkMode={darkMode}>{tab}</S.MSTabBarItemText>
			</StyledContainer>
		);
	};

	return <S.MSTabBarContainer>{tabs.map(renderItem)}</S.MSTabBarContainer>;
}
