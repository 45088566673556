import { FloorplansIcon, InfoIcon, PhotosTab, VideoIconWhite, VirtualTourTab } from '../../../assets/icons';

export let VIRTUAL_TOUR_PHOTOS_TAB: VirtualTourSection = { value: 'photos', label: 'photos', searchParam: 'photos', img: PhotosTab, path: 'photos' };
export let VIRTUAL_TOUR_FLOOR_PLANS_TAB: VirtualTourSection = {
	value: 'floorPlans',
	label: 'floor plans',
	searchParam: 'floor-plan',
	img: FloorplansIcon,
	path: 'floor-plans'
};
export let VIRTUAL_TOUR_VIDEOS_TAB: VirtualTourSection = { value: 'video', label: 'video', searchParam: 'video', img: VideoIconWhite, path: 'video' };
export let VIRTUAL_TOUR_MATTERPORT_TAB: VirtualTourSection = {
	value: 'matterport',
	label: 'matterport',
	searchParam: 'matterport',
	img: VirtualTourTab,
	path: 'matterport'
};
export let VIRTUAL_TOUR_SLIDESHOW_TAB: VirtualTourSection = {
	value: 'slideshow',
	label: 'slideshow',
	searchParam: 'matterport',
	img: VirtualTourTab,
	separator: 'right',
	path: 'slideshow'
};
export let VIRTUAL_TOUR_INCOMPLETE_TAB: VirtualTourSection = {
	value: 'incomplete',
	label: 'Listing incomplete',
	searchParam: 'incomplete',
	img: InfoIcon,
	path: 'incomplete'
};
