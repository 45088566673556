import React from 'react';
import './slide-in-out-transition.scss';
import { CSSTransition } from 'react-transition-group';

function SlideInOutTransition({ transitionIn, direction, children }: SlideInOutTransitionProps) {
	return (
		<CSSTransition appear={true} in={transitionIn} timeout={transitionIn ? 0 : 600} classNames={`slideInOutTransition--${direction}`}>
			{children}
		</CSSTransition>
	);
}

export default SlideInOutTransition;
