import gql from 'graphql-tag';

export const GET_SERVICE_WITH_TECHNICIAN = gql`
	query GetServiceWithTechnician($id: String!) {
		service(id: $id) {
			id
			status
			type
			address
			client {
				id
			}
			photographer {
				id
			}
			preferredPhotographers {
				id
				name
				phoneNumber
				email
				image
				isCertified
			}
			region {
				id
				name
				timezone
			}
			bookedOptions {
				id
				photographerCertificatesRequired
			}
			serviceDate
		}
	}
`;

export const GET_PL_TECHNICIAN = gql`
	query GetServiceTechnician($id: String!) {
		user(id: $id) {
			id
			name
			email
			phoneNumber
			image
			editingCompany {
				id
			}
		}
	}
`;
