import './mobile.scss';

export const bookingMapStyles = {
	containerStyle: {
		height: '100vh',
		width: '100%',
		flex: 1,
		display: 'flex',
		backgroundColor: '#EEE9E2',
		borderRadius: 0,
		margin: 0,
		cursor: 'crosshair'
	}
};
