// Created by jarongallo at 7/2/21
import React, { useEffect, useState } from 'react';
import * as strings from './strings';
import './styles';
import * as S from './styles';

import { FILE_STATUS_IN_PROGRESS, FILE_TYPE_RAW_IMAGE, USER_ROLE_EDITING_MANAGER, USER_ROLE_EDITOR } from '../../../../../../../utils/Config/Constants';
import PLUpload from '../../../../../../../components/PL/PLUpload';
import { LocaleTranslations } from '@uploadcare/react-widget';
import { PLServiceFilesQueryParams } from '../../../types';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_PL_SERVICE_RAW_FILES, GET_PL_SERVICE_RAW_FILES_SERVICE } from '../../PLServiceRawImages/queries';
import UIMultiFileSelect from '../../../../../../../components/UI/UIMultiUploadFileSelect/UIMultiFileSelect';
import { TYPES } from '../../../../../../../components/UI/UIMultiUploadFileSelect/config';
import UILoadingPage from '../../../../../../../components/UI/UILoadingPage/UILoadingPage';
import { useSelector } from 'react-redux';
import { getSignedInUserSelector } from '../../../../../../../redux/selectors/CurrentUserSelectors';

type PLServiceExtraRawImagesProps = {
	serviceId: string;
	localeTranslations?: LocaleTranslations;
};

export default function PLServiceExtraRawImages({ serviceId, localeTranslations }: PLServiceExtraRawImagesProps) {
	const signedInUser = useSelector(getSignedInUserSelector);

	const [rawFiles, setRawFiles] = useState<FileType[]>();
	const [service, setService] = useState<ServiceType>();
	const [getService, { data: serviceData }] = useLazyQuery(GET_PL_SERVICE_RAW_FILES_SERVICE, { variables: { serviceId } });

	let variables: PLServiceFilesQueryParams = {
		limit: 1000,
		query: { serviceId: serviceId, type: FILE_TYPE_RAW_IMAGE, status: FILE_STATUS_IN_PROGRESS },
		isActive: true,
		order: [['index', 'ASC']]
	};

	useEffect(() => {
		if (serviceData) {
			setService(serviceData['service']);
		}
	}, [serviceData]);

	const [getRawFiles, { data: rawFilesData }] = useLazyQuery(GET_PL_SERVICE_RAW_FILES, { variables });

	useEffect(() => {
		(async () => {
			await getService();
			await getRawFiles();
		})();
	}, []);

	useEffect(() => {
		if (rawFilesData) {
			setRawFiles(rawFilesData['files']);
		}
	}, [rawFilesData]);

	if (!rawFiles) return <UILoadingPage />;

	const renderRawUpload = () => {
		return (
			<PLUpload
				multiple={true}
				fileType={FILE_TYPE_RAW_IMAGE}
				postData={{ serviceId }}
				onFileUploadComplete={getRawFiles}
				uploadStatus={FILE_STATUS_IN_PROGRESS}
				localeTranslations={localeTranslations}
			/>
		);
	};

	const renderContent = () => {
		return (
			<UIMultiFileSelect
				title={strings.fileSelectTitle}
				emptySetTitle={strings.emptySetTitle}
				emptySetDescription={strings.emptySetDescription}
				data={rawFiles}
				rightItem={renderRawUpload()}
				serviceId={serviceId}
				updateService={getRawFiles}
				downloadType={TYPES.EXTRA_RAW_TYPE}
			/>
		);
	};

	return <div className='plServiceExtraRaws'>{renderContent()}</div>;
}
