import * as types from './types';

export const setSignedInUser = (user) => {
	return { type: types.SET_SIGNED_IN_USER, user };
};
export const setSuperUser = (superUser) => {
	return { type: types.SET_SUPER_USER, superUser };
};
export const setSelectedRegion = (selectedRegion) => {
	return { type: types.SET_SELECTED_REGION, selectedRegion };
};
export const showLogin = (displayLogin, onLoginComplete, preFilledEmail) => {
	return { type: types.SHOW_LOGIN, displayLogin, onLoginComplete, preFilledEmail };
};
export const showSignup = (displaySignup, onSignupComplete) => {
	return { type: types.SHOW_SIGNUP, displaySignup, onSignupComplete };
};
export const showOption = (optionId) => {
	return { type: types.SHOW_OPTION, optionId };
};
export const setSuperUserReturnRoute = (superUserReturnRoute) => {
	return { type: types.SET_SUPER_USER_RETURN_ROUTE, superUserReturnRoute };
};
export const currentUserActions = {
	setSuperUser,
	setSignedInUser,
	setSelectedRegion,
	showLogin,
	showSignup,
	showOption,
	setSuperUserReturnRoute
};
