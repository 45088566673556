import React, { Component } from 'react';
import { connect } from 'react-redux';
import './table-option.scss';
import { convertCents } from '../../../../../utils/Helpers/StringHelpers';
import UILabeledInput from '../../../../UI/UILabeledInput';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../../../utils/Helpers/AppHelper';
import { UI_LABELED_INPUT_STATIC_TYPE } from '../../../../UI/UILabeledInput/config';

class TableOption extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	//Mark: Render functions
	render() {
		const { data, pagination } = this.props;
		let mobile = this.props.control.screenType;
		if (mobile === 'mobile' || mobile === 'tablet') {
			return (
				<div className='optionTable fade-in-div'>
					<div className='optionTable__rows'>
						{data.map((option) => {
							return (
								<div className='optionTable__container' key={option.id} onClick={() => this.props.onSelect(option)}>
									<div className='optionTable__header'>
										<p className='optionTable__container--name'>{option.name}</p>
										<p className='optionTable__container--price'>{convertCents(option.price)}</p>
									</div>
									<UILabeledInput
										label={'regions'}
										type={UI_LABELED_INPUT_STATIC_TYPE}
										value={option.regions.map((region) => region.name).join(', ')}
										className='optionTable__container--pay'
									/>
									<UILabeledInput label={'type'} type={UI_LABELED_INPUT_STATIC_TYPE} value={option.type} className='optionTable__container--type' />
								</div>
							);
						})}
					</div>
					{pagination}
				</div>
			);
		} else
			return (
				<div className='service-table-card-container '>
					<div className='tableOption--rows'>
						{data.map((option) => {
							return (
								<div className='tableOption__row' key={option.id} onClick={() => this.props.onSelect(option)}>
									{option.coverFile ? (
										<img alt={'example'} className='tableOption__row--item--cover' src={option.coverFile.thumbnailUrl} />
									) : (
										<div className='tableOption__row--item--cover-empty'>
											<p className='tableOption__row--item--cover-empty--text'>no cover</p>
										</div>
									)}
									<div className='tableOption__row--item'>
										<p className='tableOption__row--item--name'>{option.name}</p>
										<p className='tableOption__row--item--price'>${option.price / 100}</p>
									</div>
									<p className='tableOption__row--item--package-type'>{option.packageType}</p>
								</div>
							);
						})}
					</div>
					{pagination}
				</div>
			);
	}
}

export default connect(commonMapStateToProps, commonMapDispatchToProps)(TableOption);
