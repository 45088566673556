// Created by jarongallo at 6/17/21
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BellIcon, MutedIcon } from '../../../../../../assets/icons';
import UIIcon from '../../../../../../components/UI/UIIcon';
import './styles';
import * as strings from './strings';
import { getIsUserAdminSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_SERVICE_WITH_MUTE, UPDATE_SERVICE_MUTE } from './queries';
import { toast } from 'react-toastify';

export default function PLServiceMute({ serviceId }: PLServiceMuteProps) {
	let isUserAdmin = useSelector(getIsUserAdminSelector);
	const [updateMute] = useMutation(UPDATE_SERVICE_MUTE);

	const [service, setService] = useState<ServiceType>();
	const [mute, setMute] = useState<boolean>();
	const [isMuting, setIsMuting] = useState<boolean>();

	const [getServiceWithMute, { data: serviceData }] = useLazyQuery(GET_SERVICE_WITH_MUTE, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getServiceWithMute();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setService(serviceData['service']);
				setMute(serviceData['service'][strings.muteNotifications]);
			}
		})();
	}, [serviceData]);

	const shouldRenderComponentForService = () => {
		return isUserAdmin;
	};
	if (!shouldRenderComponentForService()) {
		return null;
	}
	if (!service) return null;
	const setMuteValue = async () => {
		setIsMuting(true);
		let variables = {
			serviceId: serviceId,
			muteNotifications: !mute
		};
		const response = await updateMute({ variables });
		const { updateService } = response['data'];

		if (updateService) {
			setMute(!mute);
			setIsMuting(false);
			await getServiceWithMute();
			toast.success(strings.muteSuccess + mute);
		} else {
			toast.error(strings.muteError);
			setIsMuting(false);
		}
	};

	return (
		<div className='plServiceMute' onClick={setMuteValue}>
			<div className='plServiceMute__iconContainer'>
				<UIIcon isLoading={isMuting} iconSrc={mute ? MutedIcon.white : BellIcon.white} alt={strings.muteString} className='plServiceMute__iconContainer-icon' />
				<p>{mute ? strings.unmuteString : strings.muteString}</p>
			</div>
		</div>
	);
}
