// Created by carsonaberle at 6/13/21
import React, { CSSProperties } from 'react';
import './styles';
import { useSelector } from 'react-redux';
import PLImage from '../../../../PLImage';
import {
	EDIT_TYPE_SERVICE,
	SCREEN_TYPE,
	STATUS_PROOFING,
	USER_ROLE_ADMIN,
	USER_ROLE_AGENT,
	USER_ROLE_EDITING_MANAGER,
	USER_ROLE_EDITOR
} from '../../../../../../utils/Config/Constants';
import { EditPhotoIcon, IncompletePlaceholder, TechniciansIcon } from '../../../../../../assets/icons';
import { getFlagsForService } from '../helper';
import UIHover from '../../../../../UI/UIHover/UIHover';
import { isUserAdminOrRM } from '../../../../../../utils/Helpers/UserHelpers';
import { getReadableStatus } from '../../../../../../utils/Helpers/PaymentHelper/PaymentHelper';
import { getServiceDate, getServiceTime } from '../../../PLTableHelpers/PLTableHelpers';
import { noPropertyError } from './strings';
import { DEDUCTION_TYPE } from '../../../../../../utils/Helpers/PackageType/PackageType';
import { getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { useHistory, useLocation } from 'react-router-dom';
import { getScreenTypeSelector } from '../../../../../../redux/selectors/ControlSelectors';
import { getDimensionsToFitContainer } from '../../../../../UI/UIMultiImageSelect/config';
import { convertCents } from '../../../../../../utils/Helpers/StringHelpers';

export default function TableServiceRow({ service, hideAddress }: TableServiceRowProps) {
	const bookingUser = service.client;

	const signedInUser = useSelector(getSignedInUserSelector);
	const screenType = useSelector(getScreenTypeSelector);

	const history = useHistory();
	const location = useLocation();

	const onClick = (e) => {
		e.stopPropagation();
		onClickSidebarService(service);
	};

	const getStyle = (): CSSProperties => {
		if (!service.property) return {};
		if (!bookingUser) {
			return { border: 'var(--red-transparent) solid 1px' };
		}
		if (service.status === STATUS_PROOFING && signedInUser.role !== USER_ROLE_EDITING_MANAGER && signedInUser.role !== USER_ROLE_EDITOR) {
			return { backgroundColor: 'var(--red-transparent)' };
		}
		return {};
	};

	const onClickSidebarService = (service) => {
		if (location.search) {
			history.push(location.pathname + location.search + `&service=${service.id}`);
		} else {
			history.push(location.pathname + `?service=${service.id}`);
		}
	};

	const renderAddress = () => {
		if (hideAddress) return null;

		return (
			<div className='tableServiceRow__address' style={!service.property.streetAddress ? { display: 'none' } : {}}>
				{service.property.streetAddress && <p className='tableServiceRow__address--text'>{`${service.property.streetAddress.replace('undefined', '')}`}</p>}
				<p className='tableServiceRow__address--addressLineTwo'>{`${service.property.city ?? ''}. ${service.property.state ?? ''} ${
					service.property.zipCode ?? ''
				}`}</p>
				{service.property.alias && <p className='tableServiceRow__address--alias'>{service.property.alias}</p>}
				{renderAdminClaimedBy(service.customerServiceAgent)}
			</div>
		);
	};

	const renderFlags = () => {
		const flags = getFlagsForService(service, signedInUser);

		return (
			<div className='tableService__flags'>
				{flags.map((flag) => (
					<UIHover key={flag.text} text={flag.text}>
						<div className={flag.className} />
					</UIHover>
				))}
			</div>
		);
	};

	const renderAdminClaimedBy = (admin) => {
		if (admin && isUserAdminOrRM()) {
			return (
				<div className='tableServiceRow__claimed'>
					<p className='tableServiceRow__claimed--name'>Claimed by {admin.name}</p>
				</div>
			);
		}
		return;
	};

	const renderStatusDateTime = () => {
		let serviceStatus = getReadableStatus(service.status);
		let serviceDate = getServiceDate(service);
		let serviceTime = getServiceTime(service);

		return (
			<div className='tableService__stage'>
				<p className='tableService__stage--text'>{serviceStatus}</p>
				<p className='tableService__datetime--date'>{serviceDate}</p>
				<p className='tableService__datetime--time'>{serviceTime}</p>
			</div>
		);
	};

	const renderCoverFile = () => {
		const { coverFile } = service;
		const filmstripDimensions = getDimensionsToFitContainer(coverFile, { width: 96, height: 96 });
		return (
			isUserAdminOrRM(signedInUser) && (
				<div className='tableService__coverFile'>
					<PLImage
						className='tableService__coverFile--image'
						imageStyle={{ maxHeight: '100%', overflow: 'hidden', objectFit: 'contain' }}
						width={filmstripDimensions.width}
						height={filmstripDimensions.height}
						borderRadius={3}
						src={coverFile?.thumbnailUrl ?? IncompletePlaceholder.default}
						alt={service.property?.address ?? noPropertyError}
					/>
				</div>
			)
		);
	};

	const renderServiceOptions = () => {
		if (!service.bookedOptions) return;

		const renderOption = (bookedOption) => {
			const quantityPart = bookedOption.quantity > 1 ? `${bookedOption.quantity} x ` : '';
			return `${quantityPart} ${bookedOption.name}`;
		};

		const renderPrice = () => {
			if (service.orderInformation) {
				return <p className='tableService__options--options-package'>{service.orderInformation?.name}</p>;
			} else if (service.stripeBookingTotal && service.stripeBookingTotal > 0) {
				return <p className='tableService__options--options-package'>{convertCents(service.stripeBookingTotal)}</p>;
			}
			return null;
		};

		const options =
			signedInUser.role !== USER_ROLE_AGENT
				? service.bookedOptions.map(renderOption)
				: service.bookedOptions.filter((option) => option.packageType !== DEDUCTION_TYPE.packageType).map(renderOption);

		return (
			<div className='tableService__options'>
				<div className='tableService__options--options'>
					{renderPrice()}
					{options.join(', ')}
				</div>
			</div>
		);
	};

	const renderIcon = () => {
		return <PLImage src={service.type === EDIT_TYPE_SERVICE ? EditPhotoIcon.white : TechniciansIcon} alt={'service type'} className='tableService--type' />;
	};

	const renderPhotographer = () => {
		const { photographer } = service;

		const shouldNotShowPhotographer = ![USER_ROLE_ADMIN, USER_ROLE_AGENT].includes(signedInUser.role) || !photographer;

		const onClickPhotographer = (e) => {
			e.stopPropagation();

			if (shouldNotShowPhotographer) return;

			history.push(`/photographer/${photographer.id}`);
		};

		const renderContent = () => {
			const inner = (
				<>
					<img style={{ minWidth: 32, minHeight: 32, maxWidth: 32, maxHeight: 32, borderRadius: 16 }} alt={''} src={photographer?.image} />
					<p className='tableServiceRow--right-photographer-name' style={{ marginLeft: 12, marginRight: 12 }}>
						{photographer?.name}
					</p>
				</>
			);
			return <div className='tableServiceRow--right-photographer'>{!shouldNotShowPhotographer && inner}</div>;
		};

		return (
			<div className='tableServiceRow--right' onClick={onClickPhotographer}>
				{renderContent()}
				{renderIcon()}
			</div>
		);
	};

	const renderContent = () => {
		if (!service.property) return null;
		return (
			<>
				<div className='tableServiceRow--top'>
					{renderFlags()}
					{renderCoverFile()}
					{renderStatusDateTime()}
				</div>
				{renderAddress()}
				<div className='tableServiceRow--bottom' style={!service.property.streetAddress && screenType === SCREEN_TYPE.tablet ? { display: 'flex' } : {}}>
					{renderServiceOptions()}
					{renderPhotographer()}
				</div>
			</>
		);
	};
	return (
		<div onClick={onClick} style={getStyle()} className='service-table-row' key={service.id}>
			{renderContent()}
		</div>
	);
}
