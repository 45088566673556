import React from 'react';
import * as S from './styles';
import { useLocation, useRouteMatch } from 'react-router-dom';

export default function UIMenuItem({ item, onSelectItem, isSelected, itemKey }: UIMenuItemProps) {
	const location = useLocation();
	const match = useRouteMatch();

	const getLinkAddress = () => {
		if (!location.search) {
			return `${match.url}?${item.searchParam}`;
		} else {
			return `${match.url}?${item.searchParam}`;
		}
	};

	let imgStyle;
	isSelected
		? (imgStyle = {
				opacity: '100%'
		  })
		: (imgStyle = { opacity: '80%' });

	const StyledMenuItem = isSelected ? S.UIMenuItemSelected : S.UIMenuItem;

	return (
		<StyledMenuItem replace={true} key={`isSelected ${item.label} ${itemKey}`} onClick={() => onSelectItem(item)} to={getLinkAddress()}>
			<S.UIMenuItemIconContainer>
				<S.UIMenuItemIconContainerIcon alt={'menu'} imageStyle={{ width: '100%', height: '100%' }} src={item.img} style={{ ...imgStyle }} />
			</S.UIMenuItemIconContainer>
			<p className={`uiMenuItem__label ${isSelected ? 'uiMenuItem__label-selected' : null}`}>{item.label}</p>
		</StyledMenuItem>
	);
}
