import gql from 'graphql-tag';

export const GET_SERVICE_ADMIN_RIBBON = gql`
	query GetServiceAdminRibbon($id: String!) {
		service(id: $id) {
			id
			type
			isAdminApproved
			status
			preferredPhotographers {
				id
				name
				email
				image
			}
			files {
				id
				status
				type
				index
				isActive
			}
			photographer {
				id
			}
			client {
				id
			}
			bookedOptions {
				id
				name
				price
				quantity
				option {
					finalFileCount
				}
			}
		}
	}
`;

export const GET_SERVICE_ADMIN_FILES = gql`
	query GetServiceAdminFiles($query: JSON!, $limit: Int!) {
		files(query: $query, limit: $limit) {
			id
			status
			type
			index
			isActive
		}
	}
`;
