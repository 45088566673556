import { DEPRECATED_STATUS_EDITOR_REDO, STATUS_LIVE, STATUS_STANDBY, TABLE_SERVICE_FLAGS } from '../../../../../utils/Config/Constants';
import { isUserAdminOrRM } from '../../../../../utils/Helpers/UserHelpers';

export const getFlagsForService = (service: ServiceType, signedInUser: UserType) => {
	let flags: { text: string; className: string }[] = [];

	// if (service.trainee !== null && [USER_ROLE_AGENT, USER_ROLE_EDITOR, USER_ROLE_EDITING_MANAGER].indexOf(signedInUser.role) === -1) {
	// 	flags.push(TABLE_SERVICE_FLAGS.training);
	// }

	if (service.status === DEPRECATED_STATUS_EDITOR_REDO) {
		flags.push(TABLE_SERVICE_FLAGS.editorRedo);
	}

	if (service.status === STATUS_STANDBY) {
		flags.push(TABLE_SERVICE_FLAGS.standby);
	}

	if (isUserAdminOrRM(signedInUser) && service.status === STATUS_LIVE && !service.isAdminApproved) {
		flags.push(TABLE_SERVICE_FLAGS.needsApproval);
	}

	return flags;
};
