import React, { useState } from 'react';
import * as S from './styles';
import VTFullscreen from '../VTFullscreen';

export default function VTPhotos({ files }: VTPhotosProps) {
	const [expandedFileIndex, setExpandedFileIndex] = useState<number>();

	if (!files) return null;

	return (
		<S.div_VTPhotosContainer>
			<S.div_VTPhotosList>
				{files.map((file, index) => {
					return (
						<S.div_VTPhotosItem key={file.id} onClick={() => setExpandedFileIndex(index)}>
							<S.img_VTPhotosItemImage
								taller={parseInt(file.height) > parseInt(file.width)}
								width={file.width}
								height={file.height}
								alt={`file-${index}`}
								src={file.webUrl}
							/>
						</S.div_VTPhotosItem>
					);
				})}
			</S.div_VTPhotosList>
			<VTFullscreen setExpandedFileIndex={setExpandedFileIndex} files={files} expandedFileIndex={expandedFileIndex} />
		</S.div_VTPhotosContainer>
	);
}
