import React, { useEffect, useState } from 'react';
import './styles';
import {
	areAddOnOptionsSelected,
	ENTRY_OPTIONS,
	getListingOptionId,
	getOptionsFromBookingConfigWithLighting,
	getRegionFromZip,
	WORKFLOW_COMPONENTS
} from './config';
import BookingLocation from './components/BookingLocation';
import BookingDateTime from './components/BookingDateTime';
import BookingEntry from './components/BookingEntry';
import BookingPay from './components/BookingPay';
import Queries from '../../../../utils/API/Queries';
import { DiscountSummer } from '../../../../utils/Helpers/DiscountCalculator/DiscountCalculator';
import BookingOptionsSelect from './components/BookingOptionsSelect';
import { OVERLAY_CLOSE_TYPES } from '../../../../components/UI/UIOverlayCard';
import BookingUpgrades from './components/BookingUpgrades';
import { ZendeskAPI } from 'react-zendesk';
import { LottieAnimations, PartnerIcon } from '../../../../assets/icons';
import Lottie from 'react-lottie';
import UIModal from '../../../../components/UI/UIModal';
import PartnerSelectModal from '../../../../components/modals/PartnerSelectModal';
import UIIcon from '../../../../components/UI/UIIcon';
import UIStepOverlayCard from '../../../../components/UI/UIStepOverlayCard';
import { Prompt } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getIsUserAdminSelector } from '../../../../redux/selectors/CurrentUserSelectors';
import { PLLottieProps } from '../IndividualService/FullscreenFiles/interfaces';
import { useApolloClient } from '@apollo/react-hooks';
import { BookingManagerProps, BookingWorkflow } from './types';
import { setBookingConfig } from '../../../../redux/store/booking/actions';
import { getBookingConfigSelector } from '../../../../redux/selectors/BookingSelectors';
import { getWorkflowFromUser } from './workflowConfig';
import * as strings from './strings';
import { setUpBookableOption } from '../EditOptions/config';

export default function BookingManager({ listing, bookingClientId, closeBooking }: BookingManagerProps) {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedWorkflow, setSelectedWorkflow] = useState<BookingWorkflow>();
	const [showPartnerSelectModal, setShowPartnerSelectModal] = useState<boolean>(false);
	const [bookingClient, setBookingClient] = useState<UserType>();

	const isUserAdmin = useSelector(getIsUserAdminSelector);
	const bookingConfig = useSelector(getBookingConfigSelector);

	const client = useApolloClient();
	const dispatch = useDispatch();

	let workflows = getWorkflowFromUser(bookingConfig);

	const updateBookingConfig = (key: string, value: any) => {
		dispatch(setBookingConfig({ ...bookingConfig, [key]: value }));
	};

	const getNextButtonTitle = () => {
		switch (selectedWorkflow) {
			case WORKFLOW_COMPONENTS.serviceOptionsSelect:
				if (!bookingConfig || bookingConfig.selectedOptions.length === 0) {
					return strings.dontNeedServices;
				}
				return strings.next;
			case WORKFLOW_COMPONENTS.upgrades:
				if (!areAddOnOptionsSelected(bookingConfig)) {
					return strings.dontNeedUpgrades;
				}
				return strings.next;
			default:
				return strings.next;
		}
	};

	const canGoToNextStep = (): boolean => {
		switch (selectedWorkflow) {
			case WORKFLOW_COMPONENTS.location:
				return Boolean(
					bookingConfig.serviceLocation?.streetAddress &&
						bookingConfig.serviceLocation?.city &&
						bookingConfig.serviceLocation?.state &&
						bookingConfig.serviceLocation?.zipCode
				);
			case WORKFLOW_COMPONENTS.serviceProvider:
				return true;
			case WORKFLOW_COMPONENTS.serviceOptionsSelect:
				return !(bookingConfig.listing && bookingConfig.selectedOptions.length === 0);

			case WORKFLOW_COMPONENTS.dateTime:
				return true;
			case WORKFLOW_COMPONENTS.entry:
				if (bookingConfig.entry?.type === ENTRY_OPTIONS.lockBox && !bookingConfig.entry?.lockBoxCode) {
					return false;
				}
				return Boolean(bookingConfig.entry?.type);
			case WORKFLOW_COMPONENTS.upgrades:
				//Don't allow double listing only booking
				return !(bookingConfig.listing && bookingConfig.selectedOptions.length === 0);
			default:
				return false;
		}
	};

	const queryBookingClient = async () => {
		const response = await client.query({
			query: Queries.GET_USER_QUERY,
			variables: { id: bookingClientId }
		});
		const { user: bookingClientResponse } = response['data'];
		if (bookingClientResponse) {
			await queryBookingClientPromos(bookingClientResponse);
		}
	};

	const queryListingOnlyOption = async () => {
		const response = await client.query({
			query: Queries.GET_OPTION,
			variables: { id: getListingOptionId(process.env.PL_ENVIRONMENT) }
		});
		let { option: listingOption } = response['data'];
		if (listingOption) {
			return setUpBookableOption(listingOption, bookingClient?.promos || []);
		}
		return;
	};

	const queryBookingClientPromos = async (bookingClient: UserType) => {
		const response = await client.query({
			query: Queries.GET_USER_PROMOS,
			variables: { userId: bookingClient.id, limit: 1000 }
		});
		const { promos } = response['data'];
		if (promos) {
			bookingClient.promos = promos;
			setBookingClient(bookingClient);
		}
	};

	const goToNextStep = () => {
		const selectedWorkflow = workflows[getWorkflowIndexFromSelectedWorkflow() + 1];

		setSelectedWorkflow(selectedWorkflow);
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	const getCardTitle = (currentWorkflow: BookingWorkflow | undefined) => {
		if (isLoading) return '';
		return currentWorkflow?.navTitle || strings.enterThePropertyLocation;
	};

	const getWorkflowIndexFromSelectedWorkflow = () => {
		if (selectedWorkflow) {
			return workflows.indexOf(selectedWorkflow);
		}
		return 0;
	};

	const goBack = () => {
		const nextSelectedWorkflowIndex = getWorkflowIndexFromSelectedWorkflow() - 1;
		const selectedWorkflow = workflows[nextSelectedWorkflowIndex];
		if (nextSelectedWorkflowIndex < 0 && closeBooking) {
			closeBooking();
		} else {
			setSelectedWorkflow(selectedWorkflow);
		}
	};

	useEffect(() => {
		(async () => {
			dispatch(setBookingConfig(bookingConfig));
			setSelectedWorkflow(workflows[0]);
			await queryBookingClient();
			let newBookingConfig = bookingConfig;
			if (listing) {
				newBookingConfig.region = await getRegionFromZip(bookingConfig.serviceLocation?.zipCode ?? '', client);
				newBookingConfig.serviceLocation = listing.property;
			}
			// newBookingConfig.optionsSelectedTab = optionsSelectedTab;
			newBookingConfig.listingOption = await queryListingOnlyOption();
			dispatch(setBookingConfig(newBookingConfig));
			setIsLoading(false);
		})();
	}, []);

	if (!bookingClient && selectedWorkflow !== WORKFLOW_COMPONENTS.location) return null;
	if (!bookingConfig) return null;

	const renderPartnerBooking = () => {
		if (!bookingConfig.region?.regionConfig?.coldwellPackages?.partners) return null;

		const partnerIcon = bookingConfig.selectedPartnerPackage ? PartnerIcon.mint : PartnerIcon.white;
		return (
			<div className={'bookingManager__partner'} onClick={() => setShowPartnerSelectModal(true)}>
				<UIIcon iconSrc={partnerIcon} className='bookingManager__partner--icon' alt={'partner'} />
				{renderPartnerSelectModal()}
			</div>
		);
	};

	const renderPartnerSelectModal = () => {
		const hideModal = () => setShowPartnerSelectModal(false);

		const update = (updatedData) => {
			dispatch(
				setBookingConfig({
					...bookingConfig,
					...updatedData
				})
			);
		};

		return (
			<UIModal condition={showPartnerSelectModal}>
				<PartnerSelectModal
					onSelectionComplete={hideModal}
					hideModal={hideModal}
					packages={bookingConfig.region?.regionConfig?.coldwellPackages}
					update={update}
					selectedPartnerPackage={bookingConfig.selectedPartnerPackage}
				/>
			</UIModal>
		);
	};

	const renderSelectedWorkflowComponent = (currentWorkflow: BookingWorkflow) => {
		switch (currentWorkflow) {
			case WORKFLOW_COMPONENTS.location:
				return <BookingLocation bookingClient={bookingClient} updateBookingConfig={updateBookingConfig} />;
			case WORKFLOW_COMPONENTS.serviceOptionsSelect:
				return <BookingOptionsSelect bookingClient={bookingClient!} updateBookingConfig={updateBookingConfig} />;
			case WORKFLOW_COMPONENTS.dateTime:
				return <BookingDateTime bookingClient={bookingClient!} updateBookingConfig={updateBookingConfig} />;
			case WORKFLOW_COMPONENTS.upgrades:
				return <BookingUpgrades updateBookingConfig={updateBookingConfig} />;
			case WORKFLOW_COMPONENTS.entry:
				return <BookingEntry updateBookingConfig={updateBookingConfig} />;
			case WORKFLOW_COMPONENTS.pay:
				let options = getOptionsFromBookingConfigWithLighting(bookingConfig);
				return (
					<BookingPay
						discountSummer={new DiscountSummer(options, bookingClient?.promos || [])}
						bookingClient={bookingClient}
						options={options}
						closeBooking={closeBooking}
						updateBookingConfig={updateBookingConfig}
					/>
				);
			case undefined:
				const lottieProps: PLLottieProps = {
					loop: true,
					autoplay: true,
					animationData: {},
					path: LottieAnimations.cameraHomeIcon,
					rendererSettings: {
						preserveAspectRatio: 'xMidYMid slice'
					}
				};
				return <Lottie options={lottieProps} height={200} width={200} isStopped={false} isPaused={false} />;
			default:
				return;
		}
	};

	const renderRightItem = (): JSX.Element | null => {
		if (isUserAdmin && selectedWorkflow === WORKFLOW_COMPONENTS.location) {
			return renderPartnerBooking();
		}
		return null;
	};

	const selectedWorkflowIndex = getWorkflowIndexFromSelectedWorkflow();

	const showPrompt: boolean = (bookingConfig.region !== undefined || bookingConfig.serviceLocation?.zipCode !== undefined) && !bookingConfig.isPaymentComplete;
	const overlayCloseType = selectedWorkflowIndex === 0 ? OVERLAY_CLOSE_TYPES.close : OVERLAY_CLOSE_TYPES.back;

	return (
		<UIStepOverlayCard
			steps={workflows}
			currentStep={selectedWorkflowIndex}
			canGoToNextStep={canGoToNextStep()}
			nextButtonTitle={getNextButtonTitle()}
			goToNextStep={goToNextStep}
			className='bookingManager'
			cardStyle={{ maxWidth: 720 }}
			isLoading={isLoading}
			rightItem={renderRightItem()}
			closeType={overlayCloseType}
			contentClassName={'bookingManager__body'}
			title={getCardTitle(selectedWorkflow)}
			hideModal={goBack}>
			{(selectedStep: BookingWorkflow) => (
				<>
					<Prompt when={showPrompt} message={strings.closePrompt} />
					{renderSelectedWorkflowComponent(selectedStep)}
				</>
			)}
		</UIStepOverlayCard>
	);
}
