import gql from 'graphql-tag';

export const GET_DOWNLOAD_FILES = gql`
	query GetDownloadFiles($query: JSON!, $limit: Int) {
		files(query: $query, limit: $limit) {
			id
			type
			isVisible
			status
			watermarkUrl
			thumbnailUrl
			webUrl
			printUrl
			originalUrl
		}
	}
`;
