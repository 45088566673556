import React, { useEffect, useState } from 'react';
import './styles';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import { UI_LABELED_INPUT_DROPDOWN_TYPE } from '../../../../../../components/UI/UILabeledInput/config';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_OPTION_REGION } from './queries';
import { useDispatch, useSelector } from 'react-redux';
import { getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import * as strings from './strings';
import UILabeledInput from '../../../../../../components/UI/UILabeledInput';
import UIRegionRow from '../../../../../../components/UI/UIRegionRow/UIRegionRow';
import { DropdownItem } from '../../../../../../components/UI/UILabeledInput/types';
import { storeSelectedOption } from '../../../../../../redux/store/options/actions';
import { getSelectedOptionSelector } from '../../../../../../redux/selectors/OptionSelectors';
import { CHILD_TYPE } from '../../../../../../components/UI/UIListInputRow/config';

export default function OptionRegionTab({ onChangeOption }: OptionRegionTabProps) {
	const signedInUser = useSelector(getSignedInUserSelector);
	const selectedOption = useSelector(getSelectedOptionSelector);

	const dispatch = useDispatch();

	const [isSaving, setIsSaving] = useState<object>({});

	const [getOptionRegionInfo, { data: optionData }] = useLazyQuery(GET_OPTION_REGION, {
		variables: { id: selectedOption.id }
	});

	useEffect(() => {
		(async () => {
			await getOptionRegionInfo();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (optionData) {
				const fetchedOption: OptionType = optionData['option'];
				dispatch(storeSelectedOption({ ...selectedOption, ...fetchedOption }));
			}
		})();
	}, [optionData]);
	if (!selectedOption) return null;

	const addRegion = async (dropdownItem: DropdownItem) => {
		setIsSaving({ ...isSaving, ['regions']: true });
		const updatedRegions = [...selectedOption.regions, dropdownItem.value].map((region) => region.id);
		await onChangeOption('regions', updatedRegions);
		delete isSaving['regions'];
		setIsSaving({ isSaving });
		await getOptionRegionInfo();
	};

	const removeRegion = async (region) => {
		setIsSaving({ ...isSaving, ['regions']: true });
		let { regions: updatedRegions } = selectedOption;
		for (let i = 0; i < updatedRegions.length; i++) {
			if (updatedRegions[i] === region) {
				updatedRegions.splice(i, 1);
			}
		}
		await onChangeOption(
			'regions',
			updatedRegions.map((region) => region.id)
		);
		delete isSaving['regions'];
		setIsSaving({ isSaving });
		await getOptionRegionInfo();
	};

	const renderRegionComponent = () => {
		if (signedInUser.regions && selectedOption.regions) {
			let optionsDropdown: DropdownItem[] = [];

			for (let index = 0; index < signedInUser.regions.length; index++) {
				let region = signedInUser.regions[index];
				if (selectedOption.regions.map((region) => region.id).indexOf(region.id) === -1) {
					optionsDropdown.push({ value: region, label: region.name });
				}
			}

			return (
				<div className='optionRegions'>
					{optionsDropdown.length > 0 && (
						<UILabeledInput
							type={UI_LABELED_INPUT_DROPDOWN_TYPE}
							options={optionsDropdown}
							containerStyle={{ width: 320, marginBottom: 16 }}
							style={{ width: '100%' }}
							value={{ value: undefined, label: strings.addRegionPlaceholder }}
							onChangeDropdownValue={(dropdownItem) => addRegion(dropdownItem)}
							placeholder={strings.addRegionPlaceholder}
						/>
					)}
					{selectedOption.regions.map((region) => (
						<UIRegionRow key={region.id} region={region} onRemove={() => removeRegion(region)} />
					))}
				</div>
			);
		}
		return null;
	};

	return (
		<div className='optionRegion__regions'>
			<UIListInputRow type={CHILD_TYPE}>{renderRegionComponent()}</UIListInputRow>
		</div>
	);
}
