import gql from 'graphql-tag';

export const GET_SERVICE_ADMIN_TAB = gql`
	query Service($id: String!) {
		service(id: $id) {
			id
			type
			matterportModelId
			surgeBoost
			photographer {
				id
				name
				image
				phoneNumber
			}
			agent {
				id
				name
				image
				phoneNumber
			}
		}
	}
`;
