import gql from 'graphql-tag';

export const GET_SERVICE_INCLUDES_FLOORPLAN = gql`
	query Service($id: String!) {
		service(id: $id) {
			id
			includesFloorplan
		}
	}
`;

export const UPDATE_SERVICE_INCLUDES_FLOOR_PLANS = gql`
	mutation UpdateServiceSurge($serviceId: String!, $includesFloorplan: Boolean!) {
		updateService(id: $serviceId, includesFloorplan: $includesFloorplan) {
			id
			includesFloorplan
		}
	}
`;
