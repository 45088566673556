import gql from 'graphql-tag';

export const GET_SERVICE_WITH_NOTES = gql`
	query Service($id: String!) {
		service(id: $id) {
			id
			regionalManagerNotes
			photographerNotes
			editorNotes
			agentNotes
			type
		}
	}
`;
