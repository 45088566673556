export const SERVICES_TABLE_TYPE = 'PHOTOSHOOT_TABLE_TYPE';
export const SERVICE_REQUEST_TABLE_TYPE = 'SERVICE_REQUEST_TABLE_TYPE';
export const PHOTOGRAPHER_AVAILABLE_PHOTOSHOOT_TABLE_TYPE = 'PHOTOGRAPHER_AVAILABLE_PHOTOSHOOT_TABLE_TYPE';
export const EDITOR_AVAILABLE_PHOTOSHOOT_TABLE_TYPE = 'EDITOR_AVAILABLE_PHOTOSHOOT_TABLE_TYPE';
export const OPTIONS_TABLE_TYPE = 'OPTIONS_TABLE_TYPE';
export const PHOTOGRAPHERS_TABLE_TYPE = 'PHOTOGRAPHERS_TABLE_TYPE';
export const EDITORS_TABLE_TYPE = 'EDITORS_TABLE_TYPE';
export const EDITING_COMPANY_TABLE_TYPE = 'EDITING_COMPANY_TABLE_TYPE';
export const AGENTS_TABLE_TYPE = 'AGENTS_TABLE_TYPE';
export const SALESMEN_TABLE_TYPE = 'SALESMEN_TABLE_TYPE';
export const INVITE_CODE_TABLE_TYPE = 'INVITE_CODES_TABLE_TYPE';
export const PAYMENT_TABLE_TYPE = 'PAYMENT_TABLE_TYPE';
export const PARTNER_PACKAGE_TABLE_TYPE = 'PARTNER_PACKAGE_TABLE_TYPE';
export const FINANCE_TABLE_TYPE = 'FINANCE_TABLE_TYPE';
export const LISTINGS_TABLE_TYPE = 'LISTINGS_TABLE_TYPE';
export const USER_SEARCH_TABLE_TYPE = 'USER_SEARCH_TABLE_TYPE';
