import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PLImage from '../../../../PL/PLImage';

export const UIMenuItem = styled(Link)`
	//DEFAULT
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	min-height: 56px;
	cursor: pointer;
	opacity: 0.9;
	transition: 300ms all ease-out;

	&:hover {
		opacity: 1;
		transform: translateX(4px);

		& > p {
			font-weight: 600;
			color: var(--text-color);
		}
	}

	&:last-of-type p {
		border-bottom: none;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIMenuItemSelected = styled(UIMenuItem)`
	//DEFAULT
	font-weight: 600;
	color: var(--text-color);
	opacity: 1;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIMenuItemIconContainer = styled.div`
	//DEFAULT
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 8px;
	background-color: var(--white-16);
	height: 40px;
	width: 40px;
	border-radius: 50%;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIMenuItemIconContainerIcon = styled(PLImage)`
	//DEFAULT
	height: 22px !important;
	max-height: 22px;
	width: 22px;
	max-width: 22px;
	object-fit: contain;
	transition: 150ms all ease-out;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIMenuItemLabel = styled.p`
	//DEFAULT
	padding: 0 12px;
	cursor: pointer;
	margin: 4px 8px;
	border-radius: 12px;
	text-align: end;
	font-weight: 500;
	color: var(--light-grey);
	text-transform: lowercase;

	&:hover {
		font-weight: 600;
		color: var(--text-color);
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		text-align: start;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		text-align: start;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
