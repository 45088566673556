// Created by jarongallo at 6/22/21
import React, { useState } from 'react';
import './styles';
import { USER_ROLE_AGENT } from '../../../../../../../../utils/Config/Constants';
import UIIconButton from '../../../../../../../../components/UI/UIIconButton';
import UIModal from '../../../../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../../../../components/UI/UIOverlayCard';
import { rescheduleButton, rescheduleModalButton, rescheduleModalDescription, rescheduleModalTitle } from './strings';
import { UI_ICON_BUTTON_OUTLINE_TYPE, UI_ICON_BUTTON_WHITE_TYPE } from '../../../../../../../../components/UI/UIIconButton/config';
import { useSelector } from 'react-redux';
import { getSignedInUserSelector } from '../../../../../../../../redux/selectors/CurrentUserSelectors';

const PLServiceDateRescheduleButton = ({ hasServiceBeenPerformed }) => {
	const signedInUser = useSelector(getSignedInUserSelector);

	const [showRescheduleShootModal, setShowRescheduleShootModal] = useState(false);

	const shouldRenderComponentForService = () => {
		return signedInUser.role === USER_ROLE_AGENT && !hasServiceBeenPerformed;
	};

	const renderRescheduleShootModal = () => {
		const hideModal = () => setShowRescheduleShootModal(false);
		return (
			<UIModal condition={showRescheduleShootModal}>
				<UIOverlayCard
					hideModal={hideModal}
					title={rescheduleModalTitle}
					className='plServiceDateRescheduleButton__overlay'
					description={rescheduleModalDescription}>
					<div className='plServiceDateRescheduleButton__button'>
						<UIIconButton type={UI_ICON_BUTTON_WHITE_TYPE} text={rescheduleModalButton} onClick={hideModal} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	if (!shouldRenderComponentForService()) {
		return null;
	}

	return (
		<div>
			<UIIconButton type={UI_ICON_BUTTON_OUTLINE_TYPE} text={rescheduleButton} onClick={() => setShowRescheduleShootModal(true)} />
			{renderRescheduleShootModal()}
		</div>
	);
};

export default PLServiceDateRescheduleButton;
