// Created by jarongallo at 6/11/21
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './styles';
import { Link } from 'react-router-dom';
import {
	STATUS_EDITOR_UPLOAD,
	STATUS_FINDING_EDIT_TECHNICIAN,
	USER_ROLE_ADMIN,
	USER_ROLE_EDITING_MANAGER,
	USER_ROLE_EDITOR,
	USER_ROLE_REGIONAL_MANAGER
} from '../../../../../../utils/Config/Constants';
import { isUserAdminOrRM } from '../../../../../../utils/Helpers/UserHelpers';
import UIModal from '../../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../../components/UI/UIOverlayCard';
import UIUserSearch from '../../../../../../components/UI/UIUserSearch/UIUserSearch';
import UIIconButton from '../../../../../../components/UI/UIIconButton';
import { EditIcon, PlusIcon, Trashcan } from '../../../../../../assets/icons';
import PLImage from '../../../../../../components/PL/PLImage';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import UIIcon from '../../../../../../components/UI/UIIcon';
import * as strings from './strings';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_PL_EDITOR, GET_SERVICE_WITH_EDITOR, UPDATE_SERVICE_EDITOR } from './queries';
import { toast } from 'react-toastify';
import UIHover from '../../../../../../components/UI/UIHover/UIHover';
import { CHILD_TYPE } from '../../../../../../components/UI/UIListInputRow/config';
import { UI_ICON_BUTTON_CANCEL_TYPE, UI_ICON_BUTTON_FILL_TYPE } from '../../../../../../components/UI/UIIconButton/config';

export default function PLServiceEditor({ serviceId }: PLServiceEditorProps) {
	let isUserAdmin = useSelector(getIsUserAdminSelector);
	const currentUser = useSelector(getSignedInUserSelector);

	const [updateEditor] = useMutation(UPDATE_SERVICE_EDITOR);
	const [getEditor, { data: editorUserData }] = useLazyQuery(GET_PL_EDITOR);
	const [selectedEditor, setSelectedEditor] = useState<UserType>();
	const [editor, setEditor] = useState<UserType>();
	const [service, setService] = useState<ServiceType>();
	const [addEditorOverlay, setAddEditorOverlay] = useState(false);
	const [isAssigningEditor, setIsAssigningEditor] = useState(false);
	const [isRemovingEditor, setIsRemovingEditor] = useState(false);
	const [showEditEditorModal, setShowEditEditorModal] = useState(false);

	const [getService, { data: serviceData }] = useLazyQuery(GET_SERVICE_WITH_EDITOR, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getService();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setService(serviceData['service']);
				if (serviceData['service'].editor !== null) {
					let variables = { id: serviceData['service'].editor.id };
					await getEditor({ variables });
				} else {
					setEditor(undefined);
				}
			}
		})();
	}, [serviceData]);

	useEffect(() => {
		if (editorUserData) {
			setEditor(editorUserData['user']);
		}
	}, [editorUserData]);

	if (!service) return null;

	const shouldRenderComponentForService = () => {
		switch (currentUser.role) {
			case USER_ROLE_EDITOR:
			case USER_ROLE_EDITING_MANAGER:
			case USER_ROLE_REGIONAL_MANAGER:
			case USER_ROLE_ADMIN:
				return true;
			default:
				return false;
		}
	};

	if (!shouldRenderComponentForService()) {
		return null;
	}

	const assignEditor = async () => {
		if (selectedEditor) {
			setIsAssigningEditor(true);
			let variables;
			if (service.status === STATUS_FINDING_EDIT_TECHNICIAN) {
				variables = {
					serviceId: serviceId,
					editor: selectedEditor.id,
					status: STATUS_EDITOR_UPLOAD,
					editingCompany: selectedEditor.editingCompany.id
				};
			} else {
				variables = {
					serviceId: serviceId,
					editor: selectedEditor.id,
					editingCompany: selectedEditor.editingCompany.id
				};
			}

			const response = await updateEditor({ variables });
			const { updateService } = response['data'];

			if (updateService) {
				toast.success(strings.assignEditorSuccess);
				setSelectedEditor(undefined);
				setIsAssigningEditor(false);
				setAddEditorOverlay(false);
				await getService();
			}
		} else {
			toast.info(strings.assignEditorInfo);
		}
	};

	const removeEditor = async () => {
		setIsRemovingEditor(true);
		let variables;
		if (service.status === STATUS_EDITOR_UPLOAD) {
			variables = {
				serviceId: serviceId,
				editor: null,
				status: STATUS_FINDING_EDIT_TECHNICIAN,
				type: service.type
			};
		} else {
			variables = { serviceId: serviceId, editor: null, type: service.type };
		}
		const response = await updateEditor({ variables });
		const { updateService } = response['data'];
		if (updateService) {
			if (service.editor.id === currentUser.id) {
				setShowEditEditorModal(false);
				toast.success(strings.removeEditorSuccess);
			} else {
				setShowEditEditorModal(false);
				setIsRemovingEditor(false);
				toast.success(strings.removeEditorSuccess);
				await getService();
			}
		}
	};

	const renderNoEditor = () => {
		return <p className='plServiceEditor__noEditor'>{strings.noEditorString}</p>;
	};

	const renderAddEditorModal = () => {
		const hideModal = () => {
			setAddEditorOverlay(false);
		};

		return (
			<UIModal condition={addEditorOverlay}>
				<UIOverlayCard
					hideModal={hideModal}
					title={strings.assignEditorModalTitle}
					description={strings.assignEditorModalDescription}
					cardStyle={{ height: '100%' }}>
					<UIUserSearch
						emptySetText={strings.assignEditorModalEmptySet}
						role={USER_ROLE_EDITOR}
						label={strings.assignEditorModalLabel}
						placeholder={strings.assignEditorModalPlaceholder}
						selectedUser={selectedEditor}
						onSelectUser={setSelectedEditor}
					/>
					<div className='plServiceEditor__button'>
						<UIIconButton
							dark
							type={UI_ICON_BUTTON_FILL_TYPE}
							isDisabled={!selectedEditor}
							isLoading={isAssigningEditor}
							disabledErrorMessage={strings.assignEditorModalDisabledButton}
							text={strings.assignEditorModalButton}
							onClick={assignEditor}
						/>
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderEditor = () => {
		return (
			<div className='plServiceEditor'>
				<div className='plServiceEditor__container'>
					<PLImage
						style={{ marginLeft: 12 }}
						borderRadius={16}
						className='plServiceEditor__container--image'
						alt={editor?.name || 'editor'}
						src={editor?.image ?? ''}
					/>
					{editor?.name && <p className='plServiceEditor__container--text'>{editor.name}</p>}
				</div>
			</div>
		);
	};

	const renderEditEditorModal = () => {
		const hideModal = () => {
			setShowEditEditorModal(false);
		};

		const renderUserIfEditor = () => {
			if (editor) {
				return (
					<div>
						<Link to={'/editor/' + editor.id} className='plServiceEditor__selectedUser'>
							<img className='plServiceEditor__selectedUser--image' alt={editor.name} src={editor.image} />
							<div className='plServiceEditor__selectedUser--container'>
								<p className='plServiceEditor__selectedUser--container-name'>{editor.name}</p>
								<p className='plServiceEditor__selectedUser--container-email'>{editor.email}</p>
								<p className='plServiceEditor__selectedUser--container-number'>{editor.phoneNumber}</p>
							</div>
						</Link>
						{(isUserAdminOrRM(currentUser) || service.editor.id === currentUser.id) && (
							<div className='plServiceEditor__button'>
								<UIIconButton isLoading={isRemovingEditor} type={UI_ICON_BUTTON_CANCEL_TYPE} text={strings.removeEditorButton} onClick={removeEditor} />
							</div>
						)}
					</div>
				);
			} else return;
		};

		return (
			<UIModal condition={showEditEditorModal}>
				<UIOverlayCard hideModal={hideModal} title={strings.editEditorModalTitle}>
					{renderAddEditorModal()}
					{renderUserIfEditor()}
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderRightItems = () => {
		if (!editor && isUserAdmin) {
			return (
				<UIHover text={strings.editorAddHover}>
					<UIIcon className='plServiceEditor__edit' iconSrc={PlusIcon.white} onClick={() => setAddEditorOverlay(true)} alt={'add'} />
				</UIHover>
			);
		} else if (editor) {
			return (
				<UIHover text={strings.editEditorHover}>
					<UIIcon className='plServiceEditor__edit' iconSrc={EditIcon.white} onClick={() => setShowEditEditorModal(true)} alt={'edit'} />
				</UIHover>
			);
		} else return;
	};

	if (!editor) {
		return (
			<UIListInputRow label={strings.editorLabel} type={CHILD_TYPE} rightItems={renderRightItems()}>
				{renderAddEditorModal()}
				{renderNoEditor()}
				{renderEditEditorModal()}
			</UIListInputRow>
		);
	}
	return (
		<UIListInputRow label={strings.editorLabel} type={CHILD_TYPE} rightItems={renderRightItems()}>
			{renderEditor()}
			{renderEditEditorModal()}
		</UIListInputRow>
	);
}
