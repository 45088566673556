// Created by jarongallo at 6/11/21
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UILoadingPage from '../../../../../../components/UI/UILoadingPage/UILoadingPage';
import PLServiceBookedOptions from '../../../IndividualService/ServiceBookedOptions';
import EditOptions from '../../../EditOptions';
import UIDropdownSelect from '../../../../../../components/UI/UIDropdownSelect';
import {
	EDIT_TYPE_SERVICE,
	USER_ROLE_AFFILIATE,
	USER_ROLE_AGENT,
	USER_ROLE_EDITING_MANAGER,
	USER_ROLE_EDITOR,
	USER_ROLE_PHOTOGRAPHER
} from '../../../../../../utils/Config/Constants';
import { TABS } from './config';
import './styles';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SERVICE_OPTIONS_SERVICE } from './queries';
import Queries from '../../../../../../utils/API/Queries';
import PLServiceReceipts from '../../PLServiceComponents/PLServiceReceipts';

export default function PLServiceOptionsTab({ serviceId }: PLServiceOptionsTabProps) {
	const signedInUser = useSelector(getSignedInUserSelector);
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const [service, setService] = useState<ServiceType>();
	const [selectedTab, setSelectedTab] = useState(TABS.viewOptions);

	const getUserToBookFor = (): UserType => {
		if (isUserAdmin && service) {
			if (service.type === EDIT_TYPE_SERVICE) {
				if (service.photographer) {
					return service.photographer;
				}
				return service.agent;
			} else {
				if (service.agent) {
					return service.agent;
				} else if (service.photographer) {
					return service.photographer;
				}
			}
		}

		return signedInUser!;
	};

	const [userToBookFor, setUserToBookFor] = useState<UserType>(getUserToBookFor());

	const [getService, { data: serviceData }] = useLazyQuery(GET_SERVICE_OPTIONS_SERVICE, { variables: { id: serviceId } });
	const [getPromos, { data: promosData }] = useLazyQuery(Queries.GET_USER_PROMOS, { variables: { userId: userToBookFor.id } });

	useEffect(() => {
		(async () => {
			await getService();
			await getPromos();
		})();
	}, []);

	useEffect(() => {
		if (serviceData) {
			setService(serviceData['service']);
		}
	}, [serviceData]);

	useEffect(() => {
		if (promosData) {
			let userToBookForWithPromos = userToBookFor;
			userToBookForWithPromos.promos = promosData['promos'];
			setUserToBookFor(userToBookForWithPromos);
		}
	}, [promosData]);

	if (!service) return null;

	const renderSelectedTab = () => {
		if (!userToBookFor.promos) {
			return <UILoadingPage />;
		}

		let page;
		switch (selectedTab.value) {
			case TABS.viewOptions.value:
				page = <PLServiceBookedOptions fetchService={getService} service={service} />;
				break;
			case TABS.receipts.value:
				page = <PLServiceReceipts serviceId={serviceId} />;
				break;
			case TABS.editOptions.value:
				// @ts-ignore
				page = (
					<EditOptions
						onFinish={() => setSelectedTab(TABS.viewOptions)}
						userToBookFor={userToBookFor}
						serviceId={service.id}
						edit={false}
						onUpdate={getService}
					/>
				);
				break;
		}

		const tabs: PLTab[] = getTabs().filter((tab) => tab !== null) as PLTab[];

		const renderTechnicianPay = () => {
			if (signedInUser.role !== USER_ROLE_PHOTOGRAPHER || service.client.id === signedInUser.id) return null;
			return null;
		};

		return (
			<div className='plServiceOptions'>
				<UIDropdownSelect containerClassName='plServiceOptions__tabs' selectedTab={selectedTab} updateSelected={setSelectedTab} tabs={tabs} />
				{renderTechnicianPay()}
				<div className='plServiceOptions__body'>{page}</div>
			</div>
		);
	};

	const getTabs = (): (PLTab | null)[] => {
		const getReceiptTab = (): PLTab | null => {
			let receiptHiddenRoles = [USER_ROLE_EDITOR, USER_ROLE_EDITING_MANAGER];
			if (service.transactions.length > 0 && receiptHiddenRoles.indexOf(signedInUser.role) === -1) {
				return TABS.receipts;
			} else return null;
		};

		if (isUserAdmin) {
			return [TABS.viewOptions, getReceiptTab(), TABS.editOptions];
		} else if (signedInUser && signedInUser.role === USER_ROLE_PHOTOGRAPHER && service && service.client && service.client.id === signedInUser.id) {
			return [TABS.viewOptions, getReceiptTab()];
		} else if (signedInUser && signedInUser.role === USER_ROLE_PHOTOGRAPHER && service && service.type === EDIT_TYPE_SERVICE) {
			return [TABS.viewOptions, getReceiptTab()];
		} else if ((signedInUser && signedInUser.role === USER_ROLE_AGENT) || (signedInUser && signedInUser.role === USER_ROLE_AFFILIATE)) {
			return [TABS.viewOptions, getReceiptTab()];
		} else {
			return [TABS.viewOptions];
		}
	};

	return renderSelectedTab();
}
