// Created by carsonaberle at 10/30/21
import React, { useState } from 'react';
import './styles';
import UIOverlayCard from '../../../UIOverlayCard';
import UIAddressSelect from '../../../UIAddressSelect';
import UIIconButton from '../../../UIIconButton';
import UIModal from '../../../UIModal';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_PROPERTY } from './queries';
import * as strings from './strings';
import UILabeledInput from '../../../UILabeledInput';
import { UI_LABELED_INPUT_TEXT_TYPE } from '../../../UILabeledInput/config';
import { UI_ICON_BUTTON_FILL_TYPE } from '../../../UIIconButton/config';

export default function EditProperty({ condition, hideModal, property, onFinish }: EditPropertyProps) {
	const [isSavingAddress, setIsSavingAddress] = useState<boolean>(false);

	const [streetAddress, setStreetAddress] = useState<string>(property.streetAddress);
	const [unitNumber, setUnitNumber] = useState<string | undefined>(property.unitNumber);
	const [city, setCity] = useState<string>(property.city);
	const [state, setState] = useState<string>(property.state);
	const [country, setCountry] = useState<string>(property.country);
	const [alias, setAlias] = useState<string | undefined>(property.alias);
	const [zipCode, setZipCode] = useState<string>(property.zipCode);

	const [updateProperty] = useMutation(UPDATE_PROPERTY);

	const saveAddress = async () => {
		if (!isSavingAddress) {
			setIsSavingAddress(true);

			const address = `${streetAddress} ${city}, ${state} ${unitNumber ? `Unit ${unitNumber}` : ''}`;

			const variables = {
				propertyId: property.id,
				address,
				streetAddress: streetAddress,
				unitNumber: unitNumber,
				zipCode: zipCode,
				alias: alias,
				latitude: property.latitude,
				longitude: property.longitude,
				addressComponents: property.addressComponents,
				city: city,
				state: state,
				googlePlaceId: property.googlePlaceId,
				country: country
			};
			const response = await updateProperty({ variables });

			const { updateProperty: updatedProperty } = response['data'];
			if (updatedProperty) {
				if (onFinish) {
					await onFinish();
				}
				hideModal();
				setIsSavingAddress(false);
				toast.success(strings.updateSuccess);
			} else {
				toast.error(strings.updateError);
				setIsSavingAddress(false);
			}
		}
	};

	const updateLocation = (serviceLocation: ServiceLocation) => {
		setStreetAddress(serviceLocation.streetAddress);
		setUnitNumber(serviceLocation.unitNumber);
		setCity(serviceLocation.city);
		setState(serviceLocation.state);
		setCountry(serviceLocation.country);
		setAlias(serviceLocation.alias);
		setZipCode(serviceLocation.zipCode);
	};

	return (
		<UIModal condition={condition}>
			<UIOverlayCard title={strings.cardTitle} hideModal={hideModal} cardStyle={{ minHeight: 360 }}>
				<UIAddressSelect serviceLocation={property} updateLocation={updateLocation} googlePlaceId={property.googlePlaceId} />
				<div className='pl-separator' />
				<div className='editProperty__streetUnit'>
					<UILabeledInput
						type={UI_LABELED_INPUT_TEXT_TYPE}
						label={strings.streetAddressLabel}
						placeholder={strings.streetAddressPlaceholder}
						value={streetAddress}
						onChangeValue={setStreetAddress}
					/>
					<UILabeledInput
						containerClassName='editProperty__streetUnit-unit'
						type={UI_LABELED_INPUT_TEXT_TYPE}
						label={strings.unitNumberLabel}
						placeholder={strings.unitNumberPlaceholder}
						value={unitNumber}
						onChangeValue={setUnitNumber}
					/>
				</div>
				<div className='editProperty__cityState'>
					<UILabeledInput
						containerClassName='editProperty__cityState-city'
						type={UI_LABELED_INPUT_TEXT_TYPE}
						label={strings.cityLabel}
						placeholder={strings.cityPlaceholder}
						value={city}
						onChangeValue={setCity}
					/>
					<UILabeledInput
						containerClassName='editProperty__cityState-state'
						type={UI_LABELED_INPUT_TEXT_TYPE}
						label={strings.stateLabel}
						placeholder={strings.statePlaceholder}
						value={state}
						onChangeValue={setState}
					/>
				</div>
				<div className='editProperty__countryZip'>
					<UILabeledInput
						containerClassName='editProperty__countryZip-country'
						type={UI_LABELED_INPUT_TEXT_TYPE}
						label={strings.countryLabel}
						placeholder={strings.countryPlaceholder}
						value={country}
						onChangeValue={setCountry}
					/>
					<UILabeledInput
						containerClassName='editProperty__countryZip-zip'
						type={UI_LABELED_INPUT_TEXT_TYPE}
						label={strings.zipCodeLabel}
						placeholder={strings.zipCodePlaceholder}
						value={zipCode}
						onChangeValue={setZipCode}
					/>
				</div>
				<UILabeledInput
					containerClassName='editProperty--alias'
					type={UI_LABELED_INPUT_TEXT_TYPE}
					label={strings.aliasLabel}
					placeholder={strings.aliasPlaceholder}
					value={alias}
					onChangeValue={setAlias}
				/>
				<UIIconButton
					dark
					className='editProperty--save'
					isDisabled={!property}
					isLoading={isSavingAddress}
					text={strings.saveButtonTitle}
					type={UI_ICON_BUTTON_FILL_TYPE}
					onClick={saveAddress}
				/>
			</UIOverlayCard>
		</UIModal>
	);
}
