// Created by carsonaberle at 3/30/22
import styled from 'styled-components';

export const div_VTMatterportContainer = styled.div<{ isLoaded: boolean }>`
	//DEFAULT
	width: 100%;
	height: 100%;
	transition: 500ms all ease-in;
	padding: 12px 0;
	opacity: ${(props) => (!props.isLoaded ? 0.01 : 1)};

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		padding: 12px 0 0 0;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTMatterportWrapper = styled.div`
	height: 100%;
	align-items: center;
	width: 100%;
	padding: 0;
	max-width: 100%;
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		justify-content: center;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		justify-content: center;
		padding: 0 48px;
	}

	//MONITOR
	@media only screen and (min-width: 1500px) {
	}
`;

export const iframe_VTContentMatterport = styled.iframe<{ marginBottom: string | number }>`
	//DEFAULT
	width: 100%;
	height: 100%;
	border-radius: 12px;
	margin-bottom: ${(props) => props.marginBottom || '48px'};
	transition: 400ms all ease-in;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		border-radius: 12px;
		margin-bottom: 24px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		border-radius: 24px;
		max-height: calc(min(100%, 100vw));
		margin-bottom: 24px;
	}

	//MONITOR
	@media only screen and (min-width: 1500px) {
		max-width: 80%;
		max-height: 1200px;
	}
`;
