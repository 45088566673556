import gql from 'graphql-tag';

export const GET_EDIT_OPTIONS_SERVICE = gql`
	query service($id: String!) {
		service(id: $id) {
			id
			photographer {
				id
			}
			region {
				id
				timezone
			}
			bookedOptions {
				id
				photographerCertificatesRequired
			}
		}
	}
`;

export const GET_EDIT_OPTIONS_PROMOS = gql`
	query promos($userId: String!, $limit: Int, $isActive: Boolean) {
		promos(user: $userId, limit: $limit, isActive: $isActive) {
			id
			name
			promoType
			discountAmount
			discountPercentage
			discountType
			isActive
			uses
			maxUses
			option {
				id
				name
			}
		}
	}
`;

export const GET_EDIT_OPTIONS_UPGRADABLE_OPTIONS = gql`
	query GetOptions($q: JSON, $type: String, $isActive: Boolean, $region: String, $limit: Int, $offset: Int) {
		options(query: $q, type: $type, isActive: $isActive, region: $region, limit: $limit, offset: $offset) {
			id
			coverFile {
				id
				originalUrl
				webUrl
				thumbnailUrl
			}
			name
			price
			payTime
			type
			packageType
			photographerPay
			traineePay
			quantity
			description
			finalFileCount
			deliveryTimeEstimate
			exampleUrl
			photographerCertificatesRequired
			editorCertificatesRequired
			photographerInstructions
			editorInstructions
			requiredInfoPrompt
			requiredInfo
			isAdditionalOption
			regions {
				id
				name
			}
			additionalOptions {
				id
				name
				price
				payTime
				type
				packageType
				photographerPay
				traineePay
				quantity
				description
				photographerCertificatesRequired
				editorCertificatesRequired
				photographerInstructions
				editorInstructions
				requiredInfoPrompt
				requiredInfo
				isAdditionalOption
				coverFile {
					id
					originalUrl
					webUrl
					thumbnailUrl
				}
			}
		}
	}
`;
