import React from 'react';
import './styles';
import ProgressiveImage from 'react-progressive-graceful-image';
import { PLImageProps } from './types';

export default function PLImage({
	forceDimensions,
	height,
	src,
	onClick,
	alt,
	className,
	key,
	id,
	setRef,
	borderRadius,
	imageStyle,
	imageClassName,
	thumbnailSrc,
	width,
	style,
	renderImageOverlay = () => null
}: PLImageProps) {
	const getImageStyle = () => {
		const calcBR = borderRadius || style?.borderRadius || 'unset';
		let returnStyle: any = {
			...style,
			calcBR
		};
		if (forceDimensions) {
		}
		if (width) {
			returnStyle.width = width;
		}
		if (height) {
			returnStyle.height = height;
		}
		return returnStyle;
	};

	const imgStyle = borderRadius
		? { borderRadius, ...imageStyle, maxHeight: '100%', overflow: 'hidden', minWidth: width }
		: { ...imageStyle, maxHeight: '100%', overflow: 'hidden', minWidth: width };

	return (
		<div id={id} style={getImageStyle()} className={className} onClick={onClick}>
			<ProgressiveImage src={src} placeholder={thumbnailSrc ?? ''} key={key}>
				{(progressiveSrc, loading) =>
					loading && !thumbnailSrc ? (
						<div />
					) : (
						<img
							ref={(ref) => setRef && setRef(ref)}
							src={progressiveSrc}
							className={imageClassName}
							style={imgStyle}
							alt={alt}
							width={width}
							height={height}
						/>
					)
				}
			</ProgressiveImage>
			{renderImageOverlay()}
		</div>
	);
}
