import gql from 'graphql-tag';

export const GET_SERVICE_WITH_MUTE = gql`
	query Service($id: String!) {
		service(id: $id) {
			id
			muteNotifications
		}
	}
`;

export const UPDATE_SERVICE_MUTE = gql`
	mutation UpdateServiceMute($serviceId: String!, $muteNotifications: Boolean!) {
		updateService(id: $serviceId, muteNotifications: $muteNotifications) {
			id
			muteNotifications
		}
	}
`;
