import React, { Component } from 'react';
import { connect } from 'react-redux';
import './table-affiliates.scss';
import PLImage from '../../../PLImage';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../../../utils/Helpers/AppHelper';

class TableAffiliates extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	//Mark: Render functions
	render() {
		const { data, pagination } = this.props;

		return (
			<div className='tableAffiliates'>
				<div className='tableAffiliates__rows fade-in-div '>
					{data.map((salesman) => {
						return (
							<div className='tableAffiliates__row' key={salesman.id} onClick={() => this.props.onSelect(salesman)}>
								<div className='tableAffiliates__row--item'>
									<div className='salesman'>
										<PLImage alt={'salesman image'} src={salesman.image} imageClassName='salesman--image' />
										<p className='salesman--name'>{salesman.name}</p>
									</div>
								</div>
							</div>
						);
					})}
				</div>
				{pagination}
			</div>
		);
	}
}

export default connect(commonMapStateToProps, commonMapDispatchToProps)(TableAffiliates);
