export const techLabelEditType = 'Service performed by:';
export const techLabelCaptureType = 'photographer';
export const replaceTechButton = 'replace technician';
export const removeAndReOfferTechButton = 'remove & re-offer';
export const addTechButton = 'add one now';
export const errorMessage = 'Error removing photographer: ';
export const assignTechModalTitle = 'Assign a technician';
export const assignTechModalDescription =
	'This technician will receive an email, any preferred technicians will be removed, and no other technicians will be able to accept this service.';
export const assignTechModalWarning = 'This will also change the service status to ';
export const assignTechModalWarningSpan = 'Photoshoots confirmed';
export const assignTechModalDisabledMessage = 'Please search and select a photographer.';
export const assignTechModalButton = 'assign photographer';
export const assignTechInfoMessage = 'You must select a photographer!';
export const assignTechSuccessMessage = 'updated photographer';
export const noTechMessage =
	"No technician assigned yet! Occasionally it can take up to the night before to schedule your technician. We do this to ensure routes are optimized so photographers aren't late.";
export const searchTechEmptySet = 'No technicians found with that name';
export const searchTechLabel = 'Who will be the technician?';
