import React, { useState } from 'react';
import './styles';
import UIListInputRow from '../../UI/UIListInputRow';
import UIIconButton from '../../UI/UIIconButton';
import UIOverlayCard from '../../UI/UIOverlayCard';
import { add, addTeamMemberErrorMessage, description, title } from './strings';
import { UI_LABELED_INPUT_TEXT_TYPE } from '../../UI/UILabeledInput/config';
import { UI_ICON_BUTTON_WHITE_TYPE } from '../../UI/UIIconButton/config';
import { toast } from 'react-toastify';
import { AddTeamMemberModalProps } from './types';

const AddTeamMemberModal = ({ onAddTeamMember, children, isAddingContact, hideModal }: AddTeamMemberModalProps) => {
	const [newTeamMemberName, setNewTeamMemberName] = useState<string>('');
	const [newTeamMemberEmail, setNewTeamMemberEmail] = useState<string>('');

	const addTeamMember = () => {
		if (!getIsContactValid()) {
			toast.error(addTeamMemberErrorMessage);
		} else {
			onAddTeamMember({
				name: newTeamMemberName,
				email: newTeamMemberEmail,
				isDefault: true
			} as AdditionalContact);
		}
	};

	const emailIsValid = (email) => {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	};

	const getIsContactValid = () => {
		return newTeamMemberName !== undefined && emailIsValid(newTeamMemberEmail);
	};

	return (
		<UIOverlayCard title={title} description={description} hideModal={hideModal}>
			{children}
			<UIListInputRow label={'NAME'} type={UI_LABELED_INPUT_TEXT_TYPE} onChangeValue={setNewTeamMemberName} value={newTeamMemberName} placeholder={'Name...'} />
			<UIListInputRow
				label={'EMAIL'}
				type={UI_LABELED_INPUT_TEXT_TYPE}
				onChangeValue={setNewTeamMemberEmail}
				value={newTeamMemberEmail}
				placeholder={'Email...'}
			/>
			<div className='addTeamMemberModalButtons'>
				<UIIconButton dark type={UI_ICON_BUTTON_WHITE_TYPE} isLoading={isAddingContact} isDisabled={!getIsContactValid()} onClick={addTeamMember} text={add} />
			</div>
		</UIOverlayCard>
	);
};

export default AddTeamMemberModal;
