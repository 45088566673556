// Created by jarongallo at 6/17/21
import React, { useEffect, useState } from 'react';
import * as strings from './strings';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import { CHILD_TYPE } from '../../../../../../components/UI/UIListInputRow/config';
import UICheckbox from '../../../../../../components/UI/UICheckbox/UICheckbox';
import { toast } from 'react-toastify';
import { camelCaseToSentence } from '../../../../../../utils/Helpers/StringHelpers';
import { GET_SERVICE_INCLUDES_FLOORPLAN, UPDATE_SERVICE_INCLUDES_FLOOR_PLANS } from './queries';

export default function PLServiceAdminIncludesFloorplan({ serviceId }: PLServiceAdminIncludesFloorplanProps) {
	const [includesFloorplan, setIncludesFloorplan] = useState<boolean>(false);

	const [updateAdminFloorplan] = useMutation(UPDATE_SERVICE_INCLUDES_FLOOR_PLANS, {
		onError: (error) => toast.error(strings.errorUpdatingToggle(error.message))
	});

	const [getServiceAdminFloorplan, { data: serviceData }] = useLazyQuery(GET_SERVICE_INCLUDES_FLOORPLAN, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getServiceAdminFloorplan();
		})();
	}, []);

	useEffect(() => {
		(() => {
			if (serviceData) {
				setIncludesFloorplan(serviceData['service'].includesFloorplan);
			}
		})();
	}, [serviceData]);

	const updateCheckbox = async (key: string, value: any) => {
		const response = await updateAdminFloorplan({ variables: { serviceId: serviceId, [key]: value } });
		if (response['data']) {
			await getServiceAdminFloorplan();
			toast.success(strings.updateToggleSuccess(camelCaseToSentence(key)));
		} else {
			toast.error(strings.errorUpdatingToggle(`update failed.`));
		}
	};

	return (
		<UIListInputRow type={CHILD_TYPE} label={strings.toggleLabel} description={strings.includesFloorPlanDescription}>
			<UICheckbox
				label={strings.includesFloorplan}
				style={{ marginTop: 12 }}
				onChangeExpansion={() => updateCheckbox('includesFloorplan', !includesFloorplan)}
				status={includesFloorplan ? UICheckbox.CHECKED : UICheckbox.UNCHECKED}
			/>
		</UIListInputRow>
	);
}
