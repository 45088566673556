import React, { useEffect, useState } from 'react';
import './config';
import './styles';
import { FILE_STATUS_IN_PROGRESS, FILE_STATUS_LIVE, FILE_STATUS_PROOF, FILE_TYPE_FLOOR_PLAN, SCREEN_TYPE } from '../../../utils/Config/Constants';
import UICheckbox from '../UICheckbox/UICheckbox';
import PLImage from '../../PL/PLImage';
import UIIcon from '../UIIcon';
import { InProcessIcon, InvisibleWhite, ProofRed } from '../../../assets/icons';
import Queries from '../../../utils/API/Queries';
import { ZendeskAPI } from 'react-zendesk';
import UIVideo from '../UIVideo/UIVideo';
import FullscreenFiles from '../../../pages/portal/global/IndividualService/FullscreenFiles';
import { getDimensionsToFitContainer, TYPES } from './config';
import { useLazyQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { getScreenTypeSelector } from '../../../redux/selectors/ControlSelectors';
import { FULLSCREEN_TYPES } from '../../../pages/portal/global/IndividualService/FullscreenFiles/config';
import Measure from 'react-measure';
import UIMultiImageSelectQuickActions from './components/UIMultiImageSelectQuickActions';

export default function UIMultiImageSelect({
	serviceId,
	listing,
	updateData,
	files,
	disableSort,
	type,
	rightItem,
	emptySetTitle,
	emptySetDescription
}: UIMultiImageSelectProps) {
	const [isUpdating, setIsUpdating] = useState<object>({});
	const [selectedImageIndex, setSelectedImageIndex] = useState<number>();
	const [service, setService] = useState<ServiceType>();
	const [selectedFiles, setSelectedFiles] = useState<FileType[]>([]);
	const [containerBounds, setContainerBounds] = useState<PLSortableContainerBoundsType>();

	const screenType = useSelector(getScreenTypeSelector);

	const [getService, { data: serviceData }] = useLazyQuery(Queries.GET_SERVICE_WITH_FILES, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			if (serviceId) {
				await getService();
			}
		})();
	}, []);

	useEffect(() => {
		if (serviceData) {
			setService(serviceData['service']);
		}
	}, [serviceData]);

	const checkedSelection = (file: FileType) => {
		if (selectedFiles.length === 0) {
			setSelectedImageIndex(files.indexOf(file));
		} else {
			toggleIsChecked(file);
		}
	};

	const getImageOverlay = (file: FileType) => {
		if (!file.isActive) {
			return (
				<div className='UiMultiFileSelect__img-overlay fade-in-div'>
					<p>this image has been deleted</p>
				</div>
			);
		}

		let selectionIndicatorComponent = (file: FileType) => {
			return (
				<UICheckbox
					subBox
					key={file.index}
					className='uiMultiImageSelect__checkbox'
					onChangeExpansion={() => toggleIsChecked(file)}
					status={selectedFiles.map((file) => file.id).indexOf(file.id) === -1 ? UICheckbox.UNCHECKED : UICheckbox.CHECKED}
				/>
			);
		};

		const renderText = (file: FileType) => {
			let text = file.filename;

			if (file.type === FILE_TYPE_FLOOR_PLAN && file.filename) {
				if (!file.tags.floorLevel) {
					text = 'show more';
				} else {
					text = `floor: ${file.tags.floorLevel}`;
				}
			}

			return (
				<div className='imageHover__img-overlay fade-in-div'>
					<p className='imageHover__img-overlay--title'>{text}</p>
				</div>
			);
		};

		return (
			<div className='imageHover'>
				{renderFileByType(file)}
				{renderText(file)}
				{selectionIndicatorComponent(file)}
				<div className='imageHover__img__text'>
					{file.status !== FILE_STATUS_LIVE && (
						<div className='imageHover__img-overlay--status'>
							{file.status === FILE_STATUS_PROOF && <UIIcon iconSrc={ProofRed} className='imageHover__img-overlay--type' alt={'proof'} />}
							{file.status === FILE_STATUS_IN_PROGRESS && (
								<UIIcon iconSrc={InProcessIcon.red} className='imageHover__img-overlay--type' alt={'in progress'} />
							)}
						</div>
					)}
					{!file.isVisible && (
						<div className='imageHover__img-overlay--status'>
							<UIIcon iconSrc={InvisibleWhite} className='imageHover__img-overlay--type' alt={'hidden'} />
						</div>
					)}
				</div>
			</div>
		);
	};

	const toggleIsChecked = (file: FileType) => {
		if (selectedFiles.map((file) => file.id).indexOf(file.id) === -1) {
			//File is not selected, select it
			setSelectedFiles([...selectedFiles, file]);
		} else {
			//File is selected, unselect it
			setSelectedFiles(selectedFiles.filter((selectedFile) => selectedFile.id !== file.id));
		}
	};

	const renderFileByType = (file: FileType) => {
		const itemMax = getImageWidth();
		const dimensions = getDimensionsToFitContainer(file, { width: itemMax, height: itemMax });

		switch (type) {
			case TYPES.image:
			case TYPES.socialMedia:
			case TYPES.floorPlan:
			case TYPES.rawImage:
				return (
					<PLImage
						alt={file.filename}
						forceDimensions
						height={dimensions.height}
						width={dimensions.width}
						src={file.thumbnailUrl}
						style={dimensions && { minHeight: dimensions.height, maxWidth: itemMax, maxHeight: itemMax }}
						className='uiMultiImageSelect__image'
					/>
				);
			case TYPES.video:
				return (
					<UIVideo
						indService
						controls
						key={file.id}
						video={file}
						width={dimensions.width ?? 600}
						height={dimensions.height ?? 382}
						src={file.thumbnailUrl}
						onUpdate={updateData}
						className='uiMultiImageSelect__image'
					/>
				);
			default:
				return;
		}
	};

	const getFullscreenTypeFromType = () => {
		switch (type) {
			case TYPES.video:
				return FULLSCREEN_TYPES.VIDEO_TYPE;
			case TYPES.floorPlan:
				return FULLSCREEN_TYPES.FLOOR_PLAN_TYPE;
			case TYPES.image:
			default:
				return FULLSCREEN_TYPES.IMAGE_TYPE;
		}
	};

	const getNumberOfImagesFromContainerWidth = () => {
		const containerWidth = containerBounds?.width ?? 256;
		return Math.floor(containerWidth / (screenType === SCREEN_TYPE.mobile ? 160 : 256));
	};

	const getImageWidth = () => {
		const containerWidth = containerBounds?.width ?? 256;
		const numberOfImages = getNumberOfImagesFromContainerWidth();
		const widthPerImage = containerWidth / numberOfImages;
		return widthPerImage - 16;
	};

	const renderFiles = (files) => {
		if (files.length === 0) {
			return (
				<div className='uiMultiImageSelect__empty'>
					<p className='uiMultiImageSelect__empty--title'>{emptySetTitle ?? 'There are no files yet.'}</p>
					<p className='uiMultiImageSelect__empty--description'>{emptySetDescription ?? 'Upload files now, or order a service to add images.'}</p>
				</div>
			);
		}
		return files.map((file) => {
			return (
				<div key={file.id} className='uiMultiImageSelect__item' onClick={() => checkedSelection(file)}>
					{getImageOverlay(file)}
				</div>
			);
		});
	};

	const renderFullscreenFiles = () => {
		if (selectedImageIndex === undefined) return null;

		const renderImageOverlay = (file) => {
			if (file.isVisible) return null;
			return (
				<div className='uiMultiImageSelect__item--overlay'>
					<UIIcon className='uiMultiImageSelect__item--overlay-icon' iconSrc={InvisibleWhite} alt={'hidden'} />
				</div>
			);
		};

		const disableImage = (file) => !file.isVisible;

		return (
			<FullscreenFiles
				type={getFullscreenTypeFromType()}
				updateService={updateData}
				initialSlide={selectedImageIndex}
				disableImage={disableImage}
				renderImageOverlay={renderImageOverlay}
				goBack={() => setSelectedImageIndex(undefined)}
				files={files}
				listing={listing}
				service={service}
			/>
		);
	};

	return (
		<div className='uiMultiImageContainer'>
			<UIMultiImageSelectQuickActions
				type={type}
				disableSort={disableSort}
				rightItem={rightItem}
				updateData={updateData}
				files={files}
				setSelectedFiles={setSelectedFiles}
				listing={listing}
				service={service}
				isUpdating={isUpdating}
				setIsUpdating={setIsUpdating}
				selectedFiles={selectedFiles}
			/>
			<Measure
				bounds
				onResize={(contentRect: Object | any) => {
					setContainerBounds(contentRect.bounds);
				}}>
				{({ measureRef }: Function | any) => (
					<div ref={measureRef} className={'uiMultiImageSelect__listing'}>
						{renderFiles(files)}
					</div>
				)}
			</Measure>
			{renderFullscreenFiles()}
		</div>
	);
}
