export const getErrorMessageFromApolloError = (error) => {
	return gqlErrorReplace(error.message);
};

export const gqlErrorReplace = (errorMessage) => {
	return errorMessage.replace('GraphQL error: ', '');
};

class APIHelper {
	static getRowsQuery = async (query, variables, client, onResponse) => {
		const response = await client.query({
			query: query,
			variables: variables
		});
		if (onResponse) {
			onResponse(response);
		}
		return response;
	};

	static apiQuery = async (props, client, query, variables) => {
		if (!query) {
			props.showErrorMessage('Error 1002: No query defined');
			return;
		}
		if (!variables) {
			props.showErrorMessage('Error 1003: No query variables defined');
			return;
		}
		if (!client) {
			props.showErrorMessage("Error 1001: Can't find client!");
			return;
		}
		return client.query({ query, variables });
	};

	static apiMutation = (props, mutation, variables, completion) => {
		let client = props.api.client;
		if (!mutation) {
			props.showErrorMessage('Error 1002: No query defined');
			return;
		}
		if (!variables) {
			props.showErrorMessage('Error 1003: No query variables defined');
			return;
		}
		if (!client) {
			props.showErrorMessage("Error 1001: Can't find client");
			return;
		}

		client
			.mutate({ mutation, variables })
			.then(completion)
			.catch((error) => {
				APIHelper.errorHandler(error, props);
			});
	};

	static errorHandler = (error, props) => {
		if (error.networkError) {
			let networkErrorCode = error.networkError.statusCode;
			if (networkErrorCode === 401) {
				props.requireLogin(true);
			}
		}
	};
}

export default APIHelper;
