import React, { useState } from 'react';
import './styles/mobile.scss';
import { useDispatch, useSelector } from 'react-redux';
import UIListInputRow from '../../UI/UIListInputRow';
import Queries from '../../../utils/API/Queries';
import { clearUserCache } from '../../../utils/Helpers/LoginHelper';
import UIModal from '../../UI/UIModal';
import { SCREEN_TYPE, USER_ROLE_PHOTOGRAPHER } from '../../../utils/Config/Constants';
import { useHistory } from 'react-router-dom';
import UIIconButton from '../../UI/UIIconButton';
import UIOverlayCard, { OVERLAY_CLOSE_TYPES } from '../../UI/UIOverlayCard';
import { setAsyncTimeout } from '../../../utils/Helpers/GeneralHelpers';
import PLEditImage from '../../PL/EditImage';
import { getSignedInUserSelector } from '../../../redux/selectors/CurrentUserSelectors';
import { toast } from 'react-toastify';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { setSelectedRegion, setSignedInUser, setSuperUser } from '../../../redux/store/user/actions';
import { setServicesRequiringProofing } from '../../../redux/store/actionItems/actions';
import { clearFilters } from '../../../redux/store/filters/actions';
import { getSignedInUser } from '../../../sagas/LoginSagas/actions';
import { UI_LABELED_INPUT_PHONE_NUMBER_TYPE, UI_LABELED_INPUT_TEXT_TYPE } from '../../UI/UILabeledInput/config';
import { CHILD_TYPE } from '../../UI/UIListInputRow/config';
import { getScreenTypeSelector } from '../../../redux/selectors/ControlSelectors';
import { UI_ICON_BUTTON_FILL_TYPE, UI_ICON_BUTTON_OUTLINE_TYPE, UI_ICON_BUTTON_WHITE_TYPE } from '../../UI/UIIconButton/config';

export default function UserProfileModal({ hideModal, force }: UserProfileModalProps) {
	const screenType = useSelector(getScreenTypeSelector);
	const signedInUser = useSelector(getSignedInUserSelector);

	const history = useHistory();
	const dispatch = useDispatch();
	const client = useApolloClient();

	const [password, setPassword] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [name, setName] = useState<string>(signedInUser.name);
	const [isSavingName, setIsSavingName] = useState<boolean>(false);
	const [isSavingEmail, setIsSavingEmail] = useState<boolean>(false);
	const [isSavingPhoneNumber, setIsSavingPhoneNumber] = useState<boolean>(false);
	const [isUpdatingBio, setIsUpdatingBio] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isUpdatingCubiCasaEmail, setIsUpdatingCubiCasaEmail] = useState<boolean>(false);
	const [email, setEmail] = useState<string>(signedInUser.email);
	const [phoneNumber, setPhoneNumber] = useState<string>(signedInUser.phoneNumber);
	const [cubiCasaEmail, setCubiCasaEmail] = useState<string>(signedInUser.cubiCasaEmail);
	const [bio, setBio] = useState<string>(signedInUser.bio);
	const [changingPassword, setChangingPassword] = useState<boolean>(false);

	const [changePasswordMutation] = useMutation(Queries.CHANGE_PASSWORD, {
		onError: () => {
			setIsLoading(false);
			setPassword('');
			setConfirmPassword('');
			toast.error('Error updating your password. Please call us!');
		}
	});

	const [updateUserMutation] = useMutation(Queries.UPDATE_USER_MUTATION, {
		onError: (error) => {
			setIsSavingEmail(false);
			toast.error(error.message);
		}
	});

	const onChangeNameText = async () => {
		if (!isSavingName) {
			setIsSavingName(true);
			const response = await updateUserMutation({
				variables: { id: signedInUser.id, name }
			});
			const { updateUser } = response['data'];
			if (updateUser) {
				updateCurrentUser(() => {
					setIsSavingName(false);
					toast.success('Saved name!');
				});
			}
		}
	};

	const isPasswordValid = () => {
		if (password.trim().length < 8) {
			return false;
		} else return password.trim() === confirmPassword.trim();
	};

	const changePassword = async () => {
		if (!isLoading && isPasswordValid()) {
			setIsLoading(true);

			const response = await changePasswordMutation({
				variables: { id: signedInUser.id, password: password }
			});
			const { updatePassword } = response['data'];
			if (updatePassword) {
				updateCurrentUser(() => {
					toast.success('Successfully updated password!');
					setChangingPassword(false);
					setIsLoading(false);
					setPassword('');
					setConfirmPassword('');
				});
			}
		}
	};

	const onChangeEmailText = async () => {
		if (!isSavingEmail) {
			setIsSavingEmail(true);
			const response = await updateUserMutation({
				variables: { id: signedInUser.id, email }
			});
			const { updateUser } = response['data'];
			if (updateUser) {
				updateCurrentUser(() => {
					setIsSavingEmail(false);
					toast.success('Saved email.');
				});
			}
		}
	};

	const onChangePhoneNumberText = async () => {
		if (!isSavingPhoneNumber) {
			setIsSavingPhoneNumber(true);
			const response = await updateUserMutation({
				variables: { id: signedInUser.id, phoneNumber }
			});
			setIsSavingPhoneNumber(false);
			const { updateUser } = response['data'];
			if (updateUser) {
				updateCurrentUser(() => {
					setIsSavingName(false);
					toast.success('Saved phone number.');
				});
			}
		}
	};

	const onChangeBio = async () => {
		if (!isUpdatingBio) {
			const response = await updateUserMutation({
				variables: { id: signedInUser.id, bio }
			});
			const { updateUser } = response['data'];
			if (updateUser) {
				updateCurrentUser(() => {
					setIsUpdatingBio(false);
					toast.success('Updated bio!');
				});
			}
		}
	};

	const logOut = async () => {
		hideModal();
		history.push('/');
		await setAsyncTimeout(() => {
			localStorage.clear();
			dispatch(setSelectedRegion());
			dispatch(setSignedInUser());
			dispatch(setSuperUser());
			dispatch(setServicesRequiringProofing());
			dispatch(clearFilters());
			clearUserCache();
			toast.info('You have been logged out.');
		}, 200);
	};

	const updateCubiCasaEmail = async () => {
		if (!isUpdatingCubiCasaEmail) {
			setIsUpdatingCubiCasaEmail(true);
			const response = await updateUserMutation({
				variables: { id: signedInUser.id, cubiCasaEmail }
			});
			if (!response) return;
			const { updateUser } = response['data'];
			if (updateUser) {
				updateCurrentUser(() => {
					setIsUpdatingCubiCasaEmail(false);
					toast.success('Updated CubiCasa email!');
				});
			}
		}
	};

	const updateCurrentUser = (onFinish: Function) => {
		dispatch(getSignedInUser(client, onFinish));
	};

	const renderBio = () => {
		if (signedInUser) {
			const { role } = signedInUser;
			if (role === USER_ROLE_PHOTOGRAPHER) {
				return (
					<div>
						<UIListInputRow
							label={'PHOTOGRAPHER BIO'}
							type={'textField'}
							containerStyle={{ width: '100%' }}
							savedValue={signedInUser.bio}
							onChangeValue={(bio: string) => setBio(bio)}
							onSave={onChangeBio}
							value={bio}
							placeholder={'Photographer bio...'}
						/>
					</div>
				);
			}
		}
		return undefined;
	};

	const renderChangePasswordModal = () => {
		const hideModal = () => {
			if (!force) {
				setChangingPassword(false);
			}
		};

		return (
			<UIModal condition={changingPassword}>
				<UIOverlayCard hideModal={hideModal} title={!force ? 'Change password' : 'Password change required'}>
					<UIListInputRow
						type={UI_LABELED_INPUT_TEXT_TYPE}
						containerStyle={{ width: '100%' }}
						style={{ width: '100%' }}
						onChangeValue={(password: string) => setPassword(password)}
						label={'PASSWORD'}
						value={password}
						placeholder={'Password...'}
					/>
					<UIListInputRow
						type={UI_LABELED_INPUT_TEXT_TYPE}
						containerStyle={{ marginTop: 8, width: '100%' }}
						style={{ width: '100%' }}
						onChangeValue={(confirmPassword: string) => setConfirmPassword(confirmPassword)}
						label={'CONFIRM PASSWORD'}
						value={confirmPassword}
						placeholder={'Confirm password...'}
					/>
					<div className='general-buttons-container'>
						<UIIconButton
							dark
							isLoading={isLoading}
							isDisabled={!isPasswordValid()}
							disabledErrorMessage={'Please enter two matching passwords of 8 characters or more'}
							type={UI_ICON_BUTTON_FILL_TYPE}
							text={'update password'}
							onClick={changePassword}
						/>
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderCubiCasaEmail = () => {
		if (signedInUser) {
			const { role } = signedInUser;
			if (role === USER_ROLE_PHOTOGRAPHER) {
				return (
					<UIListInputRow
						containerStyle={{ marginTop: 16 }}
						listInputStyle={{ borderBottom: 'none' }}
						className='width-100'
						type={UI_LABELED_INPUT_TEXT_TYPE}
						value={cubiCasaEmail}
						savedValue={signedInUser.cubiCasaEmail}
						onChangeValue={setCubiCasaEmail}
						onSave={updateCubiCasaEmail}
						isLoading={isUpdatingCubiCasaEmail}
						placeholder={'Enter your CubiCasa email...'}
						label={`CubiCasa account email... (status: ${signedInUser['cubiCasaStatus']})`}
					/>
				);
			}
		}
		return undefined;
	};

	if (!signedInUser) {
		return null;
	}

	return (
		<UIOverlayCard
			closeType={screenType === SCREEN_TYPE.mobile ? OVERLAY_CLOSE_TYPES.back : OVERLAY_CLOSE_TYPES.close}
			hideModal={hideModal}
			cardStyle={{ maxWidth: 'calc(min(100%, 440px))' }}
			title={'Your profile'}>
			<div>
				<div className='basic-info-image-logout-container'>
					<div className='basic-info-image-logout-container__options'>
						<PLEditImage userId={signedInUser.id} userName={signedInUser.name} userImage={signedInUser.image} updateUser={updateCurrentUser} />
					</div>
				</div>
				<UIListInputRow
					className='width-100'
					containerStyle={{ width: '100%' }}
					isLoading={isSavingName}
					type={UI_LABELED_INPUT_TEXT_TYPE}
					savedValue={signedInUser.name}
					onSave={onChangeNameText}
					onChangeValue={(name: string) => setName(name)}
					value={name}
					placeholder={'Your name...'}
				/>
				<UIListInputRow
					className='width-100'
					containerStyle={{ width: '100%' }}
					isLoading={isSavingEmail}
					type={UI_LABELED_INPUT_TEXT_TYPE}
					savedValue={signedInUser.email}
					onSave={onChangeEmailText}
					onChangeValue={setEmail}
					value={email}
					placeholder={'Your email...'}
				/>
				<UIListInputRow
					className='width-100'
					containerStyle={{ width: '100%' }}
					listInputStyle={{ borderBottom: 'none' }}
					isLoading={isSavingPhoneNumber}
					type={UI_LABELED_INPUT_PHONE_NUMBER_TYPE}
					savedValue={signedInUser.phoneNumber}
					onSave={onChangePhoneNumberText}
					onChangeValue={setPhoneNumber}
					value={phoneNumber}
					placeholder={'Your phone number...'}
				/>
				{renderBio()}
				{renderCubiCasaEmail()}
				<div style={{ flexDirection: 'row', marginTop: 12 }}>
					<UIIconButton type={UI_ICON_BUTTON_OUTLINE_TYPE} onClick={logOut} className='logoutBtn' text={'log out'} />
					<UIIconButton type={UI_ICON_BUTTON_WHITE_TYPE} onClick={() => setChangingPassword(true)} className='logoutBtn' text={'change password'} />
				</div>
			</div>
			{renderChangePasswordModal()}
		</UIOverlayCard>
	);
}
