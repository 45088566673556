import React, { useEffect, useState } from 'react';
import './styles';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import { UI_LABELED_INPUT_DROPDOWN_TYPE, UI_LABELED_INPUT_NUMBER_TYPE } from '../../../../../../components/UI/UILabeledInput/config';
import { OPTION_EDITOR_CERTIFICATIONS_DROPDOWN_VALUES } from '../../../../../../utils/Config/Constants';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_OPTION_EDITOR } from './queries';
import { useDispatch, useSelector } from 'react-redux';
import * as strings from './strings';
import UIHover from '../../../../../../components/UI/UIHover/UIHover';
import { PlusIcon, Trashcan } from '../../../../../../assets/icons';
import UIModal from '../../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../../components/UI/UIOverlayCard';
import UIIconButton from '../../../../../../components/UI/UIIconButton';
import UILabeledInput from '../../../../../../components/UI/UILabeledInput';
import { storeSelectedOption } from '../../../../../../redux/store/options/actions';
import { getSelectedOptionSelector } from '../../../../../../redux/selectors/OptionSelectors';
import { CHILD_TYPE } from '../../../../../../components/UI/UIListInputRow/config';
import { UI_ICON_BUTTON_FILL_TYPE } from '../../../../../../components/UI/UIIconButton/config';

export default function OptionEditorTab({ onChangeOption }: OptionEditorTabProps) {
	const selectedOption = useSelector(getSelectedOptionSelector);
	const dispatch = useDispatch();

	const [editorInstructions, setEditorInstructions] = useState<string>(selectedOption.editorInstructions || '');
	const [editorFileCount, setEditorFileCount] = useState<number>(selectedOption.editorFileCount);
	const [editorCertificatesRequired, setEditorCertificatesRequired] = useState<string[]>(selectedOption.editorCertificatesRequired);
	const [selectedValue, setSelectedValue] = useState<SelectedValueType | undefined>(undefined);
	const [renderAddCertOptionOverlay, setRenderAddCertOptionOverlay] = useState<boolean>(false);
	const [renderRemoveCertOptionOverlay, setRenderRemoveCertOptionOverlay] = useState<boolean>(false);
	const [certificationToRemove, setCertificationToRemove] = useState<string | undefined>();
	const [isSaving, setIsSaving] = useState<object>({});

	const [getOptionEditor, { data: optionData }] = useLazyQuery(GET_OPTION_EDITOR, {
		variables: { id: selectedOption.id }
	});

	useEffect(() => {
		(async () => {
			await getOptionEditor();
		})();
	}, []);

	useEffect(() => {
		(() => {
			if (optionData) {
				const fetchedOption: OptionType = optionData['option'];
				setEditorInstructions(fetchedOption.editorInstructions);
				setEditorFileCount(fetchedOption.editorFileCount);
				setEditorCertificatesRequired(fetchedOption.editorCertificatesRequired);
				dispatch(storeSelectedOption({ ...selectedOption, ...fetchedOption }));
			}
		})();
	}, [optionData]);

	if (!selectedOption) return null;

	const updateOption = async (key, value) => {
		setIsSaving({ ...isSaving, [key]: true });
		await onChangeOption(key, value);
		delete isSaving[key];
		setIsSaving({ isSaving });
		await getOptionEditor();
	};

	const addCertificationToOption = async () => {
		let certifications = selectedOption.editorCertificatesRequired;
		if (selectedValue) {
			certifications.push(selectedValue.value);
		}
		await onChangeOption('editorCertificatesRequired', certifications);
		setRenderAddCertOptionOverlay(false);
		await getOptionEditor();
	};

	const removeCertificationFromOption = async () => {
		if (!certificationToRemove) return;
		let certifications = selectedOption.editorCertificatesRequired;
		let index = certifications.indexOf(certificationToRemove);
		if (index > -1) {
			certifications.splice(index, 1);
		}
		await onChangeOption('editorCertificatesRequired', certifications);
		setRenderRemoveCertOptionOverlay(false);
		setCertificationToRemove(undefined);
		await getOptionEditor();
	};

	const getAvailableCertsEditor = () => {
		let array = OPTION_EDITOR_CERTIFICATIONS_DROPDOWN_VALUES;
		if (selectedOption.editorCertificatesRequired) {
			array = array.filter((x) => !selectedOption.editorCertificatesRequired.includes(x.value));
		}
		return array;
	};

	const renderAddOptionCertModal = () => {
		const inputStyle = { marginTop: 16, width: 320 };
		const cardStyle = { height: '100%' };
		const hideModal = () => {
			setRenderAddCertOptionOverlay(false);
		};
		return (
			<UIModal condition={renderAddCertOptionOverlay}>
				<UIOverlayCard cardStyle={cardStyle} hideModal={hideModal} title={strings.selectCertTitle}>
					<UILabeledInput
						type={UI_LABELED_INPUT_DROPDOWN_TYPE}
						containerStyle={inputStyle}
						options={getAvailableCertsEditor()}
						onChangeDropdownValue={(value) => setSelectedValue(value)}
						value={selectedValue}
						placeholder={strings.selectCert}
					/>
					<div className='general-buttons-container'>
						<UIIconButton dark icon={PlusIcon.grey} type={UI_ICON_BUTTON_FILL_TYPE} text={strings.addButton} onClick={addCertificationToOption} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderRemoveOptionCertModal = () => {
		const hideModal = () => {
			setRenderRemoveCertOptionOverlay(false);
		};
		return (
			<UIModal condition={renderRemoveCertOptionOverlay}>
				<UIOverlayCard hideModal={hideModal} title={strings.removeCertTitle}>
					<p className='general-description'>
						{strings.removeCertCheck} "<b>{certificationToRemove}</b>" from <b>{selectedOption.name}</b>?
					</p>
					<div className='optionEditor--button'>
						<UIIconButton dark icon={Trashcan.grey} type={UI_ICON_BUTTON_FILL_TYPE} text={strings.removeCertButton} onClick={removeCertificationFromOption} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderEditorCertifications = () => {
		const hasCertifications = editorCertificatesRequired && editorCertificatesRequired.length > 0;
		if (hasCertifications) {
			return editorCertificatesRequired.map((certification, index) => (
				<p
					key={index}
					className='optionEditor--text'
					onClick={() => {
						setCertificationToRemove(certification);
						setRenderRemoveCertOptionOverlay(true);
					}}>
					{certification}
				</p>
			));
		}
		return <p className='optionEditor--certList--certList-noRequired'>{strings.noRequiredCertsString}</p>;
	};

	return (
		<div className='optionEditor'>
			<UIListInputRow
				label={strings.editorInstructionsLabel}
				placeholder={strings.editorInstructionsPlaceholder}
				value={editorInstructions}
				isLoading={isSaving['editorInstructions']}
				onChangeValue={setEditorInstructions}
				savedValue={selectedOption.editorInstructions}
				onSave={() => updateOption('editorInstructions', editorInstructions)}
			/>
			<UIListInputRow
				type={UI_LABELED_INPUT_NUMBER_TYPE}
				helpText={strings.editorFileUploadHelp}
				placeholder={strings.editorFileUploadPlaceholder}
				label={strings.editorFileUploadLabel}
				value={editorFileCount}
				isLoading={isSaving['editorFileCount']}
				onChangeValue={(editorFileCount) => setEditorFileCount(parseInt(editorFileCount))}
				savedValue={selectedOption.editorFileCount}
				onSave={() => updateOption('editorFileCount', editorFileCount)}
			/>
			<UIListInputRow type={CHILD_TYPE}>
				<div className='optionEditor--certHeader'>
					<p className='optionEditor--certHeader-text'>{strings.requiredCertsString}</p>
					<UIHover text={strings.addCertHover}>
						<img className='optionEditor--label' src={PlusIcon.mint} alt={strings.addButton} onClick={() => setRenderAddCertOptionOverlay(true)} />
					</UIHover>
				</div>
				<div className='optionEditor--certList'>{renderEditorCertifications()}</div>
			</UIListInputRow>
			{renderRemoveOptionCertModal()}
			{renderAddOptionCertModal()}
		</div>
	);
}
