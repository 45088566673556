import React, { Component } from 'react';
import './empty-data-set.scss';
import { withApollo } from '@apollo/react-hoc';
import { connect } from 'react-redux';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../utils/Helpers/AppHelper';
import PropTypes from 'prop-types';

class EmptyDataSet extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	//Mark: Render functions
	render() {
		const { message } = this.props;
		return (
			<div className='eds-card'>
				<p className='eds-card--text' key={message}>
					{message}
				</p>
			</div>
		);
	}
}

EmptyDataSet.propTypes = { message: PropTypes.string };

export default withApollo(connect(commonMapStateToProps, commonMapDispatchToProps)(EmptyDataSet));
