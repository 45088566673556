// Created by carsonaberle at 11/24/21
import gql from 'graphql-tag';

export const GET_PROOF_OPTION = gql`
	query GetProofOption($id: String!) {
		option(id: $id) {
			id
			coverFile {
				id
				originalUrl
				webUrl
				thumbnailUrl
			}
			name
			price
			payTime
			type
			packageType
			photographerPay
			traineePay
			quantity
			description
			finalFileCount
			deliveryTimeEstimate
			exampleUrl
			photographerCertificatesRequired
			editorCertificatesRequired
			photographerInstructions
			editorInstructions
			requiredInfoPrompt
			requiredInfo
			isAdditionalOption
			regions {
				id
				name
			}
			additionalOptions {
				id
				packageType
				quantity
				price
				name
				requiredInfoPrompt
				requiredInfo
				deliveryTimeEstimate
				exampleUrl
				payTime
				type
				photographerCertificatesRequired
				description
				coverFile {
					id
					originalUrl
					webUrl
					thumbnailUrl
				}
			}
		}
	}
`;

export const GET_USER_PROMOS = gql`
	query promos($id: String!, $limit: Int, $isActive: Boolean) {
		promos(user: $id, limit: $limit, isActive: $isActive) {
			id
			name
			promoType
			discountAmount
			discountPercentage
			discountType
			isActive
			uses
			maxUses
			user {
				id
			}
			option {
				id
				name
			}
		}
	}
`;

export const PROOF_SHOOT = gql`
	mutation ProofService($serviceId: String!, $proofedFiles: [JSON]) {
		proofService(id: $serviceId, proofedFiles: $proofedFiles) {
			id
			status
			isAdminApproved
		}
	}
`;
