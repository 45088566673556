import gql from 'graphql-tag';

export const GET_SERVICE_FOR_FILES = gql`
	query GetServiceForFiles($id: String!) {
		service(id: $id) {
			id
			includesMatterport
			includesFloorplan
			status
			editor {
				id
			}
		}
	}
`;
