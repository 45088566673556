// Created by carsonaberle at 11/23/21
import React, { useState } from 'react';
import './styles';
import { FILE_STATUS_OPTIONS } from '../../../utils/Config/Constants';
import UIIcon from '../../UI/UIIcon';
import UIOverlayCard from '../../UI/UIOverlayCard';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_FILES_STATUS } from './queries';
import * as strings from './strings';

export default function ChangeFileStatusModal({ hideModal, files, refetchData, service, listing, highlightedStatus }: ChangeFileStatusModalProps) {
	const [isChangingFileStatus, setIsChangingFileStatus] = useState<boolean>(false);

	const [mutateChangeFileStatus] = useMutation(UPDATE_FILES_STATUS, {
		onError: async () => {
			if (refetchData) {
				await refetchData();
			}
			setIsChangingFileStatus(false);
			hideModal();
			toast.error(strings.updateError);
		}
	});

	const changeSelectedFilesStatus = async (status: FileStatusType) => {
		if (!isChangingFileStatus) {
			setIsChangingFileStatus(true);
		}

		let changeStatusFiles = files.map((item) => {
			return { id: item.id, status: status };
		});

		const response = await mutateChangeFileStatus({
			variables: { requestedUpdates: changeStatusFiles, service: service?.id ?? undefined, listing: listing?.id ?? undefined }
		});

		if (response?.data) {
			if (refetchData) {
				await refetchData();
			}
			setIsChangingFileStatus(false);
			toast.success(strings.updateSuccess);
			hideModal();
		}
	};

	return (
		<UIOverlayCard contentClassName={'changeStatusCard'} hideModal={hideModal} title={strings.title(files)}>
			<div className='changeFileStatusModal'>
				{FILE_STATUS_OPTIONS.map((statusOption) => (
					<div
						className={`changeFileStatusModal--item ${highlightedStatus === statusOption.value ? 'changeFileStatusModal--item-selected' : ''}`}
						onClick={() => changeSelectedFilesStatus(statusOption.value as FileStatusType)}>
						<div className='changeFileStatusModal--item_header'>
							<UIIcon iconSrc={statusOption.icon} className='changeFileStatusModal--item_header-icon' alt={'status option'} />
							<p className='changeFileStatusModal--item_header-title'>{statusOption.label}</p>
						</div>
						<p className='changeFileStatusModal--item-description'>{statusOption.description}</p>
					</div>
				))}
			</div>
		</UIOverlayCard>
	);
}
