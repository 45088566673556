import React from 'react';
import './styles';
import { useSelector } from 'react-redux';
import {
	OPTION_ADDITIONAL_INFO_TYPES,
	USER_ROLE_ADMIN,
	USER_ROLE_AGENT,
	USER_ROLE_PHOTOGRAPHER,
	USER_ROLE_REGIONAL_MANAGER
} from '../../../utils/Config/Constants';
import { Link } from 'react-router-dom';
import { convertCents, getPriceFromOption } from '../../../utils/Helpers/StringHelpers';
import AdditionalInfo from './components/AdditionalInfo';
import { getOptionFromBookableOptionsById } from '../../../utils/Helpers/BookingHelpers';
import { getSignedInUserSelector } from '../../../redux/selectors/CurrentUserSelectors';
import { toast } from 'react-toastify';
import { getSingleQuantityPriceFromBookableOption } from './helpers';

export default function UIBookableOption({
	bookableOption,
	disableSelection,
	to,
	className,
	selectedOptions,
	onClick,
	disableAutoDeselectGroup,
	updateSelectedOptions
}: UIOptionProps) {
	const signedInUser = useSelector(getSignedInUserSelector);

	const getOptionNameClassName = () => {
		let selectedItem = getOptionFromBookableOptionsById(selectedOptions || [], bookableOption.option.id);
		if (!selectedItem) {
			return 'uiOption__name';
		} else {
			return 'uiOption__name--selected';
		}
	};

	const getCardClassName = () => {
		let selectedItem = getOptionFromBookableOptionsById(selectedOptions || [], bookableOption.option.id);
		if (!selectedItem) {
			return 'uiOption__card';
		} else {
			return 'uiOption__card uiOption__card--selected';
		}
	};

	const renderOptionByRole = () => {
		const { exampleUrl, deliveryTimeEstimate } = bookableOption.option;

		let isClickEnabled = !disableSelection;

		let selectedQuantity =
			selectedOptions && selectedOptions[bookableOption.option.id]
				? selectedOptions[bookableOption.option.id].selectedQuantity
					? selectedOptions[bookableOption.option.id].selectedQuantity
					: 1
				: 1;
		if (!selectedQuantity) {
			selectedQuantity = 1;
		}
		let minQuantity = 1;

		let quantityRequiringPurchase = selectedQuantity;

		let components: JSX.Element[] = [];
		let footerComponents: JSX.Element[] = [];

		const price = bookableOption.option.price;

		const singleQuantityPrice = getSingleQuantityPriceFromBookableOption(bookableOption);

		let originalPriceString = convertCents(price);
		let singleQuantityPriceString = convertCents(singleQuantityPrice);

		const option = bookableOption.option;

		let nameComponent = (
			<p key={`name-${option.id}`} className={getOptionNameClassName()}>
				{option.name}
			</p>
		);

		const updateSelectedOption = (updatedOption: BookableOptionType) => {
			updatedOption.discountCalculator?.setBookableOption(updatedOption);
			let bookableOptions = selectedOptions?.map((bookableOption) => {
				if (bookableOption.option.id === updatedOption.option.id) {
					return updatedOption;
				}
				return bookableOption;
			});
			if (updateSelectedOptions) {
				updateSelectedOptions(bookableOptions);
			}
		};

		let costForPurchaseComponent = (
			<div key={`cost-for-purchase-${option.id}`}>
				<div className='uiOption'>
					<div>
						{option.requiredInfo !== OPTION_ADDITIONAL_INFO_TYPES.quantity ||
							(quantityRequiringPurchase > 0 ? <p className='uiOption__label'>cost</p> : undefined)}
						<div className='uiOption__price'>
							<div className='uiOption__price--labels'>
								{(option.requiredInfo !== OPTION_ADDITIONAL_INFO_TYPES.quantity || quantityRequiringPurchase > 0) && (
									<span className='uiOption__price--calculated'>{singleQuantityPriceString}</span>
								)}
								{price !== singleQuantityPrice && <span className='uiOption__price--original'>{originalPriceString}</span>}
								{option.requiredInfo === OPTION_ADDITIONAL_INFO_TYPES.quantity && quantityRequiringPurchase > 1 && (
									<span className='uiOption__price--breakdown'>
										{quantityRequiringPurchase} x {convertCents(getPriceFromOption(option))}
									</span>
								)}
							</div>
							{option.previouslyPurchasedQuantity && (
								<p className='uiOption__label' style={{ marginTop: 4 }}>
									{option.previouslyPurchasedQuantity} previously purchased
								</p>
							)}
						</div>
					</div>
				</div>
			</div>
		);

		let descriptionComponent = option.description ? (
			<p key={`description-${option.id}`} className='uiOption__description'>
				{option.description}
			</p>
		) : null;

		let deliveryEstimateComponent, exampleUrlComponent;

		if (deliveryTimeEstimate) {
			deliveryEstimateComponent = (
				<div key={`delivery-time-${option.id}`} className='uiOption__item'>
					<p className='uiOption__label'>delivery estimate</p>
					<p>{deliveryTimeEstimate}</p>
				</div>
			);
		}
		if (exampleUrl) {
			exampleUrlComponent = (
				<a key={`example-${option.id}`} className='uiOption__example' onClick={(e) => e.stopPropagation()} target={'_blank'} href={exampleUrl}>
					view example
				</a>
			);
		}

		if (signedInUser) {
			switch (signedInUser.role) {
				case USER_ROLE_ADMIN:
				case USER_ROLE_REGIONAL_MANAGER:
					components.push(nameComponent);
					components.push(costForPurchaseComponent);
					if (descriptionComponent) footerComponents.push(descriptionComponent);
					footerComponents.push(deliveryEstimateComponent);
					footerComponents.push(exampleUrlComponent);
					break;
				case USER_ROLE_PHOTOGRAPHER:
					components.push(nameComponent);
					components.push(costForPurchaseComponent);
					if (descriptionComponent) footerComponents.push(descriptionComponent);
					break;
				case USER_ROLE_AGENT:
					components.push(nameComponent);
					components.push(costForPurchaseComponent);
					if (descriptionComponent) footerComponents.push(descriptionComponent);
					footerComponents.push(deliveryEstimateComponent);
					footerComponents.push(exampleUrlComponent);
					break;
			}
		} else {
			components.push(nameComponent);
			components.push(costForPurchaseComponent);
			footerComponents.push(deliveryEstimateComponent);
			footerComponents.push(exampleUrlComponent);
		}

		const onClickOption = (e: React.MouseEvent<HTMLElement>) => {
			if (onClick) {
				onClick();
				return;
			}
			if (isClickEnabled && updateSelectedOptions && selectedOptions) {
				e.stopPropagation();
				if (!selectedOptions && process.env.PL_ENVIRONMENT !== 'LIVE') {
					toast.info('Dev note: This option has no selectedOptions');
					return;
				}
				let newSelectedOptions = selectedOptions;
				if (!disableAutoDeselectGroup) {
					//start without any options of the same packageType except the clicked option
					newSelectedOptions = selectedOptions.filter(
						(selectedOption) => selectedOption.option.packageType !== option.packageType || selectedOption.option.id === option.id
					);
				}
				if (newSelectedOptions.filter((selectedOption) => selectedOption.option.id === option.id).length > 0) {
					updateSelectedOptions(newSelectedOptions.filter((selectedOption) => selectedOption.option.id !== option.id));
				} else {
					updateSelectedOptions([...newSelectedOptions, bookableOption]);
				}
			}
		};

		footerComponents = footerComponents.filter((component) => component !== undefined);

		let innerComponent = (
			<div
				className={`${getCardClassName()} fade-in-div`}
				style={isClickEnabled ? { flexDirection: 'column', cursor: 'pointer' } : { flexDirection: 'column' }}
				onClick={onClickOption}>
				<div className='uiOption__header'>
					{option.coverFile && <img alt='cover image for option' className='uiOption__cover' src={option.coverFile.thumbnailUrl} />}
					<div className='uiOption__card--content'>{components.map((component) => component)}</div>
				</div>
				{getOptionFromBookableOptionsById(selectedOptions || [], option.id) && option.requiredInfo && option.requiredInfo !== 'none' && (
					<AdditionalInfo minQuantity={minQuantity} bookableOption={bookableOption} updateSelectedOption={updateSelectedOption} />
				)}
				{footerComponents.length > 0 && <div className='uiOption__footer'>{footerComponents.map((component) => component)}</div>}
			</div>
		);

		if (to) {
			return (
				<Link to={to} className={className}>
					{innerComponent}
				</Link>
			);
		}
		return innerComponent;
	};

	return renderOptionByRole();
}
