import gql from 'graphql-tag';

export const GET_ADDITIONAL_OPTIONS = gql`
	query GetOptions($q: JSON, $type: String, $isActive: Boolean, $region: String, $limit: Int, $offset: Int) {
		options(query: $q, type: $type, isActive: $isActive, region: $region, limit: $limit, offset: $offset) {
			id
			name
		}
	}
`;
