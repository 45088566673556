export const adminHoldCheckbox = 'Place this service in administrative hold';
export const disabledServiceRequestInProgress = 'Cannot create a service request from images uploaded by the client or that already currently have a request';
export const disabledMissingNotes = 'Please add some notes for the editor';
export const sendToEditor = 'send to editor';
export const offerToAllEditors = 'offer to all editors';
export const submit = 'submit';
export const assignEditor = 'Assign an editor';
export const editorEmptySet = 'No editors found with that name';
export const notesInfo = 'Please write some notes about the issues with the selected photos.';
export const notesLabel = 'What needs changed? (5 character minimum)';
export const notesPlaceholder = 'Ex. The wall colors are incorrect.';
export const searchPlaceholder = 'search for editors...';
export const searchLabel = 'Who will be editing this?';
