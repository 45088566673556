import React, { useEffect, useState } from 'react';
import './styles';
import { useSelector } from 'react-redux';
import UICheckbox from '../UICheckbox/UICheckbox';
import UIIconButton from '../UIIconButton';
import { DownloadIcon, Trashcan } from '../../../assets/icons';
import { FILE_TYPE_IMAGE } from '../../../utils/Config/Constants';
import Queries from '../../../utils/API/Queries';
import UIModal from '../UIModal';
import PLImage from '../../PL/PLImage';
import moment from 'moment-timezone';
import UIIcon from '../UIIcon';
import { getApiUrl } from '../../../utils/API/APIConfig';
import UIOverlayCard from '../UIOverlayCard';
import { getFileTransferApiUrl, guessCurrentTimezone } from '../../../utils/Helpers/AppHelper';
import { TYPES } from './config';
import { toast } from 'react-toastify';
import { getIsUserAdminSelector } from '../../../redux/selectors/CurrentUserSelectors';
import { useApolloClient } from '@apollo/react-hooks';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SERVICE_ADDRESS } from './queries';
import { UI_ICON_BUTTON_CANCEL_TYPE, UI_ICON_BUTTON_OUTLINE_TYPE } from '../UIIconButton/config';
let streamSaver;
if (process.env.BUILD_TARGET === 'client') {
	streamSaver = require('streamsaver');
}
export default function UIMultiFileSelect({
	title,
	data,
	headerComponent,
	serviceId,
	updateService,
	downloadType,
	rightItem,
	emptySetTitle,
	emptySetDescription
}: UIMultiFileSelectProps) {
	const client = useApolloClient();
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const [selectedFiles, setSelectedFiles] = useState<FileType[]>([]);
	const [address, setAddress] = useState<string>();
	const [isDeletingSelectedFiles, setIsDeletingSelectedFiles] = useState<boolean>(false);
	const [deleteFile, setDeleteFile] = useState<boolean>(false);
	const [checked, setChecked] = useState<boolean>(false);
	const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);

	const [getServiceAddress, { data: serviceData }] = useLazyQuery(GET_SERVICE_ADDRESS, { variables: { serviceId } });

	useEffect(() => {
		(async () => {
			await getServiceAddress();
		})();
	}, []);

	useEffect(() => {
		if (serviceData) {
			const fetchedService: ServiceType = serviceData['service'];
			if (fetchedService?.property?.streetAddress) {
				setAddress(fetchedService.property.streetAddress);
			} else if (fetchedService?.property?.address) {
				setAddress(fetchedService.property.address);
			} else {
				setAddress(fetchedService.address);
			}
		}
	}, [serviceData]);

	const downloadSelectedFiles = async (size: string) => {
		setIsLoadingDownload(true);
		const fileIds = selectedFiles.map((file) => file.id);
		let baseUrl = getApiUrl(true);

		let body: { fileIds: string[]; size?: string } = {
			fileIds
		};

		if (size) body.size = size;

		try {
			const res = await fetch(`${baseUrl}/file-transfer/download-by-ids`, {
				body: JSON.stringify(body),
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				}
			});

			const fileStream = streamSaver.createWriteStream(`${address}.zip`);
			const writer = fileStream.getWriter();

			const reader = res.body?.getReader();
			if (!reader) return;
			const pump = () =>
				reader.read().then(({ value, done }) => {
					if (done) writer.close();
					else {
						writer.write(value);
						return writer.ready.then(pump);
					}
				});

			await pump()
				.then(() => toast.success('File downloaded!'))
				.catch((err) => toast.error(err.message));
			setIsLoadingDownload(false);
		} catch {
			toast.error('Error downloading files.');
			setIsLoadingDownload(false);
		}
	};

	const getDownloadIconFromType = (downloadType: string) => {
		switch (downloadType) {
			case TYPES.EXTRA_RAW_TYPE:
			case TYPES.RAW_VIDEO_TYPE:
			case TYPES.RAW_TYPE:
				return (
					<UIIcon
						className='uiMultiFileSelect__selection--icon-img'
						iconSrc={DownloadIcon.white}
						isLoading={isLoadingDownload}
						onClick={() => downloadSelectedFiles('original')}
						alt={'download'}
					/>
				);
			case TYPES.EDITED_IMAGE_TYPE:
			case TYPES.EDITED_VIDEO_TYPE:
			default:
				return;
		}
	};

	const renderDownloadAllButtonByType = (downloadType: string) => {
		if (inputData.length === 0) return null;

		let linkParams = '';
		switch (downloadType) {
			case TYPES.RAW_TYPE:
				linkParams = '&includeRawPhotos=true&status=live';
				break;
			case TYPES.EXTRA_RAW_TYPE:
				linkParams = '&includeRawPhotos=true&status=inProgress';
				break;
			case TYPES.RAW_VIDEO_TYPE:
				linkParams = '&includeRawVideos=true';
				break;
			case TYPES.EDITED_IMAGE_TYPE:
			case TYPES.EDITED_VIDEO_TYPE:
			default:
				break;
		}
		let link = `${getFileTransferApiUrl()}/download?serviceId=${serviceId}&size=original${linkParams}`;
		return isUserAdmin || data ? (
			<a target={'__blank'} href={link}>
				<UIIconButton type={UI_ICON_BUTTON_OUTLINE_TYPE} icon={DownloadIcon.white} text={'all'} />
			</a>
		) : null;
	};

	const toggleCheckedAll = () => {
		if (!checked) {
			setChecked(true);
			setSelectedFiles(data);
		} else {
			setChecked(false);
			setSelectedFiles([]);
		}
	};

	const toggleIsChecked = (file: FileType) => {
		if (selectedFiles.map((file: FileType) => file.id).indexOf(file.id) === -1) {
			setSelectedFiles([...selectedFiles, file]);
		} else {
			let newSelectedFiles = selectedFiles.filter((selectedFile: FileType) => selectedFile.id !== file.id);
			setSelectedFiles([...newSelectedFiles]);
		}
	};

	const deleteSelectedFiles = async () => {
		let files = selectedFiles;
		for (let i = 0; i < files.length; i++) {
			files[i].isActive = false;
		}
		setIsDeletingSelectedFiles(true);
		const response = await client
			.mutate({
				mutation: Queries.UPDATE_FILES,
				variables: {
					requestedUpdates: files,
					service: serviceId
				}
			})
			.catch(() => {
				setIsDeletingSelectedFiles(false);
				toast.error('Error deleting selected files.');
			});
		const { updateFiles } = response['data'];
		if (updateFiles) {
			await updateService();
			setSelectedFiles([]);
			setIsDeletingSelectedFiles(false);
			setDeleteFile(false);
			toast.success(`Successfully deleted all selected files.`);
		}
	};

	const renderDeleteModal = () => {
		const closeModal = () => setDeleteFile(false);
		return (
			<UIModal condition={deleteFile}>
				<UIOverlayCard hideModal={closeModal} title={'Delete Images?'} description={'Are you sure you want to delete the selected images?'}>
					<div className='deleteFileOverlayButtons'>
						<UIIconButton isLoading={isDeletingSelectedFiles} type={UI_ICON_BUTTON_CANCEL_TYPE} text={'yes, delete'} onClick={() => deleteSelectedFiles()} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderFiles = () => {
		if (inputData.length === 0) {
			return (
				<div className='uiMultiImageSelect__empty'>
					<p className='uiMultiImageSelect__empty--title'>{emptySetTitle ?? 'There are no files yet.'}</p>
					<p className='uiMultiImageSelect__empty--description'>{emptySetDescription ?? 'Upload files now, or order a service to add images.'}</p>
				</div>
			);
		}
		return inputData.map((file: FileType) => {
			return (
				<div className='uploadedFileList' key={file.id}>
					<div className='uploadedFileList__checkbox'>{selectionIndicatorComponent(file)}</div>

					{(file.type === FILE_TYPE_IMAGE && downloadType === TYPES.RAW_TYPE) ||
						(file.filename.endsWith('.jpg') && downloadType === TYPES.RAW_TYPE && (
							<PLImage key={file.id} src={file.thumbnailUrl} className='uploadedFileList__thumbnail' alt={'thumbnail'} />
						))}

					<div className='uploadedFileList__info'>
						<p className='uploadedFileList__info--filename'>{file.filename}</p>
						<p className='uploadedFileList__info--createdDate'>{moment(file.createdAt).tz(guessCurrentTimezone()).format('MM/DD/YYYY [at] HH:MM a z')}</p>
					</div>
				</div>
			);
		});
	};

	const inputData = data.filter((file: FileType) => file.isActive);
	let selectionIndicatorComponent = (file: FileType) => {
		return (
			<UICheckbox
				subBox
				onChangeExpansion={() => toggleIsChecked(file)}
				className='uploadedFileList__checkbox'
				status={selectedFiles.map((file: FileType) => file.id).indexOf(file.id) === -1 ? UICheckbox.UNCHECKED : UICheckbox.CHECKED}
			/>
		);
	};
	return (
		<div className='UiMultiFileSelect' key={inputData.length}>
			{renderDeleteModal()}
			<div className='UiMultiFileSelect__header'>
				<div className='UiMultiFileSelect__header--checkbox'>
					{renderDownloadAllButtonByType(downloadType)}
					{rightItem}
				</div>
			</div>
			<div className='uiMultiFileSelect__selection'>
				{inputData.length > 0 && (
					<UICheckbox
						subBox
						status={checked ? UICheckbox.CHECKED : UICheckbox.UNCHECKED}
						onChangeExpansion={() => toggleCheckedAll()}
						className='UiMultiFileSelect__header--checkbox-box'
					/>
				)}
				{selectedFiles.length > 0 && (
					<>
						<div className='UiMultiFileSelect__selected'>{selectedFiles.length}</div>
						<div className='uiMultiFileSelect__selection--icon'>
							<UIIcon
								alt={'delete'}
								className='uiMultiFileSelect__selection--icon-img'
								iconSrc={Trashcan.white}
								isLoading={isDeletingSelectedFiles}
								onClick={() => setDeleteFile(true)}
							/>
						</div>
						<div className='uiMultiFileSelect__selection--icon'>{getDownloadIconFromType(downloadType)}</div>
					</>
				)}
			</div>
			<div className='UiMultiFileSelect__title'>{title}</div>
			{headerComponent && <div className='UiMultiFileSelect__description'>{headerComponent}</div>}
			<div className='UiMultiFileSelect__files' key={inputData.length}>
				{renderFiles()}
			</div>
		</div>
	);
}
