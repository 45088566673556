// Created by jarongallo at 6/17/21
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './styles';
import { HistoryWhite } from '../../../../../../assets/icons';
import UIModal from '../../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../../components/UI/UIOverlayCard';
import ServiceActions from '../../../IndividualService/ServiceHistory';
import UIIcon from '../../../../../../components/UI/UIIcon';
import * as strings from './strings';
import { getIsUserAdminSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';

export default function PLServiceHistory({ serviceId }: PLServiceHistoryProps) {
	let isUserAdmin = useSelector(getIsUserAdminSelector);

	const [showServiceHistoryModal, setShowServiceHistoryModal] = useState(false);

	const shouldRenderComponentForService = () => {
		return isUserAdmin;
	};
	if (!shouldRenderComponentForService()) {
		return null;
	}
	if (!serviceId) return null;

	const renderServiceHistoryModal = () => {
		const hideModal = () => {
			setShowServiceHistoryModal(false);
		};

		return (
			<UIModal condition={showServiceHistoryModal}>
				<UIOverlayCard hideModal={hideModal} title={strings.serviceHistoryModal} cardStyle={{ height: '800px' }}>
					<ServiceActions serviceId={serviceId} />
				</UIOverlayCard>
			</UIModal>
		);
	};

	return (
		<div className='plServiceHistory' onClick={() => setShowServiceHistoryModal(true)}>
			<div className='plServiceHistory__iconContainer'>
				<UIIcon iconSrc={HistoryWhite} alt={strings.serviceHistoryAlt} className='plServiceHistory__iconContainer-icon' />
				<p>{strings.serviceHistoryHover}</p>
			</div>
			{renderServiceHistoryModal()}
		</div>
	);
}
