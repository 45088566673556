import styled from 'styled-components';
import UILabeledInput from '../../UILabeledInput';

export const div_UIListInputRowContainer = styled.div`
	//DEFAULT
	width: 100%;
	min-height: fit-content;
	display: flex;
	padding: 24px 0;
	flex-direction: column;
	justify-content: center;
	border-bottom: var(--white-16) solid 1px;

	&:last-of-type {
		border: none;
	}

	&:first-child {
		padding-top: 0;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-bottom: var(--white-16) solid 1px;

		&:last-of-type {
			border: none;
		}
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-bottom: var(--white-16) solid 1px;

		&:last-of-type {
			border: none;
		}

		&:first-of-type {
			padding-top: 0;
		}
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}

	.div_UIListInputRowContainer--wrapper {
		//DEFAULT
		width: 100%;

		//SHORT
		@media only screen and (max-height: 720px) {
		}

		//TABLET
		@media (min-width: 660px) and (max-width: 1024px) {
			width: 100%;
		}

		//LAPTOP
		@media only screen and (min-width: 1025px) {
			width: 100%;
		}

		//MONITOR
		@media only screen and (min-height: 1500px) {
		}
	}
`;

export const p_UIListInputRowLabel = styled.p`
	//DEFAULT
	font-size: 11pt;
	font-weight: 600;
	margin: 0;
	text-transform: lowercase;
	color: var(--white-60);
	font-family: BrownRegular, sans-serif;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		font-size: 12pt;
		font-weight: 500;
		color: var(--white-80);
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		font-size: 12pt;
		font-weight: 500;
		color: var(--white-80);
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_UIListInputRowHeaderContainer = styled.div`
	//DEFAULT
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-width: 100%;
	justify-content: flex-start;
	margin: 0 !important;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_UIListInputRowHeaderItems = styled.div`
	//DEFAULT
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 4px;
	width: 100%;
	justify-content: space-between;
`;

export const p_UIListInputRowHeaderDescription = styled.p`
	//DEFAULT
	width: 100%;
	margin: 0;
	font-size: 12pt;
	color: var(--white-40);
`;

export const input_UIListInputRowInput = styled(UILabeledInput)`
	//DEFAULT
	margin: 0;
	width: 100%;
	border: unset;
	border-radius: 8px;
	min-height: 40px;
	background-color: unset;
	font-size: 12pt;
	font-weight: 400;
	outline: none;
	padding-left: 8px;
	padding-right: 8px;
	max-width: 100%;
	color: var(--text-color);
	caret-color: var(--mint-primary);
	font-family: BrownRegular, sans-serif;

	&:hover {
		border-color: white !important;
		border-radius: 6px !important;
		cursor: pointer;
	}

	&:focus {
		border-color: white !important;
		border-radius: 6px !important;
		cursor: text !important;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		margin: 0;
		width: 100%;
		border: unset;
		border-radius: 8px;
		min-height: 40px;
		background-color: unset;
		font-size: 12pt;
		font-weight: 400;
		outline: none;
		padding-left: 8px;
		padding-right: 8px;
		max-width: 100%;
		color: var(--text-color);
		caret-color: var(--mint-primary);
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		margin: 0;
		width: 100%;
		border: unset;
		border-radius: 8px;
		min-height: 40px;
		background-color: unset;
		font-size: 12pt;
		font-weight: 400;
		outline: none;
		padding-left: 8px;
		padding-right: 8px;
		max-width: 100%;
		color: var(--text-color);
		caret-color: var(--mint-primary);
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}

	.p_UIListInputRowLabel {
		//DEFAULT
		font-size: 11pt;
		font-weight: 600;
		margin: 0;
		text-transform: lowercase;
		color: var(--white-60);
		font-family: BrownRegular, sans-serif;

		//SHORT
		@media only screen and (max-height: 720px) {
		}

		//TABLET
		@media (min-width: 660px) and (max-width: 1024px) {
			font-size: 12pt;
			font-weight: 500;
			color: var(--white-80);
		}

		//LAPTOP
		@media only screen and (min-width: 1025px) {
			font-size: 12pt;
			font-weight: 500;
			color: var(--white-80);
		}

		//MONITOR
		@media only screen and (min-height: 1500px) {
		}
	}
`;
