import "./mobile.scss";
import "./tablet.scss";
import "./desktop.scss";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const LeftItemsWrapper = styled.div`
  //DEFAULT
  display: flex;
  flex-direction: column;
  max-height: 100%;
  flex: 1;
  padding: 0;
  width: 100%;
  overflow-y: scroll;

  //TABLET+
  @media (min-width: 480px) {
    padding: 4px 0 12px 0;
  }
`;

export const ItemLink = styled(Link)`
  //DEFAULT
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  flex: 0 1 auto;
  transition: 350ms all ease-out;
  padding: 12px;

  &:last-child {
    & > div {
      background-color: transparent;
    }
  }


  //TABLET
  @media (min-width: 480px) {
    opacity: 0.45;
    margin: 0 8px 4px 8px;
    padding: 4px 12px 0 12px;

    &:hover {
      background-color: var(--white-4);
      opacity: 1;
    }
  }
`;

export const ItemLinkSelected = styled(ItemLink)`
  //DEFAULT
  opacity: 1;
  transition: 250ms all ease-in-out;
  background-color: var(--white-8);
  border-radius: 4px;

  & > * {
    background-color: transparent;

    & > div {
    }
  }

  & > * > p {
    color: white;
    font-weight: 600;
  }
`;

export const ItemContent = styled.div`
  //DEFAULT
  display: flex;
  flex-direction: row;
  flex: 0 1 auto;
  align-items: center;
  margin: 8px 12px;
  cursor: pointer;
`;

export const ItemTitle = styled.p`
  //DEFAULT
  font-weight: 600;
  color: var(--text-color);
  font-size: 12pt;
  margin-left: 16px;
`;

export const ItemIcon = styled.img`
  //DEFAULT
  height: 22px;
  width: 22px;
  object-fit: contain;
`;

export const ItemSeparator = styled.div`
  //DEFAULT
  display: none;
  background-color: var(--white-8);
  height: 1px;
  width: 100%;
  margin: 4px 0 0 0;


  //TABLET
  @media (min-width: 480px) {
    display: flex;
  }
`;
