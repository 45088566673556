import gql from 'graphql-tag';

export const GET_SERVICE_WITH_EDITOR = gql`
	query GetServiceWithEditor($id: String!) {
		service(id: $id) {
			id
			status
			type
			editor {
				id
			}
		}
	}
`;

export const GET_PL_EDITOR = gql`
	query GetEditorForService($id: String!) {
		user(id: $id) {
			id
			name
			email
			phoneNumber
			image
			editingCompany {
				id
			}
		}
	}
`;

export const UPDATE_SERVICE_EDITOR = gql`
	mutation UpdateServiceEditor($serviceId: String!, $editor: String, $editingCompany: String, $status: String) {
		updateService(id: $serviceId, editor: $editor, editingCompany: $editingCompany, status: $status) {
			id
			editor {
				id
			}
			status
		}
	}
`;
