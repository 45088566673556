import { EDIT_TYPE_SERVICE, OPTION_ADDITIONAL_INFO_TYPES } from '../Config/Constants';
import { Suggest } from 'react-geosuggest';

export const getAddressFromServiceLocation = (serviceLocation: ServiceLocation) => {
	if (!serviceLocation) return 'Error no property';
	if (serviceLocation.unitNumber) {
		return `${serviceLocation.address} Unit ${serviceLocation.unitNumber}`;
	}
	return serviceLocation.address;
};

export const getAddressFromProperty = (property: PropertyType) => {
	if (!property) return 'Error no property';
	if (property.unitNumber) {
		return `${property.address} Unit ${property.unitNumber}`;
	}
	return property.address;
};

export const getAddressFromServiceLocationWithoutUnitNumber = (serviceLocation: ServiceLocation) => {
	if (!serviceLocation) return 'Error no property';
	return serviceLocation.address;
};

export const isTechniciansPersonalService = (technician: UserType, service: ServiceType) => {
	return service.type === EDIT_TYPE_SERVICE && service.photographer && service.photographer.id === technician.id;
};

export const generateLocationFromProperty = (property: PropertyType) => {
	if (!property) return;
	return {
		...property
	};
};

export const generateLocationFromSuggest = (suggest: Suggest) => {
	let serviceLocation: ServiceLocation = {} as ServiceLocation;
	let zipCode, streetNumber, streetName, city, state, country;

	const addressComponents = suggest.gmaps?.address_components;
	if (addressComponents) {
		for (let i = 0; i < addressComponents.length; i++) {
			let value = addressComponents[i];
			let typeArray = value.types;

			if (typeArray.includes('postal_code')) {
				zipCode = value.short_name;
			}
			if (typeArray.includes('street_number')) {
				streetNumber = value.short_name;
			}
			if (typeArray.includes('route')) {
				streetName = value.short_name;
			}
			if (typeArray.includes('locality')) {
				city = value.short_name;
			}
			if (typeArray.includes('administrative_area_level_1')) {
				state = value.short_name;
			}
			if (typeArray.includes('country')) {
				country = value.short_name;
			}
		}
	}

	serviceLocation.addressComponents = addressComponents;
	const { location } = suggest;
	const streetAddress = `${streetNumber ?? ''} ${streetName ?? ''}`;
	serviceLocation.address = `${streetAddress} ${city ? `${city},` : ''} ${state ?? ''}`;
	serviceLocation.alias = '';
	serviceLocation.city = city ?? '';
	serviceLocation.country = country ?? '';
	serviceLocation.googlePlaceId = suggest.gmaps?.place_id ?? '';
	serviceLocation.latitude = location?.lat;
	serviceLocation.longitude = location?.lng;
	serviceLocation.state = state ?? '';
	serviceLocation.streetAddress = streetAddress;
	serviceLocation.zipCode = zipCode ?? '';
	return serviceLocation;
};

export const generateLocationFromGeocoderResult = (geocoderResult: google.maps.GeocoderResult) => {
	let serviceLocation: ServiceLocation = {} as ServiceLocation;
	let zipCode, streetNumber, streetName, city, state, country;

	if (geocoderResult) {
		const { address_components: addressComponents } = geocoderResult;
		if (addressComponents) {
			for (let i = 0; i < addressComponents.length; i++) {
				let value = addressComponents[i];
				let typeArray = value.types;

				if (typeArray.includes('postal_code')) {
					zipCode = value.short_name;
				}
				if (typeArray.includes('street_number')) {
					streetNumber = value.short_name;
				}
				if (typeArray.includes('route')) {
					streetName = value.short_name;
				}
				if (typeArray.includes('locality')) {
					city = value.short_name;
				}
				if (typeArray.includes('administrative_area_level_1')) {
					state = value.short_name;
				}
				if (typeArray.includes('country')) {
					country = value.short_name;
				}
			}
		}
		serviceLocation.addressComponents = addressComponents;
	}

	const { location } = geocoderResult.geometry;
	//@ts-ignore
	const latLngLiteral = location as google.maps.LatLngLiteral;
	const formattedAddress = geocoderResult.formatted_address || '';
	const streetAddress = streetNumber && streetName ? `${streetNumber} ${streetName}` : formattedAddress;

	serviceLocation.address = `${streetAddress} ${city}, ${state}`;
	serviceLocation.alias = '';
	serviceLocation.city = city;
	serviceLocation.country = country;
	serviceLocation.googlePlaceId = geocoderResult.place_id;
	serviceLocation.latitude = latLngLiteral.lat;
	serviceLocation.longitude = latLngLiteral.lng;
	serviceLocation.state = state;
	serviceLocation.streetAddress = streetAddress;
	serviceLocation.zipCode = zipCode;

	return serviceLocation;
};

export const getNotesForBookableOptions = (bookableOptions: BookableOptionType[]) => {
	let notesArray: string[] = [];
	for (let i = 0; i < bookableOptions.length; i++) {
		let bookableOption = bookableOptions[i];
		if (bookableOption.requiredInfoAnswer) {
			if (bookableOption.requiredInfoAnswer.trim() !== '') {
				if (
					bookableOption.option.requiredInfo !== OPTION_ADDITIONAL_INFO_TYPES.quantity &&
					bookableOption.option.requiredInfo !== OPTION_ADDITIONAL_INFO_TYPES.none
				) {
					notesArray.push(bookableOption.option.name + ' - ' + bookableOption.requiredInfoAnswer);
				}
			}
		}
	}
	if (notesArray.length > 0) {
		return notesArray.join('\r\n\n');
	}
	return '';
};
