import React, { useEffect, useState } from 'react';
import './styles';
import * as strings from './strings';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserSelector, getSignedInUserSelector } from '../../../../../../../redux/selectors/CurrentUserSelectors';
import Queries from '../../../../../../../utils/API/Queries';
import { toast } from 'react-toastify';
import UIModal from '../../../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../../../components/UI/UIOverlayCard';
import UILabeledInput from '../../../../../../../components/UI/UILabeledInput';
import { UI_LABELED_INPUT_DROPDOWN_TYPE } from '../../../../../../../components/UI/UILabeledInput/config';
import UIIconButton from '../../../../../../../components/UI/UIIconButton';
import UIListInputRow from '../../../../../../../components/UI/UIListInputRow';
import UIHover from '../../../../../../../components/UI/UIHover/UIHover';
import { PlusIcon } from '../../../../../../../assets/icons';
import { getRegionIndividualOptions } from './helpers';
import { GET_ADDITIONAL_OPTIONS } from './queries';
import { getSelectedOptionSelector } from '../../../../../../../redux/selectors/OptionSelectors';
import { storeSelectedOption } from '../../../../../../../redux/store/options/actions';
import { CHILD_TYPE } from '../../../../../../../components/UI/UIListInputRow/config';
import { DropdownItem } from '../../../../../../../components/UI/UILabeledInput/types';
import { UI_ICON_BUTTON_CANCEL_TYPE } from '../../../../../../../components/UI/UIIconButton/config';

export default function AdditionalOptionModal({ fetchOption, additionalOptionIds }: AdditionalOptionModalProps) {
	const selectedOptionSelector = useSelector(getSelectedOptionSelector);

	const dispatch = useDispatch();
	const currentUser = useSelector(getCurrentUserSelector);
	const signedInUser = useSelector(getSignedInUserSelector);

	const [regions, setRegions] = useState<RegionType[]>([]);
	const [additionalOptions, setAdditionalOptions] = useState<AdditionalOptionType[]>([]);
	const [optionToRemove, setOptionToRemove] = useState<AdditionalOptionType | undefined>(undefined);
	const [showAdditionalOptionsOverlay, setShowAdditionalOptionsOverlay] = useState<boolean>(false);
	const [showRemoveAdditionalOptionsOverlay, setShowRemoveAdditionalOptionsOverlay] = useState<boolean>(false);
	const [selectedRegion, setSelectedRegion] = useState<RegionType>(currentUser.selectedRegion);
	const [selectedOption, setSelectedOption] = useState<OptionType | undefined>(undefined);

	const [updateOptionAdmin] = useMutation(Queries.UPDATE_OPTION_MUTATION);

	if (!selectedOptionSelector) return null;

	let variables = {
		q: { id: { op_in: additionalOptionIds ? additionalOptionIds.map((option) => option.id) : [] } },
		limit: 100
	};
	const [getOptionAdditionalOptions, { data: optionData }] = useLazyQuery(GET_ADDITIONAL_OPTIONS, {
		variables
	});

	const [getRegions, { data: regionsData }] = useLazyQuery(Queries.GET_REGIONS_WITH_QUERY, {
		variables: { q: { id: { op_in: signedInUser.regions?.map((region) => region.id) } } }
	});

	useEffect(() => {
		(async () => {
			await getOptionAdditionalOptions();
			await getRegions();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (optionData) {
				const fetchedOptions: OptionType[] = optionData['options'];
				setAdditionalOptions(fetchedOptions);
				let newOption: OptionType = selectedOptionSelector;
				newOption.additionalOptions = fetchedOptions;
				dispatch(storeSelectedOption({ ...selectedOptionSelector, ...newOption }));
			}
		})();
	}, [optionData]);

	useEffect(() => {
		(async () => {
			if (regionsData) {
				const fetchedRegions: RegionType[] = regionsData['regions'];
				setRegions(fetchedRegions);
			}
		})();
	}, [regionsData]);

	if (!optionData) return null;

	const removeAdditionalOptionMutation = async () => {
		let newAdditionalOptions = additionalOptions.filter((additionalOption) => additionalOption.id !== optionToRemove?.id);

		let variables = {
			id: selectedOptionSelector.id,
			additionalOptions: newAdditionalOptions.map((additionalOption) => additionalOption.id)
		};
		const response = await updateOptionAdmin({ variables }).catch((error) => {
			toast.error(strings.errorRemovingAdditional + error.message);
		});
		const { updateOption } = response['data'];
		if (updateOption) {
			await fetchOption();
			toast.success(`Successfully removed ${optionToRemove?.name}!`);
			setShowRemoveAdditionalOptionsOverlay(false);
			setOptionToRemove(undefined);
			setSelectedOption(undefined);
		} else {
			setShowRemoveAdditionalOptionsOverlay(false);
			setOptionToRemove(undefined);
			setSelectedOption(undefined);
		}
	};

	const renderRemoveAdditionalOptionsOverlay = () => {
		const hideModal = () => {
			setShowRemoveAdditionalOptionsOverlay(false);
		};

		return (
			<UIModal condition={showRemoveAdditionalOptionsOverlay}>
				<UIOverlayCard
					hideModal={hideModal}
					title={strings.removeAdditionalTitle}
					description={
						optionToRemove
							? `Are you sure you would like to remove ${optionToRemove.name} as an additional option from ${selectedOptionSelector.name}?`
							: undefined
					}>
					<UIIconButton dark type={UI_ICON_BUTTON_CANCEL_TYPE} style={{ marginTop: 16 }} text={'remove'} onClick={removeAdditionalOptionMutation} />
				</UIOverlayCard>
			</UIModal>
		);
	};

	const addAdditionalOption = async () => {
		let additionalOptionsNew: string[];
		if (!additionalOptions) {
			additionalOptionsNew = [];
		} else {
			additionalOptionsNew = additionalOptions.map((additionalOption) => additionalOption.id);
		}
		let variables = { id: selectedOptionSelector.id, additionalOptions: [...additionalOptionsNew, selectedOption?.id] };
		const response = await updateOptionAdmin({ variables }).catch((error) => {
			toast.error(strings.errorAddingAdditional + error.message);
		});
		const { updateOption } = response['data'];
		if (updateOption) {
			await fetchOption();
			setSelectedOption(undefined);
			setShowAdditionalOptionsOverlay(false);
		}
	};
	const renderAdditionalOptionsOverlay = () => {
		let regionsDropdown: DropdownItem[] = regions.map((region: any | undefined) => {
			return { label: region.name, value: region };
		});
		let regionOptions = getRegionIndividualOptions(regions.filter((region) => region.id === selectedRegion.id)[0]);

		let filteredRegionOptions = regionOptions.filter((option) => option.value.isActive === true);
		const hideModal = () => {
			setShowAdditionalOptionsOverlay(false);
		};

		return (
			<UIModal condition={showAdditionalOptionsOverlay}>
				<UIOverlayCard hideModal={hideModal} title={strings.assignAdditionalTitle} cardStyle={{ height: '100%' }}>
					<UILabeledInput
						type={UI_LABELED_INPUT_DROPDOWN_TYPE}
						label={strings.searchRegionLabel}
						containerStyle={{ marginTop: 16, width: 320 }}
						value={{ value: selectedRegion, label: selectedRegion.name }}
						options={regionsDropdown}
						onChangeDropdownValue={(value) => setSelectedRegion(value.value)}
						placeholder={strings.searchRegionPlaceholder}
					/>
					{regionOptions && regionOptions.length > 0 ? (
						<UILabeledInput
							type={UI_LABELED_INPUT_DROPDOWN_TYPE}
							label={strings.selectOptionLabel}
							containerStyle={{ marginTop: 8, width: 320 }}
							value={selectedOption ? { value: selectedOption, label: selectedOption.name } : undefined}
							options={filteredRegionOptions}
							onChangeDropdownValue={(value) => setSelectedOption(value.value)}
							placeholder={strings.selectOptionPlaceholder}
						/>
					) : undefined}
					<UIIconButton className='baseOverlayButton' text={strings.assignButton} onClick={addAdditionalOption} />
				</UIOverlayCard>
			</UIModal>
		);
	};

	return (
		<div className='additionalBaseModalContainer'>
			<UIListInputRow type={CHILD_TYPE}>
				<div className='additionalAndBaseHeader'>
					<p className='additionalAndBaseHeader--title'>{strings.additionalOptions}</p>
					<UIHover text={strings.hoverAdd}>
						<img
							className='additionalAndBaseHeader--label'
							src={PlusIcon.mint}
							alt={strings.addButton}
							onClick={() => setShowAdditionalOptionsOverlay(true)}
						/>
					</UIHover>
				</div>
				<div className='additionalAndBaseHeader__certs'>
					{additionalOptions ? (
						<div>
							{additionalOptions.length > 0 ? (
								additionalOptions.map((additionalOption: AdditionalOptionType) => {
									return (
										<p
											key={additionalOption.id}
											className='additionalAndBaseHeader--text'
											onClick={() => {
												setShowRemoveAdditionalOptionsOverlay(true);
												setOptionToRemove(additionalOption);
											}}>
											{additionalOption.name}
										</p>
									);
								})
							) : (
								<p className='additionalAndBaseHeader__certs--noCerts'>{strings.noAdditionalOptions}</p>
							)}
						</div>
					) : (
						<p className='additionalAndBaseHeader__certs--noCerts'>{strings.noAdditionalOptionsWarning}</p>
					)}
				</div>
			</UIListInputRow>
			{renderAdditionalOptionsOverlay()}
			{renderRemoveAdditionalOptionsOverlay()}
		</div>
	);
}
