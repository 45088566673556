import gql from 'graphql-tag';

export const GET_SERVICE_WITH_TEAM_MEMBERS = gql`
	query GetServiceWithAdditionalContacts($id: String!) {
		service(id: $id) {
			id
			additionalAgentContacts
		}
	}
`;

export const UPDATE_SERVICE_TEAM_MEMBER = gql`
	mutation UpdateServiceTeamMember($serviceId: String!, $additionalAgentContacts: [JSON]!) {
		updateService(id: $serviceId, additionalAgentContacts: $additionalAgentContacts) {
			id
			additionalAgentContacts
		}
	}
`;
