// Created by carsonaberle at 3/30/22
import styled from 'styled-components';

export const div_VTErrorContainer = styled.div`
	//DEFAULT
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_VTErrorTitle = styled.p`
	//DEFAULT
	font-size: 24pt;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
