// Created by carsonaberle at 3/29/22
import styled from 'styled-components';
import UIIconButton from '../../../../../../components/UI/UIIconButton';

export const div_VTHeaderContainer = styled.div`
	//DEFAULT
	flex-direction: row;
	width: 100%;
	max-width: 100%;
	padding: 12px;
	max-height: 240px;
	overflow-y: scroll;
	background-color: white;
	align-items: center;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 36px 48px 24px 48px;
		background-color: unset;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 48px 48px 24px 48px;
		background-color: unset;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTHeaderRight = styled.div`
	//DEFAULT
	justify-content: center;
	align-items: flex-end;
`;

export const div_VTHeaderProperty = styled.div`
	//DEFAULT
	max-height: 100%;
	max-width: 100%;
	height: 100%;
	width: 100%;
	overflow-y: scroll;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_VTHeaderPropertyAlias = styled.p`
	//DEFAULT
	color: #4c4c4c;
	font-size: 12pt;
	font-family: BrownRegular, sans-serif;
	margin-bottom: 6px;
	-webkit-animation: fadeIn 500ms ease-in !important;
	animation: fadeIn 500ms ease-in !important;

	//SKINNY
	@media only screen and (max-width: 360px) {
		font-size: 10pt;
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		font-size: 18pt;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		font-size: 20pt;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_VTHeaderPropertyStreetAddress = styled.p`
	//DEFAULT
	color: #4c4c4c;
	font-size: 12pt;
	font-family: BrownRegular, sans-serif;
	margin-bottom: 6px;
	-webkit-animation: fadeIn 500ms ease-in !important;
	animation: fadeIn 500ms ease-in !important;

	//SKINNY
	@media only screen and (max-width: 360px) {
		font-size: 10pt;
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		font-size: 18pt;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		font-size: 20pt;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_VTHeaderPropertyCityStateZip = styled.p`
	//DEFAULT
	color: #121212;
	font-size: 12pt;
	font-family: BrownBold, sans-serif;
	max-width: 100%;
	-webkit-animation: fadeIn 500ms ease-in !important;
	animation: fadeIn 500ms ease-in !important;

	//SKINNY
	@media only screen and (max-width: 360px) {
		font-size: 10pt;
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		font-size: 18pt;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		font-size: 20pt;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_VTAskingPrice = styled.p`
	//DEFAULT
	color: #121212;
	font-size: 12pt;
	font-family: BrownBold, sans-serif;
	-webkit-animation: fadeIn 500ms ease-in !important;
	animation: fadeIn 500ms ease-in !important;

	//SKINNY
	@media only screen and (max-width: 360px) {
		font-size: 10pt;
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		font-size: 18pt;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		font-size: 20pt;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_VTArea = styled.p`
	//DEFAULT
	color: var(--dark-grey);
	font-size: 16pt;
	font-weight: 400;
	margin-top: 6px;
	-webkit-animation: fadeIn 500ms ease-in !important;
	animation: fadeIn 500ms ease-in !important;
`;

export const UIIconButton_VTHeaderDownload = styled(UIIconButton)`
	//DEFAULT
	display: none;
	-webkit-animation: fadeIn 500ms ease-in !important;
	animation: fadeIn 500ms ease-in !important;
	margin-left: 36px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: flex;
		margin-left: 36px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		margin-left: 48px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
