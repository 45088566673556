import './default.scss';
import styled from 'styled-components';

export const PLControlSidebarContainer = styled.div`
	//DEFAULT
	width: 100%;
	height: 100%;
	max-height: 100%;
	position: fixed;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const PLControlSidebarOverlay = styled.div`
	//DEFAULT
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	cursor: pointer;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const PLControlSidebarContent = styled.div`
	//DEFAULT
	position: fixed;
	top: 0;
	right: 0;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
