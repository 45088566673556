import gql from 'graphql-tag';

export const GET_PL_SERVICE_RAW_FILES = gql`
	query GetServiceRawFiles($query: JSON!, $limit: Int) {
		files(query: $query, limit: $limit) {
			id
			isActive
			createdAt
			updatedAt
			type
			filename
			index
			status
			isVisible
			watermarkUrl
			thumbnailUrl
			originalUrl
			printUrl
			allowFixRequest
			webUrl
			metadata
			tags
		}
	}
`;

export const GET_PL_SERVICE_RAW_FILES_SERVICE = gql`
	query GetServiceRawFilesService($serviceId: String!) {
		service(id: $serviceId) {
			id
			dropboxFileRequestId
			dropboxRawsLink
			bookedOptions {
				id
				name
				price
				quantity
				option {
					id
					photographerFileCount
				}
			}
		}
	}
`;

export const UPDATE_SERVICE_STATUS_FOR_RAWS = gql`
	mutation UpdateServiceStatusForRaws($serviceId: String!, $status: String!) {
		updateService(id: $serviceId, status: $status) {
			id
			status
		}
	}
`;
