import gql from 'graphql-tag';

export const GET_SERVICE_OPTIONS_SERVICE = gql`
	query service($id: String!) {
		service(id: $id) {
			id
			photographer {
				id
				isInternalTech
			}
			agent {
				id
			}
			surgeBoost
			orderInformation
			transactions {
				id
			}
			client {
				id
			}
		}
	}
`;
