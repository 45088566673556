import React, { useEffect, useMemo } from 'react';
import { ZendeskAPI } from 'react-zendesk';
import Select, { StylesConfig } from 'react-select';
import * as S from './styles';

export default function UIDropdownSelect({ selectedTab, containerClassName, tabs, updateSelected }: UIDropdownSelectProps) {
	const onSelectTab = (selectedTab: PLTab) => {
		if (!selectedTab) return;
		document.getElementById(selectedTab.value)?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
		updateSelected(selectedTab);
	};

	useEffect(() => {
		onSelectTab(selectedTab);
	}, [selectedTab]);

	if (!selectedTab) return null;

	if (!selectedTab) {
		return <div key={JSON.stringify(selectedTab)} />;
	}

	const colourStyles: StylesConfig = {
		container: (styles) => {
			return {
				...styles,
				width: '100%',
				maxWidth: '100%',
				position: 'relative'
			};
		},
		valueContainer: (styles) => ({ ...styles, padding: '2px 12px', flex: 1 }),
		singleValue: (styles) => ({ ...styles, fontFamily: 'BrownBold', fontWeight: 500, color: 'white', marginTop: 2, fontSize: '15pt' }),
		control: (styles) => ({
			...styles,
			flexDirection: 'row',
			borderRadius: 12,
			backgroundColor: '#FFFFFF12',
			borderColor: '#ffffff66',
			borderWidth: 2,
			cursor: 'pointer',
			padding: '3px 9px !important',
			minHeight: 60,
			width: '100%'
		}),
		dropdownIndicator: (styles) => ({ ...styles, backgroundColor: 'transparent' }),
		indicatorSeparator: (styles) => ({ ...styles, width: 2, borderRadius: 2, backgroundColor: '#ffffff66', marginRight: 12 }),
		indicatorsContainer: (styles) => ({ ...styles, flexDirection: 'row', justifyContent: 'flex-start', minWidth: 50 }),
		menuList: (styles) => ({ ...styles, backgroundColor: '#f1f1f1', padding: '0 8px 12px 8px', borderRadius: 12 }),
		option: (styles) => {
			return {
				...styles,
				color: '#121212',
				flexDirection: 'row',
				cursor: 'pointer',
				marginTop: 12,
				borderRadius: 6,
				padding: 18,
				fontSize: '14pt'
			};
		},
		menu: (styles) => {
			return {
				...styles,
				borderRadius: 6
			};
		}
	};

	const options = useMemo(() => tabs.map((tab) => ({ ...tab, label: tab.text })), []);

	if (options.length === 1) {
		const tab = options[0];
		return (
			<div className={`tab ${containerClassName}`}>
				<S.p_SingleOptionTitle>{tab.label}</S.p_SingleOptionTitle>
			</div>
		);
	}
	return (
		<div className={`tab ${containerClassName}`}>
			<Select
				styles={colourStyles}
				isSearchable={false}
				options={options}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					colors: {
						...theme.colors,
						primary25: '#4c4c4c44',
						primary: 'var(--light-grey)'
					}
				})}
				value={{ ...selectedTab, label: selectedTab.text }}
				onChange={updateSelected}
			/>
		</div>
	);
}
