import initialState from '../initialState';
import * as types from './types';

export default function userReducer(state = initialState.currentUser, action) {
	switch (action.type) {
		case types.SET_SUPER_USER:
			return {
				...state,
				superUser: action.superUser
			};
		case types.SET_SUPER_USER_RETURN_ROUTE:
			return {
				...state,
				superUserReturnRoute: action.superUserReturnRoute
			};
		case types.SET_SIGNED_IN_USER:
			return {
				...state,
				user: action.user
			};
		case types.SET_SELECTED_REGION:
			return {
				...state,
				selectedRegion: action.selectedRegion
			};
		case types.SET_REGIONS:
			return {
				...state,
				regions: action.regions
			};
		case types.SHOW_LOGIN:
			return {
				...state,
				displayLogin: action.displayLogin,
				onLoginComplete: action.onLoginComplete,
				preFilledEmail: action.preFilledEmail
			};
		case types.SHOW_SIGNUP:
			return {
				...state,
				displaySignup: action.displaySignup,
				onSignupComplete: action.onSignupComplete
			};
		case types.SHOW_OPTION:
			return {
				...state,
				optionId: action.optionId
			};
		default:
			return state;
	}
}
