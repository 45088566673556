import gql from 'graphql-tag';

export const GET_PL_SERVICE_RAW_VIDEOS = gql`
	query GetServiceRawVideos($query: JSON!, $limit: Int) {
		files(query: $query, limit: $limit) {
			id
			isActive
			createdAt
			updatedAt
			type
			filename
			index
			status
			isVisible
			watermarkUrl
			thumbnailUrl
			originalUrl
			printUrl
			allowFixRequest
			webUrl
			metadata
			tags
		}
	}
`;

export const UPDATE_SERVICE_STATUS_FOR_RAW_VIDEOS = gql`
	mutation UpdateServiceStatusForRawVideos($serviceId: String!, $status: String!) {
		updateService(id: $serviceId, status: $status) {
			id
			status
		}
	}
`;
