import gql from 'graphql-tag';

export const GET_PL_SERVICE_SERVICE = gql`
	query service($id: String!) {
		service(id: $id) {
			id
			status
			property {
				id
			}
			listing {
				id
			}
			client {
				id
			}
			type
			agent {
				id
			}
			photographer {
				id
			}
			serviceRequests {
				id
				isActive
				editor {
					id
				}
			}
		}
	}
`;
