// noinspection JSConstantReassignment

import React from 'react';
import './styles';
import '../../../assets/styles/ExternalPackagesStyles/AutoComplete/auto-complete.scss';
import Select from 'react-select';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';
import Lottie from 'react-lottie';
import { LottieAnimations } from '../../../assets/icons';
import UIHover from '../UIHover/UIHover';
import { SCREEN_TYPE } from '../../../utils/Config/Constants';
import UIIconButton from '../UIIconButton';
import {
	UI_LABELED_INPUT_BUTTON_TYPE,
	UI_LABELED_INPUT_CURRENCY_STRING_TYPE,
	UI_LABELED_INPUT_CURRENCY_TYPE,
	UI_LABELED_INPUT_DATE_STATIC_TYPE,
	UI_LABELED_INPUT_DROPDOWN_TYPE,
	UI_LABELED_INPUT_INVITE_CODE_TYPE,
	UI_LABELED_INPUT_LINK_NOT_CLICKABLE_TYPE,
	UI_LABELED_INPUT_LINK_TYPE,
	UI_LABELED_INPUT_LIVE_LINK_TYPE,
	UI_LABELED_INPUT_NOTES_TYPE,
	UI_LABELED_INPUT_NUMBER_TYPE,
	UI_LABELED_INPUT_PHONE_NUMBER_TYPE,
	UI_LABELED_INPUT_SEARCH_TYPE,
	UI_LABELED_INPUT_STATIC_TYPE,
	UI_LABELED_INPUT_TEXT_FIELD,
	UI_LABELED_INPUT_TEXT_TYPE
} from './config';
import { getScreenTypeSelector } from '../../../redux/selectors/ControlSelectors';
import { PLLottieProps } from '../../../pages/portal/global/IndividualService/FullscreenFiles/interfaces';
import { UILabeledInputProps } from './types';
import { UI_ICON_BUTTON_FILL_TYPE, UI_ICON_BUTTON_OUTLINE_TYPE } from '../UIIconButton/config';

export default function UILabeledInput({
	key,
	value,
	onChangeValue,
	onChangeDropdownValue,
	onChangeNumberValue,
	filterKey,
	noOptionsMessage,
	options,
	style,
	containerStyle,
	buttonType,
	id,
	prefix,
	allowEdit,
	label,
	labelClassName,
	edit,
	isLoading,
	onSearch,
	description,
	helpText,
	password,
	onKeyPress,
	noFormatting,
	onSave,
	savedValue,
	numeralPositiveOnly,
	emptySet,
	timezone,
	searchParams,
	linkUrl,
	placeholder,
	containerClassName,
	className,
	onClick,
	type,
	valueStyle,
	inputRef
}: UILabeledInputProps) {
	const renderInput = () => {
		const screenType = useSelector(getScreenTypeSelector);

		let input;

		const setRef = (hRef) => {
			if (inputRef) {
				// @ts-ignore
				inputRef.current = hRef;
			}
		};

		switch (type) {
			case UI_LABELED_INPUT_DROPDOWN_TYPE:
				if (value && options) {
					//Standard value = { label: "label", value: "value" }
					//Default if value is an object and not the standard value format
					if (!value.value && !value.label) {
						if (filterKey) {
							//Pass in a filter key so that we can replace the incorrectly formatted value with the correct one
							//which was passed in the options prop. filterKey tells us how to determine which objects are the same
							//Replace object value with standard format from the options given by comparing a specific "filterKey".
							value = options.filter((option) => option.value[filterKey] === value[filterKey])[0];
						} else {
							//Replace object value with standard format by general comparison for basic variable types
							value = options.filter((option) => option.value === value)[0];
						}
					}
				}
				input = (
					<div style={style} className='uiLabeledInput__dropdown'>
						<Select
							id={id}
							key={key}
							ref={inputRef}
							noOptionsMessage={() => noOptionsMessage}
							options={options}
							isSearchable={screenType !== SCREEN_TYPE.mobile}
							classNamePrefix='dropdown'
							className={`dropdown__wrapper ${className}`}
							onChange={onChangeDropdownValue}
							value={value}
							placeholder={placeholder}
						/>
						{renderLoadingComponent()}
					</div>
				);
				break;
			case UI_LABELED_INPUT_TEXT_FIELD:
				input = (
					<div style={{ display: 'flex', height: '100%', minHeight: 128, flexDirection: 'row', width: '100%' }} onClick={(e) => e.stopPropagation()}>
						<textarea
							id={id}
							//@ts-ignore
							ref={inputRef}
							onChange={(e) => {
								if (onChangeValue) {
									onChangeValue(e.target.value);
								}
							}}
							value={value}
							rows={3}
							cols={50}
							style={style}
							className={`label_input_input_field ${className}`}
							name='Text Field'
							placeholder={placeholder}
						/>
					</div>
				);

				break;
			case UI_LABELED_INPUT_DATE_STATIC_TYPE:
				input = (
					<div id={id} className='dateLabel' style={valueStyle}>
						<p className='label_input_static dateLabel__top'>
							{moment(value)
								.tz(timezone || '')
								.format('dddd MMMM D, YYYY')}
						</p>
						<p className='label_input_static dateLabel__bottom'>
							{moment(value)
								.tz(timezone || '')
								.format('h:mm a z')}
						</p>
					</div>
				);
				break;
			case UI_LABELED_INPUT_BUTTON_TYPE:
				input = <UIIconButton id={id} dark text={value} isLoading={isLoading} type={buttonType ? buttonType : UI_ICON_BUTTON_OUTLINE_TYPE} />;
				break;
			case UI_LABELED_INPUT_STATIC_TYPE:
				input = (
					<p
						id={id}
						style={value === 'NOT ENTERED' ? { ...valueStyle, color: 'var(--red)' } : valueStyle}
						className={`label_input_static--label orderSummary ${className}`}>
						{value}
					</p>
				);
				break;
			case UI_LABELED_INPUT_LINK_TYPE:
				input = (
					<a id={id} rel='noopener noreferrer' href={linkUrl} target='_blank'>
						<p className={`label_input_static_link--label ${className}`}>{value}</p>
					</a>
				);
				break;
			case UI_LABELED_INPUT_LIVE_LINK_TYPE:
				input = (
					<a id={id} className='mobileCard__address' rel='noopener noreferrer' href={linkUrl} target='_blank'>
						<p className={`mobileCard__address--label ${className}`}>{value}</p>
					</a>
				);
				break;
			case UI_LABELED_INPUT_LINK_NOT_CLICKABLE_TYPE:
				input = (
					<a id={id} rel='noopener noreferrer' href={linkUrl} target='_blank'>
						<p className={`label_non-clickable_link ${className}`}>{value}</p>
					</a>
				);
				break;
			case UI_LABELED_INPUT_NOTES_TYPE:
				input = (
					<p id={id} className={`label_input_static ${className}`}>
						{emptySet ? emptySet : 'no notes yet...'}
					</p>
				);
				if (value) {
					if (value.trim() !== '') {
						input = (
							<p
								id={id}
								style={{
									margin: 0,
									wordWrap: 'normal',
									whiteSpace: 'pre-wrap',
									color: 'var(--light-grey)'
								}}>
								{value.trim()}
							</p>
						);
					}
				}
				break;
			case UI_LABELED_INPUT_SEARCH_TYPE:
				input = (
					<input
						className={`label-input-search-bar ${className}`}
						id={id}
						//@ts-ignore
						ref={inputRef}
						placeholder={placeholder}
						onKeyPress={(ev) => {
							if (ev.key === 'Enter' && onSearch) {
								onSearch();
							}
						}}
						onChange={(e) => {
							if (onChangeValue) {
								onChangeValue(e.target.value);
							}
						}}
						value={searchParams}
					/>
				);

				break;
			case UI_LABELED_INPUT_CURRENCY_STRING_TYPE:
				input = (
					<p id={id} className='label_input_static'>
						${value.toLocaleString()}
					</p>
				);
				break;
			case UI_LABELED_INPUT_CURRENCY_TYPE:
				input = (
					<Cleave
						id={id}
						key={prefix}
						// @ts-ignore https://github.com/nosir/cleave.js/issues/497
						htmlRef={setRef}
						style={style}
						options={{
							rawValueTrimPrefix: true,
							numeral: true,
							prefix: prefix,
							numeralDecimalScale: 2,
							numericOnly: true
						}}
						className={`label_input_input ${className}`}
						placeholder={placeholder}
						onChange={(e) => {
							if (onChangeNumberValue) {
								onChangeNumberValue(e.target.rawValue);
							}
						}}
						value={value !== undefined && value !== null ? value : ''}
					/>
				);
				break;
			case UI_LABELED_INPUT_PHONE_NUMBER_TYPE:
				input = (
					<Cleave
						id={id}
						htmlRef={setRef}
						style={style}
						options={{ delimiter: '-', phone: true, phoneRegionCode: 'US' }}
						className={`label_input_input ${className}`}
						placeholder={placeholder}
						onChange={(e) => {
							if (onChangeValue) onChangeValue(e.target.rawValue);
						}}
						value={value}
					/>
				);
				break;
			case UI_LABELED_INPUT_NUMBER_TYPE:
				input = (
					<Cleave
						id={id}
						htmlRef={setRef}
						options={{
							stripLeadingZeroes: !noFormatting,
							delimiter: noFormatting ? '' : undefined,
							numeral: true,
							numeralPositiveOnly: numeralPositiveOnly
						}}
						style={style}
						className={`label_input_input ${className}`}
						placeholder={placeholder}
						onChange={(e) => {
							if (onChangeNumberValue) {
								onChangeNumberValue(parseFloat(e.target.rawValue));
							} else if (onChangeValue) {
								onChangeValue(e.target.rawValue);
							}
						}}
						value={value ? value : undefined}
					/>
				);
				break;

			case UI_LABELED_INPUT_INVITE_CODE_TYPE:
				input = (
					<Cleave
						id={id}
						htmlRef={setRef}
						options={{}}
						className={`label_input_input ${className}`}
						placeholder={placeholder}
						onChange={(e) => {
							if (onChangeValue) onChangeValue(e.target.rawValue.toLowerCase());
						}}
						value={value}
					/>
				);
				break;
			case UI_LABELED_INPUT_TEXT_TYPE:
			default:
				input = (
					<input
						id={id}
						//@ts-ignore
						ref={inputRef}
						autoComplete='off'
						onKeyPress={onKeyPress}
						onChange={(e) => {
							if (onChangeValue) {
								onChangeValue(e.target.value);
							}
						}}
						style={style}
						value={value ? value : ''}
						className={`label_input_input ${className}`}
						type={password ? 'password' : type}
						name='Name'
						placeholder={placeholder}
					/>
				);
				break;
		}
		return input;
	};
	const renderLoadingComponent = () => {
		if (!isLoading) {
			return undefined;
		}
		const lottieProps: PLLottieProps = {
			loop: true,
			autoplay: true,
			animationData: {},
			path: LottieAnimations.loadingCircle,
			rendererSettings: {
				preserveAspectRatio: 'xMidYMid slice'
			}
		};

		return (
			<div key={`${isLoading}${key}`} className='uiLabeledInput__dropdown--loading' style={{ width: 'fit-content', display: 'table' }}>
				<Lottie options={lottieProps} height={32} width={32} isStopped={false} isPaused={false} />
			</div>
		);
	};

	return (
		<div
			className={`label_input_container ${containerClassName}`}
			onClick={onClick}
			key={`${type}${key}${savedValue}`}
			style={{ ...containerStyle, cursor: onClick ? 'pointer' : undefined }}>
			<div>
				{(helpText || label || allowEdit || edit) && (
					<div className='uiLabeledInput__header'>
						{helpText ? (
							<UIHover text={helpText}>{label ? <p className={`listInput__input--label ${labelClassName}`}>{label}</p> : undefined}</UIHover>
						) : label ? (
							<p className={`listInput__input--label ${labelClassName}`}>{label}</p>
						) : undefined}
						{allowEdit && edit && (
							<p className='label_input_edit' onClick={edit}>
								edit
							</p>
						)}
					</div>
				)}
				{description && <p className='label_input--description'>{description}</p>}
			</div>
			<div className='uiLabeledInput__btns' key={key}>
				{renderInput()}
				{onSave && savedValue !== value ? (
					<UIIconButton
						dark
						className='animated-div'
						isLoading={isLoading}
						style={{ marginLeft: 16 }}
						text={'save'}
						type={UI_ICON_BUTTON_FILL_TYPE}
						onClick={onSave}
					/>
				) : undefined}
			</div>
		</div>
	);
}
