// Created by carsonaberle at 3/29/22
import React, { useEffect } from 'react';
import * as S from './styles';
import * as strings from './strings';
import { VTFullscreenBackIcon, VTFullscreenForwardIcon } from './config';
import { useSelector } from 'react-redux';
import { getScreenTypeSelector } from '../../../../../redux/selectors/ControlSelectors';
import { SCREEN_TYPE } from '../../../../../utils/Config/Constants';

export default function VTFullscreen({ expandedFileIndex, setExpandedFileIndex, files }: VTFullscreenProps) {
	if (expandedFileIndex === undefined || expandedFileIndex === null) return null;

	const screenType = useSelector(getScreenTypeSelector);

	const isLastIndexInFiles = (indexToCheck) => indexToCheck === files.length - 1;

	const closeFullscreen = () => setExpandedFileIndex(undefined);

	const preloadNextImage = (fromIndex: number) => {
		let nextSelectedIndex = fromIndex + 1;
		if (isLastIndexInFiles(nextSelectedIndex)) {
			return;
		}
		const img = new Image();
		img.src = files[nextSelectedIndex].webUrl ?? '';
	};

	const preloadPreviousImage = (fromIndex: number) => {
		let nextSelectedIndex = fromIndex - 1;
		if (nextSelectedIndex < 1) {
			return;
		}
		const img = new Image();
		img.src = files[nextSelectedIndex].webUrl ?? '';
	};

	const _handleKeyDown = (event) => {
		switch (event.keyCode) {
			//esc key
			case 27:
				closeFullscreen();
				break;
			//right arrow
			case 39:
				goForward();
				break;
			//left arrow
			case 37:
				goBack();
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', _handleKeyDown, { passive: true });
		return () => {
			document.removeEventListener('keydown', _handleKeyDown);
		};
	});

	useEffect(() => {
		document.getElementById(`#image-${expandedFileIndex}`)?.scrollIntoView({ block: 'center', inline: 'center' });
	}, [expandedFileIndex]);

	const goBack = () => {
		if (expandedFileIndex === null || expandedFileIndex === undefined) return;

		let newIndex = expandedFileIndex - 1;
		if (newIndex < 0) {
			return;
		}
		// document.getElementById('#fullscreen-mobile-container')?.scrollBy({ left: -100, top: 0, behavior: 'smooth' });
		setExpandedFileIndex(newIndex);
		preloadPreviousImage(newIndex);
	};

	const goForward = () => {
		if (expandedFileIndex === null || expandedFileIndex === undefined) return;
		let newIndex = expandedFileIndex + 1;
		if (newIndex === files.length) {
			return;
		}
		// document.getElementById('#fullscreen-mobile-container')?.scrollBy({ left: 100, top: 0, behavior: 'smooth' });
		setExpandedFileIndex(newIndex);
		preloadNextImage(newIndex);
	};

	const renderBackButton = () => {
		if (expandedFileIndex === 0) {
			return <S.div_VTFullscreenControlIconBlank />;
		}
		return <S.img_VTFullscreenBack alt={strings.back} src={VTFullscreenBackIcon} onClick={goBack} />;
	};

	const renderForwardButton = () => {
		if (isLastIndexInFiles(expandedFileIndex)) return <S.div_VTFullscreenControlIconBlank />;
		return <S.img_VTFullscreenForward alt={strings.forward} src={VTFullscreenForwardIcon} onClick={goForward} />;
	};

	const expandedFile = files[expandedFileIndex];

	const fileIndexString = `${expandedFileIndex + 1}/${files.length}`;

	const renderContent = () => {
		if (screenType !== SCREEN_TYPE.mobile) {
			return (
				<S.div_VTFullscreenSlideshowContent>
					<S.img_VTFullscreenSlideshowImage key={expandedFile.webUrl} alt={expandedFile.filename} src={expandedFile.webUrl} />
				</S.div_VTFullscreenSlideshowContent>
			);
		}
		return (
			<S.div_VTFullscreenMobileContent>
				{files.map((file, index) => {
					return (
						<S.div_VTFullscreenMobileImageWrapper id={`#image-${index}`}>
							<S.img_VTFullscreenMobileImage src={file.webUrl} />
						</S.div_VTFullscreenMobileImageWrapper>
					);
				})}
			</S.div_VTFullscreenMobileContent>
		);
	};
	return (
		<S.div_VTFullscreenContainer>
			<S.p_VTFullscreenClose onClick={closeFullscreen}>
				<S.span_VTFullscreenClose>{strings.esc}</S.span_VTFullscreenClose>
				{strings.close}
			</S.p_VTFullscreenClose>
			<S.p_VTFullscreenIndex>{fileIndexString}</S.p_VTFullscreenIndex>
			<S.div_VTFullscreenSlideshow>
				{renderBackButton()}
				{renderContent()}
				{renderForwardButton()}
			</S.div_VTFullscreenSlideshow>
		</S.div_VTFullscreenContainer>
	);
}
