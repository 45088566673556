// Created by carsonaberle at 4/9/22
import styled from 'styled-components';
import UIIcon from '../../../../../../components/UI/UIIcon';
import { ContextMenu, MenuItem } from 'react-contextmenu';

export const div_QuickLoginDevContainer = styled.div`
	//DEFAULT
	justify-content: center;
	height: 40px;
	width: 40px;
	margin-left: 12px;
	cursor: pointer;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIIcon_QuickLoginDevArrow = styled(UIIcon)`
	//DEFAULT
	width: 32px;
	height: 32px;
	border-radius: 4px;
	background-color: var(--white-4);
	padding: 8px;
	cursor: pointer;
	object-fit: contain;
	transition: 200ms all ease-out;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	user-select: none;
	pointer-events: none;

	&:hover {
		transform: translateY(-1px);
	}

	&:active {
		transform: translateY(1px);
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MenuItem_QuickLoginDevMenuItem = styled(MenuItem)<{ key: string; onClick: () => void; children: ReactNode }>`
	//DEFAULT
	padding: 8px !important;
	margin: 4px 0 !important;
	border-radius: 4px !important;
	font-size: 11pt;
	cursor: pointer;
	text-align: center;

	&:hover {
		background-color: var(--white-8);
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MenuItem_QuickLoginDevContextMenu = styled(ContextMenu)<{ children: ReactNode; id: string }>`
	//DEFAULT
	background-color: var(--white-12-solid);
	padding: 8px;
	z-index: 5;
	border-radius: 8px;
	border: var(--white-2) solid 1px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
