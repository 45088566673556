// Created by carsonaberle at 11/24/21
export const cardTitle = 'Your photos require proofing';
export const whyAmISeeingThisTitle = 'Why am I seeing this?';
export const whyAmISeeingThisDescription = 'You are required to complete proofing because we edited more photos than you had originally purchased.';
export const whatNowTitle = 'What do I do now?';
export const whatNowDescriptionEdit =
	'We have automatically added all the extra photos to your cart. You are required to purchase the extras before receiving your content.  From there you can sort your photos, download them, share them with your client, add virtual staging, and much more.';
export const whatNowDescriptionPhotoshoot =
	'We have automatically added all the extra photos to your cart. You can purchase any extras at our discounted rate, or remove any from your cart. Once you have discarded the photos you can simply finalize your order to access your content. From there you can sort your photos, download them, add virtual staging, and much more.';
export const laterPurchaseTitle = 'Can I purchase the photos I remove from my cart at a later date?';
export const laterPurchaseDescription = 'Yep, we keep all your files stored and you will be able to view and purchase them at any time in the future.';
export const helpTitle = 'Still need help?';
export const chatNow = 'chat with us';
