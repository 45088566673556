// noinspection SpellCheckingInspection

import moment from 'moment-timezone';
import { nearestPastMinutes } from './GeneralHelpers';
import { getPromosForOptions } from './PromoHelpers';
import {
	EDIT_TYPE_SERVICE,
	PHOTOSHOOT_TYPE_SERVICE,
	STATUS_FINDING_PREFERRED_TECHNICIAN,
	STATUS_FINDING_TECHNICIAN,
	STATUS_TECHNICIAN_UPLOAD,
	USER_ROLE_AGENT,
	USER_ROLE_PHOTOGRAPHER
} from '../Config/Constants';
import { getNotesForBookableOptions } from './ServiceHelper';
import { areMediaOptionsSelected } from '../../pages/portal/global/BookingManager/config';
import { BookingConfig } from '../../pages/portal/global/BookingManager/types';
import { getSunset } from 'sunrise-sunset-js';

export const getMapImageFromLocation = (serviceLocation: ServiceLocation) => {
	if (serviceLocation.latitude && serviceLocation.longitude) {
		const zoomLevel = 12;
		const resolution = '128X128';
		const mapboxStyle = 'carsonaberle/ck0fsz2rn022i1cof1qlokom2';
		return `https://api.mapbox.com/styles/v1/${mapboxStyle}/static/pin-m+000(${serviceLocation.longitude},${serviceLocation.latitude})/${serviceLocation.longitude},${serviceLocation.latitude},${zoomLevel},0.00/${resolution}?access_token=${process.env.MAPBOX_PUBLIC_KEY}`;
	}
	return;
};

export const getMapImageFromProperty = (property: PropertyType) => {
	if (property.latitude && property.longitude) {
		const zoomLevel = 12;
		const resolution = '128X128';
		const mapboxStyle = 'carsonaberle/ck0fsz2rn022i1cof1qlokom2';
		return `https://api.mapbox.com/styles/v1/${mapboxStyle}/static/pin-m+000(${property.longitude},${property.latitude})/${property.longitude},${property.latitude},${zoomLevel},0.00/${resolution}?access_token=${process.env.MAPBOX_PUBLIC_KEY}`;
	}
	return;
};

export const getMapPropertyWebsiteFromLocation = (serviceLocation: ServiceLocation, width: number, height: number) => {
	if (serviceLocation.latitude && serviceLocation.longitude) {
		const zoomLevel = 12;
		const resolution = `${width}X${height}`;
		const mapboxStyle = 'carsonaberle/ck0fsz2rn022i1cof1qlokom2';
		return `https://api.mapbox.com/styles/v1/${mapboxStyle}/static/pin-m+000(${serviceLocation.longitude},${serviceLocation.latitude})/${serviceLocation.longitude},${serviceLocation.latitude},${zoomLevel},0.00/${resolution}@2x?access_token=${process.env.MAPBOX_PUBLIC_KEY}`;
	}
	return;
};

export const arePointsNear = (checkPoint, centerPoint, radius: number) => {
	const ky = 40000 / 360;
	const kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
	const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
	const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
	return Math.sqrt(dx * dx + dy * dy) <= radius;
};

export const getOptionFromBookableOptionsById = (bookableOptions: BookableOptionType[], id: string): BookableOptionType => {
	const filteredOptions: BookableOptionType[] = bookableOptions.filter((bookableOption) => bookableOption.option.id === id);
	return filteredOptions[0];
};

export const getAvailableAddOns = (bookingConfig: BookingConfig): { availableOptions: BookableOptionType[]; unavailableOptions: BookableOptionType[] } => {
	const { serviceProvider, selectedOptions } = bookingConfig;

	let availableBookableOptions: BookableOptionType[] = [];
	let unavailableBookableOptions: BookableOptionType[] = [];

	let photographerCertifications: string[] = [];

	if (serviceProvider && serviceProvider.certificates) {
		photographerCertifications = serviceProvider.certificates;
	}

	const selectedOptionsWithAdditionalOptions =
		selectedOptions?.filter((bookableOption) => bookableOption.additionalBookableOptions && bookableOption.additionalBookableOptions.length > 0) || [];

	const availableBookableOptionsPool = selectedOptionsWithAdditionalOptions.map((bookableOption) => bookableOption.additionalBookableOptions!).flat(2);

	for (let availableBookableOption of availableBookableOptionsPool) {
		let sameSelectedOption = getOptionFromBookableOptionsById(selectedOptions, availableBookableOption.option.id);
		if (sameSelectedOption) {
			availableBookableOption = sameSelectedOption;
		}
		const availableOption: OptionType = availableBookableOption.option;
		const { photographerCertificatesRequired } = availableOption;
		if (!photographerCertificatesRequired || photographerCertificatesRequired.length === 0) {
			//Automatically add to available because it requires no certificates
			availableBookableOptions.push(availableBookableOption);
		} else {
			if (photographerCertificatesRequired) {
				for (let certificateRequired of photographerCertificatesRequired) {
					if (serviceProvider && photographerCertifications.indexOf(certificateRequired) === -1) {
						unavailableBookableOptions.push(availableBookableOption);
					} else {
						availableBookableOptions.push(availableBookableOption);
					}
				}
			}
		}
	}
	return { availableOptions: getUniqueOptionsFrom(availableBookableOptions), unavailableOptions: getUniqueOptionsFrom(unavailableBookableOptions) };
};

export const getUniqueOptionsFrom = (bookableOptions: BookableOptionType[]) => {
	return bookableOptions.filter((value, index, self) => {
		return self.findIndex((v) => v.option.id === value.option.id) === index;
	});
};

export const getLastBookTimeFromSunset = (sunset, selectedOptions: BookableOptionType[]) => {
	let additionalTime = 60;
	let serviceEstimatedTime = selectedOptions.reduce((acc, bookableOption) => acc + bookableOption.option.payTime, 0);
	let lastBookTime = moment(sunset).subtract(serviceEstimatedTime + additionalTime, 'minutes');
	return lastBookTime.set({
		minute: lastBookTime.minute() > 30 ? 30 : 0,
		second: 0,
		millisecond: 0
	});
};

export const fetchAndParseSunset = async (date, latLng, timezone, completion) => {
	const sunset = getSunset(latLng.lat, latLng.lng, date.toDate());
	if (completion) completion(moment(sunset).tz(timezone));
};

export const getCreateListingVariablesFromBookingConfig = (bookingConfig, userToBookFor, bookableOptions, totalPrice, paymentResult) => {
	let requestedOptionsArray = getRequestedOptionsFrom(bookableOptions);

	let variables = {
		requestedOptions: requestedOptionsArray,
		promos: getPromosForOptions(bookableOptions, userToBookFor.promos),
		propertyInfo: bookingConfig.serviceLocation,
		timezone: bookingConfig.serviceLocation.timezone,
		regionId: bookingConfig.serviceLocation.region,
		stripeBookingTotal: totalPrice,
		stripePaymentToken: paymentResult.id,
		currency: 'USD',
		client: userToBookFor.id,
		agent: null,
		photographer: null
	};
	if (userToBookFor.role === USER_ROLE_AGENT) {
		variables.agent = userToBookFor.id;
	} else if (userToBookFor.role === USER_ROLE_PHOTOGRAPHER) {
		variables.photographer = userToBookFor.id;
	}
	return variables;
};

export const getCreateServiceVariablesFromBookingConfig = (bookingConfig, userToBookFor, bookableOptions, totalPrice, paymentResult) => {
	let requestedOptionsArray = [...getRequestedOptionsFrom(bookableOptions), ...getRequestedOptionsFrom(bookingConfig.lightingOptions)];
	let notes = `${getNotesForBookableOptions(bookableOptions)}\n\nEntry method:\n${bookingConfig.entry.type}${
		bookingConfig.entry.lockBoxCode ? '\n\nLock-box Code: ' + bookingConfig.entry.lockBoxCode : ''
	}${bookingConfig.entry.notes && bookingConfig.entry.notes.length > 0 ? '\n\nEntry notes:\n' + bookingConfig.entry.notes : ''}`;

	let type = areMediaOptionsSelected(bookingConfig.selectedOptions) ? PHOTOSHOOT_TYPE_SERVICE : EDIT_TYPE_SERVICE;

	//check if unitNumber is "", if so set to null
	if (bookingConfig.serviceLocation.unitNumber === '') {
		bookingConfig.serviceLocation.unitNumber = null;
	}

	const getInitialServiceStatus = () => {
		if (!areMediaOptionsSelected(bookingConfig.selectedOptions)) {
			return STATUS_TECHNICIAN_UPLOAD;
		}
		return bookingConfig.serviceProvider ? STATUS_FINDING_PREFERRED_TECHNICIAN : STATUS_FINDING_TECHNICIAN;
	};

	let variables = {
		requestedOptions: requestedOptionsArray,
		serviceDate: bookingConfig.serviceDate || nearestPastMinutes(15, moment()),
		agentNotes: notes,
		type: type,
		client: userToBookFor.id,
		propertyInfo: bookingConfig.serviceLocation,
		timezone: bookingConfig.region?.timezone,
		regionId: bookingConfig.region?.id,
		agency: '',
		preferredPhotographers: bookingConfig.serviceProvider ? [bookingConfig.serviceProvider.id] : undefined,
		status: getInitialServiceStatus(),
		stripeBookingTotal: totalPrice,
		stripePaymentToken: paymentResult.id,
		currency: 'USD'
	};
	if (bookingConfig['listing']) {
		variables['listingId'] = bookingConfig['listing'].id;
		if (bookingConfig['listing'].property) {
			variables['propertyId'] = bookingConfig['listing'].property.id;
			delete variables['propertyInfo'];
			variables['address'] = bookingConfig['listing'].property.address;
		}
	}
	if (bookingConfig['additionalContacts'].length > 0) {
		variables['additionalAgentContacts'] = bookingConfig['additionalContacts'];
	}
	if (userToBookFor.role !== USER_ROLE_PHOTOGRAPHER) {
		variables['agent'] = userToBookFor.id;
	} else {
		if (type === EDIT_TYPE_SERVICE) {
			variables['photographer'] = userToBookFor.id;
		}
		variables['agentNotes'] = '';
	}

	if (bookingConfig['selectedPartnerPackage']) {
		variables['orderInformation'] = {
			...bookingConfig['selectedPartnerPackage'],
			options: mapOrderInformationOptions(bookingConfig['selectedPartnerPackage'].options),
			['Package Ordered']: bookingConfig['selectedPartnerPackage'].name
		};
	}
	return variables;
};

export const mapOrderInformationOptions = (options) => {
	return options.map((option) => {
		return { id: option.id, name: option.name };
	});
};

export const getRequestedOptionsFrom = (bookableOptions: BookableOptionType[]) => {
	return bookableOptions.map((bookableOption) => {
		const { option } = bookableOption;
		return {
			id: option.id,
			quantity: bookableOption.selectedQuantity,
			selectedQuantity: bookableOption.selectedQuantity,
			additionalInfo: {
				version: '0.1.0',
				value: bookableOption.requiredInfoAnswer
			},
			additionalInfoType: option.requiredInfo
			// price: (option.price - bookableOption.discountCalculator?.getBookedOptionDiscount()) * option.quantity,
			// traineePay: option.traineePay,
			// photographerCertificatesRequired: option.photographerCertificatesRequired,
			// editorCertificatesRequired: option.editorCertificatesRequired,
			// bookingUser: userToBookFor.id,
			// photographerInstructions: 'See handbook',
			// photographerPay: option.photographerPay,
			// name: option.name,
			// type: option.type,
			// packageType: option.packageType,
			// option: option.id,
		};
	});
};

export const getAppliedPromosFromBookableOptions = (bookableOptions: BookableOptionType[], discountSummer: DiscountSummerType): AppliedPromos[] => {
	let appliedPromos: AppliedPromos[] = bookableOptions
		.filter((bookableOption) => bookableOption.discountCalculator?.greatestPromo && bookableOption.discountCalculator.greatestPromo.promo)
		.map((bookableOption) => {
			return {
				promo: bookableOption.discountCalculator?.greatestPromo.promo,
				discountAmount: bookableOption.discountCalculator?.greatestPromo.promo?.discountAmount || 0
			};
		})
		.flat();

	const creditPromos = discountSummer.creditPromos;
	for (let creditPromo of creditPromos) {
		appliedPromos.push(creditPromo);
	}

	return appliedPromos;
};
