// Created by carsonaberle at 11/23/21
import gql from 'graphql-tag';

export const UPDATE_SERVICE_STAGE = gql`
	mutation UpdateServiceStage($serviceId: String!, $status: String!) {
		updateService(id: $serviceId, status: $status) {
			id
			status
		}
	}
`;
