export default {
	api: {
		clients: []
	},
	booking: {
		bookingConfig: undefined
	},
	currentUser: {
		user: undefined,
		selectedRegion: undefined,
		regions: [],
		displayLogin: false,
		onLoginComplete: undefined,
		optionId: undefined,
		preFilledEmail: undefined,
		superUser: undefined,
		superUserReturnRoute: undefined
	},
	actionItems: {
		servicesRequiringProofing: [],
		scheduleRequests: []
	},
	control: {
		requireLogin: false,
		showZendesk: false,
		showActionsNeeded: false,
		message: undefined,
		overlayInfo: undefined,
		rightPanelType: undefined,
		additionalHeader: undefined,
		booking: false,
		bookingUser: undefined,
		isMobileMenuVisible: false,
		selectedPanelTab: undefined,
		sidebarPages: [],
		sidebarNavigationIndex: 0
	},
	filters: {},
	selectedOption: null
};
