import { ReduxStateType } from '../types';

export const getScreenTypeSelector = (state: ReduxStateType) => {
	return state.control.screenType;
};

export const getShowZendeskSelector = (state: ReduxStateType) => {
	return state.control.showZendesk;
};

export const getSidebarPagesSelector = (state: ReduxStateType) => {
	return state.control.sidebarPages;
};

export const getSidebarNavigationIndex = (state: ReduxStateType) => {
	return state.control.sidebarNavigationIndex;
};

export const getSidebarPagesCountSelector = (state: ReduxStateType) => {
	return state.control.sidebarPages?.length ?? 0;
};

export const getIsMobileMenuVisibleSelector = (state: ReduxStateType) => {
	return state.control.isMobileMenuVisible;
};

export const getShowActionsNeededSelector = (state: ReduxStateType) => {
	return state.control.showActionsNeeded;
};

export const getRightPanelTypeSelector = (state: ReduxStateType) => {
	return state.control.rightPanelType;
};

export const getSelectedPanelTypeSelector = (state: ReduxStateType) => {
	return state.control.selectedPanelTab;
};
