import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ID_KEY, ROLE_KEY, SCREEN_TYPE } from '../../utils/Config/Constants';
import Queries from '../../utils/API/Queries';
import { clearUserCache } from '../../utils/Helpers/LoginHelper';
import '../../assets/styles/scss/pl-animations.scss';
import '../../assets/styles/BaseStyles';
import { getMatchingPath, removeURLParameter } from '../../utils/Helpers/AppHelper';
import { useHistory, useLocation } from 'react-router-dom';
import { isRouteExcludedFromAuth } from '../../RoutesItems';
import * as ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'
import PLErrorBoundary from '../Boundaries/PLErrorBoundary';
import UIModal from '../UI/UIModal';
import { toast } from 'react-toastify';
import UILabeledInput from '../UI/UILabeledInput';
import UIIconButton from '../UI/UIIconButton';
import { ZendeskAPI } from 'react-zendesk';
import UIOverlayCard from '../UI/UIOverlayCard';
import Login from '../../pages/public/Login';
import IndividualOption from '../../pages/portal/global/IndividualOption';
import PLService from '../../pages/portal/global/Service';
import PLMaintenancePage from '../UI/UIMaintenancePage/PLMaintenancePage';
import PLControlSidebar from '../PL/PLControlSidebar';
import queryString from 'querystring';
import { getInitialRoute } from '../../utils/Helpers/UserHelpers';
import {
	getDisplayLoginSelector,
	getDisplaySignupSelector,
	getOnLoginCompleteFunction,
	getPreFilledEmailSelector,
	getSignedInUserSelector
} from '../../redux/selectors/CurrentUserSelectors';
import { setSignedInUser, setSuperUser, showLogin, showSignup } from '../../redux/store/user/actions';
import { setServicesRequiringProofing } from '../../redux/store/actionItems/actions';
import { setScreenType } from '../../redux/store/control/actions';
import { storeSelectedOption } from '../../redux/store/options/actions';
import { getScreenTypeSelector } from '../../redux/selectors/ControlSelectors';
import { getSelectedOptionSelector } from '../../redux/selectors/OptionSelectors';
import { getSignedInUser } from '../../sagas/LoginSagas/actions';
import { useApolloClient } from 'react-apollo';
import { parseQuery } from '../UI/UIMenuBody';
import { UI_ICON_BUTTON_FILL_TYPE } from '../UI/UIIconButton/config';
import Signup from '../../pages/public/Signup';
import moment from 'moment';
import { ErrorBoundary } from 'react-error-boundary';
import { useFetchRequiredActions } from '../../hooks';

let smoothscroll;
if (process.env.BUILD_TARGET === 'client') {
	smoothscroll = require('smoothscroll-polyfill');
	smoothscroll.polyfill();
}

export function initializeReactGA() {
	if (process.env.PL_ENVIRONMENT === 'LIVE') {
		ReactGA.initialize('UA-135909099-2');
	} else {
		ReactGA.initialize('UA-135909099-3');
	}
}

export function initializeReactTagManager() {
	if (process.env.PL_ENVIRONMENT === 'LIVE') {
		const tagManagerArgs = {
			gtmId: 'AW-767189397'
		}
		TagManager.initialize(tagManagerArgs);
	}
}

export default function BaseSetup({ children }: BaseSetupProps) {
	const screenType = useSelector(getScreenTypeSelector);
	const signedInUser = useSelector(getSignedInUserSelector);
	const selectedOption = useSelector(getSelectedOptionSelector);
	const preFilledEmail = useSelector(getPreFilledEmailSelector);
	const onLoginComplete = useSelector(getOnLoginCompleteFunction);
	const displayLogin = useSelector(getDisplayLoginSelector);
	const displaySignup = useSelector(getDisplaySignupSelector);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [password, setPassword] = useState<string>('');
	const [confirmPassword, setConfirmPassword] = useState<string>('');
	const [changingPassword, setChangingPassword] = useState<boolean>(false);

	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const client = useApolloClient();

	useFetchRequiredActions('');

	const serviceId = new URLSearchParams(location.search).get('service');
	const currentSidebarPages = serviceId ? [{ type: 'service', service: { id: serviceId } }] : [];

	useEffect(() => {
		(async () => {
			setupGoogleMaps();
			addCapitalizePropertyToStringClass();
			addFlatArrayProperty();
			initializeReactGA();
			initializeReactTagManager();
			await initialSetup();
			// this.setupFacebookPixel();
			moment.suppressDeprecationWarnings = true;
		})();
	}, []);

	const addCapitalizePropertyToStringClass = () => {
		String.prototype['capitalize'] = function () {
			return this.charAt(0).toUpperCase() + this.slice(1);
		};
	};

	const addFlatArrayProperty = () => {
		if (!Array.prototype.flat) {
			Object.defineProperty(Array.prototype, 'flat', {
				value: function (depth = 1, stack: any[] = []) {
					for (let item of this) {
						if (depth > 0) {
							//@ts-ignore
							item.flat(depth - 1, stack);
						} else {
							stack.push(item);
						}
					}

					return stack;
				}
			});
		}
	};

	const changePassword = async () => {
		if (!changingPassword) {
			if (password.trim().length < 8) {
				toast.error('Password must be at least 8 character!');
			} else if (password.trim() !== confirmPassword.trim()) {
				toast.error('Passwords do not match');
			} else {
				setIsLoading(true);

				const response = await client
					.mutate({
						mutation: Queries.CHANGE_PASSWORD,
						variables: { id: signedInUser.id, password: password }
					})
					.catch((error) => {
						setIsLoading(false);
						toast.error('Error updating your password. Please call us!! ' + error.message);
					});
				if (response['data']) {
					let user = Object.assign({}, signedInUser);
					user.requiresPasswordChange = false;
					dispatch(setSignedInUser(user));
					setPassword('');
					setConfirmPassword('');
					setChangingPassword(false);
					toast.success('Successfully updated password!');
				} else {
					setIsLoading(false);
					setPassword('');
					setConfirmPassword('');
					toast.error('Error updating your password. Please call us!');
				}
			}
		}
	};

	const checkAutoLoginPopup = () => {
		const autoLoginEmail = queryString.parse(location.search.replace('?', ''))['login'];
		if (autoLoginEmail) {
			if (!signedInUser) {
				dispatch(showLogin(true, undefined, autoLoginEmail));
			} else {
				history.replace({ search: removeURLParameter(location.search, 'login') });
			}
		}
	};

	const initialSetup = async () => {
		updateScreenType();

		const matchingPath = getMatchingPath(signedInUser, history);

		const isExcluded = isRouteExcludedFromAuth(matchingPath);

		window.addEventListener('resize', updateScreenType, { passive: true });

		const forceLogout = () => {
			clearUserCache();
			dispatch(setSignedInUser(undefined));
			dispatch(setSuperUser(undefined));
			dispatch(setServicesRequiringProofing([]));
			setIsLoading(false);
			if (!isExcluded) {
				history.push('/');
			}
		};

		if (localStorage && localStorage.getItem(ID_KEY)) {
			const isNoPathAndSignedIn = matchingPath === undefined && signedInUser !== undefined;
			const isPathAndRoot = matchingPath !== undefined && matchingPath.path === '/';
			dispatch(
				getSignedInUser(
					client,
					(user) => {
						localStorage.setItem(ID_KEY, user.id);
						localStorage.setItem(ROLE_KEY, user.role);
						if (isNoPathAndSignedIn || isPathAndRoot) {
							history.push(getInitialRoute(user));
						}
						setTimeout(checkAutoLoginPopup, 1000);
					},
					(e) => {
						const { graphQLErrors, networkError, nullUser } = e;
						if (nullUser) {
							forceLogout();
							return;
						}
						if (networkError && networkError.statusCode === '401') {
							forceLogout();
						}
						if (networkError && networkError.name === 'ServerParseError') {
							forceLogout();
						} else if (graphQLErrors && graphQLErrors.length !== 0) {
							toast.error(e.message);
						} else {
							if (e.message && e.message !== '') {
								toast.error(e.message);
							} else {
								forceLogout();
							}
						}
						setTimeout(checkAutoLoginPopup, 1000);
					}
				)
			);
		} else {
			forceLogout();
			setTimeout(checkAutoLoginPopup, 1000);
		}
	};

	const setupGoogleMaps = () => {
		const gmapsScript = document.createElement(`script`);
		gmapsScript.defer = true;
		gmapsScript.id = 'gmaps';
		gmapsScript.type = 'text/javascript';
		gmapsScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAkljMbpNBOyHCfeaP8hf-wLjhFEfqbWo8&libraries=places`;
		document?.querySelector(`body`)?.insertAdjacentElement(`beforeend`, gmapsScript);
	};

	const updateScreenType = () => {
		let width = window.innerWidth;
		let height = window.innerHeight;

		let updateScreenType = SCREEN_TYPE.mobile;

		if (height < 660) {
			updateScreenType = SCREEN_TYPE.short;
		} else if (height > 1500) {
			updateScreenType = SCREEN_TYPE.monitor;
		} else if (width > 1500) {
			updateScreenType = SCREEN_TYPE.desktop;
		} else if (width > 1024) {
			updateScreenType = SCREEN_TYPE.laptop;
		} else if (width >= 660) {
			updateScreenType = SCREEN_TYPE.tablet;
		}

		if (updateScreenType !== screenType) {
			dispatch(setScreenType(updateScreenType));
		}
	};

	const renderSidebarContent = () => {
		if (currentSidebarPages[0]?.type === 'service') {
			return <PLService serviceId={currentSidebarPages[0].service.id} />;
		}
		return <div />;
	};

	const renderLoginModal = () => {
		const hideModal = async () => {
			if (onLoginComplete) {
				await onLoginComplete();
			}
			dispatch(showLogin(false, undefined));
		};
		return (
			<UIModal condition={displayLogin}>
				<Login hideModal={hideModal} preFilledEmail={preFilledEmail} />
			</UIModal>
		);
	};

	const renderSignupModal = () => {
		const hideModal = async () => {
			dispatch(showSignup(false, undefined));
		};
		return (
			<UIModal condition={displaySignup}>
				<Signup hideModal={hideModal} />
			</UIModal>
		);
	};

	const renderOptionModal = () => {
		const hideModal = async () => {
			dispatch(storeSelectedOption(undefined));
		};
		return (
			<UIModal condition={selectedOption !== undefined}>
				<IndividualOption hideModal={hideModal} />
			</UIModal>
		);
	};

	const renderPasswordChangeOverlay = () => {
		if (!signedInUser) {
			return;
		}
		return (
			<UIModal condition={signedInUser.requiresPasswordChange || false}>
				<UIOverlayCard title={'Password change required'} hideModal={() => {}}>
					<UILabeledInput
						containerStyle={{ width: '100%', margin: '16px 0 0 0' }}
						style={{ width: '100%' }}
						onChangeValue={setPassword}
						label={'PASSWORD'}
						value={password}
						type={'text'}
						placeholder={'Password...'}
					/>
					<UILabeledInput
						containerStyle={{ marginTop: 8, width: '100%' }}
						style={{ width: '100%' }}
						onChangeValue={setConfirmPassword}
						label={'CONFIRM PASSWORD'}
						value={confirmPassword}
						type={'text'}
						placeholder={'Confirm password...'}
					/>
					<div className='uiCard__buttons' style={{ margin: '16px 0 0 0' }}>
						<UIIconButton dark isLoading={isLoading} type={UI_ICON_BUTTON_FILL_TYPE} text={'update password'} onClick={changePassword} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	if (process.env.UNDER_MAINTENANCE === 'true') {
		return <PLMaintenancePage />;
	}

	const matchingRoute = getMatchingPath(signedInUser, history);
	if (!signedInUser && !isRouteExcludedFromAuth(matchingRoute)) return <div />;

	const onSetOpen = (open) => {
		if (!open) {
			const parsedQuery = parseQuery(location.search);
			const searchWithoutService = Object.entries(parsedQuery).filter((queryPair) => queryPair[0] !== 'service' && queryPair[0] !== 'page');
			const firstItem = searchWithoutService[0] ?? [];
			if (firstItem && firstItem[0]) {
				history.replace(`${location.pathname}?${firstItem[0]}`);
			} else {
				history.replace(`${location.pathname}`);
			}
		}
	};

	return (
		<ErrorBoundary
			FallbackComponent={PLErrorBoundary}
			onReset={() => {
				// reset the state of your app so the error doesn't happen again
			}}>
			<PLControlSidebar
				onSetOpen={onSetOpen}
				styles={{ sidebar: { background: 'transparent' }, overlay: { background: 'var(--white-60)' } }}
				open={currentSidebarPages?.length > 0}
				sidebar={renderSidebarContent()}>
				<>
					{children}
					{renderPasswordChangeOverlay()}
					{renderLoginModal()}
					{renderSignupModal()}
					{renderOptionModal()}
				</>
			</PLControlSidebar>
		</ErrorBoundary>
	);
}
