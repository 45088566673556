import React from 'react';
import UIIconButton from '../../UI/UIIconButton';
import { UI_ICON_BUTTON_FILL_TYPE } from '../../UI/UIIconButton/config';

export const REQUIRED_CHECK_TYPES = {
	notes: {
		type: 'notes',
		title: 'Please ensure all requests outlined in the notes fields are completed.',
		renderAdditionalComponent: ({ onClick }) => {
			return <UIIconButton onClick={onClick} className='approveServiceCheckboxItem__content--button' type={UI_ICON_BUTTON_FILL_TYPE} text={'View notes'} />;
		}
	},
	fileCount: { type: 'fileCount', title: 'This service has less files than the client purchased. Reach out to tech and editing team to confirm count' },
	exteriorShots: {
		type: 'exteriorShots',
		title: "Check if there are exterior shots. If there’s none, confirm with tech if the agent is in the know that there aren't any."
	},
	qualityCheck: {
		type: 'qualityCheck',
		title: 'Check quality of photos - refer to the editing style guide/handbook provided',
		subtext:
			'Basic ex: watch out for car plates and branded signage - these should be blurred out / make sure no photographer reflection or shadows are shown on any mirror or glass'
	},
	sort: { type: 'sort', title: 'Sort photos from front exterior to entry point of the home and work towards the back side of the home.' },
	virtualTwilight: {
		type: 'virtualTwilight',
		title: 'If there is a Virtual Dusk / twilight in the service options, ensure one is processed and uploaded (usually this is a front exterior)',
		renderAdditionalComponent: ({ onClick }) => {
			return <UIIconButton className='approveServiceCheckboxItem__content--button' type={UI_ICON_BUTTON_FILL_TYPE} text={'View options'} onClick={onClick} />;
		}
	},
	reEditsRequired: {
		type: 'reEditsRequired',
		title: 'Re-edits are not required',
		renderAdditionalComponent: ({ onClick }) => {
			return <UIIconButton dark className='approveServiceCheckboxItem__content--button' type={UI_ICON_BUTTON_FILL_TYPE} text={'View more'} onClick={onClick} />;
		}
	},
	matterport: {
		type: 'matterport',
		title: 'Please complete the Matterport checklist in the following article.',
		renderAdditionalComponent: () => {
			return (
				<a href={'https://www.google.com'} target={'_blank'}>
					<UIIconButton className='approveServiceCheckboxItem__content--button' type={UI_ICON_BUTTON_FILL_TYPE} text={'View Zendesk Checklist'} />
				</a>
			);
		}
	},
	approve: {
		type: 'approve',
		title:
			'Once you have verified that a shoot matches our quality standard, you can click the Approve photoshoot button. This sends the photos into proofing status for the agent to proof.',
		renderAdditionalComponent: ({ onClick }, approveDisabled) => {
			return (
				<UIIconButton
					dark
					className='approveServiceCheckboxItem__content--button'
					type={UI_ICON_BUTTON_FILL_TYPE}
					text={'Approve'}
					onClick={onClick}
					disabledErrorMessage={"Please make sure you've checked all the required boxes before approving"}
					isDisabled={approveDisabled}
				/>
			);
		}
	},
	markComplete: {
		type: 'mark complete',
		title:
			'If the shoot was booked through Coldwell you need to download the print sized version of the photos and upload to the CB portal before completing this step.',
		renderAdditionalComponent: ({ onClick }, approveDisabled) => {
			return (
				<UIIconButton
					className='approveServiceCheckboxItem__content--button'
					type={UI_ICON_BUTTON_FILL_TYPE}
					text={'Mark complete'}
					onClick={onClick}
					disabledErrorMessage={"Please make sure you've checked all the required boxes before marking the service complete"}
					isDisabled={approveDisabled}
				/>
			);
		}
	}
};
