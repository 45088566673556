import React from "react";
import './styles';
import TableListingRow from './TableListingRow';
import { TableListingsProps } from "./types";

const TableListings = ({ data, pagination }: TableListingsProps) => {
	return (
		<div className='tableListings'>
			<div className='tableListings__table fade-in-div'>
				{data.map((listing) => (
					<TableListingRow key={listing.id} listing={listing} />
				))}
			</div>
			{pagination}
		</div>
	);
}

export default TableListings
