import gql from 'graphql-tag';

export const GET_SERVICE_ADMIN_APPROVED = gql`
	query Service($id: String!) {
		service(id: $id) {
			id
			isAdminApproved
		}
	}
`;

export const UPDATE_SERVICE_ADMIN_APPROVED = gql`
	mutation UpdateServiceSurge($serviceId: String!, $isAdminApproved: Boolean!) {
		updateService(id: $serviceId, isAdminApproved: $isAdminApproved) {
			id
			isAdminApproved
		}
	}
`;
