import { sessionRoutes } from '../Config/SessionConfig';
import { matchPath } from 'react-router';
import { EDIT_TYPE_SERVICE } from '../Config/Constants';
import { bindActionCreators, Dispatch } from 'redux';
import { apiActions } from '../../redux/store/api/actions';
import { currentUserActions } from '../../redux/store/user/actions';
import { filtersActions } from '../../redux/store/filters/actions';
import { controlActions } from '../../redux/store/control/actions';
import { setServicesRequiringProofing } from '../../redux/store/actionItems/actions';
import { API_URLS } from '../API/APIConfig';

import moment from 'moment-timezone';
import { ReduxStateType } from '../../redux/types';
import { optionActions } from '../../redux/store/options/actions';

// export const GOOGLE_CLOUD_BUCKET_PREFIX = 'https://storage.googleapis.com/pineapple-estates/';

// export const FILESTACK_EDITOR_UPLOAD_WORKFLOW = '139f8d4e-a4f7-4795-85d5-b5000b064c3c';
// export const FILESTACK_VIDEO_UPLOAD_WORKFLOW = '1e8b8e59-2e38-446b-835b-7824c5d10525';
// export const DEPRECATED_FILESTACK_RAW_IMAGE_UPLOAD_WORKFLOW = '0fbe243f-7e99-47bd-8db8-8837b2cc8270';
// export const DEPRECATED_FILESTACK_PHOTOGRAPHER_UPLOAD_WORKFLOW = 'c0aff7e6-11b2-4b0c-abe5-34c74908abd1';

export const removeURLParameter = (url: string, parameter: string) => {
	//prefer to use l.search if you have a location/link object
	let urlParts = url.split('?');
	if (urlParts.length >= 2) {
		let prefix = encodeURIComponent(parameter) + '=';
		let pars = urlParts[1].split(/[&;]/g);

		//reverse iteration as may be destructive
		for (let i = pars.length; i-- > 0; ) {
			//idiom for string.startsWith
			if (pars[i].lastIndexOf(prefix, 0) !== -1) {
				pars.splice(i, 1);
			}
		}

		return urlParts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
	}
	return url;
};

export const actionItemsActions = {
	setServicesRequiringProofing
};

export function commonMapStateToProps(state: ReduxStateType) {
	return {
		actionItems: state.actionItems,
		filters: state.filters,
		control: state.control,
		currentUser: state.currentUser,
		api: state.api,
		option: state.option
	};
}

export function commonMapDispatchToProps(dispatch: Dispatch) {
	// @ts-ignore
	return bindActionCreators(
		{
			...apiActions,
			...currentUserActions,
			...filtersActions,
			...controlActions,
			...actionItemsActions,
			...optionActions
		},
		dispatch
	);
}

export function getZendeskSettings() {
	return {
		color: {
			theme: 'var(--mint-primary)'
		},
		webWidget: {
			chat: {
				connectOnPageLoad: false
			}
		}
	};
}
export const updateUrlParameter = (url, param, value) => {
	let regex = new RegExp('(([?;&])' + param + '=)[^&]+', 'i');
	return url.replace(regex, '&' + param + '=' + value);
};

export function getMatchingPath(user: UserType, history: any) {
	let matchingPaths = sessionRoutes(user).filter((route) => {
		return !!matchPath(history.location.pathname, {
			path: route.path,
			exact: true,
			strict: false
		});
	});
	if (matchingPaths) {
		if (matchingPaths.length > 0) {
			if (matchingPaths.length > 1) {
				return matchingPaths[1];
			}
			return matchingPaths[0];
		}
	}
	return;
}

export function getPortalUrl() {
	switch (process.env.PL_ENVIRONMENT) {
		case 'DEV':
			return 'https://dev-portal.pineapplelabs.io';
		case 'LOCAL_DEV':
		case 'LOCAL':
			return 'http://localhost:3000';
		case 'LIVE':
		default:
			return 'https://www.pineapplelabs.io';
	}
}

export function getExternalBaseUrl() {
	switch (process.env.PL_ENVIRONMENT) {
		case 'DEV':
			return 'https://dev-portal.pineapplelabs.io';
		case 'LOCAL_DEV':
		case 'LOCAL':
			return 'http://localhost:3000';
		case 'LIVE':
		default:
			return 'https://www.media.homes';
	}
}

export const getFileTransferApiUrl = () => {
	let url;

	switch (process.env.PL_ENVIRONMENT) {
		case 'LIVE':
			const momentOffset = moment().utcOffset();
			const utcOffset = (momentOffset ? momentOffset : 0) / 60;
			url = API_URLS.prod;
			if (utcOffset > 3) {
				url = API_URLS.prodAsia;
			}
			break;
		case 'DEV':
			url = API_URLS.dev;
			break;
		case 'LOCAL_DEV':
			url = API_URLS.localDev;
			break;
		case 'LOCAL':
			url = API_URLS.local;
			break;
		default:
			url = API_URLS.prod;
			break;
	}
	return `${url.replace('/api', '')}/file-transfer`;
};

export function getProofOptionByEnvironment(service?: ServiceType) {
	if (!service) return '';
	switch (process.env.PL_ENVIRONMENT) {
		case 'DEV':
		case 'LOCAL_DEV':
		case 'LOCAL':
			if (service.type === EDIT_TYPE_SERVICE) {
				//return edit option instead of proof
				return '246fa63b-dce3-4dca-85f6-5f0bd04e1ee5';
			}
			return '2c5c0f27-1b80-4dd2-8492-f47025137b7d';
		case 'LIVE':
		default:
			if (service.type === EDIT_TYPE_SERVICE) {
				//return edit option instead of proof
				return '79444c26-e85c-4b8d-b8be-3b3105f09860';
			}
			return 'dba989e4-f202-4c7b-a091-c0c9312c1f49';
	}
}

export function getPLStripeKey(): string {
	switch (process.env.PL_ENVIRONMENT) {
		case 'DEV':
		case 'LOCAL_DEV':
		case 'LOCAL':
			return process.env.STRIPE_KEY_DEV as string;
		case 'LIVE':
		default:
			return process.env.STRIPE_KEY_PROD as string;
	}
}

export const guessCurrentTimezone = () => {
	const { guess } = moment.tz;
	return guess();
};
