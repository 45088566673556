export const pageDescription =
	'This address will be displayed in your portal, on your property website, in your marketing materials, and given to any service providers you schedule with.';
export const streetAddressLabel = 'street address*';
export const streetAddressPlaceholder = 'street address...';
export const unitNumberLabel = 'unit #';
export const unitNumberPlaceholder = 'unit #...';
export const cityLabel = 'city';
export const cityPlaceholder = 'city...';
export const stateLabel = 'state*';
export const statePlaceholder = 'state...';
export const zipCodeLabel = 'zip code*';
export const zipCodePlaceholder = 'zip...';
export const aliasLabel = 'alias';
export const aliasPlaceholder = 'alias... (Ex. Main Street Apartments)';
