import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import UIModal from '../../UI/UIModal';
import UIAdditionalContacts from '../../UI/UITeamMembers';
import Queries from '../../../utils/API/Queries';
import { EditIcon, PlusIcon } from '../../../assets/icons';
import AddTeamMemberModal from '../AddTeamMemberModal';
import UIListInputRow from '../../UI/UIListInputRow';
import UIIcon from '../../UI/UIIcon';
import UIHover from '../../UI/UIHover/UIHover';
import { getSignedInUserSelector } from '../../../redux/selectors/CurrentUserSelectors';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { CHILD_TYPE } from '../../UI/UIListInputRow/config';

const ServiceAdditionalContactModal = ({ children, service, refreshData }: ServiceAdditionalContactModalProps) => {
	const signedInUser = useSelector(getSignedInUserSelector);
	const [isAddingContact, setIsAddingContact] = useState<boolean>(false);
	const [addingAdditionalServiceContacts, setAddingAdditionalServiceContacts] = useState<boolean>(false);

	const [updateService] = useMutation(Queries.UPDATE_SERVICE);

	const toggleServiceAdditionalContact = async (contact: AdditionalContact, forceAdd?: boolean) => {
		if (!isAddingContact) {
			setIsAddingContact(true);

			let serviceAdditionalContacts: AdditionalContact[] = service.additionalAgentContacts;
			if (forceAdd) {
				if (serviceAdditionalContacts.filter((additionalContact) => additionalContact.email === contact.email).length > 0) {
					toast.error("This contact is already tied to this service's notifications!");
					return;
				} else {
					serviceAdditionalContacts.push(contact);
				}
			} else {
				setIsAddingContact(true);
				if (serviceAdditionalContacts.filter((additionalContact) => additionalContact.email === contact.email).length > 0) {
					serviceAdditionalContacts.splice(serviceAdditionalContacts.indexOf(contact), 1);
				} else {
					serviceAdditionalContacts.push(contact);
				}
			}
			const response = await updateService({ variables: { serviceId: service.id, additionalAgentContacts: serviceAdditionalContacts } });
			const { updateService: updatedService } = response['data'];
			if (updatedService) {
				await refreshData();
				setAddingAdditionalServiceContacts(false);
				setIsAddingContact(false);

				toast.success("Successfully added contact to this service's notifications!");
			}
		}
	};

	const renderAddAdditionalContactServiceModal = () => {
		const userAdditionalContacts = signedInUser.additionalContacts;
		const hideModal = () => setAddingAdditionalServiceContacts(false);
		const onAddTeamMember = async (additionalContact: AdditionalContact) => {
			await toggleServiceAdditionalContact(additionalContact);
			hideModal();
		};

		const addAdditionalContact = async (additionalContact: AdditionalContact) => {
			await toggleServiceAdditionalContact(additionalContact, true);
		};

		return (
			<UIModal condition={addingAdditionalServiceContacts}>
				<AddTeamMemberModal hideModal={hideModal} isAddingContact={isAddingContact} onAddTeamMember={onAddTeamMember}>
					<UIAdditionalContacts data={userAdditionalContacts} addAdditionalContact={addAdditionalContact} deleteOnClick={false} />
				</AddTeamMemberModal>
			</UIModal>
		);
	};

	const renderRightItems = () => {
		return (
			<UIHover text={'add a contact'}>
				<UIIcon
					className='plServiceTechnician__edit'
					iconSrc={service.additionalAgentContacts?.length > 0 ? EditIcon.white : PlusIcon.white}
					onClick={() => setAddingAdditionalServiceContacts(true)}
					alt={'add a contact'}
				/>
			</UIHover>
		);
	};

	return (
		<UIListInputRow label={'notifications'} type={CHILD_TYPE} rightItems={renderRightItems()}>
			{children}
			{renderAddAdditionalContactServiceModal()}
		</UIListInputRow>
	);
};

export default ServiceAdditionalContactModal;
