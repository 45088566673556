import styled from 'styled-components';
import ReactPlayer from 'react-player';

export const div_VTVideosContainer = styled.div`
	//DEFAULT
	position: relative;
	width: 100%;
	max-width: 100%;
	max-height: 100%;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTVideosWrapper = styled.div<{ isLoaded: boolean }>`
	//DEFAULT
	display: block;
	max-width: 100%;
	height: 100%;
	max-height: calc(100% - 24px);
	align-items: center;
	justify-content: center;
	transition: 500ms all ease-in;
	padding: 12px 0;
	opacity: ${(props) => (!props.isLoaded ? 0 : 1)};

	//SHORT
	@media only screen and (max-height: 720px) {
		min-height: 300px;
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		padding: 0;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		justify-content: center;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
export const ReactPlayer_VTVideosPlayer = styled(ReactPlayer)`
	//DEFAULT
	max-width: 100%;
	width: unset !important;
	height: unset !important;
	max-height: 100%;
	transition: 300ms all ease-in;
	margin-bottom: 12px;
	border-radius: 12px;
	animation: fadeIn 700ms ease-out !important;
	-webkit-animation: fadeIn 700ms ease-out !important;

	&:last-of-type {
		margin-bottom: 0;
	}

	& > video {
		height: unset !important;
		max-height: 100%;
		border-radius: 12px;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		height: 100%;
		margin: 12px 0 36px 0;

		& > video {
			border-radius: 12px;
		}
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		//max-width: calc(min(calc(100% - 120px), 1200px));
		height: 100% !important;
		margin: auto auto 24px auto;
		border-radius: 24px;
		min-height: 480px;
		min-width: 720px;
		width: fit-content !important;

		& > video {
			height: 100%;
			width: auto !important;
			border-radius: 24px;
			animation: fadeIn 600ms ease-in;
			-webkit-animation: fadeIn 600ms ease-in;
		}
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
		justify-content: center;
	}
`;

export const div_VTVideosLoadingBar = styled.div`
	//DEFAULT
	position: absolute;
	top: 0;
	left: -12px;
	right: -12px;
	height: 2px;
	background-color: #121212;
	transition: all 50ms linear;
	animation: loadingBar 1500ms linear infinite;

	@keyframes loadingBar {
		0% {
			left: 0;
			right: 100%;
			width: 0;
		}
		10% {
			left: 0;
			right: 75%;
			width: 25%;
		}
		90% {
			right: 0;
			left: 75%;
			width: 25%;
		}
		100% {
			left: 100%;
			right: 0;
			width: 0;
		}
	}
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: none;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: none;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
