export const UI_LABELED_INPUT_CURRENCY_TYPE = 'currency';
export const UI_LABELED_INPUT_CURRENCY_STRING_TYPE = 'currencyString';
export const UI_LABELED_INPUT_DROPDOWN_TYPE = 'dropdown';
export const UI_LABELED_INPUT_SEARCH_TYPE = 'search';
export const UI_LABELED_INPUT_TEXT_TYPE = 'text';
export const UI_LABELED_INPUT_STATIC_TYPE = 'static';
export const UI_LABELED_INPUT_LINK_TYPE = 'link';
export const UI_LABELED_INPUT_LIVE_LINK_TYPE = 'liveLink';
export const UI_LABELED_INPUT_LINK_NOT_CLICKABLE_TYPE = 'linkNonClickable';
export const UI_LABELED_INPUT_DATE_STATIC_TYPE = 'dateStatic';
export const UI_LABELED_INPUT_TEXT_FIELD = 'textField';
export const UI_LABELED_INPUT_NOTES_TYPE = 'notes';
export const UI_LABELED_INPUT_PHONE_NUMBER_TYPE = 'phoneNumber';
export const UI_LABELED_INPUT_NUMBER_TYPE = 'number';
export const UI_LABELED_INPUT_BUTTON_TYPE = 'button';
export const UI_LABELED_INPUT_INVITE_CODE_TYPE = 'inviteCode';
