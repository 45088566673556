// Created by carsonaberle at 11/23/21
import gql from 'graphql-tag';

export const UPDATE_FILES_STATUS = gql`
	mutation UpdateFilesStatus($requestedUpdates: [JSON]!, $service: String, $listing: String) {
		updateFiles(requestedUpdates: $requestedUpdates, service: $service, listing: $listing) {
			id
			index
			isActive
			isVisible
			status
		}
	}
`;
