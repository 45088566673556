import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { getApiUrl } from './APIConfig';
import fetch from 'isomorphic-fetch';
import { setContext } from 'apollo-link-context';
import { SUPER_USER_TOKEN_KEY, TOKEN_KEY } from '../Config/Constants';
import { ApolloLink } from 'apollo-link';
import { clearUserCache } from '../Helpers/LoginHelper';
import { toast } from 'react-toastify';

export const getAuthorizationHeader = (clientLocalStorage) => {
	let authToken = clientLocalStorage.getItem(TOKEN_KEY);
	if (clientLocalStorage.getItem(SUPER_USER_TOKEN_KEY)) {
		authToken = clientLocalStorage.getItem(SUPER_USER_TOKEN_KEY);
	}
	return authToken;
};
// if on client side clientLocalStorage will be passed, if server it will be undefined
const createApolloClient = ({ clientLocalStorage }) => {
	const httpLink = createHttpLink({ uri: getApiUrl(), fetch: fetch });
	const authLink = setContext((_, { headers }) => {
		if (clientLocalStorage && clientLocalStorage.getItem(TOKEN_KEY)) {
			const authToken = getAuthorizationHeader(clientLocalStorage);
			let authorization = `Bearer ${authToken}`;
			return {
				headers: {
					...headers,
					authorization: authorization
				}
			};
		}
		return {
			headers: {
				...headers
			}
		};
	});

	const errorLink = onError((error) => {
		const { graphQLErrors, networkError } = error;
		if (graphQLErrors)
			if (networkError) {
				// graphQLErrors.forEach(({ message, locations, path }) =>
				// 	console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
				// );
				if (networkError.statusCode === 401) {
					toast.error('Unauthorized');
					clearUserCache();
				}
				// console.log(`[Network error]`);
			}
	});

	const link = ApolloLink.from([errorLink, httpLink]);

	const defaultOptions = {
		watchQuery: {
			fetchPolicy: 'network-only',
			errorPolicy: 'all'
		},
		query: {
			fetchPolicy: 'cache-first',
			errorPolicy: 'all'
		}
	};

	return new ApolloClient({
		// ssrMode: clientLocalStorage === undefined,
		link: authLink.concat(link),
		uri: getApiUrl(),
		cache: new InMemoryCache(),
		// ssrForceFetchDelay: 100,
		// connectToDevTools: true,
		defaultOptions: defaultOptions
	});
};

export default createApolloClient;
