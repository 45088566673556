// Created by jarongallo at 7/1/21
import React from 'react';
import './styles';

const UILoadingBar = ({ isLoading, background }: UILoadingBarProps) => {
	if (!isLoading) {
		return <div className='plServiceLoading--placeholder' />;
	}

	return (
		<div className='plServiceLoading'>
			<div className='plServiceLoading--bar' style={{ background }} />
		</div>
	);
};

export default UILoadingBar;
