// Created by jarongallo at 6/14/21
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UIIconButton from '../../../../../../components/UI/UIIconButton';
import { EditIcon, PlusIcon, Trashcan } from '../../../../../../assets/icons';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import UIModal from '../../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../../components/UI/UIOverlayCard';
import { isUserAdminOrRM } from '../../../../../../utils/Helpers/UserHelpers';
import UIIcon from '../../../../../../components/UI/UIIcon';
import './styles';
import * as strings from './strings';
import { PLServiceMatterportType } from './types';
import { getCurrentUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_PL_SERVICE_MATTERPORT_SERVICE, UPDATE_SERVICE_MATTERPORT } from './queries';
import { CHILD_TYPE } from '../../../../../../components/UI/UIListInputRow/config';

export default function PLServiceMatterport({ serviceId }: PLServiceMatterportType) {
	const currentUser = useSelector(getCurrentUserSelector);

	const [updateService] = useMutation(UPDATE_SERVICE_MATTERPORT);
	const [matterportModelId, setMatterportModelId] = useState('');
	const [matterportModelIdToSave, setMatterportModelIdToSave] = useState('');
	const [showMatterportModal, setShowMatterportModal] = useState(false);
	const [showEditMatterport, setShowEditMatterport] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isRemoving, setIsRemoving] = useState(false);

	const [getService, { data: serviceData }] = useLazyQuery(GET_PL_SERVICE_MATTERPORT_SERVICE, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getService();
		})();
	}, []);

	useEffect(() => {
		if (serviceData) {
			setMatterportModelId(serviceData['service'].matterportModelId);
			setMatterportModelIdToSave(serviceData['service'].matterportModelId);
		}
	}, [serviceData]);

	const shouldRenderComponentForService = () => {
		return !!(isUserAdminOrRM(currentUser.user) || matterportModelId);
	};

	const renderMatterportModal = () => {
		const hideModal = () => setShowMatterportModal(false);

		const removeMatterport = async () => {
			setIsRemoving(true);
			await updateService({ variables: { serviceId, matterportModelId: null } });
			await getService();
			setIsRemoving(false);
			setMatterportModelId('');
			setMatterportModelIdToSave('');
		};
		return (
			<UIModal condition={showMatterportModal}>
				<UIOverlayCard
					hideModal={hideModal}
					title={strings.matterportModalTitle}
					cardStyle={{ minHeight: '100%', minWidth: '100%' }}
					rightItem={
						<UIIcon
							className='plServiceMatterport__button'
							isLoading={isRemoving}
							iconSrc={Trashcan.white}
							onClick={async () => {
								await removeMatterport();
								hideModal();
							}}
							alt={'remove'}
						/>
					}>
					{matterportModelId && (
						<div className='plServiceMatterport__iframe'>
							<iframe className='plServiceMatterport__model' src={`https://my.matterport.com/show/?m=${matterportModelId}`} allowFullScreen />
						</div>
					)}
				</UIOverlayCard>
			</UIModal>
		);
	};

	const saveMatterport = async () => {
		setIsSaving(true);
		await updateService({ variables: { serviceId, matterportModelId: matterportModelIdToSave } });
		await getService();
		setIsSaving(false);
		setMatterportModelId(matterportModelIdToSave);
	};

	const renderEditMatterportModal = () => {
		const hideModal = () => setShowEditMatterport(false);
		return (
			<UIModal condition={showEditMatterport}>
				<UIOverlayCard hideModal={hideModal} title={strings.editMatterportModalTitle} description={strings.editMatterportModalDescription}>
					<UIListInputRow
						value={matterportModelIdToSave}
						label={strings.editMatterportModalLabel}
						isLoading={isSaving}
						placeholder={strings.editMatterportModalPlaceholder}
						savedValue={matterportModelId}
						onChangeValue={setMatterportModelIdToSave}
						onSave={async () => {
							setIsSaving(true);
							await saveMatterport();
						}}
					/>
				</UIOverlayCard>
			</UIModal>
		);
	};

	if (!shouldRenderComponentForService()) {
		return null;
	}
	const renderRightItems = () => {
		if (isUserAdminOrRM(currentUser.user)) {
			return (
				<UIIcon
					className='plServiceMatterport__edit'
					iconSrc={matterportModelId ? EditIcon.white : PlusIcon.white}
					onClick={() => setShowEditMatterport(true)}
					alt={'edit'}
				/>
			);
		}
		return;
	};

	if (matterportModelId) {
		return (
			<UIListInputRow label={strings.matterportLabel} type={CHILD_TYPE} rightItems={renderRightItems()}>
				<UIIconButton dark text={strings.matterportButton} onClick={() => setShowMatterportModal(true)} />
				{renderMatterportModal()}
				{renderEditMatterportModal()}
			</UIListInputRow>
		);
	}
	return (
		<UIListInputRow label={strings.matterportLabel} type={CHILD_TYPE} rightItems={renderRightItems()}>
			{matterportModelId ? (
				<iframe className='plServiceMatterport__model' src={`https://my.matterport.com/show/?m=${matterportModelId}`} allowFullScreen />
			) : (
				<div>{strings.noMatterport}</div>
			)}
			{renderEditMatterportModal()}
		</UIListInputRow>
	);
}
