import { USER_ROLE_ADMIN, USER_ROLE_REGIONAL_MANAGER } from '../Config/Constants';
import { roleRoutes } from '../Config/SessionConfig';

export const isUserAdminOrRM = (user) => {
	if (user) {
		return user.role === USER_ROLE_ADMIN || user.role === USER_ROLE_REGIONAL_MANAGER;
	}
	return false;
};

export const getInitialRoute = (user) => {
	const initialRoleRoute = roleRoutes(user).filter(({ skipInitial }) => !skipInitial)[0];
	if (initialRoleRoute) {
		return initialRoleRoute.path;
	}
	return '/';
};
