import { EDIT_TYPE_SERVICE, PHOTOSHOOT_TYPE_SERVICE } from '../../../../../../utils/Config/Constants';
import * as strings from './strings';
import { CameraIcon, EditPhotoIcon } from '../../../../../../assets/icons';

export const SERVICE_TYPE_ITEM_PHOTOSHOOT: ServiceTypeItem = {
	type: PHOTOSHOOT_TYPE_SERVICE,
	title: strings.photoshoot,
	description: strings.photoshootDescription,
	icon: CameraIcon.white
};

export const SERVICE_TYPE_ITEM_EDIT: ServiceTypeItem = {
	type: EDIT_TYPE_SERVICE,
	title: strings.edit,
	description: strings.editDescription,
	icon: EditPhotoIcon.white
};
