import {
	AgentIcon,
	CancelIcon,
	EditIcon,
	EditingCompaniesIcon,
	EditorTab,
	FloorplansIcon,
	HistoryWhite,
	InfoIcon,
	InviteCodes,
	ListingIcon,
	MatterportIcon,
	NotesIcon,
	PhotosTab,
	ProfileIcon,
	PromotionsIcon,
	RegionIcon,
	ServiceOptionsTab,
	ServicesIcon,
	SettingsWhite,
	SocialMediaTab,
	TechnicianCertificates,
	VideoIconWhite
} from '../../assets/icons';

export let AGENT_INFO_TAB = { label: 'Agent info', value: 'agentInfo', searchParam: 'agent-info', img: InfoIcon };
export let AGENT_BLOCKED_TECHNICIANS_TAB = { label: 'Blocked technicians', value: 'blockedTechnicians', searchParam: 'blocked-technicians', img: CancelIcon.white };
export let AGENT_LISTINGS_TAB = { label: 'Listings', value: 'agentListings', searchParam: 'agent-listings', img: ListingIcon };
export let AGENT_SERVICES_TAB = { label: 'Services', value: 'agentServices', searchParam: 'agent-services', img: ServicesIcon };
export let AGENT_PROMOTIONS_TAB = { label: 'Promotions', value: 'promotions', searchParam: 'promotions', img: PromotionsIcon.white };
export let AGENT_ADMIN_TAB = { label: 'Admin', value: 'admin', searchParam: 'admin', img: SettingsWhite };

export let TECHNICIAN_INFO_TAB = { label: 'Technician info', value: 'technicianInfo', searchParam: 'technician-info', img: InfoIcon };
export let TECHNICIAN_CERTIFICATES_TAB = { label: 'Permissions', value: 'permissions', searchParam: 'certificates', img: TechnicianCertificates.solid.white };
export let TECHNICIAN_SERVICE_HISTORY_TAB = { label: 'Pineapple services', value: 'serviceHistory', searchParam: 'network-services', img: HistoryWhite };
export let TECHNICIAN_LISTINGS_TAB = { label: 'Personal Listings', value: 'listings', searchParam: 'listings', img: ListingIcon };
export let TECHNICIAN_PROMOTIONS_TAB = { label: 'Promotions', value: 'promotions', searchParam: 'promotions', img: ServiceOptionsTab };
export let TECHNICIAN_SERVICE_AREA_TAB = { label: 'Regions', value: 'serviceArea', searchParam: 'service-area', img: RegionIcon.white };
export let TECHNICIAN_ADMIN_TAB = { label: 'Admin', value: 'admin', searchParam: 'admin', img: SettingsWhite };

export let LISTING_SERVICES_TAB = { label: 'Services', value: 'listingServices', searchParam: 'listing-services', img: ServicesIcon };
export let LISTING_PROPERTY_WEBSITE_TAB = { value: 'propertyWebsiteTab', label: 'Virtual tour', searchParam: 'virtual-tour', img: SocialMediaTab };
export let LISTING_CONTENT_TAB = { value: 'contentTab', label: 'Content', searchParam: 'content', img: PhotosTab };
export let LISTING_PHOTOS_TAB = { value: 'photosTab', label: 'Photos', searchParam: 'photos', img: PhotosTab };
export let LISTING_MATTERPORT_TAB = { value: 'matterportTab', label: 'Matterport', searchParam: 'matterport', img: MatterportIcon.white };
export let LISTING_FLOOR_PLANS_TAB = { value: 'floorPlansTab', label: 'Floor plans', searchParam: 'floor-plans', img: FloorplansIcon };
export let LISTING_VIDEOS_TAB = { value: 'videosTab', label: 'Videos', searchParam: 'videos', img: VideoIconWhite };

export let EDITOR_INFO_TAB = { value: 'editor info', label: 'Editor Info', searchParam: 'editor-info', img: InfoIcon };
export let EDITOR_CERTIFICATES_TAB = { value: 'certificates', label: 'Certificates', searchParam: 'certificates' };
export let EDITOR_PAST_EDITS_TAB = { value: 'pasted edits', label: 'Past Edits', searchParam: 'past-edits', img: HistoryWhite };

export let EDITING_MANAGER_COMPANY_INFO_TAB = { value: 'companyInfo', label: 'Company Info', searchParam: 'company-info', img: InfoIcon };
export let EDITING_MANAGER_INFO_TAB = { value: 'managerInfo', label: 'Manager Info', searchParam: 'manager-info', img: EditingCompaniesIcon };
export let EDITING_MANAGER_EDITORS_TAB = { value: 'editors', label: 'Editors', searchParam: 'editors', img: EditorTab };

export let OPTION_GENERAL_TAB = { value: 'general', text: 'General' };
export let OPTION_PHOTOGRAPHER_TAB = { value: 'photographer', text: 'Photographer' };
export let OPTION_EDITOR_TAB = { value: 'editor', text: 'Editor' };
export let OPTION_REGIONS_TAB = { value: 'regions', text: 'Regions' };
export let OPTION_ADMIN_TAB = { value: 'admin', text: 'Admin' };

export let INVITE_CODE_SHOOTS_TAB = { value: 'services', label: 'Services', searchParam: 'services', img: ServicesIcon };
export let INVITE_CODE_SALESPERSON_TAB = { value: 'salesperson', label: 'Salesperson', searchParam: 'salesperson', img: ProfileIcon };
export let INVITE_CODE_AGENTS_TAB = { value: 'agents', label: 'Agents', searchParam: 'agents', img: AgentIcon };
export let INVITE_CODE_INFO_TAB = { value: 'inviteCodeInfo', label: 'Invite Code', searchParam: 'invite-code-info', img: InfoIcon };

export let AFFILIATES_INFO_TAB = { value: 'info', label: 'Info', searchParam: 'info', img: InfoIcon };
export let AFFILIATES_AGENTS_TAB = { value: 'agents', label: 'Agents', searchParam: 'agents', img: AgentIcon };
export let AFFILIATES_INVITE_CODES_TAB = { value: 'inviteCodes', label: 'Invite Codes', searchParam: 'invite-codes', img: InviteCodes };

export let SERVICE_REQUEST_NOTES = { value: 'notes', label: 'Notes', searchParam: 'notes', img: NotesIcon.white };
export let SERVICE_REQUEST_EDITOR = { value: 'editor', label: 'Editor', searchParam: 'editor', img: EditIcon.white };
export let SERVICE_REQUEST_INCORRECT = { value: 'requestedFiles', label: 'Incorrect files', searchParam: 'requested-files', img: EditIcon.white };
export let SERVICE_REQUEST_FIXED_FILES = { value: 'completedFiles', label: 'complete files', searchParam: 'fixed-files', img: InfoIcon };
// export let SERVICE_REQUEST_UPLOAD = { value: 'upload', label: 'upload files', searchParam: 'upload-files', img: FolderWhite };
