// Created by carsonaberle at 3/29/22
import styled from 'styled-components';

export const div_VTFullscreenContainer = styled.div`
	//DEFAULT
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: #121212;
	align-items: center;
	justify-content: center;
	z-index: 3;
	//-webkit-animation: fadeIn 350ms ease-out;
	//animation: fadeIn 350ms ease-out;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		padding: 0 24px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		padding: 0 48px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTFullscreenSlideshow = styled.div`
	//DEFAULT
	position: relative;
	width: 100%;
	height: 100%;
	max-width: 100%;
	align-items: center;
	max-height: calc(100% - 160px);
	flex-direction: row;
	justify-content: space-between;
	-webkit-animation: fadeIn 500ms ease-in !important;
	animation: fadeIn 500ms ease-in !important;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		max-height: 100%;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTFullscreenSlideshowContent = styled.div`
	//DEFAULT
	max-height: 100%;
	height: 100%;
	width: 100%;
	max-width: 100%;
	align-items: center;
	position: relative;
	justify-content: space-between;
	flex-direction: row;
	padding: 120px 0;

	//SHORT
	@media only screen and (max-height: 720px) {
		height: 100%;
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		width: fit-content;
		max-height: 100%;
		height: 100%;
		max-width: 100%;
		align-items: center;
		position: relative;
		justify-content: space-between;
		flex-direction: row;
		padding: 120px 0;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		width: fit-content;
		max-height: 100%;
		height: 100%;
		max-width: 100%;
		align-items: center;
		position: relative;
		justify-content: space-between;
		flex-direction: row;
		padding: 120px 0;
	}

	//SHORT LAPTOP
	@media only screen and (max-height: 1025px) and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_VTFullscreenClose = styled.p`
	//DEFAULT
	position: absolute;
	top: 12px;
	left: 12px;
	width: fit-content;
	cursor: pointer;
	padding: 12px 24px 11px 24px;
	border: 1px #f1f1f1 solid;
	border-radius: 6px;
	z-index: 3;
	transition: 200ms background-color linear;
	font-family: BrownBold, sans-serif;
	//-webkit-animation: fadeIn 300ms ease-in !important;
	//animation: fadeIn 300ms ease-in !important;

	&:hover {
		background-color: #f1f1f133;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		top: 40px;
		left: 40px;
		padding: 12px 24px;
		border-radius: 12px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		top: 40px;
		left: 40px;
		padding: 12px 24px;
		border-radius: 12px;
	}

	//SHORT LAPTOP
	@media only screen and (max-height: 1025px) and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_VTFullscreenIndex = styled.p`
	//DEFAULT
	display: none;
	position: absolute;
	top: 24px;
	right: 24px;
	width: fit-content;
	padding: 12px 24px;
	transition: 200ms background-color linear;
	font-family: BrownBold, sans-serif;
	-webkit-animation: fadeIn 500ms ease-in !important;
	animation: fadeIn 500ms ease-in !important;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: flex;
		top: 40px;
		right: 40px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		top: 40px;
		right: 40px;
	}

	//SHORT LAPTOP
	@media only screen and (max-height: 1025px) and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const span_VTFullscreenClose = styled.span`
	//DEFAULT
	display: none;
	color: var(--light-grey);
	margin-right: 24px;
	font-family: BrownRegular, sans-serif;
	-webkit-animation: fadeIn 500ms ease-in !important;
	animation: fadeIn 500ms ease-in !important;
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: unset;
	}

	//SHORT LAPTOP
	@media only screen and (max-height: 1025px) and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const img_VTFullscreenSlideshowImage = styled.img`
	//DEFAULT
	max-width: 100%;
	max-height: 100%;
	transition: 250ms all ease-in;
	border-radius: 12px;
	user-select: none;
	animation: fadeIn 200ms linear;
	-webkit-animation: fadeIn 200ms linear;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		border-radius: 24px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const VTFullscreenControlIcon = styled.img`
	z-index: 2;
	border-radius: 36px;
	cursor: pointer;
	height: 36px;
	width: 36px;
	transition: 150ms background-color ease-in;
	-webkit-animation: fadeIn 300ms ease-in !important;
	animation: fadeIn 300ms ease-in !important;

	&:hover {
		background-color: #12121233;
	}

	&:active {
		background-color: #12121233;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		height: 48px;
		width: 48px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		height: 48px;
		width: 48px;
	}

	//MONITOR
	@media only screen and (min-width: 1500px) {
	}
`;

export const div_VTFullscreenControlIconBlank = styled.div`
	//DEFAULT
	display: none;
	height: 48px;
	width: 48px;
	min-width: 48px;
	margin: 0 12px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: unset;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: unset;
	}

	//MONITOR
	@media only screen and (min-width: 1500px) {
	}
`;

export const img_VTFullscreenBack = styled(VTFullscreenControlIcon)`
	//DEFAULT
	display: none;
	margin-right: 12px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: flex;
		margin-right: 24px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		margin-right: 24px;
	}

	//MONITOR
	@media only screen and (min-width: 1500px) {
	}
`;

export const img_VTFullscreenForward = styled(VTFullscreenControlIcon)`
	//DEFAULT
	display: none;
	margin-left: 12px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: flex;
		margin-left: 24px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		margin-left: 24px;
	}

	//MONITOR
	@media only screen and (min-width: 1500px) {
	}
`;

export const div_VTFullscreenMobileContent = styled.div`
	//DEFAULT
	flex-direction: row;
	max-width: 100%;
	max-height: 100%;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	overscroll-behavior: none;
	height: 100%;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-width: 1500px) {
	}
`;

export const div_VTFullscreenMobileImageWrapper = styled.div`
	//DEFAULT
	width: 100%;
	height: 100%;
	min-width: 100%;
	justify-content: center;
	align-items: center;
	padding: 0 12px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-width: 1500px) {
	}
`;

export const img_VTFullscreenMobileImage = styled.img`
	//DEFAULT
	margin: 0 12px;
	//flex: 1 1 100%;
	max-width: 100%;
	max-height: 100%;
	border-radius: 12px;
	scroll-snap-align: center;
	scroll-snap-stop: always;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-width: 1500px) {
	}
`;
