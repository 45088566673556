export const syncCubiSuccess = 'Successfully re-synced floor plan!';
export const syncCubiDisabled = 'Please fill out a Model and Order Id for CubiCasa';
export const syncCubiButton = 'sync floor plan';
export const removedFloorplansSuccess = 'Successfully removed floorplans from service!';
export const updatedService = 'Successfully updated service!';
export const removeFloorplansButton = 'remove floor plan';
export const addFloorPlanModalTitle = 'Add CubiCasa info';
export const cubiCasaModelIdLabel = 'CubiCasa Model Id';
export const cubiCasaModelIdPlaceholder = 'CubiCasa model id...';
export const cubiCasaOrderIdLabel = 'CubiCasa Order Id';
export const cubiCasaOrderIdPlaceholder = 'CubiCasa order id...';
export const emptySetTitle = 'No floor plans have been synced';
export const emptySetDescription = 'This service has no floor plans synced yet. If you have files you would like to upload, please do so from within the listing';
