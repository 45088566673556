import React, { useState } from 'react';
import './styles';
import * as strings from './strings';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { SERVICE_REQUESTS, USER_ROLE_EDITING_MANAGER, USER_ROLE_EDITOR } from '../../../../../utils/Config/Constants';
import { camelCaseToSentence } from '../../../../../utils/Helpers/StringHelpers';
import UIIconButton from '../../../../UI/UIIconButton';
import { CheckmarkIcon } from '../../../../../assets/icons';
import UIModal from '../../../../UI/UIModal';
import Queries from '../../../../../utils/API/Queries';
import PLImage from '../../../PLImage';
import UIOverlayCard from '../../../../UI/UIOverlayCard';
import { getSignedInUserSelector } from '../../../../../redux/selectors/CurrentUserSelectors';
import { toast } from 'react-toastify';
import { useApolloClient } from 'react-apollo';
import { UI_ICON_BUTTON_CANCEL_TYPE, UI_ICON_BUTTON_FILL_TYPE } from '../../../../UI/UIIconButton/config';

export default function TableServiceRequests({ data, onSelect, pagination, hideAddress, updateTable }: TableServiceRequestsProps) {
	const signedInUser = useSelector(getSignedInUserSelector);

	const [serviceRequestToAccept, setServiceRequestToAccept] = useState<ServiceRequestType>();
	const [isAcceptingServiceRequest, setIsAcceptingServiceRequest] = useState<boolean>(false);

	const client = useApolloClient();

	const acceptServiceRequest = async () => {
		if (!isAcceptingServiceRequest && serviceRequestToAccept) {
			setIsAcceptingServiceRequest(true);
			const response = await client.mutate({
				mutation: Queries.UPDATE_SERVICE_REQUEST,
				variables: {
					id: serviceRequestToAccept.id,
					status: SERVICE_REQUESTS.status.inProgress,
					editor: signedInUser.id
				}
			});
			const { updateServiceRequest } = response['data'];
			if (updateServiceRequest) {
				await updateTable();
				toast.success(strings.acceptedSuccessMessage);
			}
			setServiceRequestToAccept(undefined);
			setIsAcceptingServiceRequest(false);
		}
	};

	const renderEditor = (serviceRequest) => {
		if (!serviceRequest.editor) {
			return (
				<div className='tableServiceRequest__row--editor'>
					<p className='tableServiceRequest__row--editor--name' style={{ color: 'var(--light-grey)' }}>
						no editor
					</p>
				</div>
			);
		}
		return (
			<div className='tableServiceRequest__row--editor'>
				<PLImage borderRadius={12} className='tableServiceRequest__row--editor--image' src={serviceRequest.editor.image} alt={serviceRequest.editor.name} />
				<p className='tableServiceRequest__row--editor--name'>{serviceRequest.editor.name}</p>
			</div>
		);
	};
	const renderAcceptButton = (serviceRequest) => {
		if (
			!serviceRequest ||
			serviceRequest.status !== SERVICE_REQUESTS.status.awaitingAcceptance ||
			[USER_ROLE_EDITING_MANAGER, USER_ROLE_EDITOR].indexOf(signedInUser.role) === -1
		) {
			return;
		}
		return (
			<UIIconButton
				dark
				icon={CheckmarkIcon.grey}
				text={strings.accept}
				onClick={(e) => {
					e.stopPropagation();
					setServiceRequestToAccept(serviceRequest);
				}}
			/>
		);
	};

	const renderAcceptServiceRequestModal = () => {
		const hideModal = () => setServiceRequestToAccept(undefined);
		return (
			<UIModal condition={serviceRequestToAccept !== undefined}>
				<UIOverlayCard hideModal={hideModal} title={strings.acceptServiceRequest} description={strings.acceptDescription}>
					<div className='uiCard__buttons' style={{ margin: '16px 0 0 0' }}>
						<UIIconButton
							dark
							isLoading={isAcceptingServiceRequest}
							type={UI_ICON_BUTTON_FILL_TYPE}
							icon={CheckmarkIcon.grey}
							text={strings.accept}
							onClick={acceptServiceRequest}
						/>
						<UIIconButton dark type={UI_ICON_BUTTON_CANCEL_TYPE} text={strings.cancel} onClick={hideModal} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	return (
		<div className='service-table-card-container fade-in-div'>
			<div className='tableServiceRequest--rows'>
				{data.map((serviceRequest) => {
					const { service } = serviceRequest;
					let serviceDate = serviceRequest.createdAt ? moment(new Date(serviceRequest.createdAt)).format('MMM Do YYYY') : 'Invalid date';
					let serviceTime = serviceRequest.createdAt ? moment(serviceRequest.createdAt).format('h:mm a z') : '';
					return (
						<div key={serviceRequest.id} className=' tableServiceRequest__row' onClick={() => onSelect(serviceRequest)}>
							<div className='tableServiceRequest__row__dt'>
								<p className='tableServiceRequest__row__dt--date'>{serviceDate}</p>
								<p className='tableServiceRequest__row__dt--time'>{serviceTime}</p>
							</div>
							<p className='tableServiceRequest__row__address'>{serviceRequest.service?.property?.streetAddress}</p>
							<p className='tableServiceRequest__row--status'>{camelCaseToSentence(serviceRequest.status)}</p>

							{hideAddress
								? null
								: <p className='tableServiceRequest__row--address'>{service && service.property && service.property.address}</p> || (
										<p className='tableServiceRequest__row--address'>address not found</p>
								  )}
							{renderEditor(serviceRequest)}
							{renderAcceptButton(serviceRequest)}
						</div>
					);
				})}
			</div>
			{pagination}
			{renderAcceptServiceRequestModal()}
		</div>
	);
}
