export function convertCents(cents, noDecimal) {
	if (noDecimal) {
		let dollars = cents / 100;
		dollars = dollars.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 });
		return dollars;
	} else {
		let dollars = cents / 100;
		dollars = dollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
		return dollars;
	}
}

export function getPriceFromOption(option) {
	let price = option.price - option.discountCalculator.getBookedOptionDiscount() / option.selectedQuantity;
	if (price < 0) {
		price = 0;
	}
	return price;
}

export function camelCaseToSentence(camelCase) {
	return (
		camelCase
			// insert a space before all caps
			.replace(/([A-Z])/g, ' $1')
			// uppercase the first character
			.replace(/^./, function (str) {
				return str.toUpperCase();
			})
	);
}

export function convertToUrl(url) {
	// const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/);
	const urlPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/);
	let string = url;

	if (urlPattern.test(string)) {
		//string is url

		///clear http && https from string
		string = string.replace('https://', '').replace('http://', '');

		//add https to string
		string = `https://${string}`;
	}
	return string;
}

export function removeCountryFromAddress(address) {
	return address.replace(', USA', '').replace(', AUS', '');
}
