import './mobile.scss';
import styled from 'styled-components';
import UIIcon from '../../../../../../../components/UI/UIIcon';
import PLImage from '../../../../../../../components/PL/PLImage';

export const UIIcon_PLServiceTechnicianEditIcon = styled(UIIcon)`
	//DEFAULT
	width: 24px;
	height: 24px;
	opacity: 0.8;
	transition: all 0.2s;

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		&:hover {
			cursor: pointer;
			transition: all 0.2s;
			transform: scale(0.9);
		}
	}
`;

export const div_PLServiceTechnicianWrapper = styled.div`
	//DEFAULT
	flex-direction: row;
	align-items: center;
	width: 100%;
`;

export const p_PLServiceTechnicianName = styled.p`
	//DEFAULT
	font-weight: 600;
	font-size: 12pt;
`;

export const p_PLServiceTechnicianEmpty = styled.p`
	//DEFAULT
	margin-top: 12px;
	padding: 12px;
	border: var(--white-40) solid 1px;
	width: 100%;
	border-radius: 6px;
`;

export const div_PLServiceTechnicianInfo = styled.div`
	//DEFAULT
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 24px;
	height: fit-content;
	width: 100%;
	margin: 4px 8px 8px 0;
`;

export const div_PLServiceTechnicianInfoLink = styled.div`
	//DEFAULT
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 24px;
	height: fit-content;
	width: 100%;
	margin: 4px 8px 8px 0;

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		&:hover {
			opacity: 0.7;
			cursor: pointer;
		}
	}
`;

export const PLImage_PLServiceTechnicianImage = styled(PLImage)`
	//DEFAULT
	width: 32px;
	height: 32px;
	border-radius: 50%;
	margin-right: 12px;
`;
