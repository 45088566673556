import React, { useEffect, useState } from 'react';
import './styles';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import { UI_LABELED_INPUT_CURRENCY_TYPE, UI_LABELED_INPUT_DROPDOWN_TYPE, UI_LABELED_INPUT_NUMBER_TYPE } from '../../../../../../components/UI/UILabeledInput/config';
import { OPTION_TYPES_DROPDOWN_VALUES, PACKAGE_TYPES_DROPDOWN_VALUES } from '../../../../../../utils/Config/Constants';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_OPTION_GENERAL_INFO } from './queries';
import { useDispatch, useSelector } from 'react-redux';
import * as strings from './strings';
import { toast } from 'react-toastify';
import { storeSelectedOption } from '../../../../../../redux/store/options/actions';
import { getSelectedOptionSelector } from '../../../../../../redux/selectors/OptionSelectors';
import { DropdownItem } from '../../../../../../components/UI/UILabeledInput/types';

export default function OptionGeneralTab({ onChangeOption }: OptionGeneralTabProps) {
	const selectedOption = useSelector(getSelectedOptionSelector);

	const dispatch = useDispatch();

	const [coverFileId, setCoverFileId] = useState<string | undefined>(selectedOption?.coverFile?.id);
	const [name, setName] = useState<string>(selectedOption?.name);
	const [price, setPrice] = useState<number | undefined>(selectedOption?.price / 100);
	const [finalFileCount, setFinalFileCount] = useState<number | undefined>(selectedOption?.finalFileCount);
	const [description, setDescription] = useState<string>(selectedOption?.description);
	const [exampleUrl, setExampleUrl] = useState<string>(selectedOption?.exampleUrl);
	const [deliveryTimeEstimate, setDeliveryTimeEstimate] = useState<string | undefined>(selectedOption?.deliveryTimeEstimate);
	const [type, setType] = useState<string>(selectedOption?.type);
	const [quantity, setQuantity] = useState<number | undefined>(selectedOption?.quantity);
	const [packageType, setPackageType] = useState<string>(selectedOption?.packageType);
	const [isSaving, setIsSaving] = useState<object>({});

	const [getOptionInfo, { data: optionData }] = useLazyQuery(GET_OPTION_GENERAL_INFO, {
		variables: { id: selectedOption?.id }
	});

	useEffect(() => {
		(async () => {
			await getOptionInfo();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (optionData) {
				const fetchedOption: OptionType = optionData['option'];
				setCoverFileId(fetchedOption.coverFile?.id || '');
				setName(fetchedOption.name);
				setPrice(fetchedOption.price / 100);
				setFinalFileCount(fetchedOption.finalFileCount);
				setDescription(fetchedOption.description);
				setExampleUrl(fetchedOption.exampleUrl);
				setDeliveryTimeEstimate(fetchedOption.deliveryTimeEstimate);
				setType(fetchedOption.type);
				setQuantity(fetchedOption.quantity);
				setPackageType(fetchedOption.packageType);
				dispatch(storeSelectedOption({ ...selectedOption, ...fetchedOption }));
			}
		})();
	}, [optionData]);

	if (!selectedOption) return null;

	const updateSavedValue = async (key, value) => {
		setIsSaving({ ...isSaving, [key]: true });
		await onChangeOption(key, value);
		delete isSaving[key];
		setIsSaving({ isSaving });
		await getOptionInfo();
	};

	const onClickCoverFile = () => {
		const input = document.getElementById('focusInput');
		input?.focus();
		toast.info(strings.coverFileClick);
	};

	return (
		<div className='optionGeneral'>
			<div className='optionGeneral--imgContainer'>
				{selectedOption.coverFile && (
					<img onClick={onClickCoverFile} className='optionGeneral--imgContainer-img' alt={strings.altCoverText} src={selectedOption.coverFile.thumbnailUrl} />
				)}
			</div>
			<UIListInputRow
				id='focusInput'
				className='optionGeneral--inputs'
				label={strings.coverFileLabel}
				placeholder={strings.coverFilePlaceholder}
				value={coverFileId}
				isLoading={isSaving['coverFile']}
				onChangeValue={setCoverFileId}
				savedValue={selectedOption.coverFile?.id}
				onSave={() => updateSavedValue('coverFile', coverFileId)}
			/>
			<UIListInputRow
				className='optionGeneral--inputs'
				label={strings.nameLabel}
				placeholder={strings.namePlaceholder}
				value={name}
				isLoading={isSaving['name']}
				onChangeValue={setName}
				savedValue={selectedOption.name}
				onSave={() => updateSavedValue('name', name)}
			/>
			<UIListInputRow
				type={UI_LABELED_INPUT_CURRENCY_TYPE}
				prefix={strings.pricePrefix}
				placeholder={strings.pricePlaceholder}
				description={strings.priceHelp}
				label={strings.priceLabel}
				value={price}
				isLoading={isSaving['price']}
				onChangeNumberValue={setPrice}
				savedValue={selectedOption.price / 100}
				onSave={() => updateSavedValue('price', price ? parseInt(String(price * 100)) : 0)}
			/>
			<UIListInputRow
				type={UI_LABELED_INPUT_NUMBER_TYPE}
				description={strings.fileCountHelp}
				placeholder={strings.filePlaceholder}
				label={strings.fileLabel}
				value={finalFileCount}
				savedValue={selectedOption.finalFileCount}
				onChangeNumberValue={setFinalFileCount}
				onSave={() => updateSavedValue('finalFileCount', parseInt(String(finalFileCount)))}
			/>
			<UIListInputRow
				label={strings.descriptionLabel}
				className='optionGeneral--inputs-alt'
				placeholder={strings.descriptionPlaceholder}
				value={description}
				isLoading={isSaving['description']}
				onChangeValue={setDescription}
				savedValue={selectedOption.description}
				onSave={() => updateSavedValue('description', description)}
			/>
			<UIListInputRow
				label={strings.exampleUrlLabel}
				className='optionGeneral--inputs-alt'
				placeholder={strings.exampleUrlPlaceholder}
				value={exampleUrl}
				isLoading={isSaving['exampleUrl']}
				onChangeValue={setExampleUrl}
				savedValue={selectedOption.exampleUrl}
				onSave={() => updateSavedValue('exampleUrl', exampleUrl)}
			/>
			<UIListInputRow
				label={strings.deliveryLabel}
				className='optionGeneral--inputs-alt'
				placeholder={strings.deliveryPlaceholder}
				value={deliveryTimeEstimate}
				isLoading={isSaving['deliveryTimeEstimate']}
				onChangeValue={setDeliveryTimeEstimate}
				savedValue={selectedOption.deliveryTimeEstimate}
				onSave={() => updateSavedValue('deliveryTimeEstimate', deliveryTimeEstimate)}
			/>
			<UIListInputRow
				type={UI_LABELED_INPUT_DROPDOWN_TYPE}
				options={OPTION_TYPES_DROPDOWN_VALUES}
				label={strings.typeLabel}
				description={strings.typeHelp}
				value={type}
				onChangeDropdownValue={(value: DropdownItem) => updateSavedValue('type', value.value)}
			/>
			<UIListInputRow
				type={UI_LABELED_INPUT_NUMBER_TYPE}
				label={strings.quantityLabel}
				description={strings.quantityHelp}
				placeholder={strings.quantityPlaceholder}
				value={quantity}
				isLoading={isSaving['quantity']}
				onChangeNumberValue={setQuantity}
				savedValue={selectedOption.quantity}
				onSave={() => updateSavedValue('quantity', quantity)}
			/>
			<UIListInputRow
				type={UI_LABELED_INPUT_DROPDOWN_TYPE}
				label={strings.packageLabel}
				description={strings.packageHelp}
				options={PACKAGE_TYPES_DROPDOWN_VALUES}
				value={packageType}
				onChangeDropdownValue={(value) => updateSavedValue('packageType', value.value)}
			/>
		</div>
	);
}
