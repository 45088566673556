import React, { useEffect, useState } from 'react';
import './styles';
import UIAddressSelect from '../../../../../../components/UI/UIAddressSelect';
import { arePointsNear } from '../../../../../../utils/Helpers/BookingHelpers';
import { SCREEN_TYPE } from '../../../../../../utils/Config/Constants';
import { getTimezoneFromServiceLocation } from '../../../../../../utils/API/GoogleTimezone';
import { useApolloClient } from '@apollo/react-hooks';
import { BookingLocationProps } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { getBookingConfigSelector } from '../../../../../../redux/selectors/BookingSelectors';
import { getScreenTypeSelector } from '../../../../../../redux/selectors/ControlSelectors';
import { setBookingConfig } from '../../../../../../redux/store/booking/actions';
import UILabeledInput from '../../../../../../components/UI/UILabeledInput';
import * as strings from './strings';
import { getRegionFromZip } from '../../config';

export default function BookingLocation({ updateBookingConfig }: BookingLocationProps) {
	const dispatch = useDispatch();
	const client = useApolloClient();

	const bookingConfig = useSelector(getBookingConfigSelector);
	const screenType = useSelector(getScreenTypeSelector);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [streetAddress, setStreetAddress] = useState<string>(bookingConfig.serviceLocation?.streetAddress ?? '');
	const [unitNumber, setUnitNumber] = useState<string>(bookingConfig.serviceLocation?.unitNumber ?? '');
	const [city, setCity] = useState<string>(bookingConfig.serviceLocation?.city ?? '');
	const [state, setState] = useState<string>(bookingConfig.serviceLocation?.state ?? '');
	const [zipCode, setZipCode] = useState<string>(bookingConfig.serviceLocation?.zipCode ?? '');
	const [alias, setAlias] = useState<string>(bookingConfig.serviceLocation?.alias ?? '');

	useEffect(() => {
		if (streetAddress || unitNumber || city || state || zipCode || alias) {
			updateBookingConfig('serviceLocation', {
				...bookingConfig.serviceLocation,
				streetAddress,
				unitNumber,
				city,
				state,
				zipCode,
				alias
			});
		}
	}, [streetAddress, city, state, zipCode, unitNumber, alias]);

	const onUpdateLocation = async (serviceLocation) => {
		let newBookingConfig = bookingConfig;

		const timezoneResponse = await getTimezoneFromServiceLocation(serviceLocation);
		if (timezoneResponse) {
			serviceLocation.timezone = timezoneResponse['timeZoneId'];
		}

		if (!serviceLocation?.zipCode) {
			//Check if within 50 miles of Guanacaste
			if (arePointsNear({ lng: serviceLocation?.longitude, lat: serviceLocation?.latitude }, { lng: -85.84, lat: 10.299167 }, 50)) {
				serviceLocation.zipCode = '50101';
			}
		}
		const region = await getRegionFromZip(serviceLocation.zipCode ?? '', client);
		updateBookingConfig('region', region);
		newBookingConfig.region = region;
		newBookingConfig.serviceLocation = serviceLocation;
		setStreetAddress(serviceLocation.streetAddress);
		setUnitNumber(serviceLocation.unitNumber);
		setCity(serviceLocation.city);
		setState(serviceLocation.state);
		setZipCode(serviceLocation.zipCode);
		setAlias(serviceLocation.alias);
		updateBookingConfig('serviceLocation', serviceLocation);
		updateBookingConfig('newBookingConfig', newBookingConfig);
		dispatch(setBookingConfig(newBookingConfig));

		setIsLoading(false);
	};

	return (
		<div className='bookingLocation fade-in-div'>
			<UIAddressSelect
				serviceLocation={bookingConfig.serviceLocation}
				googlePlaceId={bookingConfig?.serviceLocation?.googlePlaceId}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				hideMap={screenType === SCREEN_TYPE.mobile}
				updateLocation={onUpdateLocation}
			/>
			<div className='pl-separator' />
			{bookingConfig.serviceLocation ? (
				<div className='bookingLocation__fields'>
					<div className='bookingLocation__fields--streetAddressUnit'>
						<UILabeledInput
							containerClassName='bookingLocation__fields--streetAddressUnit-streetAddress'
							value={streetAddress}
							onChangeValue={setStreetAddress}
							placeholder={strings.streetAddressPlaceholder}
							label={strings.streetAddressLabel}
						/>
						<UILabeledInput
							containerClassName='bookingLocation__fields--streetAddressUnit-unit'
							value={unitNumber}
							onChangeValue={setUnitNumber}
							placeholder={strings.unitNumberPlaceholder}
							label={strings.unitNumberLabel}
						/>
					</div>
					<div className='bookingLocation__fields--cityStateZip'>
						<UILabeledInput
							containerClassName='bookingLocation__fields--cityStateZip-city'
							value={city}
							onChangeValue={setCity}
							placeholder={strings.cityPlaceholder}
							label={strings.cityLabel}
						/>
						<UILabeledInput
							containerClassName='bookingLocation__fields--cityStateZip-state'
							value={state}
							containerStyle={state?.length > 8 ? { maxWidth: 224 } : {}}
							onChangeValue={setState}
							placeholder={strings.statePlaceholder}
							label={strings.stateLabel}
						/>
						<UILabeledInput
							containerClassName='bookingLocation__fields--cityStateZip-zip'
							value={zipCode}
							onChangeValue={setZipCode}
							placeholder={strings.zipCodePlaceholder}
							label={strings.zipCodeLabel}
						/>
					</div>
					<UILabeledInput
						containerClassName='bookingLocation__fields--alias'
						value={alias}
						onChangeValue={setAlias}
						placeholder={strings.aliasPlaceholder}
						label={strings.aliasLabel}
					/>
				</div>
			) : (
				<p className='bookingLocation--helper'>{strings.pageDescription}</p>
			)}
		</div>
	);
}
