import React, { Component } from 'react';
import './approve-service-checkbox-item.scss';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/react-hoc';
import PropTypes from 'prop-types';
import UICheckbox from '../../../UI/UICheckbox/UICheckbox';
import { REQUIRED_CHECK_TYPES } from '../ApproveServiceModalConfig';
import UIModal from '../../../UI/UIModal';
import UIOverlayCard, { OVERLAY_CLOSE_TYPES } from '../../../UI/UIOverlayCard';
import UIIconButton from '../../../UI/UIIconButton';
import ServiceNotes from '../../../../pages/portal/global/IndividualService/ServiceNotes/ServiceNotes';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../../utils/Helpers/AppHelper';
import { UI_ICON_BUTTON_FILL_TYPE } from '../../../UI/UIIconButton/config';

class ApproveServiceCheckboxItem extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	//Mark: Component functions
	getAdditionalComponentProps = () => {
		const { type } = this.props;
		switch (type) {
			case REQUIRED_CHECK_TYPES.virtualTwilight.type:
				return {
					onClick: () => this.setState({ showViewOptionsModal: true })
				};
			case REQUIRED_CHECK_TYPES.notes.type:
				return {
					onClick: () => this.setState({ showServiceNotesModal: true })
				};
			case REQUIRED_CHECK_TYPES.reEditsRequired.type:
				return {
					onClick: () => this.setState({ showViewReEditInfoModal: true })
				};
			case REQUIRED_CHECK_TYPES.approve.type:
			case REQUIRED_CHECK_TYPES.markComplete.type:
				return {
					onClick: () => this.setState({ showFinalModal: true })
				};
			default:
				return;
		}
	};

	//Mark: Render functions
	render() {
		const { onCheckChanged, isChecked, title, subtext, renderAdditionalComponent, type, approveDisabled } = this.props;

		return (
			<div key={type} className='approveServiceCheckboxItem'>
				<div className='approveServiceCheckboxItem__top'>
					<UICheckbox
						className='approveServiceCheckboxItem__top--checkbox'
						onChangeExpansion={onCheckChanged}
						status={isChecked ? UICheckbox.CHECKED : UICheckbox.UNCHECKED}
					/>
					<p className='approveServiceCheckboxItem__top--title'>{title}</p>
				</div>

				<div className='approveServiceCheckboxItem__content'>
					{subtext && <p className='approveServiceCheckboxItem__content--subtext'>{subtext}</p>}
					{renderAdditionalComponent && renderAdditionalComponent(this.getAdditionalComponentProps(), approveDisabled)}
				</div>
				<div className='pl-separator' />
				{this.renderViewOptionsModal()}
				{this.renderViewReEditInfoModal()}
				{this.renderServiceNotesModal()}
				{this.renderFinalModal()}
			</div>
		);
	}

	renderServiceNotesModal = () => {
		const { showServiceNotesModal } = this.state;
		const { updateService, service } = this.props;

		const hideModal = () => {
			this.setState({ showServiceNotesModal: false });
		};

		return (
			<UIModal condition={showServiceNotesModal} hideModal={hideModal}>
				<UIOverlayCard hideModal={hideModal} title={'Notes'} cardStyle={{ width: 512 }} closeType={OVERLAY_CLOSE_TYPES.back}>
					<ServiceNotes service={service} updateService={updateService} />
				</UIOverlayCard>
			</UIModal>
		);
	};
	renderViewOptionsModal = () => {
		const { showViewOptionsModal } = this.state;
		const { service } = this.props;

		const hideModal = () => {
			this.setState({ showViewOptionsModal: false });
		};

		return (
			<UIModal condition={showViewOptionsModal} hideModal={hideModal}>
				<UIOverlayCard hideModal={hideModal} title={'Options purchased on this service'} cardStyle={{ width: 512 }} closeType={OVERLAY_CLOSE_TYPES.back}>
					{service.bookedOptions.map((bookedOption) => (
						<div key={bookedOption.id} className={bookedOption.name && 'approveServiceCheckboxItem__optionList'}>
							{bookedOption.name && <p className='approveServiceCheckboxItem__optionList--name'>{bookedOption.name}</p>}
						</div>
					))}
				</UIOverlayCard>
			</UIModal>
		);
	};

	renderFinalModal = () => {
		const { showFinalModal, isApproving } = this.state;
		const { approveDisabled, approveService, markComplete } = this.props;

		const hideModal = () => {
			this.setState({ showFinalModal: false });
		};

		return (
			<UIModal condition={showFinalModal} hideModal={hideModal}>
				<UIOverlayCard
					hideModal={hideModal}
					title={approveService ? 'Approve this service?' : 'Mark complete?'}
					description='Are you sure?'
					cardStyle={{ width: 512 }}
					closeType={OVERLAY_CLOSE_TYPES.back}>
					<div>
						<UIIconButton
							dark
							className='approveServiceCheckboxItem__button'
							isDisabled={approveDisabled}
							disabledErrorMessage={
								approveService
									? 'You must complete all items on the check list to approve this service.'
									: 'You must complete all items on the check list to mark this service complete.'
							}
							isLoading={isApproving}
							type={UI_ICON_BUTTON_FILL_TYPE}
							text={'confirm'}
							onClick={approveService ? approveService : markComplete}
						/>
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	renderViewReEditInfoModal = () => {
		const { showViewReEditInfoModal } = this.state;
		const hideModal = () => {
			this.setState({ showViewReEditInfoModal: false });
		};

		return (
			<UIModal condition={showViewReEditInfoModal} hideModal={hideModal}>
				<UIOverlayCard hideModal={hideModal} title={'re-edit admin checklist'} cardStyle={{ width: 512 }} closeType={OVERLAY_CLOSE_TYPES.back}>
					<div>
						<p className='approveServiceCheckboxItem__text'>
							If any photo needs re-editing, you have the option to select a photo individually or multi-select to be sent as a service request.
						</p>
						<p className='approveServiceCheckboxItem__text'>Make sure to have a detailed instruction for the editing team.</p>
						<p className='approveServiceCheckboxItem__text'>
							Put the shoot in admin hold. Mute notifications so that when photos come back agents won’t get bombarded with our notifications as we
							manipulate photo statuses.
						</p>
						<p className='approveServiceCheckboxItem__text'>Update admin notes.</p>
						<UIIconButton
							dark
							className='approveServiceCheckboxItem__button'
							text='update notes'
							onClick={() => this.setState({ showServiceNotesModal: true })}
						/>
						<br />
						<p className='approveServiceCheckboxItem__text'>
							Once re-editing is complete and you approve of the service request, delete the service request, unmute, then deliver photos by approving the
							shoot or setting to proof.
						</p>
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};
}

ApproveServiceCheckboxItem.propTypes = {
	onCheckChanged: PropTypes.func.isRequired,
	approveService: PropTypes.func.isRequired,
	markComplete: PropTypes.func,
	updateService: PropTypes.func.isRequired,
	service: PropTypes.object.isRequired,
	isChecked: PropTypes.bool.isRequired,
	approveDisabled: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	subtext: PropTypes.string,
	type: PropTypes.string,
	renderAdditionalComponent: PropTypes.func
};

export default withApollo(connect(commonMapStateToProps, commonMapDispatchToProps)(ApproveServiceCheckboxItem));
