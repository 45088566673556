export const getTotalPriceFromBookableOption = (bookableOption: BookableOptionType): number => {
	const optionStandardPrice = bookableOption.option.price * bookableOption.selectedQuantity;

	let discountAmount = 0;
	if (bookableOption.discountCalculator) {
		discountAmount = bookableOption.discountCalculator.getBookedOptionDiscount();
	}
	return optionStandardPrice - discountAmount;
};

export const getSingleQuantityPriceFromBookableOption = (bookableOption: BookableOptionType): number => {
	const optionStandardPrice = bookableOption.option.price;

	let discountAmount = 0;
	if (bookableOption.discountCalculator) {
		discountAmount = bookableOption.discountCalculator.getBookedOptionDiscount() / bookableOption.selectedQuantity;
	}
	return optionStandardPrice - discountAmount;
};

export const getOriginalPriceFromBookableOption = (bookableOption: BookableOptionType): number => {
	return bookableOption.option.price * bookableOption.selectedQuantity;
};
