import { useSelector } from 'react-redux';
import { getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import moment from 'moment-timezone';
import { guessCurrentTimezone } from '../../../../../../utils/Helpers/AppHelper';
import { getPhotographerPayFromOptions } from '../../../../../../utils/Helpers/PaymentHelper/PaymentHelper';
import UIHover from '../../../../../UI/UIHover/UIHover';
import { EDIT_TYPE_SERVICE, SCREEN_TYPE } from '../../../../../../utils/Config/Constants';
import { EditPhotoIcon, TechniciansIcon } from '../../../../../../assets/icons';
import React from 'react';
import { getScreenTypeSelector } from '../../../../../../redux/selectors/ControlSelectors';
import './styles';
import { useHistory, useLocation } from 'react-router-dom';

export default function TablePaymentRow({ service }: TablePaymentRowProps) {
	const signedInUser = useSelector(getSignedInUserSelector);
	const screenType = useSelector(getScreenTypeSelector);

	const history = useHistory();
	const location = useLocation();

	if (!service) return null;

	const onClick = (e) => {
		e.stopPropagation();
		onClickSidebarService();
	};

	const onClickSidebarService = () => {
		history.push(location.pathname + `?service=${service.id}`);
	};

	const renderServiceDate = () => {
		let serviceDate = service.serviceDate
			? moment(new Date(service.serviceDate))
					.tz(service.region ? service.region.timezone : guessCurrentTimezone())
					.format('MMM Do YYYY')
			: 'Invalid date';
		let serviceTime = service.serviceDate
			? moment(service.serviceDate)
					.tz(service.region ? service.region.timezone : guessCurrentTimezone())
					.format('h:mm a z')
			: '';
		return (
			<div className='tablePayment__row--item-date'>
				<p className='tablePayment__row--item-date--date'>{serviceDate}</p>
				<p className='tablePayment__row--item-date--time'>{serviceTime}</p>
			</div>
		);
	};

	const renderServiceAddress = () => {
		return (
			<div className='tablePayment__row--item-address'>
				<p className='tablePayment__row--item-address--text'>{service.property.address}</p>
				<p className='tablePayment__row--item-address--alias'>{service.property.alias}</p>
			</div>
		);
	};

	const renderPay = () => {
		let photographerPay, traineePay;
		if (service.photographer) {
			photographerPay = getPhotographerPayFromOptions(service, service.photographer);
		}
		if (service.trainee) {
			traineePay = getPhotographerPayFromOptions(service, service.trainee);
		}
		if (!signedInUser.isInternalTech)
			return (
				<div className='tablePayment__row--item-pay'>
					<p className='tablePayment__row--item-pay--text'>
						${service.trainee && signedInUser ? (service.trainee.id === signedInUser.id ? traineePay : photographerPay) : photographerPay}
					</p>
				</div>
			);
		else return null;
	};

	const renderOptions = () => {
		return (
			<div className='tablePayment__options'>
				{service.bookedOptions.map((bookedOption) => {
					return (
						<p key={bookedOption.id} className='tablePayment__options--text'>
							{bookedOption.name}
						</p>
					);
				})}
			</div>
		);
	};

	const renderAgent = () => {
		return (
			<div className='tablePayment__row--item-agent'>
				<p>{service.agent.name}</p>
			</div>
		);
	};

	const renderType = () => {
		if (screenType === SCREEN_TYPE.mobile || screenType === SCREEN_TYPE.tablet) {
			return null;
		} else {
			return (
				<UIHover text={service.type === EDIT_TYPE_SERVICE ? 'edit type' : 'capture type'}>
					<img
						src={service.type === EDIT_TYPE_SERVICE ? EditPhotoIcon.white : TechniciansIcon}
						alt={'service type'}
						className='tablePayment__row--item-type'
					/>
				</UIHover>
			);
		}
	};

	return (
		<div className='tablePayment__row ' key={service.id} onClick={onClick}>
			{renderServiceDate()}
			{renderServiceAddress()}
			{renderPay()}
			{renderOptions()}
			{renderAgent()}
			{renderType()}
		</div>
	);
}
