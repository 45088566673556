import React, { useState } from 'react';
import './styles';
import { QUICK_ACTIONS, TYPES } from '../../config';
import UIIconButton from '../../../UIIconButton';
import { DownloadIcon, SortWhite } from '../../../../../assets/icons';
import { EDIT_TYPE_SERVICE, USER_ROLE_AFFILIATE, USER_ROLE_EDITING_MANAGER, USER_ROLE_EDITOR, USER_ROLE_PHOTOGRAPHER } from '../../../../../utils/Config/Constants';
import UICheckbox from '../../../UICheckbox/UICheckbox';
import UIIcon from '../../../UIIcon';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../../../redux/selectors/CurrentUserSelectors';
import { useMutation } from '@apollo/react-hooks';
import Queries from '../../../../../utils/API/Queries';
import UIModal from '../../../UIModal';
import CreateServiceRequestModal from '../../../../modals/CreateServiceRequestModal';
import * as strings from '../../strings';
import UIOverlayCard from '../../../UIOverlayCard';
import DownloadModal from '../../../../modals/Download';
import { getApiDownloadUrl } from '../../../../../utils/API/APIConfig';
import ServicePhotosSort from '../../../../../pages/portal/global/IndividualService/ServicePhotosSort/ServicePhotosSort';
import ChangeFileStatusModal from '../../../../modals/ChangeFileStatusModal';
import { UI_ICON_BUTTON_CANCEL_TYPE, UI_ICON_BUTTON_OUTLINE_TYPE } from '../../../UIIconButton/config';

export default function UIMultiImageSelectQuickActions({
	disableSort,
	type,
	rightItem,
	updateData,
	files,
	listing,
	service,
	setSelectedFiles,
	selectedFiles,
	setIsUpdating,
	isUpdating
}: UIMultiImageSelectQuickActionsProps) {
	const signedInUser = useSelector(getSignedInUserSelector);
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const [floorPlanToDownload, setFloorPlanToDownload] = useState<FileType>();
	const [downloadSelectedFiles, setDownloadSelectedFiles] = useState<boolean>(false);
	const [editRequestFiles, setEditRequestFiles] = useState<boolean>(false);
	const [sortMode, setSortMode] = useState<boolean>(false);
	const [isSorting, setIsSorting] = useState<boolean>(false);
	const [deleteFiles, setDeleteFiles] = useState<boolean>(false);
	const [isDeletingSelectedFiles, setIsDeletingSelectedFiles] = useState<boolean>(false);
	const [showAdminOptionsModal, setShowAdminOptionsModal] = useState<boolean>(false);

	const [mutateDeleteFiles] = useMutation(Queries.UPDATE_FILES, {
		onError: () => {
			setDeleteFiles(false);
			setSelectedFiles([]);
			setIsDeletingSelectedFiles(false);
			toast.error(`Error deleting selected files.`);
		}
	});

	const [mutateSortFiles] = useMutation(Queries.UPDATE_FILES, {
		onError: async () => {
			await updateData();
			setSortMode(false);
			setIsSorting(false);
			toast.error('File order not updated!');
		}
	});

	const [mutateSetFileVisibility] = useMutation(Queries.UPDATE_FILES, {
		onError: async () => {
			await updateData();
			setSelectedFiles([]);
			toast.error('Error updating file visibility!');
		}
	});

	const selectAllFiles = () => {
		setSelectedFiles(files);
		setIsUpdating({ ...isUpdating, ['select all']: false });
	};

	const deleteSelectedFiles = async () => {
		if (!isDeletingSelectedFiles) {
			setIsDeletingSelectedFiles(true);
		}

		let deleteFiles = selectedFiles.map((item) => {
			return { id: item.id, isActive: false };
		});
		let id = listing ? listing.id : service?.id;

		const response = await mutateDeleteFiles({
			variables: {
				requestedUpdates: deleteFiles,
				id: id
			}
		});
		const { updateFiles } = response['data'];
		if (updateFiles) {
			await updateData();
			setDeleteFiles(false);
			setSelectedFiles([]);
			setIsDeletingSelectedFiles(false);
			setIsUpdating({ ...isUpdating, [QUICK_ACTIONS.DELETE.type]: false });
			toast.success(`Successfully deleted all selected files.`);
		}
	};

	const downloadSelectedFilesByType = async (size: string) => {
		let filesArr: string[] = [];
		let URL;
		let baseUrl = getApiDownloadUrl(true);

		for (let i = 0; i < selectedFiles.length; i++) {
			let file = `"${selectedFiles[i].id}"`;
			filesArr.push(file);
		}
		!size
			? (URL = `${baseUrl}/file-transfer/download-by-ids?&fileIds=[` + filesArr + ']')
			: (URL = `${baseUrl}/file-transfer/download-by-ids?size=${size}&fileIds=[` + filesArr + ']');

		window.open(URL, '_blank');
	};

	const toggleFileVisibility = async () => {
		let visibilityFiles = selectedFiles.map((item) => {
			return { ...item, isVisible: !item.isVisible };
		});

		let variables = {
			requestedUpdates: visibilityFiles.map((item) => {
				return { id: item.id, isVisible: item.isVisible };
			})
		};

		if (service) {
			variables['service'] = service?.id;
		}

		if (listing) {
			variables['listing'] = listing.id;
		}

		const response = await mutateSetFileVisibility({ variables });
		const { updateFiles } = response['data'];

		if (updateFiles) {
			await updateData();
			setSelectedFiles(visibilityFiles);
		}
	};

	const onClickQuickAction = async (type: string) => {
		switch (type) {
			case QUICK_ACTIONS.DELETE.type:
				setIsUpdating({ ...isUpdating, [type]: true });
				setDeleteFiles(true);
				break;
			case QUICK_ACTIONS.EDIT_REQUEST.type:
				setIsUpdating({ ...isUpdating, [type]: true });
				setEditRequestFiles(true);
				break;
			case QUICK_ACTIONS.ADMIN.type:
				setShowAdminOptionsModal(true);
				setIsUpdating({ ...isUpdating, [type]: true });
				break;
			case QUICK_ACTIONS.VISIBILITY.type:
				setIsUpdating({ ...isUpdating, [type]: true });
				await toggleFileVisibility();
				await updateData();
				setIsUpdating({ ...isUpdating, [type]: false });
				toast.success('Successfully toggled visibility in virtual tour and downloads');
				break;
			case QUICK_ACTIONS.DOWNLOAD.type:
				setDownloadSelectedFiles(true);
				break;
		}
	};

	const getQuickActions = () => {
		let quickActions: UIMultiImageSelectQuickAction[] = [];

		//Create actions array here
		if (type === TYPES.floorPlan) {
			quickActions.push(QUICK_ACTIONS.VISIBILITY);
			quickActions.push(QUICK_ACTIONS.DOWNLOAD);

			if (isUserAdmin) {
				quickActions.push(QUICK_ACTIONS.DELETE);
				quickActions.push(QUICK_ACTIONS.ADMIN);
			}
			if (signedInUser.role === USER_ROLE_AFFILIATE) {
				quickActions = [];
			}
		} else if (type === TYPES.video) {
			quickActions.push(QUICK_ACTIONS.DOWNLOAD);

			if (isUserAdmin) {
				quickActions.push(QUICK_ACTIONS.DELETE);
				quickActions.push(QUICK_ACTIONS.ADMIN);
			}
			if (signedInUser.role === USER_ROLE_AFFILIATE) {
				quickActions = [];
			}
		} else if (type === TYPES.socialMedia) {
			quickActions.push(QUICK_ACTIONS.DOWNLOAD);
			quickActions.push(QUICK_ACTIONS.DELETE);
		} else if (type === TYPES.image) {
			quickActions.push(QUICK_ACTIONS.DOWNLOAD);
			if ([USER_ROLE_EDITING_MANAGER, USER_ROLE_EDITOR].indexOf(signedInUser.role) === -1) {
				const fileService = selectedFiles.filter((file) => file.service)[0];
				if (fileService || service?.id) {
					quickActions.push(QUICK_ACTIONS.EDIT_REQUEST);
				}
			}
			quickActions.push(QUICK_ACTIONS.VISIBILITY);
			if (!listing && service) {
				if (service.type === EDIT_TYPE_SERVICE) {
					if (signedInUser.role === USER_ROLE_PHOTOGRAPHER && service.photographer && service.photographer.id === signedInUser.id) {
						quickActions.push(QUICK_ACTIONS.DELETE);
					}
				}
			} else {
				if (listing && listing.client && signedInUser.id === listing.client.id) {
					quickActions.push(QUICK_ACTIONS.DELETE);
				}
			}

			if (signedInUser.role === USER_ROLE_EDITOR) {
				quickActions.push(QUICK_ACTIONS.DELETE);
			}
			if (signedInUser.role === USER_ROLE_AFFILIATE) {
				quickActions = [];
			}
			if (isUserAdmin) {
				quickActions.push(QUICK_ACTIONS.DELETE);
				quickActions.push(QUICK_ACTIONS.ADMIN);
			}
		}
		return quickActions;
	};

	const renderQuickActions = () => {
		const quickActions = getQuickActions();

		if (quickActions.length === 0) {
			return undefined;
		}

		const status = selectedFiles.length > 0 ? (selectedFiles.length === files.length ? UICheckbox.CHECKED : UICheckbox.EXPANDED) : UICheckbox.UNCHECKED;

		const renderCheckbox = () => {
			if (files.length === 0) return null;
			return (
				<UICheckbox
					subBox
					status={status}
					onChangeExpansion={() => {
						switch (status) {
							case UICheckbox.UNCHECKED:
								selectAllFiles();
								break;
							case UICheckbox.EXPANDED:
								selectAllFiles();
								break;
							case UICheckbox.CHECKED:
								setSelectedFiles([]);
								break;
						}
					}}
					className='uiMultiImageSelectQuickActions__actions__container--checkbox'
				/>
			);
		};

		if (files.length === 0) return null;
		return (
			<div className='uiMultiImageSelectQuickActions__actions'>
				{renderCheckbox()}
				{selectedFiles.length > 0 && <p> {selectedFiles.length}</p>}
				{selectedFiles.length > 0 && (
					<div className='fade-in-div uiMultiImageSelectQuickActions__actions__container'>
						{quickActions.map((quickAction) => {
							return (
								<div key={quickAction.type} onClick={() => onClickQuickAction(quickAction.type)} className={quickAction.containerClassName}>
									<UIIcon
										isLoading={isUpdating[quickAction.type]}
										className='uiMultiImageSelectQuickActions__actions__container--icon'
										iconSrc={quickAction.icon()}
										alt={'quick action'}
									/>
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	};

	const renderServiceRequestModal = () => {
		const fileService = selectedFiles.filter((file) => file.service)[0];
		if (!fileService && !service?.id) return null;

		const closeModal = () => {
			setEditRequestFiles(false);
			setIsUpdating({ ...isUpdating, [QUICK_ACTIONS.EDIT_REQUEST.type]: false });
		};

		return (
			<UIModal condition={editRequestFiles}>
				<CreateServiceRequestModal
					serviceId={fileService?.service?.id ?? service?.id}
					files={selectedFiles}
					updateService={updateData}
					hideModal={closeModal}
					onCreate={() => {
						setEditRequestFiles(false);
						setIsUpdating({ ...isUpdating, [QUICK_ACTIONS.EDIT_REQUEST.type]: false });
						toast.success(strings.creationSuccess);
					}}
				/>
			</UIModal>
		);
	};

	const renderDownloadSelectedFilesModal = () => {
		const closeModal = () => {
			setDownloadSelectedFiles(false);
			setIsUpdating({ ...isUpdating, [QUICK_ACTIONS.DOWNLOAD.type]: false });
		};
		return (
			<UIModal condition={downloadSelectedFiles}>
				<UIOverlayCard hideModal={closeModal} title='What size would you like to download?' description={`Selected file count: ${selectedFiles.length}`}>
					<div key={'web-2000px'} onClick={() => downloadSelectedFilesByType('web')} className='UiMultiFileSelect__downloadSelected'>
						<UIIcon alt={'web'} iconSrc={DownloadIcon.white} className='UiMultiFileSelect__downloadSelected--icon' />
						<p className='UiMultiFileSelect__downloadSelected--type'>web</p>
						<p className='UiMultiFileSelect__downloadSelected--size'>{'<'} 2000px</p>
					</div>
					<div key={'print-3500px'} onClick={() => downloadSelectedFilesByType('print')} className='UiMultiFileSelect__downloadSelected'>
						<UIIcon alt={'web'} iconSrc={DownloadIcon.white} className='UiMultiFileSelect__downloadSelected--icon' />
						<p className='UiMultiFileSelect__downloadSelected--type'>print</p>
						<p className='UiMultiFileSelect__downloadSelected--size'>3500px</p>
					</div>
					<div key={'original-3500+px'} onClick={() => downloadSelectedFilesByType('original')} className='UiMultiFileSelect__downloadSelected'>
						<UIIcon alt={'web'} iconSrc={DownloadIcon.white} className='UiMultiFileSelect__downloadSelected--icon' />
						<p className='UiMultiFileSelect__downloadSelected--type'>extra large</p>
						<p className='UiMultiFileSelect__downloadSelected--size'>{'>= 3500px'}</p>
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderChangeFileStatusModal = () => {
		const hideModal = () => {
			setShowAdminOptionsModal(false);
			setIsUpdating({ ...isUpdating, [QUICK_ACTIONS.ADMIN.type]: false });
		};
		return (
			<UIModal condition={showAdminOptionsModal}>
				<ChangeFileStatusModal refetchData={updateData} files={selectedFiles} hideModal={hideModal} />
			</UIModal>
		);
	};

	const renderDeleteModal = () => {
		const closeModal = () => {
			setDeleteFiles(false);
			setIsUpdating({ ...isUpdating, [QUICK_ACTIONS.DELETE.type]: false });
		};
		return (
			<UIModal condition={deleteFiles}>
				<UIOverlayCard hideModal={closeModal} title={'Delete Images?'} description={'Are you sure you want to delete these images?'}>
					<div className='uiOverlayCard__buttons'>
						<UIIconButton dark isLoading={isDeletingSelectedFiles} type={UI_ICON_BUTTON_CANCEL_TYPE} text={'yes, delete'} onClick={deleteSelectedFiles} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderDownloadFileModal = () => {
		const hideModal = () => setFloorPlanToDownload(undefined);

		return (
			<UIModal condition={floorPlanToDownload !== undefined} className='plModal-zIndex-fix'>
				<DownloadModal
					downloadFilename={floorPlanToDownload?.filename || ''}
					hideModal={hideModal}
					individualFile={floorPlanToDownload}
					listingId={listing ? listing.id : undefined}
					serviceId={service ? service.id : undefined}
				/>
			</UIModal>
		);
	};

	const onSortEnd = async (files: FileType[], showMessage: boolean) => {
		setIsSorting(true);
		const response = await mutateSortFiles({
			variables: {
				requestedUpdates: files.map((file, index) => {
					return {
						id: file.id,
						index
					};
				})
			}
		});
		const { updateFiles } = response['data'];
		if (updateFiles) {
			await updateData();
			setSortMode(false);
			setIsSorting(false);
			if (showMessage) {
				toast.success('File order updated');
			}
		}
	};

	if (sortMode) {
		return (
			<ServicePhotosSort
				isSorting={isSorting}
				saveOrder={(updateFiles: FileType[], showMessage: boolean) => onSortEnd(updateFiles, showMessage)}
				updateData={updateData}
				goBack={() => setSortMode(false)}
				images={files}
			/>
		);
	}

	const shouldShowSort = !disableSort && files.length > 1 && type !== TYPES.floorPlan;

	return (
		<div className='uiMultiImageSelectQuickActions'>
			<div className='uiMultiImageSelectQuickActions__header'>
				{shouldShowSort && (
					<UIIconButton
						className='uiMultiImageSelectQuickActions--sort'
						icon={SortWhite}
						style={{ marginRight: 24 }}
						text={'sort'}
						type={UI_ICON_BUTTON_OUTLINE_TYPE}
						onClick={() => setSortMode(true)}
					/>
				)}
				{rightItem}
			</div>
			{renderQuickActions()}
			{renderDownloadFileModal()}
			{renderChangeFileStatusModal()}
			{renderDeleteModal()}
			{renderServiceRequestModal()}
			{renderDownloadSelectedFilesModal()}
		</div>
	);
}
