import gql from 'graphql-tag';

export const GET_SERVICE_FOR_PROOFING = gql`
	query GetServiceForProofing($id: String!) {
		service(id: $id) {
			id
			type
			listing {
				id
			}
			bookedOptions {
				id
				quantity
				option {
					id
					finalFileCount
					quantity
				}
			}
		}
	}
`;

export const GET_FILES_FOR_PROOFING = gql`
	query GetFilesForProofing($limit: Int, $query: JSON!, $order: [[String]]) {
		files(limit: $limit, query: $query, order: $order) {
			id
			isActive
			createdAt
			updatedAt
			type
			filename
			index
			status
			isVisible
			watermarkUrl
			thumbnailUrl
			originalUrl
			printUrl
			allowFixRequest
			webUrl
			metadata
			width
			height
			tags
			service {
				id
				type
			}
		}
	}
`;
