// Created by jarongallo at 6/10/21
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as S from './styles';
import { Link } from 'react-router-dom';
import {
	EDIT_TYPE_SERVICE,
	STATUS_FINDING_PREFERRED_TECHNICIAN,
	USER_ROLE_EDITING_MANAGER,
	USER_ROLE_EDITOR,
	USER_ROLE_PHOTOGRAPHER
} from '../../../../../../utils/Config/Constants';
import { EditIcon } from '../../../../../../assets/icons';
import UIModal from '../../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../../components/UI/UIOverlayCard';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import EditPreferredSPModal from './components/EditPreferredSPModal';
import EditServiceSPModal from './components/EditServiceSPModal';
import * as strings from './strings';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_PL_TECHNICIAN, GET_SERVICE_WITH_TECHNICIAN } from './queries';
import UIHover from '../../../../../../components/UI/UIHover/UIHover';
import { CHILD_TYPE } from '../../../../../../components/UI/UIListInputRow/config';

export default function PLServiceTechnician({ serviceId }: PLServiceTechnicianProps) {
	const signedInUser = useSelector(getSignedInUserSelector);
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const [getTechnician, { data: technicianUserData }] = useLazyQuery(GET_PL_TECHNICIAN);

	const [showEditTechnicianModal, setShowEditTechnicianModal] = useState(false);
	const [service, setService] = useState<ServiceType>();
	const [technician, setTechnician] = useState<UserType | undefined>(undefined);

	const isPreferredSPService = useMemo(() => service?.status === STATUS_FINDING_PREFERRED_TECHNICIAN, [service?.status]);

	const [getService, { data: serviceData }] = useLazyQuery(GET_SERVICE_WITH_TECHNICIAN, { variables: { id: serviceId } });

	const shouldRenderComponent = () => {
		if (!service) return false;
		if (signedInUser.role === USER_ROLE_EDITOR || signedInUser.role === USER_ROLE_EDITING_MANAGER) return false;
		if (signedInUser.role === USER_ROLE_PHOTOGRAPHER && service.client.id === signedInUser.id) return true;
		if (service.type === EDIT_TYPE_SERVICE) return false;
		return signedInUser.role !== USER_ROLE_PHOTOGRAPHER;
	};

	const onClickEditTechnician = useCallback(() => setShowEditTechnicianModal(true), []);
	const hideEditTechnicianModal = useCallback(() => setShowEditTechnicianModal(false), []);

	useEffect(() => {
		(async () => {
			await getService();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setService(serviceData['service']);
				if (serviceData['service'].photographer !== null) {
					let variables = { id: serviceData['service'].photographer.id };
					await getTechnician({ variables });
				} else {
					setTechnician(undefined);
				}
			}
		})();
	}, [serviceData]);

	useEffect(() => {
		if (technicianUserData) {
			setTechnician(technicianUserData['user']);
		}
	}, [technicianUserData]);

	if (!service) return null;

	if (!shouldRenderComponent()) {
		return null;
	}

	const preferredPhotographer = service.preferredPhotographers[0];
	const mainLabel = isPreferredSPService ? strings.preferredServiceProviderLabel : strings.serviceProviderLabel;

	const renderTechnicianEmptySet = () => {
		if (isPreferredSPService) return null;
		return (
			<S.div_PLServiceTechnicianInfo>
				<S.p_PLServiceTechnicianEmpty>{strings.noTechString}</S.p_PLServiceTechnicianEmpty>
			</S.div_PLServiceTechnicianInfo>
		);
	};

	const renderPreferredEmptySet = () => {
		if (!isPreferredSPService) return null;
		return (
			<S.div_PLServiceTechnicianInfo>
				<S.p_PLServiceTechnicianEmpty>{strings.noPreferredPhotographersString}</S.p_PLServiceTechnicianEmpty>
			</S.div_PLServiceTechnicianInfo>
		);
	};

	const renderTechInfo = (tech: UserType) => {
		const renderUserInfo = () => {
			return (
				<>
					<S.PLImage_PLServiceTechnicianImage
						style={{ marginLeft: 12 }}
						imageStyle={{ width: '100%', height: '100%', objectFit: 'cover' }}
						borderRadius={16}
						alt={tech.name}
						src={tech.image}
					/>
					<S.p_PLServiceTechnicianName>{tech.name}</S.p_PLServiceTechnicianName>
				</>
			);
		};

		if (isUserAdmin) {
			return (
				<S.div_PLServiceTechnicianWrapper>
					<Link to={(tech && `/photographer/${tech.id}`) || ''}>
						<S.div_PLServiceTechnicianInfoLink>{renderUserInfo()}</S.div_PLServiceTechnicianInfoLink>
					</Link>
				</S.div_PLServiceTechnicianWrapper>
			);
		} else {
			return (
				<S.div_PLServiceTechnicianWrapper>
					<S.div_PLServiceTechnicianInfo>{renderUserInfo()}</S.div_PLServiceTechnicianInfo>
				</S.div_PLServiceTechnicianWrapper>
			);
		}
	};

	const renderPreferredServiceProvider = () => {
		if (!isPreferredSPService) return null;
		if (!preferredPhotographer) return renderPreferredEmptySet();
		return renderTechInfo(preferredPhotographer);
	};

	const renderTechnician = () => {
		if (isPreferredSPService) return null;
		if (!technician) return renderTechnicianEmptySet();
		return renderTechInfo(technician);
	};

	const renderEditTechnicianModal = () => {
		const renderModalContent = () => {
			if (isPreferredSPService) {
				return <EditPreferredSPModal renderButton serviceId={service.id} queryService={getService} />;
			}
			return <EditServiceSPModal technician={technician} service={service} queryService={getService} />;
		};

		return (
			<UIModal condition={showEditTechnicianModal}>
				<UIOverlayCard hideModal={hideEditTechnicianModal} title={isPreferredSPService ? strings.editPreferredTechString : strings.editTechString}>
					{renderModalContent()}
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderRightItems = () => {
		if (service.type === EDIT_TYPE_SERVICE || !signedInUser || !isUserAdmin) return;

		return (
			<UIHover text={isPreferredSPService ? strings.editPreferredTechHover : strings.editTechHover}>
				<S.UIIcon_PLServiceTechnicianEditIcon iconSrc={EditIcon.white} onClick={onClickEditTechnician} alt={strings.editIconAlt} />
			</UIHover>
		);
	};

	return (
		<UIListInputRow label={mainLabel} type={CHILD_TYPE} rightItems={renderRightItems()}>
			{renderTechnician()}
			{renderPreferredServiceProvider()}
			{renderEditTechnicianModal()}
		</UIListInputRow>
	);
}
