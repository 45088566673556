import React, { useState } from 'react';
import './styles';
import { useDispatch, useSelector } from 'react-redux';
import UIOverlayCard from '../../UI/UIOverlayCard';
import UIScheduleRequest from '../../UI/UIScheduleRequest';
import UIModal from '../../UI/UIModal';
import { ZendeskAPI } from 'react-zendesk';
import ServiceProofing from '../../../pages/portal/global/IndividualService/ServiceProofing';
import * as strings from './strings';
import UIIcon from '../../UI/UIIcon';
import { InfoIcon } from '../../../assets/icons';
import UIHover from '../../UI/UIHover/UIHover';
import { getPendingScheduleRequestsSelector, getServicesRequiringProofingSelector } from '../../../redux/selectors/ActionItemsSelectors';
import { useHistory, useLocation } from 'react-router-dom';
import { setShowActionsNeeded } from '../../../redux/store/control/actions';
import { getShowActionsNeededSelector } from '../../../redux/selectors/ControlSelectors';
import queryString from 'querystring';
import { removeURLParameter } from '../../../utils/Helpers/AppHelper';
import { getSignedInUserSelector } from '../../../redux/selectors/CurrentUserSelectors';
import { useFetchRequiredActions } from '../../../hooks';

export const INFO_TYPES = {
	proofing: 'proofing',
	scheduleRequests: 'scheduleRequests'
};

export default function AgentActionItemsModal({}: AgentActionItemsModalProps) {
	const [serviceToProof, setServiceToProof] = useState<ServiceType>();
	const [refreshToken, setRefreshToken] = useState<number>(0);

	const servicesRequiringProofing = useSelector(getServicesRequiringProofingSelector);
	const pendingScheduleRequests = useSelector(getPendingScheduleRequestsSelector);
	const showActionsNeeded = useSelector(getShowActionsNeededSelector);
	const signedInUser = useSelector(getSignedInUserSelector);

	const location = useLocation();
	const dispatch = useDispatch();
	const history = useHistory();

	useFetchRequiredActions(refreshToken);

	const containsQueryString = queryString.parse(location.search.replace('?', ''))['showActionsNeeded'] === 'true';
	if (containsQueryString && signedInUser && !showActionsNeeded) {
		setTimeout(() => {
			dispatch(setShowActionsNeeded(true));
			const noLogin = removeURLParameter(location.search, 'login');
			const search = removeURLParameter(noLogin, 'showActionsNeeded');
			history.replace({ search });
		}, 1000);
	}

	const hideModal = () => dispatch(setShowActionsNeeded(false));

	const renderInfoIcon = (type) => {
		switch (type) {
			case INFO_TYPES.proofing:
				return (
					<UIHover text={strings.proofingInfo}>
						<UIIcon className='agentActionItems--title-info' iconSrc={InfoIcon} alt={'proofing info'} />
					</UIHover>
				);
			case INFO_TYPES.scheduleRequests:
				return (
					<UIHover text={strings.scheduleRequestsInfo}>
						<UIIcon className='agentActionItems--title-info' iconSrc={InfoIcon} alt={'schedule requests info'} />
					</UIHover>
				);
			default:
				return null;
		}
	};

	const refreshData = () => setRefreshToken(refreshToken + 1);

	const renderProofingModal = () => {
		if (!serviceToProof) return null;

		const hideModal = () => setServiceToProof(undefined);

		return (
			<UIModal condition={true}>
				<ServiceProofing clientId={serviceToProof.client.id} serviceId={serviceToProof.id} onProofingSuccess={refreshData} hideModal={hideModal} />
			</UIModal>
		);
	};

	const renderScheduleRequests = () => {
		if (pendingScheduleRequests.length <= 0) return;
		return (
			<div className='agentActionItems__services'>
				<div className='agentActionItems--title'>
					{strings.pendingRequestTitle} {renderInfoIcon(INFO_TYPES.scheduleRequests)}
				</div>
				<div className='agentActionItems__services--items'>
					{pendingScheduleRequests.map((scheduleRequest) => {
						return (
							<UIScheduleRequest
								onAccept={refreshData}
								onDecline={refreshData}
								service={scheduleRequest?.service}
								property={scheduleRequest?.service?.property}
								scheduleRequest={scheduleRequest}
							/>
						);
					})}
				</div>
			</div>
		);
	};

	const renderServices = () => {
		return (
			<div>
				{servicesRequiringProofing.length > 0 && <p style={{ fontSize: '12pt', marginBottom: 12, color: 'var(--light-grey)' }}>Proofing required</p>}
				{servicesRequiringProofing.map((service) => {
					const onClickService = () => {
						history.push(`/listing/${service.listing.id}?service=${service.id}&page=info`);
						hideModal();
					};

					return (
						<div style={{ border: 'var(--white-16) solid 2px', borderRadius: 6, padding: 18, cursor: 'pointer' }} onClick={onClickService}>
							<p style={{ fontSize: '16pt' }}>{service.property?.address}</p>
						</div>
					);
				})}
			</div>
		);
	};

	const renderEmptySet = () => {
		if (servicesRequiringProofing.length === 0 && pendingScheduleRequests.length === 0) {
			return (
				<div className='agentActionItems__empty'>
					<p className='agentActionItems__empty-text'>You're all caught up!</p>
				</div>
			);
		}
		return null;
	};

	return (
		<UIModal condition={showActionsNeeded}>
			<UIOverlayCard hideModal={hideModal} title={strings.actionLabel}>
				{renderScheduleRequests()}
				{renderServices()}
				{renderEmptySet()}
				{renderProofingModal()}
			</UIOverlayCard>
		</UIModal>
	);
}
