//portal-assets ********************************************************************************************************

//***************************************************OBJECTS************************************************************
export const PartnerIcon = {
	mint: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1613529516/portal-assets/v2/partner/partner-icon-mint.svg',
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1613529516/portal-assets/v2/partner/partner-icon-white.svg'
};
export const Trashcan = {
	default: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767334/portal-assets/trash_can_bhznm6.svg',
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767334/portal-assets/trash-can-white_ryr7bb.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767334/portal-assets/trash-can-grey_uvkag7.svg'
};

export const IncompletePlaceholder = {
	default: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1613875497/portal-assets/v2/placeholders/incomplete-placeholder.svg'
};

export const PlayIcon = {
	transparent: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767333/portal-assets/transparent-play_pwuo3r.svg',
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767320/portal-assets/play-white_lhg3il.svg'
};

export const StageIcon = {
	mint: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767332/portal-assets/stage-mint_m01uy3.svg',
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767332/portal-assets/step-white_zegxqu.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767332/portal-assets/step-grey_pglgyv.svg'
};

export const SaveIcon = {
	solid: {
		dark: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1610399664/portal-assets/v2/save-button-solid-dark.png'
	}
};

export const SortIcon = {
	mint: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767330/portal-assets/sort-mint_ogg5gb.svg',
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767332/portal-assets/sort-white_klc1su.svg'
};

export const RegionIcon = {
	mint: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767323/portal-assets/region-mint_eyn7hy.svg',
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767323/portal-assets/region-white_g1py0f.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767322/portal-assets/region-grey_hzrwbj.svg'
};

export const PlusIcon = {
	mint: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767320/portal-assets/plus-mint-primary_kfgpvp.svg',
	mintDashed: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767320/portal-assets/plus-dash-mint_rjjyrf.svg',
	mintBox: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767320/portal-assets/plus-box-mint_a5sonu.svg',
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767321/portal-assets/plus-white_sou9vy.svg',
	plusSign: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767320/portal-assets/plus-sign_wl2eoz.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767320/portal-assets/plus-grey_xn1utr.svg',
	darkGrey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1613535263/portal-assets/v2/plus/plus-dark-grey.svg',
	add: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767268/portal-assets/add_icon_gbhvdc.svg'
};

export const MutedIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767314/portal-assets/muted-white_ncr2zi.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767313/portal-assets/muted-grey_zaavhl.svg'
};

export const MinusIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767312/portal-assets/minus-white_oj0q1p.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767312/portal-assets/minus-grey_hondzn.svg'
};

export const MenuIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767312/portal-assets/menu-white_fs80fq.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767312/portal-assets/menu-grey_baigxn.svg'
};

export const MapIcon = {
	mint: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767310/portal-assets/map-mint_wd2dqy.svg',
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767311/portal-assets/map-white_ht4ynb.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767310/portal-assets/map-grey_k1iyxb.svg'
};

export const ListIcon = {
	mint: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767289/portal-assets/list-mint_acrmeq.svg',
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767289/portal-assets/list-white_vhpggk.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767289/portal-assets/list-grey_nrgwmn.svg'
};

export const ForwardIcon = {
	button: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767284/portal-assets/forward-button_aqna4y.svg',
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767285/portal-assets/forward-white_pnr1k3.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767285/portal-assets/forward-grey_lryv4r.svg'
};

export const FilterIcon = {
	mint: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767283/portal-assets/filter-mint_gm0dep.svg',
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767283/portal-assets/filter-white_dntnpe.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767282/portal-assets/filter-grey_eeqxvo.svg'
};

export const EditIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767280/portal-assets/edit-white_pc17er.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767280/portal-assets/edit-grey_o3h68s.svg'
};

export const DownloadIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767279/portal-assets/download-white_dalbfu.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767278/portal-assets/download-grey_ailars.svg',
	mint: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1597791366/portal-assets/download-mint_wfsrzb.svg'
};

export const UploadIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1631736460/portal-assets/v2/upload/upload-icon-white.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1631736460/portal-assets/v2/upload/upload-icon-grey.svg'
};

export const DownArrowIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767277/portal-assets/down-white_unsskl.svg',
	whiteAlt: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767277/portal-assets/down-arrow_gzvani.svg'
};

export const RightArrowIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1653182710/portal-assets/v2/right-arrow/right-arrow-white.svg',
	dark: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1653182710/portal-assets/v2/right-arrow/right-arrow-dark.svg'
};

export const CloseIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1609396720/portal-assets/v2/close-white.svg',
	darkGrey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1609396720/portal-assets/v2/close-dark-grey.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767275/portal-assets/close-icon_vzkwd1.svg',
	alt: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767274/portal-assets/close_x_ynhwb3.svg'
};

export const CheckboxIcon = {
	expanded: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767272/portal-assets/checkbox_expanded_wznd7x.svg',
	mintChecked: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767272/portal-assets/checkbox_checked_qobcwn.svg'
};

export const CheckmarkIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1597288936/portal-assets/checkmark-white.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1597288936/portal-assets/checkmark-grey.svg'
};

export const HelpIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1598048807/portal-assets/help-white_durmfi.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1598048807/portal-assets/help-grey_akzubg.svg'
};

export const BellIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767270/portal-assets/bell-white_efi3s6.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767270/portal-assets/bell-grey_lumoa9.svg'
};

export const BackIcon = {
	button: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767268/portal-assets/back-button_tapn7b.svg',
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1609396511/portal-assets/v2/back-white.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767268/portal-assets/back-grey_tw7yw7.svg'
};

export const NotesIcon = {
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1597771161/portal-assets/notes-grey_niznk1.svg',
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1597771161/portal-assets/notes-white_gn4cxp.svg'
};

export const ChatIcon = {
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1636747474/portal-assets/v2/chat/chat-icon-grey.svg',
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1636747474/portal-assets/v2/chat/chat-icon-white.svg',
	primary: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1636747994/portal-assets/v2/chat/chat-icon-primary.svg'
};
//***************************************************SINGLE*************************************************************
export const ServicesIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767330/portal-assets/services.svg';

export const VisibleWhite = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767334/portal-assets/visible-white_vnns2b.svg';
export const PauseIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767314/portal-assets/pause-white_c9kdor.svg';
export const TechniciansIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767333/portal-assets/technicians_gjqmtt.svg';
export const ProfileIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767322/portal-assets/profile_vkjccn.svg';

export const ListingIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767289/portal-assets/listings_mtuabm.svg';

export const PineappleServicesIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767316/portal-assets/pineapple-services_kyuzme.svg';
export const InviteCodes = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767288/portal-assets/invite-codes_bwiyif.svg';
export const InfoIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767288/portal-assets/info-icon_f0mkqd.svg';
export const HistoryWhite = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767285/portal-assets/history-white_yff3vw.svg';
export const FloorplansIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767283/portal-assets/floor-plans-icon_b3s0kv.svg';

export const FinancesIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767283/portal-assets/finances_dsguk6.svg';

export const EducationIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767281/portal-assets/education-icon_xuqapg.svg';
export const EditingCompaniesIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767281/portal-assets/editing-companies_dtf72b.svg';
export const EditServicesIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767280/portal-assets/edit-services_rkuwhs.svg';
export const DocumentIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767277/portal-assets/document_pjzwgk.svg';

export const CopyIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767276/portal-assets/copy-white_nhnzqn.svg';
export const AgentIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767268/portal-assets/agents_wr4k4s.svg';
export const AffiliatesIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767268/portal-assets/affiliates_olwkmn.svg';
export const ActionNeededIcon = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767248/portal-assets/action-needed_xgujqt.svg';
export const ShareWhite = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1597783571/portal-assets/share-white_elbwzq.svg';
export const SettingsWhite = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767330/portal-assets/settings-white_qtpyg1.svg';
export const MusicNoteIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767312/portal-assets/Music_note_ct2odm.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1606159824/music-note-grey.svg'
};

//pl-assets ************************************************************************************************************
export const PLIcons = {
	dark: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1583271305/pl-assets/pl-icon/icon-dark/pl-icon-dark.svg',
	light: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1583271294/pl-assets/pl-icon/icon-light/pl-icon-light.svg'
};

export const TechnicianCertificates = {
	solid: {
		white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1609632133/portal-assets/v2/certifications-solid-white.svg'
	}
};
export const EditPhotoIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767280/portal-assets/edit-photo-icon_vwyxdd.svg',
	mint: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1595213686/portal-assets/edit-icon-mint_aygeyq.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1605401220/portal-assets/edit-photo-icon-grey.svg'
};

export const VirtualTwilightIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1599768658/portal-assets/virtual-twilight-white.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1599768658/portal-assets/virtual-twilight-grey.svg'
};

export const AddToCartIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1636922510/portal-assets/v2/add-to-cart/add-to-cart-icon-white.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1636922510/portal-assets/v2/add-to-cart/add-to-cart-icon-grey.svg',
	mint: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1636922510/portal-assets/v2/add-to-cart/add-to-cart-icon-mint.svg',
	red: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1636922510/portal-assets/v2/add-to-cart/add-to-cart-icon-red.svg'
};

export const RemoveFromCartIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1636922610/portal-assets/v2/remove-from-cart/remove-from-cart-icon-white.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1636922610/portal-assets/v2/remove-from-cart/remove-from-cart-icon-grey.svg',
	mint: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1636922610/portal-assets/v2/remove-from-cart/remove-from-cart-icon-mint.svg',
	red: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1636922610/portal-assets/v2/remove-from-cart/remove-from-cart-icon-red.svg'
};

export const NotInCartIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1637439295/portal-assets/v2/not-in-cart/not-in-cart-white.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1637439295/portal-assets/v2/not-in-cart/not-in-cart-grey.svg',
	mint: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1637439295/portal-assets/v2/not-in-cart/not-in-cart-mint.svg',
	red: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1637439295/portal-assets/v2/not-in-cart/not-in-cart-red.svg'
};

export const ReportIssueIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1637522859/portal-assets/v2/report-issue/report-issue-white.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1637522859/portal-assets/v2/report-issue/report-issue-grey.svg',
	mint: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1637522859/portal-assets/v2/report-issue/report-issue-mint.svg',
	red: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1637522859/portal-assets/v2/report-issue/report-issue-red.svg'
};

export const PLLogoOnly = {
	light: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1583271301/pl-assets/pl-icon/icon-light/pl-icon-light_320h_hxxmho.png'
};

export const DroneIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1599687790/portal-assets/drone-white.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1599687790/portal-assets/drone-grey.svg'
};
export const SunsetIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1599713005/portal-assets/sunset-white.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1599713005/portal-assets/sunset-grey.svg'
};

//Home Marketing Assets ************************************************************************************************

export const PrivacyPolicyAndTerms = {
	privacy: 'https://storage.googleapis.com/pineapple-estates/agreements/Pineapple%20Labs%20Ltd.%20-%20Privacy%20Policy%20June%2011%2C%202020.pdf',
	terms: 'https://storage.googleapis.com/pineapple-estates/agreements/Pineapple%20Labs%20Ltd.%20-%20Terms%20of%20Use%20June%2011%2C%202020.pdf'
};

// Social Media Icons **************************************************************************************************

export const InstagramWhite = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767288/portal-assets/instagram-white_wgtvhw.svg';
export const InstagramIconAlt = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767288/portal-assets/instagram_icon_imezsp.svg';
export const LinkedInTile = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767288/portal-assets/linkedin-tile_zj2yfw.svg';
export const FacebookIconAlt = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1592767281/portal-assets/facebook_icon_czcwx6.svg';

export const MatterportIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1605590238/portal-assets/matterport-icon-white.svg'
};

export const CancelIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1609528511/portal-assets/v2/cancel-white.svg'
};

export const PromotionsIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1609448168/portal-assets/v2/promotions-white.svg'
};

export const CameraIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1599352119/portal-assets/camera-white.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1599352119/portal-assets/camera-grey.svg'
};

export const SortImagesIcon = {
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1600710080/portal-assets/sort-white.svg',
	grey: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1600710080/portal-assets/sort-grey.svg'
};

// VT Music Mp3 ********************************************************************************************************
export const VTMusic = {
	byMySide: 'https://res.cloudinary.com/pineapple-labs/video/upload/v1586708650/Property%20Website%20Audio/By_My_Side_ioo278.mp3',
	teenageDream: 'https://res.cloudinary.com/pineapple-labs/video/upload/v1586708648/Property%20Website%20Audio/Teenage_Dream_lknf1j.mp3',
	timeToMove: 'https://res.cloudinary.com/pineapple-labs/video/upload/v1586708645/Property%20Website%20Audio/Time_to_Move_brzyzx.mp3',
	almanac: 'https://res.cloudinary.com/pineapple-labs/video/upload/v1586708643/Property%20Website%20Audio/Almanac_akvxg6.mp3',
	timeOff: 'https://res.cloudinary.com/pineapple-labs/video/upload/v1586708642/Property%20Website%20Audio/Time_Off_ztinej.mp3',
	techNoLedge: 'https://res.cloudinary.com/pineapple-labs/video/upload/v1586708636/Property%20Website%20Audio/Tech_No_Ledge_hzh04k.mp3',
	musicForTheSoul: 'https://res.cloudinary.com/pineapple-labs/video/upload/v1586708631/Property%20Website%20Audio/Music_for_the_Soul_Instrumental_mixrfj.mp3',
	allStars: 'https://res.cloudinary.com/pineapple-labs/video/upload/v1586708626/Property%20Website%20Audio/All_Stars_gfcwbe.mp3',
	subjection: 'https://res.cloudinary.com/pineapple-labs/video/upload/v1586708622/Property%20Website%20Audio/Subjection_nrsdwn.mp3',
	aBigAdventure: 'https://res.cloudinary.com/pineapple-labs/video/upload/v1586708619/Property%20Website%20Audio/A_Big_Adventure_wrwcev.mp3',
	binaryLove: 'https://res.cloudinary.com/pineapple-labs/video/upload/v1586708616/Property%20Website%20Audio/Binary_Love_xyq3hk.mp3',
	inViaggioVersolAlba:
		'https://res.cloudinary.com/pineapple-labs/video/upload/v1596559577/Property%20Website%20Audio/InViaggioVersolAlba_by_AndreaMusci_Artlist_jbuxmc.mp3',
	fivetoOne: 'https://res.cloudinary.com/pineapple-labs/video/upload/v1596559581/Property%20Website%20Audio/FivetoOne_by_StevePoloni_Artlist_gv2j0a.mp3',
	highLine: 'https://res.cloudinary.com/pineapple-labs/video/upload/v1596559588/Property%20Website%20Audio/HighLine_by_Duffmusiq_Artlist_ltjji5.mp3'
};

// JSON Transitions ****************************************************************************************************
export const LottieAnimations = {
	loadingCircle: 'https://res.cloudinary.com/pineapple-labs/raw/upload/v1592767310/portal-assets/loading-circle_ojnlox.json',
	loadingCircleGrey: 'https://res.cloudinary.com/pineapple-labs/raw/upload/v1601601950/portal-assets/loading-circle-grey_vn7ra0.json',
	loadingCircleAlt: 'https://res.cloudinary.com/pineapple-labs/raw/upload/v1592767290/portal-assets/loading-circle_kxpoc7.json',
	imageLoading: 'https://res.cloudinary.com/pineapple-labs/raw/upload/v1592767288/portal-assets/image-loading_ycz4mq.json',
	upwardGraph: 'https://res.cloudinary.com/pineapple-labs/raw/upload/v1592767334/portal-assets/UpwardGraph_xbunmi.json',
	text: 'https://res.cloudinary.com/pineapple-labs/raw/upload/v1592767333/portal-assets/TextAnimation_p4vpqd.json',
	pineappleLogo: 'https://res.cloudinary.com/pineapple-labs/raw/upload/v1592767316/portal-assets/PineappleLogoAnimation_ds9did.json',
	cameraHomeIcon: 'https://res.cloudinary.com/pineapple-labs/raw/upload/v1603850965/portal-assets/lottie-home-camera.json',
	musicNote: 'https://res.cloudinary.com/pineapple-labs/raw/upload/v1592767312/portal-assets/MusicNote_rss9du.json'
};

//***************************************************REFACTORED*********************************************************

export const DownloadBlack = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1602795409/Icon%20Refactor%20Folder/download-icon-black.svg';
export const SortWhite = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1602795413/Icon%20Refactor%20Folder/sort-white.svg';
export const PhotosTab = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1602795411/Icon%20Refactor%20Folder/photos-tab-icon.svg';

export const InvisibleWhite = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1607458301/portal-assets/invisible-white.svg';
export const CameraWhite = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1602795409/Icon%20Refactor%20Folder/camera-white.svg';

export const EditorTab = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1602795409/Icon%20Refactor%20Folder/editor-tab.svg';

export const ServiceOptionsTab = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1602795411/Icon%20Refactor%20Folder/service-options-white.svg';
export const SocialMediaTab = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1602795413/Icon%20Refactor%20Folder/social-media-tab-icon.svg';

export const VirtualTourTab = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1602795413/Icon%20Refactor%20Folder/virtual-tour-tab-white.svg';
export const ProofRed = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1603302872/Icon%20Refactor%20Folder/proof-red.svg';
export const InProcessIcon = {
	red: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1603302872/Icon%20Refactor%20Folder/in-progress-red.svg',
	white: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1603302872/Icon%20Refactor%20Folder/in-progress-white.svg'
};

export const VideoIconWhite = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1605587709/portal-assets/video-icon-white.svg';
export const MutedRed = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1609351923/Icon%20Refactor%20Folder/muted-red.svg';
export const EmailActions = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1609365311/Icon%20Refactor%20Folder/email-action-grey.svg';
export const EditPhotoIconMint = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1610660635/Icon%20Refactor%20Folder/edit-photo-icon-mint.svg';
export const TrashCanRed = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1610660671/Icon%20Refactor%20Folder/trash-can-red.svg';
export const PlugFemale = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1615315582/Icon%20Refactor%20Folder/plug-female.svg';
export const PlugMale = 'https://res.cloudinary.com/pineapple-labs/image/upload/v1615315580/Icon%20Refactor%20Folder/plug-male.svg';
