import Queries from '../../../../utils/API/Queries';
import { getAvailableAddOns } from '../../../../utils/Helpers/BookingHelpers';
import { OPTION_TYPES } from '../../../../utils/Config/Constants';
import { BookingConfig } from './types';

export const getListingOptionId = (PL_ENVIRONMENT) => {
	switch (PL_ENVIRONMENT) {
		case 'DEV':
		case 'LOCAL_DEV':
		case 'LOCAL':
			return 'c4d60c0b-6862-4dbd-b5ec-279b9acd2916';
		case 'LIVE':
			return 'ec694615-cbd8-42c9-bfd8-4c114d5c1cd7';
		default:
			return '';
	}
};

export const ENTRY_OPTIONS = {
	agent: 'I will meet the tech there.',
	homeowner: 'Homeowner will be there.',
	lockBox: 'I have a lock-box code.'
};

export const WORKFLOW_COMPONENTS = {
	location: {
		name: 'location',
		navTitle: 'Enter the property location'
	},
	serviceProvider: {
		name: 'serviceProvider',
		navTitle: 'Have a preferred provider?'
	},
	serviceOptionsSelect: {
		name: 'serviceOptions',
		navTitle: 'Select your services'
	},
	marketingOptions: {
		name: 'marketingOptions',
		navTitle: 'Marketing'
	},
	editingOptions: {
		name: 'editingOptions',
		navTitle: 'Editing'
	},
	dateTime: {
		name: 'dateTime',
		navTitle: 'Schedule your services'
	},
	entry: {
		name: 'entry',
		navTitle: 'Technician enter information'
	},
	upgrades: {
		name: 'upgrades',
		navTitle: 'Service upgrades'
	},
	pay: {
		name: 'pay',
		navTitle: 'Review and pay'
	}
};

export const WORKFLOWS = {
	mediaServices: [
		WORKFLOW_COMPONENTS.location,
		WORKFLOW_COMPONENTS.serviceOptionsSelect,
		WORKFLOW_COMPONENTS.upgrades,
		WORKFLOW_COMPONENTS.dateTime,
		WORKFLOW_COMPONENTS.entry,
		WORKFLOW_COMPONENTS.pay
	],
	mediaServicesNoUpgrades: [
		WORKFLOW_COMPONENTS.location,
		WORKFLOW_COMPONENTS.serviceOptionsSelect,
		WORKFLOW_COMPONENTS.dateTime,
		WORKFLOW_COMPONENTS.entry,
		WORKFLOW_COMPONENTS.pay
	],
	editServices: [WORKFLOW_COMPONENTS.location, WORKFLOW_COMPONENTS.serviceOptionsSelect, WORKFLOW_COMPONENTS.upgrades, WORKFLOW_COMPONENTS.pay],
	editServicesNoUpgrades: [WORKFLOW_COMPONENTS.location, WORKFLOW_COMPONENTS.serviceOptionsSelect, WORKFLOW_COMPONENTS.pay],
	listingOnly: [WORKFLOW_COMPONENTS.location, WORKFLOW_COMPONENTS.serviceOptionsSelect, WORKFLOW_COMPONENTS.pay]
};

export const getEditVSUpgradeOptionsSelected = (bookableOptions: BookableOptionType[]) => {
	if (bookableOptions) {
		return bookableOptions.filter(
			(bookableOption) =>
				[OPTION_TYPES.editUpgrade, OPTION_TYPES.virtualStaging, OPTION_TYPES.edit, OPTION_TYPES.admin, OPTION_TYPES.listing].indexOf(
					bookableOption.option.type
				) !== -1 || bookableOption.option.packageType === 'technicianFloorPlan'
		);
	}
	return [];
};

export const areAddOnOptionsSelected = (bookingConfig: BookingConfig) => {
	const availableAddOnIds = getAvailableAddOns(bookingConfig).availableOptions.map((bookableOption) => bookableOption.option.id);
	const selectedOptionsIds = bookingConfig.selectedOptions.map((bookableOption) => bookableOption.option.id);

	const sharedOptions = selectedOptionsIds.filter((selectedOption) => availableAddOnIds.indexOf(selectedOption) !== -1) ?? [];
	return sharedOptions.length > 0;
};

export const areEditVSUpgradeOptionsSelected = (selectedOptions: BookableOptionType[]) => {
	return getEditVSUpgradeOptionsSelected(selectedOptions).length > 0;
};

export const getMediaOptionsSelected = (selectedOptions: BookableOptionType[]) => {
	if (!selectedOptions) return [];
	return selectedOptions.filter(
		(bookableOption) =>
			[OPTION_TYPES.editUpgrade, OPTION_TYPES.virtualStaging, OPTION_TYPES.edit, OPTION_TYPES.listing].indexOf(bookableOption.option.type) === -1
	);
};

export const areMediaOptionsSelected = (selectedOptions: BookableOptionType[]) => {
	return getMediaOptionsSelected(selectedOptions).length > 0;
};

export const getRegionFromZip = async (zipCode: String, client) => {
	const response = await client.query({
		query: Queries.GET_REGIONS_QUERY,
		variables: {
			q: { zipCodes: { op_contains: [zipCode] } }
		}
	});
	const { regions } = response['data'];
	return regions[0];
};

export function getOptionsFromBookingConfig(bookingConfig: BookingConfig): BookableOptionType[] {
	if (bookingConfig?.selectedOptions) {
		return Object.assign([...bookingConfig?.selectedOptions], []);
	}
	return [];
}

export function getOptionsFromBookingConfigWithLighting(bookingConfig: BookingConfig): BookableOptionType[] {
	let fullOptionsList: BookableOptionType[] = getOptionsFromBookingConfig(bookingConfig);

	if (areMediaOptionsSelected(bookingConfig.selectedOptions)) {
		if (bookingConfig.lightingOptions) {
			fullOptionsList = [...fullOptionsList, ...bookingConfig.lightingOptions];
		}
	}
	if (fullOptionsList.length === 0 && bookingConfig.listingOption) {
		fullOptionsList = [bookingConfig.listingOption];
	}
	return fullOptionsList;
}
