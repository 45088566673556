import * as types from './types';

export function addClient(client) {
	return { type: types.ADD_CLIENT, client };
}

export function removeFirstClient() {
	return { type: types.REMOVE_FIRST_CLIENT };
}

export const apiActions = {
	addClient,
	removeFirstClient
};
