// Created by jarongallo at 6/17/21
import React, { useEffect, useState } from 'react';
import './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { useLazyQuery } from 'react-apollo';
import { GET_SERVICE_AGENT_HELP } from './queries';
import {
	STATUS_ADMIN_HOLD,
	STATUS_EDITOR_UPLOAD,
	STATUS_FINDING_EDIT_TECHNICIAN,
	STATUS_FINDING_PREFERRED_TECHNICIAN,
	STATUS_FINDING_TECHNICIAN,
	STATUS_LIVE,
	STATUS_PRE_ORDER,
	STATUS_PROOFING,
	STATUS_REVIEW,
	STATUS_STANDBY,
	STATUS_TECHNICIAN_UPLOAD,
	USER_ROLE_AGENT
} from '../../../../../../utils/Config/Constants';
import UIIcon from '../../../../../../components/UI/UIIcon';
import { HelpIcon } from '../../../../../../assets/icons';
import * as strings from './strings';
import { useHistory } from 'react-router-dom';
import { clearSidebar } from '../../../../../../redux/store/control/actions';

export default function PLServiceAgentHelp({ serviceId }: PLServiceAgentHelpProps) {
	const signedInUser = useSelector(getSignedInUserSelector);

	const history = useHistory();
	const dispatch = useDispatch();

	const [service, setService] = useState<ServiceType>();

	const [getService, { data: serviceData }] = useLazyQuery(GET_SERVICE_AGENT_HELP, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getService();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setService(serviceData['service']);
			}
		})();
	}, [serviceData]);

	if (!service) return null;

	const shouldRenderComponentForService = () => {
		return signedInUser.role === USER_ROLE_AGENT && service.status !== STATUS_PROOFING;
	};

	if (!shouldRenderComponentForService()) return null;

	const renderHelpContent = () => {
		switch (service.status) {
			case STATUS_PRE_ORDER:
				return <p className='plServiceHelp--text'>{strings.preOrderHelpText}</p>;
			case STATUS_FINDING_TECHNICIAN:
				return <p className='plServiceHelp--text'>{strings.findingTechHelpText}</p>;
			case STATUS_FINDING_PREFERRED_TECHNICIAN:
				return <p className='plServiceHelp--text'>{strings.findingPreferredTechHelpText}</p>;
			case STATUS_TECHNICIAN_UPLOAD:
			case STATUS_FINDING_EDIT_TECHNICIAN:
			case STATUS_EDITOR_UPLOAD:
			case STATUS_REVIEW:
				return <p className='plServiceHelp--text'>{strings.processingHelpText}</p>;
			case STATUS_ADMIN_HOLD:
			case STATUS_STANDBY:
				return <p className='plServiceHelp--text'>{strings.adminHoldHelpText}</p>;
			case STATUS_LIVE:
				if (!service.listing) {
					return <p className='plServiceHelp--text'>{strings.liveNoListingText}</p>;
				}
				const onClickListing = () => {
					dispatch(clearSidebar());
					history.push(`/listing/${service.listing.id}?photos`);
				};
				return (
					<p className='plServiceHelp--text'>
						<span className='plServiceHelp--text-link' onClick={onClickListing}>
							Manage your listing
						</span>{' '}
						to view the content from this service
					</p>
				);
			default:
				return null;
		}
	};

	return (
		<div className='plServiceHelp'>
			<UIIcon iconSrc={HelpIcon.white} className='plServiceHelp--icon' alt={'help'} />
			{renderHelpContent()}
		</div>
	);
}
