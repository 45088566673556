import React, { useState } from 'react';
import './styles';
import moment from 'moment-timezone';
import UIIconButton from '../UIIconButton';
import { CheckmarkIcon } from '../../../assets/icons';
import * as strings from './strings';
import { TYPES } from '../UIProperty/config';
import UIProperty from '../UIProperty';
import { UI_ICON_BUTTON_CANCEL_TYPE, UI_ICON_BUTTON_WHITE_TYPE } from '../UIIconButton/config';
import { toast } from 'react-toastify';
import { ACCEPT_SCHEDULE_REQUEST, DECLINE_SCHEDULE_REQUEST } from './queries';
import { useMutation } from '@apollo/react-hooks';

const UIScheduleRequest = ({ service, scheduleRequest, onAccept, onDecline, property }) => {
	const [isAcceptingScheduleRequest, setIsAcceptingScheduleRequest] = useState<boolean>(false);
	const [isDecliningScheduleRequest, setIsDecliningScheduleRequest] = useState<boolean>(false);

	const [acceptScheduleRequestMutation] = useMutation(ACCEPT_SCHEDULE_REQUEST);
	const [declineScheduleRequestMutation] = useMutation(DECLINE_SCHEDULE_REQUEST);

	const acceptScheduleRequest = async (requestedTime, scheduleRequest) => {
		if (!isAcceptingScheduleRequest) {
			setIsAcceptingScheduleRequest(true);
			const response = await acceptScheduleRequestMutation({
				variables: {
					service: service.id,
					acceptedTime: requestedTime,
					id: scheduleRequest.id,
					photographer: scheduleRequest.photographer.id
				}
			});
			if (response['data'].acceptScheduleRequest) {
				await onAccept();
				setIsAcceptingScheduleRequest(false);
				toast.success(strings.acceptSuccessMessage);
			}
		}
	};

	const declineScheduleRequest = async () => {
		if (!isDecliningScheduleRequest) {
			setIsDecliningScheduleRequest(true);
			const response = await declineScheduleRequestMutation({
				variables: {
					requestId: scheduleRequest.id
				}
			});
			const { declineScheduleRequest } = response['data'];
			if (declineScheduleRequest) {
				await onDecline();
				setIsDecliningScheduleRequest(false);
				toast.success(strings.declineSuccessMessage);
			} else {
				toast.error(strings.declineErrorMessage);
			}
		}
	};

	const renderRequestedTime = (requestedTime, index) => {
		let momentTime = moment(requestedTime);

		if (property?.timezone) {
			momentTime = momentTime.tz(property.timezone);
		}

		return (
			<div className='requestedTime' key={index}>
				<div className='requestedTime__container'>
					<p className='requestedTime__container--date'>{momentTime.format('ddd MMM Do, YYYY')}</p>
					<p className='requestedTime__container--time'>{momentTime.format('h:mm a z')}</p>
				</div>
				<UIIconButton
					dark
					isLoading={isAcceptingScheduleRequest}
					iconPadding={8}
					icon={CheckmarkIcon.grey}
					type={UI_ICON_BUTTON_WHITE_TYPE}
					onClick={() => acceptScheduleRequest(requestedTime, scheduleRequest)}
				/>
			</div>
		);
	};

	return (
		<div key={scheduleRequest.id} className='scheduleRequest'>
			<UIProperty type={TYPES.sideBar} property={scheduleRequest?.service?.property} />
			<div className='scheduleRequest__user'>
				<img src={scheduleRequest.photographer.image} alt={scheduleRequest.photographer.id} className='scheduleRequest__user--img' />
				<p className='scheduleRequest__user--request'>
					<span className='scheduleRequest__user--request-name'>{scheduleRequest?.photographer?.name || strings.noActor}</span>
					{strings.techSuggestString}
				</p>
			</div>
			<div className='scheduleRequest__buttons'>
				{scheduleRequest.requestedTimes.map(renderRequestedTime)}
				<UIIconButton
					dark
					className='scheduleRequest__decline'
					isLoading={isDecliningScheduleRequest}
					type={UI_ICON_BUTTON_CANCEL_TYPE}
					text={scheduleRequest.requestedTimes.length < 0 ? strings.declineButton : strings.declineAllButton}
					onClick={declineScheduleRequest}
				/>
			</div>
		</div>
	);
};

export default UIScheduleRequest;
