import UIIcon from '../../../UI/UIIcon';
import { updatePage } from '../../../../redux/store/filters/actions';
import { BackIcon, ForwardIcon } from '../../../../assets/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getFiltersProp } from '../../../../redux/selectors/CurrentUserSelectors';

const PLTableFooter = ({ data, limit = 30, updateTable }: PLTableFooterProps) => {
	const history = useHistory();
	const filters = useSelector(getFiltersProp);
	const dispatch = useDispatch();

	const matchingPath = history.location.pathname;
	const limitValue = limit;

	let page = filters[matchingPath] ? (filters[matchingPath].page ? filters[matchingPath].page : 1) : 1;
	let updateTableData = () => setTimeout(() => updateTable(), 5);

	if (data.length < limitValue) {
		return (
			<div className='paginationContainer'>
				{page > 1 && (
					<UIIcon
						onClick={() => {
							dispatch(
								updatePage({
									route: matchingPath,
									page: parseInt(page) - 1
								})
							);
							updateTableData();
						}}
						className='paginationContainer__icon'
						iconSrc={BackIcon.white}
						alt={'back'}
					/>
				)}

				{data.length === limitValue || (page > 1 && <p className='paginationNumberText'>{page}</p>)}
			</div>
		);
	}

	if (data.length === limitValue || page > 1) {
		return (
			<div className='paginationContainer'>
				{page > 1 && (
					<UIIcon
						onClick={() => {
							dispatch(
								updatePage({
									route: matchingPath,
									page: parseInt(page) - 1
								})
							);
							updateTableData();
						}}
						className='paginationContainer__icon'
						iconSrc={BackIcon.white}
						alt={'back'}
					/>
				)}

				{data.length === limitValue || page > 1 ? <p className='paginationNumberText'>{page}</p> : undefined}

				{(limitValue * (page - 1) + data.length) % limitValue === 0 && (
					<UIIcon
						onClick={() => {
							dispatch(
								updatePage({
									route: matchingPath,
									page: parseInt(page) + 1
								})
							);
							updateTableData();
						}}
						className='paginationContainer__icon'
						iconSrc={ForwardIcon.white}
						alt={'forward'}
					/>
				)}
			</div>
		);
	} else {
		return (
			<div className='paginationContainer'>
				{page > 1 && (
					<UIIcon
						onClick={() => {
							dispatch(
								updatePage({
									route: matchingPath,
									page: parseInt(page) - 1
								})
							);
							updateTableData();
						}}
						className='paginationContainer__icon'
						iconSrc={BackIcon.white}
						alt={'back'}
					/>
				)}

				{data.length === limitValue || (page > 1 && <p className='paginationNumberText'>{page}</p>)}
			</div>
		);
	}
};

export default PLTableFooter;
