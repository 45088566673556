// Created by carsonaberle at 11/23/21
import gql from 'graphql-tag';

export const UPDATE_SERVICE_TYPE = gql`
	mutation UpdateServiceType($serviceId: String!, $type: String!) {
		updateService(id: $serviceId, type: $type) {
			id
			type
		}
	}
`;
