// Created by carsonaberle at 2/24/22
import styled from 'styled-components';

export const UIIconContainer = styled.div`
	//DEFAULT
	position: relative;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIIconContent = styled.div`
	//DEFAULT
	width: 14px;
	height: 14px;
	border-radius: 7px;
	background-color: var(--mint-light);
	margin-top: 6px;
	position: absolute;
	right: 0;
	top: 0;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIIconBadge = styled.p`
	//DEFAULT
	color: var(--mint-darkest);
	font-size: 8pt;
	text-align: center;
	font-weight: 500;
	margin-top: 1px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
