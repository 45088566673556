// Created by jarongallo at 6/17/21
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PartnerIcon } from '../../../../../../assets/icons';
import UIModal from '../../../../../../components/UI/UIModal';
import PartnerSelectModal from '../../../../../../components/modals/PartnerSelectModal';
import UIIcon from '../../../../../../components/UI/UIIcon';
import './styles';
import * as strings from './strings';
import { getIsUserAdminSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_SERVICE_REGION, UPDATE_SERVICE_ORDER_INFO } from './queries';
import { toast } from 'react-toastify';

export default function PLServiceOrderInformation({ serviceId }: PLServiceOrderInformationProps) {
	let isUserAdmin = useSelector(getIsUserAdminSelector);
	const [updateOrderInformation] = useMutation(UPDATE_SERVICE_ORDER_INFO);

	const [service, setService] = useState<ServiceType>();

	const [showOrderInformationModal, setShowOrderInformationModal] = useState(false);
	const [selectedPartnerPackage, setSelectedPartnerPackage] = useState<PartnerPackageType>();
	const [getService, { data: serviceData }] = useLazyQuery(GET_SERVICE_REGION, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getService();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setService(serviceData['service']);
			}
		})();
	}, [serviceData]);

	const shouldRenderComponentForService = () => {
		return isUserAdmin;
	};

	if (!shouldRenderComponentForService()) {
		return null;
	}

	if (!service) return null;

	const renderEditOrderInformationModal = () => {
		const hideModal = () => {
			setShowOrderInformationModal(false);
			setSelectedPartnerPackage(undefined);
		};

		const onSelectionComplete = async () => {
			const orderInformation = {
				...selectedPartnerPackage,
				['Package Ordered']: selectedPartnerPackage?.name
			};
			if (orderInformation) {
				let variables = { serviceId, orderInformation };
				await updateOrderInformation({ variables });
				await getService();
				toast.success(strings.selectPartnerSuccessMessage);
				hideModal();
			}
		};

		return (
			<UIModal condition={showOrderInformationModal}>
				<PartnerSelectModal
					onSelectionComplete={onSelectionComplete}
					hideModal={hideModal}
					packages={service?.region?.regionConfig?.coldwellPackages ?? []}
					selectedPartnerPackage={selectedPartnerPackage ?? service?.orderInformation}
					update={(updateData) => setSelectedPartnerPackage(updateData.selectedPartnerPackage)}
				/>
			</UIModal>
		);
	};

	return (
		<div className='plServiceOrderInformation' onClick={() => setShowOrderInformationModal(true)}>
			<div className='plServiceOrderInformation__iconContainer'>
				<UIIcon
					iconSrc={service?.orderInformation ? PartnerIcon.mint : PartnerIcon.white}
					alt={strings.orderInformationAlt}
					className='plServiceOrderInformation__iconContainer-icon'
				/>
				<p style={service?.orderInformation ? { color: 'var(--mint-primary)' } : {}}>{strings.orderInformationHover}</p>
			</div>
			{renderEditOrderInformationModal()}
		</div>
	);
}
