import React, { useState } from 'react';
import './styles';
import UIBookableOption from '../UIBookableOption';
import { CheckboxIcon, DownArrowIcon, PlusIcon } from '../../../assets/icons';
import { getOptionFromBookableOptionsById } from '../../../utils/Helpers/BookingHelpers';
import { OPTION_ADDITIONAL_INFO_TYPES } from '../../../utils/Config/Constants';

export default function UIOptionGroup({
	selectedOptions,
	bookableOptions,
	disableAutoClose,
	disableAutoDeselectGroup,
	optionGroups,
	style,
	updateSelectedOptions
}: UIOptionGroupProps) {
	const [isClosing, setIsClosing] = useState({});
	const [isExpanding, setIsExpanding] = useState({});
	const [expandedOptionGroups, setExpandedOptionGroups] = useState<PackageType[]>([]);

	const toggleOptionGroupExpanded = (optionGroup: PackageType) => {
		const isPackageTypeExpanded = expandedOptionGroups.indexOf(optionGroup) !== -1;
		if (isPackageTypeExpanded) {
			setIsClosing({
				isClosing: {
					...isClosing,
					[optionGroup.packageType]: true
				}
			});
			expandedOptionGroups.splice(expandedOptionGroups.indexOf(optionGroup), 1);
			setTimeout(() => {
				setExpandedOptionGroups(expandedOptionGroups);
				setIsClosing({ ...isClosing, [optionGroup.packageType]: false });
			}, 600);
		} else {
			setExpandedOptionGroups([...expandedOptionGroups, optionGroup]);
			setIsExpanding({
				...isExpanding,
				[optionGroup.packageType]: true
			});
			setTimeout(() => {
				setIsExpanding({
					...isExpanding,
					[optionGroup.packageType]: false
				});
			}, 600);
		}
	};

	const renderOptionGroup = (optionGroup: PackageType, options: BookableOptionType[]) => {
		let isExpanded = expandedOptionGroups.indexOf(optionGroup) !== -1;

		let hasOptionsOfGroup = selectedOptions.filter((selectedOption) => selectedOption.option.packageType === optionGroup.packageType).length > 0;

		return (
			<div
				key={optionGroup.packageType}
				className={`uiOptionGroup__type ${isExpanded ? 'uiOptionGroup__type-expanded' : ''}`}
				onClick={() => toggleOptionGroupExpanded(optionGroup)}>
				<div className='uiOptionGroup__type__head'>
					<img
						src={hasOptionsOfGroup ? CheckboxIcon.mintChecked : isExpanded ? DownArrowIcon.white : PlusIcon.white}
						className='uiOptionGroup__type--arrow'
						alt={isExpanded ? 'collapse' : 'expand'}
					/>
					<p className='uiOptionGroup__type--title' style={hasOptionsOfGroup ? { color: 'var(--mint-primary)' } : { color: 'white' }}>
						{getTitleFromOptionGroup(optionGroup)}
					</p>
				</div>
				{renderOptionsFor(optionGroup, options)}
				<div className='uiOptionGroup--right-gradient' />
			</div>
		);
	};

	const getTitleFromOptionGroup = (optionGroup: PackageType) => {
		return optionGroup.title;
	};

	const renderOptionsFor = (optionGroup: PackageType, options: BookableOptionType[]) => {
		if (!options) {
			return;
		}
		if (expandedOptionGroups.indexOf(optionGroup) === -1 && !isClosing[optionGroup.packageType] && !isExpanding[optionGroup.packageType]) {
			return;
		}

		const onUpdateSelectedOptions = (selectedOptions: BookableOptionType[]) => {
			updateSelectedOptions(selectedOptions);
			if (selectedOptions.length < selectedOptions.length) {
				//Don't auto collapse if requiredInfo==="quantity on any selected option of this packageType || if !disableAutoClose
				const shouldAutoClose =
					selectedOptions.filter(
						(selectedOption) =>
							(selectedOption.option.requiredInfo === OPTION_ADDITIONAL_INFO_TYPES.quantity ||
								selectedOption.option.requiredInfo === OPTION_ADDITIONAL_INFO_TYPES.text) &&
							selectedOption.option.packageType === optionGroup.packageType
					).length === 0;
				if (shouldAutoClose && !disableAutoClose) {
					toggleOptionGroupExpanded(optionGroup);
				}
			}
		};

		const content = options.map((filteredOption) => {
			let bookableOption = filteredOption;
			//If there is a selectedOption use that instead
			const selectedOption = getOptionFromBookableOptionsById(selectedOptions, bookableOption.option.id);
			if (selectedOption) {
				bookableOption = selectedOption;
			}

			return (
				<div key={filteredOption.option.id} className='uiOptionGroup__option'>
					<UIBookableOption
						disableAutoDeselectGroup={disableAutoDeselectGroup}
						updateSelectedOptions={onUpdateSelectedOptions}
						selectedOptions={selectedOptions}
						disableSelection={false}
						bookableOption={bookableOption}
					/>
				</div>
			);
		});

		const getStyle = () => {
			if (isClosing[optionGroup.packageType] === true) {
				return { height: 1, opacity: 0, margin: '0 24px 0 0' };
			} else if (isExpanding[optionGroup.packageType] === true) {
				return { height: 'auto', opacity: 1 };
			}
			if (expandedOptionGroups.indexOf(optionGroup) !== -1) {
				return { height: 'auto', opacity: 1 };
			}
			return { height: 0, opacity: 1 };
		};

		return (
			<div className='uiOptionGroup__type--options' style={getStyle()}>
				{content}
			</div>
		);
	};

	return (
		<div className='uiOptionGroup' style={style}>
			{optionGroups.map((optionGroup) => {
				const filteredOptions = bookableOptions
					.filter((option) => option.option.packageType === optionGroup.packageType)
					.sort((a, b) => (a.option.price === b.option.price ? a.option.name.localeCompare(b.option.name) : a.option.price - b.option.price));
				if (filteredOptions.length === 0) return null;
				return renderOptionGroup(optionGroup, filteredOptions);
			})}
		</div>
	);
}
