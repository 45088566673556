// Created by jarongallo at 6/14/21
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as strings from './strings';
import './styles';
import {
	FILE_STATUS_LIVE,
	FILE_TYPE_VIDEO,
	USER_ROLE_ADMIN,
	USER_ROLE_EDITING_MANAGER,
	USER_ROLE_EDITOR,
	USER_ROLE_REGIONAL_MANAGER
} from '../../../../../../utils/Config/Constants';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_PL_SERVICE_VIDEOS_FILES } from './queries';
import UIMultiImageSelect from '../../../../../../components/UI/UIMultiImageSelect';
import { TYPES } from '../../../../../../components/UI/UIMultiImageSelect/config';
import { PLServiceFilesQueryParams } from '../../types';
import PLUpload from '../../../../../../components/PL/PLUpload';
import UILoadingPage from '../../../../../../components/UI/UILoadingPage/UILoadingPage';

export default function PLServiceVideos({ serviceId }: PLServiceVideosProps) {
	const signedInUser = useSelector(getSignedInUserSelector);
	let isUserAdmin = useSelector(getIsUserAdminSelector);
	const [videos, setVideos] = useState<FileType[]>();

	let variables: PLServiceFilesQueryParams = {
		limit: 1000,
		query: { serviceId: serviceId, type: FILE_TYPE_VIDEO },
		isActive: true,
		order: [['index', 'ASC']]
	};

	if (!isUserAdmin) variables.query.status = FILE_STATUS_LIVE;

	const [getVideos, { data: filesData }] = useLazyQuery(GET_PL_SERVICE_VIDEOS_FILES, { variables });

	useEffect(() => {
		(async () => {
			await getVideos();
		})();
	}, []);

	useEffect(() => {
		if (filesData) {
			setVideos(filesData['files']);
		}
	}, [filesData]);

	if (!videos) return <UILoadingPage />;

	const renderUpload = () => {
		if (signedInUser) {
			const allowedRoles = [USER_ROLE_ADMIN, USER_ROLE_REGIONAL_MANAGER, USER_ROLE_EDITOR, USER_ROLE_EDITING_MANAGER];
			if (allowedRoles.indexOf(signedInUser.role) !== -1) {
				return (
					<PLUpload postData={{ serviceId }} multiple={true} uploadStatus={FILE_STATUS_LIVE} fileType={FILE_TYPE_VIDEO} onFileUploadComplete={getVideos} />
				);
			}
		}
		return;
	};
	const renderContent = () => {
		return (
			<UIMultiImageSelect
				disableSort
				type={TYPES.video}
				rightItem={renderUpload()}
				updateData={getVideos}
				files={videos}
				serviceId={serviceId}
				emptySetTitle={strings.emptySetTitle}
				emptySetDescription={strings.emptySetDescription}
			/>
		);
	};

	return <div className='plServiceVideos'>{renderContent()}</div>;
}
