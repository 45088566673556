import React, { useReducer, useRef, useState } from 'react';
import './styles';
import PLImage from '../PLImage';
import * as strings from '../PLUpload/strings';
import { FileInfo, Widget, WidgetAPI } from '@uploadcare/react-widget';
import { toast } from 'react-toastify';
import { getApiUrl } from '../../../utils/API/APIConfig';
import { getAuthorizationHeader } from '../../../utils/API/ApolloClient';
import Lottie from 'react-lottie';

export default function PLEditImage({ updateUser, userId, userName, userImage, imageStyle }: PLEditImageProps) {
	const widgetApi = useRef<WidgetAPI>(null);
	const [isFinalizing, setIsFinalizing] = useState<boolean>(false);
	const [key, rerender] = useReducer((v: number) => v + 1, 0);

	const fileTypeLimit = (allowedFileTypes: string) => {
		const types = allowedFileTypes.split(' ');

		return function (fileInfo: FileInfo) {
			if (fileInfo.name === null) {
				return;
			}
			const extension = fileInfo.name.split('.').pop();

			if (extension && !types.includes(extension.toLowerCase())) {
				toast.error(`Can't upload type: ${extension}`);
				throw new Error('fileType');
			}
		};
	};

	const getAllowedUploadTypes = () => {
		return 'jpg jpeg png pdf';
	};

	const postUploadcare = async (data: object) => {
		const url = `${getApiUrl(true)}/uploadcare/profile-image`;
		return new Promise(async (resolve) => {
			await fetch(url, {
				method: 'POST',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${getAuthorizationHeader(localStorage)}`
				},
				body: JSON.stringify(data)
			})
				.then((response) => {
					resolve(response.json());
				})
				.catch(() => {
					resolve(null);
				});
		});
	};

	const postResponse = async (fileUUID: string) => {
		const data = { fileUUID, userId };
		return await postUploadcare(data);
	};

	const onChange = async (fileInfo: FileInfo) => {
		const response: any = await postResponse(fileInfo.uuid as string);
		await updateUser();
		const { user } = response;
		if (user) {
			toast.success('Profile photo successfully changed!');
		} else {
			toast.error(`Error changing profile photo.`);
		}
		setIsFinalizing(false);
		rerender();
	};

	const validators = [fileTypeLimit(getAllowedUploadTypes())];

	return (
		//@ts-ignore
		<div style={imageStyle} className='edit-image-holder' onClick={() => widgetApi.current?.openDialog()}>
			{isFinalizing ? (
				<Lottie
					options={{
						loop: true,
						autoplay: true,
						//@ts-ignore
						path: 'https://res.cloudinary.com/pineapple-labs/raw/upload/v1592767290/portal-assets/loading-circle_kxpoc7.json',
						rendererSettings: {
							preserveAspectRatio: 'xMidYMid slice'
						}
					}}
					height={48}
					width={48}
					isStopped={false}
					isPaused={false}
				/>
			) : (
				<PLImage imageClassName='edit-image-image' className='edit-image-image' alt={userName} src={userImage} />
			)}
			<div className='drawer-overlay'>
				<p className='edit-image-text'>edit</p>
			</div>
			<div className='editImage--widget'>
				<Widget
					key={key}
					ref={widgetApi}
					multiple={false}
					multipartConcurrency={4}
					previewStep={false}
					localeTranslations={strings.defaultLocaleTranslations}
					validators={validators}
					tabs={'file gdrive gphotos dropbox onedrive box'}
					publicKey={process.env.UPLOADCARE_KEY as string}
					onFileSelect={() => setIsFinalizing(true)}
					onChange={onChange}
				/>
			</div>
		</div>
	);
}
