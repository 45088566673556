import gql from 'graphql-tag';

export const GET_OPTION_EDITOR = gql`
	query GetOption($id: String!) {
		option(id: $id) {
			id
			name
			editorInstructions
			editorFileCount
			editorCertificatesRequired
		}
	}
`;
