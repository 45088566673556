import React, { useEffect, useState } from 'react';
import './styles';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import { UI_LABELED_INPUT_DROPDOWN_TYPE, UI_LABELED_INPUT_TEXT_FIELD } from '../../../../../../components/UI/UILabeledInput/config';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_OPTION_ADMIN } from './queries';
import { useDispatch, useSelector } from 'react-redux';
import * as strings from './strings';
import { OPTION_ADDITIONAL_INFO_TYPES_DROPDOWN } from '../../../../../../utils/Config/Constants';
import AdditionalOptionModal from './OptionAdminModals';
import { storeSelectedOption } from '../../../../../../redux/store/options/actions';
import { getSelectedOptionSelector } from '../../../../../../redux/selectors/OptionSelectors';

export default function OptionAdminTab({ onChangeOption }: OptionAdminTabProps) {
	const selectedOption = useSelector(getSelectedOptionSelector);
	const dispatch = useDispatch();

	const [requiredInfoPrompt, setRequiredInfoPrompt] = useState(selectedOption.requiredInfoPrompt || '');
	const [option, setOption] = useState(selectedOption);
	const [isSaving, setIsSaving] = useState<object>({});

	const [getOptionAdmin, { data: optionData }] = useLazyQuery(GET_OPTION_ADMIN, {
		variables: { id: selectedOption.id }
	});

	useEffect(() => {
		(async () => {
			await getOptionAdmin();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (optionData) {
				const fetchedOption: OptionType = optionData['option'];
				if (fetchedOption.additionalOptions) {
					setOption(fetchedOption);
				}
				setRequiredInfoPrompt(fetchedOption.requiredInfoPrompt);
			}
		})();
	}, [optionData]);

	useEffect(() => {
		dispatch(storeSelectedOption({ ...selectedOption, ...option }));
	}, [optionData]);

	if (!optionData) return null;

	const updateOption = async (key, value) => {
		setIsSaving({ ...isSaving, [key]: true });
		await onChangeOption(key, value);
		delete isSaving[key];
		setIsSaving({ isSaving });
		await getOptionAdmin();
	};

	return (
		<div className='optionAdmin'>
			<UIListInputRow
				type={UI_LABELED_INPUT_DROPDOWN_TYPE}
				placeholder={strings.requiredInfoPlaceholder}
				label={strings.requiredInfoLabel}
				helpText={strings.requiredInfoHelp}
				options={OPTION_ADDITIONAL_INFO_TYPES_DROPDOWN}
				savedValue={{ value: selectedOption.requiredInfo, label: selectedOption.requiredInfo }}
				value={{ value: option.requiredInfo, label: option.requiredInfo }}
				onChangeDropdownValue={(dropdownItem) => updateOption('requiredInfo', dropdownItem.value)}
			/>
			{(option.requiredInfo === 'text' || option.requiredInfo === 'quantityAndNotes') && (
				<UIListInputRow
					placeholder={strings.requiredInfoPromptPlaceholder}
					type={UI_LABELED_INPUT_TEXT_FIELD}
					label={strings.requiredInfoPromptLabel}
					value={requiredInfoPrompt}
					isLoading={isSaving['requiredInfoPrompt']}
					onChangeValue={setRequiredInfoPrompt}
					savedValue={option.requiredInfoPrompt}
					onSave={() => onChangeOption('requiredInfoPrompt', requiredInfoPrompt)}
				/>
			)}
			<AdditionalOptionModal fetchOption={getOptionAdmin} additionalOptionIds={option.additionalOptions} />
		</div>
	);
}
