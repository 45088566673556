import React, { useState } from 'react';
import './styles';
import Geocode from 'react-geocode';
import PLMap from '../../../../../../components/PL/PLMap';
import UIIcon from '../../../../../../components/UI/UIIcon';
import { CloseIcon } from '../../../../../../assets/icons';
import { generateLocationFromGeocoderResult } from '../../../../../../utils/Helpers/ServiceHelper';
import UIIconButton from '../../../../../../components/UI/UIIconButton';
import { LOCATION_KEY } from '../../../../../../utils/Config/Constants';
import { BookingMapProps } from './types';
import UIServiceLocation from '../../../../../../components/UI/UIServiceLocation';
import { bookingMapStyles } from './styles';
import * as strings from './strings';
import { filterGeocoderResults } from './helpers';
import { BASE_STYLE } from '../../../../../../components/PL/PLMap/config';
import { UI_ICON_BUTTON_WHITE_TYPE } from '../../../../../../components/UI/UIIconButton/config';

let ReactMapboxGl, Layer, Feature;
if (process.env.BUILD_TARGET === 'client') {
	ReactMapboxGl = require('react-mapbox-gl');
	Layer = ReactMapboxGl.Layer;
	Feature = ReactMapboxGl.Feature;
}

export default function BookingMap({
	serviceLocation,
	myLocation = JSON.parse(localStorage.getItem(LOCATION_KEY)!),
	updateLocation,
	hideMap,
	onSetLocation
}: BookingMapProps) {
	const [addresses, setAddresses] = useState<google.maps.GeocoderResult[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [flyTo, setFlyTo] = useState<Function>();

	Geocode.setApiKey(process.env.GOOGLE_MAPS!);
	Geocode.enableDebug();

	const selectAddress = (lngLat) => {
		const selectedLngLat = [lngLat.lng, lngLat.lat];
		if (flyTo) flyTo(selectedLngLat);
		setAddresses([]);
		setIsLoading(true);
		Geocode.fromLatLng(lngLat.lat, lngLat.lng).then(
			(response) => {
				let addresses = filterGeocoderResults(response.results);
				onSetLocation({ ...generateLocationFromGeocoderResult(addresses[0]), unitNumber: serviceLocation?.unitNumber });
				setAddresses(addresses);
				setIsLoading(false);
			},
			() => {
				setIsLoading(false);
			}
		);
	};

	let renderAddressItem = (geocoderResult: google.maps.GeocoderResult) => {
		if (geocoderResult) {
			const serviceLocation = generateLocationFromGeocoderResult(geocoderResult);

			const onClickAddressItem = () => {
				setAddresses([]);
				onSetLocation(serviceLocation);
			};

			return (
				<div key={geocoderResult.place_id} className='general-table-row  bookingMapCard__address--item' onClick={onClickAddressItem}>
					<p className='bookingMapCard__address--item--streetAddress'>{serviceLocation.streetAddress}</p>
					<p className='bookingMapCard__address--item--cityStateZip'>
						{serviceLocation.city ? `${serviceLocation.city},` : ''} {serviceLocation.state} {serviceLocation.zipCode}
					</p>
				</div>
			);
		}
		return null;
	};

	const renderAddresses = () => {
		if (serviceLocation?.latitude) {
			const onClickUseAddress = (e) => {
				updateLocation(serviceLocation);
				hideMap(e);
			};

			return (
				<div className='animated-div bookingMapCard'>
					<UIServiceLocation serviceLocation={serviceLocation} hideEditUnitNumber={true} imageSize={80} />
					<UIIconButton dark type={UI_ICON_BUTTON_WHITE_TYPE} onClick={onClickUseAddress} text={strings.useAddressButtonTitle} />
					{(addresses.length > 0 || isLoading) && (
						<>
							<div className='pl-separator' />
							<div className='bookingMapCard__address'>
								<p className='bookingMapCard__address--title'>{strings.nearbyAddressesTitle}</p>
								{addresses.map((address) => {
									return renderAddressItem(address);
								})}
							</div>
						</>
					)}
				</div>
			);
		}
		return (
			<div className='bookingMapCard animated-div '>
				<p className='bookingMapCard--title'>{strings.cardTitle}</p>
				<p className='uiOverlayCard__description' style={{ marginBottom: 'unset' }}>
					{strings.selectPropertyHelper}
				</p>
			</div>
		);
	};

	const mapCenter: [number, number] | undefined =
		serviceLocation?.latitude && serviceLocation.longitude
			? [serviceLocation.longitude, serviceLocation.latitude]
			: myLocation
			? [myLocation.lng, myLocation.lat]
			: undefined;

	return (
		<div className='bookingMapBody' onClick={(e) => e.stopPropagation()}>
			<PLMap
				setFlyTo={setFlyTo}
				zoom={[10]}
				style={BASE_STYLE}
				onClick={(map, evt) => selectAddress(evt.lngLat)}
				center={mapCenter}
				containerStyle={bookingMapStyles.containerStyle}>
				<Layer type='symbol' id='marker' layout={{ 'icon-image': 'marker-15', 'icon-size': 1.8 }}>
					{serviceLocation && (
						<Feature style={{ cursor: 'pointer' }} properties={{ minzoom: 0 }} coordinates={[serviceLocation.longitude, serviceLocation.latitude]} />
					)}
				</Layer>
			</PLMap>
			<div onClick={hideMap} className='backContainer animated-div'>
				<UIIcon className='backContainer--image' alt={'close'} iconSrc={CloseIcon.white} />
			</div>
			<div className='bookingMapAddresses'>{renderAddresses()}</div>
		</div>
	);
}
