import React, { useEffect, useState } from 'react';
import * as strings from './strings';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_PL_SERVICE_RAW_FILES, GET_PL_SERVICE_RAW_FILES_SERVICE, UPDATE_SERVICE_STATUS_FOR_RAWS } from './queries';
import {
	FILE_STATUS_LIVE,
	FILE_TYPE_RAW_IMAGE,
	STATUS_EDITOR_UPLOAD,
	STATUS_FINDING_EDIT_TECHNICIAN,
	STATUS_TECHNICIAN_UPLOAD,
	USER_ROLE_EDITING_MANAGER,
	USER_ROLE_EDITOR
} from '../../../../../../utils/Config/Constants';
import './styles';
import UIMultiFileSelect from '../../../../../../components/UI/UIMultiUploadFileSelect/UIMultiFileSelect';
import { TYPES } from '../../../../../../components/UI/UIMultiUploadFileSelect/config';
import { PLServiceFilesQueryParams } from '../../types';
import PLUpload from '../../../../../../components/PL/PLUpload';
import { getTotalNumberOfPhotographerUploadFilesAvailable } from '../../../../../../utils/Helpers/FileHelper';
import UILoadingPage from '../../../../../../components/UI/UILoadingPage/UILoadingPage';
import * as S from './styles';
import { useSelector } from 'react-redux';
import { getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';

export default function PLServiceRawImages({ serviceId, serviceStatus, serviceEditor }: PLServiceRawsProps) {
	const signedInUser = useSelector(getSignedInUserSelector);

	const [rawFiles, setRawFiles] = useState<FileType[]>();
	const [service, setService] = useState<ServiceType>();

	let variables: PLServiceFilesQueryParams = {
		limit: 1000,
		query: { serviceId: serviceId, type: FILE_TYPE_RAW_IMAGE, status: FILE_STATUS_LIVE },
		isActive: true,
		order: [['index', 'ASC']]
	};

	const [updateService] = useMutation(UPDATE_SERVICE_STATUS_FOR_RAWS);
	const [getService, { data: serviceData }] = useLazyQuery(GET_PL_SERVICE_RAW_FILES_SERVICE, { variables: { serviceId } });
	const [getRawFiles, { data: rawFilesData }] = useLazyQuery(GET_PL_SERVICE_RAW_FILES, { variables });

	console.log(service);

	useEffect(() => {
		(async () => {
			await getService();
			await getRawFiles();
		})();
	}, []);

	useEffect(() => {
		if (rawFilesData) {
			setRawFiles(rawFilesData['files']);
		}
	}, [rawFilesData]);

	useEffect(() => {
		if (serviceData) {
			setService(serviceData['service']);
		}
	}, [serviceData]);

	if (!rawFiles || !service) return <UILoadingPage />;

	const onFileUploadComplete = async () => {
		if (serviceStatus === STATUS_TECHNICIAN_UPLOAD) {
			if (serviceEditor) {
				await updateService({ variables: { serviceId, status: STATUS_EDITOR_UPLOAD } });
			} else {
				await updateService({ variables: { serviceId, status: STATUS_FINDING_EDIT_TECHNICIAN } });
			}
		}
		await getRawFiles();
	};

	const getUploadSuccessMessage = (): string => {
		if (serviceEditor || serviceStatus === STATUS_TECHNICIAN_UPLOAD) {
			return strings.updateSuccessMessage;
		}
		return strings.updateSuccessMessageAlt;
	};

	const renderRawUpload = () => {
		return (
			<PLUpload
				multiple={true}
				fileType={FILE_TYPE_RAW_IMAGE}
				postData={{ serviceId }}
				uploadSuccessMessage={getUploadSuccessMessage()}
				onFileUploadComplete={onFileUploadComplete}
				uploadStatus={FILE_STATUS_LIVE}
			/>
		);
	};

	const renderContent = () => {
		if (service?.dropboxFileRequestId) {
			const renderUploadLink = () => {
				if ([USER_ROLE_EDITOR, USER_ROLE_EDITING_MANAGER].includes(signedInUser.role)) return null;
				return (
					<S.div_Upload>
						<S.p_UploadTitle>Upload raws to Dropbox</S.p_UploadTitle>
						<S.p_UploadDescription>{strings.emptySetDescription(getTotalNumberOfPhotographerUploadFilesAvailable(service))}</S.p_UploadDescription>
						<S.a_UploadLink target='_blank' href={`https://www.dropbox.com/request/${service.dropboxFileRequestId}`}>
							upload link
						</S.a_UploadLink>
					</S.div_Upload>
				);
			};

			const renderDownloadLink = () => {
				if (!service.dropboxRawsLink) return null;

				return (
					<S.a_UploadLink target='_blank' href={service.dropboxRawsLink}>
						download link
					</S.a_UploadLink>
				);
			};

			return (
				<>
					{renderUploadLink()}
					{renderDownloadLink()}
				</>
			);
		}

		return (
			<UIMultiFileSelect
				title={strings.fileSelectTitle}
				emptySetTitle={strings.emptySetTitle}
				emptySetDescription={strings.emptySetDescription(getTotalNumberOfPhotographerUploadFilesAvailable(service))}
				data={rawFiles}
				rightItem={renderRawUpload()}
				serviceId={serviceId}
				updateService={getRawFiles}
				downloadType={TYPES.RAW_TYPE}
			/>
		);
	};

	return <div className='plServiceRaws'>{renderContent()}</div>;
}
