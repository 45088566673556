// Created by jarongallo at 6/17/21
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ServiceAdditionalContactModal from '../../../../../../components/modals/ServiceAdditionalContactModal';
import UITeamMembers from '../../../../../../components/UI/UITeamMembers';
import { USER_ROLE_ADMIN, USER_ROLE_AGENT, USER_ROLE_REGIONAL_MANAGER } from '../../../../../../utils/Config/Constants';
import * as strings from './strings';
import './styles';
import { getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_SERVICE_WITH_TEAM_MEMBERS, UPDATE_SERVICE_TEAM_MEMBER } from './queries';
import { toast } from 'react-toastify';
import UIModal from '../../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../../components/UI/UIOverlayCard';
import UIIconButton from '../../../../../../components/UI/UIIconButton';
import { Trashcan } from '../../../../../../assets/icons';

export default function PlServiceTeamMembers({ serviceId }: PLServiceTeamMemberProps) {
	const currentUser = useSelector(getSignedInUserSelector);

	const [service, setService] = useState<ServiceType>();
	const [contactToDelete, setContactToDelete] = useState<AdditionalContact | undefined>();
	const [showRemoveContactModal, setShowRemoveContactModal] = useState<boolean>(false);
	const [isRemoving, setIsRemoving] = useState<boolean>(false);

	const [updateTeamMembers] = useMutation(UPDATE_SERVICE_TEAM_MEMBER);
	const [getServiceWithTeamMembers, { data: serviceData }] = useLazyQuery(GET_SERVICE_WITH_TEAM_MEMBERS, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getServiceWithTeamMembers();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setService(serviceData['service']);
			}
		})();
	}, [serviceData]);

	if (!service) return null;

	const shouldRenderComponentForService = () => {
		switch (currentUser.role) {
			case USER_ROLE_AGENT:
			case USER_ROLE_ADMIN:
			case USER_ROLE_REGIONAL_MANAGER:
				return true;
			default:
				return false;
		}
	};

	if (!shouldRenderComponentForService()) {
		return null;
	}

	const toggleServiceAdditionalContact = async (contact: AdditionalContact, forceAdd?: Boolean) => {
		setIsRemoving(true);
		let serviceAdditionalContacts = service.additionalAgentContacts;
		if (forceAdd) {
			serviceAdditionalContacts.push(contact);
		} else {
			if (serviceAdditionalContacts.filter((additionalContact: AdditionalContact) => additionalContact.email === contact.email).length > 0) {
				serviceAdditionalContacts.splice(serviceAdditionalContacts.indexOf(contact), 1);
			} else {
				serviceAdditionalContacts.push(contact);
			}
		}

		let variables = { serviceId: serviceId, additionalAgentContacts: serviceAdditionalContacts };
		const response = await updateTeamMembers({ variables });
		const { updateService } = response['data'];

		if (updateService) {
			setIsRemoving(false);
			setShowRemoveContactModal(false);
			await getServiceWithTeamMembers();
			toast.success(strings.manageTeamMembersSuccessMessage);
		}
	};
	const renderRemoveContactModal = () => {
		const hideModal = () => {
			setShowRemoveContactModal(false);
			setContactToDelete(undefined);
		};
		if (!contactToDelete) return null;
		return (
			<UIModal condition={showRemoveContactModal}>
				<UIOverlayCard hideModal={hideModal} title={strings.removeTeamMemberTitle}>
					<UIIconButton
						text={strings.removeButton + contactToDelete.name}
						icon={Trashcan.white}
						isLoading={isRemoving}
						onClick={async () => await toggleServiceAdditionalContact(contactToDelete)}
					/>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderTeamMembers = () => {
		if (service.additionalAgentContacts && service.additionalAgentContacts.length > 0) {
			return (
				<p>
					{service.additionalAgentContacts.length} team member{service.additionalAgentContacts.length > 0 ? 's' : ''} receiving notifications
				</p>
			);
		} else return <div className='plServiceTeamMembers--empty'>no team members...</div>;
	};

	return (
		<ServiceAdditionalContactModal service={service} refreshData={getServiceWithTeamMembers}>
			<div className='plServiceTeamMembers__container'>{renderTeamMembers()}</div>
			{renderRemoveContactModal()}
		</ServiceAdditionalContactModal>
	);
}
