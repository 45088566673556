import React, { useEffect, useState } from 'react';
import './styles';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import {
	UI_LABELED_INPUT_CURRENCY_TYPE,
	UI_LABELED_INPUT_DROPDOWN_TYPE,
	UI_LABELED_INPUT_NUMBER_TYPE
} from '../../../../../../components/UI/UILabeledInput/config';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_OPTION_PHOTOGRAPHER } from './queries';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as strings from './strings';
import { PlusIcon, Trashcan } from '../../../../../../assets/icons';
import UIHover from '../../../../../../components/UI/UIHover/UIHover';
import UIModal from '../../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../../components/UI/UIOverlayCard';
import UILabeledInput from '../../../../../../components/UI/UILabeledInput';
import UIIconButton from '../../../../../../components/UI/UIIconButton';
import { OPTION_PHOTOGRAPHER_CERTIFICATIONS_DROPDOWN_VALUES } from '../../../../../../utils/Config/Constants';
import { storeSelectedOption } from '../../../../../../redux/store/options/actions';
import { getSelectedOptionSelector } from '../../../../../../redux/selectors/OptionSelectors';
import { CHILD_TYPE } from '../../../../../../components/UI/UIListInputRow/config';
import { UI_ICON_BUTTON_FILL_TYPE } from '../../../../../../components/UI/UIIconButton/config';

export default function OptionPhotographerTab({ onChangeOption }: OptionPhotographerTabProps) {
	const dispatch = useDispatch();
	const selectedOption = useSelector(getSelectedOptionSelector);

	const [photographerPay, setPhotographerPay] = useState<number | undefined>(selectedOption.photographerPay / 100);
	const [photographerFileCount, setPhotographerFileCount] = useState<number>(selectedOption.photographerFileCount);
	const [payTime, setPayTime] = useState<number | null>(selectedOption.payTime);
	const [photographerInstructions, setPhotographerInstructions] = useState<string>(selectedOption.photographerInstructions);
	const [type, setType] = useState<string | undefined>(selectedOption.type);
	const [duration, setDuration] = useState<number | undefined>(selectedOption.duration);
	const [certificationToRemove, setCertificationToRemove] = useState<string | undefined>();
	const [photographerCertificatesRequired, setPhotographerCertificatesRequired] = useState<string[] | null>(selectedOption.photographerCertificatesRequired);
	const [selectedValue, setSelectedValue] = useState<ValueType | undefined>(undefined);
	const [isSaving, setIsSaving] = useState<object>({});
	const [renderAddCertOptionOverlay, setRenderAddCertOptionOverlay] = useState<boolean>(false);
	const [renderRemoveCertOptionOverlay, setRenderRemoveCertOptionOverlay] = useState<boolean>(false);

	const [getOptionPhotographer, { data: optionData }] = useLazyQuery(GET_OPTION_PHOTOGRAPHER, {
		variables: { id: selectedOption.id }
	});

	useEffect(() => {
		(async () => {
			await getOptionPhotographer();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (optionData) {
				const fetchedOption: OptionType = optionData['option'];
				setPhotographerPay(fetchedOption.photographerPay / 100);
				setPhotographerFileCount(fetchedOption.photographerFileCount);
				setPayTime(fetchedOption.payTime);
				setPhotographerInstructions(fetchedOption.photographerInstructions);
				setType(fetchedOption.type);
				setDuration(fetchedOption.duration);
				setPhotographerCertificatesRequired(fetchedOption.photographerCertificatesRequired);
				dispatch(storeSelectedOption({ ...selectedOption, ...fetchedOption }));
			}
		})();
	}, [optionData]);

	if (!selectedOption) return null;

	const updateSavedValue = async (key, value) => {
		setIsSaving({ ...isSaving, [key]: true });
		await onChangeOption(key, value);
		delete isSaving[key];
		setIsSaving({ isSaving });
		await getOptionPhotographer();
	};

	const addCertificationToOption = async () => {
		let certifications = selectedOption.photographerCertificatesRequired;
		if (selectedValue) {
			certifications.push(selectedValue.value);
		}
		await onChangeOption('photographerCertificatesRequired', certifications);
		setRenderAddCertOptionOverlay(false);
		await getOptionPhotographer();
		setSelectedValue(undefined);
		toast.success(strings.successAddCert);
	};

	const renderAddOptionCertModal = () => {
		const getAvailableCerts = () => {
			let array = OPTION_PHOTOGRAPHER_CERTIFICATIONS_DROPDOWN_VALUES;
			array = array.filter((x) => (selectedOption.photographerCertificatesRequired ? !selectedOption.photographerCertificatesRequired.includes(x.value) : null));
			return array;
		};

		const hideModal = () => {
			setRenderAddCertOptionOverlay(false);
		};

		return (
			<UIModal condition={renderAddCertOptionOverlay}>
				<UIOverlayCard cardStyle={{ height: '100%' }} hideModal={hideModal} title={strings.selectCertTitle}>
					<UILabeledInput
						type={UI_LABELED_INPUT_DROPDOWN_TYPE}
						containerStyle={{ marginTop: 16, width: 320 }}
						options={getAvailableCerts()}
						onChangeDropdownValue={setSelectedValue}
						value={selectedValue}
						placeholder={strings.selectCert}
					/>
					<div className='general-buttons-container'>
						<UIIconButton dark icon={PlusIcon.grey} type={UI_ICON_BUTTON_FILL_TYPE} text={'add'} onClick={addCertificationToOption} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const removeCertificationFromOption = async () => {
		if (!certificationToRemove) return;

		let certifications = selectedOption.photographerCertificatesRequired;
		let index = certifications.indexOf(certificationToRemove);
		if (index > -1) {
			certifications.splice(index, 1);
		}
		await onChangeOption('photographerCertificatesRequired', certifications);
		setRenderRemoveCertOptionOverlay(false);
		toast.success(strings.successRemoveCert);
		setCertificationToRemove(undefined);
		await getOptionPhotographer();
	};

	const renderRemoveOptionCertModal = () => {
		const hideModal = () => {
			setRenderRemoveCertOptionOverlay(false);
		};

		return (
			<UIModal condition={renderRemoveCertOptionOverlay}>
				<UIOverlayCard hideModal={hideModal} title={strings.removeCertTitle}>
					<p className='general-description'>
						{strings.removeCertCheck} "<b>{certificationToRemove}</b>" from <b>{selectedOption.name}</b>?
					</p>
					<div className='optionPhotographer--button'>
						<UIIconButton icon={Trashcan.white} type={UI_ICON_BUTTON_FILL_TYPE} text={strings.removeCertButton} onClick={removeCertificationFromOption} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	return (
		<div className='optionPhotographer'>
			<UIListInputRow
				type={UI_LABELED_INPUT_CURRENCY_TYPE}
				prefix={strings.dollarPrefix}
				placeholder={strings.techPayPlaceholder}
				helpText={strings.techPayHelp}
				label={strings.techPayLabel}
				value={photographerPay}
				isLoading={isSaving['photographerPay']}
				onChangeNumberValue={setPhotographerPay}
				savedValue={selectedOption.photographerPay / 100}
				onSave={() => updateSavedValue('photographerPay', photographerPay ? parseInt(String(photographerPay * 100)) : null)}
			/>
			<UIListInputRow
				type={UI_LABELED_INPUT_NUMBER_TYPE}
				placeholder={strings.techFilePlaceholder}
				helpText={strings.techFileHelp}
				label={strings.techFileLabel}
				value={photographerFileCount}
				isLoading={isSaving['photographerFileCount']}
				onChangeValue={(photographerFileCountString) => setPhotographerFileCount(parseInt(photographerFileCountString))}
				savedValue={selectedOption.photographerFileCount}
				onSave={() => updateSavedValue('photographerFileCount', photographerFileCount)}
			/>
			<UIListInputRow
				type={UI_LABELED_INPUT_NUMBER_TYPE}
				placeholder={strings.payTimePlaceholder}
				helpText={strings.payTimeHelp}
				label={strings.payTimeLabel}
				value={payTime}
				isLoading={isSaving['payTime']}
				onChangeValue={(payTime) => setPayTime(parseInt(payTime))}
				savedValue={selectedOption.payTime}
				onSave={() => updateSavedValue('payTime', payTime)}
			/>
			<UIListInputRow
				placeholder={strings.techInstructionPlaceholder}
				label={strings.techInstructionLabel}
				value={photographerInstructions}
				isLoading={isSaving['photographerInstructions']}
				onChangeValue={setPhotographerInstructions}
				savedValue={selectedOption.photographerInstructions}
				onSave={() => updateSavedValue('photographerInstructions', photographerInstructions)}
			/>
			{(type === 'video' || type === 'droneVideo') && (
				<UIListInputRow
					type={UI_LABELED_INPUT_NUMBER_TYPE}
					label={strings.durationLabel}
					placeholder={strings.durationPlaceholder}
					value={duration}
					savedValue={selectedOption.duration}
					onChangeValue={(durationString) => setDuration(parseInt(durationString))}
					onSave={() => updateSavedValue('duration', duration)}
				/>
			)}
			<UIListInputRow type={CHILD_TYPE}>
				<div className='optionPhotographer--certHeader'>
					<p className='optionPhotographer--certHeader-text'>Required certifications:</p>
					<UIHover text={strings.addCertHover}>
						<img
							className='optionPhotographer--certHeader-label'
							src={PlusIcon.mint}
							alt={strings.addCertHoverImage}
							onClick={() => setRenderAddCertOptionOverlay(true)}
						/>
					</UIHover>
				</div>
				<div className='optionPhotographer--certList'>
					{photographerCertificatesRequired && photographerCertificatesRequired.length > 0 ? (
						photographerCertificatesRequired.map((certification, index) => {
							return (
								<p
									key={index}
									className='optionPhotographer--text'
									onClick={() => {
										setCertificationToRemove(certification);
										setRenderRemoveCertOptionOverlay(true);
									}}>
									{certification}
								</p>
							);
						})
					) : (
						<p className='optionPhotographer--certList-noRequired'>{strings.noRequiredCerts}</p>
					)}
				</div>
			</UIListInputRow>
			{renderAddOptionCertModal()}
			{renderRemoveOptionCertModal()}
		</div>
	);
}
