import { DownloadIcon, FloorplansIcon, ReportIssueIcon, StageIcon, Trashcan, VisibleWhite } from '../../../assets/icons';
import { UI_ICON_BUTTON_CANCEL_TYPE, UI_ICON_BUTTON_FILL_TYPE, UI_ICON_BUTTON_OUTLINE_TYPE } from '../UIIconButton/config';

export const TYPES = {
	image: 'image',
	floorPlan: 'floorPlan',
	video: 'video',
	rawImage: 'rawImage',
	socialMedia: 'socialMedia'
};

const DOWNLOAD_QUICK_ACTION: UIMultiImageSelectQuickAction = {
	icon: () => DownloadIcon.white,
	helpText: () => 'download',
	type: 'download',
	buttonType: UI_ICON_BUTTON_OUTLINE_TYPE,
	containerClassName: 'fabSelection__container--icon'
};

const FIX_REQUEST_QUICK_ACTION: UIMultiImageSelectQuickAction = {
	icon: () => FloorplansIcon,
	helpText: () => 'edit floor plan',
	type: 'fixRequest',
	containerClassName: 'fullscreenFiles__quickActions--icon'
};

const EDIT_IMAGE_QUICK_ACTION: UIMultiImageSelectQuickAction = {
	icon: () => FloorplansIcon,
	helpText: () => 'edit this image',
	type: 'editImage',
	containerClassName: 'fullscreenFiles__quickActions--icon'
};

const VISIBILITY_QUICK_ACTION: UIMultiImageSelectQuickAction = {
	icon: () => VisibleWhite,
	helpText: () => 'toggle hidden',
	type: 'visible',
	buttonType: UI_ICON_BUTTON_OUTLINE_TYPE,
	containerClassName: 'fabSelection__container--icon'
};

const EDIT_REQUEST_QUICK_ACTION: UIMultiImageSelectQuickAction = {
	icon: () => ReportIssueIcon.red,
	helpText: () => 'request edit',
	type: 'serviceRequest',
	buttonType: UI_ICON_BUTTON_FILL_TYPE,
	containerClassName: 'fabSelection__container--icon'
};

const DELETE_QUICK_ACTION: UIMultiImageSelectQuickAction = {
	icon: () => Trashcan.white,
	helpText: () => 'delete',
	type: 'delete',
	buttonType: UI_ICON_BUTTON_CANCEL_TYPE,
	containerClassName: 'fabSelection__container--icon fabSelection__container--icon-delete'
};

const EDIT_STAGE_QUICK_ACTION: UIMultiImageSelectQuickAction = {
	icon: () => StageIcon.white,
	helpText: () => 'stage',
	type: 'stage',
	buttonType: UI_ICON_BUTTON_OUTLINE_TYPE,
	containerClassName: 'fabSelection__container--icon'
};

export const QUICK_ACTIONS = {
	DOWNLOAD: DOWNLOAD_QUICK_ACTION,
	FIX_REQUEST: FIX_REQUEST_QUICK_ACTION,
	EDIT_IMAGE: EDIT_IMAGE_QUICK_ACTION,
	VISIBILITY: VISIBILITY_QUICK_ACTION,
	EDIT_REQUEST: EDIT_REQUEST_QUICK_ACTION,
	DELETE: DELETE_QUICK_ACTION,
	ADMIN: EDIT_STAGE_QUICK_ACTION
};

export const getDimensionsToFitContainer = (file: FileType, containerBounds?: PLSortableContainerBoundsType) => {
	if (!containerBounds || !file || !file.width || !file.height) return { width: containerBounds?.width, height: 'fit-content' };

	const fileWidth = parseInt(file.width);
	const fileHeight = parseInt(file.height);
	const { width: containerWidth, height: containerHeight } = containerBounds;

	const isLandscape = fileWidth >= fileHeight;
	if (isLandscape) {
		//Scale down image to fit in width
		const landscapeScaleFactor = containerWidth / fileWidth;
		const scaledWidth = fileWidth * landscapeScaleFactor;
		const scaledHeight = fileHeight * landscapeScaleFactor;

		//If scaled height is greater than the container height, scale more.
		if (scaledHeight > containerHeight) {
			const additionalScaleFactor = containerHeight / scaledHeight;
			return { width: additionalScaleFactor * scaledWidth, height: additionalScaleFactor * scaledHeight };
		}

		return { width: scaledWidth, height: scaledHeight };
	} else {
		//If portrait
		//Scale down image to fit in height
		const portraitScaleFactor = containerHeight / fileHeight;
		const scaledWidth = fileWidth * portraitScaleFactor;
		const scaledHeight = fileHeight * portraitScaleFactor;

		//If scaled width is greater than the container width, scale more.
		if (scaledWidth > containerWidth) {
			const additionalScaleFactor = containerWidth / scaledWidth;
			return { width: additionalScaleFactor * scaledWidth, height: additionalScaleFactor * scaledHeight };
		}

		return { width: scaledWidth, height: scaledHeight };
	}
};
