import gql from 'graphql-tag';

export const GET_VIRTUAL_TOUR_FILES = gql`
	query GetVirtualTourFiles($query: JSON!, $isActive: Boolean, $isVisible: Boolean, $status: String, $type: String, $limit: Int) {
		files(query: $query, isActive: $isActive, isVisible: $isVisible, status: $status, type: $type, limit: $limit) {
			id
			isActive
			createdAt
			updatedAt
			type
			filename
			index
			status
			isVisible
			watermarkUrl
			thumbnailUrl
			originalUrl
			printUrl
			webUrl
			metadata
			tags
			width
			height
		}
	}
`;
