import './mobile.scss';
import './tablet.scss';
import './desktop.scss';
import styled, { css } from 'styled-components';
import UIController from '../../UIController';
import UIOverlayCard from '../../UIOverlayCard';

export const UIController_UIMenuBodyRoot = styled(UIController)`
	//DEFAULT
	width: 100%;
	height: 100%;
	max-height: 100%;
	display: flex;
	flex-direction: column;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIMenuBodyContent = styled.div`
	//DEFAULT
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: auto;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: flex;
		justify-content: space-between !important;
		flex-direction: row !important;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		justify-content: space-between !important;
		flex-direction: row !important;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIMenuBodyComponent = styled.div`
	//DEFAULT
	width: 100%;
	display: flex;
	max-height: 100%;
	flex: 1;
	height: 100%;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		max-height: unset;
		align-items: flex-end;
		max-width: calc(100% - 256px);
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIMenuBodyComponentContent = styled.div`
	//DEFAULT
	overflow-y: auto;
	width: 100%;
	flex: 1;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;
		padding: 0;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

const UIMenuBodyMenuCss = css`
	//DEFAULT
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	height: 100%;
	max-height: 100%;
	width: 100%;
	z-index: 5;
	border-radius: 0;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		position: unset;
		top: unset;
		height: 100%;
		margin: 0 0 0 8px;
		width: 320px;
		min-width: 240px;
		z-index: unset;
		background-color: unset;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		position: unset;
		top: unset;
		height: 100%;
		margin: 0 0 0 8px;
		width: 320px;
		min-width: 320px;
		z-index: unset;
		background-color: unset;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIMenuBodyMenu = styled.div`
	${UIMenuBodyMenuCss}
`;

export const UIMenuBodyMenuOverlay = styled(UIOverlayCard)`
	${UIMenuBodyMenuCss}
`;

export const UIMenuBodyMenuContent = styled.div`
	//DEFAULT
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0;
	height: 100%;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 24px 12px;
		border-right: var(--white-4) solid 1px;
		max-height: 100%;
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		align-items: flex-start;
		padding: 24px 12px;
		border-right: var(--white-4) solid 1px;
		max-height: 100%;
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIMenuBodyMenuContentMobile = styled(UIMenuBodyMenuContent)`
	//DEFAULT
	display: flex;
	flex-direction: row;
	align-items: center;
	min-height: 44px;
	min-width: 40px;
	max-height: 44px;
	width: 100%;
	padding: 6px 12px;
	cursor: pointer;

	&:hover {
		background-color: var(--white-2);
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIMenuBodyMenuItems = styled.div`
	//DEFAULT
	display: flex;
	flex-direction: column;
	overflow-y: unset;
	flex: 1 1 auto;
	width: 100%;
	margin: 8px 0 0 0;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		margin: 0;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIMenuBodyMenuTitle = styled.p`
	//DEFAULT
	font-weight: 600;
	text-transform: lowercase;
	min-width: 0;
	font-size: 13pt;
	width: 100%;
	color: var(--text-color);
	margin: 8px -8px 0 0;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		white-space: unset;
		text-align: unset;
		flex: 0;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		padding: 8px 16px;
		font-weight: 700;
		font-size: 12pt;
		margin: 16px 0;
		flex: 0;
		color: white;
		text-align: unset;
		white-space: unset;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
