import React, { useEffect, useState } from 'react';
import './styles';
import 'react-image-lightbox/style.css';
import UILoadingPage from '../../../../../components/UI/UILoadingPage/UILoadingPage';
import { EDIT_TYPE_SERVICE, FILE_STATUS_LIVE, FILE_STATUS_PROOF, FILE_TYPE_IMAGE, SCREEN_TYPE } from '../../../../../utils/Config/Constants';
import { getTotalNumberOfFilesAvailable } from '../../../../../utils/Helpers/FileHelper';
import UIModal from '../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../components/UI/UIOverlayCard';
import { useLazyQuery } from '@apollo/react-hooks';
import { ZendeskAPI } from 'react-zendesk';
import { GET_FILES_FOR_PROOFING, GET_SERVICE_FOR_PROOFING } from './queries';
import ServiceProofingCart from './components/ServiceProofingCart';
import ServiceProofingHelpCard from './components/ServiceProofingHelpCard';
import * as strings from './strings';
import { getDimensionsToFitContainer } from '../../../../../components/UI/UIMultiImageSelect/config';
import UIIcon from '../../../../../components/UI/UIIcon';
import { AddToCartIcon, NotInCartIcon, RemoveFromCartIcon } from '../../../../../assets/icons';
import PLImage from '../../../../../components/PL/PLImage';
import FullscreenFilesQuickAction from '../FullscreenFiles/components/FullscreenFilesQuickAction';
import { FULLSCREEN_QUICK_ACTION_ADD_TO_CART, FULLSCREEN_QUICK_ACTION_REMOVE_FROM_CART, FULLSCREEN_TYPES } from '../FullscreenFiles/config';
import FullscreenFiles from '../FullscreenFiles';
import Measure from 'react-measure';
import { ReactSortable } from 'react-sortablejs';
import { useSelector } from 'react-redux';
import { getScreenTypeSelector } from '../../../../../redux/selectors/ControlSelectors';
import ServiceProofingPay from './components/ServiceProofingPay';

export default function ServiceProofing({ serviceId, clientId, hideModal, onProofingSuccess }: ServiceProofingProps) {
	const screenType = useSelector(getScreenTypeSelector);

	const [showProofingHelpModal, setShowProofingHelpModal] = useState<boolean>(false);
	const [service, setService] = useState<ServiceType>();
	const [sortedFiles, setSortedFiles] = useState<FileType[]>([]);
	const [selectedFiles, setSelectedFiles] = useState<FileType[]>([]);
	const [availableFileCount, setAvailableFileCount] = useState<number>(0);
	const [liveImages, setLiveImages] = useState<FileType[]>([]);
	const [proofOnlyFiles, setProofOnlyFiles] = useState<FileType[]>([]);
	const [selectedImageIndex, setSelectedImageIndex] = useState<number>();
	const [containerBounds, setContainerBounds] = useState<PLSortableContainerBoundsType>();

	const [getService, { data: serviceData }] = useLazyQuery(GET_SERVICE_FOR_PROOFING, { variables: { id: serviceId } });
	const [getServiceImages, { data: filesData }] = useLazyQuery(GET_FILES_FOR_PROOFING, {
		variables: {
			limit: 1000,
			order: [['index', 'ASC']],
			query: {
				isActive: true,
				serviceId,
				type: FILE_TYPE_IMAGE
			}
		}
	});

	const getAmountRequiringPurchase = () => {
		let selectedFileCount = selectedFiles.length;
		let amountRequiringPurchase = selectedFileCount - availableFileCount;
		if (amountRequiringPurchase < 0) {
			amountRequiringPurchase = 0;
		}
		return amountRequiringPurchase;
	};

	useEffect(() => {
		setAvailableFileCount(getTotalNumberOfFilesAvailable(service, liveImages.length));
	});

	useEffect(() => {
		(async () => {
			getService();
			getServiceImages();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setService(serviceData['service']);
			}
		})();
	}, [serviceData]);

	useEffect(() => {
		(async () => {
			if (filesData) {
				const fetchedImages: FileType[] = filesData['files'];
				setSortedFiles(fetchedImages);
				setSelectedFiles(fetchedImages.filter((file) => file.status !== FILE_STATUS_LIVE));
				setLiveImages(fetchedImages.filter((proofingImage) => proofingImage.status === FILE_STATUS_LIVE));
				setProofOnlyFiles(fetchedImages.filter((proofingImage) => proofingImage.status === FILE_STATUS_PROOF));
			}
		})();
	}, [filesData]);

	if (!service) return null;

	if (!service) {
		return (
			<UIOverlayCard hideModal={hideModal} contentClassName='serviceProofing__content' className='serviceProofing__card'>
				<UILoadingPage />
			</UIOverlayCard>
		);
	}

	const getIsImageSelected = (file: FileType): boolean => {
		return selectedFiles.map((file) => file.id).includes(file.id);
	};

	const renderProofingHelpModal = () => {
		const hideModal = () => setShowProofingHelpModal(false);

		return (
			<UIModal condition={showProofingHelpModal}>
				<ServiceProofingHelpCard hideModal={hideModal} serviceType={service.type} />
			</UIModal>
		);
	};

	const renderRightItem = () => {
		return (
			<p className='serviceProofing--help' onClick={() => setShowProofingHelpModal(true)}>
				{strings.help}
			</p>
		);
	};

	const toggleImageSelected = (file: FileType) => {
		const isSelected = getIsImageSelected(file);
		if (isSelected) {
			setSelectedFiles(selectedFiles.filter((selectedFile) => selectedFile.id !== file.id));
		} else {
			setSelectedFiles([...selectedFiles, file]);
		}
	};

	const setImageSelected = (proofingImage: FileType) => {
		toggleImageSelected(proofingImage);

		if (selectedImageIndex !== undefined) {
			setSelectedImageIndex(sortedFiles.indexOf(proofingImage));
		}
	};

	const sortedSelectedFiles = selectedFiles.map((file) => {
		const sameServiceImage = sortedFiles
			.map((serviceImage, index) => {
				return { ...serviceImage, index };
			})
			.filter((serviceImage) => serviceImage.id === file.id)[0];
		return { ...file, ...sameServiceImage, index: sameServiceImage?.index ?? file.index };
	});

	const renderLightbox = () => {
		if (selectedImageIndex === undefined) {
			return;
		}

		const renderAdditionalItems = (file) => {
			if (service.type === EDIT_TYPE_SERVICE) return null;
			const isSelected = getIsImageSelected(file);
			if (file.status === FILE_STATUS_LIVE) return null;

			if (getAmountRequiringPurchase() > 0 || !isSelected) {
				if (selectedImageIndex === undefined) return null;
				return (
					<FullscreenFilesQuickAction
						file={file}
						quickAction={isSelected ? FULLSCREEN_QUICK_ACTION_REMOVE_FROM_CART : FULLSCREEN_QUICK_ACTION_ADD_TO_CART}
						onClickQuickAction={setImageSelected}
						isUpdating={{}}
					/>
				);
			}
			return null;
		};

		const renderImageOverlay = (file) => {
			const isSelected = getIsImageSelected(file);

			if (isSelected || file.status === FILE_STATUS_LIVE) return null;
			return (
				<div className='serviceProofing__item--overlay'>
					<UIIcon className='serviceProofing__item--overlay-icon' iconSrc={NotInCartIcon.white} alt={'not in cart'} />
				</div>
			);
		};

		return (
			<FullscreenFiles
				type={FULLSCREEN_TYPES.PROOFING_TYPE}
				disableImage={(file) => !getIsImageSelected(file) && file.status !== FILE_STATUS_LIVE}
				renderImageOverlay={renderImageOverlay}
				renderAdditionalItems={renderAdditionalItems}
				initialSlide={selectedImageIndex}
				goBack={() => setSelectedImageIndex(undefined)}
				files={sortedFiles}
				headerComponent={
					<ServiceProofingCart
						style={{ padding: '0 0 12px 0', marginBottom: 16 }}
						getAmountRequiringPurchase={getAmountRequiringPurchase}
						getIsImageSelected={getIsImageSelected}
						proofOnlyFiles={proofOnlyFiles}
						selectedImages={sortedSelectedFiles}
						service={service}
					/>
				}
			/>
		);
	};

	const renderSortableItem = (proofingImage: FileType, maxDimension: number) => {
		const isSelected = getIsImageSelected(proofingImage);

		const dimensions = getDimensionsToFitContainer(proofingImage, { width: maxDimension, height: maxDimension });
		const renderImageOverlay = () => {
			if (isSelected) return null;
			if (proofingImage.status === FILE_STATUS_LIVE) {
				return (
					<div className='serviceProofing__item--overlay' style={{ cursor: 'pointer', backgroundColor: 'rgba(0,0,0,0.75)' }}>
						<p className='serviceProofing__item--overlay-text'>{strings.alreadyPurchased}</p>
					</div>
				);
			}
			return (
				<div className='serviceProofing__item--overlay' style={{ cursor: 'pointer' }}>
					<UIIcon className='serviceProofing__item--overlay-icon' iconSrc={NotInCartIcon.white} alt={'not in cart'} />
				</div>
			);
		};

		const imageClassName = `serviceProofing__item--image ${
			isSelected || proofingImage.status === FILE_STATUS_LIVE ? 'serviceProofing__item--image-selected' : ''
		}`;

		const onClickItem = (e) => {
			e.stopPropagation();
			if (proofingImage.status !== FILE_STATUS_LIVE) setImageSelected(proofingImage);
		};

		return (
			<div className='serviceProofing__item' key={proofingImage.id} style={{ width: dimensions.width }}>
				<PLImage
					src={proofingImage.thumbnailUrl}
					thumbnailSrc={proofingImage.thumbnailUrl}
					alt={'image thumbnail'}
					width={dimensions.width}
					height={dimensions.height}
					renderImageOverlay={renderImageOverlay}
					style={{ position: 'relative' }}
					imageClassName={imageClassName}
					borderRadius={3}
					onClick={() => setSelectedImageIndex(sortedFiles.map((file) => file.id).indexOf(proofingImage.id))}
				/>
				{service.type !== EDIT_TYPE_SERVICE && proofingImage.status !== FILE_STATUS_LIVE && (
					<>
						{getAmountRequiringPurchase() > 0 || !isSelected ? (
							<div
								className='proofingOverlay--discarded__container'
								onClick={onClickItem}
								style={{ backgroundColor: isSelected ? 'var(--red)' : 'var(--mint-primary)' }}>
								<UIIcon
									className='proofingOverlay--discarded__container-icon'
									iconSrc={isSelected ? RemoveFromCartIcon.grey : AddToCartIcon.grey}
									alt={'add/remove from cart'}
								/>
							</div>
						) : null}
					</>
				)}
			</div>
		);
	};

	const onResize = (contentRect: Object | any) => setContainerBounds(contentRect.bounds);

	const renderServiceImages = (file) => {
		if (!containerBounds) return <div />;
		let itemsPerRow = Math.floor(containerBounds.width / (screenType === SCREEN_TYPE.mobile ? containerBounds.width : 360));
		let maxDimension = (containerBounds.width - 16) / itemsPerRow;
		return renderSortableItem(file, maxDimension - (screenType === SCREEN_TYPE.mobile ? 0 : 16));
	};

	return (
		<UIOverlayCard
			title={strings.sortAndProof}
			hideModal={hideModal}
			contentClassName='serviceProofing__card-content'
			className='serviceProofing__card'
			rightItem={renderRightItem()}>
			<ServiceProofingCart
				getAmountRequiringPurchase={getAmountRequiringPurchase}
				getIsImageSelected={getIsImageSelected}
				proofOnlyFiles={proofOnlyFiles}
				selectedImages={sortedSelectedFiles}
				service={service}
			/>
			<Measure bounds onResize={onResize}>
				{({ measureRef }: Function | any) => (
					<div ref={measureRef} className='sortableContainer'>
						<ReactSortable
							disabled={screenType === SCREEN_TYPE.mobile}
							className='sortableHeader'
							animation={300}
							list={sortedFiles}
							setList={setSortedFiles}
							selectedClass='sortableHeader--selected'>
							{sortedFiles.map(renderServiceImages)}
						</ReactSortable>
					</div>
				)}
			</Measure>
			<ServiceProofingPay
				getAmountRequiringPurchase={getAmountRequiringPurchase}
				listingId={service.listing.id}
				onProofingSuccess={onProofingSuccess}
				selectedImages={sortedSelectedFiles}
				service={service}
				clientId={clientId}
			/>
			{renderProofingHelpModal()}
			{renderLightbox()}
		</UIOverlayCard>
	);
}
