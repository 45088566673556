import { useDispatch, useSelector } from 'react-redux';
import { getSignedInUserSelector } from '../../redux/selectors/CurrentUserSelectors';
import { STATUS_PROOFING, USER_ROLE_AGENT, USER_ROLE_PHOTOGRAPHER } from '../../utils/Config/Constants';
import { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from 'react-apollo';
import Queries from '../../utils/API/Queries';
import { GET_REQUIRED_ACTION_SERVICES, GET_SCHEDULE_REQUESTS } from './queries';
import { setPendingScheduleRequests, setServicesRequiringProofing } from '../../redux/store/actionItems/actions';
import { GET_FINANCE_SERVICES_WITH_QUERY } from '../../pages/portal/roles/Admin/AdminFinances/queries';

const fetchRequiredActions = (refreshToken) => {
	const signedInUser = useSelector(getSignedInUserSelector);

	const dispatch = useDispatch();

	let variables;

	switch (signedInUser?.role) {
		case USER_ROLE_AGENT:
			//todo: fetch schedule request services
			variables = { query: { clientId: signedInUser.id, status: STATUS_PROOFING } };
			break;
		case USER_ROLE_PHOTOGRAPHER:
			variables = { query: { clientId: signedInUser.id, status: STATUS_PROOFING } };
			break;
	}

	const [getProofingServices, { data: servicesData }] = useLazyQuery(GET_REQUIRED_ACTION_SERVICES, { variables });
	const [getScheduleRequests, { data: scheduleRequestsData }] = useLazyQuery(GET_SCHEDULE_REQUESTS, {
		variables: { query: { scheduleAgentId: signedInUser?.id || '' } }
	});

	useEffect(() => {
		getProofingServices();
		getScheduleRequests();
	}, [refreshToken]);

	useEffect(() => {
		if (!servicesData?.services) return;
		dispatch(setServicesRequiringProofing(servicesData?.services));
	}, [servicesData]);

	useEffect(() => {
		if (!scheduleRequestsData?.scheduleRequests) return;
		dispatch(setPendingScheduleRequests(scheduleRequestsData?.scheduleRequests));
	}, [scheduleRequestsData]);
};

export default fetchRequiredActions;
