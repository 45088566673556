import React, { useState } from 'react';
import * as S from './styles';

export default function VTVideos({ videos }: VTVideosProps) {
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const renderVideoPlayer = (video) => {
		const onVideoReady = () => setTimeout(() => setIsLoaded(true), 300);

		return <S.ReactPlayer_VTVideosPlayer key={video.id} onReady={onVideoReady} controls playing={video.index === 0} url={video.printUrl} height={'100%'} on />;
	};

	return (
		<S.div_VTVideosContainer>
			{!isLoaded && <S.div_VTVideosLoadingBar />}
			<S.div_VTVideosWrapper isLoaded={isLoaded}>{videos.map(renderVideoPlayer)}</S.div_VTVideosWrapper>
		</S.div_VTVideosContainer>
	);
}
