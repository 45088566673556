export const removeCertButton = 'remove';
export const addButton = 'add';
export const selectCert = 'select a certification...';
export const selectCertTitle = 'Add a required certification';
export const addCertHover = 'add required certification';
export const removeCertCheck = 'Are you sure you would like to remove the certification';
export const removeCertTitle = 'Remove certification';
export const editorInstructionsLabel = 'INSTRUCTIONS';
export const editorInstructionsPlaceholder = 'Specific instructions for the editor for every time they edit this option...';
export const editorFileUploadHelp = 'This is the number of files the editor should upload after editing.';
export const editorFileUploadPlaceholder = 'Editor total file upload count...';
export const editorFileUploadLabel = 'FILE UPLOAD COUNT';
export const requiredCertsString = 'Required certifications:';
export const noRequiredCertsString = 'this option has no required photographer certifications yet';
