import gql from 'graphql-tag';

export const GET_SERVICE_BOOKED_OPTIONS = gql`
	query bookedOptions($query: JSON!) {
		bookedOptions(query: $query) {
			id
			name
			price
			quantity
			option {
				id
				name
				price
				quantity
				type
				packageType
				deliveryTimeEstimate
				exampleUrl
				photographerCertificatesRequired
				editorCertificatesRequired
				packageType
				photographerPay
				traineePay
			}
			bookingUser {
				id
				name
			}
			photographerCertificatesRequired
			editorCertificatesRequired
			packageType
			photographerPay
			traineePay
			additionalInfo
			additionalInfoType
		}
	}
`;

export const UPDATE_SERVICE_ORDER_INFORMATION = gql`
	mutation UpdateServiceStatusForRawVideos($serviceId: String!, $orderInformation: JSON!) {
		updateService(id: $serviceId, orderInformation: $orderInformation) {
			id
			orderInformation
		}
	}
`;
