import React, { Component } from 'react';
import './mark-service-complete-modal.scss';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/react-hoc';
import UIOverlayCard from '../../UI/UIOverlayCard';
import PropTypes from 'prop-types';
import ApproveServiceCheckboxItem from '../ApproveServiceModal/ApproveServiceCheckboxItem/ApproveServiceCheckboxItem';
import { REQUIRED_CHECK_TYPES } from '../ApproveServiceModal/ApproveServiceModalConfig';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../utils/Helpers/AppHelper';

class MarkServiceCompleteModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			completedSteps: {}
		};
	}

	//Mark: Component functions

	getRequiredChecks = () => {
		let requiredChecks = [];

		requiredChecks.push(REQUIRED_CHECK_TYPES.markComplete);

		return requiredChecks;
	};

	//Mark: Render functions
	render() {
		const { hideModal } = this.props;

		const requiredChecks = this.getRequiredChecks();

		return (
			<UIOverlayCard hideModal={hideModal} title={'Complete checklist'} description={'Please review all of the following before marking the service complete'}>
				<div className='approveChecklist'>{requiredChecks.map(this.renderRequiredCheck)}</div>
			</UIOverlayCard>
		);
	}

	renderRequiredCheck = (requiredCheckType) => {
		const { completedSteps } = this.state;
		const { updateService, service, markComplete } = this.props;

		const onCheckChanged = () => {
			let newValue = !completedSteps[requiredCheckType.type];
			if (newValue === undefined) {
				newValue = true;
			}
			this.setState({ completedSteps: { ...completedSteps, [requiredCheckType.type]: newValue } });
		};

		const isChecked = completedSteps[requiredCheckType.type] === true;

		return (
			<ApproveServiceCheckboxItem
				key={requiredCheckType.type}
				type={requiredCheckType.type}
				onCheckChanged={onCheckChanged}
				isChecked={isChecked}
				title={requiredCheckType.title}
				subtext={requiredCheckType.subtext}
				renderAdditionalComponent={requiredCheckType.renderAdditionalComponent}
				updateService={updateService}
				service={service}
				markComplete={markComplete}
				approveDisabled={Object.entries(completedSteps).filter((step) => step[1] === true).length !== this.getRequiredChecks().length}
			/>
		);
	};
}

MarkServiceCompleteModal.propTypes = {
	hideModal: PropTypes.func,
	markComplete: PropTypes.func.isRequired,
	updateService: PropTypes.func.isRequired,
	service: PropTypes.object.isRequired
};

export default withApollo(connect(commonMapStateToProps, commonMapDispatchToProps)(MarkServiceCompleteModal));
