import gql from 'graphql-tag';

export const GET_OPTION_NAME = gql`
	query GetOptionIndividualOption($id: String!) {
		option(id: $id) {
			id
			name
		}
	}
`;
