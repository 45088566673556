export const assignEditorSuccess = 'Updated editor!';
export const assignEditorInfo = 'You must select an editor!';
export const noEditorString = 'No editor assigned yet...';
export const assignEditorModalTitle = 'Assign an editor';
export const assignEditorModalDescription = 'This editor will receive an email and no other editors will be able to see this service.';
export const assignEditorModalEmptySet = 'No editors found with that name';
export const assignEditorModalLabel = 'Who will be the editor?';
export const assignEditorModalPlaceholder = 'search for editors...';
export const assignEditorModalButton = 'assign editor';
export const assignEditorModalDisabledButton = 'Please search and select an editor to assign to this service.';
export const removeEditorButton = 'remove';
export const removeEditorSuccess = 'removed editor from service';
export const editEditorModalTitle = 'Edit editor';
export const editorLabel = 'editor';
export const editorAddHover = 'add editor';
export const editEditorHover = 'edit editor';
