import React, { useEffect, useState } from 'react';
import './styles';
import { useDispatch, useSelector } from 'react-redux';
import { OPTION_ADMIN_TAB, OPTION_EDITOR_TAB, OPTION_GENERAL_TAB, OPTION_PHOTOGRAPHER_TAB, OPTION_REGIONS_TAB } from '../../../../utils/Config/MenuBodyTabs';
import UIOverlayCard from '../../../../components/UI/UIOverlayCard';
import UIDropdownSelect from '../../../../components/UI/UIDropdownSelect';
import OptionGeneralTab from './IndividualOptionTabs/OptionGeneralTab';
import OptionPhotographerTab from './IndividualOptionTabs/OptionPhotographerTab';
import OptionEditorTab from './IndividualOptionTabs/OptionEditorTab';
import OptionRegionTab from './IndividualOptionTabs/OptionRegionTab';
import OptionAdminTab from './IndividualOptionTabs/OptionAdminTab';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { getSelectedOptionSelector } from '../../../../redux/selectors/OptionSelectors';
import { GET_OPTION_NAME } from './queries';
import UIIcon from '../../../../components/UI/UIIcon';
import { Trashcan } from '../../../../assets/icons';
import UIModal from '../../../../components/UI/UIModal';
import UIIconButton from '../../../../components/UI/UIIconButton';
import { showOption } from '../../../../redux/store/user/actions';
import { toast } from 'react-toastify';
import Queries from '../../../../utils/API/Queries';
import * as strings from './strings';
import { storeSelectedOption } from '../../../../redux/store/options/actions';
import { camelCaseToSentence } from '../../../../utils/Helpers/StringHelpers';
import { UI_ICON_BUTTON_CANCEL_TYPE } from '../../../../components/UI/UIIconButton/config';

export default function IndividualOption({ hideModal }: IndividualOptionProps) {
	const selectedOption = useSelector(getSelectedOptionSelector);
	const dispatch = useDispatch();

	const [selectedTab, setSelectedTab] = useState(OPTION_GENERAL_TAB);
	const [showDeleteOptionModal, setShowDeleteOptionModal] = useState<boolean>(false);
	const [updateOptionMutation] = useMutation(Queries.UPDATE_OPTION_MUTATION);

	const [getOptionInfo, { data: optionData }] = useLazyQuery(GET_OPTION_NAME, {
		variables: { id: selectedOption?.id }
	});

	const menuItems = [OPTION_GENERAL_TAB, OPTION_PHOTOGRAPHER_TAB, OPTION_EDITOR_TAB, OPTION_REGIONS_TAB, OPTION_ADMIN_TAB];

	useEffect(() => {
		(async () => {
			await getOptionInfo();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (optionData) {
				dispatch(storeSelectedOption({ ...selectedOption, ...optionData['option'] }));
			}
		})();
	}, [optionData]);

	const onChangeOption = async (key: string, value: any, message?: string) => {
		if (key === null || key === undefined) {
			return;
		}
		let option = Object.assign({}, selectedOption);
		if (option) {
			option[key] = value;
		}
		let variables = { id: selectedOption.id };
		variables[key] = value;
		const response = await updateOptionMutation({ variables }).catch((error) => {
			toast.error(error.message);
		});
		const { updateOption } = response['data'];
		if (updateOption) {
			dispatch(storeSelectedOption({ ...selectedOption, ...optionData['option'] }));
			toast.success(message || strings.successOptionUpdate(camelCaseToSentence(key)));
		} else {
			toast.error(strings.errorUpdatingOption);
		}
	};

	const renderDeleteIcon = () => {
		return <UIIcon className='individualOption__delete' iconSrc={Trashcan.white} onClick={() => setShowDeleteOptionModal(true)} alt={'delete'} />;
	};

	const renderDeleteOptionModal = () => {
		const hideDeleteModal = () => setShowDeleteOptionModal(false);

		const onDelete = async () => {
			await onChangeOption('isActive', false, 'Deleted option!');
			dispatch(showOption(undefined));
			hideDeleteModal();
			hideModal();
		};

		return (
			<UIModal condition={showDeleteOptionModal}>
				<UIOverlayCard hideModal={hideDeleteModal} title={strings.deleteOptionTitle} description={strings.deleteOptionDescription}>
					<div className='optionAdmin--buttons'>
						<UIIconButton dark type={UI_ICON_BUTTON_CANCEL_TYPE} text={strings.deleteButton} onClick={onDelete} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderTabComponent = () => {
		switch (selectedTab.value) {
			case OPTION_GENERAL_TAB.value:
				return <OptionGeneralTab onChangeOption={onChangeOption} />;
			case OPTION_PHOTOGRAPHER_TAB.value:
				return <OptionPhotographerTab onChangeOption={onChangeOption} />;
			case OPTION_EDITOR_TAB.value:
				return <OptionEditorTab onChangeOption={onChangeOption} />;
			case OPTION_REGIONS_TAB.value:
				return <OptionRegionTab onChangeOption={onChangeOption} />;
			case OPTION_ADMIN_TAB.value:
				return <OptionAdminTab onChangeOption={onChangeOption} />;
			default:
				return null;
		}
	};

	const cardStyle = { maxWidth: 'calc(min(100%, 600px))', height: '100%' };

	return (
		<UIOverlayCard
			hideModal={hideModal}
			cardStyle={cardStyle}
			contentClassName={'optionPadding'}
			title={selectedOption?.name ?? ''}
			rightItem={renderDeleteIcon()}>
			<UIDropdownSelect containerClassName='individualOption--tabs' selectedTab={selectedTab} updateSelected={setSelectedTab} tabs={menuItems} />
			<div className='individualOption'>{renderTabComponent()}</div>
			{renderDeleteOptionModal()}
		</UIOverlayCard>
	);
}
