// Created by carsonaberle at 3/25/22
import styled from 'styled-components';

export const VTSlideshowContainer = styled.div<{ paddingBottom: string }>`
	//DEFAULT
	position: relative;
	width: 100%;
	height: 100%;
	max-height: 100%;
	max-width: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	padding: 12px 0;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		height: unset;
		min-height: 360px;
		justify-content: space-between;
		padding-bottom: 24px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		justify-content: space-between;
		padding-bottom: 36px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const VTSlideshowControlIcon = styled.img`
	z-index: 2;
	border-radius: 36px;
	cursor: pointer;
	height: 36px;
	width: 36px;
	transition: 150ms background-color ease-in;
	-webkit-animation: fadeIn 500ms ease-in !important;
	animation: fadeIn 500ms ease-in !important;

	&:hover {
		background-color: #12121233;
	}

	&:active {
		background-color: #12121233;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		height: 36px;
		width: 36px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		height: 48px;
		width: 48px;
	}

	//MONITOR
	@media only screen and (min-width: 1500px) {
	}
`;

export const img_VTSlideshowBack = styled(VTSlideshowControlIcon)`
	//DEFAULT
	display: none;
	margin-right: 12px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: flex;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		margin-right: 24px;
	}

	//MONITOR
	@media only screen and (min-width: 1500px) {
	}
`;

export const img_VTSlideshowForward = styled(VTSlideshowControlIcon)`
	//DEFAULT
	display: none;
	margin-left: 12px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: flex;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		margin-left: 24px;
	}

	//MONITOR
	@media only screen and (min-width: 1500px) {
	}
`;

export const VTSlideshowContent = styled.div<{ taller: boolean }>`
	//DEFAULT
	max-height: 100%;
	height: 100%;
	//justify-content: center;
	align-items: center;
	// justify-content: ${(props) => (props.taller ? 'unset' : 'center')};
	width: fit-content;
	max-width: 100%;
	position: relative;

	//SHORT
	@media only screen and (max-height: 720px) {
		height: 100%;
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//SHORT LAPTOP
	@media only screen and (max-height: 1025px) and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const VTSlideshowVertical = styled.div`
	//DEFAULT
	width: 100%;
	height: 100%;
	align-items: center;
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		justify-content: center;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		justify-content: center;
	}

	//SHORT LAPTOP
	@media only screen and (max-height: 1025px) and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const VTSlideshowHorizontal = styled.div`
	//DEFAULT
	position: relative;
	max-width: 100%;
	max-height: 100%;
	align-items: center;
	width: fit-content;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//SHORT LAPTOP
	@media only screen and (max-height: 1025px) and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const img_VTSlideshowImage = styled.img`
	//DEFAULT
	max-width: 100%;
	max-height: 100%;
	transition: 250ms all ease-in;
	border-radius: 6px;
	user-select: none;
	animation: fadeIn 500ms linear;
	-webkit-animation: fadeIn 500ms linear;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		border-radius: 12px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		border-radius: 24px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTSlideshowControlContainer = styled.div`
	//DEFAULT
	position: absolute;
	bottom: 12px;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding: 0 12px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		bottom: 24px;
		padding: 0 24px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		padding: 0 36px;
		bottom: 36px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const VTSlideshowControl = styled.div`
	//position: absolute;
	//bottom: 24px;
	width: 48px;
	height: 48px;
	border-radius: 36px;
	background-color: #f1f1f1;
	border: #dadada 2px solid;
	transition: border 150ms ease-in;
	z-index: 3;
	cursor: pointer;
	-webkit-animation: fadeIn 100ms ease-in !important;
	animation: fadeIn 100ms ease-in !important;

	&:hover {
		border: #121212 solid 2px;
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		bottom: 36px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		bottom: 48px;
		width: 60px;
		height: 60px;
	}
`;

export const VTSlideshowPlaybackControl = styled(VTSlideshowControl)`
	//DEFAULT
	left: 24px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		left: 36px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		left: 60px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const VTSlideshowAudioControl = styled(VTSlideshowControl)`
	//DEFAULT
	display: none;
	right: 24px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: flex;
		right: 36px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		right: 60px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const img_VTSlideshowControlIcon = styled.img`
	//DEFAULT
	margin: -2px 0 0 -1px;
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const VTSlideshowProgressBar = styled.div`
	--horizontal-padding: 102px;
	//DEFAULT
	position: absolute;
	bottom: 18px;
	height: 12px;
	left: 72px;
	border-radius: 6px;
	right: 100%;
	background-color: #12121255;
	z-index: 2;
	margin: 0 -24px;
	-webkit-animation: leftToRight 5000ms linear;
	animation: leftToRight 5000ms linear;
	animation-delay: 0ms;
	-webkit-animation-delay: 0ms;
	backdrop-filter: blur(3px);

	/* Chrome, Safari, Opera */
	@-webkit-keyframes leftToRight {
		from {
			right: 100%;
		}
		to {
			right: var(--horizontal-padding);
		}
	}

	/* Standard syntax */
	@keyframes leftToRight {
		from {
			right: 100%;
		}
		to {
			right: var(--horizontal-padding);
		}
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		left: 90px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		bottom: 24px;
		left: var(--horizontal-padding);
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
