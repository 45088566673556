// Created by carsonaberle at 3/24/22
import styled from 'styled-components';

export const div_VTSectionBarContainer = styled.div`
	//DEFAULT
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	overflow-x: scroll;
	min-height: 38px !important;
	-webkit-animation: fadeIn 500ms ease-in !important;
	animation: fadeIn 500ms ease-in !important;
	//border-top: #1212121c solid 1px;
	border-bottom: #1212121c solid 1px;
	padding: 0 12px 12px 0;
	background-color: white;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		min-height: 40px !important;
		padding: 0 24px 24px 48px;
		border: none;
		background-color: unset;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		width: fit-content;
		min-height: 40px !important;
		padding: 0;
		border: none;
		background-color: unset;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTSectionBarItem = styled.div`
	//DEFAULT
	height: 36px;
	border: #1212121f solid 2px;
	padding: 0 16px;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	margin-right: 6px;
	cursor: pointer;
	transition: 350ms all ease-out;
	user-select: none;

	& > p {
		width: 100%;
		user-select: none;
		opacity: 1 !important;
		color: #121212;
		white-space: nowrap;
		font-size: 10pt;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		height: 40px;
		padding: 0 24px;
		margin-right: 12px;

		& > p {
			font-size: 12pt;
		}
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		height: 40px;
		padding: 0 24px;
		margin-right: 12px;

		&:hover {
			opacity: 0.9;
			border-color: #121212;
		}

		& > p {
			font-size: 14pt;
		}
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTSectionBarItemSelected = styled(div_VTSectionBarItem)`
	//DEFAULT
	background-color: #121212;

	& > p {
		color: #f1f1f1;
	}
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTSectionBarSeparator = styled.div`
	//DEFAULT
	margin: 0 12px 0 6px;
	width: 2px;
	min-width: 2px;
	border-radius: 1.5px;
	background-color: #1212121c;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		margin: 0 24px 0 12px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		margin: 0 24px 0 12px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTSectionBarWrapper = styled.div`
	//DEFAULT
	flex-direction: row;

	&:first-of-type {
		& > div {
			margin-left: 12px;
		}
	}
	&:last-of-type {
		& > div {
			margin-right: 12px;
		}
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		&:first-of-type {
			& > div {
				margin-left: 0;
			}
		}
		&:last-of-type {
			& > div {
				margin-right: 48px;
			}
		}
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		&:first-of-type {
			& > div {
				margin-left: 0;
			}
		}
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_VTSectionBarItemText = styled.p`
	//DEFAULT

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
