import React from 'react';
import TablePaymentRow from './TablePaymentRow';

export default function TablePayment({ data, pagination }: TablePaymentProps) {
	return (
		<div className='service-table-card-container '>
			{data.map((service) => (
				<TablePaymentRow key={service.id} service={service} />
			))}
			{pagination}
		</div>
	);
}
