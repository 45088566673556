// Created by carsonaberle at 3/30/22
import React, { useEffect, useState } from 'react';
import * as S from './styles';

export default function VTMatterport({ matterportModelId, isExternal }: VTMatterportProps) {
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	useEffect(() => {
		const interval = setInterval(() => {
			setIsLoaded(true);
		}, 500);
		return () => clearInterval(interval);
	}, []);

	if (!matterportModelId) return null;
	return (
		<S.div_VTMatterportContainer isLoaded={isLoaded}>
			<S.div_VTMatterportWrapper>
				<S.iframe_VTContentMatterport marginBottom={isExternal ? '0' : '0'} src={`https://my.matterport.com/show/?m=${matterportModelId}`} allowFullScreen />
			</S.div_VTMatterportWrapper>
		</S.div_VTMatterportContainer>
	);
}
