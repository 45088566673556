import gql from 'graphql-tag';

export const ACCEPT_SCHEDULE_REQUEST = gql`
	mutation acceptScheduleRequest($id: String!, $photographer: String!, $service: String!, $acceptedTime: String!) {
		acceptScheduleRequest(id: $id, photographer: $photographer, service: $service, acceptedTime: $acceptedTime) {
			id
			createdAt
			acceptedTime
			service {
				id
			}
			agent {
				id
				name
			}
			photographer {
				id
				name
			}
			accepted
		}
	}
`;

export const DECLINE_SCHEDULE_REQUEST = gql`
	mutation declineScheduleRequest($requestId: String!) {
		declineScheduleRequest(id: $requestId) {
			id
			createdAt
			acceptedTime
			service {
				id
			}
			accepted
		}
	}
`;
