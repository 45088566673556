// Created by carsonaberle at 3/29/22
import React from 'react';
import * as S from './styles';
import { convertCents } from '../../../../../utils/Helpers/StringHelpers';
import { EXTERNAL_VIRTUAL_TOUR } from '../../../../../RoutesItems';
import { UI_ICON_BUTTON_BLACK_TYPE } from '../../../../../components/UI/UIIconButton/config';
import { getMatchingPath } from '../../../../../utils/Helpers/AppHelper';
import { useSelector } from 'react-redux';
import { getSignedInUserSelector } from '../../../../../redux/selectors/CurrentUserSelectors';
import { useHistory } from 'react-router-dom';
import * as strings from './strings';
import { p_VTArea } from './styles';

export default function VTHeader({ listing, showDownloadModal }: VTHeaderProps) {
	const { property, askingPrice, squareFootage } = listing;
	const { streetAddress, city, state, zipCode, alias } = property;

	const signedInUser = useSelector(getSignedInUserSelector);

	const history = useHistory();

	const matchingRoute = getMatchingPath(signedInUser, history);

	const formattedAskingPrice = askingPrice && convertCents(askingPrice * 100, true);
	const formattedArea = squareFootage ? `${squareFootage.toLocaleString()} sq/ft` : undefined;

	return (
		<S.div_VTHeaderContainer>
			<S.div_VTHeaderProperty>
				{alias && <S.p_VTHeaderPropertyAlias>{alias.substring(0, 120)}</S.p_VTHeaderPropertyAlias>}
				<S.p_VTHeaderPropertyStreetAddress>{streetAddress.substring(0, 120)}</S.p_VTHeaderPropertyStreetAddress>
				<S.p_VTHeaderPropertyCityStateZip>
					{city.substring(0, 40)}, {state.substring(0, 15)} {zipCode.substring(0, 12)}
				</S.p_VTHeaderPropertyCityStateZip>
			</S.div_VTHeaderProperty>
			<S.div_VTHeaderRight>
				{formattedAskingPrice && <S.p_VTAskingPrice>{formattedAskingPrice}</S.p_VTAskingPrice>}
				{formattedArea && <S.p_VTArea>{formattedArea}</S.p_VTArea>}
			</S.div_VTHeaderRight>
			{matchingRoute === EXTERNAL_VIRTUAL_TOUR && (
				<S.UIIconButton_VTHeaderDownload style={{ borderRadius: 20 }} type={UI_ICON_BUTTON_BLACK_TYPE} text={strings.download} onClick={showDownloadModal} />
			)}
		</S.div_VTHeaderContainer>
	);
}
