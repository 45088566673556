// Created by carsonaberle at 2/24/22
import React from 'react';
import * as S from './styles';
import Lottie from 'react-lottie';
import PLImage from '../../PL/PLImage';
import { defaultLottieOptions } from './config';

export default function UIIcon({ iconSrc, onClick, style, alt, className, isLoading, badgeNumber, width, height }: UIIconProps) {
	if (isLoading) {
		return (
			<div className={className} style={style}>
				<Lottie options={defaultLottieOptions} height={'100%'} width={'100%'} isStopped={false} isPaused={false} />
			</div>
		);
	}
	let image = (
		<PLImage
			src={iconSrc}
			onClick={onClick}
			alt={alt}
			style={style}
			imageStyle={{ maxWidth: '100%', maxHeight: '100%' }}
			width={width}
			height={height}
			className={className}
		/>
	);

	if (badgeNumber && badgeNumber > 0) {
		return (
			<S.UIIconContainer>
				{image}
				<S.UIIconContent>
					<S.UIIconBadge>{badgeNumber}</S.UIIconBadge>
				</S.UIIconContent>
			</S.UIIconContainer>
		);
	}
	return image;
}
