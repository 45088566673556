import gql from 'graphql-tag';

export const GET_PL_SERVICE_VIDEOS_FILES = gql`
	query GetServiceVideos($limit: Int, $query: JSON!, $isActive: Boolean, $order: [[String]]) {
		files(limit: $limit, query: $query, isActive: $isActive, order: $order) {
			id
			isActive
			createdAt
			updatedAt
			type
			filename
			index
			status
			isVisible
			watermarkUrl
			thumbnailUrl
			originalUrl
			printUrl
			allowFixRequest
			webUrl
			metadata
			width
			height
			tags
		}
	}
`;
