// Created by jarongallo at 6/11/21
import React, { useEffect, useState } from 'react';
import './styles';
import UILoadingPage from '../../../../../../components/UI/UILoadingPage/UILoadingPage';
import UIReceipt from '../../../../../../components/UI/UIReceipt';
import './styles';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SERVICE_RECEIPTS } from './queries';

export default function PLServiceReceipts({ serviceId }: PLServiceReceiptsProps) {
	const [service, setService] = useState<ServiceType>();
	const [transactions, setTransactions] = useState<TransactionsType[]>();
	const [getService, { data: serviceData }] = useLazyQuery(GET_SERVICE_RECEIPTS, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getService();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setService(serviceData['service']);
				setTransactions(serviceData['service'].transactions);
			}
		})();
	}, [serviceData]);

	if (!service || !transactions) return null;

	if (!transactions) {
		return <UILoadingPage />;
	} else
		return (
			<div className='plServiceReceipts'>
				{transactions &&
					transactions.map((transaction: TransactionsType, index: number) => {
						return <UIReceipt noService disableLink style={{ width: '100%' }} key={index} receipt={transaction} promos={service.promos} />;
					})}
			</div>
		);
}
