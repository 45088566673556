import React from 'react';

import {
	INVITED_TO_PINEAPPLE,
	STATUS_LIVE,
	USER_ROLE_ADMIN,
	USER_ROLE_AFFILIATE,
	USER_ROLE_AGENT,
	USER_ROLE_EDITING_MANAGER,
	USER_ROLE_EDITOR,
	USER_ROLE_PHOTOGRAPHER,
	USER_ROLE_REGIONAL_MANAGER,
	USER_ROLE_SALES
} from './Constants';

import {
	ADMIN_AFFILIATES,
	ADMIN_AGENTS,
	ADMIN_EDITING_COMPANIES,
	ADMIN_HOME,
	ADMIN_INVITE_CODES,
	ADMIN_LISTINGS,
	ADMIN_PAYMENT,
	ADMIN_REGIONS,
	ADMIN_SERVICE_REQUESTS,
	ADMIN_SERVICES,
	ADMIN_TECHNICIANS,
	AFFILIATE_AGENTS,
	AFFILIATE_INVITES,
	MARKETING_SITE,
	AGENT_LISTINGS,
	AGENT_PENDING_SERVICES,
	CONTACT,
	EDITING_MANAGER_EDITORS,
	EDITING_MANAGER_HOME,
	EDITING_MANAGER_PENDING,
	EDITOR_SERVICE_REQUESTS,
	EDITOR_SERVICES,
	EXTERNAL_VIRTUAL_TOUR,
	HOME_PREPARATION,
	INDIVIDUAL_AGENT,
	INDIVIDUAL_EDITING_COMPANY,
	INDIVIDUAL_EDITOR,
	INDIVIDUAL_INVITE_CODE,
	INDIVIDUAL_LISTING,
	INDIVIDUAL_REGION,
	INDIVIDUAL_SALESPERSON,
	INDIVIDUAL_SERVICE_REQUEST,
	INDIVIDUAL_TECHNICIAN,
	PRIVACY_POLICY,
	SALESPERSON_AGENTS,
	SALESPERSON_INVITES,
	SALESPERSON_PAYMENT,
	SIGNUP,
	TECHNICIAN_EDUCATION,
	TECHNICIAN_LISTINGS,
	TECHNICIAN_PINEAPPLE_SERVICES,
	TECHNICIAN_STRIPE_SIGNUP,
	TERMS_OF_USE,
	VIRTUAL_TOUR
} from '../../RoutesItems';

export const sessionRoutes = (user) => {
	return roleRoutes(user)
		.concat(staticRoutes(user))
		.filter((route) => route !== undefined);
};

export const roleRoutes = (user) => {
	if (!user) {
		return [];
	}

	switch (user.role) {
		case USER_ROLE_AGENT:
			let agentRoutes = [AGENT_LISTINGS];
			if (
				user.listings &&
				user.listings.length > 0 &&
				user.listings.filter((listing) => listing.services && listing.services.filter((service) => service.status !== STATUS_LIVE).length > 0)
			) {
				agentRoutes.push(AGENT_PENDING_SERVICES);
			}
			return agentRoutes;
		case USER_ROLE_EDITOR:
			return [EDITOR_SERVICE_REQUESTS, EDITOR_SERVICES];
		case USER_ROLE_EDITING_MANAGER:
			return [EDITING_MANAGER_HOME, EDITING_MANAGER_PENDING, EDITING_MANAGER_EDITORS];
		case USER_ROLE_REGIONAL_MANAGER:
			return [
				ADMIN_HOME,
				ADMIN_LISTINGS,
				ADMIN_SERVICES,
				ADMIN_SERVICE_REQUESTS,
				ADMIN_TECHNICIANS,
				ADMIN_AGENTS,
				ADMIN_REGIONS,
				ADMIN_INVITE_CODES,
				ADMIN_AFFILIATES,
				ADMIN_EDITING_COMPANIES
			];
		case USER_ROLE_PHOTOGRAPHER:
			let values = [TECHNICIAN_LISTINGS, TECHNICIAN_EDUCATION];
			if (user.stripeAccountId) {
				if (user.stripeAccountId === INVITED_TO_PINEAPPLE) {
					values.splice(0, 0, TECHNICIAN_STRIPE_SIGNUP);
				} else {
					values.splice(1, 0, TECHNICIAN_PINEAPPLE_SERVICES);
				}
			}
			return values;
		case USER_ROLE_SALES:
			return [SALESPERSON_AGENTS, SALESPERSON_INVITES, SALESPERSON_PAYMENT];
		case USER_ROLE_AFFILIATE:
			// return [AFFILIATE_AGENTS, AFFILIATE_INVITES, AFFILIATE_PAYMENT];
			return [AFFILIATE_AGENTS, AFFILIATE_INVITES];
		case USER_ROLE_ADMIN:
			return [
				ADMIN_HOME,
				ADMIN_LISTINGS,
				ADMIN_SERVICES,
				ADMIN_SERVICE_REQUESTS,
				ADMIN_TECHNICIANS,
				ADMIN_AGENTS,
				ADMIN_REGIONS,
				ADMIN_INVITE_CODES,
				ADMIN_AFFILIATES,
				ADMIN_EDITING_COMPANIES,
				ADMIN_PAYMENT
			];
		default:
			return [];
	}
};

export const staticRoutes = (user) => {
	let routes = [VIRTUAL_TOUR, EXTERNAL_VIRTUAL_TOUR, HOME_PREPARATION, PRIVACY_POLICY, TERMS_OF_USE, CONTACT, INDIVIDUAL_REGION, SIGNUP, MARKETING_SITE];

	if (user) {
		let userRoutes = [
			INDIVIDUAL_TECHNICIAN,
			INDIVIDUAL_SERVICE_REQUEST,
			INDIVIDUAL_EDITING_COMPANY,
			INDIVIDUAL_EDITOR,
			INDIVIDUAL_AGENT,
			INDIVIDUAL_LISTING,
			INDIVIDUAL_SALESPERSON,
			INDIVIDUAL_INVITE_CODE
		];
		routes = userRoutes.concat(routes);
	}
	return routes;
};
