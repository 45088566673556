import React  from 'react';
import './styles';
import { MenuIcon } from '../../../assets/icons';
import UIIcon from '../UIIcon';
import UIMenuItem from './UIMenuItem';
import UIModal from '../UIModal';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getIsMobileMenuVisibleSelector, getScreenTypeSelector } from "../../../redux/selectors/ControlSelectors";
import * as S from './styles';
import { toggleMenuVisibility } from "../../../redux/store/control/actions";

export function filterSelectedItem(tabs, location) {
	const parsedQuery = parseQuery(location.search);
	return tabs.filter((tab) => parsedQuery[tab.searchParam] !== undefined)[0] || tabs[0];
}
export function parseQuery(queryString) {
	let query = {};
	const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
	for (let i = 0; i < pairs.length; i++) {
		const pair = pairs[i].split('=');
		query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
	}
	return query;
}

export default function UIMenuBody({ children, menuItems, menuTitle, menuHeader, additionalHeader }: UIMenuBodyProps) {
	const screenType = useSelector(getScreenTypeSelector);
	const isMobileMenuVisible = useSelector(getIsMobileMenuVisibleSelector);

	const location = useLocation();
	const dispatch = useDispatch();

	const getSelectedItem = () => {
		let selectedItem = filterSelectedItem(menuItems, location);
		if (!selectedItem) {
			selectedItem = menuItems[0];
		}

		return selectedItem;
	};

	const onSelectItem = () => dispatch(toggleMenuVisibility(false));

	const renderMobileMenu = () => {
		const selectedItem = getSelectedItem();

		const hideModal = () => dispatch(toggleMenuVisibility(false));

		// if (menuItems.length === 1 && !menuHeader) return null;

		return (
			<S.UIMenuBodyMenuContentMobile onClick={() => dispatch(toggleMenuVisibility(true))}>
				<UIIcon className='uiMenu__menu-closed_img--default' alt={'menu'} iconSrc={MenuIcon.white} />
				<p className='uiMenu__menu-right--label'>{selectedItem.label}</p>
				<UIModal condition={isMobileMenuVisible}>
					<S.UIMenuBodyMenuOverlay hideModal={hideModal}>
						<S.UIMenuBodyMenuContent>
							{menuHeader ? menuHeader : menuTitle ? <S.UIMenuBodyMenuTitle>{menuTitle}</S.UIMenuBodyMenuTitle> : undefined}
							<S.UIMenuBodyMenuItems>
								{menuItems.map((item, index) => (
									<UIMenuItem key={index} itemKey={index} onSelectItem={onSelectItem} isSelected={selectedItem === item} item={item} />
								))}
							</S.UIMenuBodyMenuItems>
						</S.UIMenuBodyMenuContent>
					</S.UIMenuBodyMenuOverlay>
				</UIModal>
			</S.UIMenuBodyMenuContentMobile>
		);
	};

	const renderMenuItems = () => {
		const selectedItem = getSelectedItem();

		if (screenType === 'mobile') {
			return renderMobileMenu();
		}

		if (menuItems.length === 1 && !menuHeader) return null;

		return (
			<S.UIMenuBodyMenu>
				<S.UIMenuBodyMenuContent>
					{menuHeader ? menuHeader : menuTitle ? <S.UIMenuBodyMenuTitle>{menuTitle}</S.UIMenuBodyMenuTitle> : undefined}
					<S.UIMenuBodyMenuItems>
						{menuItems.map((item, index) => (
							<UIMenuItem key={index} itemKey={index} onSelectItem={onSelectItem} isSelected={selectedItem === item} item={item} />
						))}
					</S.UIMenuBodyMenuItems>
				</S.UIMenuBodyMenuContent>
			</S.UIMenuBodyMenu>
		);
	};

	return (
		<S.UIController_UIMenuBodyRoot noScroll>
			<S.UIMenuBodyContent>
				{renderMenuItems()}
				<S.UIMenuBodyComponent style={menuItems.length < 2 ? { maxWidth: '100%' } : undefined}>
					{additionalHeader}
					<S.UIMenuBodyComponentContent>{children}</S.UIMenuBodyComponentContent>
				</S.UIMenuBodyComponent>
			</S.UIMenuBodyContent>
		</S.UIController_UIMenuBodyRoot>
	);
}
