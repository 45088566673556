import React from 'react';
import './styles';
import { useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import { getPortalUrl } from '../../../../../utils/Helpers/AppHelper';
import PLImage from '../../../PLImage';
import { CopyIcon } from '../../../../../assets/icons';
import {
	USER_ROLE_ADMIN,
	USER_ROLE_AGENT,
	USER_ROLE_EDITING_MANAGER,
	USER_ROLE_EDITOR,
	USER_ROLE_PHOTOGRAPHER,
	USER_ROLE_REGIONAL_MANAGER
} from '../../../../../utils/Config/Constants';
import { Link } from 'react-router-dom';
import { getSignedInUserSelector } from '../../../../../redux/selectors/CurrentUserSelectors';
import { toast } from 'react-toastify';
import { DISCOUNT_TYPES, PROMO_TYPES } from '../../../../../utils/Helpers/DiscountCalculator/DiscountCalculator';
import { convertCents } from '../../../../../utils/Helpers/StringHelpers';

export default function TableInviteCodes({ data, pagination, onSelect }: TableInviteCodesProps) {
	const signedInUser = useSelector(getSignedInUserSelector);

	const getPromoAmountString = (promo) => {
		switch (promo.discountType) {
			case DISCOUNT_TYPES.REDUCTION_AMOUNT:
				return `${convertCents(promo.discountAmount)}`;
			case DISCOUNT_TYPES.REDUCTION_PERCENTAGE:
				return `${promo.discountPercentage}%`;
			default:
				return 'Error';
		}
	};
	const renderPromo = (promo, inviteCodeId) => {
		switch (promo.promoType) {
			case PROMO_TYPES.CREDIT:
				return <p key={`${inviteCodeId}-${promo.discountType}-${promo.name}`}>{getPromoAmountString(promo)} Credit</p>;
			case PROMO_TYPES.SPECIFIC_OPTION:
				return <p key={`${inviteCodeId}-${promo.discountType}-${promo.name}`}> {getPromoAmountString(promo)} Specific option</p>;
			case PROMO_TYPES.UNIVERSAL_DISCOUNT:
				return <p key={`${inviteCodeId}-${promo.discountType}-${promo.name}`}>{getPromoAmountString(promo)} Universal discount</p>;
			default:
				return null;
		}
	};

	const renderPromos = (inviteCode) => {
		return (
			<div className='tableInvite__row--item'>
				<div className='tableInvite__row--item-price'>{inviteCode.formData?.promos.map((promo) => renderPromo(promo, inviteCode.id))}</div>
			</div>
		);
	};

	const renderCreator = (inviteCode) => {
		const renderCreator = (user, highlight) => {
			if (!user) {
				return (
					<div className='tableInvite__technician'>
						<p className='tableInvite__user--name'>error...</p>
					</div>
				);
			}
			return (
				<div>
					{[USER_ROLE_ADMIN, USER_ROLE_REGIONAL_MANAGER, USER_ROLE_AGENT].indexOf(signedInUser.role) !== -1 && (
						<Link
							style={highlight ? { border: 'var(--mint-primary) solid 1px' } : {}}
							onClick={(e) => e.stopPropagation()}
							className='tableInvite__technician'
							to={`/salesperson/${user.id}`}>
							<img alt={user.name} className='tableInvite__user--image' src={user.image} />
							<div>
								<p className='tableInvite__user--name'>{user.name}</p>
								{highlight && <p className='tableInvite__user--name-mint'>requested</p>}
							</div>
						</Link>
					)}
					{[USER_ROLE_PHOTOGRAPHER, USER_ROLE_EDITOR, USER_ROLE_EDITING_MANAGER].indexOf(signedInUser.role) !== -1 && (
						<div className='tableInvite__type--user'>
							<p className='tableInvite__user--name'>{user.name}</p>
							{highlight && <p className='tableInvite__user--name-mint'>requested</p>}
						</div>
					)}
				</div>
			);
		};

		if (inviteCode.salesman !== null) {
			return renderCreator(inviteCode.salesman, false);
		} else {
			return (
				<div className='tableInvite__technician'>
					<p className='tableInvite__user--name'>No salesperson</p>
				</div>
			);
		}
	};

	return (
		<div className='tableInvite'>
			<div className='tableInvite--header'>
				<div className='tableInvite__label'>Code</div>
				<div className='tableInvite__label'>Affiliate</div>
				<div className='tableInvite__label'>Promos</div>
			</div>
			<div className='fade-in-div'>
				{data.map((inviteCode) => {
					return (
						<div onClick={() => onSelect(inviteCode)} className={inviteCode.isActive ? 'tableInvite__row' : 'tableInvite__row-important'} key={inviteCode.id}>
							<div className='tableInvite__row--item'>
								<CopyToClipboard
									onCopy={() => toast.success('Copied invite link to your clipboard!')}
									text={getPortalUrl() + '/signup?invitecode=' + encodeURIComponent(inviteCode.inviteCode)}>
									<PLImage src={CopyIcon} className='tableInvite__row--item-copy' onClick={(e) => e.stopPropagation()} alt={'copy'} />
								</CopyToClipboard>
								<p className='tableInvite__row--item-name' style={!inviteCode.isActive ? { color: 'var(--red)' } : {}}>
									{inviteCode.inviteCode}
								</p>
							</div>
							<div className='tableInvite__row--item'>{renderCreator(inviteCode)}</div>
							{renderPromos(inviteCode)}
						</div>
					);
				})}
			</div>
			{pagination}
		</div>
	);
}
