import React from 'react';
import './styles/mobile.scss';
import UIUser from '../UITeamMember/UITeamMember';

export default function UITeamMembers({ data, deleteOnClick, className, deleteAdditionalContact, addAdditionalContact }: UITeamMembersProps) {
	if (!data) {
		return null;
	}
	if (data.length === 0) {
		return null;
	}
	return (
		<div className={className} onClick={(e) => e.stopPropagation()}>
			{data.map((contact) => {
				return (
					<div className='deleteAdditionalContainer' key={contact.name}>
						<UIUser
							key={contact.name}
							user={contact}
							showPhoneNumber
							dontShowImg
							showEmail
							background
							deleteOnClick={deleteOnClick}
							onClick={() => {
								deleteOnClick ? deleteAdditionalContact!(contact) : addAdditionalContact!(contact);
							}}
						/>
					</div>
				);
			})}
		</div>
	);
}
