import gql from 'graphql-tag';
import Queries from '../../../../../../utils/API/Queries';

export const GET_SERVICE_REGION = gql`
	query Service($id: String!) {
		service(id: $id) {
			id
			client { 
				id
			}
			orderInformation
			region {
				id
				timezone
				name
				${Queries.regionConfig.full}
			}
		}
	}
`;

export const UPDATE_SERVICE_ORDER_INFO = gql`
	mutation UpdateServiceOrderInformation($serviceId: String!, $orderInformation: JSON!) {
		updateService(id: $serviceId, orderInformation: $orderInformation) {
			id
			orderInformation
		}
	}
`;
