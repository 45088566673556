import gql from 'graphql-tag';

export const GET_OPTION_GENERAL_INFO = gql`
	query GetOption($id: String!) {
		option(id: $id) {
			id
			coverFile {
				id
				thumbnailUrl
			}
			name
			price
			finalFileCount
			description
			exampleUrl
			deliveryTimeEstimate
			type
			quantity
			packageType
		}
	}
`;
