import DiscountCalculator from '../../../../utils/Helpers/DiscountCalculator/DiscountCalculator';

export const setUpBookableOptions = (options: OptionType[], promos: PromoType[]): BookableOptionType[] => {
	if (options) {
		return options.map((option) => setUpBookableOption(option, promos));
	}
	return [];
};

export const setUpBookableOption = (option: OptionType, promos?: PromoType[]) => {
	let bookableOption: BookableOptionType = {
		option,
		selectedQuantity: option.quantity || 1
	};
	if (!promos) return { ...bookableOption, discountCalculator: undefined };
	return { ...bookableOption, discountCalculator: new DiscountCalculator(bookableOption, promos) };
};
