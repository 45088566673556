import React from 'react';
import './pl-error-boundary.scss';
import UIIconButton from '../UI/UIIconButton';
import { UI_ICON_BUTTON_OUTLINE_TYPE, UI_ICON_BUTTON_WHITE_TYPE } from '../UI/UIIconButton/config';
import PLImage from '../PL/PLImage';
import { ChatIcon } from '../../assets/icons';
import { SCREEN_TYPE } from '../../utils/Config/Constants';
import { getInitialRoute } from '../../utils/Helpers/UserHelpers';
import { useSelector } from 'react-redux';
import { getScreenTypeSelector } from '../../redux/selectors/ControlSelectors';
import { useHistory } from 'react-router-dom';
import { getSignedInUserSelector } from '../../redux/selectors/CurrentUserSelectors';

const PLErrorBoundary = ({ error, resetErrorBoundary }) => {
	const signedInUser = useSelector(getSignedInUserSelector);
	const screenType = useSelector(getScreenTypeSelector);

	const history = useHistory();
	console.log(error);
	return (
		<div className='errorBody'>
			<div className='errorBody--card'>
				<p className='errorBody--card-title'>We had an error :(</p>
				<p className='errorBody--card-description'>We have tracked the issue and will begin fixing it.</p>
				<div className='errorBody__chat'>
					<p className='errorBody--card-help'>Need more help?</p>
					<a
						className='errorBody__chat-button'
						target='__blank'
						href='https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=pineapplelabs.zendesk.com'>
						<PLImage className='headerImage--img' src={ChatIcon.grey} alt={'Chat'} />
						{screenType !== SCREEN_TYPE.mobile && <p className='errorBody__chat-button-text'>chat with us</p>}
					</a>
				</div>
				<div style={{ flexDirection: 'row' }}>
					<UIIconButton
						type={UI_ICON_BUTTON_WHITE_TYPE}
						style={{ margin: '32px 0 0 0' }}
						text={'take me home'}
						onClick={() => {
							history.push(getInitialRoute(signedInUser));
							resetErrorBoundary();
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default PLErrorBoundary;
