// Created by carsonaberle at 11/24/21
import React from 'react';
import './styles';
import { EDIT_TYPE_SERVICE } from '../../../../../../../utils/Config/Constants';
import * as strings from './strings';

export default function ServiceProofingCart({
	style,
	service,
	proofOnlyFiles,
	selectedImages,
	getIsImageSelected,
	getAmountRequiringPurchase
}: ServiceProofingCartProps) {
	const renderCartItem = (label: string, value: string | number) => {
		return (
			<div className='serviceProofingCart--item'>
				<p className='serviceProofingCart--item--label'>{label}</p>
				<p className='serviceProofingCart--item--value'>{value}</p>
			</div>
		);
	};

	const renderDiscardedCartItem = () => {
		if (service.type === EDIT_TYPE_SERVICE) {
			return null;
		}

		let discardedFileCount = proofOnlyFiles.filter((proofingImage) => !getIsImageSelected(proofingImage)).length;

		return renderCartItem(strings.numberRemoved, discardedFileCount);
	};

	return (
		<div className='serviceProofingCart' style={style}>
			{renderCartItem(strings.numberInCart, selectedImages.length)}
			{renderDiscardedCartItem()}
			{renderCartItem(strings.numberOfExtras, getAmountRequiringPurchase())}
		</div>
	);
}
