// Created by carsonaberle at 2/24/21
import React from 'react';
import Div100vh from 'react-div-100vh';
import { ZendeskAPI } from 'react-zendesk';

export default function UIModal({ children, condition, className }: UIModalProps) {
	if (!condition) {
		return null;
	}

	return <Div100vh className={`plModal plModal--blur ${className}`}>{children}</Div100vh>;
}
