import styled from 'styled-components';
import Select from 'react-select';

export const p_SingleOptionTitle = styled.p`
	//DEFAULT
	font-size: 16pt;
	font-weight: 500;
	margin-top: 12px;
`;
export const select_UITabBarSelect = styled(Select)`
	//DEFAULT
`;
