// Created by carsonaberle at 3/29/22
import React from 'react';
import './styles';
import Helmet from 'react-helmet';

export default function VTMetaHelmet({ listing }: VTMetaHelmetProps) {
	const { property, coverFile } = listing;

	if (!property) return null;

	const { address } = property;

	return (
		<Helmet>
			<title>{address}</title>
			<meta name='og:title' content={address} />
			<meta name='description' content={address} />
			<meta name='og:description' content={address} />
			{coverFile && <meta name='og:image' content={coverFile.thumbnailUrl} />}
			{coverFile && <meta name='og:video' content={coverFile.thumbnailUrl} />}
			{coverFile && <meta property='og:type' content='video' />}
			{coverFile && <meta property='og:video:type' content='video/mp4' />}
			{coverFile && <meta property='og:video:width' content='385' />}
			{coverFile && <meta property='og:video:height' content='640' />}
			<meta name='twitter:card' content={address} />
			<meta name='twitter:title' content={address} />
			<meta name='twitter:description' content={address} />
			<meta name='twitter:image' content={address} />
		</Helmet>
	);
}
