import React from 'react';
import './styles/mobile.scss';
import { LottieAnimations } from '../../../assets/icons';
import Lottie from 'react-lottie';
import {
	UI_ICON_BUTTON_BLACK_TYPE,
	UI_ICON_BUTTON_BLANK_TYPE,
	UI_ICON_BUTTON_CANCEL_TYPE,
	UI_ICON_BUTTON_FILL_TYPE,
	UI_ICON_BUTTON_OUTLINE_TYPE,
	UI_ICON_BUTTON_PROPERTY_WEBSITE_AGENT_TYPE,
	UI_ICON_BUTTON_PROPERTY_WEBSITE_TYPE,
	UI_ICON_BUTTON_WHITE_TYPE
} from './config';
import { toast } from 'react-toastify';

export default function UIIconButton({
	isDisabled,
	onClick,
	disabledErrorMessage,
	type,
	dark,
	className,
	isLoading,
	style,
	text,
	icon,
	key,
	reverse,
	iconPadding,
	textClassname,
	setRef,
	id,
	iconAlt,
	iconStyle
}: UIIconButtonProps) {
	const getButtonClassnameFromType = () => {
		if (isDisabled) {
			return 'uiIconButton-disabled';
		}
		switch (type) {
			case UI_ICON_BUTTON_OUTLINE_TYPE:
				return 'uiIconButton--outline';
			case UI_ICON_BUTTON_BLANK_TYPE:
				return 'uiIconButton--blank';
			case UI_ICON_BUTTON_BLACK_TYPE:
				return 'uiIconButton--black';
			case UI_ICON_BUTTON_WHITE_TYPE:
				return 'uiIconButton--white';
			case UI_ICON_BUTTON_PROPERTY_WEBSITE_TYPE:
				return 'uiIconButton--outline-property';
			case UI_ICON_BUTTON_PROPERTY_WEBSITE_AGENT_TYPE:
				return 'uiIconButton--outline-agent';
			case UI_ICON_BUTTON_CANCEL_TYPE:
				return 'uiIconButton--cancel';
			case UI_ICON_BUTTON_FILL_TYPE:
			default:
				return 'uiIconButton--fill';
		}
	};

	const onClickButton = (e) => {
		if (isDisabled) {
			toast.info(
				disabledErrorMessage
					? disabledErrorMessage
					: process.env.PL_ENVIRONMENT !== 'LIVE' &&
							'Dev Mode Note: This UIIconButton has no disabledErrorMessage prop and may cause confusion for the users. Please add this prop.'
			);
		} else if (isLoading) {
			if (process.env.PL_ENVIRONMENT !== 'LIVE') {
				toast.info('Dev Mode Note: This action is currently in progress');
			}
		} else {
			if (onClick) {
				onClick(e);
			}
		}
	};

	return (
		<div
			id={id}
			key={`${key}-${isDisabled}`}
			ref={(ref) => (setRef ? setRef(ref) : undefined)}
			className={`${getButtonClassnameFromType()} fade-in-div ${className} `}
			style={reverse ? { ...style, flexDirection: 'row-reverse' } : style}
			onClick={onClickButton}>
			{isLoading ? (
				<div
					className='uiIconButton--icon'
					style={text && icon ? { width: 28, height: 28, marginRight: 12, padding: iconPadding } : { width: 28, height: 28, padding: iconPadding }}>
					<Lottie
						options={{
							loop: true,
							autoplay: true,
							//@ts-ignore
							path: dark ? LottieAnimations.loadingCircleGrey : LottieAnimations.loadingCircle,
							rendererSettings: {
								preserveAspectRatio: 'xMidYMid slice'
							}
						}}
						height={'100%'}
						width={'100%'}
						isStopped={false}
						isPaused={false}
					/>
				</div>
			) : (
				icon && <img alt={iconAlt || 'button icon'} className='uiIconButton--icon' src={icon} style={{ ...iconStyle, padding: iconPadding }} />
			)}
			{text && icon ? (
				<p
					style={
						isDisabled
							? { color: 'var(--light-grey)', marginLeft: 12, marginRight: 8 }
							: { color: dark ? 'var(--dark-grey)' : 'white', marginLeft: 12, marginRight: 8 }
					}
					className={textClassname}>
					{text}
				</p>
			) : (
				!isLoading && (
					<p style={isDisabled ? { color: 'var(--light-grey)' } : { color: dark ? 'var(--dark-grey)' : 'white' }} className={textClassname}>
						{text}
					</p>
				)
			)}
		</div>
	);
}
