import React, { Component } from 'react';
import './approve-service-modal.scss';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/react-hoc';
import UIOverlayCard from '../../UI/UIOverlayCard';
import { FILE_STATUS_LIVE, OPTION_TYPES } from '../../../utils/Config/Constants';
import { REQUIRED_CHECK_TYPES } from './ApproveServiceModalConfig';
import ApproveServiceCheckboxItem from './ApproveServiceCheckboxItem/ApproveServiceCheckboxItem';
import PropTypes from 'prop-types';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../utils/Helpers/AppHelper';

class ApproveServiceModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			completedSteps: {}
		};
	}

	//Mark: Component functions

	getRequiredChecks = () => {
		const { service, serviceFiles } = this.props;
		let files = serviceFiles ?? service.files;

		let requiredChecks = [];

		if (service.agentNotes || service.regionalManagerNotes || service.photographerNotes || service.editorNotes) {
			requiredChecks.push(REQUIRED_CHECK_TYPES.notes);
		}

		let purchasedFileCount = service.bookedOptions
			.map((bookedOption) => bookedOption.option && bookedOption.option['finalFileCount'] * bookedOption.quantity)
			.reduce((total, acc) => acc + total, 0);
		let completedFileCount = files.filter((image) => image.status === FILE_STATUS_LIVE).length;
		if (completedFileCount < purchasedFileCount) {
			requiredChecks.push(REQUIRED_CHECK_TYPES.fileCount);
		}

		requiredChecks.push(REQUIRED_CHECK_TYPES.exteriorShots);
		requiredChecks.push(REQUIRED_CHECK_TYPES.qualityCheck);
		requiredChecks.push(REQUIRED_CHECK_TYPES.sort);

		if (service.bookedOptions.filter((bookedOption) => bookedOption.option && bookedOption.option.type === OPTION_TYPES.virtualTwilight).length > 0) {
			requiredChecks.push(REQUIRED_CHECK_TYPES.virtualTwilight);
		}

		requiredChecks.push(REQUIRED_CHECK_TYPES.reEditsRequired);

		if (service.bookedOptions.filter((options) => options.packageType === 'matterport').length > 0) {
			requiredChecks.push(REQUIRED_CHECK_TYPES.matterport);
		}
		requiredChecks.push(REQUIRED_CHECK_TYPES.approve);

		return requiredChecks;
	};

	//Mark: Render functions
	render() {
		const { hideModal } = this.props;

		const requiredChecks = this.getRequiredChecks();

		return (
			<UIOverlayCard hideModal={hideModal} title={'Approval checklist'} description={'Please review all of the following before approving'}>
				<div className='approveChecklist'>{requiredChecks.map(this.renderRequiredCheck)}</div>
			</UIOverlayCard>
		);
	}

	renderRequiredCheck = (requiredCheckType) => {
		const { updateService, service, approveService } = this.props;
		const { completedSteps } = this.state;

		const onCheckChanged = () => {
			let newValue = !completedSteps[requiredCheckType.type];
			if (newValue === undefined) {
				newValue = true;
			}
			this.setState({ completedSteps: { ...completedSteps, [requiredCheckType.type]: newValue } });
		};

		const isChecked = completedSteps[requiredCheckType.type] === true;

		return (
			<ApproveServiceCheckboxItem
				key={requiredCheckType.type}
				type={requiredCheckType.type}
				onCheckChanged={onCheckChanged}
				isChecked={isChecked}
				title={requiredCheckType.title}
				subtext={requiredCheckType.subtext}
				renderAdditionalComponent={requiredCheckType.renderAdditionalComponent}
				updateService={updateService}
				service={service}
				approveService={approveService}
				approveDisabled={Object.entries(completedSteps).filter((step) => step[1] === true).length !== this.getRequiredChecks().length}
			/>
		);
	};
}

ApproveServiceModal.propTypes = {
	hideModal: PropTypes.func,
	approveService: PropTypes.func.isRequired,
	updateService: PropTypes.func.isRequired,
	service: PropTypes.object.isRequired
};

export default withApollo(connect(commonMapStateToProps, commonMapDispatchToProps)(ApproveServiceModal));
