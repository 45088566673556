import React, { useState } from 'react';
import './styles';
import UIIconButton from '../../../../../../components/UI/UIIconButton';
import UIModal from '../../../../../../components/UI/UIModal';
import UIOverlayCard, { OVERLAY_CLOSE_TYPES } from '../../../../../../components/UI/UIOverlayCard';
import UICertifiedTechSearch from '../../../../../../components/UI/UICertifiedTechSearch';
import { BookingServiceProviderProps } from './types';
import { useSelector } from 'react-redux';
import { getBookingConfigSelector } from '../../../../../../redux/selectors/BookingSelectors';
import { UI_ICON_BUTTON_CANCEL_TYPE } from '../../../../../../components/UI/UIIconButton/config';

export default function BookingServiceProvider({ updateBookingConfig }: BookingServiceProviderProps) {
	const bookingConfig = useSelector(getBookingConfigSelector);

	const [searchParams, setSearchParams] = useState<string>('');
	const [showRemovePreferredProviderModal, setShowRemovePreferredProviderModal] = useState<boolean>(false);

	const onSelectPreferredProvider = (serviceProvider: UserType) => {
		if (bookingConfig.serviceProvider) {
			setShowRemovePreferredProviderModal(true);
		} else {
			updateBookingConfig('serviceProvider', serviceProvider);
		}
	};

	const renderPreferredTechnicians = () => {
		return (
			<UICertifiedTechSearch
				emptySetText={'No technicians found with this name...'}
				onUpdateSearchParams={setSearchParams}
				certifications={bookingConfig.selectedOptions.map((bookableOption) => bookableOption.option.photographerCertificatesRequired).flat(2)}
				selectedUser={bookingConfig.serviceProvider}
				onSelectUser={onSelectPreferredProvider}
				region={bookingConfig.region!}
			/>
		);
	};

	const renderSkipPreferred = () => {
		if (bookingConfig.serviceProvider) return;
		return (
			<div style={{ minHeight: 'fit-content', marginTop: 24 }}>
				<p className='bookingManager--title'>Don’t have a preference?</p>
				<p className='bookingManager--description'>
					We will match you with a professional who will work with your schedule. Our technicians are hand picked, screened, trained, and certified to ensure
					you receive top quality content every time. If you would like us to match you with a technician you should close this screen and continue with your
					booking.
				</p>
			</div>
		);
	};

	const renderRemovePreferredModal = () => {
		const hideModal = () => setShowRemovePreferredProviderModal(false);
		const onRemove = () => {
			updateBookingConfig('serviceProvider', undefined);
			hideModal();
		};
		if (!showRemovePreferredProviderModal) return;
		return (
			<UIModal condition={showRemovePreferredProviderModal}>
				<UIOverlayCard
					hideModal={hideModal}
					closeType={OVERLAY_CLOSE_TYPES.back}
					title={'Remove preferred technician'}
					cardStyle={{ minHeight: 'unset' }}
					description={`Are you sure you want to remove ${bookingConfig.serviceProvider?.name} as your preferred technician. This will allow all certified technicians to accept your job.`}>
					<div className='uiOverlayCard__buttons' style={{ marginTop: 12 }}>
						<UIIconButton dark type={UI_ICON_BUTTON_CANCEL_TYPE} onClick={onRemove} text={'yes, remove'} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	return (
		<div className='bookingServiceProvider'>
			{renderPreferredTechnicians()}
			{renderSkipPreferred()}
			{renderRemovePreferredModal()}
		</div>
	);
}
