import React, { Component } from 'react';
import './ui-card.scss';
import './ui-card-tablet.scss';
import './ui-card-desktop.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UIIcon from '../UIIcon';
import { DownArrowIcon } from '../../../assets/icons';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../utils/Helpers/AppHelper';

class UICard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			collapsed: !!props.collapsable
		};
	}

	//Mark: Component functions
	getStyleFromProps = () => {
		const { style, padding, margin, scrollVertical } = this.props;

		let returnStyle = style;

		if (padding) {
			returnStyle = { ...returnStyle, padding: padding };
		}
		if (margin) {
			returnStyle = { ...returnStyle, margin: margin };
		}
		if (scrollVertical) {
			returnStyle = { ...returnStyle, overflowY: 'auto' };
		}
		return returnStyle;
	};

	//Mark: Render functions
	render() {
		const { collapsed } = this.state;
		const { className, title, titleAlt, description, collapsable, rightItem } = this.props;

		return (
			<div className={`uiCard ${className}`} style={this.getStyleFromProps()} onClick={(e) => e.stopPropagation()}>
				{title && (
					<div
						className='uiCard__top'
						style={collapsable ? { cursor: 'pointer' } : {}}
						onClick={() => {
							if (collapsable) {
								this.setState({ collapsed: !collapsed });
							}
						}}>
						{collapsable && <UIIcon className='uiCard__top--icon' iconSrc={collapsed ? DownArrowIcon.white : DownArrowIcon.white} alt={'toggle expansion'} />}
						{title ? <p className='uiCard__title'>{title}</p> : undefined}
						{rightItem}
					</div>
				)}
				{titleAlt ? <p className='uiCard__titleAlt'>{titleAlt}</p> : undefined}
				{description ? <p className='uiCard__description'>{description}</p> : undefined}
				{(!collapsable || collapsed === false) && this.props.children}
			</div>
		);
	}
}

UICard.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
	rightItem: PropTypes.object,
	padding: PropTypes.number,
	margin: PropTypes.number,
	title: PropTypes.string,
	description: PropTypes.string,
	scrollVertical: PropTypes.bool,
	collapsable: PropTypes.bool
};
export default connect(commonMapStateToProps, commonMapDispatchToProps)(UICard);
