import gql from 'graphql-tag';

export const GET_OPTION_ADMIN = gql`
	query GetOptionAdmin($id: String!) {
		option(id: $id) {
			id
			name
			requiredInfo
			requiredInfoPrompt
			additionalOptions {
				id
				isActive
			}
		}
	}
`;
