import React, { useState } from 'react';
import './styles';
import { convertCents, convertToUrl } from '../../../utils/Helpers/StringHelpers';
import {
	EDIT_TYPE_SERVICE,
	OPTION_ADDITIONAL_INFO_TYPES,
	USER_ROLE_ADMIN,
	USER_ROLE_AFFILIATE,
	USER_ROLE_EDITING_MANAGER,
	USER_ROLE_EDITOR,
	USER_ROLE_PHOTOGRAPHER,
	USER_ROLE_REGIONAL_MANAGER,
	USER_ROLE_SALES
} from '../../../utils/Config/Constants';
import { getOptionPriceWithPromos } from '../../../utils/Helpers/PaymentHelper/PaymentHelper';
import { isUserAdminOrRM } from '../../../utils/Helpers/UserHelpers';
import UIIcon from '../UIIcon';
import Queries from '../../../utils/API/Queries';
import { EditIcon, Trashcan } from '../../../assets/icons';
import UIModal from '../UIModal';
import UIIconButton from '../UIIconButton';
import UIOverlayCard from '../UIOverlayCard';
import UIListInputRow from '../UIListInputRow';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../redux/selectors/CurrentUserSelectors';
import { useMutation } from '@apollo/react-hooks';
import { UIBookedOptionProps } from './types';
import { UI_ICON_BUTTON_CANCEL_TYPE, UI_ICON_BUTTON_OUTLINE_TYPE } from '../UIIconButton/config';

export default function UIBookedOption({ bookedOption, onUpdate, allBookedOptions, disableSelection, onClick, disableCost, service }: UIBookedOptionProps) {
	const signedInUser = useSelector(getSignedInUserSelector);
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const [updateService] = useMutation(Queries.UPDATE_SERVICE);
	const [updateBookedOption] = useMutation(Queries.UPDATE_BOOKED_OPTION);

	const [isDeleting, setIsDeleting] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isEditingInfo, setIsEditingInfo] = useState(false);
	const [editedAdditionalInfo, setEditedAdditionalInfo] = useState<string>();
	const [showEditAdditionalInfoModal, setShowEditAdditionalInfoModal] = useState(false);

	const deleteOption = async () => {
		if (!isDeleting) {
			setIsDeleting(true);

			await updateService({
				variables: {
					serviceId: service.id,
					bookedOptions: allBookedOptions
						.filter((filteredBookedOption) => filteredBookedOption.id !== bookedOption.id)
						.map((mappedBookedOption) => mappedBookedOption.id)
				}
			});
			await onUpdate();
			toast.success(`Successfully removed ${bookedOption.name} from this service`);
		}
	};

	const editAdditionalInfo = async () => {
		if (!isEditingInfo) {
			setIsEditingInfo(true);
			await updateBookedOption({
				variables: {
					id: bookedOption.id,
					additionalInfo: { version: bookedOption.additionalInfo.version, value: editedAdditionalInfo }
				}
			});
			await onUpdate();
			setIsEditingInfo(false);
			setShowEditAdditionalInfoModal(false);
			toast.success(`Successfully edited ${bookedOption.name}'s additional info`);
		}
	};

	const getOptionNameClassName = () => {
		return 'uiBookedOption__name';
	};

	const renderOptionByRole = (user: UserType) => {
		let components: JSX.Element[] = [];

		let optionPrices = getOptionPriceWithPromos(bookedOption);

		let quantity = bookedOption.quantity;

		let originalPrice = convertCents(optionPrices[0] * quantity);
		let discountedPrice = convertCents(optionPrices[1] * quantity);

		let nameComponent = (
			<p key={`quantity-${bookedOption.id}`} className={getOptionNameClassName()}>
				{quantity > 1 ? `${quantity}` : ''}
				<span className='uiBookedOption__name--symbol'>{quantity > 1 ? ' x ' : ''}</span>
				{bookedOption.name}
			</p>
		);

		let costForPurchaseComponent =
			disableCost || !service.orderInformation ? (
				<div key={`disabled-cost-${bookedOption.id}`} />
			) : (
				<div key={`cost-${bookedOption.id}`} className='uiBookedOption__item'>
					<p className='uiBookedOption__label'>cost</p>
					<p className='uiBookedOption__price'>
						<span className='uiBookedOption__price--original'>{originalPrice > discountedPrice ? `${originalPrice} ` : ''}</span>
						{discountedPrice}
					</p>
				</div>
			);

		let photographerPayComponent = (
			<div key={`photographer-pay-${bookedOption.id}`} className='uiBookedOption__item'>
				<p className='uiBookedOption__label'>technician pay</p>
				<p>{convertCents(bookedOption.photographerPay * bookedOption.quantity)}</p>
			</div>
		);
		let descriptionComponent = (
			<p key={`description-${bookedOption.id}`} className='uiBookedOption__description'>
				{bookedOption.description}
			</p>
		);
		let notesComponent = bookedOption.additionalInfo?.value ? (
			<div key={`notes-${bookedOption.id}`} className='uiBookedOption__item'>
				<p className='uiBookedOption__label'>notes</p>
				<p className='uiBookedOption__description'>{bookedOption.additionalInfo.value}</p>
			</div>
		) : (
			<div key={`disabled-notes-${bookedOption.id}`} />
		);

		let deliveryEstimateComponent, exampleUrlComponent, photographerInstructionsComponent, editorInstructionsComponent;

		if (bookedOption.option) {
			const { exampleUrl, deliveryTimeEstimate, photographerInstructions, editorInstructions } = bookedOption.option;
			if (deliveryTimeEstimate) {
				deliveryEstimateComponent = (
					<div key={`deliveryEstimate-${bookedOption.id}`} className='uiBookedOption__item'>
						<p className='uiBookedOption__label'>delivery time estimate</p>
						<p>{deliveryTimeEstimate}</p>
					</div>
				);
			}
			if (exampleUrl) {
				exampleUrlComponent = (
					<div key={`example-${bookedOption.id}${bookedOption.name}`} className='uiBookedOption__example'>
						<a href={convertToUrl(exampleUrl)} target={'_blank'} className='uiBookedOption__example--link'>
							view example
						</a>
					</div>
				);
			}

			if (photographerInstructions) {
				photographerInstructionsComponent = (
					<div key={`photographerInstructions-${bookedOption.id}`} className='uiBookedOption__example'>
						<a href={convertToUrl(photographerInstructions)} target={'_blank'} className='uiBookedOption__example--link'>
							view instructions
						</a>
					</div>
				);
			}

			if (editorInstructions) {
				editorInstructionsComponent = (
					<div key={`editorInstructions-${bookedOption.id}`} className='uiBookedOption__example'>
						<a href={convertToUrl(editorInstructions)} target={'_blank'} className='uiBookedOption__example--link'>
							view instructions
						</a>
					</div>
				);
			}
		}

		switch (user.role) {
			case USER_ROLE_ADMIN:
			case USER_ROLE_REGIONAL_MANAGER:
				components.push(nameComponent);
				components.push(costForPurchaseComponent);
				components.push(photographerPayComponent);
				components.push(descriptionComponent);
				components.push(deliveryEstimateComponent);
				components.push(exampleUrlComponent);
				components.push(notesComponent);
				break;
			case USER_ROLE_PHOTOGRAPHER:
				if (service.type === EDIT_TYPE_SERVICE) {
					components.push(nameComponent);
					components.push(costForPurchaseComponent);
					components.push(photographerInstructionsComponent);
					components.push(deliveryEstimateComponent);
					components.push(exampleUrlComponent);
					components.push(notesComponent);
				} else {
					components.push(nameComponent);
					if (!service.photographer?.isInternalTech) {
						components.push(photographerPayComponent);
					}
					components.push(photographerInstructionsComponent);
					components.push(exampleUrlComponent);
					components.push(notesComponent);
				}
				break;
			case USER_ROLE_EDITOR:
			case USER_ROLE_EDITING_MANAGER:
				components.push(nameComponent);
				components.push(editorInstructionsComponent);
				components.push(exampleUrlComponent);
				break;
			case USER_ROLE_SALES:
			case USER_ROLE_AFFILIATE:
			default:
				components.push(nameComponent);
				components.push(costForPurchaseComponent);
				components.push(deliveryEstimateComponent);
				components.push(exampleUrlComponent);
				components.push(notesComponent);
		}

		if (bookedOption.additionalInfo?.value && bookedOption.additionalInfoType === OPTION_ADDITIONAL_INFO_TYPES.text) {
			components.push(
				<div key={`photographer-additional-info-${bookedOption.id}`} className='uiBookedOption__item'>
					<p className='uiBookedOption__label'>additional info</p>
					<div className='uiBookedOption__item--edit'>
						{bookedOption.additionalInfo.value}
						{isUserAdmin && (
							<UIIcon
								className='uiBookedOption__item--edit-icon'
								alt={'edit icon'}
								iconSrc={EditIcon.white}
								isLoading={isEditingInfo}
								onClick={() => setShowEditAdditionalInfoModal(true)}
							/>
						)}
					</div>
				</div>
			);
		}
		return (
			<div key={bookedOption.id} className='uiBookedOption__card' onClick={onClick} style={!disableSelection ? { cursor: 'pointer' } : undefined}>
				{renderDeleteModal()}
				{renderEditAdditionalInfoModal()}
				{isUserAdminOrRM(signedInUser) && (
					<div
						onClick={(e) => {
							e.stopPropagation();
							setShowDeleteModal(true);
						}}>
						<UIIcon iconSrc={Trashcan.white} className='uiBookedOption--delete' alt={'delete'} />
					</div>
				)}
				{components.map((component) => component)}
			</div>
		);
	};

	const renderDeleteModal = () => {
		const hideModal = () => {
			setShowDeleteModal(false);
		};

		return (
			<UIModal condition={showDeleteModal}>
				<UIOverlayCard
					hideModal={hideModal}
					title={'Are you sure you want to remove this option?'}
					description={`This will permanently remove ${bookedOption.name} from this service.`}>
					<UIIconButton type={UI_ICON_BUTTON_CANCEL_TYPE} isLoading={isDeleting} icon={Trashcan.white} text={'remove'} onClick={deleteOption} />
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderEditAdditionalInfoModal = () => {
		const hideModal = () => setShowEditAdditionalInfoModal(false);

		return (
			<UIModal condition={showEditAdditionalInfoModal}>
				<UIOverlayCard hideModal={hideModal} title={'Edit additional info on option?'}>
					<UIListInputRow
						// @ts-ignore
						type={UIListInputRow.TEXT_FIELD}
						onChangeValue={(editedAdditionalInfo: string) => setEditedAdditionalInfo(editedAdditionalInfo)}
						value={editedAdditionalInfo}
						placeholder={'Notes...'}
					/>
					<UIIconButton type={UI_ICON_BUTTON_OUTLINE_TYPE} isLoading={isEditingInfo} text={'save'} onClick={editAdditionalInfo} />
				</UIOverlayCard>
			</UIModal>
		);
	};

	return renderOptionByRole(signedInUser);
}
