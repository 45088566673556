import React, { useEffect, useState } from 'react';
import './styles';
import { useSelector } from 'react-redux';
import { getAddressFromProperty } from '../../../utils/Helpers/ServiceHelper';
import PLImage from '../../PL/PLImage';
import { getMapImageFromProperty } from '../../../utils/Helpers/BookingHelpers';
import UILabeledInput from '../UILabeledInput';
import UIModal from '../UIModal';
import UIOverlayCard from '../UIOverlayCard';
import UIIconButton from '../UIIconButton';
import Queries from '../../../utils/API/Queries';
import { CloseIcon, EditIcon, MapIcon } from '../../../assets/icons';
import { SCREEN_TYPE } from '../../../utils/Config/Constants';
import { TYPES } from './config';
import UIIcon from '../UIIcon';
import UIHover from '../UIHover/UIHover';
import { UI_LABELED_INPUT_TEXT_TYPE } from '../UILabeledInput/config';
import { getIsUserAdminSelector } from '../../../redux/selectors/CurrentUserSelectors';
import { getScreenTypeSelector } from '../../../redux/selectors/ControlSelectors';
import { useLazyQuery } from '@apollo/react-hooks';
import EditProperty from './components/EditProperty';

export default function UIProperty({
	property: inputProperty,
	onFinishSavingAddress,
	editOnClick,
	type,
	updateAndReturn,
	showEditUnitNumber,
	forceClose
}: UIPropertyProps) {
	const isUserAdmin = useSelector(getIsUserAdminSelector);
	const screenType = useSelector(getScreenTypeSelector);

	const [property, setProperty] = useState<PropertyType>(inputProperty);
	const [showEditAddressModal, setShowEditAddressModal] = useState<boolean>(false);
	const [showCreatePropertyModal, setShowCreatePropertyModal] = useState<boolean>(false);
	const [showPropertyOptionsModal, setShowPropertyOptionsModal] = useState<boolean>(false);

	const [getProperty, { data: propertyData }] = useLazyQuery(Queries.GET_PROPERTY, {
		variables: { id: property ? property.id : undefined }
	});

	useEffect(() => {
		(async () => {
			if (property) {
				await getProperty();
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (propertyData) {
				setProperty(propertyData['property']);
			}
		})();
	}, [propertyData]);

	const renderPropertyOptionsModal = () => {
		const openGoogleMaps = () => window.open(`https://www.google.com/maps/search/?api=1&query=${property.latitude},${property.longitude}`, '_blank');

		const hideModal = () => {
			setShowPropertyOptionsModal(false);
		};

		const showEditAddressModal = () => {
			setShowEditAddressModal(true);
			hideModal();
		};

		return (
			<UIModal condition={showPropertyOptionsModal}>
				<UIOverlayCard title={'Property options'} hideModal={hideModal}>
					<div className='general-buttons-container-no-margin'>
						<UIIconButton dark text={'Show on Google Maps'} onClick={openGoogleMaps} />
						<UIIconButton dark text={'Update property'} onClick={showEditAddressModal} />
					</div>
				</UIOverlayCard>
				{type === TYPES.sideBar ? null : renderEditAddressModal()}
			</UIModal>
		);
	};

	const renderCreatePropertyModal = () => {
		const hideModal = () => setShowCreatePropertyModal(false);
		return (
			<UIModal condition={showCreatePropertyModal}>
				<UIOverlayCard title={'Create a property'} hideModal={hideModal}>
					<p>Please reach out to the dev team to add a property to this service.</p>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderContentByType = () => {
		if (!property.streetAddress) return null;

		let header = (
			<div className='uiProperty--address'>
				{forceClose && <UIIcon iconSrc={CloseIcon.white} onClick={forceClose} className='uiProperty--close' alt={'close'} />}
				<div className='uiProperty--address--text'>
					<p className='uiProperty--title'>
						{property.streetAddress ?? getAddressFromProperty(property)} {property.unitNumber ? `Unit ${property.unitNumber}` : ''}
					</p>
					<p className='uiProperty--addressLineTwo'>
						{property.city}, {property.state} {property.zipCode}
					</p>
				</div>
			</div>
		);

		if (property.alias) {
			header = (
				<div className='uiProperty--address'>
					{/*{screenType === SCREEN_TYPE.mobile && <UIIcon iconSrc={CloseIcon.white} onClick={forceClose} className='uiProperty--close' alt={'close'} />}*/}
					<div className='uiProperty--address--text'>
						<p className='uiProperty--title'>
							{property.streetAddress} {property.unitNumber ? `Unit ${property.unitNumber}` : ''}
						</p>
						<p className='uiProperty--addressLineTwo'>
							{property.city}, {property.state} {property.zipCode}
						</p>
						<p className='uiProperty--title-alias'>{property.alias}</p>
					</div>
				</div>
			);
		}

		switch (type) {
			case TYPES.map:
				return (
					<div className='uiProperty__top'>
						<div className='uiProperty__top-left'>
							{header}
							{property.longitude && (
								<p className='uiProperty--lngLat'>
									{property.latitude}, {property.longitude}
								</p>
							)}

							{showEditUnitNumber && (
								<UILabeledInput
									type={UI_LABELED_INPUT_TEXT_TYPE}
									label={'UNIT #'}
									containerStyle={{ maxWidth: 128, marginTop: 8 }}
									value={property.unitNumber}
									placeholder={'Unit #...'}
									onChangeValue={(unitNumber) => (updateAndReturn ? updateAndReturn('unitNumber', unitNumber) : null)}
								/>
							)}
						</div>
						{screenType !== SCREEN_TYPE.mobile && <PLImage src={getMapImageFromProperty(property) ?? ''} className='uiProperty--img' alt={'location map'} />}
					</div>
				);
			case TYPES.sideBar:
				const openGoogleMaps = () => window.open(`https://www.google.com/maps/search/?api=1&query=${property.latitude},${property.longitude}`, '_blank');
				const showEditAddressModal = () => {
					setShowPropertyOptionsModal(false);
					setShowEditAddressModal(true);
				};
				const renderRightItems = () => {
					if (editOnClick) {
						return (
							<div className='uiProperty__rightItems'>
								<UIHover text='view on Google Maps'>
									<UIIcon className='uiProperty__rightItems--map' iconSrc={MapIcon.white} onClick={openGoogleMaps} alt={'map'} />
								</UIHover>
								<UIHover text='edit property'>
									<UIIcon className='uiProperty__rightItems--edit' iconSrc={EditIcon.white} onClick={showEditAddressModal} alt={'edit'} />
								</UIHover>
							</div>
						);
					} else return undefined;
				};

				return (
					<div className='uiProperty__sideBar--header'>
						{header}
						{renderRightItems()}
					</div>
				);
			case TYPES.text:
			default:
				return (
					<div className={`uiProperty__text ${editOnClick ? 'uiProperty__top-hover' : ''}`} style={editOnClick ? { cursor: 'pointer' } : {}}>
						{header}
					</div>
				);
		}
	};

	const renderEditAddressModal = () => {
		const hideModal = () => {
			setShowEditAddressModal(false);
			setShowPropertyOptionsModal(false);
		};

		return <EditProperty condition={showEditAddressModal} hideModal={hideModal} property={property} onFinish={onFinishSavingAddress} />;
	};

	if (!property) {
		return (
			<div className='uiProperty__noProperty' onClick={isUserAdmin ? () => setShowCreatePropertyModal(true) : undefined}>
				<p className='uiProperty__noProperty--title'>{isUserAdmin ? 'missing property' : 'missing property info, please reach out'}</p>
				{renderCreatePropertyModal()}
			</div>
		);
	}

	return (
		<div className='uiProperty'>
			<div onClick={() => (editOnClick ? setShowPropertyOptionsModal(true) : null)}>{renderContentByType()}</div>
			{type === TYPES.sideBar ? null : renderPropertyOptionsModal()}
			{renderEditAddressModal()}
		</div>
	);
}
