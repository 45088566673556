import { DropdownItem } from '../../../../../../../components/UI/UILabeledInput/types';

export const getRegionIndividualOptions = (selectedRegion) => {
	let regionOptions: DropdownItem[] = [];
	if (selectedRegion) {
		let addOnOptions = selectedRegion.options;
		addOnOptions = addOnOptions.filter((addOnOptions) => addOnOptions.packageType === 'addOn');

		for (let i = 0; i < addOnOptions.length; i++) {
			let option = addOnOptions[i];
			regionOptions.push({
				value: option,
				label: option.name + '  ($' + option.price / 100 + ')'
			});
		}
	}
	return regionOptions;
};
