import React from 'react';
import './styles';
import { useSelector } from 'react-redux';
import TableService from './types/TableService';
import TableAvailablePhotographer from './types/TableAvailablePhotographer';
import TableAvailableEditor from './types/TableAvailableEditor/TableAvailableEditor';
import TableEditingCompany from './types/TableEditingCompany/TableEditingCompany';
import TableTechnicianInfo from './types/TableUserSearch/TableUserSearch';
import TableAgentList from './types/TableAgentList/TableAgentList';
import TableAffiliates from './types/TableAffiliates/TableAffiliates';
import TableOption from './types/TableOptions/TableOption';
import TablePayment from './types/TablePayment';
import TableFinances from './types/TableFinances';
import EmptyDataSet from '../EmptyDataSet/EmptyDataSet';
import TableTechnicianList from './types/TableTechnicianList/TableTechnicianList';
import TableEditors from './types/TableEditors/TableEditors';
import TableListings from './types/TableListings';
import TableServiceRequests from './types/TableServiceRequests';
import UILoadingPage from '../../UI/UILoadingPage/UILoadingPage';
import {
	AGENTS_TABLE_TYPE,
	EDITING_COMPANY_TABLE_TYPE,
	EDITOR_AVAILABLE_PHOTOSHOOT_TABLE_TYPE,
	EDITORS_TABLE_TYPE,
	FINANCE_TABLE_TYPE,
	PARTNER_PACKAGE_TABLE_TYPE,
	INVITE_CODE_TABLE_TYPE,
	LISTINGS_TABLE_TYPE,
	OPTIONS_TABLE_TYPE,
	PAYMENT_TABLE_TYPE,
	PHOTOGRAPHER_AVAILABLE_PHOTOSHOOT_TABLE_TYPE,
	PHOTOGRAPHERS_TABLE_TYPE,
	SALESMEN_TABLE_TYPE,
	SERVICE_REQUEST_TABLE_TYPE,
	SERVICES_TABLE_TYPE,
	USER_SEARCH_TABLE_TYPE
} from './config';
import { getScreenTypeSelector } from '../../../redux/selectors/ControlSelectors';
import TableInviteCodes from './types/TableInviteCodes';
import TablePartnerPackages from './types/TablePartnerPackages';
import PLTableFooter from './footer';

export default function PLTable({ limit, data, onSelect, header, type, isLoading, hideAddress, emptySetText, updateTable }: PLTableProps) {
	const screenType = useSelector(getScreenTypeSelector);

	if (isLoading) {
		return (
			<div className='lottieContainer'>
				<UILoadingPage />
			</div>
		);
	}

	if (!data) {
		return null;
	}

	let pagination = <PLTableFooter data={data} limit={limit} updateTable={updateTable} />;

	if (data.length === 0 && !isLoading) {
		const emptySetTextValue = emptySetText || 'There are no results!';

		return (
			<div id='empty-table' className='emptySet'>
				<EmptyDataSet message={emptySetTextValue} />
				{pagination}
			</div>
		);
	}

	switch (type) {
		case SERVICES_TABLE_TYPE:
			return <TableService data={data} pagination={pagination} hideAddress={hideAddress} />;
		case PHOTOGRAPHER_AVAILABLE_PHOTOSHOOT_TABLE_TYPE:
			return <TableAvailablePhotographer data={data} pagination={pagination} updateTable={updateTable} />;
		case EDITOR_AVAILABLE_PHOTOSHOOT_TABLE_TYPE:
			return <TableAvailableEditor data={data} pagination={pagination} onSelect={onSelect} updateTable={updateTable} />;
		case PAYMENT_TABLE_TYPE:
			return <TablePayment data={data} pagination={pagination} />;
		case AGENTS_TABLE_TYPE:
			return <TableAgentList data={data} pagination={pagination} onSelect={onSelect} />;
		case PHOTOGRAPHERS_TABLE_TYPE:
			return <TableTechnicianList data={data} pagination={pagination} onSelect={onSelect} />;
		case USER_SEARCH_TABLE_TYPE:
			return <TableTechnicianInfo data={data} pagination={pagination} onSelect={onSelect} />;
		case FINANCE_TABLE_TYPE:
			return <TableFinances data={data} pagination={pagination} />;
		case PARTNER_PACKAGE_TABLE_TYPE:
			return <TablePartnerPackages data={data} pagination={pagination} />;
		case EDITORS_TABLE_TYPE:
			return <TableEditors data={data} pagination={pagination} onSelect={onSelect} />;
		case SALESMEN_TABLE_TYPE:
			return <TableAffiliates data={data} pagination={pagination} onSelect={onSelect} />;
		case INVITE_CODE_TABLE_TYPE:
			return <TableInviteCodes data={data} pagination={pagination} onSelect={onSelect!} />;
		case OPTIONS_TABLE_TYPE:
			return <TableOption data={data} pagination={pagination} onSelect={onSelect} />;
		case EDITING_COMPANY_TABLE_TYPE:
			return <TableEditingCompany data={data} pagination={pagination} mobile={screenType} onSelect={onSelect} />;
		case LISTINGS_TABLE_TYPE:
			return <TableListings data={data} pagination={pagination} />;
		case SERVICE_REQUEST_TABLE_TYPE:
			return (
				<TableServiceRequests
					hideAddress={hideAddress}
					data={data}
					pagination={pagination}
					onSelect={onSelect ? onSelect : () => {}}
					updateTable={updateTable}
				/>
			);
		default:
			return <TableService data={data} pagination={pagination} />;
	}
}
