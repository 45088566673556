import React from 'react';
import './styles';
import UIOverlayCard from '../UIOverlayCard';
import UILoadingPage from '../UILoadingPage/UILoadingPage';
import { CAMERA_LOADING_TYPE } from '../UILoadingPage/config';
import UIIconButton from '../UIIconButton';

export default function UIStepOverlayCard(props: UIStepOverlayCardProps) {
	const { hideModal, steps, currentStep, canGoToNextStep, goToNextStep, nextButtonTitle, isLoading, renderTabBar, disableLastStep = true, className } = props;

	const stepPercentage = ((currentStep + 1) / steps.length) * 100;

	const showButton = currentStep !== steps.length - 1 || !disableLastStep;

	const renderOptionalTabBar = () => {
		if(isLoading || !renderTabBar) return null;
		return renderTabBar();
	}

	const renderContent = () => {
		if(isLoading) return <UILoadingPage style={{ height: 400 }} type={CAMERA_LOADING_TYPE} />;
		return props.children(steps[currentStep]);
	}

	const renderStepButton = () => {
		if(isLoading || !showButton) return null;
		return <UIIconButton onClick={goToNextStep} dark className='uiStepOverlayCard--next' text={nextButtonTitle || 'next'} isDisabled={!canGoToNextStep} />;
	}

	return (
		<UIOverlayCard {...props} hideModal={hideModal} className={className}>
			<div
				className='uiStepOverlayCard--steps'
				style={{
					width: `${stepPercentage}%`,
					backgroundColor: stepPercentage === 100 || currentStep === 0 || steps.length === 0 ? 'transparent' : 'var(--mint-primary)'
				}}
			/>
			{renderOptionalTabBar()}
			<div className='uiStepOverlayCard__content'>
				{renderContent()}
			</div>
			{renderStepButton()}
		</UIOverlayCard>
	);
}
