import React from 'react';
import './styles/mobile.scss';
import UILabeledInput from '../../../../../../components/UI/UILabeledInput';
import { ENTRY_OPTIONS } from '../../config';
import { BookingEntryProps } from './types';
import { UI_LABELED_INPUT_TEXT_FIELD, UI_LABELED_INPUT_TEXT_TYPE } from '../../../../../../components/UI/UILabeledInput/config';
import { useSelector } from 'react-redux';
import { getBookingConfigSelector } from '../../../../../../redux/selectors/BookingSelectors';
import { ENTRY_TYPE_AGENT, ENTRY_TYPE_HOMEOWNER, ENTRY_TYPE_LOCK_BOX } from './config';
import UIDropdownSelect from '../../../../../../components/UI/UIDropdownSelect';

export default function BookingEntry({ updateBookingConfig }: BookingEntryProps) {
	const bookingConfig = useSelector(getBookingConfigSelector);

	const entryOptions = [ENTRY_TYPE_AGENT, ENTRY_TYPE_HOMEOWNER, ENTRY_TYPE_LOCK_BOX];

	const onChangeEntryNotes = (notes: string) => {
		let entry = bookingConfig.entry;
		entry['notes'] = notes;
		updateBookingConfig('entry', entry);
	};

	const onChangeEntryType = (newEntry: PLTab) => {
		let entry = bookingConfig.entry;
		entry['type'] = newEntry.value;
		updateBookingConfig('entry', entry);
	};

	const onChangeLockBoxValue = (lockBoxCode: string) => {
		let entry = bookingConfig.entry;
		entry['lockBoxCode'] = lockBoxCode;
		updateBookingConfig('entry', entry);
	};

	const renderEntryOptions = () => {
		const selectedEntryType = entryOptions.find((entryOption) => entryOption.value === bookingConfig.entry.type);
		if (!selectedEntryType) return null;

		return (
			<div className='bookingEntry__card--wrapper'>
				<UIDropdownSelect selectedTab={selectedEntryType} tabs={entryOptions} updateSelected={onChangeEntryType} />
			</div>
		);
	};

	const isLockBoxEntryType = bookingConfig.entry && bookingConfig.entry.type === ENTRY_OPTIONS.lockBox;

	return (
		<div className='bookingEntry'>
			{renderEntryOptions()}
			<div className='pl-separator' />
			{isLockBoxEntryType && (
				<UILabeledInput
					type={UI_LABELED_INPUT_TEXT_TYPE}
					containerStyle={{ marginTop: 24, width: 160, marginBottom: 24 }}
					style={{ width: 600, maxWidth: '100%' }}
					value={bookingConfig.entry.lockBoxCode}
					placeholder={'Lock-box code...'}
					label={'LOCK-BOX CODE'}
					onChangeValue={onChangeLockBoxValue}
				/>
			)}
			<UILabeledInput
				type={UI_LABELED_INPUT_TEXT_FIELD}
				containerClassName='bookingEntry__card--select'
				value={bookingConfig.entry.notes}
				placeholder={'Are there any specifics your service provider will need to access the property?'}
				label={'ENTRY NOTES'}
				onChangeValue={onChangeEntryNotes}
			/>
		</div>
	);
}
