import styled from 'styled-components';
import PLImage from '../../../../components/PL/PLImage';
import UIListInputRow from '../../../../components/UI/UIListInputRow';
import UIIconButton from '../../../../components/UI/UIIconButton';
import { Link } from 'react-router-dom';

export const div_SignupContainerTab = styled.div`
	//DEFAULT
	animation: fade-in-up 0.6s ease-in-out;
	margin: 8px;

	@-webkit-keyframes fade-in-up {
		0% {
			opacity: 0;
			transform: translate(0, 25px);
		}
		100% {
			opacity: 1;
			transform: translate(0, 0);
		}
	}

	@-o-keyframes fade-in-up {
		0% {
			opacity: 0;
			transform: translate(0, 25px);
		}
		100% {
			opacity: 1;
			transform: translate(0, 0);
		}
	}

	@-ms-keyframes fade-in-up {
		0% {
			opacity: 0;
			transform: translate(0, 25px);
		}
		100% {
			opacity: 1;
			transform: translate(0, 0);
		}
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_SignupCardsContainer = styled.div`
	//DEFAULT
	display: flex;
	flex-direction: column;
	flex: 1 1 100%;
	margin-top: 24px;
	width: 100%;
	align-items: center;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_SignupCard = styled.div`
	//DEFAULT
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 120px;
	border: #f1f1f1 solid 1px;
	background-color: var(--extra-light-background-color);
	margin: 0 0 12px 0;
	padding: 36px;
	border-radius: 12px;
	cursor: pointer;
	transition: all 0.2s;

	&:active {
		transform: translateY(1px);
		opacity: 0.8;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		&:hover {
			background-color: #f1f1f1;

			& > p {
				color: #121212;
			}
		}
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const PLImage_SignupCardImage = styled(PLImage)`
	//DEFAULT
	width: 60px;
	height: 60px;
	margin-right: 36px;
	background-color: var(--white-8-solid);
	padding: 16px;
	object-fit: contain;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_SignupCardText = styled.p`
	//DEFAULT
	font-size: 18pt;
	font-weight: 700;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_SignupCardHeader = styled.p`
	//DEFAULT
	font-size: 16pt;
	font-weight: 700;
	width: 100%;
	margin-top: 12px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		margin-top: 0;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		margin-top: 0;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_SignupSelection = styled.div`
	//DEFAULT
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIListInputRow_SignupInput = styled(UIListInputRow)`
	//DEFAULT
	padding: 0 12px 12px 0;
	border-bottom: none;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_SignupCheckboxContainer = styled.div`
	//DEFAULT
	margin-top: 24px;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 10pt;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIIconButton_SignupFinishButton = styled(UIIconButton)`
	//DEFAULT
	margin-top: 36px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_SignupTerms = styled.p`
	//DEFAULT
	font-size: 12pt;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const Link_SignupTermsLink = styled(Link)`
	//DEFAULT
	color: var(--mint-primary);

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
