import gql from 'graphql-tag';

export const GET_SERVICE_WITH_SERVICE_DATE = gql`
	query GetServiceWithServiceDate($id: String!) {
		service(id: $id) {
			id
			status
			type
			surgeBoost
			serviceDate
			property {
				id
				timezone
			}
			trainee {
				id
				name
				email
				stripeAccountId
				phoneNumber
				image
			}
		}
	}
`;

export const UPDATE_SERVICE_DATE = gql`
	mutation UpdateServiceDate($serviceId: String!, $serviceDate: String!) {
		updateService(id: $serviceId, serviceDate: $serviceDate) {
			id
			serviceDate
		}
	}
`;
