import { LottieAnimations } from '../../../assets/icons';
import { Options } from 'react-lottie';

export const defaultLottieOptions: Options = {
	loop: true,
	autoplay: true,
	//@ts-ignore
	path: LottieAnimations.loadingCircle,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice'
	}
};
