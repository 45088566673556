import React, { useEffect, useState } from 'react';
import './styles';
import { useSelector } from 'react-redux';
import UIListInputRow from '../../UI/UIListInputRow';
import Queries from '../../../utils/API/Queries';
import { SERVICE_REQUESTS, STATUS_ADMIN_HOLD, USER_ROLE_EDITOR } from '../../../utils/Config/Constants';
import UIUser from '../../UI/UITeamMember/UITeamMember';
import UIUserSearch from '../../UI/UIUserSearch/UIUserSearch';
import PLImage from '../../PL/PLImage';
import UIIconButton from '../../UI/UIIconButton';
import UICheckbox from '../../UI/UICheckbox/UICheckbox';
import { getServiceRequestsOnFile } from '../../../utils/Helpers/FileHelper';
import UIOverlayCard from '../../UI/UIOverlayCard';
import { UI_LABELED_INPUT_TEXT_FIELD } from '../../UI/UILabeledInput/config';
import { CHILD_TYPE } from '../../UI/UIListInputRow/config';
import { useLazyQuery } from '@apollo/react-hooks';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../redux/selectors/CurrentUserSelectors';
import { useApolloClient } from 'react-apollo';
import { toast } from 'react-toastify';
import UILoadingPage from '../../UI/UILoadingPage/UILoadingPage';
import * as strings from './strings';
import { UI_ICON_BUTTON_FILL_TYPE } from '../../UI/UIIconButton/config';

export default function CreateServiceRequestModal({ serviceId, updateService, onCreate, hideModal, files }: CreateServiceRequestModalProps) {
	if (!serviceId) return null;

	const isUserAdmin = useSelector(getIsUserAdminSelector);
	const signedInUser = useSelector(getSignedInUserSelector);

	const [notes, setNotes] = useState<string>('');
	const [service, setService] = useState<ServiceType>();
	const [assignEditor, setAssignEditor] = useState<boolean>(false);
	const [administrativeHold, setAdministrativeHold] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [editor, setEditor] = useState<UserType>();

	const client = useApolloClient();

	const [getService, { data: serviceData }] = useLazyQuery(Queries.GET_SERVICE_WITH_FILES, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getService();
		})();
	}, []);

	useEffect(() => {
		if (serviceData) {
			const fetchedService: ServiceType = serviceData['service'];
			setService(fetchedService);
			if (isUserAdmin && fetchedService.editor) {
				setEditor(fetchedService.editor);
			}
		}
	}, [serviceData]);

	if (!service) return null;

	const createAction = async (createdServiceRequest) => {
		await client.mutate({
			mutation: Queries.CREATE_ACTION,
			variables: {
				title: 'fixRequestEditorAssigned',
				serviceRequest: createdServiceRequest.id,
				actor: signedInUser.id
			}
		});
	};

	const createServiceRequest = async (files) => {
		let variables: any = {
			type: SERVICE_REQUESTS.type.imageAdjustment,
			accepted: false,
			agent: service.client.id,
			service: service.id,
			urgent: false,
			status: SERVICE_REQUESTS.status.awaitingRequestApproval,
			requestedFiles: files.map((file) => file.id),
			notes
		};
		if (editor) {
			variables.editor = editor.id;
			variables.status = SERVICE_REQUESTS.status.inProgress;
		} else if (isUserAdmin) {
			variables.status = SERVICE_REQUESTS.status.awaitingAcceptance;
		}
		setIsLoading(true);
		let createResponse = await client
			.mutate({
				mutation: Queries.CREATE_SERVICE_REQUEST,
				variables
			})
			.catch((error) => {
				setIsLoading(false);
				setAdministrativeHold(false);
				toast.error(error.message);
			});
		const { createServiceRequest } = createResponse['data'];

		if (createServiceRequest) {
			if (editor) {
				await createAction(createServiceRequest);
			}
			if (administrativeHold) {
				await updateServiceStatus(STATUS_ADMIN_HOLD);
			}
			if (updateService) {
				updateService();
			}
			onCreate();
			hideModal();
			setIsLoading(false);
			setAdministrativeHold(false);
		}
	};

	const updateServiceStatus = async (status) => {
		await client
			.mutate({
				mutation: Queries.UPDATE_SERVICE,
				variables: {
					serviceId: service.id,
					status
				}
			})
			.catch((error) => {
				setIsLoading(false);
				setAdministrativeHold(false);
				toast.error(error.message);
			});
	};

	const renderEditorComponent = (editor) => {
		if (!isUserAdmin) {
			return;
		}
		if (!editor) {
			return (
				<UIListInputRow type={CHILD_TYPE}>
					<UICheckbox
						label={strings.assignEditor}
						onChangeExpansion={() => setAssignEditor(!assignEditor)}
						status={assignEditor ? UICheckbox.CHECKED : UICheckbox.UNCHECKED}
					/>
					{assignEditor && (
						<UIUserSearch
							emptySetText={strings.editorEmptySet}
							role={USER_ROLE_EDITOR}
							style={{ marginTop: 12 }}
							label={strings.searchLabel}
							placeholder={strings.searchPlaceholder}
							onSelectUser={setEditor}
						/>
					)}
				</UIListInputRow>
			);
		} else {
			return (
				<UIListInputRow type={CHILD_TYPE}>
					<div className='editFile__editor'>
						<UIUser user={editor} deleteOnClick onClick={() => setEditor(undefined)} />
						{editor && (
							<div className='editFile__editor__right'>
								<p className='editFile__editor__right--text'>{editor.name} will be assigned to this service request</p>
							</div>
						)}
					</div>
				</UIListInputRow>
			);
		}
	};

	let unavailableFiles: FileType[] = [];

	if (!service) {
		return (
			<UIOverlayCard cardStyle={{ justifyContent: 'center', alignItems: 'center' }} hideModal={hideModal}>
				<UILoadingPage />
			</UIOverlayCard>
		);
	}

	for (let file of files) {
		if (getServiceRequestsOnFile(service.serviceRequests, file).length > 0) {
			unavailableFiles.push(file);
		} else if (!file['allowFixRequest']) {
			unavailableFiles.push(file);
		}
	}

	let availableFiles = files.filter((file) => unavailableFiles.filter((fileInProgress) => fileInProgress.id === file.id).length === 0);

	return (
		<UIOverlayCard
			hideModal={hideModal}
			title={`Report an issue with ${availableFiles.length} ${availableFiles.length > 1 ? 'files' : 'file'}`}
			description={strings.notesInfo}>
			<div className='editFile__carousel no-scrollbar'>
				{availableFiles.map((file, index) => (
					<PLImage key={`${file.filename}${index}`} borderRadius={3} alt={file.filename} className='editFile__carousel--image' src={file.thumbnailUrl} />
				))}
			</div>
			{unavailableFiles.length > 0 && (
				<div className='editFile__unavailableFiles'>
					<p className='editFile__description'>
						The following files <span className='editFile__description--span'>can not</span> be add to this service request because they are either
						<br /> 1.) Already being processed in a service request or
						<br /> 2.) They were uploaded by the client.
					</p>
					<div className='editFile__unavailableFiles--images'>
						{unavailableFiles.map((file) => (
							<PLImage key={file.filename} alt={file.filename} className='editFile__carousel--image' src={file.thumbnailUrl} />
						))}
					</div>
				</div>
			)}
			<UIListInputRow
				label={strings.notesLabel}
				placeholder={strings.notesPlaceholder}
				value={notes}
				onChangeValue={setNotes}
				type={UI_LABELED_INPUT_TEXT_FIELD}
			/>
			{renderEditorComponent(editor)}
			{isUserAdmin && (
				<UIListInputRow type={CHILD_TYPE}>
					<UICheckbox
						label={strings.adminHoldCheckbox}
						onChangeExpansion={() => setAdministrativeHold(!administrativeHold)}
						status={administrativeHold ? UICheckbox.CHECKED : UICheckbox.UNCHECKED}
					/>
				</UIListInputRow>
			)}
			<UIListInputRow type={CHILD_TYPE}>
				<div className='editFile__footer'>
					<UIIconButton
						dark
						isDisabled={notes.length < 5}
						disabledErrorMessage={unavailableFiles.length > 0 ? strings.disabledServiceRequestInProgress : strings.disabledMissingNotes}
						isLoading={isLoading}
						type={UI_ICON_BUTTON_FILL_TYPE}
						onClick={() => createServiceRequest(availableFiles)}
						text={editor ? strings.sendToEditor : isUserAdmin ? strings.offerToAllEditors : strings.submit}
					/>
				</div>
			</UIListInputRow>
		</UIOverlayCard>
	);
}
