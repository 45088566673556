// Created by jarongallo at 7/1/21
import React, { useEffect, useState } from 'react';
import './styles';
import PLServicePhotos from '../../PLServiceComponents/PLServicePhotos';
import PLServiceFloorplans from '../../PLServiceComponents/PLServiceFloorPlans';
import PLServiceMatterport from '../../PLServiceComponents/PLServiceMatterport';
import PLServiceVideos from '../../PLServiceComponents/PLServiceVideos';
import { PLServiceContentProps } from './types';
import PLServiceRawImages from '../../PLServiceComponents/PLServiceRawImages';
import { useSelector } from 'react-redux';
import { getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import {
	USER_ROLE_ADMIN,
	USER_ROLE_AGENT,
	USER_ROLE_EDITING_MANAGER,
	USER_ROLE_EDITOR,
	USER_ROLE_PHOTOGRAPHER,
	USER_ROLE_REGIONAL_MANAGER
} from '../../../../../../utils/Config/Constants';
import {
	PL_SERVICE_FILES_FLOOR_PLANS_TAB,
	PL_SERVICE_FILES_MATTERPORT_TAB,
	PL_SERVICE_FILES_PHOTOS_TAB,
	PL_SERVICE_FILES_RAW_VIDEOS_TAB,
	PL_SERVICE_FILES_RAWS_TAB,
	PL_SERVICE_FILES_EXTRA_RAWS_TAB,
	PL_SERVICE_FILES_VIDEOS_TAB
} from './config';
import UIDropdownSelect from '../../../../../../components/UI/UIDropdownSelect';
import PLServiceRawVideos from '../../PLServiceComponents/PLServiceRawVideos';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SERVICE_FOR_FILES } from './queries';
import PLServiceExtraRawImages from '../../PLServiceComponents/UploadTypeComponents/PLServiceExtraRawImages';
import { useHistory, useLocation } from 'react-router-dom';

export default function PLServiceContent({ serviceId }: PLServiceContentProps) {
	const signedInUser = useSelector(getSignedInUserSelector);

	const [service, setService] = useState<ServiceType>();

	const history = useHistory();
	const location = useLocation();

	const getTabs = (tabService): PLTab[] => {
		let tabs: PLTab[] = [];
		switch (signedInUser.role) {
			case USER_ROLE_ADMIN:
			case USER_ROLE_REGIONAL_MANAGER:
				tabs = [
					PL_SERVICE_FILES_PHOTOS_TAB,
					PL_SERVICE_FILES_VIDEOS_TAB,
					PL_SERVICE_FILES_RAWS_TAB,
					PL_SERVICE_FILES_RAW_VIDEOS_TAB,
					PL_SERVICE_FILES_EXTRA_RAWS_TAB
				];
				if (tabService?.includesFloorplan) {
					tabs.push(PL_SERVICE_FILES_FLOOR_PLANS_TAB);
				}
				if (tabService?.includesMatterport) {
					tabs.push(PL_SERVICE_FILES_MATTERPORT_TAB);
				}
				return tabs;
			case USER_ROLE_EDITOR:
			case USER_ROLE_EDITING_MANAGER:
				tabs = [PL_SERVICE_FILES_PHOTOS_TAB, PL_SERVICE_FILES_VIDEOS_TAB, PL_SERVICE_FILES_RAWS_TAB, PL_SERVICE_FILES_RAW_VIDEOS_TAB];
				if (tabService?.includesFloorplan) {
					tabs.push(PL_SERVICE_FILES_FLOOR_PLANS_TAB);
				}
				return tabs;
			case USER_ROLE_AGENT:
			case USER_ROLE_PHOTOGRAPHER:
				tabs = [
					PL_SERVICE_FILES_RAWS_TAB,
					PL_SERVICE_FILES_RAW_VIDEOS_TAB,
					PL_SERVICE_FILES_PHOTOS_TAB,
					PL_SERVICE_FILES_VIDEOS_TAB,
					PL_SERVICE_FILES_EXTRA_RAWS_TAB
				];
				if (tabService?.includesFloorplan) {
					tabs.push(PL_SERVICE_FILES_FLOOR_PLANS_TAB);
				}
				if (tabService?.includesMatterport) {
					tabs.push(PL_SERVICE_FILES_MATTERPORT_TAB);
				}
				return tabs;
		}
		return tabs;
	};

	const [getService, { data: serviceData }] = useLazyQuery(GET_SERVICE_FOR_FILES, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getService();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				const fetchedService: ServiceType = serviceData['service'];
				setService(fetchedService);
				//getTabs(fetchedService)[0]
			}
		})();
	}, [serviceData]);

	if (!service) return null;

	const renderSelectedTab = () => {
		switch (getSelectedTab()) {
			case PL_SERVICE_FILES_PHOTOS_TAB:
				return <PLServicePhotos serviceId={serviceId} serviceStatus={service.status} />;
			case PL_SERVICE_FILES_FLOOR_PLANS_TAB:
				return <PLServiceFloorplans serviceId={serviceId} />;
			case PL_SERVICE_FILES_VIDEOS_TAB:
				return <PLServiceVideos serviceId={serviceId} />;
			case PL_SERVICE_FILES_MATTERPORT_TAB:
				return <PLServiceMatterport serviceId={serviceId} />;
			case PL_SERVICE_FILES_RAWS_TAB:
				return <PLServiceRawImages serviceId={serviceId} serviceStatus={service.status} serviceEditor={service.editor} />;
			case PL_SERVICE_FILES_RAW_VIDEOS_TAB:
				return <PLServiceRawVideos serviceId={serviceId} serviceStatus={service.status} serviceEditor={service.editor} />;
			case PL_SERVICE_FILES_EXTRA_RAWS_TAB:
				return <PLServiceExtraRawImages serviceId={serviceId} />;
			default:
				return null;
		}
	};

	const getSelectedTab = () => {
		const tabs = getTabs(service);
		let upload = new URLSearchParams(location.search).get('upload');
		return tabs.filter((tab) => tab.linkPath === upload)[0] ?? tabs[0];
	};

	const updateSelectedTab = (selectedTab) => {
		let pageParam = new URLSearchParams(location.search).get('page');
		history.push(`${location.pathname}?service=${serviceId}&page=${pageParam}&upload=${selectedTab.linkPath}`);
	};

	const tabsForService = getTabs(service);

	return (
		<div className='plServiceContentTab'>
			<UIDropdownSelect containerClassName='plServiceContentTab--tabs' selectedTab={getSelectedTab()} tabs={tabsForService} updateSelected={updateSelectedTab} />
			{renderSelectedTab()}
		</div>
	);
}
