// Created by jarongallo at 6/23/21
import React, { useState } from 'react';
import UIOverlayCard from '../../../../../../../../components/UI/UIOverlayCard';
import './styles';
import {
	EDIT_TYPE_SERVICE,
	STATUS_FINDING_PREFERRED_TECHNICIAN,
	STATUS_FINDING_TECHNICIAN,
	STATUS_PRE_ORDER,
	STATUS_TECHNICIAN_UPLOAD,
	USER_ROLE_ADMIN,
	USER_ROLE_EDITOR,
	USER_ROLE_REGIONAL_MANAGER
} from '../../../../../../../../utils/Config/Constants';
import UIUser from '../../../../../../../../components/UI/UITeamMember/UITeamMember';
import { isUserAdminOrRM } from '../../../../../../../../utils/Helpers/UserHelpers';
import { Link } from 'react-router-dom';
import UIIconButton from '../../../../../../../../components/UI/UIIconButton';
import { PlusIcon, TechniciansIcon, Trashcan } from '../../../../../../../../assets/icons';
import Queries from '../../../../../../../../utils/API/Queries';
import UIModal from '../../../../../../../../components/UI/UIModal';
import UICertifiedTechSearch from '../../../../../../../../components/UI/UICertifiedTechSearch';
import * as strings from './strings';
import { useSelector } from 'react-redux';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../../../../../../redux/selectors/CurrentUserSelectors';
import { useApolloClient } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { UI_ICON_BUTTON_CANCEL_TYPE, UI_ICON_BUTTON_FILL_TYPE, UI_ICON_BUTTON_OUTLINE_TYPE } from '../../../../../../../../components/UI/UIIconButton/config';

export default function EditServiceSPModal({ technician, service, queryService }: EditSerivceSPModalProps) {
	const signedInUser = useSelector(getSignedInUserSelector);
	const isUserAdmin = useSelector(getIsUserAdminSelector);
	const client = useApolloClient();

	const [isRemovingTech, setIsRemovingTech] = useState<boolean>(false);
	const [selectedPhotographer, setSelectedPhotographer] = useState<UserType | undefined>(undefined);
	const [isAssigningTechnician, setIsAssigningTechnician] = useState<boolean>(false);
	const [assignPhotographerModal, setAssignPhotographerModal] = useState<boolean>(false);

	const renderPhotographer = () => {
		if (signedInUser.role === USER_ROLE_EDITOR) {
			return undefined;
		}

		if (technician) {
			return (
				<div>
					<div className='renderTech__tech'>{service.type !== EDIT_TYPE_SERVICE ? strings.techLabelEditType : strings.techLabelCaptureType}</div>
					{(service.type !== EDIT_TYPE_SERVICE || isUserAdminOrRM(signedInUser)) && (
						<div>
							{[USER_ROLE_ADMIN, USER_ROLE_REGIONAL_MANAGER].indexOf(signedInUser.role) !== -1 ? (
								<Link to={`/photographer/${technician.id}`}>
									<UIUser user={technician} />
								</Link>
							) : (
								<UIUser user={technician} />
							)}
						</div>
					)}
					{service.type !== EDIT_TYPE_SERVICE && signedInUser && isUserAdmin && (
						<div className='renderTech__tech--buttonContainer'>
							<UIIconButton
								className='renderTech__tech--buttonContainer-replace'
								text={strings.replaceTechButton}
								icon={TechniciansIcon}
								type={UI_ICON_BUTTON_OUTLINE_TYPE}
								onClick={() => setAssignPhotographerModal(true)}
							/>
							<UIIconButton
								className='renderTech__tech--buttonContainer-remove'
								text={strings.removeAndReOfferTechButton}
								isLoading={isRemovingTech}
								icon={Trashcan.white}
								type={UI_ICON_BUTTON_CANCEL_TYPE}
								onClick={removePhotographer}
							/>
						</div>
					)}
				</div>
			);
		} else {
			return (
				<div className='renderTech__container'>
					<p className='renderTech__container--description'>{strings.noTechMessage}</p>
					{isUserAdminOrRM(signedInUser) && (
						<UIIconButton
							dark
							text={strings.addTechButton}
							className='renderTech__container--button'
							icon={PlusIcon.grey}
							onClick={() => setAssignPhotographerModal(true)}
						/>
					)}
				</div>
			);
		}
	};

	const removePhotographer = async () => {
		let variables;
		setIsRemovingTech(true);
		if (service.status === STATUS_PRE_ORDER || service.status === STATUS_TECHNICIAN_UPLOAD) {
			variables = {
				serviceId: service.id,
				photographer: null,
				status: STATUS_FINDING_TECHNICIAN
			};
		} else {
			variables = { serviceId: service.id, photographer: null };
		}
		variables.type = service.type;
		const response = await client
			.mutate({
				mutation: Queries.UPDATE_SERVICE,
				variables: variables
			})
			.catch((error) => {
				toast.error(strings.errorMessage + error.message);
			});
		const { updateService: updatedService } = response['data'];
		if (updatedService) {
			service.status = updatedService.status;
			service.photographer = undefined;
			await queryService();
			setIsRemovingTech(false);
		}
	};

	const renderAssignPhotographerModal = () => {
		const willChangeStatus = service.status === STATUS_PRE_ORDER || service.status === STATUS_FINDING_TECHNICIAN;

		const hideModal = () => {
			setAssignPhotographerModal(false);
		};

		return (
			<UIModal condition={assignPhotographerModal}>
				<UIOverlayCard
					hideModal={hideModal}
					cardStyle={{ height: '100%' }}
					title={strings.assignTechModalTitle}
					description={strings.assignTechModalDescription}>
					{willChangeStatus && (
						<p className='renderTech__warning'>
							{strings.assignTechModalWarning}
							<span className='renderTech__warning--span'>{strings.assignTechModalWarningSpan}</span>
						</p>
					)}
					<div className='renderTech__assign'>
						<UICertifiedTechSearch
							emptySetText={strings.searchTechEmptySet}
							region={service.region}
							showSearchParameters={true}
							certifications={service.bookedOptions.map((option) => option.photographerCertificatesRequired).flat(2)}
							label={strings.searchTechLabel}
							selectedUser={selectedPhotographer}
							onSelectUser={(selectedPhotographer) => setSelectedPhotographer(selectedPhotographer)}
						/>
					</div>

					<div className='renderTech__assign'>
						<UIIconButton
							dark
							type={UI_ICON_BUTTON_FILL_TYPE}
							isDisabled={!selectedPhotographer}
							isLoading={isAssigningTechnician}
							disabledErrorMessage={strings.assignTechModalDisabledMessage}
							text={strings.assignTechModalButton}
							onClick={assignPhotographer}
						/>
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const assignPhotographer = async () => {
		if (!selectedPhotographer) {
			toast.info(strings.assignTechInfoMessage);
		} else {
			const { status } = service;
			setIsAssigningTechnician(true);
			let variables;
			if (status === STATUS_FINDING_TECHNICIAN || status === STATUS_PRE_ORDER || status === STATUS_FINDING_PREFERRED_TECHNICIAN) {
				variables = {
					serviceId: service.id,
					photographer: selectedPhotographer.id,
					preferredPhotographers: [],
					status: STATUS_TECHNICIAN_UPLOAD
				};
			} else {
				variables = { serviceId: service.id, photographer: selectedPhotographer.id };
			}
			const response = await client
				.mutate({
					mutation: Queries.UPDATE_SERVICE,
					variables: variables
				})
				.catch((error) => {
					setSelectedPhotographer(undefined);
					setIsAssigningTechnician(false);

					toast.error(error.message);
				});
			const { updateService: assignTech } = response['data'];
			if (assignTech) {
				setSelectedPhotographer(undefined);
				setIsAssigningTechnician(false);
				setAssignPhotographerModal(false);
				service.photographer = selectedPhotographer;
				await queryService();
				toast.success(strings.assignTechSuccessMessage);
			}
		}
	};

	return (
		<div>
			{renderPhotographer()}
			{renderAssignPhotographerModal()}
		</div>
	);
}
