import * as strings from './strings';

export const quickLoginEmails = [
	strings.adminEmail,
	strings.agentEmail,
	strings.techEmail,
	strings.editorEmail,
	strings.regionalManagerEmail,
	strings.salesEmail,
	strings.editingManagerEmail,
	strings.affiliateManagerEmail
];
