import gql from 'graphql-tag';

export const GET_SERVICE_AGENT_HELP = gql`
	query GetServiceAgentHelp($id: String!) {
		service(id: $id) {
			id
			status
			listing {
				id
			}
		}
	}
`;
