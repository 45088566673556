import React from 'react';
import './styles';
import { useSelector } from 'react-redux';
import { getScreenTypeSelector } from '../../../redux/selectors/ControlSelectors';
import { UI_LABELED_INPUT_TEXT_TYPE } from '../UILabeledInput/config';
import UILabeledInput from '../UILabeledInput';
import { SCREEN_TYPE } from '../../../utils/Config/Constants';
import PLImage from '../../PL/PLImage';
import { getMapImageFromLocation } from '../../../utils/Helpers/BookingHelpers';
import * as strings from './strings';

export default function UIServiceLocation({ updateUnitNumber, serviceLocation, unitNumber, hideEditUnitNumber, imageSize }: UIServiceLocationProps) {
	const screenType = useSelector(getScreenTypeSelector);

	if (!serviceLocation) return null;

	return (
		<div className='uiServiceLocation'>
			<div className='uiServiceLocation--left'>
				<div className='uiServiceLocation--left-address'>
					{serviceLocation.alias && <p className='uiServiceLocation--left-alias'>{serviceLocation.alias}</p>}
					<p className='uiServiceLocation--left-title'>{serviceLocation.streetAddress}</p>
					<p className='uiServiceLocation--left-lineTwo'>
						{serviceLocation.city ? `${serviceLocation.city},` : ''} {serviceLocation.state} {serviceLocation.zipCode}
					</p>
				</div>
				{serviceLocation.longitude && (
					<p className='uiServiceLocation--left-lngLat'>
						{serviceLocation.latitude}, {serviceLocation.longitude}
					</p>
				)}
				{!hideEditUnitNumber && (
					<UILabeledInput
						type={UI_LABELED_INPUT_TEXT_TYPE}
						label={strings.unitNumberLabel}
						containerStyle={{ maxWidth: 128, marginTop: 8 }}
						value={unitNumber}
						placeholder={strings.unitNumberPlaceholder}
						onChangeValue={updateUnitNumber}
					/>
				)}
			</div>
			{screenType !== SCREEN_TYPE.mobile && (
				<PLImage
					src={getMapImageFromLocation(serviceLocation) ?? ''}
					style={{ height: imageSize, width: imageSize, maxHeight: imageSize, borderRadius: 12 }}
					imageClassName='uiServiceLocation--img'
					alt={'map view'}
				/>
			)}
		</div>
	);
}
