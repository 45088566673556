import * as types from './types';
import initialState from '../initialState';

export default function optionReducer(state = initialState.selectedOption, action) {
	if (action.type === types.STORE_OPTION) {
		return {
			...state,
			selectedOption: action.selectedOption
		};
	} else {
		return state;
	}
}
