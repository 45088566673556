// Created by jarongallo at 6/17/21
import React, { useEffect, useState } from 'react';
import ServiceNotes from '../../../IndividualService/ServiceNotes/ServiceNotes';
import './styles';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_SERVICE_WITH_NOTES } from './queries';

export default function PLServiceNotes({ serviceId }: PLServiceNotesProps) {
	const [service, setService] = useState<ServiceType>();

	const [getServiceWithNotes, { data: serviceData }] = useLazyQuery(GET_SERVICE_WITH_NOTES, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getServiceWithNotes();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setService(serviceData['service']);
			}
		})();
	}, [serviceData]);

	if (!service) return null;

	return (
		<div className='plServiceNotes'>
			<ServiceNotes service={service} updateService={getServiceWithNotes} />
		</div>
	);
}
