import React, { useEffect, useState } from 'react';
import * as strings from './strings';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_PL_SERVICE_RAW_VIDEOS, UPDATE_SERVICE_STATUS_FOR_RAW_VIDEOS } from './queries';
import {
	FILE_STATUS_LIVE,
	FILE_TYPE_VIDEO_RAW,
	STATUS_EDITOR_UPLOAD,
	STATUS_FINDING_EDIT_TECHNICIAN,
	STATUS_TECHNICIAN_UPLOAD
} from '../../../../../../utils/Config/Constants';
import './styles';
import UIMultiFileSelect from '../../../../../../components/UI/UIMultiUploadFileSelect/UIMultiFileSelect';
import { TYPES } from '../../../../../../components/UI/UIMultiUploadFileSelect/config';
import { PLServiceFilesQueryParams } from '../../types';
import PLUpload from '../../../../../../components/PL/PLUpload';
import UILoadingPage from '../../../../../../components/UI/UILoadingPage/UILoadingPage';

export default function PLServiceRawVideos({ serviceId, serviceStatus, serviceEditor }: PLServiceRawsProps) {
	const [rawVideos, setRawVideos] = useState<FileType[]>();

	let variables: PLServiceFilesQueryParams = {
		limit: 1000,
		query: { serviceId: serviceId, type: FILE_TYPE_VIDEO_RAW },
		isActive: true,
		order: [['index', 'ASC']]
	};

	const [updateService] = useMutation(UPDATE_SERVICE_STATUS_FOR_RAW_VIDEOS);
	const [getRawVideos, { data: rawVideosData }] = useLazyQuery(GET_PL_SERVICE_RAW_VIDEOS, { variables });

	useEffect(() => {
		(async () => {
			await getRawVideos();
		})();
	}, []);

	useEffect(() => {
		if (rawVideosData) {
			setRawVideos(rawVideosData['files']);
		}
	}, [rawVideosData]);

	if (!rawVideos) return <UILoadingPage />;

	const onFileUploadComplete = async () => {
		if (serviceStatus === STATUS_TECHNICIAN_UPLOAD) {
			if (serviceEditor) {
				await updateService({ variables: { serviceId, status: STATUS_EDITOR_UPLOAD } });
			} else {
				await updateService({ variables: { serviceId, status: STATUS_FINDING_EDIT_TECHNICIAN } });
			}
		}
		await getRawVideos();
	};

	const renderRawUpload = () => {
		return (
			<PLUpload
				onFileUploadComplete={onFileUploadComplete}
				multiple={true}
				fileType={FILE_TYPE_VIDEO_RAW}
				postData={{ serviceId }}
				uploadStatus={FILE_STATUS_LIVE}
			/>
		);
	};

	const renderContent = () => {
		return (
			<UIMultiFileSelect
				title={strings.fileSelectTitle}
				emptySetTitle={strings.emptySetTitle}
				emptySetDescription={strings.emptySetDescription}
				data={rawVideos}
				rightItem={renderRawUpload()}
				serviceId={serviceId}
				updateService={getRawVideos}
				downloadType={TYPES.RAW_VIDEO_TYPE}
			/>
		);
	};

	return <div className='plServiceRawVideos'>{renderContent()}</div>;
}
