import React, { Component } from 'react';
import { connect } from 'react-redux';
import './table-user-search.scss';
import PLImage from '../../../PLImage';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../../../utils/Helpers/AppHelper';

class TableUserSearch extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	//Mark: Render functions
	render() {
		const { data } = this.props;
		return (
			<div className='tableUserSearch fade-in-div' style={{ overflowY: 'auto', maxHeight: 360 }}>
				<div className='tableUserSearch--rows'>
					{data.map((photographer) => {
						return (
							<div className='overlayLabel' onClick={() => this.props.onSelect(photographer)} key={photographer.id}>
								<div className='overlayLabel__container'>
									<div className='photographerLabel__container'>
										<PLImage src={photographer.image} className='photographerLabel__container--img' alt={'service provider image'} />
										<div className='photographerLabel__container--info'>
											<p className='photographerLabel__container--info-name'>{photographer.name}</p>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

export default connect(commonMapStateToProps, commonMapDispatchToProps)(TableUserSearch);
