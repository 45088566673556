export const SET_SCREEN_TYPE = 'SET_SCREEN_TYPE';
export const SET_SHOW_ZENDESK_TYPE = 'SET_SHOW_ZENDESK_TYPE';
export const SHOW_OVERLAY = 'SHOW_OVERLAY';
export const SET_OVERLAY_COMPONENT = 'SET_OVERLAY_COMPONENT';
export const SET_RIGHT_PANEL_TYPE = 'SET_RIGHT_PANEL_TYPE';
export const SET_ADDITIONAL_HEADER = 'SET_ADDITIONAL_HEADER';
export const REQUIRE_LOGIN = 'REQUIRE_LOGIN';
export const TOGGLE_BOOKING = 'TOGGLE_BOOKING';
export const TOGGLE_MENU_VISIBILITY = 'TOGGLE_MENU_VISIBILITY';
export const SET_SELECTED_PANEL_TAB = 'SET_SELECTED_PANEL_TAB';
export const SET_SHOW_ACTIONS_NEEDED = 'SET_SHOW_ACTIONS_NEEDED';

export const ADD_PAGE_TO_SIDEBAR_NAVIGATION = 'ADD_PAGE_TO_SIDEBAR_NAVIGATION';
export const GO_TO_SIDEBAR_INDEX = 'GO_TO_SIDEBAR_INDEX';
export const CLEAR_SIDEBAR = 'CLEAR_SIDEBAR';
