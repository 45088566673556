import { LocaleTranslations } from '@uploadcare/react-widget';

export const defaultLocaleTranslations: LocaleTranslations = {
	dialog: {
		tabs: {
			preview: {
				done: 'Confirm selection',
				multiple: {
					done: 'Confirm selection'
				}
			}
		},
		done: 'Confirm selection'
	},
	buttons: {
		choose: {
			files: {
				one: 'upload file',
				other: 'upload files'
			},
			images: {
				one: 'upload file',
				other: 'upload files'
			}
		}
	}
};
