import React, { useEffect, useState } from 'react';
import './styles';
import UILoadingPage from '../UILoadingPage/UILoadingPage';
import PLImage from '../../PL/PLImage';
import { getDimensionsToFitContainer } from '../UIMultiImageSelect/config';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { FILE_TYPE_VIDEO } from '../../../utils/Config/Constants';
import UIVideo from '../UIVideo/UIVideo';
import UIFilmstripControl from './UIFilmstripControl';
import Measure from 'react-measure';
import { isMobileSafari, isSafari } from 'react-device-detect';
import { ZendeskAPI } from 'react-zendesk';

export default function UIFilmstrip({
	selectedIndex,
	setSelectedIndex,
	files,
	containerClassName,
	noGradient,
	style,
	fileSize = 'webUrl',
	audioFile,
	autoMute,
	autoplaySeconds,
	disableImage,
	renderImageOverlay = () => null
}: UIFilmstripProps) {
	const [containerBounds, setContainerBounds] = useState<PLSortableContainerBoundsType>();

	const _handleKeyDown = (event) => {
		switch (event.keyCode) {
			//Left arrow
			case 37:
				goToPrevious(!isMobileSafari && !isSafari);
				break;
			//Right arrow
			case 39:
				goToNext(true, !isMobileSafari && !isSafari);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', _handleKeyDown, { passive: true });

		return () => {
			document.removeEventListener('keydown', _handleKeyDown);
		};
	});

	useEffect(() => {
		files.forEach((file) => {
			const img = new Image();
			img.src = file.thumbnailUrl;
		});
		scrollToFilmstripIndex(selectedIndex, false);
	}, []);

	const selectedFile = files[selectedIndex];

	const goToPrevious = (smoothScroll = false) => {
		let newSelectedIndex = selectedIndex - 1;
		if (newSelectedIndex < 0) {
			newSelectedIndex = files.length - 1;
		}
		scrollToFilmstripIndex(newSelectedIndex, smoothScroll);
	};

	const preloadNextImage = (fromIndex: number) => {
		let nextSelectedIndex = fromIndex + 1;
		if (nextSelectedIndex % files.length === 0) {
			nextSelectedIndex = 0;
		}
		const img = new Image();
		img.src = files[nextSelectedIndex].webUrl ?? '';
	};

	const goToNext = (scroll?: boolean, smoothScroll = false) => {
		let newSelectedIndex = selectedIndex + 1;
		if (newSelectedIndex % files.length === 0) {
			newSelectedIndex = 0;
		}
		if (scroll) {
			scrollToFilmstripIndex(newSelectedIndex, smoothScroll);
		} else {
			setSelectedIndex(newSelectedIndex);
			preloadNextImage(newSelectedIndex);
		}
	};

	const scrollToFilmstripIndex = (selectedIndex, smoothScroll) => {
		setSelectedIndex(selectedIndex);
		preloadNextImage(selectedIndex);
		const fileElement = document.getElementById(`#img-${selectedIndex}`);
		if (fileElement) {
			setTimeout(() => {
				fileElement.scrollIntoView({
					behavior: smoothScroll ? 'smooth' : 'auto',
					block: 'nearest',
					inline: 'center'
				});
			}, 1);
		}
	};

	if (!selectedFile) {
		return <UILoadingPage />;
	}

	const renderFile = () => {
		if (selectedFile.type !== FILE_TYPE_VIDEO) {
			const dimensions = getDimensionsToFitContainer(selectedFile, containerBounds);

			return (
				<div className='uiFilmstrip__main--wrapper'>
					<TransformWrapper
						defaultScale={1}
						options={{
							minScale: 1,
							maxScale: 4,
							limitToWrapper: true,
							limitToBounds: true
						}}
						defaultPositionX={0}
						defaultPositionY={0}>
						<TransformComponent>
							<PLImage
								borderRadius={6}
								alt={selectedFile.filename}
								renderImageOverlay={() => renderImageOverlay(selectedFile)}
								style={{ maxWidth: '100%', maxHeight: '100%', alignItems: 'center' }}
								imageStyle={{ maxHeight: containerBounds?.height, opacity: disableImage && disableImage(selectedFile) ? 0.3 : 1 }}
								width={selectedFile.width ? dimensions.width : 'unset'}
								height={selectedFile.height ? dimensions.height : 'unset'}
								src={selectedFile[fileSize]}
								imageClassName='uiFilmstrip__main--image'
								thumbnailSrc={selectedFile.thumbnailUrl}
							/>
						</TransformComponent>
					</TransformWrapper>
				</div>
			);
		}
		return <UIVideo indService controls key={selectedFile.id} video={selectedFile} src={selectedFile.thumbnailUrl} className='uiMultiImageSelect__image' />;
	};

	const renderFilmstrip = () => {
		const renderFilmstripFile = (imageFile, index) => {
			if (imageFile.type !== FILE_TYPE_VIDEO) {
				const filmstripDimensions = getDimensionsToFitContainer(imageFile, { width: 152, height: 152 });
				return (
					<PLImage
						id={'#img-' + index}
						key={imageFile.id}
						onClick={() => scrollToFilmstripIndex(index, !isSafari && !isMobileSafari)}
						alt={imageFile.filename}
						borderRadius={3}
						width={filmstripDimensions.width}
						height={filmstripDimensions.height}
						thumbnailSrc={selectedFile.thumbnailUrl}
						style={{
							maxWidth: '100%',
							maxHeight: '100%',
							alignItems: 'center',
							minWidth: filmstripDimensions.width
						}}
						imageStyle={{ minWidth: filmstripDimensions.width, maxHeight: 152, maxWidth: 152 }}
						className={`uiFilmstrip__filmstrip__images-img ${selectedFile.id === imageFile.id ? 'uiFilmstrip__filmstrip__images-img-selected' : ''}`}
						src={imageFile.thumbnailUrl}
					/>
				);
			}
			return (
				<div
					style={{ maxWidth: 140 }}
					onClick={() => scrollToFilmstripIndex(index, !isSafari && !isMobileSafari)}
					className={`uiFilmstrip__filmstrip__images-video ${selectedFile.id === imageFile.id ? 'uiFilmstrip__filmstrip__images-video-selected' : ''}`}>
					<UIVideo indService key={imageFile.id} video={imageFile} src={imageFile.thumbnailUrl} width={140} />
				</div>
			);
		};
		return (
			<div id={'#filmstrip'} className='uiFilmstrip__filmstrip'>
				{noGradient ? undefined : <div className='uiFilmstrip__filmstrip--leftGradient' />}
				{noGradient ? undefined : <div className='uiFilmstrip__filmstrip--rightGradient' />}
				<div className='uiFilmstrip__filmstrip__images no-scrollbar'>{files.map(renderFilmstripFile)}</div>
			</div>
		);
	};

	return (
		<div id={'#filmstrip'} className={`${containerClassName ?? ''} uiFilmstrip`} style={style}>
			<Measure
				bounds
				onResize={(contentRect: Object | any) => {
					setContainerBounds(contentRect.bounds);
				}}>
				{({ measureRef }: Function | any) => (
					<div ref={measureRef} className='uiFilmstrip__main'>
						{renderFile()}
					</div>
				)}
			</Measure>

			<div className='uiFilmstrip__header'>
				{
					<UIFilmstripControl
						autoMute={isSafari || isMobileSafari || autoMute}
						audioFile={audioFile}
						autoplaySeconds={files.length < 2 ? undefined : autoplaySeconds}
						goToNext={goToNext}
						selectedIndex={selectedIndex}
					/>
				}
				<p>
					{selectedIndex + 1}/{files.length}
				</p>
			</div>
			{renderFilmstrip()}
		</div>
	);
}
