export const changeClient = 'Change the client on this service';
export const selectNewClient = 'Please select a new client';
export const noClient = 'No client found with that name';
export const clientNamePlaceholder = 'Client name...';
export const save = 'save';
export const goToListingButton = 'go to listing page';
export const clientLabel = 'client';
export const changeClientWarning =
	'Warning: This will make the listing client different from this service client. If you wish to change the client on all of the listing services, please change the client on the listing page.';
export const noClientWarning = 'no client';
export const errorSelectedClient = 'no client selected';
export const successSelectedClient = 'Successfully changed client on service';
export const clientHover = 'edit client';
