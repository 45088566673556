import gql from 'graphql-tag';

export const UPDATE_PROPERTY = gql`
	mutation EditPropertyUpdate(
		$propertyId: String!
		$streetAddress: String
		$address: String
		$unitNumber: String
		$zipCode: String
		$alias: String
		$city: String
		$state: String
		$longitude: Float
		$latitude: Float
		$addressComponents: JSON
		$googlePlaceId: String
		$country: String
	) {
		updateProperty(
			id: $propertyId
			streetAddress: $streetAddress
			address: $address
			unitNumber: $unitNumber
			zipCode: $zipCode
			alias: $alias
			city: $city
			state: $state
			latitude: $latitude
			longitude: $longitude
			addressComponents: $addressComponents
			googlePlaceId: $googlePlaceId
			country: $country
		) {
			id
			address
			unitNumber
			alias
		}
	}
`;
