import React, { Component } from 'react';
import './ui-region-row.scss';
import { connect } from 'react-redux';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../utils/Helpers/AppHelper';

class UIRegionRow extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	//Mark: Render functions
	render() {
		const { region, onRemove } = this.props;

		return (
			<div key={region.id} onClick={onRemove} className='regionRow'>
				<p className='regionRow--name'>{region.name}</p>
			</div>
		);
	}
}

export default connect(commonMapStateToProps, commonMapDispatchToProps)(UIRegionRow);
