// Created by carsonaberle at 3/10/22
import React, { useState } from 'react';
import './styles';
import UIModal from '../../UI/UIModal';
import UIOverlayCard from '../../UI/UIOverlayCard';
import UILabeledInput from '../../UI/UILabeledInput';
import UIIconButton from '../../UI/UIIconButton';
import { UI_ICON_BUTTON_FILL_TYPE } from '../../UI/UIIconButton/config';
import * as EmailValidator from 'email-validator';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { REQUEST_PASSWORD_CHANGE } from './queries';
import { gqlErrorReplace } from '../../../utils/Helpers/APIHelper';

export default function ForgotPasswordModal({ condition, hideModal }: ForgotPasswordModalProps) {
	const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [requestPasswordReset] = useMutation(REQUEST_PASSWORD_CHANGE, {
		onError: (error) => {
			toast.error(gqlErrorReplace(error.message));
		}
	});

	const isPasswordValid = (showInfo?: boolean) => {
		if (forgotPasswordEmail.trim() === '') {
			showInfo && toast.info('Please enter your email.');
			return false;
		} else if (!EmailValidator.validate(forgotPasswordEmail.toLowerCase().trim())) {
			showInfo && toast.info('Please enter a valid email.');
			return false;
		}
		return true;
	};

	const resetPassword = async () => {
		setIsLoading(true);

		const response = await requestPasswordReset({ variables: { email: forgotPasswordEmail.toLowerCase().trim() } });

		if (response?.data?.resetPassword) {
			toast.success('Successfully reset password. Check your email.');
			hideModal();
		}
		setIsLoading(false);
	};

	return (
		<UIModal condition={condition}>
			<UIOverlayCard
				hideModal={hideModal}
				title={'Forgot password.'}
				description={'Enter your email below and we will email you instructions on how to recover your account.'}>
				<UILabeledInput onChangeValue={setForgotPasswordEmail} value={forgotPasswordEmail} type={'text'} placeholder={'Your email...'} />
				<div className='uiOverlayCard__buttons' style={{ marginTop: 24 }}>
					<UIIconButton
						dark
						isLoading={isLoading}
						type={UI_ICON_BUTTON_FILL_TYPE}
						text={'reset password'}
						onClick={resetPassword}
						isDisabled={!isPasswordValid()}
					/>
				</div>
			</UIOverlayCard>
		</UIModal>
	);
}
