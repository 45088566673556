import React from "react";
import "./ui-hover.scss";
import { Tooltip } from "react-tippy";
import { UIHoverProps } from "./types";

const UIHover = ({ onClick, children, text, className, isDisabled, style, maxWidth = "100%" }: UIHoverProps) => {
   return (
      <div className={`plHover ${className}`} style={style} onClick={onClick}>
         {/*// @ts-ignore*/}
         <Tooltip
            disabled={isDisabled}
            title={text}
            className={`plHover ${className}`}
            animateFill={true}
            position={"top"}
            theme={"light"}
            size={"big"}
            style={{ maxWidth }}
            trigger="mouseenter">
            {children}
         </Tooltip>
      </div>
   );
};

export default UIHover;
