// Created by jarongallo at 6/17/21
import React, { useEffect, useState } from 'react';
import * as strings from './strings';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import './styles';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import UIIconButton from '../../../../../../components/UI/UIIconButton';
import { EditIcon, PlusIcon, Trashcan } from '../../../../../../assets/icons';
import UIModal from '../../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../../components/UI/UIOverlayCard';
import UILabeledInput from '../../../../../../components/UI/UILabeledInput';
import { toast } from 'react-toastify';
import UIIcon from '../../../../../../components/UI/UIIcon';
import UIHover from '../../../../../../components/UI/UIHover/UIHover';
import { UI_LABELED_INPUT_CURRENCY_TYPE } from '../../../../../../components/UI/UILabeledInput/config';
import { GET_SERVICE_SURGE, UPDATE_SERVICE_SURGE_BOOST } from './queries';
import { CHILD_TYPE } from '../../../../../../components/UI/UIListInputRow/config';
import { UI_ICON_BUTTON_FILL_TYPE } from '../../../../../../components/UI/UIIconButton/config';

export default function PLServiceAdminSurge({ serviceId }: PLServiceAdminSurgeProps) {
	const [isAddingSurge, setIsAddingSurge] = useState<boolean>(false);
	const [surgeAmount, setSurgeAmount] = useState<number | null>(null);
	const [surgeBoost, setSurgeBoost] = useState<number | null>(null);
	const [addSurgeModal, setAddSurgeModal] = useState(false);
	const [service, setService] = useState<ServiceType>();

	const [updateSurge] = useMutation(UPDATE_SERVICE_SURGE_BOOST);

	const [getServiceWithSurge, { data: serviceData }] = useLazyQuery(GET_SERVICE_SURGE, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getServiceWithSurge();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setService(serviceData['service']);
				setSurgeBoost(serviceData['service'].surgeBoost);
			}
		})();
	}, [serviceData]);

	if (!service) return null;

	const addSurge = async () => {
		if (!isAddingSurge) {
			setIsAddingSurge(true);
			if (!surgeAmount) {
				toast.info(strings.noSurgeAmountString);
				setIsAddingSurge(false);
			} else {
				let variables = {
					serviceId: serviceId,
					surgeBoost: parseInt(String(100 * surgeAmount), 10)
				};
				const response = await updateSurge({ variables });
				const { updateService } = response['data'];
				if (updateService) {
					setIsAddingSurge(false);
					setAddSurgeModal(false);
					toast.success(`Successfully added a surge amount of $${surgeAmount}`);
					await getServiceWithSurge();
				}
			}
		}
	};

	const renderAddSurgeModal = () => {
		const hideModal = () => {
			setAddSurgeModal(false);
		};
		return (
			<UIModal condition={addSurgeModal}>
				<UIOverlayCard hideModal={hideModal} title={strings.addSurgeOverlayTitle} description={strings.addSurgeOverlayDescription}>
					<UILabeledInput
						onChangeNumberValue={setSurgeAmount}
						label={strings.addSurgeLabel}
						containerStyle={{ marginTop: 8 }}
						prefix={'$'}
						value={surgeAmount ? surgeAmount : null}
						type={UI_LABELED_INPUT_CURRENCY_TYPE}
						placeholder={strings.addSurgePlaceholder}
					/>
					<div className='plServiceAdminBoost__button'>
						<UIIconButton dark type={UI_ICON_BUTTON_FILL_TYPE} isLoading={isAddingSurge} text={strings.addSurgeButton} onClick={addSurge} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const removeSurge = async () => {
		let variables = {
			serviceId: serviceId,
			surgeBoost: null
		};
		const response = await updateSurge({ variables });
		const { updateService } = response['data'];
		if (updateService) {
			toast.success(strings.removedSurgeString);
			await getServiceWithSurge();
		}
	};

	const editClickEvent = () => {
		if (surgeBoost !== null) {
			let newSurgeAmount = surgeBoost / 100;
			setSurgeAmount(newSurgeAmount);
			setAddSurgeModal(true);
		}
	};
	const renderRightItems = () => {
		return (
			<div>
				{surgeBoost !== null ? (
					<div className='plServiceAdminBoost__rightItems'>
						<UIHover text='delete surge'>
							<UIIcon className='plServiceAdminBoost__rightItems--icons' iconSrc={Trashcan.white} onClick={removeSurge} alt={'remove'} />
						</UIHover>
						<UIHover text='edit surge'>
							<UIIcon className='plServiceAdminBoost__rightItems--icons' iconSrc={EditIcon.white} onClick={editClickEvent} alt={'edit'} />
						</UIHover>
					</div>
				) : (
					<UIHover text='add surge'>
						<UIIcon className='plServiceAdminBoost__rightItems--icons' iconSrc={PlusIcon.white} onClick={() => setAddSurgeModal(true)} alt={'add'} />
					</UIHover>
				)}
			</div>
		);
	};

	const renderSurge = () => {
		return (
			<UIListInputRow type={CHILD_TYPE} label={strings.addSurgeBoostLabel} rightItems={renderRightItems()}>
				<div className='plServiceAdminBoost__container'>
					{surgeBoost !== null ? (
						<p className='plServiceAdminBoost__container--title'>{'$' + surgeBoost / 100}</p>
					) : (
						<p className='plServiceAdminBoost--empty'>No surge boost...</p>
					)}
				</div>
			</UIListInputRow>
		);
	};

	return (
		<div>
			{renderSurge()}
			{renderAddSurgeModal()}
		</div>
	);
}
