// Created by carsonaberle at 3/24/22
import React, { useEffect } from 'react';
import * as S from './styles';
import { generatePath, useHistory } from 'react-router-dom';

export default function VTSectionBar({ sections, match, listingId }: VTSectionBarProps) {
	const history = useHistory();

	useEffect(() => {
		const section = sections.filter((section) => section.path === match.params.page)[0];
		document.getElementById(`#${section?.value}`)?.scrollIntoView({ block: 'center', inline: 'center' });
	}, []);
	const renderBar = (section: VirtualTourSection) => {
		if (!section.separator) return null;

		switch (section.separator) {
			case 'left':
			case 'right':
				return <S.div_VTSectionBarSeparator />;
			default:
				return null;
		}
	};

	return (
		<S.div_VTSectionBarContainer>
			{sections.map((section) => {
				const itemElementId = `#${section.value}`;
				const StyledItem = match.params.page === section.path ? S.div_VTSectionBarItemSelected : S.div_VTSectionBarItem;
				const openNewSection = () => {
					const path = generatePath(match.path, { id: listingId, page: section.path });
					history.replace(path);
					document.getElementById(itemElementId)?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
				};

				return (
					<S.div_VTSectionBarWrapper key={section.value} id={itemElementId}>
						<StyledItem onClick={openNewSection}>
							<S.p_VTSectionBarItemText>{section.label}</S.p_VTSectionBarItemText>
						</StyledItem>
						{renderBar(section)}
					</S.div_VTSectionBarWrapper>
				);
			})}
		</S.div_VTSectionBarContainer>
	);
}
