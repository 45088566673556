import initialState from '../initialState';
import * as types from './types';

export default function actionItemsReducer(state = initialState.actionItems, action) {
	switch (action.type) {
		case types.SET_SERVICES_REQUIRING_PROOFING:
			return { ...state, servicesRequiringProofing: action.servicesRequiringProofing };
		case types.SET_PENDING_SCHEDULE_REQUESTS:
			return { ...state, scheduleRequests: action.scheduleRequests };
		default:
			return state;
	}
}
