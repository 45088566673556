// noinspection SpellCheckingInspection

export const SOURCES = {
	US_ZIP_CODES: {
		type: 'vector',
		url: 'mapbox://carsonaberle.0bf85dc5'
	}
};
export const BASE_STYLE = 'mapbox://styles/carsonaberle/ck0fsz2rn022i1cof1qlokom2';
export const DARK_STYLE = 'mapbox://styles/carsonaberle/ckcky7wct04xu1io7x1q9ykl5';
export const ZIP_CODES_STYLE = 'mapbox://styles/carsonaberle/cjtqi1z1807jj1fo6gceoqr3s';
