import React from 'react';
import { convertCents } from '../../../../../../../utils/Helpers/StringHelpers';
import './styles';
import * as strings from './strings';
import { getOriginalPriceFromBookableOption, getTotalPriceFromBookableOption } from '../../../../../../../components/UI/UIBookableOption/helpers';

export default function ReviewOption({ bookableOption, id }: ReviewOptionProps) {
	const { option } = bookableOption;

	const fullPrice = getOriginalPriceFromBookableOption(bookableOption);
	let discountPrice = getTotalPriceFromBookableOption(bookableOption);

	const isDiscounted = discountPrice < fullPrice;

	return (
		<div key={`review-option-${id}`} className='reviewOption'>
			{option.coverFile && (
				<img key={option.id} width={96} height={96} alt={option.name} className='reviewOption--coverFile' src={option.coverFile.thumbnailUrl} />
			)}
			<div className='reviewOption__content'>
				<p className='reviewOption--name'>
					{bookableOption.selectedQuantity > 1 ? `${bookableOption.selectedQuantity} x ` : ''}
					{option.name}
				</p>
				{isDiscounted && <p className='reviewOption--description'>{strings.discountAmount(convertCents(fullPrice - discountPrice))}</p>}
			</div>
			<p className='reviewOption--price'>{convertCents(getTotalPriceFromBookableOption(bookableOption))}</p>
		</div>
	);
}
