import React, { useState } from 'react';
import * as S from './styles';
import { useSelector } from 'react-redux';
import UIModal from '../UIModal';
import UIOverlayCard, { OVERLAY_CLOSE_TYPES } from '../UIOverlayCard';
import { convertToUrl } from '../../../utils/Helpers/StringHelpers';
import { getSignedInUserSelector } from '../../../redux/selectors/CurrentUserSelectors';

export default function UIAvailableOptions({ bookedOptions, onClick, className, showPayPerOption }: UIAvailableOptionsProps) {
	const signedInUser = useSelector(getSignedInUserSelector);

	const [selectedOption, setSelectedOption] = useState<BookedOptionType>();

	const renderInstructionsModal = () => {
		if (!selectedOption) return null;

		const hideModal = () => {
			setSelectedOption(undefined);
		};

		return (
			<UIModal condition={true}>
				<UIOverlayCard hideModal={hideModal} title={`Option Instructions: ${selectedOption.name}`} closeType={OVERLAY_CLOSE_TYPES.back}>
					<S.p_UIAvailableOptionInstructions>{selectedOption.option[`${signedInUser.role}Instructions`]}</S.p_UIAvailableOptionInstructions>
					{selectedOption.option.exampleUrl && (
						<S.a_UIAvailableOptionsExample href={convertToUrl(selectedOption.option.exampleUrl)} target={'_blank'}>
							view example
						</S.a_UIAvailableOptionsExample>
					)}
				</UIOverlayCard>
			</UIModal>
		);
	};
	const getPay = (option) => {
		let price = option.photographerPay;
		price = (price / 100).toFixed(2);

		return price;
	};

	const onClickShowOptionInfoModal = (bookedOption: BookedOptionType) => {
		setSelectedOption(bookedOption);
	};

	const renderBookedOption = (bookedOption) => {
		const onClickInstructions = () => onClickShowOptionInfoModal(bookedOption);

		return (
			<S.div_UIAvailableOptionsContainer onClick={onClick} key={bookedOption.id}>
				{showPayPerOption && <S.p_UIAvailableOptionPay>{bookedOption.photographerPay === 0 ? '' : `$${getPay(bookedOption)}`}</S.p_UIAvailableOptionPay>}
				{bookedOption.name && <S.p_UIAvailableOptionName>{bookedOption.name}</S.p_UIAvailableOptionName>}
				{bookedOption.option && bookedOption.option[`${signedInUser.role}Instructions`] && (
					<S.p_UIAvailableOptionInstructions onClick={onClickInstructions}>see instructions</S.p_UIAvailableOptionInstructions>
				)}
			</S.div_UIAvailableOptionsContainer>
		);
	};

	return (
		<S.div_UIAvailableOptionsWrapper className={className}>
			{showPayPerOption && (
				<S.div_UIAvailableOptionsContainer>
					<S.p_UIAvailableOptionPay>$30</S.p_UIAvailableOptionPay>
					<S.p_UIAvailableOptionName>Base pay</S.p_UIAvailableOptionName>
				</S.div_UIAvailableOptionsContainer>
			)}

			<S.div_UIAvailableOptionOptions>{bookedOptions.map(renderBookedOption)}</S.div_UIAvailableOptionOptions>
			{renderInstructionsModal()}
		</S.div_UIAvailableOptionsWrapper>
	);
}
