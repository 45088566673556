import { toast } from 'react-toastify';

export function showSuccessMessage(message) {
	return () => {
		if (message) {
			toast.success(message);
		}
	};
}

export function showInfoMessage(message) {
	return () => {
		if (message) {
			toast.info(message.replace('GraphQL error: ', ''));
		}
	};
}

export function showErrorMessage(message) {
	return () => {
		if (message) {
			toast.error(message.replace('GraphQL error: ', ''));
		}
	};
}
