// Created by carsonaberle at 12/16/21
import React, { useEffect, useState } from 'react';
import './styles';
import UIModal from '../../../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../../../components/UI/UIOverlayCard';
import { convertCents } from '../../../../../../../utils/Helpers/StringHelpers';
import UIIconButton from '../../../../../../../components/UI/UIIconButton';
import { Elements } from '@stripe/react-stripe-js';
import InjectedCheckoutForm from '../../../../BookingManager/components/BookingPay/PaymentRequestForm/UIPaymentRequestForm';
import Queries from '../../../../../../../utils/API/Queries';
import { toast } from 'react-toastify';
import { useApolloClient } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_PROOF_OPTION, GET_USER_PROMOS, PROOF_SHOOT } from '../ServiceProofingCart/queries';
import { setUpBookableOption } from '../../../../EditOptions/config';
import { getPLStripeKey, getProofOptionByEnvironment } from '../../../../../../../utils/Helpers/AppHelper';
import { loadStripe } from '@stripe/stripe-js/pure';
import { UI_ICON_BUTTON_FILL_TYPE } from '../../../../../../../components/UI/UIIconButton/config';

const stripePromise = loadStripe(getPLStripeKey());

export default function ServiceProofingPay({
	clientId,
	service,
	getAmountRequiringPurchase,
	onProofingSuccess,
	listingId,
	selectedImages
}: ServiceProofingPayProps) {
	const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
	const [isPaying, setIsPaying] = useState<boolean>(false);
	const [isFinalizing, setIsFinalizing] = useState<boolean>(false);
	const [proofOption, setProofOption] = useState<BookableOptionType>();
	const [clientPromos, setClientPromos] = useState<PromoType[]>();

	const [getClientPromos, { data: promosData }] = useLazyQuery(GET_USER_PROMOS, { variables: { id: clientId } });
	const [proofShoot] = useMutation(PROOF_SHOOT, { onError: (error) => toast.error(error.message) });
	const [getProofOption, { data: proofOptionData }] = useLazyQuery(GET_PROOF_OPTION, { variables: { id: getProofOptionByEnvironment(service) } });

	const client = useApolloClient();
	const history = useHistory();

	useEffect(() => {
		(async () => {
			getClientPromos();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (promosData) {
				const fetchedPromos: PromoType[] = promosData['promos'];
				setClientPromos(fetchedPromos);
				getProofOption();
			}
		})();
	}, [promosData]);

	useEffect(() => {
		(async () => {
			if (proofOptionData) {
				const fetchedOption: OptionType = proofOptionData['option'];
				if (fetchedOption) {
					setProofOption(setUpBookableOption(fetchedOption, clientPromos));
				}
			}
		})();
	}, [proofOptionData]);

	const isPaymentRequired = getAmountRequiringPurchase() > 0;

	const getTotalPrice = () => {
		if (!proofOption) return;
		let proofPrice = proofOption.option.price / 100;

		let finalPrice = proofPrice * getAmountRequiringPurchase() * 100;
		if (finalPrice < 0) {
			finalPrice = 0;
		}

		return Math.ceil(finalPrice);
	};

	const onPaymentError = () => {
		setIsPaying(false);
		toast.error('Error during payment!');
	};

	const getPaymentIntent = async () => {
		let amountRequiringPurchase = getAmountRequiringPurchase();

		const requestedOptions = [proofOption];

		let appliedPromoIds = requestedOptions
			.map((option) => {
				return option?.discountCalculator?.greatestPromo;
			})
			.flat()
			.filter((greatestDiscount) => greatestDiscount?.promo)
			.map((appliedPromo) => appliedPromo?.promo?.id);

		const response = await client
			.mutate({
				mutation: Queries.CREATE_PAYMENT_INTENT,
				variables: {
					id: service.id,
					currency: 'USD',
					stripeBookingTotal: getTotalPrice(),
					requestedOptions: requestedOptions.map((bookableOption) => {
						return {
							id: bookableOption?.option.id,
							quantity: amountRequiringPurchase
						};
					}),
					promos: appliedPromoIds
				}
			})
			.catch(() => {
				toast.error('Error validating payment');
				setIsPaying(false);
				return {};
			});
		const { createPaymentIntent } = response['data'];
		if (createPaymentIntent && createPaymentIntent.validated) {
			return { clientSecret: createPaymentIntent.client_secret };
		} else {
			toast.error('Error validating payment');
			setIsPaying(false);
			return {};
		}
	};

	const proofService = async () => {
		const proofingResponse = await proofShoot({
			variables: {
				serviceId: service.id,
				proofedFiles: selectedImages.map((file) => {
					return { id: file.id, index: file.index };
				})
			}
		});

		if (proofingResponse?.data['proofService']) {
			await onProofingSuccess();
			setIsFinalizing(false);
			history.push(`/listing/${listingId}?photos`);
		}
	};

	const onPaymentComplete = async (paymentIntent) => {
		if (!isFinalizing) {
			setIsFinalizing(true);
		}

		const amountRequiringPurchase = getAmountRequiringPurchase();

		if (paymentIntent && amountRequiringPurchase > 0) {
			await upgradeServiceOptions(paymentIntent);
		}

		await proofService();
	};

	const upgradeServiceOptions = async (paymentIntent) => {
		if (!proofOption) return;

		let newProofOption = { quantity: getAmountRequiringPurchase(), id: proofOption.option.id };

		const upgradeResponse = await client
			.mutate({
				mutation: Queries.UPGRADE_SHOOT,
				variables: {
					serviceId: service.id,
					requestedOptions: [newProofOption],
					stripeBookingTotal: newProofOption.quantity * proofOption.option.price,
					stripePaymentToken: paymentIntent.id
				}
			})
			.catch((error) => {
				setIsFinalizing(false);
				toast.error('Error: ' + error.message);
			});

		const { upgradeService } = upgradeResponse['data'];
		if (!upgradeService) {
			setIsFinalizing(false);
			toast.error('We had an error upgrading this service!');
		}
	};

	const renderPayModal = () => {
		const hideModal = () => setShowPaymentModal(false);
		const amountRequiringPurchase = getAmountRequiringPurchase();

		if (!proofOption) return null;

		return (
			<UIModal condition={showPaymentModal}>
				<UIOverlayCard title={'Purchase your proofed photos'} hideModal={hideModal}>
					<p className='serviceProofingPay--breakdown'>
						{amountRequiringPurchase} image{amountRequiringPurchase > 1 ? 's' : ''} x {convertCents(proofOption.option.price)} ={' '}
						{convertCents(getTotalPrice())}
					</p>
					{getTotalPrice() === 0 ? (
						<div className='amtRequired'>
							<UIIconButton
								className='proofHeader__counts--item--button'
								isLoading={isFinalizing}
								type={UI_ICON_BUTTON_FILL_TYPE}
								onClick={onPaymentComplete}
								text={'finalize order'}
							/>
						</div>
					) : (
						<Elements stripe={stripePromise}>
							<InjectedCheckoutForm
								amount={getTotalPrice()}
								isPaying={isPaying}
								updateIsPaying={setIsPaying}
								getPaymentIntent={getPaymentIntent}
								onPaymentSuccess={onPaymentComplete}
								onPaymentError={onPaymentError}
							/>
						</Elements>
					)}
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderPay = () => {
		if (!proofOption) return null;

		return isPaymentRequired ? (
			<UIIconButton dark className='serviceProofingPay--button' isLoading={isFinalizing} text={'Pay'} onClick={() => setShowPaymentModal(true)} />
		) : (
			<UIIconButton dark className='serviceProofingPay--button' isLoading={isFinalizing} text={'Approve'} onClick={onPaymentComplete} />
		);
	};

	return (
		<div className='serviceProofingPay'>
			{renderPay()}
			{renderPayModal()}
		</div>
	);
}
