// Created by jarongallo at 6/10/21
import React, { useEffect, useState } from 'react';
import './styles';
import { useSelector } from 'react-redux';
import { EDIT_TYPE_SERVICE } from '../../../../../../utils/Config/Constants';
import { CameraWhite, EditPhotoIcon } from '../../../../../../assets/icons';
import { getReadableStatus } from '../../../../../../utils/Helpers/PaymentHelper/PaymentHelper';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import * as strings from './strings';
import { useLazyQuery } from '@apollo/react-hooks';
import { getIsUserAdminSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { GET_SERVICE_WITH_STATUS } from './queries';
import { CHILD_TYPE } from '../../../../../../components/UI/UIListInputRow/config';
import ChangeServiceStageModal from '../../../../../../components/modals/ChangeServiceStageModal';
import ChangeServiceTypeModal from '../../../../../../components/modals/ChangeServiceTypeModal';
import PLImage from '../../../../../../components/PL/PLImage';

export default function PLServiceStatus({ serviceId }: PLServiceStatusProps) {
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const [service, setService] = useState<ServiceType>();
	const [showChangeStageModal, setShowChangeStageModal] = useState<boolean>(false);
	const [showChangeTypeModal, setShowChangeTypeModal] = useState<boolean>(false);

	const [getService, { data: serviceData }] = useLazyQuery(GET_SERVICE_WITH_STATUS, { variables: { id: serviceId } });

	useEffect(() => {
		(async () => {
			await getService();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setService(serviceData['service']);
			}
		})();
	}, [serviceData]);

	if (!service) return null;

	const renderChangeTypeModal = () => {
		const hideModal = () => {
			setShowChangeTypeModal(false);
		};
		return <ChangeServiceTypeModal service={service} condition={showChangeTypeModal} hideModal={hideModal} onComplete={getService} />;
	};

	const renderChangeStatusModal = () => {
		const hideModal = () => {
			setShowChangeStageModal(false);
		};
		return <ChangeServiceStageModal service={service} condition={showChangeStageModal} hideModal={hideModal} onComplete={getService} />;
	};

	const renderServiceType = () => {
		const clickEnabledClassName = isUserAdmin ? 'plServiceStatus--clickable' : '';

		const onClickType = () => {
			if (!isUserAdmin) return;
			setShowChangeTypeModal(true);
		};

		const onClickStage = () => {
			if (!isUserAdmin) return;
			setShowChangeStageModal(true);
		};

		const typeItem =
			service.type === EDIT_TYPE_SERVICE ? { icon: EditPhotoIcon.white, alt: strings.editTypeAlt } : { icon: CameraWhite, alt: strings.captureTypeAlt };

		return (
			<div className='plServiceStatus__info'>
				<PLImage
					style={{ marginLeft: 12 }}
					src={typeItem.icon}
					alt={typeItem.alt}
					className={`plServiceStatus__info--type ${clickEnabledClassName}`}
					onClick={onClickType}
				/>
				<p className={`plServiceStatus__info--status ${clickEnabledClassName}`} onClick={onClickStage}>
					{getReadableStatus(service.status)}
				</p>
			</div>
		);
	};

	return (
		<UIListInputRow label={strings.serviceTypeAndStage} type={CHILD_TYPE}>
			{renderServiceType()}
			{renderChangeTypeModal()}
			{renderChangeStatusModal()}
		</UIListInputRow>
	);
}
