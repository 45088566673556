import React from 'react';
import './styles';
import UIHover from '../UIHover/UIHover';
import { UINotificationBannerProps } from './types';

export default function UINotificationBanner({ className, text, children, backgroundColor, hoverText, onClick, textStyle }: UINotificationBannerProps) {
	return (
		<div
			className={`uiNotificationBanner ${onClick ? 'uiNotificationBanner--clickable' : ''} ${className} `}
			style={onClick ? { cursor: 'pointer', backgroundColor } : { backgroundColor }}
			onClick={onClick}>
			<UIHover text={hoverText} style={{ width: '100%' }}>
				<p className='uiNotificationBanner--text' style={textStyle}>
					{text ? text : children}
				</p>
			</UIHover>
		</div>
	);
}
