import { CERTIFICATION_TYPES } from '../../Config/Constants';

export const PHOTO_TYPE: PackageType = {
	packageType: 'photo',
	associatedCertification: CERTIFICATION_TYPES.dslr,
	title: 'Photography services',
	description: 'Highlight your listing with crystal clear professional images'
};

export const DRONE_TYPE: PackageType = {
	packageType: 'droneMedia',
	associatedCertification: CERTIFICATION_TYPES.droneMedia,
	title: 'Drone media services',
	description: 'Perfect for highlighting the area around your property'
};
export const VIDEO_TYPE: PackageType = {
	packageType: 'video',
	associatedCertification: CERTIFICATION_TYPES.video,
	title: 'Videography services',
	description: 'Highlight your listing with crystal clear professional images'
};
export const FLOOR_PLAN_TYPE: PackageType = {
	packageType: 'floorplan',
	associatedCertification: CERTIFICATION_TYPES.floorPlan,
	title: 'Floor plan services',
	description: 'Show the layout of your property in a classic way'
};
export const TECHNICIAN_FLOOR_PLAN_TYPE: PackageType = {
	packageType: 'technicianFloorPlan',
	associatedCertification: CERTIFICATION_TYPES.floorPlan,
	title: 'Self-Service Floor Plans',
	description: 'Scan and generate floor plans using your styles device'
};
export const MATTERPORT_TYPE: PackageType = {
	packageType: 'matterport',
	associatedCertification: CERTIFICATION_TYPES.matterport,
	title: 'Matterport services',
	description: 'Show your property from any angle with a 360 tour'
};
export const DEDUCTION_TYPE: PackageType = { packageType: 'deduction', associatedCertification: undefined, title: 'Deductions' };
export const ADDITIONAL_PHOTOS_TYPE: PackageType = {
	packageType: 'additionalPhotos',
	associatedCertification: CERTIFICATION_TYPES.dslr,
	title: 'Additional Photos'
};
export const ADDITIONAL_DRONE_TYPE: PackageType = {
	packageType: 'additionalDrone',
	associatedCertification: CERTIFICATION_TYPES.droneMedia,
	title: 'Additional Drone Media'
};
export const ADMIN_TYPE: PackageType = { packageType: 'admin', associatedCertification: undefined, title: 'Admin type' };
export const TRAINING_TYPE: PackageType = { packageType: 'training', associatedCertification: undefined, title: 'Training' };
export const EDIT_TYPE: PackageType = {
	packageType: 'edit',
	associatedCertification: undefined,
	title: 'RAW Image Edits',
	description: 'Make your edits stand out using our Fusion editing technology'
};
export const PROOF_TYPE: PackageType = { packageType: 'proof', associatedCertification: undefined, title: 'Proofing' };
export const ADD_ON_TYPE: PackageType = { packageType: 'addOn', associatedCertification: undefined, title: 'Add-ons' };
export const LIGHTING_TYPE: PackageType = { packageType: 'lighting', associatedCertification: undefined, title: 'Lighting' };
export const VIRTUAL_STAGING_TYPE: PackageType = { packageType: 'virtualStaging', associatedCertification: undefined, title: 'Virtual Staging' };
export const EDITING_UPGRADE_TYPE: PackageType = { packageType: 'editUpgrade', associatedCertification: undefined, title: 'Editing upgrades' };

export const getPackageTypesList = () => {
	return [
		EDITING_UPGRADE_TYPE,
		VIRTUAL_STAGING_TYPE,
		PROOF_TYPE,
		LIGHTING_TYPE,
		ADD_ON_TYPE,
		EDIT_TYPE,
		TRAINING_TYPE,
		ADMIN_TYPE,
		ADDITIONAL_DRONE_TYPE,
		ADDITIONAL_PHOTOS_TYPE,
		DEDUCTION_TYPE,
		MATTERPORT_TYPE,
		TECHNICIAN_FLOOR_PLAN_TYPE,
		FLOOR_PLAN_TYPE,
		VIDEO_TYPE,
		DRONE_TYPE,
		PHOTO_TYPE
	];
};

export const getPackageTypesFromOptions = (bookableOptions: BookableOptionType[]): PackageType[] => {
	const packageTypesResult: PackageType[] = [];
	const packageTypesList = getPackageTypesList();
	for (let bookableOption of bookableOptions) {
		let packageType = packageTypesList.filter((optionGroup) => optionGroup.packageType === bookableOption.option.packageType)[0];
		if (packageType && !packageTypesResult.includes(packageType)) {
			packageTypesResult.push(packageType);
		}
	}
	return packageTypesResult;
};
