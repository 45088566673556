import styled from 'styled-components';

export const div_VTPhotosContainer = styled.div`
	//DEFAULT
	max-width: calc(min(100%, 2400px));
	flex: 1 1 100%;
	max-height: 100%;
	align-items: center;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTPhotosList = styled.div`
	//DEFAULT
	width: 100%;
	overflow-y: scroll;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100%;
	justify-content: space-between;
	padding-top: 12px;
	padding-bottom: 24px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		width: 100%;
		padding-bottom: 24px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		justify-content: center;
		padding: 12px 20px 24px 20px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTPhotosItem = styled.div`
	//DEFAULT
	width: 100%;
	max-width: 100%;
	justify-content: center;
	align-items: center;
	padding: 0 0 12px 0;

	&:first-of-type {
		padding-top: 0;
	}
	&:last-of-type {
		padding-bottom: 0;
		align-self: flex-start;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		padding: 0 0 24px 0;
		max-width: calc(50% - 12px);

		&:last-of-type {
			padding-bottom: 24px;
		}
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		padding: 0 0 24px 0;
		margin: 0 12px;
		max-width: calc(50% - 48px);

		&:first-of-type {
			padding-top: 0;
		}
		&:last-of-type {
			padding-bottom: 24px;
		}
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const img_VTPhotosItemImage = styled.img<{ taller: boolean }>`
	//DEFAULT
	width: fit-content;
	max-width: 100%;
	height: auto;
	max-height: 100%;
	border-radius: 6px;
	cursor: pointer;
	transition: 150ms all ease-out;
	-webkit-animation: fadeIn 500ms ease-in !important;
	animation: fadeIn 500ms ease-in !important;
	user-select: none;

	&:active {
		transform: translateY(1px);
		opacity: 0.96;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		border-radius: 12px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		border: transparent solid 3px;
		width: ${(props) => (props.taller ? 'unset' : '100%')};
		max-height: 640px;
		border-radius: 18px;

		&:hover {
			transform: translateY(-1px);
			border-color: #12121218;
		}
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
