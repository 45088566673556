import styled from 'styled-components';

export const div_VTFloorPlansContainer = styled.div`
	//DEFAULT
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 12px 0 24px 0;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		padding: 12px 0 36px 0;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		padding: 12px 0 48px 0;
	}

	//MONITOR
	@media only screen and (min-width: 1500px) {
	}
`;

export const div_VTFloorPlansFloor = styled.div`
	//DEFAULT
	width: 100%;
	align-items: center;
	transition: 100ms all linear;

	&:last-of-type {
		& > div {
			margin-bottom: 0;
		}
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		width: 50%;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const div_VTFloorPlansItem = styled.div`
	//DEFAULT
	width: 100%;
	background-color: #ffffff;
	border-radius: 12px;
	margin: 0 0 12px 0;
	padding: 0 24px;
	border: #1212121f solid 2px;
	cursor: pointer;
	transition: all 200ms ease-out;
	min-height: 240px;
	-webkit-animation: fadeIn 500ms ease-in !important;
	animation: fadeIn 500ms ease-in !important;
	max-height: 360px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		width: 88%;
		max-height: 420px;
		min-height: 360px;
		margin: 0 0 24px 0;
		border: #1212121f solid 3px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		max-height: 420px;
		border-radius: 24px;
		width: 88%;
		min-height: 360px;
		margin: 0 0 24px 0;
		border: #1212121f solid 3px;

		&:hover {
			transform: translateY(-1px);
		}

		&:active {
			transform: translateY(1px);
			opacity: 0.9;
		}
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const img_VTFloorPlansImage = styled.img`
	//DEFAULT
	max-height: 100%;
	object-fit: contain;
	max-width: 100%;
	-webkit-animation: fadeIn 500ms ease-in !important;
	animation: fadeIn 500ms ease-in !important;
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const p_VTFloorPlansFloorTitle = styled.p`
	//DEFAULT
	margin: 12px 0;
	font-family: BrownBold, sans-serif;
	color: #121212;
	font-size: 14pt;
	-webkit-animation: fadeIn 500ms ease-in !important;
	animation: fadeIn 500ms ease-in !important;
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		font-size: 16pt;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
