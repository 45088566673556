import gql from 'graphql-tag';

export const GET_SERVICE_WITH_PROPERTY = gql`
	query Service($id: String!) {
		service(id: $id) {
			id
			property {
				id
				unitNumber
				address
				addressComponents
				alias
				baths
				beds
				city
				country
				googlePlaceId
				latitude
				longitude
				state
				streetAddress
				squareFootage
				timezone
				type
				unitNumber
				yearBuilt
				zipCode
				services {
					id
					status
					coverFile {
						thumbnailUrl
					}
				}
			}
		}
	}
`;
