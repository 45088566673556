// Created by carsonaberle at 11/23/21
import React from 'react';
import './styles';
import { SERVICE_TYPE_ITEM_EDIT, SERVICE_TYPE_ITEM_PHOTOSHOOT } from '../../../pages/portal/global/Service/PLServiceComponents/PLServiceStatus/config';
import UIModal from '../../UI/UIModal';
import UIOverlayCard from '../../UI/UIOverlayCard';
import * as strings from './strings';
import UIIcon from '../../UI/UIIcon';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_SERVICE_TYPE } from './queries';

export default function ChangeServiceTypeModal({ service, condition, hideModal, onComplete }: ChangeServiceTypeModalProps) {
	const [mutateServiceType] = useMutation(UPDATE_SERVICE_TYPE);

	const changeServiceType = async (type: string) => {
		let variables = {
			serviceId: service.id,
			type
		};

		const response = await mutateServiceType({ variables });
		if (response?.data?.updateService) {
			await onComplete();
			toast.success(strings.onChangeTypeString);
		}
	};

	const renderServiceType = (serviceStatus: ServiceTypeItem) => {
		return (
			<div
				key={serviceStatus.type}
				className={`changeServiceTypeModal--item ${service.type === serviceStatus.type ? 'changeServiceTypeModal--item-selected' : ''}`}
				onClick={() => changeServiceType(serviceStatus.type)}>
				<div className='changeServiceTypeModal--item-header'>
					<UIIcon className='changeServiceTypeModal--item-icon' iconSrc={serviceStatus.icon} alt={serviceStatus.title} />
					<p className='changeServiceTypeModal--item-title'>{serviceStatus.title}</p>
				</div>
				<p className='changeServiceTypeModal--item-description'>{serviceStatus.description}</p>
			</div>
		);
	};

	const availableTypes: ServiceTypeItem[] = [SERVICE_TYPE_ITEM_PHOTOSHOOT, SERVICE_TYPE_ITEM_EDIT];

	return (
		<UIModal condition={condition}>
			<UIOverlayCard hideModal={hideModal} title={strings.title}>
				<div className='changeServiceTypeModal'>{availableTypes.map(renderServiceType)}</div>
			</UIOverlayCard>
		</UIModal>
	);
}
