//Service Types
import { CheckmarkIcon, InProcessIcon, ProofRed } from '../../assets/icons';

export let PHOTOSHOOT_TYPE_SERVICE: ServiceTypeType = 'photoshoot';
export let EDIT_TYPE_SERVICE: ServiceTypeType = 'edit';

export const serviceTypesList = [
	{ value: PHOTOSHOOT_TYPE_SERVICE, label: PHOTOSHOOT_TYPE_SERVICE },
	{ value: EDIT_TYPE_SERVICE, label: EDIT_TYPE_SERVICE }
];

//Service Requests
export const SERVICE_REQUESTS = {
	type: {
		imageAdjustment: 'imageAdjustment', // Request an edit to an image
		video: 'video', // Request an edit to a video
		virtualTwilight: 'virtualTwilight', // Order a virtual twilight
		floorPlan: 'floorPlan', // Request an edit to a floor plan
		contentCapture: 'contentCapture', // Technicians going to property to capture (Photographers)
		rawImageEdit: 'rawEdit' //Edit raw photos (Editors)
	},
	status: {
		complete: 'complete', //Done
		awaitingAdminApproval: 'awaitingAdminApproval', //Last step before approval
		inProgress: 'inProgress', //Sent to editor
		awaitingAcceptance: 'awaitingAcceptance', //If offered to all techs
		awaitingRequestApproval: 'awaitingRequestApproval' //Awaiting admin approval (agent or photographer requested)
		// awaitingPreferredAcceptance: "awaitingPreferredAcceptance", //If offered to a specific tech
	}
};

//Promos
export let PROMOS = {
	discountTypes: {
		amount: 'amount',
		percentage: 'percentage'
	},
	promoTypes: {
		credit: 'Credit',
		specificOption: 'Specific Option',
		universalDiscount: 'Universal Discount'
	}
};

//Service Status'
export const STATUS_PRE_ORDER = 'preOrder';
export const STATUS_FINDING_TECHNICIAN = 'findingPhotographer';
export const STATUS_FINDING_PREFERRED_TECHNICIAN = 'findingPreferredTechnician';
export const STATUS_TECHNICIAN_UPLOAD = 'photographerUpload';
export const STATUS_FINDING_EDIT_TECHNICIAN = 'awaitingEditing';
export const STATUS_EDITOR_UPLOAD = 'editorUpload';
export const STATUS_REVIEW = 'waitingForReview';
export const STATUS_PROOFING = 'proofing';
export const STATUS_ADMIN_HOLD = 'adminHold';
export const STATUS_LIVE = 'live';
export const STATUS_STANDBY = 'standby';
export const DEPRECATED_STATUS_EDITOR_REDO = 'editorRedo';
export const DEPRECATED_STATUS_RE_EDIT_REVIEW = 'reEditReview';

//User Roles
export let USER_ROLE_ADMIN: UserRoleType = 'admin';
export let USER_ROLE_REGIONAL_MANAGER: UserRoleType = 'regionalManager';
export let USER_ROLE_PHOTOGRAPHER: UserRoleType = 'photographer';
export let USER_ROLE_AGENT: UserRoleType = 'agent';
export let USER_ROLE_EDITOR: UserRoleType = 'editor';
export let USER_ROLE_SALES: UserRoleType = 'sales';
export let USER_ROLE_AFFILIATE: UserRoleType = 'affiliate';
export let USER_ROLE_EDITING_MANAGER: UserRoleType = 'editingCompanyManager';
// export let DEPRECATED_USER_ROLE_EXTERNAL: UserRoleType = 'external';

//File Types
export const FILE_TYPE_IMAGE: FileTypeType = 'image';
export const FILE_TYPE_FLOOR_PLAN: FileTypeType = 'floorplan';
// export const DEPRECATED_FILE_TYPE_ADDITIONAL_RAW_ZIP: FileTypeType = 'additional zip';
export const FILE_TYPE_RAW_IMAGE: FileTypeType = 'raw image';
export const FILE_TYPE_VIDEO_RAW: FileTypeType = 'video zip';
export const FILE_TYPE_VIDEO: FileTypeType = 'video';
export const FILE_TYPE_AUDIO: FileTypeType = 'audio';
// export const FILE_TYPE_SOCIAL_MEDIA = 'socialMedia';

// export const DEPRECATED_FILE_TYPE_RAW_ZIP = 'raw zip';
// export const DEPRECATED_FILE_TYPE_ADDITIONAL_RAW = 'additional raw image';

//File Status'
export const FILE_STATUS_IN_PROGRESS: FileStatusType = 'inProgress';
export const FILE_STATUS_PROOF: FileStatusType = 'proof';
export const FILE_STATUS_LIVE: FileStatusType = 'live';

//Option Package Types option.packageType
export let PACKAGE_TYPES_DROPDOWN_VALUES = [
	{ label: 'Add-on', value: 'addOn' },
	{ label: 'Admin', value: 'admin' },
	{ label: 'Deduction', value: 'deduction' },
	{ label: 'Drone Media', value: 'droneMedia' },
	{ label: 'Edit Only', value: 'edit' },
	{ label: 'Edit Upgrade', value: 'editUpgrade' },
	{ label: 'Proof', value: 'proof' },
	{ label: 'Exterior', value: 'exterior' },
	{ label: 'Floor plan', value: 'floorplan' },
	{ label: 'Self-scan Floor plan', value: 'technicianFloorPlan' },
	{ label: 'Lighting', value: 'lighting' },
	{ label: 'Matterport', value: 'matterport' },
	{ label: 'Photos', value: 'photo' },
	{ label: 'Video', value: 'video' },
	{ label: 'Virtual Staging', value: 'virtualStaging' },
	{ label: 'Floor plan redraw', value: 'floorPlanRedraw' },
	{ label: 'Site plan', value: 'sitePlan' },
	{ label: 'Property line mapping', value: 'propertyLineMapping' },
	{ label: 'Animated slideshow video', value: 'animatedSlideshowVideo' },
	{ label: 'Rendering', value: 'rendering' }
];

//Technician Certification Types technician.certificates
export const CERTIFICATION_TYPES = {
	dslr: 'dslr',
	video: 'video',
	droneMedia: 'droneMedia',
	DEPRECATED_dronePhoto: 'dronePhoto',
	DEPRECATED_droneVideo: 'droneVideo',
	matterport: 'matterport',
	floorPlan: 'floorplan',
	promoFloorPlan: 'promoFloorPlan',
	dslrTrainer: 'dslrTrainer',
	videoTrainer: 'videoTrainer',
	dronePhotoTrainer: 'dronePhotoTrainer',
	droneVideoTrainer: 'droneVideoTrainer',
	matterportTrainer: 'matterportTrainer',
	floorPlanTrainer: 'floorplanTrainer',
	socialMediaVerticalVideo: 'socialMediaVerticalVideo'
};

//Option Types
export const OPTION_TYPES = {
	addOn: 'addOn',
	admin: 'admin',
	deduction: 'deduction',
	drone: 'drone',
	edit: 'edit',
	editUpgrade: 'editUpgrade',
	floorPlan: 'floorplan',
	lighting: 'lighting',
	listing: 'listing',
	marketing: 'marketing',
	matterport: 'matterport',
	photo: 'photo',
	proof: 'proof',
	video: 'video',
	twilightUpgrade: 'twilightUpgrade',
	virtualStaging: 'virtualStaging',
	virtualTwilight: 'virtualTwilight'
};

export const OPTION_ADDITIONAL_INFO_TYPES = {
	quantity: 'quantity',
	text: 'text',
	address: 'address',
	none: 'none',
	quantityAndNotes: 'quantityAndNotes'
};

export const OPTION_ADDITIONAL_INFO_TYPES_DROPDOWN = [
	{ label: 'None', value: OPTION_ADDITIONAL_INFO_TYPES.none },
	{ label: 'Quantity', value: OPTION_ADDITIONAL_INFO_TYPES.quantity },
	{ label: 'Address', value: 'address' },
	{ label: 'Notes', value: OPTION_ADDITIONAL_INFO_TYPES.text },
	{ label: 'Quantity & Notes', value: OPTION_ADDITIONAL_INFO_TYPES.quantityAndNotes }
];

//Login tokens
export let TOKEN_KEY = 'LOCAL_STORAGE_TOKEN';
export let SUPER_USER_TOKEN_KEY = 'LOCAL_STORAGE_SUPER_USER_TOKEN';
export let ID_KEY = 'LOCAL_STORAGE_ID';
export let ROLE_KEY = 'LOCAL_STORAGE_ROLE';
export let LOCATION_KEY = 'LOCATION_KEY';
//Right Panel Types
export let RIGHT_PANEL_TYPE_SERVICES = 'Services';

export const INVITED_TO_PINEAPPLE = 'invitedToPineapple';

export let SCREEN_TYPE = {
	short: 'short',
	mobile: 'mobile',
	tablet: 'tablet',
	laptop: 'laptop',
	desktop: 'desktop',
	monitor: 'monitor'
};

export let TABLE_SERVICE_FLAGS = {
	needsApproval: { text: 'needs approval', className: 'tableService__flags--approval' },
	standby: { text: 'standby', className: 'tableService__flags--standby' },
	editorRedo: { text: 'editor redo', className: 'tableService__flags--standby' },
	training: { text: 'training', className: 'tableService__flags--training' }
};

//Sorting
export let SORT_SERVICE_DATE = 'Service Date';

export let OPTION_TYPES_DROPDOWN_VALUES = [
	{ label: 'Add-on', value: OPTION_TYPES.addOn },
	{ label: 'Admin', value: OPTION_TYPES.admin },
	{ label: 'Deduction', value: OPTION_TYPES.deduction },
	{ label: 'Drone', value: OPTION_TYPES.drone },
	{ label: 'Edit Only', value: OPTION_TYPES.edit },
	{ label: 'Edit Upgrade', value: OPTION_TYPES.editUpgrade },
	{ label: 'Floor plan', value: OPTION_TYPES.floorPlan },
	{ label: 'Lighting', value: OPTION_TYPES.lighting },
	{ label: 'Marketing', value: OPTION_TYPES.marketing },
	{ label: 'Matterport', value: OPTION_TYPES.matterport },
	{ label: 'Photo', value: OPTION_TYPES.photo },
	{ label: 'Proof', value: OPTION_TYPES.proof },
	{ label: 'Video', value: OPTION_TYPES.video },
	{ label: 'Virtual Staging', value: OPTION_TYPES.virtualStaging },
	{ label: 'Virtual Twilight', value: OPTION_TYPES.virtualTwilight },
	{ label: 'Twilight', value: OPTION_TYPES.twilightUpgrade }
];

export let OPTION_PHOTOGRAPHER_CERTIFICATIONS_DROPDOWN_VALUES = [
	{ label: 'DSLR', value: CERTIFICATION_TYPES.dslr },
	{ label: 'Video', value: CERTIFICATION_TYPES.video },
	{ label: 'Drone Media', value: CERTIFICATION_TYPES.droneMedia },
	{ label: 'Matterport', value: CERTIFICATION_TYPES.matterport },
	{ label: 'Social Media Vertical Video', value: CERTIFICATION_TYPES.socialMediaVerticalVideo },
	{ label: 'Floor plan', value: CERTIFICATION_TYPES.floorPlan }
	// { label: 'Drone Photos', value: CERTIFICATION_TYPES.DEPRECATED_dronePhoto },
	// { label: 'Drone Videos', value: CERTIFICATION_TYPES.DEPRECATED_droneVideo },
];

export let OPTION_EDITOR_CERTIFICATIONS_DROPDOWN_VALUES = [
	{ label: 'DSLR', value: 'dslr' },
	{ label: 'Video', value: 'video' },
	{ label: 'VTSlideshow Video', value: 'slideshowVideo' },
	{ label: 'Virtual Staging', value: 'virtualStaging' },
	{ label: 'Virtual Dusk', value: 'virtualDusk' },
	{ label: 'Premium Editing', value: 'premiumEditing' },
	{ label: 'Floor Plans', value: 'floor plans' },
	{ label: 'Social Media Vertical Video', value: 'socialMediaVerticalVideo' },
	{ label: 'Grass Replacement', value: 'grassReplacement' }
];

export const FILE_STATUS_OPTIONS = [
	{
		value: 'inProgress',
		label: 'In Progress',
		description: 'When a file is still being worked on and is not ready to be sent to the client.',
		icon: InProcessIcon.white
	},
	{ value: 'proof', label: 'Ready for proofing', description: 'When a file needs payment or approval.', icon: ProofRed },
	{ value: 'live', label: 'Live', description: 'When a file is completed and delivered to the client.', icon: CheckmarkIcon.white }
];
