import React, { useEffect, useState } from 'react';
import './styles/mobile.scss';
import './styles/tablet.scss';
import './styles/desktop.scss';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import UIIcon from '../../UI/UIIcon';
import {
	FILE_STATUS_IN_PROGRESS,
	FILE_STATUS_LIVE,
	FILE_STATUS_PROOF,
	FILE_TYPE_FLOOR_PLAN,
	FILE_TYPE_IMAGE,
	FILE_TYPE_VIDEO
} from '../../../utils/Config/Constants';
import { getFileTransferApiUrl } from '../../../utils/Helpers/AppHelper';
import UICheckbox from '../../UI/UICheckbox/UICheckbox';
import { DownloadIcon } from '../../../assets/icons';
import UIOverlayCard from '../../UI/UIOverlayCard';
import { getIsUserAdminSelector } from '../../../redux/selectors/CurrentUserSelectors';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_DOWNLOAD_FILES } from './queries';

function DownloadModal({ listingId, serviceId, hideModal, individualFile, downloadFilename }: UIDownloadProps) {
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const [allFiles, setAllFiles] = useState<FileType[]>();
	const [isLoading, setIsLoading] = useState(true);
	const [includePhotos, setIncludePhotos] = useState(false);
	const [includeFloorPlans, setIncludeFloorPlans] = useState(false);
	const [includeVideos, setIncludeVideos] = useState(false);
	const [includeUnpurchased, setIncludeUnpurchased] = useState(false);

	let variables: { limit: number; query: { serviceId?: string; listingId?: string } } = {
		limit: 1000,
		query: {}
	};

	if (serviceId) {
		variables.query.serviceId = serviceId;
	} else if (listingId) {
		variables.query.listingId = listingId;
	}

	const [getFiles, { data: filesData }] = useLazyQuery(GET_DOWNLOAD_FILES, {
		variables
	});

	useEffect(() => {
		(async () => {
			await getFiles();
		})();
	}, []);

	useEffect(() => {
		if (filesData) {
			setAllFiles(filesData['files']);
			setIsLoading(false);
		}
	}, [filesData]);

	let baseLink = `${getFileTransferApiUrl()}/download?${serviceId ? 'service' : 'listing'}Id=${serviceId ? serviceId : listingId}${
		includeUnpurchased ? '&includeUnpurchased=true' : ''
	}${includeFloorPlans ? '&includeFloorplans=true' : ''}${includeVideos ? '&includeVideos=true' : ''}${includePhotos ? '&includePhotos=true' : ''}`;

	const getFileTypesFromService = (types: FileTypeType[]) => {
		if (!allFiles) return [];
		return allFiles.filter((file) => types.includes(file.type));
	};

	const getVisibleFilesByStatusesAndTypes = (statuses: FileStatusType[], types: FileTypeType[]): FileType[] => {
		return getFilesByStatusesAndTypes(statuses, types).filter((file) => file.isVisible);
	};

	const getFilesByStatusesAndTypes = (statuses: FileStatusType[], types: FileTypeType[]): FileType[] => {
		const filteredFiles = getFileTypesFromService(types);
		if (filteredFiles.length === 0) {
			return [];
		} else {
			return filteredFiles.filter((file) => statuses.includes(file.status));
		}
	};

	const renderPhotosSection = () => {
		const visibleFiles = getVisibleFilesByStatusesAndTypes([FILE_STATUS_LIVE], [FILE_TYPE_IMAGE]);
		if (!visibleFiles || visibleFiles.length === 0) return null;
		return (
			<UICheckbox
				label={`${visibleFiles.length} Photos`}
				className='downloadModal__checkbox'
				status={includePhotos ? UICheckbox.CHECKED : UICheckbox.UNCHECKED}
				onChangeExpansion={() => setIncludePhotos(!includePhotos)}
			/>
		);
	};

	const renderVideosSection = () => {
		const visibleFiles = getVisibleFilesByStatusesAndTypes([FILE_STATUS_LIVE], [FILE_TYPE_VIDEO]);
		if (!visibleFiles || visibleFiles.length === 0) return null;
		return (
			<UICheckbox
				label={`${visibleFiles.length} Videos`}
				className='downloadModal__checkbox'
				status={includeVideos ? UICheckbox.CHECKED : UICheckbox.UNCHECKED}
				onChangeExpansion={() => setIncludeVideos(!includeVideos)}
			/>
		);
	};

	const renderFloorPlansSection = () => {
		const visibleFiles = getVisibleFilesByStatusesAndTypes([FILE_STATUS_LIVE], [FILE_TYPE_FLOOR_PLAN]);
		if (!visibleFiles || visibleFiles.length === 0) return null;
		return (
			<UICheckbox
				label={`${visibleFiles.length} Floor Plans`}
				className='downloadModal__checkbox'
				status={includeFloorPlans ? UICheckbox.CHECKED : UICheckbox.UNCHECKED}
				onChangeExpansion={() => setIncludeFloorPlans(!includeFloorPlans)}
			/>
		);
	};

	const renderAdminSection = () => {
		if (!isUserAdmin) return;
		const unpurchasedFiles = getFilesByStatusesAndTypes([FILE_STATUS_IN_PROGRESS, FILE_STATUS_PROOF], [FILE_TYPE_IMAGE, FILE_TYPE_VIDEO, FILE_TYPE_FLOOR_PLAN]);
		const hiddenLiveFiles = getFilesByStatusesAndTypes([FILE_STATUS_LIVE], [FILE_TYPE_IMAGE, FILE_TYPE_VIDEO, FILE_TYPE_FLOOR_PLAN]).filter(
			(file) => !file.isVisible
		);

		const aggregate = [...unpurchasedFiles, ...hiddenLiveFiles];

		if (!aggregate || aggregate.length === 0) return null;
		return (
			<UICheckbox
				label={`Include ${aggregate.length} additional hidden or not purchased file${aggregate.length > 0 ? 's' : ''}`}
				className='downloadModal__checkbox'
				status={includeUnpurchased ? UICheckbox.CHECKED : UICheckbox.UNCHECKED}
				onChangeExpansion={() => setIncludeUnpurchased(!includeUnpurchased)}
			/>
		);
	};

	if (individualFile) {
		const { webUrl, printUrl, originalUrl } = individualFile;
		return (
			<UIOverlayCard hideModal={hideModal} title={'Download file'}>
				<div className='downloadModal'>
					{webUrl && (
						<div className='downloadModal__download--item' onClick={() => saveAs(webUrl, individualFile.filename)}>
							<UIIcon alt={'web'} iconSrc={DownloadIcon.white} className='downloadModal__download--item--icon' />
							<p className='downloadModal__download--item--type'>web | normal</p>
							<p className='downloadModal__download--item--size'>2000px/720p</p>
						</div>
					)}
					{printUrl && (
						<div className='downloadModal__download--item' onClick={() => saveAs(printUrl, individualFile.filename)}>
							<UIIcon alt={'print'} iconSrc={DownloadIcon.white} className='downloadModal__download--item--icon' />
							<p className='downloadModal__download--item--type'>print | large</p>
							<p className='downloadModal__download--item--size'>3500px/1080p</p>
						</div>
					)}
					{originalUrl && (
						<div className='downloadModal__download--item' onClick={() => saveAs(originalUrl, individualFile.filename)}>
							<UIIcon alt={'original'} iconSrc={DownloadIcon.white} className='downloadModal__download--item--icon' />
							<p className='downloadModal__download--item--type'>original | extra-large</p>
							<p className='downloadModal__download--item--size'>{'>= 3500px/1080p'}</p>
						</div>
					)}
				</div>
			</UIOverlayCard>
		);
	}

	const hasContent: boolean = !!getFilesByStatusesAndTypes([FILE_STATUS_LIVE], [FILE_TYPE_IMAGE, FILE_TYPE_VIDEO, FILE_TYPE_FLOOR_PLAN])?.[0];

	return (
		<UIOverlayCard
			hideModal={hideModal}
			title={'Download your content'}
			isLoading={isLoading}
			description={
				hasContent
					? 'Select the content you would like to include in your download.'
					: 'This listing has no content yet. Upload some or order media services to get started.'
			}>
			{renderAdminSection()}
			{renderPhotosSection()}
			{renderVideosSection()}
			{renderFloorPlansSection()}
			<div className='downloadModal'>
				{hasContent && (
					<div>
						<a key={'web-size'} href={`${baseLink}&size=web`} download={downloadFilename} className='downloadModal__download--item'>
							<UIIcon alt={'web'} iconSrc={DownloadIcon.white} className='downloadModal__download--item--icon' />
							<p className='downloadModal__download--item--type'>web | normal</p>
							<p className='downloadModal__download--item--size'>2000px/720p</p>
						</a>
						<a key={'print-size'} href={`${baseLink}&size=print`} download={downloadFilename} className='downloadModal__download--item'>
							<UIIcon alt={'print'} iconSrc={DownloadIcon.white} className='downloadModal__download--item--icon' />
							<p className='downloadModal__download--item--type'>print | large</p>
							<p className='downloadModal__download--item--size'>3500px/1080p</p>
						</a>
						<a key={'original-size'} href={`${baseLink}&size=original`} download={downloadFilename} className='downloadModal__download--item'>
							<UIIcon alt={'original'} iconSrc={DownloadIcon.white} className='downloadModal__download--item--icon' />
							<p className='downloadModal__download--item--type'>original | extra-large</p>
							<p className='downloadModal__download--item--size'>{'>= 3500px/1080p'}</p>
						</a>
					</div>
				)}
			</div>
		</UIOverlayCard>
	);
}

export default DownloadModal;
