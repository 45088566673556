import {
	ADMIN_AFFILIATES,
	ADMIN_AGENTS,
	ADMIN_EDITING_COMPANIES,
	ADMIN_HOME,
	ADMIN_INVITE_CODES,
	ADMIN_LISTINGS,
	ADMIN_PAYMENT,
	ADMIN_REGIONS,
	ADMIN_SERVICE_REQUESTS,
	ADMIN_SERVICES,
	ADMIN_TECHNICIANS,
	AFFILIATE_AGENTS,
	AFFILIATE_INVITES,
	AFFILIATE_PAYMENT,
	MARKETING_SITE,
	AGENT_LISTINGS,
	AGENT_PENDING_SERVICES,
	BRAND_BOOK,
	CONTACT,
	EDITING_MANAGER_EDITORS,
	EDITING_MANAGER_HOME,
	EDITING_MANAGER_PENDING,
	EDITOR_SERVICE_REQUESTS,
	EDITOR_SERVICES,
	EXTERNAL_VIRTUAL_TOUR,
	HOME_PREPARATION,
	INDIVIDUAL_AGENT,
	INDIVIDUAL_EDITING_COMPANY,
	INDIVIDUAL_EDITOR,
	INDIVIDUAL_INVITE_CODE,
	INDIVIDUAL_LISTING,
	INDIVIDUAL_REGION,
	INDIVIDUAL_SALESPERSON,
	INDIVIDUAL_SERVICE_REQUEST,
	INDIVIDUAL_TECHNICIAN,
	PRIVACY_POLICY,
	SALESPERSON_AGENTS,
	SALESPERSON_INVITES,
	SALESPERSON_PAYMENT,
	SIGNUP,
	TECHNICIAN_EDUCATION,
	TECHNICIAN_LISTINGS,
	TECHNICIAN_PINEAPPLE_SERVICES,
	TECHNICIAN_STRIPE_SIGNUP,
	TERMS_OF_USE,
	VIRTUAL_TOUR
} from './RoutesItems';

export default [
	ADMIN_HOME,
	ADMIN_SERVICES,
	ADMIN_TECHNICIANS,
	ADMIN_AGENTS,
	ADMIN_EDITING_COMPANIES,
	ADMIN_REGIONS,
	ADMIN_AFFILIATES,
	ADMIN_INVITE_CODES,
	ADMIN_PAYMENT,
	ADMIN_LISTINGS,
	ADMIN_SERVICE_REQUESTS,
	AGENT_LISTINGS,
	AGENT_PENDING_SERVICES,
	EDITOR_SERVICE_REQUESTS,
	EDITOR_SERVICES,
	EDITING_MANAGER_HOME,
	EDITING_MANAGER_PENDING,
	EDITING_MANAGER_EDITORS,
	SALESPERSON_AGENTS,
	SALESPERSON_INVITES,
	SALESPERSON_PAYMENT,
	AFFILIATE_AGENTS,
	AFFILIATE_INVITES,
	AFFILIATE_PAYMENT,
	TECHNICIAN_STRIPE_SIGNUP,
	TECHNICIAN_PINEAPPLE_SERVICES,
	TECHNICIAN_LISTINGS,
	TECHNICIAN_EDUCATION,
	INDIVIDUAL_AGENT,
	INDIVIDUAL_TECHNICIAN,
	INDIVIDUAL_EDITING_COMPANY,
	INDIVIDUAL_SERVICE_REQUEST,
	INDIVIDUAL_EDITOR,
	INDIVIDUAL_SALESPERSON,
	INDIVIDUAL_REGION,
	INDIVIDUAL_INVITE_CODE,
	INDIVIDUAL_LISTING,
	VIRTUAL_TOUR,
	CONTACT,
	HOME_PREPARATION,

	PRIVACY_POLICY,
	TERMS_OF_USE,
	BRAND_BOOK,
	EXTERNAL_VIRTUAL_TOUR,

	MARKETING_SITE,
	SIGNUP
];
