import React, { Component } from 'react';
import './ui-button.scss';
import Lottie from 'react-lottie';
import { connect } from 'react-redux';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../utils/Helpers/AppHelper';

class UIButton extends Component {
	static CANCEL_TYPE = 'cancelType';
	static FILL_TYPE = 'fillType';
	static OUTLINE_TYPE = 'outlineType';

	constructor(props) {
		super(props);

		this.state = {
			text: this.props.text,
			isLoading: this.props.isLoading,
			isDisabled: this.props.isDisabled !== undefined ? this.props.isDisabled : false,
			type: this.props.type !== undefined ? this.props.type : UIButton.OUTLINE_TYPE
		};
	}

	static getDerivedStateFromProps(nextProps) {
		return {
			text: nextProps.text,
			isLoading: nextProps.isLoading
		};
	}

	//Mark: Component functions
	getButtonClassnameFromType = () => {
		switch (this.state.type) {
			case UIButton.FILL_TYPE:
				return this.state.isDisabled ? 'pineapple-button-fill-disabled' : 'pineapple-button-fill';
			case UIButton.OUTLINE_TYPE:
				return this.state.isDisabled ? 'pineapple-button-outline-disabled' : 'pineapple-button-outline';
			case UIButton.CANCEL_TYPE:
				return this.state.isDisabled ? 'pineapple-button-cancel-disabled' : 'pineapple-button-cancel';
			default:
				return undefined;
		}
	};

	getLoadingButtonClassnameFromType = () => {
		switch (this.state.type) {
			case UIButton.FILL_TYPE:
				return 'pineapple-button-fill-disabled';
			case UIButton.OUTLINE_TYPE:
				return 'pineapple-button-outline-disabled';
			case UIButton.CANCEL_TYPE:
				return 'pineapple-button-cancel-disabled';
			default:
				return undefined;
		}
	};

	//Mark: Render functions

	render() {
		const { className, isLoading, style, text, isDisabled, key, disabledErrorMessage } = this.props;

		if (!isLoading) {
			return (
				<div
					className={className}
					style={{ width: 'fit-content', display: 'table', ...style }}
					onClick={isDisabled ? () => this.props.showSuccessMessage(disabledErrorMessage) : this.props.onClick}>
					<p key={isLoading} style={{ display: 'inline-flex' }} className={this.getButtonClassnameFromType()}>
						{text}
					</p>
				</div>
			);
		} else {
			return (
				<div
					key={`${isLoading}${isDisabled}${key}`}
					className={`${className} ${this.getLoadingButtonClassnameFromType()}`}
					style={{ width: 'fit-content', display: 'table', ...style }}>
					<Lottie
						options={{
							loop: true,
							autoplay: true,
							path: 'https://res.cloudinary.com/pineapple-labs/raw/upload/v1592767290/portal-assets/loading-circle_kxpoc7.json',
							rendererSettings: {
								preserveAspectRatio: 'xMidYMid slice'
							}
						}}
						height={24}
						width={24}
						isStopped={false}
						isPaused={false}
					/>
				</div>
			);
		}
	}
}

export default connect(commonMapStateToProps, commonMapDispatchToProps)(UIButton);
