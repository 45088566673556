// Created by carsonaberle at 11/13/21
import React, { useState } from 'react';
import './styles';
import { toast } from 'react-toastify';
import * as strings from '../PLServiceAdminRibbons/strings';
import { clearSidebar } from '../../../../../../redux/store/control/actions';
import UIModal from '../../../../../../components/UI/UIModal';
import ServiceProofing from '../../../IndividualService/ServiceProofing';
import { useDispatch, useSelector } from 'react-redux';
import UINotificationBanner from '../../../../../../components/UI/UINotificationBanner';
import { STATUS_PROOFING } from '../../../../../../utils/Config/Constants';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';

export default function PLServiceProofing({ service }: PLServiceProofingProps) {
	const signedInUser = useSelector(getSignedInUserSelector);
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const [showProofingModal, setShowProofingModal] = useState(false);

	const dispatch = useDispatch();

	const renderProofingModal = () => {
		const hideModal = () => setShowProofingModal(false);
		const onProofingSuccess = async () => {
			hideModal();
			toast.success(strings.proofingSuccessMessage);
			dispatch(clearSidebar());
		};
		return (
			<UIModal condition={showProofingModal} className='plServiceProofing--fullscreen'>
				<ServiceProofing clientId={service.client.id} serviceId={service.id} onProofingSuccess={onProofingSuccess} hideModal={hideModal} />
			</UIModal>
		);
	};

	if (service.status !== STATUS_PROOFING) return null;
	if (!isUserAdmin && service.client.id !== signedInUser.id) return null;
	return (
		<div className='plServiceProofing'>
			<UINotificationBanner
				key={strings.proofingKey}
				className='plServiceAdminRibbons__ribbon plServiceAdminRibbons__ribbon-red'
				onClick={() => setShowProofingModal(true)}
				backgroundColor={'var(--red-transparent-half)'}
				text={strings.proofingRequired}
				textStyle={{ color: 'var(--red)', fontSize: '14pt' }}
			/>
			{renderProofingModal()}
		</div>
	);
}
