import React, { Component } from 'react';
import './service-photos-sort.scss';
import './service-photos-sort-tablet.scss';
import './service-photos-sort-desktop.scss';
import { connect } from 'react-redux';
import Queries from '../../../../../utils/API/Queries';
import { isUserAdminOrRM } from '../../../../../utils/Helpers/UserHelpers';
import { CloseIcon, SaveIcon, SortImagesIcon } from '../../../../../assets/icons';
import PLSortable from '../../../../../components/PL/PLSortable';
import UIIconButton from '../../../../../components/UI/UIIconButton';
import { SCREEN_TYPE } from '../../../../../utils/Config/Constants';
import { withApollo } from '@apollo/react-hoc';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../../../utils/Helpers/AppHelper';
import PLImage from '../../../../../components/PL/PLImage';
import { getDimensionsToFitContainer } from '../../../../../components/UI/UIMultiImageSelect/config';
import { UI_ICON_BUTTON_FILL_TYPE, UI_ICON_BUTTON_OUTLINE_TYPE } from '../../../../../components/UI/UIIconButton/config';
import { toast } from 'react-toastify';

class ServicePhotosSort extends Component {
	constructor(props) {
		super(props);

		this.state = {
			images: this.props.images,
			selectedItems: []
		};
	}

	//Mark: Component functions
	onBulkSortEnd = async (files) => {
		const { goBack } = this.props;
		this.setState({ isBulkSorting: true });
		const response = await this.props.client.mutate({
			mutation: Queries.UPDATE_FILES,
			variables: {
				requestedUpdates: files
			}
		});
		const { updateFiles } = response['data'];
		if (!updateFiles) {
			toast.error('Error updating order');
		}
		await this.props.saveOrder(updateFiles, false);
		await this.props.updateData();
		this.setState({ isBulkSorting: false }, async () => {
			toast.success('Successfully rearranged all images by filename!');
			goBack();
		});
	};

	onSortEnd = (images) => {
		this.setState({ images });
	};

	rearrangeByFilename = async () => {
		const { images } = this.state;
		let sortedFiles = [];

		for (let i = 0; i < images.length; i++) {
			images[i].index = i;
			sortedFiles.push({ id: images[i].id, index: i, filename: images[i].filename });
		}

		let sortedItems = sortedFiles
			.sort((a, b) => a.filename.localeCompare(b.filename))
			.map((item, index) => {
				return { ...item, index };
			});

		await this.onBulkSortEnd(sortedItems);
	};

	renderItem = (file, maxDimension) => {
		const dimensions = getDimensionsToFitContainer(file, { width: maxDimension, height: maxDimension });

		return (
			<div style={{ padding: 6, marginBottom: 2, marginRight: 2 }} id={file.id} key={file.id}>
				<PLImage
					className='sortPhotos--image'
					width={dimensions.width}
					height={dimensions.height}
					src={file.thumbnailUrl}
					alt={file.filename}
					borderRadius={3}
				/>
			</div>
		);
	};

	render() {
		const { goBack, isSorting } = this.props;
		const { images, isBulkSorting, selectedItems } = this.state;

		return (
			<div className='sortPhotos'>
				<div className='sortPhotos__header'>
					<img onClick={goBack} src={CloseIcon.white} alt={'close'} className='sortPhotos__header--close' />
					<div>
						<p className='sortPhotos__header--title'>Sort your photos</p>
						{selectedItems.length > 0 ? (
							<p className='sortPhotos__header--action'>
								{this.props.control.screenType === SCREEN_TYPE.mobile ? 'tap' : 'click'} another or drag to re-order
							</p>
						) : (
							<p className='sortPhotos__header--action'>{this.props.control.screenType === SCREEN_TYPE.mobile ? 'tap' : 'click'} a photo</p>
						)}
					</div>
				</div>
				<p className='uiOverlayCard__description' style={{ margin: 24 }}>
					Click and drag a photo to change it's position, click an image to select and sort multiple images at once. This order will be reflected in your
					virtual tour and downloads.
				</p>
				<div className='sortPhotos__container'>
					{isUserAdminOrRM(this.props.currentUser.user) && (
						<UIIconButton
							className='sortableHeader__content--admin-edit--sort'
							type={UI_ICON_BUTTON_OUTLINE_TYPE}
							isLoading={isBulkSorting}
							icon={SortImagesIcon.white}
							text={'sort all by filename'}
							onClick={this.rearrangeByFilename}
						/>
					)}
					<UIIconButton
						dark
						iconPadding={4}
						className='sortableHeader__content--admin-edit--sort-save'
						type={UI_ICON_BUTTON_FILL_TYPE}
						isLoading={isSorting}
						icon={SaveIcon.solid.dark}
						text={'save'}
						onClick={() => this.props.saveOrder(images)}
					/>
				</div>
				{images && (
					<div style={{ padding: '0 14px', overflow: 'auto' }}>
						<PLSortable
							imageType={'thumbnailUrl'}
							data={images}
							renderItem={this.renderItem}
							selectedItems={selectedItems}
							updateSelectedItems={(selectedItems) => this.setState({ selectedItems })}
							onSortEnd={this.onSortEnd}
							expand={(selectedImageIndex) => this.setState({ selectedImageIndex })}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default connect(commonMapStateToProps, commonMapDispatchToProps)(withApollo(ServicePhotosSort));
