export const coverFileLabel = 'COVER FILE';
export const coverFilePlaceholder = 'Cover file id...';
export const coverFileClick = 'Enter a new file id to change the cover file';
export const nameLabel = 'NAME';
export const namePlaceholder = 'Name...';
export const pricePrefix = '$';
export const priceLabel = 'PRICE';
export const priceHelp = 'This is the price the agent pays for 1 of these options.';
export const pricePlaceholder = 'price...';
export const fileCountHelp = 'This is the total number of files the agent is told they are getting.';
export const filePlaceholder = '0...';
export const fileLabel = 'FILE UPLOAD COUNT';
export const descriptionLabel = 'DESCRIPTION';
export const descriptionPlaceholder = 'Description...';
export const exampleUrlLabel = 'EXAMPLE URL';
export const exampleUrlPlaceholder = 'Example url...';
export const deliveryLabel = 'DELIVERY TIME ESTIMATE';
export const deliveryPlaceholder = 'Delivery time estimate...';
export const typeLabel = 'TYPE';
export const typeHelp = "This is the option type. This is used to make sure you can't book two of the same type options.";
export const quantityLabel = 'DEFAULT QUANTITY';
export const quantityHelp = 'This is the default number of this option that is selected when first booking.';
export const quantityPlaceholder = 'Quantity...';
export const packageLabel = 'PACKAGE TYPE';
export const packageHelp = 'This is the package type. This is used in booking to group options together.';
export const altCoverText = 'cover file';
export const updateSuccessMessage = (optionName) => `Updated ${optionName}!`;
