// Created by jarongallo at 6/9/21
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles';
import PLImage from '../../../../../../components/PL/PLImage';
import UIIconButton from '../../../../../../components/UI/UIIconButton';
import UIModal from '../../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../../components/UI/UIOverlayCard';
import UIUserSearch from '../../../../../../components/UI/UIUserSearch/UIUserSearch';
import { EDIT_TYPE_SERVICE, PHOTOSHOOT_TYPE_SERVICE, USER_ROLE_AGENT, USER_ROLE_PHOTOGRAPHER } from '../../../../../../utils/Config/Constants';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import UIIcon from '../../../../../../components/UI/UIIcon';
import { EditIcon } from '../../../../../../assets/icons';
import * as strings from './strings';
import { getIsUserAdminSelector, getSignedInUserSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_SERVICE_WITH_CLIENT, UPDATE_SERVICE_CLIENT } from './queries';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import UIHover from '../../../../../../components/UI/UIHover/UIHover';
import { CHILD_TYPE } from '../../../../../../components/UI/UIListInputRow/config';
import CopyAgentMessage from '../PLServiceTechnician/components/CopyAgentMessage';
import { clearSidebar } from '../../../../../../redux/store/control/actions';
import { UI_ICON_BUTTON_FILL_TYPE } from '../../../../../../components/UI/UIIconButton/config';

export default function PLServiceClient({ serviceId }: PLServiceClientProps) {
	const signedInUser = useSelector(getSignedInUserSelector);
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const history = useHistory();
	const dispatch = useDispatch();

	const [updateClient] = useMutation(UPDATE_SERVICE_CLIENT);
	const [changeClientOnService, setChangeClientOnService] = useState(false);
	const [selectedClient, setSelectedClient] = useState<UserType>();
	const [isSaving, setIsSaving] = useState<boolean>();
	const [service, setService] = useState<ServiceType>();

	const [getService, { data: serviceData }] = useLazyQuery(GET_SERVICE_WITH_CLIENT, {
		variables: { id: serviceId }
	});

	useEffect(() => {
		(async () => {
			await getService();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (serviceData) {
				setService(serviceData['service']);
			}
		})();
	}, [serviceData]);

	if (!service) return null;

	const shouldRenderComponentForService = () => {
		return isUserAdmin || signedInUser.role === USER_ROLE_PHOTOGRAPHER;
	};

	if (!shouldRenderComponentForService()) {
		return null;
	}

	const renderChangeClientOnServiceModal = () => {
		const hideModal = () => {
			setChangeClientOnService(false);
			setIsSaving(false);
		};

		const saveClient = async () => {
			if (!selectedClient) {
				toast.error(strings.errorSelectedClient);
			} else {
				let variables = {
					serviceId: serviceId,
					client: selectedClient.id
				};
				const response = await updateClient({ variables });
				const { updateService } = response['data'];

				if (updateService) {
					toast.success(strings.successSelectedClient);
					hideModal();
				}
			}
		};

		const openListingPage = () => {
			history.push(`/listing/${service.listing.id}`);
			dispatch(clearSidebar());
		};

		const renderClientChangeWarning = () => {
			if (selectedClient && service.listing.client && selectedClient.id !== service.listing.client.id) {
				return (
					<div className='plServiceClient--warning'>
						{strings.changeClientWarning}
						<UIIconButton dark text={strings.goToListingButton} className='plServiceClient--warning-link' onClick={openListingPage} />
					</div>
				);
			} else return null;
		};

		return (
			<UIModal condition={changeClientOnService}>
				<UIOverlayCard hideModal={hideModal} title={strings.changeClient} cardStyle={{ height: '100%' }}>
					<UIUserSearch
						emptySetText={strings.noClient}
						role={service.type === EDIT_TYPE_SERVICE ? USER_ROLE_PHOTOGRAPHER : USER_ROLE_AGENT}
						selectedUser={selectedClient}
						placeholder={strings.clientNamePlaceholder}
						onSelectUser={setSelectedClient}
					/>
					{renderClientChangeWarning()}
					<div className='plServiceClient__button'>
						<UIIconButton
							isDisabled={!selectedClient}
							disabledErrorMessage={strings.selectNewClient}
							dark
							isLoading={isSaving}
							text={strings.save}
							type={UI_ICON_BUTTON_FILL_TYPE}
							onClick={saveClient}
						/>
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const formatPhoneNumber = (phoneNumberString) => {
		const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			const intlCode = match[1] ? '+1 ' : '';
			return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
		}
		return null;
	};

	const renderClient = () => {
		const onClickClient = () => {
			if (!service.client || !isUserAdmin) return;
			history.push(`/${service.client.role}/${service.client.id}`);
		};

		if (service.client) {
			return (
				<div className='plServiceClient'>
					<div className={`plServiceClient__container ${service.client && isUserAdmin ? 'plServiceClient__container-hover' : ''}`} onClick={onClickClient}>
						<PLImage
							imageStyle={{ width: '100%', height: '100%', objectFit: 'cover' }}
							borderRadius={16}
							className='plServiceClient__container--image'
							alt={service.client.name}
							src={service.client.image}
						/>
						<p className='plServiceClient__container--text'>{service.client.name}</p>
					</div>
					{signedInUser.role === USER_ROLE_PHOTOGRAPHER && service.client.id !== signedInUser.id && (
						<>
							<p className='plServiceClient__container--email'>{service.client.email}</p>
							<p className='plServiceClient__container--phoneNumber'>{formatPhoneNumber(service.client.phoneNumber)}</p>
						</>
					)}
				</div>
			);
		} else
			return (
				<div className='plServiceClient'>
					<p className='plServiceClient__container--text'>{strings.noClientWarning}</p>
				</div>
			);
	};

	const renderRightItems = () => {
		if (service.type === PHOTOSHOOT_TYPE_SERVICE && service.photographer && service.photographer.id === signedInUser.id) {
			return <CopyAgentMessage service={service} copyForUser={service.photographer} region={service.region} property={service.property} />;
		} else if (isUserAdmin) {
			return (
				<UIHover text={strings.clientHover}>
					<UIIcon className='plServiceClient__edit' iconSrc={EditIcon.white} onClick={() => setChangeClientOnService(true)} alt={'edit'} />
				</UIHover>
			);
		}
		return;
	};

	return (
		<UIListInputRow label={strings.clientLabel} type={CHILD_TYPE} rightItems={renderRightItems()}>
			{renderClient()}
			{renderChangeClientOnServiceModal()}
		</UIListInputRow>
	);
}
