//todo: REFACTOR THIS. COMPONENTS SHOULD BE FUNCTIONAL COMPONENTS THAT QUERY THEIR OWN DATA WITH HOOKS
import Queries from '../../utils/API/Queries';

export const getUser = async (variables, client) => {
	const response = await client.query({
		query: Queries.GET_USER_QUERY,
		variables
	});
	const { user } = response['data'];
	return user;
};

export const updateUser = async (variables, client) => {
	const response = await client.mutate({
		mutation: Queries.UPDATE_USER_MUTATION,
		variables
	});
	const { updateUser } = response['data'];
	if (updateUser) {
		return updateUser;
	} else {
		throw new Error();
	}
};
