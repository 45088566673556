export const setAsyncTimeout = (cb, timeout = 0) =>
	new Promise((resolve) => {
		setTimeout(() => {
			cb();
			resolve();
		}, timeout);
	});

export const nearestPastMinutes = (interval, someMoment) => {
	const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval;
	return someMoment.clone().minute(roundedMinutes).second(0);
};
