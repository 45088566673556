import React, { useState } from 'react';
import './styles';
import {
	EDIT_TYPE_SERVICE,
	FILE_STATUS_IN_PROGRESS,
	FILE_STATUS_LIVE,
	FILE_STATUS_PROOF,
	SCREEN_TYPE,
	USER_ROLE_AFFILIATE,
	USER_ROLE_EDITOR,
	USER_ROLE_PHOTOGRAPHER
} from '../../../../../utils/Config/Constants';
import { ZendeskAPI } from 'react-zendesk';
import { CloseIcon } from '../../../../../assets/icons';
import DownloadModal from '../../../../../components/modals/Download';
import Queries from '../../../../../utils/API/Queries';
import UIModal from '../../../../../components/UI/UIModal';
import UILabeledInput from '../../../../../components/UI/UILabeledInput';
import { getServiceRequestsOnFile, toggleFileVisibility } from '../../../../../utils/Helpers/FileHelper';
import CreateServiceRequestModal from '../../../../../components/modals/CreateServiceRequestModal';
import UIIconButton from '../../../../../components/UI/UIIconButton';
import { camelCaseToSentence } from '../../../../../utils/Helpers/StringHelpers';
import UICard from '../../../../../components/UI/UICard/UICard';
import UIOverlayCard from '../../../../../components/UI/UIOverlayCard';
import UIIcon from '../../../../../components/UI/UIIcon';
import { FULLSCREEN_TYPES, QUICK_ACTIONS_FULLSCREEN } from './config';
import { UI_LABELED_INPUT_TEXT_TYPE } from '../../../../../components/UI/UILabeledInput/config';
import UICheckbox from '../../../../../components/UI/UICheckbox/UICheckbox';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getIsUserAdminSelector } from '../../../../../redux/selectors/CurrentUserSelectors';
import { setSignedInUser } from '../../../../../redux/store/user/actions';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from 'react-apollo';
import UIFilmstrip from '../../../../../components/UI/UIFilmstrip';
import { getScreenTypeSelector } from '../../../../../redux/selectors/ControlSelectors';
import FullscreenFilesQuickAction from './components/FullscreenFilesQuickAction';
import ChangeFileStatusModal from '../../../../../components/modals/ChangeFileStatusModal';
import { UI_ICON_BUTTON_CANCEL_TYPE, UI_ICON_BUTTON_FILL_TYPE, UI_ICON_BUTTON_OUTLINE_TYPE } from '../../../../../components/UI/UIIconButton/config';

export default function FullscreenFiles({
	files,
	type,
	initialSlide,
	updateService,
	goBack,
	service,
	listing,
	renderAdditionalItems,
	disableImage,
	headerComponent,
	renderImageOverlay = () => null
}: FullscreenFilesProps) {
	const isUserAdmin = useSelector(getIsUserAdminSelector);
	const signedInUser = useSelector(setSignedInUser);
	const screenType = useSelector(getScreenTypeSelector);

	const [selectedFileIndex, setSelectedFileIndex] = useState<number>(initialSlide);
	const [floorLevel, setFloorLevel] = useState<string>(files[initialSlide]?.tags?.floorLevel?.toString(10) || '');
	const [isUpdating, setIsUpdating] = useState<object>({});
	const [serviceRequests, setServiceRequests] = useState<ServiceRequestType[]>([]);
	const [filename, setFilename] = useState<string>('');
	const [showChangeFilenameModal, setShowChangeFilenameModal] = useState<boolean>(false);
	const [showChangeFloorPlanSectionModal, setShowChangeFloorPlanSectionModal] = useState<boolean>(false);
	const [showEditFloorLevelModal, setShowEditFloorLevelModal] = useState<boolean>(false);
	const [showChangeStatusModal, setShowChangeStatusModal] = useState<boolean>(false);
	const [deleteFile, setDeleteFile] = useState<boolean>(false);
	const [cubiQuickFix, setCubiQuickFix] = useState<boolean>(false);
	const [fileToEdit, setFileToEdit] = useState<FileType>();
	const [downloadFile, setDownloadFile] = useState<FileType>();

	const history = useHistory();
	const client = useApolloClient();

	const queryForServiceRequestsFromFiles = async () => {
		//Get an array of all services in all files
		const servicesInFiles = files.map((file) => file.service);

		//Query service requests by array of services
		const uniqueServiceIds = [...new Set(servicesInFiles.filter((item) => item !== undefined && item !== null).map((item) => item.id))];

		const response = await client
			?.query({
				query: Queries.GET_SERVICE_REQUESTS,
				variables: {
					query: {
						serviceId: { op_in: uniqueServiceIds }
					}
				}
			})
			.catch((error) => toast.error(error.message));
		const { serviceRequests } = response['data'];
		if (serviceRequests) {
			setServiceRequests(serviceRequests);
		}
	};

	const changeFile = async (type, value, optionalCompletion) => {
		if (!isUpdating[type]) {
			let file = files[selectedFileIndex];
			file[type] = value;
			setIsUpdating({ ...isUpdating, [type]: true });
			const response = await client.mutate({
				mutation: Queries.UPDATE_FILES,
				variables: { requestedUpdates: [{ id: file.id, [type]: value }] }
			});
			const { updateFiles } = response['data'];
			if (updateFiles) {
				setSelectedFileIndex(selectedFileIndex === files.length - 1 ? 0 : selectedFileIndex);
				setIsUpdating({ ...isUpdating, [type]: false });
				// await this.props.updateService();
				if (optionalCompletion) {
					optionalCompletion();
				}
			}
		}
	};

	const changeFloorLevel = async (type, value, optionalCompletion) => {
		if (!isUpdating[type]) {
			let file = files[selectedFileIndex];
			file[type] = value;
			setIsUpdating({ ...isUpdating, [type]: true });
			let updatedTags = { ...file.tags, floorLevel: value };

			const response = await client?.mutate({
				mutation: Queries.UPDATE_FILES,
				variables: { requestedUpdates: [{ id: file.id, tags: updatedTags }] }
			});
			const { updateFiles } = response['data'];
			if (updateFiles) {
				// await this.props.updateService();
				setIsUpdating({ ...isUpdating, [type]: false });
				if (optionalCompletion) {
					optionalCompletion();
				}
			}
		}
	};

	const getQuickActions = () => {
		let currentFile = files[selectedFileIndex];
		let quickActions: FullscreenQuickAction[] = [];

		let serviceRequestsForFile: ServiceRequestType[] = [];
		if (serviceRequests) {
			serviceRequestsForFile = getServiceRequestsOnFile(serviceRequests, currentFile);
		}

		const addDefaultActions = (quickActions) => {
			if (currentFile.isVisible) {
				quickActions.push(QUICK_ACTIONS_FULLSCREEN.VISIBILITY);
			} else {
				quickActions.push(QUICK_ACTIONS_FULLSCREEN.INVISIBILITY);
			}
			quickActions.push(QUICK_ACTIONS_FULLSCREEN.DOWNLOAD);
			if (isUserAdmin) {
				quickActions.push(QUICK_ACTIONS_FULLSCREEN.DELETE);
			}

			if (signedInUser.role === USER_ROLE_EDITOR) {
				quickActions.push(QUICK_ACTIONS_FULLSCREEN.DELETE);
			}

			if (service) {
				if (serviceRequestsForFile.length === 0 && currentFile['allowFixRequest'] === true) {
					quickActions.push(QUICK_ACTIONS_FULLSCREEN.EDIT_REQUEST);
				}
				if (service.type === EDIT_TYPE_SERVICE) {
					if (signedInUser.role === USER_ROLE_PHOTOGRAPHER && service.photographer && service.photographer.id === signedInUser.id) {
						quickActions.push(QUICK_ACTIONS_FULLSCREEN.DELETE);
					}
				}
			} else {
				if (currentFile.service) {
					quickActions.push(QUICK_ACTIONS_FULLSCREEN.EDIT_REQUEST);
				}
				if (listing?.client && listing.client.id === signedInUser.id) {
					quickActions.push(QUICK_ACTIONS_FULLSCREEN.DELETE);
				}
			}

			return quickActions;
		};

		switch (type) {
			case FULLSCREEN_TYPES.SOCIAL_MEDIA_TYPE:
				quickActions.push(QUICK_ACTIONS_FULLSCREEN.DELETE);
				break;
			case FULLSCREEN_TYPES.PROOFING_TYPE:
				quickActions.push(QUICK_ACTIONS_FULLSCREEN.EDIT_REQUEST);
				break;
			case FULLSCREEN_TYPES.FLOOR_PLAN_TYPE:
				if (currentFile['allowFixRequest'] === true && currentFile.type === FULLSCREEN_TYPES.FLOOR_PLAN_TYPE) {
					quickActions.push(QUICK_ACTIONS_FULLSCREEN.FLOOR_PLAN_FIX_REQUEST);
				}
				quickActions.push(QUICK_ACTIONS_FULLSCREEN.FLOOR_PLAN_FLOOR_LEVEL);
				quickActions = addDefaultActions(quickActions);
				break;
			default:
				quickActions = addDefaultActions(quickActions);
				break;
		}
		if (signedInUser.role === USER_ROLE_AFFILIATE) {
			quickActions = [];
		}
		return quickActions;
	};

	const onClickQuickAction = (selectedImage, type) => {
		switch (type) {
			case QUICK_ACTIONS_FULLSCREEN.EDIT_REQUEST.type:
				setFileToEdit(selectedImage);
				break;
			case QUICK_ACTIONS_FULLSCREEN.DELETE.type:
				setDeleteFile(true);
				break;
			case QUICK_ACTIONS_FULLSCREEN.FLOOR_PLAN_FIX_REQUEST.type:
				setCubiQuickFix(true);
				break;
			case QUICK_ACTIONS_FULLSCREEN.FLOOR_PLAN_SECTION.type:
				setShowChangeFloorPlanSectionModal(true);
				break;
			case QUICK_ACTIONS_FULLSCREEN.FLOOR_PLAN_FLOOR_LEVEL.type:
				setShowEditFloorLevelModal(true);
				break;
			case QUICK_ACTIONS_FULLSCREEN.VISIBILITY.type:
				setIsUpdating({ ...isUpdating, [type]: true });
				toggleFileVisibility(selectedImage, client, async () => {
					// await this.props.updateService();
					setIsUpdating({ ...isUpdating, [type]: false });
				});
				break;
			case QUICK_ACTIONS_FULLSCREEN.DOWNLOAD.type:
				setDownloadFile(selectedImage);
				break;
		}
	};

	const requestAdvancedChanges = () => {
		ZendeskAPI('webWidget', 'open');
		ZendeskAPI('webWidget', 'chat:send', `Hi, I need some advanced changes made to my floor plan.`);
	};

	const renderQuickActions = (currentFile) => {
		const quickActions = getQuickActions();

		if (quickActions.length === 0) {
			return undefined;
		}

		return (
			<div className='fullscreenFiles__quickActions'>
				{renderAdditionalItems && renderAdditionalItems(files[selectedFileIndex])}

				{quickActions.map((quickAction) => (
					<FullscreenFilesQuickAction file={currentFile} isUpdating={isUpdating} quickAction={quickAction} onClickQuickAction={onClickQuickAction} />
				))}
			</div>
		);
	};

	const renderServiceRequestsForFile = () => {
		let currentFile = files[selectedFileIndex];

		let serviceRequestsOnFile: ServiceRequestType[] = [];
		if (serviceRequests) {
			serviceRequestsOnFile = getServiceRequestsOnFile(serviceRequests, currentFile);
		}

		if (serviceRequestsOnFile.length === 0) {
			return;
		}

		return (
			<UICard className='fullscreenFiles__serviceRequests' padding={16} margin={4} description={'This file is already included in a pending fix request.'}>
				{serviceRequestsOnFile.map((serviceRequest) => {
					return (
						<div
							key={serviceRequest.id}
							className='fullscreenFiles__serviceRequest'
							style={isUserAdmin ? { cursor: 'pointer' } : undefined}
							onClick={isUserAdmin ? () => history.push(`/service-request/${serviceRequest.id}`) : undefined}>
							<p className='fullscreenFiles__serviceRequest--type'>{camelCaseToSentence(serviceRequest.type)}</p>
							<p className='fullscreenFiles__serviceRequest--notes'>{serviceRequest.notes}</p>
							<p className='fullscreenFiles__serviceRequest--status'>Status: {camelCaseToSentence(serviceRequest.status)}</p>
						</div>
					);
				})}
			</UICard>
		);
	};

	const renderChangeFloorPlanSectionModal = () => {
		const hideModal = () => setShowChangeFloorPlanSectionModal(false);

		return (
			<UIModal condition={showChangeFloorPlanSectionModal}>
				<UIOverlayCard hideModal={hideModal}>
					<UIIconButton text={'send to measured floor plans section'} />
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderDeleteModal = () => {
		const closeModal = () => setDeleteFile(false);

		const onComplete = async () => {
			closeModal();
			if (updateService) {
				await updateService();
			}
			toast.success('Image has been deleted.');
		};

		return (
			<UIModal condition={deleteFile}>
				<UIOverlayCard hideModal={closeModal} title={'Delete Image?'} description={'Are you sure you want to delete this image?'}>
					<div className='deleteFullscreenOverlayBtns'>
						<UIIconButton
							isLoading={isUpdating['isActive']}
							type={UI_ICON_BUTTON_CANCEL_TYPE}
							text={'yes, delete'}
							onClick={() => changeFile('isActive', false, onComplete)}
						/>
						<UIIconButton className='deleteFullscreenOverlayBtns--cancel' type={UI_ICON_BUTTON_OUTLINE_TYPE} text={'cancel'} onClick={closeModal} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderEditModal = () => {
		const hideModal = async (successMessage) => {
			await queryForServiceRequestsFromFiles();
			if (successMessage) {
				toast.success(successMessage);
			}
			setFileToEdit(undefined);
		};

		const onCreate = async () => {
			await hideModal('Successfully created service request!');
		};

		if (!fileToEdit) {
			return;
		}

		return (
			<UIModal condition={true}>
				<CreateServiceRequestModal
					serviceId={service?.id ?? fileToEdit.service?.id}
					updateService={updateService}
					files={[fileToEdit]}
					hideModal={hideModal}
					onCreate={onCreate}
				/>
			</UIModal>
		);
	};

	const renderDownloadModal = () => {
		const hideModal = () => setDownloadFile(undefined);

		if (!downloadFile) return null;

		return (
			<UIModal condition={true} className='plModal-zIndex-fix'>
				<DownloadModal
					downloadFilename={downloadFile.filename}
					hideModal={hideModal}
					serviceId={service?.id}
					individualFile={downloadFile}
					listingId={listing ? listing.id : undefined}
				/>
			</UIModal>
		);
	};

	const renderQuickFixDirections = () => {
		if (!cubiQuickFix) {
			return;
		}
		const hideModal = () => setCubiQuickFix(false);

		let currentFile = files[selectedFileIndex];

		return (
			<UIModal condition={cubiQuickFix}>
				<UIOverlayCard
					hideModal={hideModal}
					className='fade-in-div fixRequestModal'
					title={'Make changes to this floor plan'}
					description={
						'If you would like to change room names, click the button below to be directed to CubiCasa to edit your floor plan. Double click on any room name to edit. Once you have finished click the checkmark in the top right. Your floor plans will automatically update on Pineapple Labs after a few minutes.'
					}>
					<div className='fixRequestModal__buttons'>
						<UIIconButton
							type={UI_ICON_BUTTON_FILL_TYPE}
							text={'quick edit'}
							onClick={() => window.open(service ? service['cubiCasaQuickEditLink'] : currentFile.service['cubiCasaQuickEditLink'], '_blank')}
						/>
						{isUserAdmin ? (
							<a target={'_blank'} href={service ? service['cubiCasaAdvancedEditLink'] : currentFile.service['cubiCasaAdvancedEditLink']}>
								<UIIconButton type={UI_ICON_BUTTON_OUTLINE_TYPE} text={'advanced editor'} />
							</a>
						) : (
							<UIIconButton type={UI_ICON_BUTTON_OUTLINE_TYPE} text={'request advanced changes'} onClick={requestAdvancedChanges} />
						)}
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderChangeFilenameModal = () => {
		let currentFile = files[selectedFileIndex];

		const hideModal = () => setShowChangeFilenameModal(false);

		return (
			<UIModal condition={showChangeFilenameModal}>
				<UIOverlayCard hideModal={hideModal} className='fade-in-div' title={'Change filename'}>
					<UILabeledInput
						className='inputContainer__1'
						label={'FILENAME'}
						containerStyle={{ width: '100%', maxWidth: 512, margin: '8px 0' }}
						isLoading={isUpdating['filename']}
						type={UI_LABELED_INPUT_TEXT_TYPE}
						onChangeValue={setFilename}
						onSave={() =>
							changeFile('filename', filename, () => {
								hideModal();
								toast.success('Filename saved');
							})
						}
						savedValue={currentFile.filename}
						value={filename}
						placeholder={'Filename...'}
					/>
				</UIOverlayCard>
			</UIModal>
		);
	};

	const renderChangeStatusModal = () => {
		let currentFile = files[selectedFileIndex];

		const hideModal = () => setShowChangeStatusModal(false);
		return (
			<UIModal condition={showChangeStatusModal}>
				<ChangeFileStatusModal hideModal={hideModal} files={[currentFile]} highlightedStatus={currentFile.status} />
			</UIModal>
		);
	};

	const renderEditFloorLevelModal = () => {
		let currentFile = files[selectedFileIndex];

		if (!showEditFloorLevelModal) {
			return;
		}
		const hideModal = () => setShowEditFloorLevelModal(false);

		const onSave = () =>
			changeFloorLevel('floorLevel', floorLevel, () => {
				hideModal();
				if (updateService) {
					updateService();
				}
				toast.success('Floor level saved.');
			});
		return (
			<UIModal condition={showEditFloorLevelModal}>
				<UIOverlayCard hideModal={hideModal} title='Edit image'>
					{type === FULLSCREEN_TYPES.FLOOR_PLAN_TYPE && (
						<UILabeledInput
							className='inputContainer__1'
							label={'FLOOR LEVEL'}
							containerStyle={{ width: '100%', maxWidth: 512, margin: '8px 0' }}
							isLoading={isUpdating['floorLevel']}
							type={UI_LABELED_INPUT_TEXT_TYPE}
							onChangeValue={setFloorLevel}
							onSave={onSave}
							savedValue={currentFile?.tags?.floorLevel}
							value={floorLevel || ''}
							placeholder={'Floor level'}
						/>
					)}
				</UIOverlayCard>
			</UIModal>
		);
	};

	let noFilesComponent = (
		<div className='fullscreenFiles__noImages'>
			<p>This service has no completed images...</p>
		</div>
	);

	if (files.length === 0) {
		return noFilesComponent;
	}

	let currentFile = files[selectedFileIndex];

	const getStatusInfo = (status) => {
		switch (status) {
			case FILE_STATUS_LIVE:
				return 'When a file is in live status all it will be visible to clients and can be used throughout the pineapple labs system.';
			case FILE_STATUS_IN_PROGRESS:
				return 'When a file is in progress it can only be viewed internally by code or admins';
			case FILE_STATUS_PROOF:
				return 'When a file is in proofing status only it’s watermarked version will be visible to the client. They will be prompted to proof their service to gain access to this file.';
			default:
				return '';
		}
	};

	return (
		<div className='fullscreenFiles'>
			<div className='fullscreenFiles__header-slider'>
				<UIIcon className='fullscreenFiles--closeIcon' onClick={() => goBack()} alt={'Close'} iconSrc={CloseIcon.white} />
				<p className='fullscreenFiles__header-slider__filename' onClick={() => setShowChangeFilenameModal(true)}>
					{currentFile.filename}
				</p>
			</div>
			<div className='fullscreenFiles__content'>
				<UIFilmstrip
					selectedIndex={selectedFileIndex}
					setSelectedIndex={setSelectedFileIndex}
					disableImage={disableImage}
					renderImageOverlay={renderImageOverlay}
					files={files}
					fileSize={'webUrl'}
					style={screenType === SCREEN_TYPE.desktop ? { maxWidth: 'calc(100% - 360px)' } : {}}
				/>
				<div className='fullscreenFiles__content__manage'>
					{headerComponent}
					{renderQuickActions(currentFile)}
					{isUserAdmin ? (
						type !== FULLSCREEN_TYPES.FLOOR_PLAN_TYPE ? (
							<>
								<div className='optionsContainer__status adminHover' onClick={() => setShowChangeStatusModal(true)}>
									<p className='optionsContainer__status--header'>Status: {currentFile.status}</p>
									<p className='optionsContainer__status--detail'>{getStatusInfo(currentFile.status)}</p>
								</div>
								<div className='fullscreenFiles__quickActions--separator' />
							</>
						) : (
							<>
								<div className='optionsContainer__status'>
									<p className='optionsContainer__status--header'>Status: {currentFile.status}</p>
									<p className='optionsContainer__status--detail'>{getStatusInfo(currentFile.status)} </p>
								</div>
								<div className='fullscreenFiles__quickActions--separator' />
							</>
						)
					) : null}
					{currentFile.service && isUserAdmin && (
						<>
							<UICheckbox
								label={'allowFixRequest'}
								onChangeExpansion={() => changeFile('allowFixRequest', !currentFile['allowFixRequest'], updateService)}
								status={currentFile['allowFixRequest'] ? UICheckbox.CHECKED : UICheckbox.UNCHECKED}
							/>
							<div className='fullscreenFiles__quickActions--separator' />
						</>
					)}
					{isUserAdmin && <p className='optionsContainer--id'>File ID: {currentFile.id}</p>}
					{renderServiceRequestsForFile()}
				</div>
			</div>
			{renderDeleteModal()}
			{renderDownloadModal()}
			{renderEditModal()}
			{renderQuickFixDirections()}
			{renderEditFloorLevelModal()}
			{renderChangeFilenameModal()}
			{renderChangeStatusModal()}
			{renderChangeFloorPlanSectionModal()}
		</div>
	);
}
