export const emailWarning = 'Please enter an email!';
export const emailValidWarning = 'Please enter a valid email!';
export const passwordValidWarning = 'Please enter a password!';
export const graphQLError = 'GraphQL error:';
export const adminEmail = 'admin@pineapplelabs.io';
export const agentEmail = 'agent@pineapplelabs.io';
export const techEmail = 'technician@pineapplelabs.io';
export const editorEmail = 'editor@pineapplelabs.io';
export const regionalManagerEmail = 'regionalManager@pineapplelabs.io';
export const salesEmail = 'sales@pineapplelabs.io';
export const editingManagerEmail = 'editingManager@pineapplelabs.io';
export const affiliateManagerEmail = 'affiliate@pineapplelabs.io';
export const loginTitle = 'login';
export const emailInputId = 'email-input';
export const emailInputPlaceholder = 'Your email...';
export const emailInputLabel = 'EMAIL';
export const passwordInputId = 'password-input';
export const passwordInputPlaceholder = 'Your password...';
export const passwordInputLabel = 'PASSWORD';
export const passwordInputType = 'password';
export const buttonInputId = 'login-submit';
export const forgotPassword = 'forgot your password?';
export const noAccount = "Don't have an account?";
