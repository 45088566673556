import React from 'react';
import './styles/ui-header.scss';
import './styles/ui-header-tablet.scss';
import './styles/ui-header-desktop.scss';
import { getMatchingPath } from '../../../utils/Helpers/AppHelper';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { BackIcon, ChatIcon, MenuIcon, PLIcons, ProfileIcon } from '../../../assets/icons';
import { SCREEN_TYPE } from '../../../utils/Config/Constants';
import UIIcon from '../UIIcon';
import { isRouteExcludedFromAuth } from '../../../RoutesItems';
import PLImage from '../../PL/PLImage';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { getInitialRoute } from '../../../utils/Helpers/UserHelpers';
import UIHover from '../UIHover';
import { ZendeskAPI } from 'react-zendesk';
import { UIHeaderProps } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { getSignedInUserSelector, getSuperUserReturnRouteSelector, getSuperUserSelector } from '../../../redux/selectors/CurrentUserSelectors';
import { getScreenTypeSelector, getSelectedPanelTypeSelector } from '../../../redux/selectors/ControlSelectors';
import { setSignedInUser, setSuperUserReturnRoute, showLogin } from '../../../redux/store/user/actions';
import { HEADER_TYPES } from './config';
import { toggleMenuVisibility } from '../../../redux/store/control/actions';

const UIHeader = ({ type }: UIHeaderProps) => {
	const signedInUser = useSelector(getSignedInUserSelector);
	const superUser = useSelector(getSuperUserSelector);
	const superUserReturnRoute = useSelector(getSuperUserReturnRouteSelector);
	const screenType = useSelector(getScreenTypeSelector);
	const selectedPanelTab = useSelector(getSelectedPanelTypeSelector);

	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const onBack = () => {
		const matchingRoute = getMatchingPath(signedInUser, history);

		if (matchingRoute?.skipAllOnBack && selectedPanelTab !== undefined) {
			history.push(selectedPanelTab);
		} else {
			history.goBack();
		}
	};

	const goToInitialRoute = () => {
		if (signedInUser) {
			history.push(getInitialRoute(signedInUser) + location.search);
		}
	};

	const getLinkClassname = (toRoute) => {
		const matchingRoute = getMatchingPath(signedInUser, history);

		if (matchingRoute && matchingRoute.path === toRoute) return 'uiHeader__center--left--link uiHeader__center--left--link-selected';
		return 'uiHeader__center--left--link';
	};

	const renderLeft = (matchingRoute) => {
		if (!signedInUser || isRouteExcludedFromAuth(matchingRoute)) {
			if (screenType === SCREEN_TYPE.mobile) {
				return (
					<div className='headerContainer__left' key={matchingRoute.path}>
						<UIIcon
							className='headerMenu'
							onClick={() => {
								console.log('dispatch', true);
								dispatch(toggleMenuVisibility(true));
							}}
							alt={'menu'}
							iconSrc={MenuIcon.white}
						/>
					</div>
				);
			} else {
				return <div className='uiHeader__center--left' />;
			}
		}

		if (matchingRoute && matchingRoute.noPanel) {
			return (
				<div className='headerContainer__left' key={matchingRoute.path} onClick={() => onBack()}>
					<UIIcon className='headerMenu' alt={'back'} iconSrc={BackIcon.white} />
				</div>
			);
		} else if (matchingRoute && matchingRoute.region) {
			return (
				<div className='headerContainer__left' key={matchingRoute.path} onClick={() => onBack()}>
					<UIIcon className='headerMenu' alt={'back'} iconSrc={BackIcon.white} />
				</div>
			);
		} else if (matchingRoute && matchingRoute.showInPanel && screenType === SCREEN_TYPE.mobile) {
			return (
				<div
					className='headerContainer__left'
					key={matchingRoute.path}
					onClick={() => {
						console.log('dispatch', true);
						dispatch(toggleMenuVisibility(true));
					}}>
					<UIIcon className='headerMenu' alt={'menu'} iconSrc={MenuIcon.white} />
				</div>
			);
		}
		return null;
	};

	const renderRight = () => {
		return (
			<a className='uiHeader__right' target='__blank' href='https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=pineapplelabs.zendesk.com'>
				<PLImage className='headerImage--img' src={ChatIcon.grey} alt={'Chat'} />
				{screenType !== SCREEN_TYPE.mobile && <p className='uiHeader__right-text'>help</p>}
			</a>
		);
	};

	const renderHeaderByType = (type) => {
		const matchingRoute = getMatchingPath(signedInUser, history);
		switch (type) {
			case HEADER_TYPES.MARKETING_SITE_TYPE:
				if (screenType === SCREEN_TYPE.mobile) {
					return (
						<div className='uiHeader'>
							<div className='uiHeader__center--left'>
								<PLImage
									src={MenuIcon.white}
									className='uiHeader__center--left--icon'
									style={{ padding: 4 }}
									onClick={() => dispatch(toggleMenuVisibility(true))}
									alt={'Menu'}
								/>
							</div>
							<PLImage alt='Pineapple Labs icon' className='uiHeader__center--left--icon' src={PLIcons.dark} onClick={goToInitialRoute} />

							{signedInUser ? (
								<Link to={getInitialRoute(signedInUser)} className='uiHeader__center--right'>
									<PLImage src={signedInUser.image} className='uiHeader__center--left--icon' style={{ borderRadius: '50%' }} alt={'User image'} />
								</Link>
							) : (
								<div className='uiHeader__center--right'>
									{/*// @ts-ignore*/}
									<ContextMenuTrigger id='ui-header-profile' holdToDisplay={0}>
										<PLImage src={ProfileIcon} className='uiHeader__center--left--icon' style={{ padding: 4 }} alt={'Profile icon'} />
									</ContextMenuTrigger>
									{/*// @ts-ignore*/}
									<ContextMenu className='plContextMenu headerContextMenu' id='ui-header-profile'>
										{/*// @ts-ignore*/}
										<MenuItem onClick={() => dispatch(showLogin(true))}>
											<p className='uiHeader__center--right--cm-item'>login</p>
										</MenuItem>
										<div className='pl-separator' style={{ margin: 0 }} />
										{/*// @ts-ignore*/}
										<MenuItem onClick={() => history.push('/signup')}>
											<p className='uiHeader__center--right--cm-item'>signup</p>
										</MenuItem>
									</ContextMenu>
								</div>
							)}
						</div>
					);
				}
				return (
					<div className='uiHeader'>
						<div className='uiHeader__center--left'>
							<Link to={getInitialRoute(signedInUser)} className='uiHeader__center--left--icon'>
								<PLImage src={PLIcons.dark} className='uiHeader__center--left--icon' alt={'Pineapple Labs Icon'} />
							</Link>
							<Link to={'/'} className={getLinkClassname('/')}>
								media services
							</Link>
							<Link to={'/tools'} className={getLinkClassname('/tools')}>
								service providers
							</Link>
						</div>
						<div className='uiHeader__center--right'>{renderRight()}</div>
					</div>
				);
			case HEADER_TYPES.CENTER_TITLE_TYPE:
			default:
				let titleComponent = <div className='uiHeader__center--title'>{'pineapple labs'}</div>;
				let isViewingAsSuperUser = superUser && superUser?.id !== signedInUser.id;
				if (isViewingAsSuperUser && signedInUser.name) {
					const exitSuperUserView = () => {
						dispatch(setSignedInUser(superUser));
						if (superUserReturnRoute) {
							history.push(superUserReturnRoute);
							dispatch(setSuperUserReturnRoute(undefined));
						} else {
							history.push(getInitialRoute(superUser));
						}
					};

					titleComponent = (
						<UIHover text={'Exit view'} className='uiHeader__center--title' onClick={exitSuperUserView}>
							viewing as {signedInUser.name}
						</UIHover>
					);
				}
				return (
					<div className='uiHeader'>
						<div className='uiHeader__center--left'>{renderLeft(matchingRoute)}</div>
						{signedInUser && !isViewingAsSuperUser ? <Link to={getInitialRoute(signedInUser)}>{titleComponent}</Link> : titleComponent}
						<div className='uiHeader__center--right'>{renderRight()}</div>
					</div>
				);
		}
	};

	return renderHeaderByType(type);
};

export default UIHeader;
