import gql from 'graphql-tag';

export const GET_SERVICE_SURGE = gql`
	query Service($id: String!) {
		service(id: $id) {
			id
			surgeBoost
		}
	}
`;

export const UPDATE_SERVICE_SURGE_BOOST = gql`
	mutation UpdateServiceSurge($serviceId: String!, $surgeBoost: Int) {
		updateService(id: $serviceId, surgeBoost: $surgeBoost) {
			id
			surgeBoost
		}
	}
`;
