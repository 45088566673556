import gql from 'graphql-tag';
import { Component } from 'react';

const property = {
	fields: {
		full: `
         id
            address
            actions {
               id
            }
            alias
            audioFile {
               id
            }
            baths
            beds
            city
            country
            googlePlaceId
            coverFile {
               id
            }
            services {
               id
               coverFile {
                id
                thumbnailUrl
               }
               status
            }
            dateLastListed
            datePineappleListed
            files {
               id
            }
            flags
            HOAPerMonth
            homeDescription
            isActive
            latitude
            listings {
               id
               askingPrice
               client{
                  id
                  image
                  name
                  companyName
               }
            }
            location
            longitude
            lotSize
            MLSNumber
            neighborhoodDescription
            pastAgentEmails
            pineappleUrl
            propertyType
            region {
               id
            }
            soldDate
            state
            streetAddress
            squareFootage
            timezone
            type
            videos {
               id
            }
            yearBuilt
            unitNumber
            zipCode
      `
	},
	full: `
      property {
         id
         unitNumber
         address
         addressComponents
         alias
         baths
         beds
         city
         country
         googlePlaceId
         latitude
         longitude
         state
         streetAddress
         squareFootage
         timezone
         type
         unitNumber
         yearBuilt
         zipCode
      }
   `
};
export const files = {
	full: `files{
                    id,
                    isActive,
                    createdAt,
                    updatedAt,
                    type,
                    filename,
                    index,
                    status,
                    isVisible,
                    watermarkUrl,
                    thumbnailUrl,
                    originalUrl,
                    printUrl,
                    webUrl,
                    metadata,
                    tags,
                } `,
	fields: `
        id,
        isActive,
        createdAt,
        updatedAt,
        type,
        filename,
        index,
        status,
        isVisible,
        watermarkUrl,
        thumbnailUrl,
        originalUrl,
        printUrl,
        allowFixRequest,
        webUrl,
        metadata,
        width,
        height,
        tags,
        service {
         id
         type
        }
      `
};
export const transactions = {
	min: `transactions{
             id
             invoiced
             paymentStatus
          } `,
	full: `transactions{
             id
             amount
             creditsApplied
             currency
             discountedAmount
             flags
             invoiced
             invoiceId
             invoiceItemId
             optionsPurchased
             paymentMethod
             paymentStatus
             receiptUrl
             serviceDate
             stripeBookingTotal
             property
             service{
               id
               address
               property {
                 id
                 address
               }
               orderInformation
             }
             createdAt
             updatedAt
             bookedOptions{
               id
             }
             promos{
               id
             }
          } `,
	fields: `
       id
       amount
       creditsApplied
       currency
       discountedAmount
       flags
       invoiced
       invoiceId
       invoiceItemId
       optionsPurchased
       paymentMethod
       paymentStatus
       receiptUrl
       serviceDate
       stripeBookingTotal
       property
       service{
         id
         address
         agent {
         id
         name
         }
         type
         orderInformation
         property {
           id
           address
         }
       }
       createdAt
       updatedAt
       bookedOptions{
         id
         name
         price
         quantity
       }
       promos{
         id
       }
      `
};
export const serviceRequests = {
	full: `serviceRequests {
                  id
               accepted
               actionBy
               additionalInfo
               cubiCasaOrderId
               cubiCasaModelId
               cubiCasaQuickEditLink
               editor {
                  id
                  name
                  email
                  image
               }
               service {
                  id
               }
               requestedFiles {
                  ${files.fields}
               }
               completedFiles {
                  ${files.fields}
               }
               flags
               isActive
               isApproved
               notes
               status
               type
               urgent
                }`,
	fields: `
               id
               accepted
               actionBy
               additionalInfo
               cubiCasaOrderId
               cubiCasaModelId
               cubiCasaQuickEditLink
               editor {
                  id
                  name
                  email
                  image
               }
               service {
                  id
                  ${property.full}
               }
               requestedFiles {
                  ${files.fields}
               }
               completedFiles {
                  ${files.fields}
               }
               flags
               isActive
               isApproved
               notes
               status
               type
               urgent
      `
};

class Queries extends Component {
	static preferredPhotographers = {
		full: `preferredPhotographers{
                    id,
                    name,
                    image,
                    email,
                    phoneNumber
                }`
	};

	static promos = {
		full: `promos{
                  id
                  name
                  promoType
                  discountAmount
                  discountPercentage
                  discountType
                  uses
                  maxUses
                  option {
                     id
                     name
                  }
              }`
	};

	static regions = {
		flat: `regions {
               id
               name
               latitude
               longitude
               infoPath
               regionConfig {
                  id
                  name
                  coldwellPackages
                  lightingOptions
               }
               flexBookingDelayTime
               state
               zipCodes
               timezone
            }`
	};

	static regionConfig = {
		full: `regionConfig {
               id
               name
               coldwellPackages
               lightingOptions
            }`
	};

	static floorPlans = {
		full: `floorplans{
                    id,
                    originalUrl,
                    filename,
                    isVisible,
                    status,
                    tags,
                    allowFixRequest,
                    isActive
                }`
	};

	static videos = {
		full: `videos{
                    id,
                    isActive,
                    createdAt,
                    updatedAt,
                    type,
                    filename,
                    index,
                    status,
                    isVisible,
                    watermarkUrl,
                    thumbnailUrl,
                    originalUrl,
                    printUrl,
                    webUrl,
                    metadata,
                    tags
                }
        `
	};

	static selectedImages = {
		full: `selectedImages{
                id,
                isActive,
                createdAt,
                updatedAt,
                type,
                filename,
                index,
                status,
                isVisible,
                watermarkUrl,
                thumbnailUrl,
                originalUrl,
                printUrl,
                webUrl,
                metadata,
                tags
            }`
	};

	static CREATE_USER_MUTATION = gql`
		mutation signup(
			$name: String!
			$email: String!
			$password: String!
			$certificates: [String]
			$phoneNumber: String!
			$regions: [String]
			$role: String!
			$agency: String
			$agencies: String
			$editingCompany: String
			$requiresPasswordChange: Boolean
			$inviteCode: String
		) {
			signup(
				name: $name
				credits: 2000
				regions: $regions
				certificates: $certificates
				email: $email
				password: $password
				phoneNumber: $phoneNumber
				role: $role
				notes: ""
				agency: $agency
				agencies: [$agencies]
				editingCompany: $editingCompany
				requiresPasswordChange: $requiresPasswordChange
				inviteCode: $inviteCode
			) {
				id
				token
				role
				regions {
					id
					name
				}
				droneCertified
				stripeAccountId
				id
				name
				email
				phoneNumber
				image
				role
				credits
				inTraining
				certificates
				regions {
					id
				}
				promos {
					id
					name
					discountAmount
					discountPercentage
					discountType
					uses
					promoType
					maxUses
					option {
						id
						name
						price
					}
				}
				agency {
					id
					imageUrl
					name
					email
					phoneNumber
				}
			}
		}
	`;

	static CREATE_LISTING = gql`
		mutation CreateListing(
			$requestedOptions: [JSON]
			$propertyInfo: JSON!
			$regionId: String
			$client: String
			$agent: String
			$photographer: String
			$stripeBookingTotal: Int!
			$stripePaymentToken: String
			$timezone: String
			$currency: String
			$promos: [String]
		) {
			createListing(
				client: $client
				agent: $agent
				photographer: $photographer
				requestedOptions: $requestedOptions
				promos: $promos
				propertyInfo: $propertyInfo
				timezone: $timezone
				region: $regionId
				stripeBookingTotal: $stripeBookingTotal
				stripePaymentToken: $stripePaymentToken
				currency: $currency
			) {
				id
			}
		}
	`;

	static CREATE_SERVICE_MUTATION = gql`
		mutation CreateService(
			$serviceDate: String!
			$photographer: String
			$type: String!
			$status: String!
			$timezone: String
			$externalAgentName: String
			$externalAgentEmail: String
			$preferredPhotographers: [String]
			$requestedOptions: [JSON]
			$agentNotes: String!
			$propertyInfo: JSON
			$propertyId: String
			$listingId: String
			$orderInformation: JSON
			$regionId: String
			$client: String
			$options: [String]
			$agent: String
			$agency: String
			$isDroneService: Boolean
			$stripeBookingTotal: Int!
			$stripePaymentToken: String
			$promos: [String]
			$additionalAgentContacts: [JSON]
		) {
			createService(
				serviceDate: $serviceDate
				type: $type
				agentNotes: $agentNotes
				requestedOptions: $requestedOptions
				propertyInfo: $propertyInfo
				property: $propertyId
				listing: $listingId
				timezone: $timezone
				orderInformation: $orderInformation
				photographer: $photographer
				preferredPhotographers: $preferredPhotographers
				externalAgentName: $externalAgentName
				externalAgentEmail: $externalAgentEmail
				client: $client
				regionId: $regionId
				options: $options
				agent: $agent
				status: $status
				promos: $promos
				agency: $agency
				isDroneService: $isDroneService
				stripePaymentToken: $stripePaymentToken
				stripeBookingTotal: $stripeBookingTotal
				additionalAgentContacts: $additionalAgentContacts
			) {
				id
				agent {
					name
				}
				listing {
					id
				}
				status
				property {
					id
				}
			}
		}
	`;
	static CREATE_PROPERTY = gql`
		mutation createProperty(
			$address: String!
			$alias: String
			$baths: String
			$beds: String
			$service: String
			$city: String
			$country: String
			$latitude: Float!
			$longitude: Float!
			$googlePlaceId: String!
			$unitNumber: String
			$state: String
			$streetAddress: String
			$squareFootage: Int
			$type: String
			$zipCode: String
		) {
			createProperty(
				address: $address
				alias: $alias
				baths: $baths
				beds: $beds
				serviceId: $service
				city: $city
				country: $country
				latitude: $latitude
				longitude: $longitude
				googlePlaceId: $googlePlaceId
				unitNumber: $unitNumber
				state: $state
				streetAddress: $streetAddress
				squareFootage: $squareFootage
				type: $type
				zipCode: $zipCode
			) {
				id
			}
		}
	`;

	static CREATE_OPTION = gql`
		mutation CreateOption(
			$name: String!
			$price: Int!
			$finalFileCount: Int!
			$description: String
			$type: String
			$quantity: Int
			$packageType: String
			$editorInstructions: String
			$editorCertificatesRequired: [String]
			$regions: [String]!
			$requiredInfo: String
			$requiredInfoPrompt: String
			$additionalOptions: [String]
			$photographerPay: Int
			$traineePay: Int
			$photographerFileCount: Int
			$payTime: Int
			$photographerInstructions: String
			$duration: Int
			$packageName: String
			$packageDescription: String
			$packageDetails: JSON
			$photographerCertificatesRequired: [String]
		) {
			createOption(
				name: $name
				price: $price
				finalFileCount: $finalFileCount
				description: $description
				type: $type
				quantity: $quantity
				packageType: $packageType
				editorInstructions: $editorInstructions
				editorCertificatesRequired: $editorCertificatesRequired
				regions: $regions
				requiredInfo: $requiredInfo
				requiredInfoPrompt: $requiredInfoPrompt
				additionalOptions: $additionalOptions
				photographerPay: $photographerPay
				traineePay: $traineePay
				photographerFileCount: $photographerFileCount
				payTime: $payTime
				photographerInstructions: $photographerInstructions
				duration: $duration
				packageName: $packageName
				packageDescription: $packageDescription
				packageDetails: $packageDetails
				photographerCertificatesRequired: $photographerCertificatesRequired
			) {
				id
				name
				price
			}
		}
	`;

	static GET_TRANSACTIONS_WITH_QUERY = gql`
      query transactions( $limit: Int, $query: JSON!) {
         transactions(limit: $limit, query: $query) {
            ${transactions.fields}
         }
      }
   `;

	static GET_FILES_WITH_QUERY = gql`
      query files( $limit: Int, $query: JSON!, $order: [[String]]) {
         files(limit: $limit, query: $query, order: $order) {
            ${files.fields}
         }
      }
   `;

	static CREATE_FILE = gql`
		mutation CreateFile(
			$type: String!
			$filename: String!
			$index: Int!
			$status: String!
			$isVisible: Boolean
			$watermarkUrl: String
			$thumbnailUrl: String
			$originalUrl: String
			$metadata: JSON
			$tags: JSON
			$service: String
			$listing: String
		) {
			createFile(
				type: $type
				filename: $filename
				index: $index
				status: $status
				isVisible: $isVisible
				watermarkUrl: $watermarkUrl
				thumbnailUrl: $thumbnailUrl
				originalUrl: $originalUrl
				metadata: $metadata
				tags: $tags
				service: $service
				listing: $listing
			) {
				id
				createdAt
				updatedAt
				type
				filename
				index
				status
				isVisible
				watermarkUrl
				thumbnailUrl
				originalUrl
				webUrl
				metadata
				tags
				service {
					id
				}
				listing {
					id
				}
			}
		}
	`;

	static CREATE_FILES = gql`
		mutation CreateFiles($service: String, $listing: String, $requestedImages: [JSON]) {
			createFiles(service: $service, listing: $listing, requestedImages: $requestedImages) {
				id
				createdAt
				updatedAt
				type
				filename
				index
				status
				isVisible
				watermarkUrl
				thumbnailUrl
				originalUrl
				webUrl
				metadata
				tags
				service {
					id
				}
			}
		}
	`;

	static UPDATE_LISTING = gql`
		mutation UpdateListing(
			$id: String!
			$audioFile: String
			$flags: JSON
			$client: String
			$askingPrice: Int
			$squareFootage: Int
			$baths: String
			$beds: String
			$homeDescription: String
			$neighborhoodDescription: String
			$matterportModelId: String
		) {
			updateListing(
				id: $id
				audioFile: $audioFile
				flags: $flags
				client: $client
				askingPrice: $askingPrice
				squareFootage: $squareFootage
				baths: $baths
				beds: $beds
				homeDescription: $homeDescription
				neighborhoodDescription: $neighborhoodDescription
				matterportModelId: $matterportModelId
			) {
				id
				createdAt
				updatedAt
				type
				status
				flags
				askingPrice
				squareFootage
				beds
				baths
				homeDescription
				matterportModelId
				neighborhoodDescription
				audioFile {
					id
					filename
					webUrl
					originalUrl
					thumbnailUrl
				}
			}
		}
	`;

	static UPDATE_FILES = gql`
		mutation UpdateFiles($requestedUpdates: [JSON]!, $service: String, $listing: String) {
			updateFiles(service: $service, requestedUpdates: $requestedUpdates, listing: $listing) {
				id
				index
				isActive
				isVisible
				status
			}
		}
	`;

	static CREATE_SCHEDULE_REQUEST = gql`
		mutation CreateScheduleRequest($serviceId: String!, $requestedTimes: [String]!, $agentId: String!, $photographerId: String!) {
			createScheduleRequest(service: $serviceId, requestedTimes: $requestedTimes, agent: $agentId, photographer: $photographerId) {
				id
				updatedAt
				createdAt
				accepted
				acceptedTime
				service {
					id
					serviceDate
				}
				agent {
					id
					name
				}
				photographer {
					id
					name
					schedulePhotographerRequests {
						id
					}
				}
			}
		}
	`;

	static SET_SHOOT_IMAGES_ORDER = gql`
		mutation UpdateService($serviceId: String!) {
			updateService(id: $serviceId) {
				status
				askingPrice
				squareFootage
				agent {
					name
				}
				region {
					name
				}
				photographer {
					name
					email
				}
			}
		}
	`;

	static UPDATE_AGENCY_MUTATION = gql`
		mutation updateAgency(
			$id: String!
			$imageUrl: String
			$name: String
			$email: String
			$phoneNumber: String
			$parentCompany: String
			$websiteUrl: String
			$notes: String
		) {
			updateAgency(
				id: $id
				imageUrl: $imageUrl
				name: $name
				email: $email
				phoneNumber: $phoneNumber
				parentCompany: $parentCompany
				websiteUrl: $websiteUrl
				notes: $notes
			) {
				id
				name
				email
				phoneNumber
				imageUrl
				websiteUrl
			}
		}
	`;

	static UPDATE_FILE = gql`
		mutation updateFile(
			$fileId: String!
			$isVisible: Boolean
			$status: String
			$isActive: Boolean
			$filename: String
			$index: Int
			$originalUrl: String
			$listing: String
		) {
			updateFile(
				id: $fileId
				isVisible: $isVisible
				isActive: $isActive
				status: $status
				filename: $filename
				index: $index
				originalUrl: $originalUrl
				listing: $listing
			) {
				id
				isActive
				filename
				isVisible
				status
				originalUrl
				index
				listing {
					id
				}
			}
		}
	`;

	static CREATE_PAYMENT_INTENT = gql`
		mutation createPaymentIntent($requestedOptions: [JSON]!, $promos: [String], $currency: String, $stripeBookingTotal: Int, $id: String) {
			createPaymentIntent(requestedOptions: $requestedOptions, promos: $promos, currency: $currency, stripeBookingTotal: $stripeBookingTotal, id: $id)
		}
	`;

	static PACKAGES = gql`
		query packages($region: String!) {
			packages(region: $region)
		}
	`;

	static UPDATE_USER_MUTATION = gql`
		mutation updateUser(
			$id: String!
			$regions: [String]
			$cubiCasaEmail: String
			$optOutPhotographers: [String]
			$certificates: [String]
			$image: String
			$name: String
			$email: String
			$phoneNumber: String
			$notes: String
			$bio: String
			$droneCertified: Boolean
			$isInternalTech: Boolean
			$stripeAccountId: String
			$requiresPasswordChange: Boolean
			$websiteUrl: String
			$inTraining: Boolean
			$isActive: Boolean
			$serviceAreas: [String]
			$additionalContacts: [JSON]
			$claimServiceDelay: Int
			$resourcesRead: JSON
			$allowInvoicing: Boolean
		) {
			updateUser(
				id: $id
				regions: $regions
				cubiCasaEmail: $cubiCasaEmail
				certificates: $certificates
				optOutPhotographers: $optOutPhotographers
				image: $image
				name: $name
				email: $email
				phoneNumber: $phoneNumber
				notes: $notes
				bio: $bio
				droneCertified: $droneCertified
				isInternalTech: $isInternalTech
				stripeAccountId: $stripeAccountId
				requiresPasswordChange: $requiresPasswordChange
				websiteUrl: $websiteUrl
				inTraining: $inTraining
				isActive: $isActive
				serviceAreas: $serviceAreas
				additionalContacts: $additionalContacts
				claimServiceDelay: $claimServiceDelay
				resourcesRead: $resourcesRead
				allowInvoicing: $allowInvoicing
			) {
				id
				isActive
				name
				regions {
					id
					name
					longitude
					latitude
					timezone
					zipCodes
				}
				email
				role
				certificates
				phoneNumber
				image
				droneCertified
				stripeAccountId
				cubiCasaEmail
				websiteUrl
				bio
				serviceAreas
				additionalContacts
				claimServiceDelay
				resourcesRead
				allowInvoicing
			}
		}
	`;

	static CHANGE_PASSWORD = gql`
		mutation ChangePassword($id: String!, $password: String!) {
			updatePassword(id: $id, password: $password) {
				id
				role
				email
				name
				regions {
					name
				}
			}
		}
	`;

	static SYNC_CUBI_CASA = gql`
		mutation SyncCubiCasa($id: String!) {
			syncCubiCasa(id: $id)
		}
	`;

	static UPDATE_OPTION_MUTATION = gql`
		mutation updateOption(
			$id: String!
			$isActive: Boolean
			$coverFile: String
			$name: String
			$regions: [String]
			$duration: Int
			$price: Int
			$payTime: Int
			$baseOption: String
			$additionalOptions: [String]
			$finalFileCount: Int
			$photographerFileCount: Int
			$editorFileCount: Int
			$quantity: Int
			$type: String
			$packageType: String
			$description: String
			$photographerPay: Int
			$traineePay: Int
			$photographerInstructions: String
			$editorInstructions: String
			$photographerCertificatesRequired: [String]
			$editorCertificatesRequired: [String]
			$requiredInfoPrompt: String
			$requiredInfo: String
			$deliveryTimeEstimate: String
			$exampleUrl: String
			$isBaseOption: Boolean
		) {
			updateOption(
				id: $id
				isActive: $isActive
				coverFile: $coverFile
				additionalOptions: $additionalOptions
				baseOption: $baseOption
				name: $name
				regions: $regions
				duration: $duration
				price: $price
				payTime: $payTime
				quantity: $quantity
				type: $type
				packageType: $packageType
				description: $description
				photographerPay: $photographerPay
				traineePay: $traineePay
				photographerInstructions: $photographerInstructions
				editorInstructions: $editorInstructions
				photographerCertificatesRequired: $photographerCertificatesRequired
				editorCertificatesRequired: $editorCertificatesRequired
				requiredInfoPrompt: $requiredInfoPrompt
				requiredInfo: $requiredInfo
				finalFileCount: $finalFileCount
				photographerFileCount: $photographerFileCount
				editorFileCount: $editorFileCount
				exampleUrl: $exampleUrl
				deliveryTimeEstimate: $deliveryTimeEstimate
				isBaseOption: $isBaseOption
			) {
				id
				name
				duration
				price
				payTime
				quantity
				finalFileCount
				photographerFileCount
				editorFileCount
				type
				description
				requiredInfo
				requiredInfoPrompt
				coverFile {
					id
				}
				baseOption {
					id
					name
				}
				regions {
					name
				}
				additionalOptions {
					id
					name
				}
			}
		}
	`;

	static SET_EDITOR_ACCEPT_SHOOT = gql`
		mutation UpdateService($serviceId: String!, $editor: String!, $editingCompany: String!) {
			updateService(id: $serviceId, editor: $editor, editingCompany: $editingCompany) {
				status
				agent {
					name
				}
				region {
					name
				}
				photographer {
					name
					email
				}
			}
		}
	`;

	static REMOVE_PHOTOGRAPHER_FROM_SHOOT = gql`
		mutation UpdateService($serviceId: String!) {
			updateService(id: $serviceId, photographer: null, status: "findingPhotographer") {
				status
				agent {
					name
				}
				region {
					name
				}
				photographer {
					name
					email
				}
			}
		}
	`;

	static SET_PHOTOGRAPHER_ACCEPT_SHOOT = gql`
		mutation UpdateService($serviceId: String!, $photographer: String!) {
			updateService(id: $serviceId, photographer: $photographer, status: "photographerUpload") {
				status
				agent {
					name
				}
				region {
					name
				}
				photographer {
					name
					email
				}
			}
		}
	`;

	static GET_LISTING = gql`
      query GetListing($id: String!) {
         listing(id: $id) {
            id
            createdAt
            updatedAt
            type
            client {
               id
               name
               image
               role
            }
            coverFile {
               id
               type
               filename
               thumbnailUrl
               webUrl
               printUrl
               originalUrl
            }
            ${property.full}
            status
            askingPrice
            squareFootage
            socialMediaFiles {
               id
            }
            lotSize
            matterportModelId
            beds
            aggregatedImages {
               id
            }
            aggregatedVideos {
               id
            }
            aggregatedFloorplans {
               id
            }
            baths
            flags
            yearBuilt
            homeDescription
            neighborhoodDescription
            services {
               id
            }
            ${property.full}
            audioFile {
               id
               filename
               webUrl
               originalUrl
               thumbnailUrl
            }
            agent {
               id
               name
               phoneNumber
               email
               image
               websiteUrl
            }
            region {
               id
               name
               state
            }
         }
      }
   `;

	static GET_LISTINGS_FOR_TABLES = gql`
      query Listings($order: [[String]], $q: JSON, $limit: Int, $offset: Int) {
         listings(order: $order, query: $q, limit: $limit, offset: $offset) {
            id
            createdAt
            coverFile {
               id
               type
               filename
               thumbnailUrl
               webUrl
               printUrl
               originalUrl
            }
            ${property.full}
            updatedAt
            type
            status
            askingPrice
            squareFootage
            lotSize
            beds
            baths
            neighborhoodDescription
            homeDescription
            yearBuilt
            services {
               id
               type
               status
               serviceDate
            }
         }
      }
   `;

	static GET_LISTINGS_WITH_QUERY = gql`
      query Listings($order: [[String]], $q: JSON, $limit: Int, $offset: Int) {
         listings(order: $order, query: $q, limit: $limit, offset: $offset) {
            id
            createdAt
            coverFile {
               id
               type
               filename
               thumbnailUrl
               webUrl
               printUrl
               originalUrl
            }
            ${property.full}
            updatedAt
            type
            status
            askingPrice
            squareFootage
            lotSize
            beds
            baths
            neighborhoodDescription
            homeDescription
            yearBuilt
            services {
               id
               type
               status
               serviceDate
            }
            aggregatedImages {
               id
               isActive
               createdAt
               updatedAt
               type
               filename
               index
               status
               isVisible
               watermarkUrl
               thumbnailUrl
               originalUrl
               printUrl
               webUrl
               metadata
               tags
            }
            videos {
               id
               isActive
               createdAt
               updatedAt
               type
               filename
               index
               status
               isVisible
               watermarkUrl
               thumbnailUrl
               originalUrl
               printUrl
               webUrl
               metadata
               tags
            }
            floorplans {
               id
               originalUrl
               filename
               isVisible
               status
               tags
               isActive
            }
            audioFile {
               id
               filename
               webUrl
               originalUrl
               thumbnailUrl
            }
            client {
               id
               name
               phoneNumber
               email
               image
               websiteUrl
            }
            region {
               id
               name
               state
            }
         }
      }
   `;

	static GET_PROPERTY = gql`
      query property($id: String!) {
         property(id: $id) {
         ${property.fields.full} 
         }
      }
   `;

	static UPDATE_PROPERTY = gql`
		mutation UpdateProperty(
			$propertyId: String!
			$streetAddress: String
			$address: String
			$unitNumber: String
			$zipCode: String
			$alias: String
			$city: String
			$state: String
			$longitude: Float
			$latitude: Float
			$addressComponents: JSON
			$googlePlaceId: String
			$country: String
		) {
			updateProperty(
				id: $propertyId
				streetAddress: $streetAddress
				address: $address
				unitNumber: $unitNumber
				zipCode: $zipCode
				alias: $alias
				city: $city
				state: $state
				latitude: $latitude
				longitude: $longitude
				addressComponents: $addressComponents
				googlePlaceId: $googlePlaceId
				country: $country
			) {
				id
				address
				unitNumber
				alias
			}
		}
	`;

	static GET_PROPERTIES_FOR_TABLES = gql`
		query properties($order: [[String]], $q: JSON, $limit: Int, $offset: Int) {
			properties(order: $order, query: $q, limit: $limit, offset: $offset) {
				id
				address
				alias
				city
				country
				flags
				isActive
				latitude
				listings {
					id
					client {
						id
						name
					}
					coverFile {
						id
						thumbnailUrl
						width
						height
					}
				}
				services {
					id
					status
					serviceDate
					client {
						id
					}
				}
				location
				longitude
				state
				streetAddress
				timezone
				unitNumber
				zipCode
			}
		}
	`;

	static GET_PROPERTIES_WITH_QUERY = gql`
		query properties($order: [[String]], $q: JSON, $limit: Int, $offset: Int) {
			properties(order: $order, query: $q, limit: $limit, offset: $offset) {
				id
				address
				alias
				baths
				beds
				city
				country
				dateLastListed
				datePineappleListed
				flags
				HOAPerMonth
				homeDescription
				isActive
				latitude
				listings {
					id
					client {
						id
						name
					}
					coverFile {
						id
						thumbnailUrl
					}
				}
				services {
					id
					status
				}
				location
				longitude
				lotSize
				MLSNumber
				neighborhoodDescription
				pastAgentEmails
				pineappleUrl
				propertyType
				soldDate
				state
				streetAddress
				squareFootage
				timezone
				type
				unitNumber
				yearBuilt
				zipCode
			}
		}
	`;

	static GET_AGENCIES_WITH_QUERY = gql`
		query GetAgenciesWithQuery($salesmanId: String!) {
			agencies(salesmanId: $salesmanId) {
				id
				name
				email
				phoneNumber
				imageUrl
				agents {
					id
					name
					email
					phoneNumber
				}
			}
		}
	`;

	static GET_SERVICE_REQUEST = gql`
      query GetServiceRequest($id: String!) {
         serviceRequest(id: $id) {
            ${serviceRequests.fields}
         }
      }
   `;

	static GET_ACTIONS = gql`
		query Actions($order: [[String]], $q: JSON, $limit: Int) {
			actions(order: $order, query: $q, limit: $limit) {
				id
				createdAt
				title
				upgradeDetails
				updatedServiceAttributes
				notificationsMuted
				isResent
				scheduleRequest {
					id
				}
				notificationParams
				actor {
					id
					name
					email
					image
					role
				}
				service {
					id
					serviceDate
					property {
						id
						address
					}
				}
			}
		}
	`;

	static GET_SERVICE_WITH_FILES = gql`
        query Service($id: String!) {
            service(id: $id) {
                id
                createdAt
                status
                muteNotifications
                askingPrice
                cubiCasaQuickEditLink
                cubiCasaAdvancedEditLink
                cubiCasaOrderId
                additionalAgentContacts 
                client{
                	id
                  name
                  role
                  image
                }
                customerServiceAgent {
                  id
                  name
                  image
                }
                editingCompany {
                  id
                  name
                  editingManager {
                     id
                     name
                  }
                }
                ${property.full}
                ${serviceRequests.full}
                cubiCasaModelId
                listing {
                  id
                  flags
                  askingPrice
                  squareFootage
                  client{
                     id
                     role
                  }
                }
                coverFile {
                  id
                  filename
                  thumbnailUrl
               }
                orderInformation
                squareFootage
                agentNotes
                type
                isAdminApproved
                photographerNotes
                regionalManagerNotes
                matterportModelId
                editorNotes
                serviceDate
                surgeBoost
                includesMatterport
                ${Queries.preferredPhotographers.full},
                ${Queries.floorPlans.full},
                ${Queries.promos.full},
                ${Queries.videos.full}
                bookedOptions{
                    id
                    name
                    price
                    quantity
                    promos{
                        id
                    }
                    finalFileCount
                    photographerFileCount
                    photographerInstructions
                    editorInstructions
                    option{
                        id
                        name
                        price
                        quantity
                        type
                        finalFileCount
                        photographerFileCount
                        photographerInstructions
                        editorInstructions
                        packageType
                        deliveryTimeEstimate
                        exampleUrl
                        photographerCertificatesRequired
                        editorCertificatesRequired
                        packageType
                        photographerPay
                        traineePay
                      
                    }
                    bookingUser{
                        id
                        name
                    }
                    photographerCertificatesRequired
                    editorCertificatesRequired
                    packageType
                    photographerPay
                    traineePay
                    additionalInfo
                    additionalInfoType
                }
                isDroneService,
                isTraining,
                ${transactions.min}
                agent {
                    id,
                    email,
                    phoneNumber,
                    name,
                    notes,
                    image
                    role
                }
                trainee {
                    id
                    name
                    email
                    image
                    phoneNumber
                }
                editor {
                    id
                    name
                    email
                }
                stripeBookingTotal
                region{
                    id
                    name
                    timezone
                }
                photographer {
                    id
                    name
                    email
                    phoneNumber
                    image
                    role
                    certificates
                    isInternalTech
                }
                editor {
                    id
                    name
                    email
                    phoneNumber
                    image
                    editingCompany {
                     id
                    }
                }
            }
        }
    `;

	static GET_SERVICES_FOR_TABLES = gql`
      query Services($q: JSON, $regionId: String, $limit: Int, $offset: Int, $order: [[String]]) {
         services(query: $q, regionId: $regionId, limit: $limit, offset: $offset, order: $order) {
            id
            address
            status
            type
            coverFile {
               id
               filename
               thumbnailUrl
            }
            ${property.full}
            serviceDate
            bookedOptions {
               id
               name
               price
               quantity
               packageType
               additionalInfo
               additionalInfoType
            }
            client {
            	id
            }
            agent {
            	id
            }
            editor {
            	id
            }
            photographer {
            	id
            }
            trainee {
            	id
            }
         }
      }
   `;

	static GET_SERVICES_WITH_QUERY = gql`
      query Services($q: JSON, $regionId: String, $limit: Int, $offset: Int, $order: [[String]]) {
         services(query: $q, regionId: $regionId, limit: $limit, offset: $offset, order: $order) {
            id
            address
            status
            askingPrice
            squareFootage
            cubiCasaQuickEditLink
            cubiCasaAdvancedEditLink
            cubiCasaOrderId
            cubiCasaModelId
            matterportModelId
            agentNotes
            isAdminApproved
            type
            coverFile {
               id
               filename
               thumbnailUrl
            }
            ${property.full}
            orderInformation
            photographerNotes
            regionalManagerNotes
            editorNotes
            serviceDate
            surgeBoost
            includesMatterport
            ${transactions.min}
            bookedOptions {
               id
               name
               price
               quantity
               photographerCertificatesRequired
               editorCertificatesRequired
               packageType
               photographerPay
               traineePay
               additionalInfo
               additionalInfoType
            }
            isDroneService
            isTraining
            customerServiceAgent {
               id
               name
               image
            }
            agent {
               id
               email
               phoneNumber
               name
               notes
               image
            }
            client {
               id
               email
               phoneNumber
               name
               notes
               image
            }
            stripeBookingTotal
            region {
               id
               name
               latitude
               longitude
               timezone
               ${Queries.regionConfig.full}
            }
            preferredPhotographers {
               id
               name
               email
               stripeAccountId
               phoneNumber
               image
            }
            photographer {
               id
               name
               email
               stripeAccountId
               phoneNumber
               image
            }
            trainee {
               id
               name
               email
               stripeAccountId
               phoneNumber
               image
            }
            editor {
               id
               name
               email
               phoneNumber
               image
            }
         }
      }
   `;

	static GET_CERTIFIED_TECHNICIANS = gql`
		query CertifiedTechnicians($q: JSON, $userToBookFor: String, $requestedOptions: [JSON], $regionId: String, $limit: Int, $offset: Int, $order: [[String]]) {
			users(query: $q, userToBookFor: $userToBookFor, requestedOptions: $requestedOptions, regionId: $regionId, limit: $limit, offset: $offset, order: $order) {
				droneCertified
				stripeAccountId
				id
				name
				email
				cubiCasaEmail
				phoneNumber
				image
				role
				credits
				inTraining
				certificates
				regions {
					id
					name
				}
				editingCompany {
					id
				}
				optOutAgents {
					id
				}
				isCertified
			}
		}
	`;

	static GET_USERS_WITH_QUERY = gql`
		query Users($q: JSON, $regionId: String, $salespersonId: String, $limit: Int, $offset: Int, $order: [[String]], $optOutAgents: [String]) {
			users(query: $q, salespersonId: $salespersonId, regionId: $regionId, limit: $limit, offset: $offset, order: $order, optOutAgents: $optOutAgents) {
				droneCertified
				stripeAccountId
				id
				name
				email
				cubiCasaEmail
				phoneNumber
				image
				role
				credits
				inTraining
				certificates
				additionalContacts
				regions {
					id
					name
				}
				editingCompany {
					id
				}
				optOutAgents {
					id
				}
				promos {
					id
					name
					discountAmount
					discountPercentage
					discountType
					uses
					promoType
					maxUses
					option {
						id
						name
						price
					}
				}
			}
		}
	`;

	static GET_AVAILABLE_SHOOTS_EDITOR = gql`
      query GetAvailableServicesEditor($query: JSON, $limit: Int, $offset: Int, $order: [[String]]) {
         availableEditorServices(query: $query, limit: $limit, offset: $offset, order: $order) {
            id
            status
            serviceDate
            agentNotes
            type
            ${property.full}
            bookedOptions {
               id
               name
               price
               quantity
               option {
                  id
                  name
                  price
                  quantity
                  type
                  packageType
                  deliveryTimeEstimate
                  exampleUrl
                  photographerCertificatesRequired
                  editorCertificatesRequired
                  packageType
                  photographerPay
                  traineePay
               }
               bookingUser {
                  id
                  name
               }
               photographerCertificatesRequired
               editorCertificatesRequired
               packageType
               photographerPay
               traineePay
               additionalInfo
               additionalInfoType
            }
            trainee {
               id
               name
               notes
            }
            agent {
               id
               name
               notes
            }
            editor {
               id
               name
            }
            region {
               id
               timezone
            }
            photographer {
               id
               name
               email
            }
         }
      }
   `;

	static GET_AGENCY_BY_ID = gql`
		query Agency($id: String!) {
			agency(id: $id) {
				id
				imageUrl
				name
				email
				phoneNumber
				agents {
					id
					name
					email
					phoneNumber
				}
			}
		}
	`;

	static CREATE_PROMOTION = gql`
		mutation CreatePromotion(
			$promoType: String!
			$discountAmount: Int
			$discountPercentage: Int
			$discountType: String!
			$uses: Int!
			$maxUses: Int!
			$user: String!
			$creator: String!
			$option: String
			$name: String
		) {
			createPromo(
				promoType: $promoType
				discountAmount: $discountAmount
				discountPercentage: $discountPercentage
				discountType: $discountType
				maxUses: $maxUses
				user: $user
				uses: $uses
				creator: $creator
				option: $option
				name: $name
			) {
				id
				name
				promoType
				discountAmount
				discountPercentage
				discountType
				maxUses
				user {
					id
					name
				}
				creator {
					id
					name
				}
				option {
					id
					name
				}
			}
		}
	`;

	static CREATE_BOOKED_OPTION = gql`
		mutation CreateBookedOption(
			$option: String!
			$bookingUser: String!
			$photographerCertificatesRequired: [String]
			$editorCertificatesRequired: [String]
			$packageType: String
			$photographerPay: Int
			$traineePay: Int
			$photographerInstructions: String
			$editorInstructions: String
			$additionalInfo: JSON
			$additionalInfoType: String
			$name: String
			$price: Int
			$quantity: Int
		) {
			createBookedOption(
				option: $option
				bookingUser: $bookingUser
				photographerCertificatesRequired: $photographerCertificatesRequired
				editorCertificatesRequired: $editorCertificatesRequired
				packageType: $packageType
				photographerPay: $photographerPay
				traineePay: $traineePay
				photographerInstructions: $photographerInstructions
				editorInstructions: $editorInstructions
				additionalInfo: $additionalInfo
				additionalInfoType: $additionalInfoType
				name: $name
				price: $price
				quantity: $quantity
			) {
				id
				option {
					id
					photographerCertificatesRequired
					editorCertificatesRequired
					packageType
					photographerPay
					traineePay
					photographerInstructions
					editorInstructions
					requiredInfo
					name
					price
					quantity
				}
				photographerCertificatesRequired
				editorCertificatesRequired
				packageType
				photographerPay
				traineePay
				photographerInstructions
				editorInstructions
				additionalInfo
				additionalInfoType
				name
				price
				quantity
			}
		}
	`;

	static UPDATE_BOOKED_OPTION = gql`
		mutation updateBookedOption(
			$id: String!
			$bookingUser: String
			$photographerCertificatesRequired: [String]
			$editorCertificatesRequired: [String]
			$packageType: String
			$photographerPay: Int
			$traineePay: Int
			$photographerInstructions: String
			$editorInstructions: String
			$additionalInfo: JSON
			$name: String
			$price: Int
			$quantity: Int
		) {
			updateBookedOption(
				id: $id
				bookingUser: $bookingUser
				photographerCertificatesRequired: $photographerCertificatesRequired
				editorCertificatesRequired: $editorCertificatesRequired
				packageType: $packageType
				photographerPay: $photographerPay
				traineePay: $traineePay
				photographerInstructions: $photographerInstructions
				editorInstructions: $editorInstructions
				additionalInfo: $additionalInfo
				name: $name
				price: $price
				quantity: $quantity
			) {
				id
				option {
					id
					photographerCertificatesRequired
					editorCertificatesRequired
					packageType
					photographerPay
					traineePay
					photographerInstructions
					editorInstructions
					requiredInfo
					name
					price
					quantity
				}
				photographerCertificatesRequired
				editorCertificatesRequired
				packageType
				photographerPay
				traineePay
				photographerInstructions
				editorInstructions
				additionalInfo
				name
				price
				quantity
			}
		}
	`;

	static GET_SERVICE_REQUESTS = gql`
      query GetServiceRequests($id: String, $status: String, $query: JSON, $editor: String, $limit: Int, $offset: Int) {
         serviceRequests(id: $id, status: $status, query: $query, editor: $editor, limit: $limit, offset: $offset) {
            id
            createdAt
            accepted
            actionBy
            actions {
             id
            }
            additionalInfo
            agent {
               id
               name
               email
               image
            }
            completedFiles {
               ${files.fields}
            }
            cubiCasaOrderId
            cubiCasaModelId
            cubiCasaQuickEditLink
            editor {
               id
               name
               email
               image
            }
            flags
            isActive
            isApproved
            photographer  {
               id
               name
               email
               image
            }
            requestedFiles {
               ${files.fields}
            }
            notes
            service {
               id
               ${property.full}
            }
            status
            type
            urgent
         }
      }
   `;

	static CREATE_SERVICE_REQUEST = gql`
		mutation CreateServiceRequest(
			$type: String!
			$accepted: Boolean!
			$agent: String
			$editor: String
			$service: String!
			$requestedFiles: [String!]
			$urgent: Boolean
			$status: String
			$notes: JSON
			$additionalInfo: JSON
		) {
			createServiceRequest(
				type: $type
				accepted: $accepted
				agent: $agent
				editor: $editor
				service: $service
				urgent: $urgent
				status: $status
				requestedFiles: $requestedFiles
				notes: $notes
				additionalInfo: $additionalInfo
			) {
				id
			}
		}
	`;

	static UPDATE_SERVICE_REQUEST = gql`
		mutation UpdateServiceRequest(
			$id: String!
			$isActive: Boolean
			$type: String
			$accepted: Boolean
			$agent: String
			$status: String
			$editor: String
			$service: String
			$requestedFiles: [String!]
			$completedFiles: [String!]
			$urgent: Boolean
			$notes: JSON
			$additionalInfo: JSON
		) {
			updateServiceRequest(
				id: $id
				isActive: $isActive
				type: $type
				accepted: $accepted
				agent: $agent
				status: $status
				editor: $editor
				service: $service
				urgent: $urgent
				requestedFiles: $requestedFiles
				completedFiles: $completedFiles
				notes: $notes
				additionalInfo: $additionalInfo
			) {
				id
				editor {
					id
				}
				status
			}
		}
	`;

	static CREATE_FORM_ENTRY = gql`
		mutation CreateFormEntry($formType: String!, $formData: JSON!, $region: String, $inviteCode: String, $salesman: String, $agency: String) {
			createForm(formData: $formData, formType: $formType, region: $region, inviteCode: $inviteCode, salesman: $salesman, agency: $agency) {
				id
				formData
				formType
				region {
					id
					name
				}
			}
		}
	`;

	static UPDATE_EDITING_COMPANY = gql`
		mutation UpdateEditingCompany($id: String!, $managerId: String, $name: String, $email: String, $phoneNumber: String) {
			updateEditingCompany(id: $id, editingManager: $managerId, name: $name, email: $email, phoneNumber: $phoneNumber) {
				id
				name
				email
				phoneNumber
				editingManager {
					id
					name
					email
					phoneNumber
				}
			}
		}
	`;

	static UPDATE_REGION_CONFIG = gql`
		mutation UpdateRegionConfig($id: String!, $lightingOptions: JSON, $coldwellPackages: JSON) {
			updateRegionConfig(id: $id, lightingOptions: $lightingOptions, coldwellPackages: $coldwellPackages) {
				id
				name
				coldwellPackages
				lightingOptions
			}
		}
	`;

	static UPDATE_REGION = gql`
		mutation UpdateRegion(
			$id: String!
			$zipCodes: [String]
			$flexBookingDelayTime: Int
			$name: String
			$timezone: String
			$latitude: Float
			$longitude: Float
			$state: String
			$infoPath: String
		) {
			updateRegion(
				id: $id
				zipCodes: $zipCodes
				flexBookingDelayTime: $flexBookingDelayTime
				name: $name
				timezone: $timezone
				latitude: $latitude
				longitude: $longitude
				state: $state
				infoPath: $infoPath
			) {
				id
				name
				zipCodes
			}
		}
	`;

	static GET_EDITING_COMPANY = gql`
		query GetEditingCompany($id: String!) {
			editingCompany(id: $id) {
				id
				name
				email
				phoneNumber
				editors {
					id
					name
					email
					phoneNumber
					certificates
				}
				editingManager {
					id
					name
					email
					phoneNumber
				}
			}
		}
	`;

	static VIRTUAL_TOUR_MUTATION = gql`
    query GetVirtualTourListing($id: String!){
        virtualTour(id: $id) {
            id,
            squareFootage,
            matterportModelId,
            askingPrice,
            flags,
            aggregatedImages{
                id,
                isActive,
                createdAt,
                updatedAt,
                type,
                filename,
                index,
                status,
                isVisible,
                watermarkUrl,
                thumbnailUrl,
                originalUrl,
                printUrl,
                webUrl,
                metadata,
                tags
                }
            aggregatedVideos{
                 id,
                 isActive,
                 createdAt,
                 updatedAt,
                 type,
                 filename,
                 index,
                 status,
                 isVisible,
                 watermarkUrl,
                 thumbnailUrl,
                 originalUrl,
                 printUrl,
                 webUrl,
                 metadata,
                 tags
            }
            aggregatedFloorplans{
              id,
              originalUrl,
              filename,
              isVisible,
              status,
              tags,
              isActive
              service {
                  id
               }
              }
            services{
               id
               flags
               matterportModelId
               floorplans {
               id,
                createdAt,
                updatedAt,
                type,
                filename,
                index,
                status,
                isVisible,
                isActive,
                watermarkUrl,
                thumbnailUrl,
                originalUrl,
                webUrl,
                metadata,
                tags
               }
                ${Queries.videos.full}
            },
             audioFile {
               id
               filename
               webUrl
               originalUrl
               thumbnailUrl
            }
            region{
                id
                name
                state
            }
        }
    }
`;

	static GET_EDITING_COMPANIES = gql`
		query($q: JSON) {
			editingCompanies(query: $q) {
				id
				name
				email
				phoneNumber
				editors {
					name
				}
				editingManager {
					id
					name
					email
					phoneNumber
				}
			}
		}
	`;

	static GET_USER_PROMOS = gql`
		query promos($userId: String!, $limit: Int, $isActive: Boolean) {
			promos(user: $userId, limit: $limit, isActive: $isActive) {
				id
				name
				promoType
				discountAmount
				discountPercentage
				discountType
				isActive
				uses
				maxUses
				user {
					id
				}
				option {
					id
					name
				}
			}
		}
	`;

	static GET_USER_QUERY = gql`
      query GetUser($id: String!) {
         user(id: $id) {
            id
            isActive
            name
            role
            email
            image
            notes
            inTraining
            cubiCasaEmail
            cubiCasaStatus
            certificates
            requiresPasswordChange
            stripeAccountId
            stripeCustomerId
            isInternalTech
            allowInvoicing
            websiteUrl
            phoneNumber
            bio
            serviceAreas
            additionalContacts
            claimServiceDelay
            resourcesRead
            marketingAssets {
               ${files.fields}
            }
            optOutPhotographers {
               id
               name
               image
            }
            promos {
               id
               name
               promoType
               discountAmount
               discountPercentage
               discountType
               isActive
               uses
               maxUses
               option {
                  id
                  name
               }
            }
            schedulePhotographerRequests {
               id
               isActive
               accepted
               requestedTimes
            }
            editingCompany {
               id
            }
            ${Queries.regions.flat}
         }
      }
   `;

	static UPDATE_FORM = gql`
		mutation UpdateForm($id: String!, $isActive: Boolean, $inviteCode: String, $formData: JSON, $salesman: String) {
			updateForm(id: $id, isActive: $isActive, inviteCode: $inviteCode, formData: $formData, salesman: $salesman) {
				id
				formType
				formData
				isActive
				inviteCode
				formData
				salesman {
					id
				}
			}
		}
	`;

	static RESET_PASSWORD = gql`
		mutation ResetPassword($email: String!) {
			resetPassword(email: $email)
		}
	`;

	static GET_FORMS_QUERY = gql`
		query GetForms($q: JSON!, $limit: Int, $offset: Int) {
			forms(query: $q, limit: $limit, offset: $offset) {
				id
				isActive
				formType
				formData
				salesman {
					id
					name
					image
				}
				inviteCode
			}
		}
	`;

	static GET_REGIONS_QUERY = gql`
      query GetRegions($q: JSON, $limit: Int, $offset: Int) {
         regions(query: $q, limit: $limit, offset: $offset) {
            id
            name
            latitude
            longitude
            infoPath
            ${Queries.regionConfig.full}
            flexBookingDelayTime
            timezone
            state
            options{
            	id
            	packageType
            	name
            }
            regionalManager {
               id
               image
               name
               email
               phoneNumber
            }
            zipCodes
            timezone
         }
      }
   `;

	static UPDATE_SERVICE = gql`
		mutation UpdateService(
			$serviceId: String!
			$squareFootage: Int
			$surgeBoost: Int
			$cubiCasaModelId: String
			$cubiCasaOrderId: String
			$matterportModelId: String
			$selectedImages: [String]
			$isAdminApproved: Boolean
			$agent: String
			$client: String
			$status: String
			$muteNotifications: Boolean
			$photographer: String
			$editor: String
			$serviceDate: String
			$isTraining: Boolean
			$agentNotes: String
			$editorNotes: String
			$photographerNotes: String
			$regionalManagerNotes: String
			$askingPrice: Int
			$orderInformation: JSON
			$type: String
			$floorplans: [String]
			$editingCompany: String
			$options: [String]
			$bookedOptions: [String]
			$trainee: String
			$customerServiceAgent: String
			$preferredPhotographers: [String]
			$additionalAgentContacts: [JSON]
			$includesMatterport: Boolean
			$includesFloorplan: Boolean
			$isDroneService: Boolean
		) {
			updateService(
				id: $serviceId
				options: $options
				isAdminApproved: $isAdminApproved
				trainee: $trainee
				agent: $agent
				client: $client
				floorplans: $floorplans
				matterportModelId: $matterportModelId
				preferredPhotographers: $preferredPhotographers
				cubiCasaModelId: $cubiCasaModelId
				cubiCasaOrderId: $cubiCasaOrderId
				surgeBoost: $surgeBoost
				selectedImages: $selectedImages
				status: $status
				muteNotifications: $muteNotifications
				type: $type
				bookedOptions: $bookedOptions
				photographer: $photographer
				editor: $editor
				editingCompany: $editingCompany
				serviceDate: $serviceDate
				orderInformation: $orderInformation
				isTraining: $isTraining
				agentNotes: $agentNotes
				editorNotes: $editorNotes
				photographerNotes: $photographerNotes
				regionalManagerNotes: $regionalManagerNotes
				askingPrice: $askingPrice
				squareFootage: $squareFootage
				customerServiceAgent: $customerServiceAgent
				additionalAgentContacts: $additionalAgentContacts
				includesMatterport: $includesMatterport
				includesFloorplan: $includesFloorplan
				isDroneService: $isDroneService
			) {
				id
				agentNotes
				agent {
					id
					name
					email
				}
				type
				muteNotifications
				bookedOptions {
					id
				}
				isAdminApproved
				customerServiceAgent {
					id
				}
				photographer {
					id
				}
				client {
					id
					name
				}
				editor {
					id
				}
				isTraining
				status
				isAdminApproved
				additionalAgentContacts
			}
		}
	`;

	static UPGRADE_SHOOT = gql`
		mutation UpgradeService(
			$serviceId: String!
			$requestedOptions: [JSON]!
			$stripeBookingTotal: Int
			$stripePaymentToken: String
			$isDroneService: Boolean
			$includesMatterport: Boolean
			$promos: [String]
		) {
			upgradeService(
				id: $serviceId
				requestedOptions: $requestedOptions
				stripeBookingTotal: $stripeBookingTotal
				stripePaymentToken: $stripePaymentToken
				isDroneService: $isDroneService
				includesMatterport: $includesMatterport
				promos: $promos
			) {
				id
			}
		}
	`;

	static DELETE_SHOOT = gql`
		mutation DeleteService($serviceId: String!) {
			deleteService(id: $serviceId) {
				id
			}
		}
	`;

	static DELETE_PROMO = gql`
		mutation DeletePromo($promoId: String!) {
			deletePromo(id: $promoId) {
				id
			}
		}
	`;

	static APPROVE_SERVICE_REQUEST = gql`
		mutation ApproveServiceRequest($id: String!, $completedFileStatus: String!) {
			approveServiceRequest(id: $id, completedFileStatus: $completedFileStatus) {
				id
			}
		}
	`;

	static CREATE_ACTION = gql`
		mutation CreateAction($title: String!, $service: String, $serviceRequest: String, $actor: String, $isResent: Boolean) {
			createAction(title: $title, service: $service, serviceRequest: $serviceRequest, actor: $actor, isResent: $isResent) {
				id
			}
		}
	`;

	static DELETE_FORM = gql`
		mutation DeleteForm($formId: String!) {
			deleteForm(id: $formId) {
				id
			}
		}
	`;

	static GET_REGIONS_WITH_QUERY = gql`
      query GET_REGIONS($q: JSON) {
         regions(query: $q) {
            id
            timezone
            name
            zipCodes
            latitude
            longitude
            flexBookingDelayTime
            state
            infoPath
            ${Queries.regionConfig.full}
            regionalManager {
               id
               name
               image
               email
               phoneNumber
            }
            options {
               id
               isActive
               name
               price
               type
               description
               packageType
               quantity
               finalFileCount
               photographerFileCount
               editorFileCount
               requiredInfo
               requiredInfoPrompt
               payTime
               photographerPay
               traineePay
               duration
               photographerCertificatesRequired
               editorCertificatesRequired
               photographerInstructions
               editorInstructions
               isAdditionalOption
               additionalOptions {
                  id
               }
            }
         }
      }
   `;

	static GET_REGIONS_WITH_QUERY_MIN = gql`
		query GET_REGIONS($q: JSON) {
			regions(query: $q) {
				id
				timezone
				name
				zipCodes
				latitude
				longitude
				state
				infoPath
			}
		}
	`;

	static GET_OPTION = gql`
		query GetOption($id: String!) {
			option(id: $id) {
				id
				coverFile {
					id
					type
					originalUrl
					webUrl
					thumbnailUrl
				}
				isActive
				name
				price
				payTime
				type
				packageType
				description
				photographerPay
				traineePay
				quantity
				duration
				finalFileCount
				photographerFileCount
				editorFileCount
				photographerCertificatesRequired
				editorCertificatesRequired
				photographerInstructions
				editorInstructions
				requiredInfoPrompt
				requiredInfo
				exampleUrl
				deliveryTimeEstimate
				isAdditionalOption
				isBaseOption
				baseOption {
					id
					name
				}
				additionalOptions {
					id
					name
				}
				regions {
					id
					name
				}
			}
		}
	`;

	static GET_OPTIONS = gql`
		query GetOptions($query: JSON, $type: String, $isActive: Boolean, $region: String, $limit: Int, $offset: Int) {
			options(query: $query, type: $type, isActive: $isActive, region: $region, limit: $limit, offset: $offset) {
				id
				coverFile {
					id
					originalUrl
					webUrl
					thumbnailUrl
				}
				name
				price
				payTime
				type
				packageType
				photographerPay
				traineePay
				quantity
				description
				finalFileCount
				deliveryTimeEstimate
				exampleUrl
				photographerCertificatesRequired
				editorCertificatesRequired
				photographerInstructions
				editorInstructions
				requiredInfoPrompt
				requiredInfo
				isAdditionalOption
				regions {
					id
					name
				}
				additionalOptions {
					id
					packageType
					quantity
					price
					name
					requiredInfoPrompt
					requiredInfo
					deliveryTimeEstimate
					exampleUrl
					payTime
					type
					photographerCertificatesRequired
					description
					coverFile {
						id
						originalUrl
						webUrl
						thumbnailUrl
					}
				}
			}
		}
	`;
}

export default Queries;
