import React from 'react';
import './styles/mobile.scss';
import './styles/tablet.scss';
import './styles/desktop.scss';
import UILabeledInput from '../UILabeledInput';
import { getPromosString } from '../../../utils/Helpers/PromoHelpers';
import { convertCents } from '../../../utils/Helpers/StringHelpers';
import { UI_LABELED_INPUT_STATIC_TYPE } from '../UILabeledInput/config';
import { useHistory, useLocation } from 'react-router-dom';

export default function UIReceipt({ receipt, promos, disableLink, listingOnly, style, noService }: UIReceiptProps) {
	const location = useLocation();
	const history = useHistory();

	const onClickSidebarService = (service) => {
		history.push(location.pathname + `?service=${service.id}`);
	};

	const renderService = () => {
		if (noService) return null;

		const onClick = (e) => {
			e.stopPropagation();
			onClickSidebarService(receipt.service);
		};
		if (!receipt.service) {
			return;
		}
		if (disableLink) {
			return (
				<div className='uiReceipt__service '>
					<p className='uiReceipt__service--label'>Service:</p>
					<p className='uiReceipt__service--address'>{receipt.service.property.address || 'view this receipts service'}</p>
				</div>
			);
		} else
			return (
				<div onClick={onClick} className='uiReceipt__service service-link-hover'>
					<p className='uiReceipt__service--label'>Service:</p>
					<p className='uiReceipt__service--address'>{receipt.service.property.address || 'view this receipts service'}</p>
				</div>
			);
	};

	const { amount, receiptUrl, bookedOptions } = receipt;

	const renderTotalPrice = () => {
		if (receipt.service?.orderInformation)
			return (
				<div className='receiptInfo__header'>
					<h4>Package ordered</h4>
					<p className='receiptInfo__options--price'>{receipt.service?.orderInformation.name}</p>
				</div>
			);

		return (
			<div className='receiptInfo__header'>
				<h4>TOTAL</h4>
				<p className='receiptInfo__options--price'>{convertCents(amount)}</p>
			</div>
		);
	};
	return (
		<div className='receipt' style={style}>
			{renderService()}
			<div className='receiptInfo'>
				<div className='receiptInfo__options'>
					{listingOnly && <p className='receiptInfo__options--value'>Content marketing & management</p>}
					{bookedOptions &&
						bookedOptions.map((bookedOption) => {
							return (
								<p key={bookedOption.id} className='receiptInfo__options--value'>
									{bookedOption.name} - {convertCents(bookedOption.price)}
									{bookedOption.quantity > 1 ? ` x ${bookedOption.quantity}` : ''}
								</p>
							);
						})}
				</div>
			</div>
			{renderTotalPrice()}
			{promos && promos.length !== 0 && (
				<UILabeledInput containerStyle={{ marginTop: 16 }} type={UI_LABELED_INPUT_STATIC_TYPE} label={'PROMOS USED'} value={getPromosString(promos, true)} />
			)}
			{receiptUrl && receiptUrl !== '' && (
				<a className='receiptInfo__link' target='_blank' rel='noopener noreferrer' href={receiptUrl}>
					view receipt on Stripe
				</a>
			)}
		</div>
	);
}
