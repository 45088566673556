import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import './styles';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import { guessCurrentTimezone } from '../../../../../utils/Helpers/AppHelper';
import * as strings from './strings';
import { getSelectedRegionSelector } from '../../../../../redux/selectors/CurrentUserSelectors';
import { toast } from 'react-toastify';
import { convertCents } from '../../../../../utils/Helpers/StringHelpers';
import { useHistory, useLocation } from 'react-router-dom';
import { statusLabel } from './strings';
import { getReadableStatus } from '../../../../../utils/Helpers/PaymentHelper/PaymentHelper';

export default function TableFinances({ data, pagination }: TableFinancesProps) {
	const selectedRegion = useSelector(getSelectedRegionSelector);

	const location = useLocation();
	const history = useHistory();

	return (
		<div className='service-table-card-container fade-in-div'>
			<div className='financeTable__header'>
				<div className='financeTable__header--title'>{strings.serviceDateLabel}</div>
				<div className='financeTable__header--title'>{strings.addressLabel}</div>
				<div className='financeTable__header--title'>{strings.clientLabel}</div>
				<div className='financeTable__header--title'>{strings.pricePaidLabel}</div>
				<div className='financeTable__header--title'>{strings.optionsPurchasedLabel}</div>
				<div className='financeTable__header--title'>{strings.statusLabel}</div>
			</div>
			<div className='financeTable__content'>
				{data.map((service) => {
					const serviceDate = moment(service.serviceDate).tz(service.property?.timezone ?? selectedRegion?.timezone ?? guessCurrentTimezone());

					const address = service.property?.address ?? service.address ?? strings.noAddressError;

					const bookedOptions = service.bookedOptions
						.map((bookedOption) => (bookedOption.quantity > 1 ? `${bookedOption.quantity} x ${bookedOption.name}` : bookedOption.name))
						.join('\r\n');

					return (
						<div className='service-table-row' key={service.id} onClick={() => history.push(location.pathname + `?service=${service.id}`)}>
							<div className='financeTable__item'>
								<p className='financeTable__item--title'>{serviceDate.format('MM/D/YYYY')}</p>
								<p className='financeTable__item--description'>{serviceDate.format('h:mm a z')}</p>
							</div>
							<CopyToClipboard onCopy={() => toast.success(strings.copySuccessAlert)} text={address}>
								<div className='financeTable__item' onClick={(e) => e.stopPropagation()}>
									<p className='financeTable__item--title'>{service.property.streetAddress}</p>
									<p className='financeTable__item--description'>
										{service.property.city}, {service.property.state}
									</p>
								</div>
							</CopyToClipboard>
							<div className='financeTable__item'>
								<p className='financeTable__item--title'>{service.client?.name ?? strings.noClientAlert}</p>
							</div>
							<div className='financeTable__item'>
								<p className='financeTable__item--price'>{convertCents(service.stripeBookingTotal)}</p>
							</div>
							<div className='financeTable__item'>
								<p className='financeTable__item--options'>{bookedOptions}</p>
							</div>
							<div className='financeTable__item'>
								<p className='financeTable__item--options'>{getReadableStatus(service.status)}</p>
							</div>
						</div>
					);
				})}
				{pagination}
			</div>
		</div>
	);
}
