// Created by carsonaberle at 7/5/21
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { commonMapDispatchToProps, commonMapStateToProps } from '../../../utils/Helpers/AppHelper';
import SlideInOutTransition from './components/SlideInOutTransition';
import FadeInOutTransition from './components/FadeInOutTransition';
import * as S from './styles';

function PLControlSidebar({ open, styles, onSetOpen, sidebar, children }: PLControlSidebarProps) {
	const animationDuration = 600;
	const [isClosing, setIsClosing] = useState(false);

	const onClickOverlay = (e: React.MouseEvent) => {
		e.stopPropagation();

		if (!isClosing && open) {
			setIsClosing(true);
			setTimeout(() => {
				setIsClosing(false);
				onSetOpen(!open);
			}, animationDuration);
		} else {
			onSetOpen(!open);
		}
	};

	return (
		<S.PLControlSidebarContainer>
			{children}
			{(open || isClosing) && (
				<>
					<FadeInOutTransition transitionIn={!isClosing}>
						<S.PLControlSidebarOverlay style={styles?.overlay}>
							<SlideInOutTransition transitionIn={!isClosing} direction='left-right'>
								<S.PLControlSidebarContent style={styles?.sidebar} onClick={onClickOverlay}>
									{React.cloneElement(sidebar, { forceClose: onClickOverlay })}
								</S.PLControlSidebarContent>
							</SlideInOutTransition>
						</S.PLControlSidebarOverlay>
					</FadeInOutTransition>
				</>
			)}
		</S.PLControlSidebarContainer>
	);
}

export default connect(commonMapStateToProps, commonMapDispatchToProps)(PLControlSidebar);
