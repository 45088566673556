import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './styles';
import { useDispatch, useSelector } from 'react-redux';
import { OPTION_TYPES } from '../../../../../../utils/Config/Constants';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment-timezone';
import TimePicker from 'rc-time-picker/lib/TimePicker';
import { getLastBookTimeFromSunset } from '../../../../../../utils/Helpers/BookingHelpers';
import { DRONE_TYPE, PHOTO_TYPE } from '../../../../../../utils/Helpers/PackageType/PackageType';
import UIBookableOption from '../../../../../../components/UI/UIBookableOption';
import UIHover from '../../../../../../components/UI/UIHover/UIHover';
import { getIsUserAdminSelector } from '../../../../../../redux/selectors/CurrentUserSelectors';
import { Moment } from 'moment/moment';
import { toast } from 'react-toastify';
import { getBookingConfigSelector } from '../../../../../../redux/selectors/BookingSelectors';
import { setUpBookableOptions } from '../../../EditOptions/config';
import { getOptionsFromBookingConfig } from '../../config';
import { STANDARD_LIGHTING_TAB, TWILIGHT_LIGHTING_TAB, VIRTUAL_TWILIGHT_LIGHTING_TAB } from './config';
import { getSunset } from 'sunrise-sunset-js';
import UIDropdownSelect from '../../../../../../components/UI/UIDropdownSelect';
import { setBookingConfig } from '../../../../../../redux/store/booking/actions';
import { guessCurrentTimezone } from '../../../../../../utils/Helpers/AppHelper';
import { book } from '../../../../../../components/UI/UIBookingButton/strings';

export default function BookingDateTime({ updateBookingConfig, bookingClient }: BookingDateTimeProps) {
	const bookingConfig = useSelector(getBookingConfigSelector);
	const isUserAdmin = useSelector(getIsUserAdminSelector);

	const timezone = bookingConfig?.region?.timezone || guessCurrentTimezone();

	const initialDate = moment
		.tz(bookingConfig?.region?.timezone || guessCurrentTimezone())
		.add('4', 'days')
		.set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
	const dispatch = useDispatch();

	const [focused, setFocused] = useState<boolean>(false);

	const selectedDate: Moment = useMemo(() => {
		return bookingConfig.serviceDate || initialDate;
	}, [bookingConfig.serviceDate]);

	useEffect(() => {
		if (bookingConfig.serviceDate) return;
		dispatch(setBookingConfig({ ...bookingConfig, serviceDate: initialDate }));
	}, []);

	const setSelectedDate = (serviceDate) => {
		updateBookingConfig('serviceDate', moment.tz(serviceDate, timezone));
	};

	let { longitude = bookingConfig?.region?.longitude, latitude = bookingConfig?.region?.latitude } = bookingConfig.serviceLocation;

	let selectedOptions = getOptionsFromBookingConfig(bookingConfig);

	const sunsetMoment = useMemo(() => {
		if (!latitude || !longitude) return moment.tz(selectedDate, timezone);
		const sunsetDate = getSunset(latitude, longitude, selectedDate.toDate());
		return moment(sunsetDate).add(1, 'day');
	}, [latitude, longitude, selectedDate]);

	const sunsetStartMoment = useMemo(() => {
		return getLastBookTimeFromSunset(sunsetMoment, selectedOptions);
	}, [sunsetMoment, selectedOptions]);

	const lastBookMoment = useMemo(() => {
		return getLastBookTimeFromSunset(sunsetStartMoment, selectedOptions);
	}, [sunsetMoment, selectedOptions]);

	const shouldShowLightingOption = useMemo(() => {
		return (
			bookingConfig.selectedOptions.filter(
				(bookableOption) => [PHOTO_TYPE.packageType, DRONE_TYPE.packageType].indexOf(bookableOption.option.packageType) !== -1
			).length > 0
		);
	}, [bookingConfig.selectedOptions]);

	const twilightOption = useMemo(() => {
		return setUpBookableOptions(bookingConfig?.region?.options?.filter((option) => option.type === OPTION_TYPES.twilightUpgrade) ?? [], bookingClient.promos)[0];
	}, [bookingConfig?.region?.options]);

	const virtualTwilightOption = useMemo(() => {
		return setUpBookableOptions(bookingConfig?.region?.options?.filter((option) => option.type === OPTION_TYPES.virtualTwilight) ?? [], bookingClient.promos)[0];
	}, [bookingConfig?.region?.options]);

	const disabledHours = useCallback(() => {
		let array = [0, 1, 2, 3, 4, 5, 6, 23];
		if (!isUserAdmin) {
			array = [0, 1, 2, 3, 4, 5, 6];
			for (let i = lastBookMoment.hour() + 1; i < 24; i++) {
				array.push(i);
			}
		}
		return array;
	}, [isUserAdmin, lastBookMoment]);

	const LIGHTING_TABS: PLTab[] = [STANDARD_LIGHTING_TAB, VIRTUAL_TWILIGHT_LIGHTING_TAB, TWILIGHT_LIGHTING_TAB];

	const getDefaultTab = () => {
		if (bookingConfig?.lightingOptions?.length === 0) return LIGHTING_TABS[0];
		const selectedLightingOption = bookingConfig.lightingOptions[0];
		if (selectedLightingOption.option.id === virtualTwilightOption.option.id) return VIRTUAL_TWILIGHT_LIGHTING_TAB;
		if (selectedLightingOption.option.id === twilightOption.option.id) return TWILIGHT_LIGHTING_TAB;
		return LIGHTING_TABS[0];
	};

	const [selectedTab, setSelectedTab] = useState<PLTab>(getDefaultTab());

	const selectedTabOption = useMemo(() => {
		switch (selectedTab.value) {
			case TWILIGHT_LIGHTING_TAB.value:
				return twilightOption;
			case VIRTUAL_TWILIGHT_LIGHTING_TAB.value:
				return virtualTwilightOption;
			default:
				return;
		}
	}, [selectedTab]);

	const isDateValid = (current: Moment) => {
		let now = moment();
		if (isUserAdmin) {
			return true;
		}
		let daysToAdd = 0;
		if (now.hour() > 16) {
			daysToAdd = 1;
		}
		return current.startOf('day').isAfter(now.add(daysToAdd, 'day'));
	};

	const onDateChange = async (date: Moment | null) => {
		if (!date || !isDateValid(date)) {
			toast.success("We can't guarantee this date! Please select a later date or react out to support for more help.");
			return;
		}
		const isTwilightSelected = selectedTab.value === TWILIGHT_LIGHTING_TAB.value;

		if (isTwilightSelected) {
			if (!latitude || !longitude) return date;
			const newSunsetTime = moment(getSunset(latitude, longitude, date.toDate()));
			setSelectedDate(moment.tz(getLastBookTimeFromSunset(newSunsetTime, selectedOptions), bookingConfig?.region?.timezone || guessCurrentTimezone()));
		} else {
			setSelectedDate(moment.tz(date, bookingConfig?.region?.timezone || guessCurrentTimezone()).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }));
		}
		return;
	};

	const renderDateTimeComponent = () => {
		const isTwilightSelected = selectedTab.value === TWILIGHT_LIGHTING_TAB.value;

		const renderTimePicker = () => {
			const renderTimeContent = () => {
				if (!isTwilightSelected) {
					return (
						<div className='bookingDT__left--time'>
							<TimePicker
								showSecond={false}
								hideDisabledOptions
								value={selectedDate}
								onChange={setSelectedDate}
								disabledHours={disabledHours}
								use12Hours
								allowEmpty={false}
								inputReadOnly={true}
								minuteStep={15}
							/>
							<p className='bookingDT__left--time-timezone'>
								<span>{selectedDate.zoneAbbr()}</span>
							</p>
						</div>
					);
				}
				return (
					<UIHover
						maxWidth={'calc(min(440px, 100%))'}
						text={"You have chosen a twilight service, so the time has been set for you based off the sunset time and the options you've selected."}
						className='bookingDT__left--time'>
						<p className='bookingDT__left--time-twilight'>{sunsetStartMoment.format('h:mm a z')}</p>
					</UIHover>
				);
			};

			return renderTimeContent();
		};

		return (
			<div className='bookingDT__left__card'>
				<div className='bookingDT__top'>
					<SingleDatePicker
						id={'bookingDateTimePicker'}
						isDayBlocked={(value) => !isDateValid(value)}
						enableOutsideDays={true}
						isOutsideRange={() => false}
						onDateChange={onDateChange}
						displayFormat={'MM/DD/YY'}
						placeholder={'MM/DD/YY...'}
						date={selectedDate}
						numberOfMonths={1}
						focused={focused}
						onFocusChange={({ focused }) => setFocused(focused)}
						initialVisibleMonth={() => moment()}
					/>
					<p className='bookingDT__left--time-timezone'>at </p>
					{renderTimePicker()}
				</div>
			</div>
		);
	};

	const renderLightingOptionsComponent = () => {
		if (!shouldShowLightingOption || !twilightOption || !virtualTwilightOption) return null;

		const renderTabContent = () => {
			switch (selectedTab.value) {
				case TWILIGHT_LIGHTING_TAB.value:
					return (
						<div className='bookingDT--section'>
							<UIBookableOption
								disableSelection={true}
								bookableOption={twilightOption}
								selectedOptions={bookingConfig.lightingOptions}
								updateSelectedOptions={(lightingOptions: OptionType[]) => updateBookingConfig('lightingOptions', lightingOptions)}
							/>
						</div>
					);
				case VIRTUAL_TWILIGHT_LIGHTING_TAB.value:
					return (
						<div className='bookingDT--section'>
							<UIBookableOption
								disableSelection={true}
								bookableOption={bookingConfig.lightingOptions[0] || virtualTwilightOption}
								selectedOptions={bookingConfig.lightingOptions}
								updateSelectedOptions={(lightingOptions: OptionType[]) => updateBookingConfig('lightingOptions', lightingOptions)}
							/>
						</div>
					);
				default:
					return null;
			}
		};

		const updateSelectedTab = (tab) => {
			let newBookingConfig = Object.assign({}, bookingConfig);

			if (!selectedTabOption) {
				newBookingConfig.lightingOptions = [];
			} else {
				newBookingConfig.lightingOptions = [selectedTabOption];
			}

			if (newBookingConfig.serviceDate && tab.value === TWILIGHT_LIGHTING_TAB.value) {
				newBookingConfig.serviceDate = newBookingConfig.serviceDate.set({ hour: sunsetStartMoment.hour(), minute: sunsetStartMoment.minute() });
			} else {
				if (newBookingConfig.serviceDate?.isAfter(lastBookMoment)) {
					newBookingConfig.serviceDate = lastBookMoment;
				}
			}

			dispatch(setBookingConfig(newBookingConfig));

			setSelectedTab(tab);
		};

		return (
			<>
				<div className='pl-separator' />
				<UIDropdownSelect tabs={LIGHTING_TABS} selectedTab={selectedTab} updateSelected={updateSelectedTab} containerClassName='bookingDT--tabs' />
				{renderTabContent()}
			</>
		);
	};

	return (
		<div className='bookingDT fade-in-div'>
			<p className='bookingDT--title'>Select a date & time</p>
			{renderDateTimeComponent()}
			{renderLightingOptionsComponent()}
		</div>
	);
}
