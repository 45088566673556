import gql from 'graphql-tag';

export const VIRTUAL_TOUR_QUERY = gql`
	query listingVirtualTour($id: String!) {
		listingVirtualTour(id: $id) {
			id
			squareFootage
			matterportModelId
			askingPrice
			flags
			coverFile {
				id
				thumbnailUrl
				type
			}
			property {
				id
				unitNumber
				address
				addressComponents
				alias
				baths
				beds
				city
				country
				googlePlaceId
				latitude
				longitude
				state
				streetAddress
				squareFootage
				timezone
				type
				unitNumber
				yearBuilt
				zipCode
			}
			audioFile {
				id
				originalUrl
			}
		}
	}
`;
