import React, { useState } from 'react';
import './styles';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { RIGHT_PANEL_TYPE_SERVICES, SCREEN_TYPE, USER_ROLE_AGENT, USER_ROLE_PHOTOGRAPHER } from '../../../utils/Config/Constants';
import { roleRoutes } from '../../../utils/Config/SessionConfig';
import { getMatchingPath } from '../../../utils/Helpers/AppHelper';
import { isRouteExcludedFromAuth } from '../../../RoutesItems';
import '../../../assets/styles/scss/pl-animations.scss';
import '../../../assets/styles/BaseStyles/app.scss';
import '../../../assets/styles/BaseStyles/client.scss';
import '../../../assets/styles/pl-colors.scss';
import '../../../assets/styles/ExternalPackagesStyles/ReactDateTime/react-date-time-mobile.scss';
import '../../PL/PLDateRangePicker/styles/pl_date_range_picker.scss';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'rc-time-picker/assets/index.css';
import UIModal from '../UIModal';
import UIOverlayCard from '../UIOverlayCard';
import UIControllerHeaders from './Headers';
import AgentActionItemsModal from '../../modals/AgentActionItemsModal';
import { getIsMobileMenuVisibleSelector, getRightPanelTypeSelector, getScreenTypeSelector } from '../../../redux/selectors/ControlSelectors';
import { setRightPanelType, setSelectedPanelTab, toggleMenuVisibility } from '../../../redux/store/control/actions';
import { getSignedInUserSelector } from '../../../redux/selectors/CurrentUserSelectors';
import { ToastContainer } from 'react-toastify';
import PLImage from '../../PL/PLImage';
import UserProfileModal from '../../modals/UserProfileModal';
import AgentTeamMembersModal from '../../modals/AgentTeamMembersModal/AgentTeamMembersModal';
import TechnicianCoverageAreaModal from '../../modals/TechnicianCoverageAreaModal';
import UIHeader from "../UIHeader";
import { HEADER_TYPES } from "../UIHeader/config";
import * as Styles from './styles';
import { ItemContent, ItemLink, ItemSeparator } from "./styles";

export default function UIController({ headerType, bodyClassName, noScroll, className, additionalHeader, children }: UIControllerProps) {
	const screenType = useSelector(getScreenTypeSelector);
	const rightPanelType = useSelector(getRightPanelTypeSelector);
	const isMobileMenuVisible = useSelector(getIsMobileMenuVisibleSelector);
	const signedInUser = useSelector(getSignedInUserSelector);

	const [showUserProfileModal, setShowUserProfileModal] = useState<boolean>(false);
	const [showAgentTeamMembersModal, setShowAgentTeamMembersModal] = useState<boolean>(false);
	const [showTechnicianServiceAreaModal, setShowTechnicianServiceAreaModal] = useState<boolean>(false);

	const history = useHistory();
	const dispatch = useDispatch();

	const toggleMenuMobile = () => {
		screenType === SCREEN_TYPE.mobile ? dispatch(toggleMenuVisibility(!isMobileMenuVisible)) : undefined;
	};

	const renderActionItemsModal = () => {
		return <AgentActionItemsModal />;
	};

	const renderHeader = () => {
		const matchingRoute = getMatchingPath(signedInUser, history);
		if (matchingRoute && !matchingRoute.noHeader) {
			return <UIHeader type={headerType || HEADER_TYPES.CENTER_TITLE_TYPE} />;
		}
		return null;
	};

	const renderLeftPanel = () => {
		const matchingRoute = getMatchingPath(signedInUser, history);
		if (isRouteExcludedFromAuth(matchingRoute)) return;
		if (screenType === SCREEN_TYPE.mobile) {
			if (!isMobileMenuVisible) {
				return null;
			}
		}

		if (matchingRoute && !matchingRoute.noPanel) {
			const content = (
				<div className='uiController__left--panel' onClick={(e) => e.stopPropagation()}>
					{<UIControllerHeaders />}
					<Styles.LeftItemsWrapper>
						{roleRoutes(signedInUser)
							.filter(({ showInPanel }) => showInPanel)
							.map((section) => renderPageItem(section, matchingRoute))}
					</Styles.LeftItemsWrapper>
					<div className='uiController__left--item' onClick={() => setShowUserProfileModal(true)}>
						<PLImage
							borderRadius={16}
							imageClassName='uiController__left--profile-image'
							className='uiController__left--profile-image-container'
							src={signedInUser.image}
							alt={'profile image'}
						/>
						<p className='uiController__left--profile-name'>view profile</p>
					</div>
					{signedInUser.role === USER_ROLE_AGENT && (
						<div
							className='uiController__left--item'
							onClick={(e) => {
								e.stopPropagation();
								setShowAgentTeamMembersModal(true);
							}}>
							<p className='uiController__left--profile-name'>manage team members</p>
						</div>
					)}
					{signedInUser.role === USER_ROLE_PHOTOGRAPHER && (
						<div
							className='uiController__left--item'
							onClick={(e) => {
								e.stopPropagation();
								setShowTechnicianServiceAreaModal(true);
							}}>
							<p className='uiController__left--profile-name'>edit service area</p>
						</div>
					)}
				</div>
			);

			const hideModal = () => dispatch(toggleMenuVisibility(false));

			return (
				<div className='uiController__left' onClick={toggleMenuMobile}>
					{content}
					<UIModal condition={screenType === SCREEN_TYPE.mobile}>
						<UIOverlayCard noLoader cardStyle={{ backgroundColor: "var(--background-color)"}} padding={0} hideModal={hideModal}>
							{content}
						</UIOverlayCard>
					</UIModal>
				</div>
			);
		}

		return null;
	};

	const renderPageItem = (section, matchingRoute) => {
		const { path } = matchingRoute;
		const toPath = section['defaultPath'] ? section['defaultPath'] : section.path;

		const onClickItem = () => {
			dispatch(setSelectedPanelTab(toPath));
			dispatch(toggleMenuVisibility(false));
		}

		const LinkComponent = path === section.path || section['skipInitial'] ? Styles.ItemLinkSelected : Styles.ItemLink
		return (
			<LinkComponent
				key={section.path}
				onClick={onClickItem}
				to={toPath}>
				<Styles.ItemContent>
					{section.icon ? <Styles.ItemIcon src={section.icon} alt={section.path} /> : undefined}
					<Styles.ItemTitle style={section['skipInitial'] ? { color: 'var(--mint-primary)' } : undefined}>
						{section.title}
					</Styles.ItemTitle>
				</Styles.ItemContent>
				<Styles.ItemSeparator />
			</LinkComponent>
		);
	};

	const renderRightPanel = () => {
		if (rightPanelType) {
			if (rightPanelType === RIGHT_PANEL_TYPE_SERVICES) {
				return undefined;
			}

			return (
				<div className='uiController__right'>
					<div className='rightPanel__background' onClick={() => dispatch(setRightPanelType(undefined))} />
				</div>
			);
		}
		return undefined;
	};

	const renderProfileModal = () => {
		const hideModal = () => setShowUserProfileModal(false);

		return (
			<UIModal condition={showUserProfileModal}>
				<UserProfileModal hideModal={hideModal} />
			</UIModal>
		);
	};

	const renderTechnicianServiceAreaModal = () => {
		const hideModal = () => setShowTechnicianServiceAreaModal(false);

		return (
			<UIModal condition={showTechnicianServiceAreaModal}>
				<TechnicianCoverageAreaModal hideModal={hideModal} regions={signedInUser?.regions} technician={signedInUser} />
			</UIModal>
		);
	};

	const renderAgentTeamMembersModal = () => {
		const hideModal = () => setShowAgentTeamMembersModal(false);

		return (
			<UIModal condition={showAgentTeamMembersModal}>
				<AgentTeamMembersModal hideModal={hideModal} />
			</UIModal>
		);
	};

	const matchingRoute = getMatchingPath(signedInUser, history);

	if (!matchingRoute) {
		return null;
	}

	return (
		<div className={`uiController ${className}`}>
			{renderHeader()}
			{additionalHeader}
			<div className='uiController__content'>
				{renderLeftPanel()}
				<div style={!noScroll ? { overflowY: 'auto' } : undefined} className={`uiController__content__body ${bodyClassName}`}>
					<ToastContainer />
					{children}
					<div id={'scroll-end'} />
				</div>
				{renderRightPanel()}
			</div>
			{renderActionItemsModal()}
			{renderProfileModal()}
			{renderTechnicianServiceAreaModal()}
			{renderAgentTeamMembersModal()}
		</div>
	);
}
