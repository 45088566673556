import { getAvailableAddOns } from '../../../../utils/Helpers/BookingHelpers';
import { areEditVSUpgradeOptionsSelected, areMediaOptionsSelected, WORKFLOW_COMPONENTS, WORKFLOWS } from './config';

export const getWorkflowFromUser = (bookingConfig) => {
	if (!bookingConfig) {
		return [];
	}
	let workflow;
	const availableAddOns = getAvailableAddOns(bookingConfig);

	const addOnsUnavailable = availableAddOns.availableOptions.length === 0;

	if (bookingConfig.region && bookingConfig.region.id) {
		if (areMediaOptionsSelected(bookingConfig.selectedOptions)) {
			if (addOnsUnavailable) {
				workflow = WORKFLOWS.mediaServicesNoUpgrades;
			} else {
				workflow = WORKFLOWS.mediaServices;
			}
		} else if (areEditVSUpgradeOptionsSelected(bookingConfig.selectedOptions)) {
			if (addOnsUnavailable) {
				workflow = WORKFLOWS.editServicesNoUpgrades;
			} else {
				workflow = WORKFLOWS.editServices;
			}
		} else {
			workflow = WORKFLOWS.listingOnly;
		}
	} else {
		if (areEditVSUpgradeOptionsSelected(bookingConfig.selectedOptions)) {
			if (addOnsUnavailable) {
				workflow = WORKFLOWS.editServicesNoUpgrades;
			} else {
				workflow = WORKFLOWS.editServices;
			}
		} else {
			workflow = WORKFLOWS.listingOnly;
		}
	}

	if (bookingConfig.listing) {
		workflow = workflow.filter((workflowComponent) => workflowComponent !== WORKFLOW_COMPONENTS.location);
	}

	return workflow;
};
