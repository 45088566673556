// Created by jarongallo at 6/17/21
import React, { useState } from 'react';
import * as strings from './strings';
import './styles';
import { useMutation } from '@apollo/react-hooks';
import UIListInputRow from '../../../../../../components/UI/UIListInputRow';
import UIModal from '../../../../../../components/UI/UIModal';
import UIOverlayCard from '../../../../../../components/UI/UIOverlayCard';
import UIIconButton from '../../../../../../components/UI/UIIconButton';
import UIButton from '../../../../../../components/UI/UIButton/UIButton';
import { toast } from 'react-toastify';
import { UI_LABELED_INPUT_BUTTON_TYPE } from '../../../../../../components/UI/UILabeledInput/config';
import { clearSidebar } from '../../../../../../redux/store/control/actions';
import { useDispatch } from 'react-redux';
import { DELETE_SERVICE } from './queries';
import { UI_ICON_BUTTON_CANCEL_TYPE, UI_ICON_BUTTON_OUTLINE_TYPE } from '../../../../../../components/UI/UIIconButton/config';

export default function PLServiceAdminDelete({ service }: PLServiceAdminDeleteProps) {
	const [isDeleting, setIsDeleting] = useState<boolean>(false);
	const [deleteServiceModal, setDeleteServiceModal] = useState(false);
	const [deleteServiceAdmin] = useMutation(DELETE_SERVICE);

	const dispatch = useDispatch();

	if (!service) return null;
	const renderDeleteServiceModal = () => {
		const hideModal = () => {
			setDeleteServiceModal(false);
		};

		const deleteService = async () => {
			if (!isDeleting) {
				setIsDeleting(true);
				let variables = { serviceId: service.id };
				const response = await deleteServiceAdmin({ variables });
				const { deleteService } = response['data'];
				if (deleteService) {
					setIsDeleting(false);
					hideModal();
					toast.success(strings.deleteServiceSuccessString);
					dispatch(clearSidebar());
				}
			}
		};

		return (
			<UIModal condition={deleteServiceModal}>
				<UIOverlayCard hideModal={hideModal} title={strings.deleteServiceModalTitle} description={strings.deleteServiceModalDescription}>
					<div className='plServiceAdminDelete'>
						<UIIconButton type={UI_ICON_BUTTON_CANCEL_TYPE} isLoading={isDeleting} text={strings.deleteServiceModalDeleteButton} onClick={deleteService} />
						<UIIconButton type={UI_ICON_BUTTON_OUTLINE_TYPE} text={strings.deleteServiceModalKeepButton} onClick={hideModal} />
					</div>
				</UIOverlayCard>
			</UIModal>
		);
	};
	return (
		<div>
			<UIListInputRow
				dark
				label={strings.deleteServiceButtonLabel}
				type={UI_LABELED_INPUT_BUTTON_TYPE}
				buttonType={UIButton.CANCEL_TYPE}
				value={strings.deleteServiceButton}
				onClick={() => setDeleteServiceModal(true)}
			/>
			{renderDeleteServiceModal()}
		</div>
	);
}
